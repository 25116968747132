import { flagOutline, filterOutline, peopleOutline } from 'ionicons/icons';
import GridTable from '@nadavshaar/react-grid-table';
import Kpi from '../../../components/kpi/Kpi';
import { IonIcon, useIonViewDidEnter } from '@ionic/react';
import { texts } from '../../../theme/dataTabelTexts';
import Placeholder from '../../../components/placeholder/Placeholder';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { accountsPayable, accountsReceivable } from '../../../graphql/invoice/queries';

const Invoice = ({ unpaid, overdue, enterprise }) => {
  const [income, setIncome] = useState({
    title: '',
    description: '',
    subtitle: '',
    rows: []
  });

  const [profit, setProfit] = useState({
    title: '',
    description: '',
    subtitle: '',
    rows: []
  })

  const { data: overdueData, loading: overdueLoading, error: overdueError } = useQuery(accountsPayable, {
    fetchPolicy: 'no-cache',
    async onCompleted(data) {
      let {accountsPayable} = data;
      let profit = {
        title: invoiceInfo(accountsPayable, 'total'),
        description: "Facturas que debes a proveedores - Cuentas por pagar",
        subtitle: `Monto próximo a vencer ${invoiceInfo(accountsPayable, 'last')}`,
        rows: invoiceInfo(accountsPayable, 'items') || []
      }
      setProfit({...profit});
    }
  });
  
  const { data: unpaideData, loading: unpaidLoading, error: unpaidError, refetch } = useQuery(accountsReceivable, {
    fetchPolicy: 'cache-and-network',
    async onCompleted(data) {
      let {accountsReceivable} = data;
      let income = {
        title: invoiceInfo(accountsReceivable, 'total'),
        description: "Facturas pendientes de clientes - Cuentas por cobrar",
        subtitle: `Monto próximo a vencer ${invoiceInfo(accountsReceivable, 'last')}`,
        rows: invoiceInfo(accountsReceivable, 'items') || []
      }
      setIncome({...income});
    }
  });

  const columns = [{
    id: 1,
    field: 'label',
    label: 'Tiempo',
  }, {
    id: 2,
    field: 'amount',
    label: 'Monto',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full">
          <p>{data.amount}</p>
        </div>)
    }

  }];

  const invoiceInfo = (data, type) => {
    let [a, b, c, d, e] = data?.items || [];
    if (type === 'total') {
      return `${enterprise?.currency?.symbol}${data?.total} ${enterprise?.currency?.code}`;
    } else if (type === 'items') {
      return [b, c, d, e].map(i => ({
        ...i,
        amount: `${enterprise?.currency?.symbol}${i?.amount} ${enterprise?.currency?.code}`
      }));
    } else if (type === 'last') {
      return `${enterprise?.currency?.symbol}${a?.amount} ${enterprise?.currency?.code}`;
    }
  }
  
  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  });

  
  useIonViewDidEnter(() => {
    refetch();
});


  return (
    <div className="flex flex-col my-12">
      <div className="flex flex-col">
        <h3 className="text-2xl font-bold text-gray-blue" >Cuentas por cobrar y cuentas por pagar</h3>
        <p className="text-base mt-4">Facturas pendientes de tus clientes hacia tu empresa y facturas que debes a tus proveedores.</p>
      </div>
      <div className="flex h-auto xs:flex-col sm:flex-col lg:flex-row sm:justify-center lg:justify-between items-center w-full">
        <div className="flex flex-col xs:w-full sm:w-full lg:w-1/2 md:mr-4 h-full my-12">
          <Kpi icon={flagOutline} title={income.title} description={income.description} loading={unpaidLoading} />
          <h3 className="text-xl font-bold my-8" >{income.subtitle}</h3>
          <GridTable
            className="sfn-data-table row-cursor-pointer"
            columns={columns}
            rows={income?.rows}
            texts={texts}
            icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            isLoading={unpaidLoading}
            components={{
              NoResults: () => (
                <Placeholder icon={peopleOutline} description='Aún no tienes facturas pendientes de pago clientes' />
              )
            }}
            {...tableProps(false)}
          />
        </div>
        <div className="flex flex-col xs:w-full sm:w-full lg:w-1/2 h-full my-12">
          <Kpi icon={flagOutline} iconColor="danger" title={profit.title} description={profit.description} loading={overdueLoading} />
          <h3 className="text-xl font-bold my-8" >{profit.subtitle}</h3>
          <GridTable
            className="sfn-data-table row-cursor-pointer"
            columns={columns}
            rows={profit?.rows}
            texts={texts}
            icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            isLoading={overdueLoading}
            components={{
              NoResults: () => (
                <Placeholder icon={peopleOutline} description='Aún no tienes facturas pendientes de pago clientes' />
              )
            }}
            {...tableProps(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default Invoice;