import React from "react";
import "./Tooltip.scss";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export const Tooltip = ({children,  label, className}) => {
  return (
    <>
      <Tippy content={label}>
        <span className={`tooltip--component ${className}`}>
          {!children && <i className="icon ion-md-help-circle-outline"></i>}
          { children && children}
        </span>
      </Tippy>
    </>
  );
};
