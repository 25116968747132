import gql from 'graphql-tag'

export const getAllCurrenciesQuery = gql`
	query currencies($options: Options, $page: Int) {
		currencies(options: $options, page: $page) {
			rows {
				id
				name
				code
				symbol
				accounts {
					id
					name
					customId
					description
					
				}
				invoices {
					id
					date
					dueDate
					number
					description
					discount
					creditDays
					
				}
				bills {
					id
					document
					expense
					number
					exchangeRate
					description
					bankDescription
					date
					dueDate
					
				}
				enterprises {
					id
					name
				}

			}
		}
	}
`

export const getOneCurrencyQuery = gql`
	query currency($input: ID!) {
		currency(id: $input) {
			id
			name
			code
			symbol
			accounts {
				id
				name
				customId
				description
				
			}
			invoices {
				id
				date
				dueDate
				number
				description
				discount
				creditDays
				
			}
			bills {
				id
				document
				expense
				number
				exchangeRate
				description
				bankDescription
				date
				dueDate
				
			}
			enterprises {
				id
				name
			}
		}
	}
`

export const currencyConverterQuery = gql`
	query currencyConverter($from: ID!,	$to: ID!) {
		currencyConverter(from: $from,	to: $to)
	}
`
