import { ApolloProvider } from "@apollo/client/react";
import { client } from "./config/apolloClient";
import AppRoute from "./router/AppRoute";
import { AuthProvider } from "../src/context/auth/AuthState";
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, setupIonicReact } from '@ionic/react';

import React from "react";
import 'animate.css';
/* Theme variables */
import "./theme/global.scss";
import "./theme/variables.scss";

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
       <AuthProvider>
        <IonApp>
          <IonReactRouter>
            <AppRoute></AppRoute>
          </IonReactRouter>
        </IonApp>
       </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
