import gql from 'graphql-tag'

export const getAllBillsQuery = gql`
	query bills($options: Options, $page: Int, $status: EnumStatusInvoice) {
		bills(options: $options, page: $page, status: $status) {
			rows {
				id
				date
				dueDate
				number
				remaining 
				convertedRemaining
				total 
				convertedTotal
				status
				currency {
					code
					symbol
				}
				vendor {
					name				
				}
			}
		}
	}
`

export const getOneBillQuery = gql`
	query bill($input: ID!) {
		bill(id: $input) {
			id
			number
			exchangeRate
			description
			date
			dueDate
			poso
			includeTaxes
			vendorId: vendor {
				value: id
				label: name				
			}
			currencyId: currency {
				id
			}
			extras {
				serie
				authorization
			}
			products {
				id
				description
				quantity
				price
				product{
					name
					id
				}
				taxes {
					id
					rate
					tax {
						id
					}
				}
				accountId: account{
					label: name
					value: id
				}
			}
		}
	}
`

export const getOneBillPaymentQuery = gql`
	query bill($input: ID!) {
		bill(id: $input) {
			id
			number
			exchangeRate
			description
			date
			dueDate
			poso
			status
			total
			remaining
			customer: vendor {
				id
				name
				bill {
					nit
				}			
			}
			currency{
				name
				code
				symbol
			}
			payments {
				id
				date
				amount
				convertedAmount
				exchangeRate
				description
				method
				account{
					id
					name
					currency{
						id
						code
						name
						symbol
					}
				}				
			}
		}
	}
`

export const getOneBillDetailQuery = gql`
	query bill($input: ID!) {
		bill(id: $input) {
			id
			remaining 
			currency {
				id
				name
				code
				symbol
				rate
			}
		}
	}
`

export const billIndicatorsQuery = gql`
	query billIndicators {
		billIndicators {
			overdue {
				label
				value
			}
			unpaid {
				label
				value
			}
			paid {
				label
				value
			}
		}
	}
`