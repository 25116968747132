// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createBillMutation,
	deleteBillMutation,
	updateBillMutation,
} from './mutations'
import { getAllBillsQuery } from './queries'

export const useBillsService = () => {
	const [createBill, { loading: cLoading, error: cError }] = useMutation(
		createBillMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createBill } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllBillsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllBillsQuery,
					data: {
						bills: { rows: [createBill, ...data.bills.rows] },
					},
				})
			},
		},
	)

	const [updateBill, { loading: uLoading, error: uError }] = useMutation(
		updateBillMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateBill } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllBillsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllBillsQuery,
					data: {
						bills: {
							rows: data.bills.rows.map((bill) =>
								bill.id === updateBill.id ? updateBill : bill,
							),
						},
					},
				})
			},
		},
	)

	const [deleteBill, { loading: dLoading, error: dError }] = useMutation(
		deleteBillMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteBill } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllBillsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllBillsQuery,
					data: {
						bills: {
							rows: data.bills.rows.map((bill) =>
								bill.id === deleteBill.id ? deleteBill : bill,
							),
						},
					},
				})
			},
		},
	)

	return {
		createBill,
		cLoading,
		cError,

		updateBill,
		uLoading,
		uError,

		deleteBill,
		dLoading,
		dError,
	}
}
