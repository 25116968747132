import {
  IonButton,
  IonContent,
  IonIcon,
  IonNote,
  IonPage,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  addCircleOutline,
  createOutline,
  filterOutline,
  repeatOutline,
  trashOutline,
} from "ionicons/icons";
import GridTable from "@nadavshaar/react-grid-table";
import Select from "react-select";
import Header from "../../components/header/Header";
import Placeholder from "../../components/placeholder/Placeholder";
import { texts } from "../../theme/dataTabelTexts";
import { useState } from "react";
import {
  dateTimeFormat,
  getDate,
  getRange,
  parseDate,
} from "../../helpers/date";
import InputDate from "../../components/input-date/InputDate";
import { sfnSelectTheme } from "../../theme/sfnSelectTheme";
import { transactionsType } from "../../helpers/catalogs";
import TransactionModal from "./components/transaction-modal/TransactionModal";
import JournalModal from "./components/journal-modal/JournalModa";
import SearchTable from "../../components/search-table/SearchTable";
import {
  getAllTransactionsQuery,
  transactionCategoriesQuery,
} from "../../graphql/transaction/queries";
import { useQuery } from "@apollo/client";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import { useTransactionsService } from "../../graphql/transaction/service";
import { formatMoney, isEmpty } from "../../helpers/utils";
import { useAuth } from '../../context/auth/AuthState';
import { AuthStore } from "../../store/auth";

const TransactionsPage = () => {
  const user = AuthStore.useState(s => s.user);
  const {authState} = useAuth();
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showJournalModal, setShowJournalModal] = useState(false);
  const [present] = useIonAlert();
  const [transactionId, setTransactionId] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(undefined);
  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState(undefined);
  const { deleteTransaction } = useTransactionsService();
  const [presentToast] = useIonToast();
  const [options, setOptions] = useState({
    where: {
      type: undefined,
      accountId: undefined,
      date: undefined,
    },
  });
  const {
    data: allTransactions,
    loading,
    error,
  } = useQuery(getAllTransactionsQuery, {
    variables: { options },
    fetchPolicy: "cache-and-network",
  });
  const {
    data: allAccounts,
    loading: allAccountsLoading,
    error: allAccountsError,
  } = useQuery(transactionCategoriesQuery, { variables: { type: "ALL" } });

  const filterDate = (e) => {
    let from = date,
      to = dueDate;
    if (e.target.name == "date") {
      setDate(e.target.value);
      from = e.target.value;
    } else {
      setDueDate(e.target.value);
      to = e.target.value;
    }
    const  between = [from, to]?.filter(v => v !== '').length ? [from, to]?.filter(v => v !== '') : undefined
    let where
    if(between) {
      where = {
        ...options.where,
        date: {
          _between: between
        },
      };
    }
    setOptions({ where });
  };

  const handleFilterChange = (selectedOption, source) => {
    let where = {};
    if (source === "category") {
      setSelectedCategoryOption(selectedOption);
      if (selectedOption) {
        where = {
          ...(options.where || {}),
          accountId: selectedOption.value.id,
        };
        setOptions({ where });
      } else {
        setOptions({ where: { ...options.where, accountId: undefined } });
      }
    }
    if (source === "status") {
      setSelectedStatusOption(selectedOption);
      if (selectedOption) {
        where = {
          ...(options.where || {}),
          type: selectedOption.value,
        };
        setOptions({ where });
      } else {
        setOptions({ where: { ...options.where, type: undefined } });
      }
    }
  };

  const ItemsPopover = ({ onHide, data }) => (
    <>
      {/* <OptionItem onHide={onHide} event={() => goToVendorPage(data.id)} icon={eyeOutline} caption="Ver detalle" /> */}
      <OptionItem
        onHide={onHide}
        event={() => handleEdit(data.id)}
        icon={createOutline}
        caption="Editar"
      />
      <OptionItem
        onHide={onHide}
        event={() => handleDelete(data.id)}
        icon={trashOutline}
        caption="Eliminar"
        className="danger"
      />
    </>
  );

  const columns = [
    {
      id: 1,
      field: "date",
      label: "Fecha de pago",
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, "-")),
    },
    {
      id: 2,
      field: "description",
      label: "Descripción",
      getValue: ({value}) => isEmpty(value)
    },
    {
      id: 3,
      field: "account",
      label: "Cuenta contable",
      getValue: ({ value }) => isEmpty(value.name),
    },
    {
      id: 4,
      field: "account",
      label: "Categoría",
      cellRenderer: ({ data }) => {
        let { items } = data;
        return items?.length > 1
          ? `${items[0]?.account?.name} + ${items?.length}`
          : `${items[0]?.account?.name || ""}`;
      },
    },
    {
      id: 5,
      field: "amount",
      label: "Monto",
      headerCellRenderer: () => <div className="ml-auto mr-2">Monto</div>,
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <IonNote
              color={data.type === "DEBIT" ? "success" : "dark"}
              className="font-bold"
            >
              {formatMoney(data?.account, data?.amount, true)}
            </IonNote>
          </div>
        );
      },
    },
    {
      id: "option",
      label: "",
      width: "max-content",
      pinned: true,
      cellRenderer: ({ data }) => (
        !['READ'].includes(user?.role?.permissions['accounting']) && <ButtonPopover options={ItemsPopover} data={data} />
      ),
    },
  ];

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show,
  });

  const handleDelete = (id) => {
    present({
      cssClass: "sfn-alert-dialog",
      mode: "md",
      header: "Eliminar transacción",
      subHeader: "Este transacción se eliminará permanentemente",
      message: "¿Esta seguro de eliminar a este transacción?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Eliminar transacción",
          cssClass: "delete",
          handler: async () => {
            let resp = await deleteTransaction({ variables: { input: id } });
            if (resp.errors) {
              presentToast({
                message: "Oops! ha ocurrido un error, intentalo de nuevo 😪",
                color: "danger",
                mode: "ios",
                duration: 4000,
              });
            } else {
              presentToast({
                message: "La transacción se ha eliminado correctamente 👍",
                color: "success",
                mode: "ios",
                duration: 4000,
              });
            }
          },
        },
      ],
    });
  };

  const handleEdit = (id) => {
    setTransactionId(id);
    setShowTransactionModal(true);
  };
  return (
    <IonPage>
      <Header
        title="Transacciones"
        subtitle="Seguimiento de transacciones monetarias que no son facturas, recibos o nomina."
      />

      <IonContent className="sfn-content">
        <section className="flex flex-col sm:flex-row justify-between mb-4 items-end">
          <div className="sm:w-52 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64">Tipo</p>
            <Select
              isClearable={true}
              options={transactionsType}
              value={selectedStatusOption}
              onChange={(e) => handleFilterChange(e, "status")}
              placeholder="Seleccione un tipo"
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
            />
          </div>

          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64">Categoría</p>
            <Select
              isClearable={true}
              isLoading={allAccountsLoading}
              options={allAccounts?.transactionCategories}
              value={selectedCategoryOption}
              onChange={(e) => handleFilterChange(e, "category")}
              placeholder="Seleccione una categoría"
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
            />
          </div>

          <InputDate
            label="Desde"
            value={date}
            handleInputChange={(e) => filterDate(e)}
            name="date"
            icon={false}
          />
          <InputDate
            label="Hasta"
            value={dueDate}
            handleInputChange={(e) => filterDate(e)}
            name="dueDate"
            icon={false}
          />

          <IonButton
            disabled={['READ'].includes(user?.role?.permissions['accounting'])}
            className="sfn-button sm:w-64 w-full mt-12 sm:mt-0"
            shape="round"
            onClick={setShowTransactionModal}
          >
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar transacción
          </IonButton>
          {/* <IonButton className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" fill="outline" shape="round" onClick={setShowJournalModal}>
                    <IonIcon slot="start" icon={addCircleOutline} />
                    Agregar diario
                </IonButton> */}
        </section>

        <GridTable
          className="mt-10 sfn-data-table h-screen"
          isLoading={loading}
          columns={columns}
          rows={allTransactions?.transactions?.rows}
          components={{
            NoResults: () => (
              <Placeholder
                icon={repeatOutline}
                description='Aún no tienes transacciones agregadas, haz clic en el botón "Agregar transacción"'
              />
            ),
            Search: SearchTable,
          }}
          {...tableProps(
            allTransactions?.transactions?.rows.length ? true : false
          )}
          texts={texts}
          icons={{
            columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" />,
          }}
          onRowClick={({ data }) => {
            // goToInvoicePage(data.id);
          }}
        />
      </IonContent>
      {showTransactionModal && (
        <TransactionModal
          showModal={showTransactionModal}
          setShowModal={setShowTransactionModal}
          transactionId={transactionId}
          setTransactionId={setTransactionId}
        />
      )}
      {showJournalModal && (
        <JournalModal
          showModal={showJournalModal}
          setShowModal={setShowJournalModal}
        />
      )}
    </IonPage>
  );
};

export default TransactionsPage;
