import { IonItem, IonLabel, IonIcon, IonInput } from '@ionic/react';
import { useEffect, useState } from 'react';
import { eyeOutline, eyeOffOutline} from 'ionicons/icons';
import './InputPassword.scss';

const InputPassword = ({label, name, required, value, onChange}) => {
    const [type, setType] = useState('password');
    return (
        <IonItem className="sfn-input input-password" mode="md" lines="none">
            <IonLabel position="stacked">{label}</IonLabel>
            <IonInput type={type} name={name} value={value} onIonChange={onChange} required> </IonInput>
            <span className="btn_eye_icon" onClick={()=>setType(type == 'text' ? 'password' : 'text')}>
                <IonIcon className="pointer-events-none"  icon={type == 'password' ? eyeOutline : eyeOffOutline} />
            </span>
        </IonItem>
    )
}

export default InputPassword;