import "./Onboarding.scss";
import { useState, useEffect } from "react";
import { DashboardOnboarding } from "./Sections/dashboard";
import { InvoicesOnboarding } from "./Sections/invoices";
import { ExpensesOnboarding } from "./Sections/expenses";
import { PayrollsOnboarding } from "./Sections/payrolls";
import { AccountsOnboarding } from "./Sections/accounts";
import { TransactionsOnboarding } from "./Sections/transactions";
import { ReportsOnboarding } from "./Sections/reports";
import { UserManagementOnboarding } from "./Sections/userManagement";
import { SettingsOnboarding } from "./Sections/settings";
import parse from 'html-react-parser';

import { Tooltip } from "../tooltip/Tooltip";

const Onboarding = ({ path }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const [onboarding, setOnboarding] = useState({
    dashboard: true,
    invoices: true,
    expenses: true,
    payrolls: true,
    accounts: true,
    transactions: true,
    reports: true,
    "user-management": true,
    settings: true,
  });

  useEffect(() => {
    if (path !== "") {
      // Reset the page index when the route changes
      setCurrentPage(0);
      const onboardingData = localStorage.getItem("onboarding");

      if (onboardingData) {
        setOnboarding(JSON.parse(onboardingData));
      } else {
        localStorage.setItem("onboarding", JSON.stringify(onboarding));
      }
    }
  }, [path]);

  /**
   * @constant sections
   * * add new sections here
   * ! Remember to modify the onboarding object if a new section is added
   * ! The [key] needs to match the route of the section
   */
  const sections = {
    dashboard: DashboardOnboarding,
    invoices: InvoicesOnboarding,
    expenses: ExpensesOnboarding,
    payrolls: PayrollsOnboarding,
    accounts: AccountsOnboarding,
    transactions: TransactionsOnboarding,
    reports: ReportsOnboarding,
    "user-management": UserManagementOnboarding,
    settings: SettingsOnboarding,
  };

  /**
   * @constant pages
   * * List of pages of the current section
   */
  const pages = sections[path];

  const skipOnboardingAndWriteToLocalStorage = () => {
    setOnboarding((prevState) => {
      const newState = { ...prevState, [path]: false };
      localStorage.setItem("onboarding", JSON.stringify(newState));
      return newState;
    });
  };

  /**
   * @function skipOnboarding skip the Onboarding
   * @description grab a copy of the onboarding object and
   * sets the current section to false
   */
  const skipOnboarding = () => {
    setOnboarding({ ...onboarding, [path]: false });
  };

  /**
   * @function nextPage go to the next page
   * @description grabs the previous state and adds 1
   */
  const nextPage = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  /**
   * @function prevPage go to the previous page
   * @description grabs the previous state and subtracts 1
   */
  const prevPage = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  if (path !== "" && onboarding[path] && pages[currentPage] !== undefined) {
    return (
      <div className="onboarding--wrapper">
        <div className="onboarding--card">
          <div className="onboarding--text">
            {currentPage === 0 ? null : (
              <button
                className="onboarding--text--prev__cta"
                onClick={prevPage}
              >
                {/* Start SVG - Previous arrow */}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.35396 0.646039C7.40052 0.692485 7.43747 0.747661 7.46267 0.808406C7.48788 0.869151 7.50085 0.934272 7.50085 1.00004C7.50085 1.06581 7.48788 1.13093 7.46267 1.19167C7.43747 1.25242 7.40052 1.30759 7.35396 1.35404L1.70696 7.00004L7.35396 12.646C7.40045 12.6925 7.43732 12.7477 7.46248 12.8085C7.48764 12.8692 7.50059 12.9343 7.50059 13C7.50059 13.0658 7.48764 13.1309 7.46248 13.1916C7.43732 13.2524 7.40045 13.3076 7.35396 13.354C7.30747 13.4005 7.25228 13.4374 7.19154 13.4626C7.1308 13.4877 7.0657 13.5007 6.99996 13.5007C6.93422 13.5007 6.86912 13.4877 6.80838 13.4626C6.74764 13.4374 6.69245 13.4005 6.64596 13.354L0.645962 7.35404C0.599398 7.30759 0.562456 7.25242 0.53725 7.19167C0.512043 7.13093 0.499068 7.06581 0.499068 7.00004C0.499068 6.93427 0.512043 6.86915 0.53725 6.80841C0.562456 6.74766 0.599398 6.69249 0.645962 6.64604L6.64596 0.646039C6.69241 0.599476 6.74758 0.562533 6.80833 0.537327C6.86907 0.51212 6.93419 0.499145 6.99996 0.499145C7.06573 0.499145 7.13085 0.51212 7.19159 0.537327C7.25234 0.562533 7.30752 0.599476 7.35396 0.646039Z"
                    fill="#A8A8A8"
                  />
                </svg>
                {/* End SVG - Previous arrow */}
              </button>
            )}
            <div className="onboarding--text--skip__wrapper">
              <button
                className="onboarding--text--skip"
                onClick={skipOnboarding}
              >
                Omitir
                <span className="ml-1">
                  <Tooltip label="El dialogo seguirá apareciendo la próxima vez que visites esta sección. Completa el tour para que no vuelva a aparecer."></Tooltip>
                </span>
              </button>
            </div>
            <div className="onboarding--text--container">
              <h1 className="onboarding--text--title">
                {pages[currentPage].title}
              </h1>
              <p className="onboarding--text--description">
                {parse(pages[currentPage].description)}
              </p>
              <div className="onboarding--text--cta__wrapper">
                {pages.length - 1 === currentPage ? (
                  <button
                    className="onboarding--text--cta"
                    onClick={skipOnboardingAndWriteToLocalStorage}
                  >
                    ¡Entendido!
                  </button>
                ) : (
                  <button className="onboarding--text--cta" onClick={nextPage}>
                    Siguiente
                  </button>
                )}
                <div className="onboarding--text--current__page--wrapper">
                  {pages.length > 1
                    ? Array(pages.length)
                        .fill(0)
                        .map((currElement, index) => (
                          <div
                            key={index}
                            className={
                              currentPage === index
                                ? "onboarding--text--current__page--active"
                                : "onboarding--text--current__page"
                            }
                            onClick={() => {
                              setCurrentPage(index);
                            }}
                          ></div>
                        ))
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="onboarding--image">
            {currentPage !== pages.length - 1 ? (
              <button
                className="onboarding--image--next__cta"
                onClick={nextPage}
              >
                {/* Start SVG - Next arrow */}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.646039 13.3541C0.599476 13.3076 0.562533 13.2525 0.537326 13.1917C0.51212 13.131 0.499146 13.0658 0.499146 13.0001C0.499146 12.9343 0.51212 12.8692 0.537326 12.8084C0.562533 12.7477 0.599476 12.6925 0.646039 12.6461L6.29304 7.00008L0.646039 1.35408C0.599551 1.30759 0.562675 1.2524 0.537516 1.19166C0.512357 1.13093 0.499407 1.06583 0.499407 1.00008C0.499407 0.934338 0.512357 0.869237 0.537516 0.808497C0.562675 0.747759 0.599551 0.69257 0.646039 0.646081C0.692527 0.599593 0.747716 0.562717 0.808455 0.537558C0.869195 0.512399 0.934295 0.49945 1.00004 0.49945C1.06578 0.49945 1.13088 0.512399 1.19162 0.537558C1.25236 0.562717 1.30755 0.599593 1.35404 0.646081L7.35404 6.64608C7.4006 6.69253 7.43754 6.7477 7.46275 6.80845C7.48796 6.86919 7.50093 6.93431 7.50093 7.00008C7.50093 7.06585 7.48796 7.13097 7.46275 7.19172C7.43754 7.25246 7.4006 7.30764 7.35404 7.35408L1.35404 13.3541C1.30759 13.4006 1.25242 13.4376 1.19167 13.4628C1.13093 13.488 1.06581 13.501 1.00004 13.501C0.934272 13.501 0.86915 13.488 0.808405 13.4628C0.74766 13.4376 0.692485 13.4006 0.646039 13.3541Z"
                    fill="#A8A8A8"
                  />
                </svg>
                {/* End SVG - Next arrow */}
              </button>
            ) : null}
            <img
              className="onboarding--image--figure"
              src={pages[currentPage].image}
              alt={pages[currentPage].alt}
            ></img>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Onboarding;
