import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Tooltip } from '../tooltip/Tooltip';
import './InputAutocomplete.scss';

const InputAutocomplete = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  options,
  required,
  tooltip,
}) => {
  const ref = useRef();
  const [isSelected, setIsSelected] = useState(!value ? true : false)
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState([])

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const onSearch = (e) => {
    onChange(e);
    let { value } = e.target;
    if(value == '' || !value || !value?.length) {
      setIsOpen(false);
    }else {
      setIsOpen(true);
    }
    if (!isSelected) {
      const filter = options.filter(f => String(f?.value).toLowerCase().includes(value.toLowerCase()));
      setFilter(filter);
      setIsOpen(true);
    }
  };

  const selected = (item) => {
    onChange(item, name);
    setIsSelected(true);
    setFilter([]);
    setIsOpen(false);
  }


  return (
    <div ref={ref} id={name} className='input-autocomplete mb-4'>
      <div className="sfn-input" mode='md' lines='none'>
        <div position="stacked" className="hover:text-secondary text-xs">{label}{required && <span className="text-xs text-danger">*</span>} <Tooltip className="text-xs" label={tooltip} /></div>
        <IonInput
          onClick={() => (setIsSelected(false))}
          name={name}
          type={type}
          value={value}
          onIonChange={onSearch}
          placeholder={placeholder}
          required={required}
        ></IonInput>
      </div>

      {isOpen && value && filter?.length ? (
        <div className="list w-full h-auto overflow-y-auto p-2 shadow-md">
          {filter.length && filter.map((item, index) =>
          (
            <div key={index} className="item p-3 rounded-md hover:cursor-pointer" onClick={e => (selected(item.value))}>
              <h3>{item.label}</h3>
            </div>
          ))}
        </div>
      ) : ''}
    </div>
  );
};

export default InputAutocomplete;
