import { useApolloClient } from "@apollo/client";
import { IonButton, IonIcon, IonNote, useIonAlert } from "@ionic/react";
import { addCircleOutline, createOutline, eyeOutline, filterOutline, receiptOutline, trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonPopover from "../../../../components/button-popover/ButtonPopover";
import InputDate from "../../../../components/input-date/InputDate";
import InvoiceModal from "../../../../components/modals/invoice-modal/InvoiceModal";
import PaymentRecordModal from "../../../../components/modals/payment-record-modal/PaymentRecordModal";
import OptionItem from "../../../../components/option-item/OptionItem";
import Placeholder from "../../../../components/placeholder/Placeholder";
import { useAuth } from "../../../../context/auth/AuthState";
import { getAllInvoicesQuery } from "../../../../graphql/invoice/queries";
import { useInvoicesService } from "../../../../graphql/invoice/service";
import { documentStatus, statusName } from "../../../../helpers/catalogs";
import { dateTimeFormat, getDate, getRange, parseDate } from "../../../../helpers/date";
import { texts } from "../../../../theme/dataTabelTexts";
import { sfnSelectTheme } from "../../../../theme/sfnSelectTheme";
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import { getAllBillsQuery } from "../../../../graphql/bill/queries";
import ExpenseModal from "../../../../components/modals/expense-modal/ExpenseModal";
import { formatMoney, isEmpty } from "../../../../helpers/utils";
import { AuthStore } from "../../../../store/auth";

const InvoicesTab = ({customerId=null, vendorId=null}) => {
  const user = AuthStore.useState(s => s.user);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentRecordModal, setShowPaymentRecordModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [billId, setBillId] = useState(null);

  const history = useHistory();
  const { deleteInvoice, dLoading, dError } = useInvoicesService();
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  // const [date, setDate] = useState(getDate(new Date(getRange(-1)), false));
  // const [dueDate, setDueDate] = useState(getDate(new Date().setDate(new Date(date).getDate() + 30), false));
  const {authState} = useAuth();
  const {enterprise} = authState;
  const [status, setStatus] = useState(undefined);
  const client = useApolloClient();
  
  const where = () => {
    let tmp = {
      date: date == null && dueDate == null ? undefined : {
        _between: [date, dueDate]
      }
    }
    if(customerId) tmp['customerId'] = customerId;
    if(vendorId) tmp['vendorId'] = vendorId;
    return {where:tmp};
  }
  const [options, setOptions] = useState( where() );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInvoices = async(options = {}, status = undefined) => {
    const { data, loading, error } = await client.query({
        query: getAllInvoicesQuery,
        variables: { options, status },
        fetchPolicy: 'no-cache',
      });
      if(!error){
          setData(data?.invoices);
          setLoading(false);
      }
  }

  const getBills = async(options = {}, status = undefined) => {
    const { data, loading, error } = await client.query({
        query: getAllBillsQuery,
        variables: { options, status },
        fetchPolicy: 'no-cache',
      });
      if(!error){
          setData(data?.bills);
          setLoading(false);
      }
  }

  const [present] = useIonAlert();

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if(selectedOption){
        setStatus(selectedOption.value);
        if(customerId)getInvoices(options, selectedOption.value);
        if(vendorId)getBills(options, selectedOption.value);
        console.log('handleChange');
    } else {
      if(customerId)getInvoices(options, undefined);
      if(vendorId)getBills(options, undefined);

    }
  };

  useEffect(() => {
      console.log('enter')
      if(customerId)getInvoices(options, status);
      if(vendorId)getBills(options, status);
  }, [showModal])


  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => goToInvoicePage(data.id)} icon={eyeOutline} caption="Ver detalle" />
      <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
      <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" />
    </>
  )
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />)
  
  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Anular factura',
      subHeader: 'Esta factura se anulará permanentemente',
      message: '¿Esta seguro de anular a esta factura?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Anular factura',
          cssClass: 'delete',
          handler: () => {
            console.log(id);
            deleteInvoice({ variables: { input: id } });
          }
        }
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
    if (dError) {
      console.log(dError);
    }
  }
  const handleUpdate = (id) => {
    console.log(id);
    if(customerId) setInvoiceId(id);
    if(vendorId) setBillId(id);
    setShowModal(true);
  }


  const goToInvoicePage = (id) => {
   if(customerId) history.push(`/invoices/${id}`);
   if(vendorId) history.push(`/expenses/${id}`);
  }

  const columns = [
    {
      id: 1,
      field: 'status',
      label: 'Estado',
      width: '100px',
      cellRenderer: ({ value }) => (
        <IonNote className={`w-full text-center ${value} with-bg`}>{isEmpty(statusName[value])}</IonNote>
      )
    },
    {
      id: 2,
      field: 'number',
      label: 'No. Factura',
      width: '120px',
      getValue: ({ value }) => isEmpty(value)

    },
    {
      id: 3,
      field: 'date',
      label: 'Fecha creación',
      width: '135px',
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    },
    {
      id: 5,
      field: 'dueDate',
      label: 'Fecha límite',
      width: '140px',
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    },
    {
      id: 6,
      field: 'total',
      label: 'Monto',
      width: 'max-content',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
      cellRenderer: ({ data }) => {
        return(
        <div className="text-right w-full m-4">
        <p>{formatMoney(data, data.total)}</p>
        {(data.total !== data.convertedTotal) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedTotal)}</IonNote>}
        </div>)
      }
    },
    {
      id: 7,
      field: 'remaining',
      label: 'Saldo',
      width: 'max-content',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Saldo</div>),
      cellRenderer: ({ data }) => {
        return(
        <div className="text-right w-full m-4">
        <p>{formatMoney(data, data.remaining)}</p>
        {(data.remaining !== data.convertedRemaining) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedRemaining)}</IonNote>}
        </div>)
      }
    },

  ];

  const filterDate = (e) => {
    let from = date, to = dueDate
    if (e.target.name == 'date') {
      setDate(e.target.value)
      from = e.target.value
    } else {
      setDueDate(e.target.value)
      to = e.target.value
    }
    let where = {
      ...options.where,
      date: {
        _between: [from, to]
      }
    }
    setOptions({ ...options, where });
    if(customerId)getInvoices({ ...options, where }, status);
    if(vendorId)getBills({ ...options, where }, status);
  }
  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })

  return (
    <>
        <section className="flex flex-col sm:flex-row justify-between mb-4 items-end" >

          <div className="sm:w-80 w-full">
            <p className="text-sm mb-2 hover:text-secondary w-80" >Estado</p>
            <Select
              isClearable={true}
              options={documentStatus}
              value={selectedOption}
              onChange={handleChange}
              placeholder="Seleccione un estado"
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
            />
          </div>


          <InputDate label="Desde" value={date} handleInputChange={e => filterDate(e)} name="date" icon={false} />
          <InputDate label="Hasta" value={dueDate} handleInputChange={e => filterDate(e)} name="dueDate" icon={false} />

          <IonButton disabled={['READ'].includes(user?.role?.permissions['expenses']) || ['READ'].includes(user?.role?.permissions['sales'])} className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" shape="round" onClick={() => setShowModal(true)}>
            <IonIcon slot="start" icon={addCircleOutline} />
              Agregar factura
          </IonButton>
        </section>

        <GridTable
          className={data?.rows?.length ? 'mt-10 sfn-data-table h-screen' : 'max-h-80'}
          isLoading={loading}
          columns={columns}
          rows={data?.rows}
          components={{
            NoResults: () => (
              <Placeholder icon={receiptOutline} description='Aún no tienes facturas agregados haz clic en el botón "Agregar factura"' />
            )
          }}
          {...tableProps(data?.rows?.length ? true : false)}
          texts={texts} 
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          onRowClick={({data}) => { 
           goToInvoicePage(data.id);}}
        />
        {(customerId && showModal) && <InvoiceModal invoiceId={invoiceId} setInvoiceId={setInvoiceId} showModal={showModal} setShowModal={setShowModal}></InvoiceModal>}
        {(vendorId && showModal) && <ExpenseModal billId={billId} setBillId={setBillId} showExpenseModal={showModal} setShowExpenseModal={setShowModal} />}
        {showPaymentRecordModal && <PaymentRecordModal invoiceId={invoiceId} setInvoiceId={setInvoiceId} showPaymentRecordModal={showPaymentRecordModal} setShowPaymentRecordModal={setShowPaymentRecordModal}></PaymentRecordModal>}
  </>

  );
};



export default InvoicesTab
