import gql from 'graphql-tag';

export const getSubscription = gql`
  query {
    subscription {
      plan {
        id
        name
      }
      billingData {
        firstName
        lastName
        phone {
          type
          areaCode
          number
        }
        email
        nit
        address
        countryISO2
        city
        state
        postalCode
      }
      card {
        enterprise
        number
      }
      dtes
    }
  }
`;

export const paymentHistory = gql`
  query {
    paymentHistory {
      total
      date
      status
      invoiceId
    }
  }
`;
