import { IonContent, IonPage, IonIcon, useIonAlert, useIonToast, IonAlert } from "@ionic/react";
import { createOutline, eyeOutline, flagOutline, personOutline, trashOutline } from "ionicons/icons";
import Kpi from "../../components/kpi/Kpi";
import { useApolloClient } from '@apollo/client';
import { getOneCustomerQuery } from "../../graphql/customer/queries";
import { getOneVendorQuery } from "../../graphql/vendor/queries";
import { dateTimeFormat, parseDate } from '../../helpers/date'
import { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import './CustomerPage.scss';
import Tabs from "../../components/tabs/Tabs";
import GeneralTab from "./tabs/general-tab/GeneralTab";
import InvoicesTab from './tabs/invoices-tab/InvoicesTab'
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import { useCustomersService } from "../../graphql/customer/service";
import { useHistory } from "react-router";
import CustomerModal from "../../components/modals/customer-modal/CustomerModal";
import CustomerDetailModal from "../../components/modals/customer-detail-modal/CustomerDetailModal";
import { useVendorsService } from "../../graphql/vendor/service";
import VendorModal from "../../components/modals/vendor-modal/VendorModal";
import { useAuth } from "../../context/auth/AuthState";
import { AuthStore } from "../../store/auth";

const CustomerPage = ({ match }) => {
  const { params } = match;
  const {authState} = useAuth();
  const user = AuthStore.useState(s => s.user)

  const history = useHistory();
  const client = useApolloClient();
  const [selected, setSelected] = useState('general');
  const [present] = useIonAlert();
  const [showAlert, setShowAlert] = useState(false);
  const { deleteCustomer, dError: dcError, dLoading: dcLoading } = useCustomersService();
  const { deleteVendor, dError: dvError, dLoading: dvLoading } = useVendorsService();
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [customerId, setCustomerId] = useState(params.customerId || null);
  const [vendorId, setVendorId] = useState(params.vendorId || null);
  const [presentToast] = useIonToast();

  const getCustomer = async () => {
    const { data, loading, error } = await client.query({ query: getOneCustomerQuery, variables: { input: params?.customerId, options: { where: { remaining: { _gt: 0 }, deletedAt: null } } }, fetchPolicy: 'network-only', skip: !params?.customerId });
    if (!error) {
      setData(data?.customer);
      setloading(false)
    }
  }

  const getVendor = async () => {
    const { data, loading, error } = await client.query({ query: getOneVendorQuery, variables: { input: params?.vendorId, options: { where: { remaining: { _gt: 0 } } } }, fetchPolicy: 'network-only', skip: !params?.vendorId });
    if (!error) {
      setData(data?.vendor);
      setloading(false)
    }
  }

  useEffect(() => {
    if (!showModal) {
      if (params?.customerId) setCustomerId(params?.customerId);
      if (params?.vendorId) setVendorId(params?.vendorId);
      if (params?.vendorId) getVendor();
      if (params?.customerId) getCustomer();

      setSelected('general');
    }
  }, [match, showModal])

  let tabs = [
    { title: "Facturas pendientes de pago", value: 'general', },
    { title: "Todas las facturas", value: 'invoices', }
  ]

  const breads = [{
    title: customerId ? 'Clientes' : 'Proveedores',
    url: customerId ? '/customers' : '/vendors',
  }, {
    title: data?.name,
  }];

  const ItemsPopover = ({ onHide }) => {
    return (
      <>
        <OptionItem onHide={onHide} event={handleViewDetails} icon={eyeOutline} caption="Ver más información" />
        {!(['READ'].includes(user?.role?.permissions['sales']) || ['READ'].includes(user?.role?.permissions['expenses'])) &&
          <>
            <OptionItem onHide={onHide} event={handleUpdate} icon={createOutline} caption="Editar" />
            <OptionItem onHide={onHide} event={handleDelete} icon={trashOutline} caption="Eliminar" className="danger" />
          </>}
      </>)
  }
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);

  const handleViewDetails = () => {
    if (params.customerId) setCustomerId(params.customerId);
    if (params.vendorId) setVendorId(params.vendorId);
    setShowDetailModal(true);
  }

  const handleDelete = () => {
    present({
      cssClass: 'sfn-alert-dialog',
      backdropDismiss: false,
      mode: 'md',
      header: `Eliminar ${customerId ? 'cliente' : 'proveedor'}`,
      subHeader: `Este ${customerId ? 'cliente' : 'proveedor'} se eliminará permanentemente`,
      message: `¿Esta seguro de eliminar a este ${customerId ? 'cliente' : 'proveedor'}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: `Eliminar ${customerId ? 'cliente' : 'proveedor'}`,
          cssClass: 'delete',
          handler: async () => {
            let resp;
            if (customerId) resp = await deleteCustomer({ variables: { input: params.customerId } });
            if (vendorId) resp = await deleteVendor({ variables: { input: params.vendorId } });
            if (resp.errors) {
              showErrorAlert(resp.errors)
            } else {
              presentToast({ message: 'El cliente se ha eliminado correctamente 😀', color: "success", mode: "ios", duration: 4000 });
              history.goBack();
            }
          }
        }
      ],
    })
  }

  const showErrorAlert = (errors) => {
    const { extensions } = errors[0];
    if (extensions?.code === "LOCKED") {
      present({
        cssClass: 'sfn-alert-dialog',
        mode: 'md',
        header: 'Oops! ha ocurrido un problema',
        subHeader: `No podemos eliminar este ${customerId ? 'cliente' : 'proveedor'} porque está asociado con lo siguiente:
         ${customerId ?
            extensions?.invoices?.rows?.map(invoice => {
              return `\n• Factura ${invoice?.number}`
            })
            :
            extensions?.bills?.rows?.map(bill => {
              return `\n• Factura ${bill?.number}`
            })
          }
         `,
        message: `Mostrando ${customerId ? extensions?.invoices?.rows?.length : extensions?.bills?.rows?.length}/${customerId ? extensions?.invoices?.length : extensions?.bills?.length} Para eliminar este ${customerId ? 'cliente' : 'proveedor'}, elimínelo de los elementos enumerados anteriormente`,
        buttons: [
          {
            text: 'Cerrar',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      })
    } else {
      presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
    }
  }

  const handleUpdate = () => {
    if (params.customerId) setCustomerId(params.customerId);
    if (params.vendorId) setVendorId(params.vendorId);
    setShowModal(true);
  }

  const CustomerHeader = () => (
    <div className="shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white" >
      <div style={{ position: 'absolute', right: '32px' }} className="flex md:hidden">
        <Options />
      </div>
      <div className="flex w-full justify-between items-center flex-col md:flex-row text-center md:text-left">
        <div className="flex">
          <div className="bg-gray-blue rounded-full p-4 w-16 h-16 hidden sm:flex justify-center items-center mb-4" >
            <IonIcon color="light" size="large" icon={personOutline} />
          </div>
          <div className="flex flex-col sm:ml-4 justify-center">
            <h3 className="font-bold text-3xl text-gray-blue">{data?.name}</h3>
            <p className="mt-1 text-base text-gray-blue">{params.customerId ? 'Cliente' : 'Proveedor'} desde {parseDate(data?.createdAt).replace(/\//g, '-')}</p>
          </div>
        </div>
        {/* <Kpi icon={flagOutline} className="md:mt-0 mt-12"  title="Q. 10,000.00" description="Valor de vida del cliente (CLV)" /> */}
        <div className="hidden md:flex">
          <Options />
        </div>
      </div>

    </div>
  )

  return (
    <IonPage>
      <Header title={`Detalle de ${params.customerId ? 'Cliente' : 'Proveedor'}`} subtitle="" />
      <IonContent className="sfn-content">
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />
        <CustomerHeader />
        <div className="mt-16">
          <div className="border-b-2 border-gray-white">
            <Tabs className="w-full text-left justify-start md:flex-row flex-col" tabs={tabs} selected={selected} setSelected={setSelected} />
          </div>
          {selected === 'general' && <GeneralTab isCustomer={customerId} data={data} loading={loading} />}
          {selected === 'invoices' && <InvoicesTab customerId={params.customerId} vendorId={params.vendorId} />}
        </div>
        {(customerId && showModal) && <CustomerModal showModal={showModal} setShowModal={setShowModal} customerId={customerId}></CustomerModal>}
        {(vendorId && showModal) && <VendorModal showModal={showModal} setShowModal={setShowModal} vendorId={vendorId} />}

        {showDetailModal && <CustomerDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} customerId={customerId} vendorId={vendorId} />}
      </IonContent>
    </IonPage>
  )
}

export default CustomerPage;