import { useQuery } from '@apollo/client';
import { IonSkeletonText, useIonViewDidEnter } from '@ionic/react';
import { pieChartOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Placeholder from '../../../components/placeholder/Placeholder';
import { getExpenseChartQuery } from '../../../graphql/dashboard/queries';
import { expenseChartData } from '../chart';

const CostsExpenses = ({ options, enterprise }) => {
    const [data, setData] = useState(null);
    const { data: expenses, loading, error, refetch } = useQuery(getExpenseChartQuery, {
        fetchPolicy: 'no-cache',
        async onCompleted(data) {
            let { expenseChart } = data;
            if (expenseChart.length) {
                expenseChartData.labels = expenseChart.map(e => e.label)
                expenseChartData.datasets[0].data = expenseChart.map(e => e.value)
                setData(expenseChartData);
            }
        }
    });

    const pieOptions = options || {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: 'black'
            },
            labels: [
                {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside'
                },
                {
                    render: 'percentage',
                    fontColor: ['green', 'white', 'red'],
                    precision: 2
                }
            ]
        }
    }


    useIonViewDidEnter(() => {
        refetch();
    });


    return (
        <>
            <div className="flex flex-col md:flex-row my-12 items-start justify-between w-full">
                <div className="flex flex-col items-start w-full md:w-1/2 mr-12">
                    <h3 className="text-2xl font-bold text-gray-blue" >Desglose de costos y gastos</h3>
                    <p className="text-base mt-4">Detalle de costos y gastos de tu empresa.</p>
                </div>
                <div className="flex flex-col items-start justify-start w-full md:w-1/2">
                    {loading && <IonSkeletonText animated style={{ width: '100%', height: '400px' }} />}
                    {!loading && data !== null && <Doughnut height={300} data={data} options={pieOptions} />}
                    {!loading && data === null && <Placeholder icon={pieChartOutline} description='Aún no tienes costos o gastos' />}
                </div>
            </div>
        </>
    );
}

export default CostsExpenses;