import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonCheckbox, IonTextarea, IonContent, useIonToast } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import Select from 'react-select';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { useQuery } from '@apollo/client';
import { getAllTaxesQuery } from '../../../graphql/tax/queries';
import { formatSelectData } from '../../../helpers/utils';

import { productIncomeAccountsQuery, productExpenseAccountsQuery } from '../../../graphql/account/queries'
import { getOneProductQuery } from '../../../graphql/product/queries';
import { useProductsService } from '../../../graphql/product/service';
import SfnButton from '../../button/Button';
import { ListNoOptionsMessage, MenuListButton } from '../../select-menu-components/SelectMenuComponents';
import TaxModal from '../add-tax-modal/TaxModal';
import { Tooltip } from '../../tooltip/Tooltip';
import { _productTypes } from '../../../helpers/catalogs';

const initialState = {
    name: "",
    price: "",
    sku: "",
    description: "",
    taxes: [], //ids
    incomeAccountId: null, //id
    expenseAccountId: null, //id  
    type: {label: 'Bien', value: 'ASSET'},
}

const ProductModal = ({ showModal, setShowModal, productId = null, setProductId = () => { }, afterCreate  = () => {}, position = null}) => {

    const { formValues, handleInputChange, reset, numberHandleInputChange } = useForm({ ...initialState });
    const { name, price, sku, description, taxes, incomeAccountId, expenseAccountId, type } = formValues;
    const [sellChecked, setSellChecked] = useState(false);
    const [buyChecked, setBuyChecked] = useState(false);
    const { createProduct, cLoading, cError, updateProduct, uLoading, uError } = useProductsService();
    const [present] = useIonToast();
    const [calculatedTax, setCalculatedTax] = useState(0);
    const [showTaxModal, setShowTaxModal] = useState(false);

    const { data: allTaxes, loading: taxesLoading, error: taxesError, refetch: refetchTaxes } = useQuery(getAllTaxesQuery);
    const { data: incomes, loading: incomesLoading, error: incomesError } = useQuery(productIncomeAccountsQuery);
    const { data: expenses, loading: expensesLoading, error: expensesError } = useQuery(productExpenseAccountsQuery);

    const { loading, data } = useQuery(getOneProductQuery, {
        variables: { input: productId },
        skip: !productId,
        fetchPolicy: "no-cache"
    });


    useEffect(() => {
        if (productId && !loading && data) {
            if (data.product.incomeAccountId) setSellChecked(true);
            if (data.product.expenseAccountId) setBuyChecked(true);

            reset({...data.product, type: _productTypes?.find(f => f.value == data?.product?.type)});
        }
    }, [data])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const input = {...formValues, type: type?.value ?? 'ASSET'}
            if (productId) {
                let tmp = {...input};
                delete tmp.numberOfInvoices
                delete tmp.numberOfBills
    
                await updateProduct({ variables: { productId, input: tmp }, })
            } else {
                let {data, errors} =await createProduct({ variables: { input } });
                if(data && !errors) {
                    afterCreate(position, {...data?.createProduct})
                }
            }

            if (!uLoading && !cLoading) {
                if (cError || uError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 😑', color: "danger", mode: "md", duration: 4000 });
                    return
                }
                present({ message: `El producto se ${productId ? 'actualizó' : 'creó'} exitosamente! 🎉`, color: "success", mode: "md", duration: 4000 })
                onClose();
                console.log(formValues);
            }
        }
    }

    const validateForm = () => {
        if (!sellChecked && !buyChecked) {
            present({ message: 'Debes seleccionar si el producto/servicio se venderá o comprará', color: "warning", mode: "md", duration: 4000 });
            return false;
        }

        if (sellChecked && !formValues.incomeAccountId) {
            present({ message: 'Debes seleccionar una categoría de ingreso 😮', color: "warning", mode: "md", duration: 4000 });
            return false;
        }
        if (buyChecked && !formValues.expenseAccountId) {
            present({ message: 'Debes seleccionar una categoría de egreso 😮', color: "warning", mode: "md", duration: 4000 });
            return false;
        }
        formValues.incomeAccountId = formValues?.incomeAccountId?.value || null;
        formValues.expenseAccountId = formValues?.expenseAccountId?.value || null;
        let taxesId = [];
        formValues.taxes.forEach(tax => {
            taxesId.push(tax.value);
        })
        formValues.taxes = taxesId;
        formValues.price = parseFloat(formValues.price);
        return true;
    }

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
        setProductId(null);
    }

    const handleCheckboxChance = (value, box) => {
        switch (box) {
            case 'sell':
                setSellChecked(value);
                if (!value) {
                    reset({
                        ...formValues,
                        incomeAccountId: null
                    })
                }
                break;
            case 'buy':
                setBuyChecked(value);
                if (!value) {
                    reset({
                        ...formValues,
                        expenseAccountId: null
                    })
                }
                break;
            default:
                break;
        }
    }

    const getTax = () => {
        let taxes = 0;

        if (formValues.taxes) {
            formValues.taxes.forEach(tax => {
                console.log(tax);
                let curentTax = allTaxes.taxes.rows.find(t => t.id === tax.value);
                taxes = taxes + (parseInt(price) * curentTax.rate);
            })
        }
        setCalculatedTax(taxes);
        return taxes;
    }

    const fetchTaxes = async () => {
        refetchTaxes();
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-xl font-bold">{productId ? 'Editar' : 'Nuevo'} Producto/Servicio</h3>
                    <p className="text-center">Completa el siguiente formulario para {productId ? 'editar el' : 'crear un nuevo'} Producto/Servicio</p>

                    <form onSubmit={handleSubmit} className="h-full p-2">

                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">Nombre producto / servicio<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput
                                name="name"
                                placeholder="Indica nombre de producto o servicio"
                                type="text"
                                onIonChange={handleInputChange}
                                value={name}
                                required />
                        </IonItem>

                        <div className="sfn-input flex flex-col" mode="md" lines="none">
                            <div position="stacked" className="flex items-center text-sm mt-4 mb-2">
                                <p className="mr-1">Precio<span className="text-base text-danger">*</span></p>
                                <Tooltip label="Este es el precio determinado para tu producto. Puedes modificarlo en tus facturas de ventas cuando sea requerido." />
                            </div>
                            <IonInput
                                name="price"
                                placeholder="0.00"
                                type="tel"
                                onKeyPress={numberHandleInputChange}
                                onChange={numberHandleInputChange}
                                onKeyUp={numberHandleInputChange}
                                onKeyDown={numberHandleInputChange}
                                value={price}
                                required />
                        </div>

                        <div className="sfn-input flex flex-col" mode="md" lines="none">
                            <div position="stacked" className="flex items-center text-sm mt-4 mb-2">
                                <p className="mr-1">Código de producto SKU (Opcional)</p>
                                <Tooltip label="Código interno que usas para identificar este producto o servicio dentro de tu empresa." />
                            </div>
                            <IonInput
                                name="sku"
                                placeholder="Indica código de este producto"
                                type="text"
                                onIonChange={handleInputChange}
                                value={sku} />
                        </div>
                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">Descripción</IonLabel>
                            <IonTextarea name="description" placeholder="Breve descripción..." onIonChange={handleInputChange} value={description}> </IonTextarea>
                        </IonItem>
                        <div className="my-4">
                            <p className="text-sm mb-2 hover:text-secondary">Tipo de producto</p>
                            <Select placeholder="Selecciona un tipo de producto" isLoading={expensesLoading} name="type" value={type} options={_productTypes} onChange={e => handleInputChange(e, 'type')} className="sfn-select z-10" styles={sfnSelectTheme} />
                        </div>
                        <div className="py-4">
                            <div className="my-4">
                                <div className="flex items-center">
                                    <IonCheckbox className="sfn-checkbox mr-2" disabled={productId && data?.product?.numberOfInvoices > 0} checked={sellChecked} onIonChange={e => handleCheckboxChance(e.detail.checked, 'sell')} />
                                    <IonLabel>Vender este producto {data?.product?.numberOfInvoices > 0 && <Tooltip className="ml-1 mt-1 text-sm" label={`Este producto está asociado a ${data?.product?.numberOfInvoices} factura${data?.product?.numberOfInvoices > 1 ? 's' : ''} de ventas, por lo que no puedes  deshabilitar esta opción.`} />}</IonLabel>
                                </div>
                                <p className="text-base text-gray-light">Permite agregar este producto / servicio en facturas.</p>
                            </div>
                            {sellChecked &&
                                <div className="my-4">
                                    <p className="text-sm mb-2 hover:text-secondary" >Categoria<span className="text-base text-danger">*</span> <Tooltip label="Cuenta contable en la que se registrarán los ingresos o egresos de este producto." /></p>
                                    <Select placeholder="Selecciona categoria de ingreso" isLoading={incomesLoading} name="incomeAccountId" value={incomeAccountId} options={incomes?.productAccounts || []} onChange={e => handleInputChange(e, 'incomeAccountId')} className="sfn-select z-20" styles={sfnSelectTheme} />
                                </div>}
                            <div className="my-4">
                                <div className="flex items-center">
                                    <IonCheckbox className="sfn-checkbox mr-2" disabled={productId && data?.product?.numberOfBills > 0 } checked={buyChecked} onIonChange={e => handleCheckboxChance(e.detail.checked, 'buy')} />
                                    <IonLabel>Comprar este producto {data?.product?.numberOfBills > 0 && <Tooltip className="ml-1 mt-1 text-sm" label={`Este producto está asociado a ${data?.product?.numberOfBills} factura${data?.product?.numberOfBills > 1 ? 's' : ''} de compras, por lo que no puedes  deshabilitar esta opción.`} />}</IonLabel>
                                </div>
                                <p className="text-base text-gray-light">Permite agregar este producto / servicio en gastos.</p>
                            </div>
                            {buyChecked &&
                                <div className="my-4">
                                    <p className="text-sm mb-2 hover:text-secondary" >Categoria<span className="text-base text-danger">*</span>  <Tooltip label="Cuenta contable en la que se registrarán los ingresos o egresos de este producto." /></p>
                                    <Select placeholder="Selecciona categoria de egreso" isLoading={expensesLoading} name="expenseAccountId" value={expenseAccountId} options={expenses?.productAccounts || []} onChange={e => handleInputChange(e, 'expenseAccountId')} className="sfn-select z-10" styles={sfnSelectTheme} />
                                </div>}
                        </div>
                        <div className="my-4">
                            <p className="text-sm mb-2 hover:text-secondary" >Impuesto  <Tooltip label="Se calculará sobre el precio del producto. Para agregar los impuestos de tu país ve a configuración." /></p>
                            <Select placeholder="Selecciona impuesto" name="taxes" isMulti isLoading={taxesLoading} value={taxes} options={formatSelectData({ value: 'id', label: 'abbreviation' }, allTaxes?.taxes?.rows || [])}
                                onChange={e => handleInputChange(e, 'taxes')}
                                className="sfn-select z-9"
                                styles={sfnSelectTheme}
                                components={
                                    {
                                        MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo", callback: setShowTaxModal }),
                                        NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay impuesto" })
                                    }
                                }
                            />
                        </div>
                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={() => setShowModal(false)}>
                                Cancelar
                            </IonButton>
                            <SfnButton label={productId ? 'Guardar cambios' : 'Guardar producto'} loading={cLoading || uLoading} />
                        </div>

                    </form>
                </Wrapper>
            </IonContent>
            {showTaxModal && <TaxModal showModal={showTaxModal} setShowModal={setShowTaxModal} eventsClose={fetchTaxes} />}

        </IonModal>

    )

}

export default ProductModal;