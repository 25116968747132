import { IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import ProfileAvatar from "../profile-avatar/ProfileAvatar"
import HelpHeader from "../help-header/HelpHeader"
import './Header.scss';
import { menuOutline } from "ionicons/icons";
import { MenuStore, handleMenu } from "../../store/menu";
import MenuOpenSvg from './menu.svg';
import { Banner } from "../banner/Banner";
import UpgradeMyPlanModal from "../modals/upgrade-my-plan-modal/UpgradeMyPlanModal";
import { MyPlanStore, setShowUpgradeMyPlanModal } from "../../store/myPlan";
import { AuthStore } from "../../store/auth";
const Header = ({ title, subtitle = "", children = undefined }) => {
    const myPlanStore = MyPlanStore.useState(s => s);
    const auth = AuthStore.useState(s => s.user);
    const menuStore = MenuStore.useState(s => s);

    if (document) {
        document.title = (title ? title + ' | Symfonia' : 'Symfonia') + ' :: Organiza las finanzas de tu negocio en un solo lugar 🤑';
    }

    const handleOpenMenu = () => {
        handleMenu()
    }
    return (
        // <IonHeader mode="md">
        <div className="sfn-header flex flex-col h-auto shadow">
            <div className="w-full flex py-8 pl-2 pr-4">

                <IonButtons slot="start">
                    <IonMenuButton color="primary" />
                </IonButtons>

                <div className="title pl-0 sm:pl-4">
                    <div className="flex items-center">
                        {/* <IonButton className=" mt-2 mr-2 w-18" fill="clear" shape="round" slot="icon-only" > */}
                        <IonIcon src={menuStore.isOpen ? MenuOpenSvg : menuOutline} onClick={handleOpenMenu} className="gridia-menu-button text-3xl mt-2 mr-2 hover:cursor-pointer text-primary" />
                        {/* </IonButton> */}
                        <h3 className="text-xl sm:text-3xl font-extrabold" >{title}</h3>
                    </div>
                    {subtitle && <p className="text-base sm:text-sm text-gray mt-4">{subtitle}</p>}
                </div>
                <IonButtons slot="end" className="items-center gap-4">
                    <HelpHeader />
                    <ProfileAvatar></ProfileAvatar>
                </IonButtons>
            </div>
            {/* Logic by hide if not expire plan */}
            {(!auth?.enterprise?.isEnabled && auth?.enterprise !== null ) && <Banner />}
            {myPlanStore.showModal && <UpgradeMyPlanModal showModal={myPlanStore.showModal} setShowModal={setShowUpgradeMyPlanModal} />}
            {children}
        </div >
        // </IonHeader>
    )
}

export default Header;