export const expenseChartData = {
  labels: [],
  datasets: [{
    label: 'Costos y gastos',
    data: [],
    backgroundColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderWidth: 1,
  }]
}

export const cashFlowChartData = {
  labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
  datasets: [
    {
      type: 'line',
      label: 'Ingresos netos',
      data: [100, 20, 50, 28, 20, 80, 90, 120],
      fill: false,
      backgroundColor: 'rgba(54, 162, 235, 1)',
      borderColor: 'rgba(54, 162, 235, 1)',
    },
    {
      type: 'bar',
      label: 'Ingresos',
      data: [90, 120, 80, 23],
      fill: false,
      backgroundColor: 'rgba(75, 192, 192, 1)',
      borderColor: 'rgba(75, 192, 192, 1)',
    },
    {
      type: 'bar',
      label: 'Gastos',
      data: [-90, -180, -78, -23],
      fill: false,
      backgroundColor: 'rgba(255, 99, 132, 1)',
      borderColor: 'rgba(255, 99, 132, 1)',
    }
  ],
};

export const profitLossChartData = {
  labels: [['Nov', '20'], ['Dic', '20'], ['Ene', '21'], ['Feb', '21'], ['Mar', '21'], ['Abr', '21'], ['May', '21'], ['Jun', '21'], ['Jul', '21']],
  datasets: [
    {
      label: 'Ingresos',
      data: [2100, 4200, 90, 203, 803, 30, 8000, 1200, 7023, 1230],
      backgroundColor: 'rgba(75, 192, 192, 1)',
      borderColor: 'rgba(75, 192, 192, 1)',
    },
    {
      label: 'Gastos',
      data: [8000, 300, 898, 231, 82, 129, 800, 1230, 4800, 8000],
      backgroundColor: 'rgba(255, 99, 132, 1)',
      borderColor: 'rgba(255, 99, 132, 1)',
    }
  ],
}

export const indicators = [{
  value: 'ALL',
  label: 'Todos los indicadores',
}, {
  value: 'CURRENT_MONTH_INCOME',
  label: 'Ingresos mes actual ',
}, {
  value: 'CASH_FLOW',
  label: 'Flujo de caja',
}, {
  value: 'PROFIT_LOSS',
  label: ' Ganancias y perdidas',
}, {
  value: 'COST_EXPENSE',
  label: 'Desglose de costos y gastos',
}, {
  value: 'INVOICE',
  label: 'Cuentas por cobrar y pagar',
}, {
  value: 'NET_INCOME',
  label: 'Ingresos netos',
}]

export const yearStatus =[{
  label: 'Últimos 12 meses',
  value: 'LAST_YEAR'
}, {
  label: 'Últimos 24 meses',
  value: 'LAST_TWO_YEARS'
}];