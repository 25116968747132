import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

const usePageTracking = () => {
    const l = useLocation();
    //console.log(l)

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (initialized) {
            ReactPixel.pageView();
            // console.log('changed')
            // ReactGA.pageview(l.pathname + l.search);
            ReactGA.send({ hitType: "pageview", page: l.pathname + l.search });

        } else {
            // if (!window.location.href.includes("localhost")) {
                console.log('initialized')
                ReactPixel.init('630742988257332');
                ReactGA.initialize("G-63VRWKXK1C",{
                    // debug: true,
                    // testMode: true
                });
            // }
            setInitialized(true);
        }
    }, [initialized, l]);
};

export default usePageTracking;
