export const texts = {
    search: '',
    totalRows: 'Total filas:',
    rows: 'Filas:',
    selected: 'Selección',
    rowsPerPage: 'Filas por página:',
    page: 'Página:',
    of: 'de',
    prev: 'Ant',
    next: 'Sig',
    columnVisibility: 'Columnas'
}