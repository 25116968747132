import { IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import { shortMonths, months } from './helper';
import './InputDatePayroll.scss';
const InputDatePayroll = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  options,
  required,
  className = 'w-full'
}) => {
  const ref = useRef();
  const [isSelected, setIsSelected] = useState(!value ? true : false)
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState([])
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });


  const onMonth = (index) => {
    setMonth(index);
    onChange(`1/${index+1}/${year}`, name);
    setIsOpen(false);
  }

  const handleKey = (e) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || [37, 39, 8, 46].includes(e.keyCode)) {
      return
    }
    e.preventDefault()
  }


  const check = (e) => {
		let val = parseInt(e.target.innerText)
		if (Number.isNaN(val) || val < 1900 || val > 3000) {
			e.target.innerText = year
		} else {
			setYear(val);
    onChange(`1/${month+1}/${val}`, name);
      
		}
	}

  const substract = () => {
    const y = year-1;
    setYear(y);
    onChange(`1/${month+1}/${y}`, name);
  }
  const add = () => {
    const y = year+1;
    setYear(y);
    onChange(`1/${month+1}/${y}`, name);
  }

  useEffect(() => {
    if(!value || value === null) {
      let date = new Date();
      setYear(date.getFullYear());
      setMonth(date.getMonth());
      onChange(`1/${date.getMonth()+1}/${date.getFullYear()}`, name);
    }else {
      let date = new Date(value);
      setYear(date.getFullYear());
      setMonth(date.getMonth());
      onChange(`1/${date.getMonth()+1}/${date.getFullYear()}`, name);
    }
  }, [])


  return (
    <div ref={ref} id={name} className={`input-date-payroll ${className}`}>
      <IonItem className='sfn-input' mode='md' lines='none'>
       {label &&  <IonLabel position='stacked'>{label}{required && <span className="text-base text-danger">*</span>}</IonLabel>}
        <IonInput
          readonly={true}
          onClick={() => (setIsOpen(!isOpen))}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          required={required}
        ></IonInput>
      </IonItem>

      {isOpen ? (
        <div className="list w-full h-auto overflow-y-auto p-4 shadow-md">
          <div className="input-date-header flex justify-between items-center my-4">
            <IonIcon className="text-xl cursor-pointer" onClick={substract} icon={chevronBackOutline} />
            <div className="title items-center flex sm:flex-row flex-col mx-2">
              <p className="sm:text-xl text-base cursor-auto">{months[month] || ''}</p>
              <p className="sm:text-xl text-base cursor-auto mx-2 md:flex hidden">{year ? '-' : 'Año'}</p>
              <p className="sm:text-xl text-base cursor-auto" suppressContentEditableWarning={true} contentEditable={true}
                onKeyDown={handleKey} onBlur={check}>{year || ''}</p>
            </div>
            <IonIcon className="text-xl cursor-pointer" onClick={add} icon={chevronForwardOutline} />
          </div>
          <div className="month-picker">
            {shortMonths.map((m, index) => (
              <span key={index} onClick={() => (onMonth(index))} className={`month-element ${index == month ? 'selected' : ''}`}> {m} </span>
            ))}
          </div>
        </div>
      ) : ''}
    </div>
  );
};

export default InputDatePayroll;
