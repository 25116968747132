import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonMenu,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  chevronDownOutline,
  chevronUpOutline,
  businessOutline,
} from "ionicons/icons";

import "./Menu.scss";
import { InitialAppPages } from "./appPages";
import { useAuth } from "../../context/auth/AuthState";
import { ReactComponent as IsotipoSvg } from './isotipo.svg';
import { MenuStore, handleMenu } from "../../store/menu";
import useOutsideClick, { useOutsideOver } from "../../hooks/useOutsideClick"
import { useMediaQuery } from 'react-responsive';
import { AuthStore } from "../../store/auth";


const Menu = () => {
  const authStore = AuthStore.useState(s => s)
  const isOpen = MenuStore.useState(s => s.isOpen);
  const menuStore = MenuStore.useState(s => s);
  const [appPage, setAppPage] = useState(null)
  const menuRef = useRef(null);
  const refContent = useRef(null);
  const refChildren = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { authState } = useAuth();
  const [appPages, setAppPages] = useState([...InitialAppPages.filter(p => authStore?.user?.role?.permissions[p.key] !== 'NONE')]);
  const { enterprise } = authState;
  const [positionChildren, setpositionChildren] = useState({ top: 0, left: 0 })
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });


  const handleSelect = (page) => {
    if (page.children) {
      const pages = [
        ...appPages.map((p) =>
          p.title === page.title
            ? { ...p, open: !page.open }
            : { ...p, open: false }
        ),
      ];
      setAppPages(pages);
      return;
    }
    const pages = [...appPages.map((page) => ({ ...page, open: false }))];
    setAppPages(pages);
    history.push(page.url);
    menuRef.current.close();
  };

  const validateModule = () => {
    const pages = InitialAppPages.filter(p => authStore?.user?.role?.permissions[p.key] !== 'NONE')
    setAppPages([...pages])
  }


  useEffect(() => {
    validateModule()
  }, [authState])

  useEffect(() => {
    validateModule()
  }, [])

  

  const stylesMenu = {
    open: {
      '--width-menu': '240px',
      '--logo-display': 'flex',
      '--isotipo-display': 'none',
      '--enterprise-display': 'flex',
      '--label-display': 'flex'
    },
    close: {
      '--width-menu': '80px',
      '--logo-display': 'none',
      '--isotipo-display': 'flex',
      '--enterprise-display': 'none',
      '--label-display': 'none'
    }
  }


  useEffect(() => {
    setAppPage(null)
    setAppPages(InitialAppPages);
  }, [isOpen])

  function getOffset(el) {
    const rect = el?.target?.getBoundingClientRect();
    let top = rect.top - el.target.offsetTop == 0 ? el.target.offsetTop :  rect.top - el.target.offsetTop
    return {
      left: rect.left + window.scrollX,
      top: top 
    };
  }

  useOutsideClick(refChildren, (ref) => {
    !isOpen && setAppPage(null)
  })

  const handleMouseMove = e => {
    if (appPage != null && refChildren.current && !refChildren?.current?.contains(e.target) && menuRef.current && !menuRef?.current?.contains(e.target)) {
      !isOpen && setAppPage(null)
      !isOpen && setpositionChildren({top: 0, left: 0})
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  });

  
  const guardEnterprise = ( ) => {
    if(authStore.user?.enterprises?.accepted == 0 && authStore.user?.enterprises?.pendings > 0) {
      return true
    }else if(authStore.user?.enterprises?.accepted > 0) {
      return false
    }
    return false
  }

  return (

    <>
      <IonMenu  contentId="menuContent" side="start" style={isOpen ? stylesMenu.open : stylesMenu.close}  type="overlay" ref={menuRef}>
        <IonContent>
          <div className="px-4 py-8  w-full h-36 min-h-36 max-h-36" >
            <div className="rounded flex flex-col justify-center items-center">
              <div className={`flex flex-row justify-between items-center mb-2  ${isMobile ? 'h-14' : 'h-auto'}`}>
                <img src="assets/img/isotipo.svg" className={`isotipo ${isMobile ? 'h-12 mr-1' : 'h-7'}`} alt="" />
                <img src="assets/img/symfonia.svg" className={`logo ${isOpen ||isMobile ? 'flex' : 'hidden'} ${isMobile ? 'h-8' : 'h-7'}`} alt="" />
              </div>
              <div className="line"> </div>
             {isOpen &&  <div className="enterprise flex items-center m-4 header-title tooltip">
                <IonIcon size="small" icon={businessOutline} className="mr-2" />
                <h3 title={enterprise?.name} className="text-sm overflow-ellipsis">{enterprise?.name}</h3>
              </div>}
            </div>
          </div>
          {appPages.map((page, index) => {
            return (
              <div key={index}>
                {appPages.length - 2 === index && (
                  <div
                    style={{ borderTop: "2px solid var(--input-background)" }}
                    className="mx-4 my-8"
                  ></div>
                )}
                <IonItem
                  disabled={guardEnterprise()}
                  onMouseOver={(e) => {
                    if (!isOpen && !isMobile ) {
                      setAppPage(page);
                      // handleSelect(page);
                      setpositionChildren(getOffset(e))
                    }
                  }}
                  onClick={(e) => {
                    handleSelect(page);
                    setAppPage(page);
                    // setpositionChildren(getOffset(e?.target))
                  }}
                  key={index}
                  className={location.pathname.includes(page.url) || (!isOpen && page?.children?.some(c => c.url == location.pathname)) ? "selected" : ""}
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    icon={
                      location.pathname.includes(page.url)
                        ? page.iconSolid
                        : page.icon
                    }
                  />
                  <IonLabel>{page.title}</IonLabel>
                  {page.children && (
                    <IonIcon
                      slot="end"
                      icon={page.open ? chevronUpOutline : chevronDownOutline}
                    />
                  )}
                </IonItem>
                <div style={{ background: "var(--input-background)" }}>
                  {((isOpen || isMobile) && page.open) &&
                    appPage.children?.map((children) => (
                      <div key={children.url} className="py-0.5">
                        <IonItem
                          key={children.title}
                          className={
                            location.pathname === children.url ? "selected" : ""
                          }
                          onClick={() => {
                            setAppPage(appPage);
                            handleSelect(appPage);
                          }}
                          routerLink={children.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          <IonIcon
                            slot="start"
                            icon={
                              location.pathname === children.url
                                ? children.iconSolid
                                : children.icon
                            }
                          />
                          <IonLabel>{children.title}</IonLabel>
                        </IonItem>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </IonContent>
      </IonMenu>

      {(!isOpen && !isMobile && appPage !== null) &&
        <div ref={refChildren} className="flex symfonia-sub-menu z-10 absolute " style={{ left: 80 + 'px', top: (positionChildren.top - 5) + 'px' }}>
          <div  className="symfonia-sub-content ml-3  bg-white p-4">
            <h2 className={`text-xl text-primary font-bold ${appPage.children ? 'ml-4 mb-4' : 'text-left ml-0'}`}>{appPage.title}</h2>
            {appPage.children?.map((children) => (
              <div key={children.url} className="py-0.5 bg-white">
                <IonItem
                  key={children.title}
                  className={
                    location.pathname === children.url ? "selected" : ""
                  }
                  onClick={() => {
                    setAppPage(children);
                    handleSelect(children);
                  }}
                  routerLink={children.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonLabel>{children.title}</IonLabel>
                </IonItem>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
};

export default Menu;
