import { useForm } from "../../../../../../hooks/useForm";
import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, useIonToast, IonCheckbox } from '@ionic/react';
import { closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import SfnButton from "../../../../../../components/button/Button";
import { Tooltip } from "../../../../../../components/tooltip/Tooltip";
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import Select from 'react-select';
import { sfnSelectTheme } from "../../../../../../theme/sfnSelectTheme";
import { generateYears } from "../../../../../../helpers/utils";
import { months } from "../../../../../../helpers/catalogs";
import { MyPlanStore, updateSubscription } from "../../../../../../store/myPlan";
import { useSubscriptionService } from "../../../../../../graphql/subscription/services";
import InputCardNumber from "../../../../../../components/input-card-number/InputCardNumber";
import { isValid, isExpirationDateValid, isSecurityCodeValid, getCreditCardNameByNumber } from 'creditcard.js';
import { getSubscription } from "../../../../../../graphql/subscription/queries";
import { getOneEnterpriseQuery } from "../../../../../../graphql/enterprise/queries";

const initialState = {
    planId: '',
    billingData: {
        firstName: '',
        lastName: '',
        phone: { type: '', areaCode: '', number: '' },
        email: '',
        nit: '',
        address: '',
        countryISO2: 'GT',
        city: '',
        state: '',
        postalCode: '',
    },
    card: {
        number: '',
        name: '',
        month: '',
        year: '',
        CVV: '',
        enterprise: ''
    }
}


const PaymentCardModal = ({ showModal, setShowModal, paymentId, setPaymentId = () => { } }) => {
    const { subscription } = MyPlanStore.useState(s => s);
    const { formValues, handleInputChange, reset, removeCharacter, validateMaxLengthInput } = useForm(initialState);
    const { card } = formValues;
    const [isConsumer, setIsConsumer] = useState(subscription?.billingData?.nit === 'C/F' ? true : false);
    const [showPostalCode, setShowPostalCode] = useState(false)
    const [present] = useIonToast();
    const { updateCreditCard, uCardLoading, uCardError } = useSubscriptionService();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (invalidForm()) return;
            let input = { ...formValues };
            input.card.month = card?.month?.value;
            input.card.year = card?.year?.value;
            input.card.number = card?.number?.replaceAll(' ', '');
            input.card.enterprise = getCreditCardNameByNumber(input.card.number)?.toUpperCase();
            let { data, errors } = await updateCreditCard({ variables: { input: input.card }, refetchQueries: [{ query: getSubscription }] });

            if (errors) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
                return
            } else {
                present({ message: 'Tu método de pago se actualizo, exitosamente!', color: "success", mode: "md", duration: 4000 });
                updateSubscription(JSON.parse(JSON.stringify(data?.updateCreditCard)));
            }
            e.target.reset();
            onClose();
        } catch (error) {
            console.log(error);
        }
    }

    function invalidForm() {
        const cards = ['visa', 'mastercard'];
        const cardNumber = card.number.replaceAll(' ', '');
        const typeCard = getCreditCardNameByNumber(cardNumber)?.toLowerCase();
        if (card?.month?.value === '' || card?.month == null || card?.month == '') {
            present({ message: `Debe seleccionar un mes en la cual expira tu tarjeta!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (card?.year?.value === '' || card?.year == null || card?.year == '') {
            present({ message: `Debe seleccionar un año en la cual expira tu tarjeta!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        // if (!isValid(cardNumber)) {
        //     present({ message: `Número de tarjeta incorrecta!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        // if (!cards.includes(typeCard)) {
        //     present({ message: `Symfonia te permite realizar pagos a través de tus tarjetas VISA y MASTERCARD!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        // if (!isExpirationDateValid(card?.month?.value, card?.year?.value)) {
        //     console.log(card?.month?.value, card?.year?.value)
        //     present({ message: `La fecha de expiración de la tarjeta es incorrecta!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        // if (!isSecurityCodeValid(cardNumber, card?.ccv)) {
        //     present({ message: `El código de seguridad de la tarjeta es incorrecta!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        return false
    }

    const onClose = async () => {
        setPaymentId(null);
        reset({ ...initialState });
        setShowModal(false);
    }
    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={() => setShowModal(false)}>
            <Wrapper>
                <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                <h3 className="text-center text-xl font-bold">{paymentId ? 'Editar' : 'Agregar'} tarjeta de pago</h3>
                <p className="text-center text-base">Indica los datos de tu tarjeta con la que harás el pago de tu plan en Symfonia</p>

                <form id='payment-form' onSubmit={handleSubmit} className="h-full p-2">
                    <IonItem className={`sfn-input mr-4 w-full`} mode="md" lines="none">
                        <IonLabel position="stacked">Nombre en tarjeta<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="card.name"
                            type="text"
                            placeholder="Indica el nombre en tu tarjeta"
                            onIonChange={handleInputChange}
                            value={card.name}
                            autocomplete="off"
                        > </IonInput>
                    </IonItem>
                    <div className="flex w-full justify-between md:flex-row flex-col">
                        <InputCardNumber klass="md:w-[50%] w-full" label="Número de tarjeta" placeholder="Indica número de tarjeta" name="card.number" type="text" value={card.number} onChange={handleInputChange} required autocomplete="off" />
                        <IonItem className={`sfn-input md:w-[50%] md:ml-4 ml-0  w-full`} mode="md" lines="none">
                            <div className="flex flex-row items-center align-center">
                                <IonLabel className="mb-4 mr-1" position="stacked">CVC<span className="text-base text-danger" >*</span></IonLabel>
                                <div className="mt-2 text-base">
                                    <Tooltip label="Número de 4 dígitos que se encuentra en el reverso de tu tarjeta." />
                                </div>
                            </div>
                            <IonInput
                                name="card.CVV"
                                type="number"
                                placeholder="0000"
                                maxlength="4"
                                onInput={validateMaxLengthInput}
                                onIonChange={validateMaxLengthInput}
                                value={card.CVV}
                                required
                                autocomplete="off"
                            > </IonInput>
                        </IonItem>
                    </div>
                    <div className="flex md:flex-row flex-col w-full mt-3 items-center">
                        <div className="w-full">
                            <p className="text-sm h-[36px] flex items-center hover:text-secondary w-80">Expira<span className="text-base text-danger">*</span></p>
                            <div className="flex md:flex-row flex-col">
                                <Select
                                    placeholder="Selecciona mes"
                                    value={card.month}
                                    onChange={(e) => handleInputChange(e, 'card.month')}
                                    options={months}
                                    className="sfn-select md:w-[50%] w-full md:mr-4 mr-0 md:mb-0 mb-4"
                                    styles={sfnSelectTheme}
                                />
                                <Select
                                    placeholder="Selecciona el año"
                                    value={card.year}
                                    onChange={(e) => handleInputChange(e, 'card.year')}
                                    options={generateYears(new Date(), 10)}
                                    className="sfn-select md:w-[50%] w-full  md:mb-0 mb-4"
                                    styles={sfnSelectTheme}
                                />
                            </div>
                        </div>
                    </div>


                    <p className="text-gray-2 mt-8">Autoriza a Symfonia a debitar los pagos de esta tarjeta enlazada a la cuenta de la institución bancaria que la extiende.</p>
                    {/* <div className="py-4">
                        <div className="my-4">
                            <div className="flex items-center pb-8">
                                <IonCheckbox className="sfn-checkbox mr-2" checked={defaulted} onIonChange={e => handleInputChange(e.detail.checked, 'defaulted')} />
                                <IonLabel>Tarjeta predeterminada</IonLabel>
                            </div>
                            <p className="text-base text-gray-light">Autorizo a Symfonia a debitar los pagos de esta tarjeta enlazada a la cuenta de la institución bancaria que la extiende, siempre y cuando este marcada como predeterminada para realizar los pagos de esta aplicación.</p>
                        </div>
                    </div> */}

                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                            Cancelar
                        </IonButton>
                        <SfnButton form="payment-form" label="Guardar cambios" loading={uCardLoading} />
                    </div>

                </form>
            </Wrapper>
        </IonModal>
    );
}

export default PaymentCardModal;