import { IonButton, useIonViewDidEnter } from '@ionic/react';
import { flagOutline, pieChartOutline } from 'ionicons/icons';
import { Bar } from 'react-chartjs-2';
import Placeholder from '../../../components/placeholder/Placeholder';
import Kpi from '../../../components/kpi/Kpi';
import Toggle from '../../../components/toggle/Toggle';
import { useState } from 'react';
import { getNetIncomeQuery } from '../../../graphql/dashboard/queries';
import { useQuery } from '@apollo/client';
import { formatMoney } from '../../../helpers/utils';
import { Tooltip } from '../../../components/tooltip/Tooltip';

const CurrentMonthIncome = ({ options, enterprise }) => {

    const { data, loading, error, refetch } = useQuery(getNetIncomeQuery, { fetchPolicy: 'no-cache',});

    useIonViewDidEnter(() => {
        refetch();
    });

    return (
        <div className="flex flex-col my-12">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <div className="flex flex-col">
                    <h3 className="text-2xl font-bold text-gray-blue" >Ingreso mes actual <Tooltip className="text-base pb-4" label="Los montos son la suma de todas las ventas, gastos y transacciones del mes en curso." /></h3>
                    <p className="text-base mt-4">Total de ingresos netos de tu empresa.</p>
                </div>
                <div className="hidden md:flex items-center h-12 md:h-full md:my-0">
                    {/* <IonButton className="sfn-button sm:w-60 w-full text-primary normal-case font-bold" fill="none" shape="round" onClick={() => { }}>
                        Ver reporte
                    </IonButton> */}
                </div>
            </div>
            <div className="flex mt-12 flex-col md:flex-row items-start justify-start md:items-center md:justify-center">
                <div className="flex flex-row">
                    <Kpi loading={loading} title={formatMoney(enterprise, data?.netIncomeIndicators?.income?.value)} description={data?.netIncomeIndicators?.income?.label} />
                    <h3 className="text-xl md:text-3xl font-bold text-danger mx-8">-</h3>
                </div>
                <div className="flex flex-row">
                    <Kpi loading={loading} title={formatMoney(enterprise, data?.netIncomeIndicators?.expenses?.value)} description={data?.netIncomeIndicators?.expenses?.label} />
                    <h3 className="text-xl md:text-3xl font-bold mx-8">=</h3>
                </div>
                <Kpi loading={loading} title={formatMoney(enterprise, data?.netIncomeIndicators?.netIncome?.value)} description={data?.netIncomeIndicators?.netIncome?.label} />
            </div>
        </div>
    );
}

export default CurrentMonthIncome;