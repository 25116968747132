import { getAvatar, getBackground } from '../../helpers/utils';
import './Avatar.scss';

const Avatar = ({ user }) => {
    let text = getAvatar(user?.name)
    let background = getBackground(user?.id);

    return (<>
        <div className="sfn-user-avatar flex items-center justify-center rounded-full" style={{ background }}>
            {text}
        </div>
    </>)
}


export default Avatar;