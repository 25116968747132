import { IonIcon } from "@ionic/react";
import { cardOutline } from "ionicons/icons";

const Placeholder = ({ }) => {
    return (<div className="flex flex-col justify-center items-center mt-20">
        <div className="flex text-center items-center justify-center bg-secondary-hover w-[116px] h-[116px] mb-8 rounded-full">
            <IonIcon className="text-6xl text-secondary" icon={cardOutline} />
        </div>
        <h3 className="font-bold text-xl mb-4">Configura tus datos de facturación en SAT</h3>
        <p className="mb-4 text-sm text-gray">Asegúrate de haber realizado el proceso previo en SAT para poder emitir facturas electrónicas.</p>
        <a className="text-primary font-bold hover:cursor-pointer" href={`${process.env.REACT_APP_CONFIG_FEL_URL}`} target="_blank" >¿No sabes cómo? Ver video</a>
    </div>);
}

export default Placeholder;