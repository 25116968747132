import { IonButton, IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import { components } from 'react-select';
import './SelectMenu.scss';

export const MenuListButton = ({ children, label = "Agregar", callback = () => { }, disabled, ...rest}) => {
    return (
        <components.MenuList  {...rest}>
            {children}
            <button className="flex w-full h-12 items-center justify-center text-primary hover:bg-primary-hover" onClick={callback} disabled={disabled}>
                <IonIcon slot="start" color="primary" className="mr-2 w-6 h-6" icon={addCircleOutline} />
                {label}
            </button>
        </components.MenuList >
    )
}

export const ListNoOptionsMessage = ({ label = "No hay elementos", ...rest }) => {

    return (
        <components.NoOptionsMessage {...rest}>
            {label}
        </components.NoOptionsMessage>
    );
}


export const OptionCheckbox = props => {
    return (
        <div>
            <components.Option {...props}>
                <div className="flex sfn-checkbox-select ">
                    <label className="container">{props.label}
                        <input type="checkbox" checked={props.isSelected} onChange={e => null} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </components.Option>
        </div>
    );
};

export const MultiValue = props => (
    <components.MultiValue {...props}>
        <div className="flex sfn-checkbox-select ">
            <label className="container">{props.label}
                <input type="checkbox" checked={props.isSelected} onChange={e => null} />
                <span className="checkmark"></span>
            </label>
        </div>
    </components.MultiValue>
);

export const cardCustomer = props => {
    return (
        <components.Option {...props }>
            <div className="flex flex-col ">
                <h3>{props?.value?.name}</h3>
                <p className="text-sm text-gray mt-2">{props?.value?.bill?.nit}</p>
            </div>
        </components.Option >
    )
};



