export const sfnSelectTheme = {
  option: (provided, state) => ({
    ...provided,
    '&:hover': {
      background: state.isSelected
        ? ''
        : 'rgba(var(--ion-color-secondary-rgb), 0.14)',
      color: state.isSelected ? '' : 'var(--ion-color-secondary)',
      p: {
        color: state.isSelected ? '' : 'var(--ion-color-secondary)',
      },
    },
    color: state.isSelected ? '#ffffff' : 'var(--ion-color-dark)',
    p: {
      color: state.isSelected ? '#ffffff' : 'var(--ion-color-medium)',
    },
    background: state.isSelected ? 'var(--ion-color-secondary)' : '',
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: 'left',
    fontSize: 14,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--ion-color-secondary)',
    '&:hover': {
      color: 'var(--ion-color-secondary)',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    '&:hover': {
      borderColor: 'var(--ion-color-secondary)',
    },
    background: state.isFocused
      ? 'var(--input-background)'
      : 'var(--input-background-tint)',
    borderColor: state.isFocused
      ? 'var(--ion-color-secondary)'
      : 'var(--input-background)',
    boxShadow: 'none',
    fontSize: 14,
    minHeight: 44,
  }),
};

export const sfnSelectMultiTheme = {
  option: (provided, state) => ({
    ...provided,
    '&:hover': {
      background: state.isSelected
        ? ''
        : 'rgba(var(--ion-color-secondary-rgb), 0.14)',
      color: state.isSelected ? '' : 'var(--ion-color-secondary)',
    },
    color: state.isSelected ? '' : 'var(--ion-color-dark)',
    background: state.isSelected ? '#fff' : '',
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: 'left',
    fontSize: 14,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--ion-color-secondary)',
    '&:hover': {
      color: 'var(--ion-color-secondary)',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    '&:hover': {
      borderColor: 'var(--ion-color-secondary)',
    },
    background: state.isFocused
      ? 'var(--input-background)'
      : 'var(--input-background-tint)',
    borderColor: state.isFocused
      ? 'var(--ion-color-secondary)'
      : 'var(--input-background)',
    boxShadow: 'none',
    fontSize: 14,
    minHeight: 'fit-content',
    height: '40px',
    maxHeigth: '40px',
    scrollY: 'auto',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
  }),
};
