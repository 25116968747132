import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonNote,
  IonItem,
  IonLabel,
  IonInput,
  useIonAlert,
  useIonViewWillEnter,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import GridTable from "@nadavshaar/react-grid-table";
import Select from "react-select";
import { useHistory, useParams } from "react-router";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  addCircleOutline,
  arrowDownOutline,
  calendarClearOutline,
  create,
  createOutline,
  documentAttachOutline,
  downloadOutline,
  eye,
  eyeOutline,
  filterOutline,
  flagOutline,
  receiptOutline,
  timeOutline,
  trash,
  trashOutline,
} from "ionicons/icons";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import Header from "../../components/header/Header";
import OptionItem from "../../components/option-item/OptionItem";
import InvoiceModal from "../../components/modals/invoice-modal/InvoiceModal";
import Kpi from "../../components/kpi/Kpi";
import PaymentRecordModal from "../../components/modals/payment-record-modal/PaymentRecordModal";
import { sfnSelectTheme } from "../../theme/sfnSelectTheme";
import {
  downloadInvoice,
  getAllInvoicesQuery,
  invoiceIndicatorsQuery,
} from "../../graphql/invoice/queries";
import Placeholder from "../../components/placeholder/Placeholder";
import { getAllCustomersQuery } from "../../graphql/customer/queries";
import { formatMoney, formatSelectData, isEmpty } from "../../helpers/utils";
import InputDate from "../../components/input-date/InputDate";
import { useInvoicesService } from "../../graphql/invoice/service";
import { texts } from "../../theme/dataTabelTexts";
import {
  getDate,
  getRange,
  dateTimeFormat,
  parseDate,
} from "../../helpers/date";
import { documentStatus, statusName } from "../../helpers/catalogs";
import { useAuth } from "../../context/auth/AuthState";
import {
  ListNoOptionsMessage,
  MenuListButton,
} from "../../components/select-menu-components/SelectMenuComponents";
import CustomerModal from "../../components/modals/customer-modal/CustomerModal";
import SearchTable from "../../components/search-table/SearchTable";
import { getOneEnterpriseQuery } from "../../graphql/enterprise/queries";
import { Tooltip } from "../../components/tooltip/Tooltip";
import { AuthStore } from "../../store/auth";
import CreditNoteModal from "../../components/modals/credit-note-modal/CreditNoteModal";
import AlertModal from "../../components/modals/alert-modal/AlertModal";
import { updatePage } from "../../store/page";

const InvoicesPage = () => {
  const client = useApolloClient();
  const [presentToast] = useIonToast();
  const [showModal, setShowModal] = useState(false);
  const [showPaymentRecordModal, setShowPaymentRecordModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [invoiceId, setInvoiceId] = useState(null);
  const history = useHistory();
  const { deleteInvoice, dLoading, dError, generateInvoice, gLoading, gError } =
    useInvoicesService();
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  // const [date, setDate] = useState(getDate(new Date(getRange(-1)), false));
  // const [dueDate, setDueDate] = useState(getDate(new Date().setDate(new Date(date).getDate() + 30), false));
  const { authState } = useAuth();
  const { enterprise } = authState;
  const user = AuthStore.useState((s) => s.user);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showNoteCreditModal, setShowNoteCreditModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [present] = useIonAlert();
  const [columns, setColumns] = useState([]);

  const [options, setOptions] = useState({
    order: [["date", "DESC"]],
  });

  const ItemsPopover = ({ onHide, data, enterprise }) => (
    <>
      <OptionItem
        onHide={onHide}
        event={() => goToInvoicePage(data.id)}
        icon={eyeOutline}
        caption="Ver detalle"
      />

      {data.status !== "CANCELLED" &&
        !data.isFEL &&
        !["READ"].includes(user?.role?.permissions["sales"]) && (
          <OptionItem
            onHide={onHide}
            event={() => handleUpdate(data.id)}
            icon={createOutline}
            caption="Editar"
          />
        )}
      {!data.isFEL &&
        enterprise?.bill?.issuer !== "NONE" &&
        data.status !== "CANCELLED" &&
        !["READ"].includes(user?.role?.permissions["sales"]) && (
          <OptionItem
            onHide={onHide}
            event={() => handleGenerateInvoice(data)}
            icon={documentAttachOutline}
            caption="Emitir factura FEL en SAT"
          />
        )}
      {data.isFEL && !["READ"].includes(user?.role?.permissions["sales"]) && (
        <OptionItem
          onHide={onHide}
          event={() => handleDownloadInvoice(data)}
          icon={downloadOutline}
          caption="Descargar factura"
        />
      )}
      {data.status !== "CANCELLED" && !data?.createCreditNote ? (
        <Tooltip
          label={textsDisableButtonNoteCredit.rangeDate}
          component={
            <OptionItem
              onHide={onHide}
              event={() => {
                setInvoice(data);
                setShowNoteCreditModal(true);
              }}
              icon={receiptOutline}
              disabled={true}
              caption="Agregar nota de crédito"
            />
          }
        />
      ) : (
        data.status !== "CANCELLED" && (
          <OptionItem
            onHide={onHide}
            event={() => {
              setInvoice(data);
              setShowNoteCreditModal(true);
            }}
            icon={receiptOutline}
            caption="Agregar nota de crédito"
          />
        )
      )}
      {data.status !== "CANCELLED" &&
        !["READ"].includes(user?.role?.permissions["sales"]) && (
          <OptionItem
            onHide={onHide}
            event={() => handleDelete(data)}
            icon={trashOutline}
            caption="Anular"
            className="danger"
          />
        )}
    </>
  );
  const Options = ({ data }) => (
    <ButtonPopover
      options={ItemsPopover}
      enterprise={authState?.enterprise}
      data={data}
    />
  );

  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const {
    data: allInvoices,
    loadingInvoices,
    error,
    refetch: refetchInvoices,
  } = useQuery(getAllInvoicesQuery, {
    variables: { options, status },
    fetchPolicy: "cache-and-network",
    onCompleted(invoices) {
      let data = [
        {
          id: 1,
          field: "status",
          label: "Estado",
          width: "100px",
          cellRenderer: ({ value }) => (
            <IonNote className={`w-full text-center ${value} with-bg`}>
              {isEmpty(statusName[value])}
            </IonNote>
          ),
        },
        {
          id: 2,
          field: "number",
          label: "No. Factura",
          width: "120px",
          getValue: ({ value }) => isEmpty(value),
        },
        {
          id: 3,
          field: "customer",
          label: "Cliente",
          getValue: ({ value }) => isEmpty(value?.name),
        },
        {
          id: 4,
          field: "date",
          label: "Fecha creación",
          width: "135px",
          getValue: ({ value }) =>
            isEmpty(parseDate(value).replace(/\//g, "-")),
        },
        {
          id: 5,
          field: "dueDate",
          label: "Fecha límite",
          width: "140px",
          getValue: ({ value }) =>
            isEmpty(parseDate(value).replace(/\//g, "-")),
        },
        {
          id: 6,
          field: "total",
          label: "Monto",
          width: "max-content",
          headerCellRenderer: () => <div className="ml-auto mr-2">Monto</div>,
          cellRenderer: ({ data }) => {
            return (
              <div className="text-right w-full m-4">
                <p>{formatMoney(data, data.total)}</p>
                {data.total !== data.convertedTotal && (
                  <IonNote className="text-sm">
                    {formatMoney(enterprise, data.convertedTotal)}
                  </IonNote>
                )}
              </div>
            );
          },
        },
        {
          id: 7,
          field: "remaining",
          label: "Saldo",
          width: "max-content",
          headerCellRenderer: () => <div className="ml-auto mr-2">Saldo</div>,
          cellRenderer: ({ data }) => {
            return (
              <div className="text-right w-full m-4">
                <p>{formatMoney(data, data.remaining)}</p>
                {data.remaining !== data.convertedRemaining && (
                  <IonNote className="text-sm">
                    {formatMoney(enterprise, data.convertedRemaining)}
                  </IonNote>
                )}
              </div>
            );
          },
        },
      ];

      data = [
        ...data,
        enterprise?.bill?.issuer !== "NONE"
          ? {
              id: 8,
              label: "",
              cellRenderer: ({ data }) => (
                <div className="text-center w-full ">
                  {data?.isFEL && (
                    <p className="text-primary font-bold">
                      Emitida en SAT
                      <Tooltip
                        className="ml-1 mt-1 text-sm"
                        label="Esta factura ya está emitida y registrada en la SAT"
                      />
                    </p>
                  )}
                  {!data?.isFEL && (
                    <p className="text-gray-light font-bold">
                      Sin emitir en SAT
                    </p>
                  )}
                </div>
              ),
            }
          : {
              id: 9,
              label: "",
              cellRenderer: ({ data }) => (
                <div className="text-center w-full">
                  <IonButton
                    className="sfn-button"
                    fill="outline"
                    shape="round"
                    disabled={
                      !(data.remaining > 0 && data.status !== "CANCELLED") ||
                      ["READ"].includes(user?.role?.permissions["expenses"])
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvoiceId(data.id);
                      setShowPaymentRecordModal(true);
                    }}
                  >
                    Registrar Pago
                  </IonButton>
                </div>
              ),
            },

        {
          id: 10,
          label: "",
          cellRenderer: Options,
          pinned: true,
          width: "max-content",
        },
      ];
      setColumns(data);
      setLoading(false);
    },
  });
  const {
    data: allCustomer,
    loading: customersLoading,
    error: customersError,
    refetch: refetchCustomers,
  } = useQuery(getAllCustomersQuery);
  const {
    data: indicators,
    loading: indicatorsLoading,
    error: indicatorsError,
    refetch: refetchIndicators,
  } = useQuery(invoiceIndicatorsQuery);
  const { invoiceIndicators } = indicators || [];
  const {
    data: dataEnterprise,
    loading: loadingEnterprise,
    error: errorEnterprise,
    refetch: refetchEnterprise,
  } = useQuery(getOneEnterpriseQuery, {
    variables: { input: user?.enterprise?.id },
  });

  useEffect(() => {
    let data = [
      {
        id: 1,
        field: "status",
        label: "Estado",
        width: "100px",
        cellRenderer: ({ value }) => (
          <IonNote className={`w-full text-center ${value} with-bg`}>
            {isEmpty(statusName[value])}
          </IonNote>
        ),
      },
      {
        id: 2,
        field: "number",
        label: "No. Factura",
        width: "120px",
        getValue: ({ value }) => isEmpty(value),
      },
      {
        id: 3,
        field: "customer",
        label: "Cliente",
        getValue: ({ value }) => isEmpty(value?.name),
      },
      {
        id: 4,
        field: "date",
        label: "Fecha creación",
        width: "135px",
        getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, "-")),
      },
      {
        id: 5,
        field: "dueDate",
        label: "Fecha límite",
        width: "140px",
        getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, "-")),
      },
      {
        id: 6,
        field: "total",
        label: "Monto",
        width: "max-content",
        headerCellRenderer: () => <div className="ml-auto mr-2">Monto</div>,
        cellRenderer: ({ data }) => {
          return (
            <div className="text-right w-full m-4">
              <p>{formatMoney(data, data.total)}</p>
              {data.total !== data.convertedTotal && (
                <IonNote className="text-sm">
                  {formatMoney(enterprise, data.convertedTotal)}
                </IonNote>
              )}
            </div>
          );
        },
      },
      {
        id: 7,
        field: "remaining",
        label: "Saldo",
        width: "max-content",
        headerCellRenderer: () => <div className="ml-auto mr-2">Saldo</div>,
        cellRenderer: ({ data }) => {
          return (
            <div className="text-right w-full m-4">
              <p>{formatMoney(data, data.remaining)}</p>
              {data.remaining !== data.convertedRemaining && (
                <IonNote className="text-sm">
                  {formatMoney(enterprise, data.convertedRemaining)}
                </IonNote>
              )}
            </div>
          );
        },
      },
    ];

    data = [
      ...data,
      enterprise?.bill?.issuer !== "NONE"
        ? {
            id: 8,
            label: "",
            cellRenderer: ({ data }) => (
              <div className="text-center w-full ">
                {data?.isFEL && (
                  <p className="text-primary">
                    Emitida en SAT
                    <Tooltip
                      className="ml-1 mt-1 text-sm"
                      label="Esta factura ya está emitida y registrada en la SAT"
                    />
                  </p>
                )}
                {!data?.isFEL && (
                  <p>
                    Sin emitir en SAT
                    <Tooltip
                      className="ml-1 mt-1 text-sm"
                      label="Esta factura no ha sido emitida en la SAT"
                    />
                  </p>
                )}
              </div>
            ),
          }
        : {
            id: 9,
            label: "",
            cellRenderer: ({ data }) => (
              <div className="text-center w-full">
                <IonButton
                  className="sfn-button"
                  fill="outline"
                  shape="round"
                  disabled={
                    !(data.remaining > 0 && data.status !== "CANCELLED") ||
                    ["READ"].includes(user?.role?.permissions["expenses"])
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setInvoiceId(data.id);
                    setShowPaymentRecordModal(true);
                  }}
                >
                  Registrar Pago
                </IonButton>
              </div>
            ),
          },

      {
        id: 10,
        label: "",
        cellRenderer: Options,
        pinned: true,
        width: "max-content",
      },
    ];

    setColumns(data);
  }, [enterprise]);

  useEffect(async () => {
    setLoading(true);
    await refetchEnterprise({
      variables: { input: authState?.enterprise?.id },
    });
    let data = await refetchInvoices({
      variables: { options, status },
      fetchPolicy: "cache-and-network",
    });
    setLoading(false);
  }, [authState]);

  useEffect(() => {
    setLoading(false);
  }, [!loadingInvoices]);

  const handleChange = (selectedOption, source) => {
    setLoading(true);

    if (source === "customer") {
      let where = {};
      setSelectedOption(selectedOption);
      if (selectedOption) {
        where = {
          ...(options.where || {}),
          customerId: selectedOption.value,
        };
        setOptions({ ...options, where });
      } else {
        setOptions({
          ...options,
          where: { ...options.where, customerId: undefined },
        });
      }
    }
    if (source === "status") {
      setSelectedStatusOption(selectedOption);
      if (selectedOption) {
        setStatus(selectedOption.value);
      } else {
        setStatus(undefined);
      }
    }
  };

  const textsDisableButtonNoteCredit = {
    fel: "Solo puedes crear una nota de crédito si esta factura esta emitida en SAT.", //no va
    rangeDate:
      "No puedes crear una nota crédito porque ya pasaron 60 días desde la emisión de esta factura.",
  };

  const handleDelete = (input) => {
    const description = input?.isFEL
      ? `Esta factura no. ${input?.number} ya esta emitida en SAT, su anulación será permanente y también se registrará en su sistema.`
      : `La factura no. ${input?.number} será anulada permanente, además de los pagos asociados a ella.`;
    present({
      cssClass: "sfn-alert-dialog",
      mode: "md",
      header: `Anular factura ${input?.isFEL && "en SAT"}`,
      subHeader: description,
      message: "¿Esta seguro de anular a esta factura?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Anular factura",
          cssClass: "delete",
          handler: async () => {
            try {
              const { errors } = await deleteInvoice({
                variables: { input: input?.id },
              });
              if (errors) {
                const { extensions } = errors[0];
                let message = "";
                if (extensions?.code === "DTES_NOT_AVAILABLE") {
                  return present({
                    cssClass: "sfn-alert-dialog",
                    mode: "md",
                    header: `No tienes DTE's disponibles`,
                    subHeader: `Para anular una factura en SAT necesitas tener DTE's (Documento tributario eléctronico) disponibles.`,
                    buttons: [
                      {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                      },
                      {
                        text: "Comprar paquete",
                        cssClass: "primary",
                        handler: async () => {
                          updatePage({ tab: "plan" });
                          history.push("/settings?tab=plan");
                        },
                      },
                    ],
                    onDidDismiss: (e) => console.log("did dismiss"),
                  });
                }
                if (extensions?.code === "BAD_REQUEST") {
                  message = `motivo: el servidor no pudo interpretar la solicitud`;
                }
                if (extensions?.code === "NOT_FOUND") {
                  message = `motivo: no se encontro la factura a eliminar o anular`;
                }
                if (extensions?.code === "FAILED_ELECTRONIC_INVOICE") {
                  if (extensions?.errors?.length) {
                    message = `La factura no. ${isEmpty(
                      input?.number
                    )} no ha podido ser emitida hacia la SAT. Por favor verifica lo siguiente: \n ${extensions?.errors?.map(
                      (e) => `\n• ${e}`
                    )}`;
                  } else {
                    message = `No hemos podido emitir tu factura en SAT`;
                  }
                }
                present({
                  cssClass: "sfn-alert-dialog",
                  mode: "md",
                  header: `No se ha podido anular la factura`,
                  subHeader: message,
                  buttons: [
                    {
                      text: "Cerrar",
                      role: "cancel",
                      cssClass: "secondary",
                    },
                  ],
                  onDidDismiss: (e) => console.log("did dismiss"),
                });
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
    if (dError) {
      console.log(dError);
    }
  };
  const handleUpdate = (id) => {
    console.log(id);
    setInvoiceId(id);
    setShowModal(true);
  };

  const goToInvoicePage = (id) => {
    history.push(`/invoices/${id}`);
  };

  const filterDate = (e) => {
    let from = date,
      to = dueDate;
    if (e.target.name === "date") {
      setDate(e.target.value);
      from = e.target.value;
    } else {
      setDueDate(e.target.value);
      to = e.target.value;
    }
    const between = [from, to]?.filter((v) => v !== "").length
      ? [from, to]?.filter((v) => v !== "")
      : undefined;
    let where;
    if (between) {
      where = {
        ...options.where,
        date: {
          _between: between,
        },
      };
    }
    setOptions({ ...options, where });
    setLoading(true);
  };
  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show,
  });

  const handleGenerateInvoice = async (input) => {
    setLoading(true);
    let { errors, data } = await generateInvoice({
      variables: { input: input?.id },
    });
    if (!errors) {
      refetchInvoices({ variables: { options, status } });
    }
    if (!errors && data) {
      presentToast({
        message: "Se ha emitido y registrado factura FEL en SAT exitosamente",
        color: "success",
        mode: "ios",
        duration: 4000,
      });
      return;
    }
    if (errors) {
      /**
       * INVALID_ISSUER_CREDENTIALS
       * FAILED_ELECTRONIC_INVOICE
       */
      let message = "";
      const { extensions } = errors[0];
      if (extensions?.code === "DTES_NOT_AVAILABLE") {
        present({
          cssClass: "sfn-alert-dialog",
          mode: "md",
          header: `No tienes DTE's disponibles`,
          subHeader: `Para emitir una factura en SAT necesitas tener DTE's  (Documento tributario eléctronico) disponibles.`,
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Comprar paquete",
              cssClass: "primary",
              handler: async () => {
                updatePage({ tab: "plan" });
                history.push("/settings?tab=plan");
              },
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        });
        setLoading(false);
        return;
      }
      if (extensions?.code === "INVALID_ISSUER_CREDENTIALS") {
        message = `La factura no. ${isEmpty(
          input?.number
        )} no ha podido ser emitida hacia la SAT. Por favor verifica la definición de tu proveedor de facturación en la sección Configuración, pestaña Facturación.`;
      }
      if (extensions?.code === "FAILED_ELECTRONIC_INVOICE") {
        if (extensions?.errors?.length) {
          message = `La factura no. ${isEmpty(
            input?.number
          )} no ha podido ser emitida hacia la SAT. Por favor verifica lo siguiente: \n ${extensions?.errors?.map(
            (e) => `\n• ${e}`
          )}`;
        } else {
          message = `La factura no. ${isEmpty(
            input?.number
          )} no ha podido ser emitida hacia la SAT. Por favor contactate con nuestro equipo de soporte y envianos tus comentarios.`;
        }
      }
      if (extensions?.code === "BAD_REQUEST") {
        message = `motivo: el servidor no pudo interpretar la solicitud`;
      }
      if (extensions?.code === "NOT_FOUND") {
        message = `motivo: no se encontro la factura`;
      }

      present({
        cssClass: "sfn-alert-dialog",
        mode: "md",
        header: "No se ha podido emitir factura",
        subHeader: message,
        // message: ``,
        buttons: [
          {
            text: "Cerrar",
            role: "cancel",
            cssClass: "secondary",
          },
        ],
      });
    }
    setLoading(false);
  };

  const handleDownloadInvoice = async (input) => {
    const { data } = await client.query({
      query: downloadInvoice,
      variables: { input: input.id },
    });

    if (data?.downloadInvoice) {
      fetch(data?.downloadInvoice)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = input.number ? input.number : input.id;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => null);
    }
    console.log(data);
  };

  return (
    <IonPage>
      <Header
        title="Facturación - ventas"
        subtitle={`Indicadores a la fecha ${new Date().toLocaleDateString()}`}
      ></Header>

      <IonContent className="sfn-content">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center my-8">
          <Kpi
            iconColor="danger"
            icon={flagOutline}
            loading={indicatorsLoading}
            title={formatMoney(
              enterprise,
              invoiceIndicators?.overdue?.value,
              true
            )}
            description="Facturación atrasada"
            tooltipDescription={
              <Tooltip
                className="text-sm ml-1 pb-4"
                label="Este monto representa la suma de todas las facturas atrasadas desde que utilizas Symfonia."
              />
            }
          />
          <Kpi
            iconColor="warning"
            className="my-6 md:my-0"
            icon={timeOutline}
            loading={indicatorsLoading}
            title={formatMoney(
              enterprise,
              invoiceIndicators?.unpaid?.value,
              true
            )}
            description="Facturación pendiente y parcial"
            tooltipDescription={
              <Tooltip
                className="text-sm ml-1 pb-4"
                label="Este monto representa la suma de todas las facturas pendientes y pagadas parcialmente desde que utilizas Symfonia."
              />
            }
          />
          <Kpi
            iconColor="secondary"
            icon={calendarClearOutline}
            loading={indicatorsLoading}
            title={formatMoney(
              enterprise,
              invoiceIndicators?.paid?.value,
              true
            )}
            description="Facturación pagada últimos 30 días"
          />
        </div>

        <section className="flex flex-col sm:flex-row justify-between mb-4 items-end">
          <div className="sm:w-64 w-full">
            <p className="text-sm mb-2 hover:text-secondary w-64">Cliente</p>
            <Select
              isClearable={true}
              placeholder="Cliente"
              isLoading={customersLoading}
              value={selectedOption}
              onChange={(e) => handleChange(e, "customer")}
              options={formatSelectData(
                { value: "id", label: "name" },
                allCustomer?.customers?.rows
              )}
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
              components={{
                // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo cliente", callback:setShowCustomerModal}),
                NoOptionsMessage: (props) =>
                  ListNoOptionsMessage({ ...props, label: "No hay clientes" }),
              }}
            />
          </div>

          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64">Estado</p>
            <Select
              isClearable={true}
              options={documentStatus}
              value={selectedStatusOption}
              onChange={(e) => handleChange(e, "status")}
              placeholder="Seleccione un estado"
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
            />
          </div>

          <InputDate
            label="Desde"
            value={date}
            handleInputChange={(e) => filterDate(e)}
            name="date"
            icon={false}
          />
          <InputDate
            label="Hasta"
            value={dueDate}
            handleInputChange={(e) => filterDate(e)}
            name="dueDate"
            icon={false}
          />

          <IonButton
            disabled={["READ"].includes(user?.role?.permissions["sales"])}
            className="sfn-button sm:w-60 w-full mt-12 sm:mt-0"
            shape="round"
            onClick={() => setShowModal(true)}
          >
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar factura
          </IonButton>
        </section>
        {loading && (
          <div className="flex w-full h-40 content  items-center justify-center">
            <IonIcon
              className="text-8xl"
              src="assets/icon/loading.svg"
            ></IonIcon>
          </div>
        )}
        {!loading && (
          <GridTable
            className="mt-10 sfn-data-table h-screen"
            isLoading={loading && columns.length}
            columns={columns}
            rows={
              allInvoices?.invoices?.rows.length
                ? allInvoices?.invoices?.rows
                : []
            }
            components={{
              NoResults: () => (
                <Placeholder
                  icon={receiptOutline}
                  description='Aún no tienes facturas agregadas haz clic en el botón "Agregar factura"'
                />
              ),
              Search: SearchTable,
            }}
            {...tableProps(allInvoices?.invoices?.rows.length ? true : false)}
            texts={texts}
            icons={{
              columnVisibility: (
                <IonIcon icon={filterOutline} slot="icon-only" />
              ),
            }}
            onRowClick={({ data }) => {
              goToInvoicePage(data.id);
            }}
          />
        )}
        {showNoteCreditModal && (
          <CreditNoteModal
            enterprise={enterprise}
            invoice={invoice}
            creditNoteId={null}
            showModal={showNoteCreditModal}
            setShowModal={setShowNoteCreditModal}
          />
        )}

        {showModal && (
          <InvoiceModal
            invoiceId={invoiceId}
            setInvoiceId={setInvoiceId}
            showModal={showModal}
            setShowModal={setShowModal}
            hookClose={() => {
              refetchIndicators();
            }}
          ></InvoiceModal>
        )}
        {showPaymentRecordModal && (
          <PaymentRecordModal
            invoiceId={invoiceId}
            setInvoiceId={setInvoiceId}
            showPaymentRecordModal={showPaymentRecordModal}
            setShowPaymentRecordModal={setShowPaymentRecordModal}
            hookClose={() => {
              refetchIndicators();
            }}
          ></PaymentRecordModal>
        )}
        {/* {showCustomerModal && <CustomerModal showModal={showCustomerModal} setShowModal={setShowCustomerModal}></CustomerModal>} */}
      </IonContent>
    </IonPage>
  );
};

export default InvoicesPage;
