import { IonButton } from "@ionic/react";
import {
  setShowUpgradeMyPlanModal,
  updatePackage,
} from "../../../../../../store/myPlan";

const Placeholder = () => {
  return (
    <div className="flex flex-col text-center justify-center items-center w-[300px]">
      <h3 className="font-bold text-xl mb-8">
      Aún no estas suscrito a un plan
      </h3>
      <IonButton
        className="sfn-button content-end w-full normal-case font-bold"
        color="primary"
        fill="solid"
        shape="round"
        onClick={() => {
          updatePackage(null);
          setShowUpgradeMyPlanModal(true);
        }}
      >
        Suscríbete ahora
      </IonButton>
    </div>
  );
};

export default Placeholder;
