import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import ProfileAvatarImage from "../profile-avatar-image/ProfileAvatarImage";
import Select from 'react-select';
import './ProfileAvatar.scss';
import OptionItem from "../option-item/OptionItem";
import { businessOutline, chevronDownOutline, chevronUpOutline, helpCircleOutline, lockClosedOutline } from "ionicons/icons";
import { useAuth } from "../../context/auth/AuthState";
import { useHistory } from "react-router";
import ResetModal from "../modals/reset-modal/ResetModal";
import { useApolloClient, useQuery } from '@apollo/client';
import { useEnterprisesService } from '../../graphql/enterprise/service';
import { getAllEnterprisesQuery } from "../../graphql/enterprise/queries";
import { formatSelectData, defaultValue, getAvatar } from '../../helpers/utils';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import { IonIcon, IonImg } from "@ionic/react"
import { ListNoOptionsMessage, MenuListButton } from "../select-menu-components/SelectMenuComponents";
import BusinessModal from "../modals/business-modal/BusinessModal";
import { AuthStore } from "../../store/auth";

const ProfileAvatar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const { logout, authState, setAuth } = useAuth();
  const authStore = AuthStore.useState(s => s)
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState({ label: authStore.user?.enterprise?.name, value: authStore.user?.enterprise?.id });
  const { data: optionsEnterprises, loading, error } = useQuery(getAllEnterprisesQuery);
  const { changeEnterprise, chLoading, chError } = useEnterprisesService();
  const { plan } = authState;
  const client = useApolloClient();


  const handleLogout = () => {
    history.replace("/login");
    logout();
  }

  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false)
  });

  const handleChangeEnterprise = async (selectedOption) => {
    setSelectedEnterprise(selectedOption);
    let { data, errors } = await changeEnterprise({ variables: { input: selectedOption.value } });
    if (!errors) {
      let { changeEnterprise } = data;
      let auth = { ...authState };
      auth.enterprise = optionsEnterprises.enterprises.find(e => e.id === selectedOption.value);
      client.clearStore();
      history.replace('/dashboard')
      setAuth({ user: auth, token: changeEnterprise.token, checking: false });
      window.location.reload();
    }
  };

  const goToBusinessPage = () => {
    history.push('/businesses');
  }

  const openModal = () => {
    setShowModal(true)
  }

  const helpLink = () => {
    window.open("https://symfonia.io/ayuda", "_blank")
  }

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <button
          className="btn btn-info btn-sm btn-block"

        >Add New</button>
      </div>
    ) : null;

    useEffect(() => {
        setSelectedEnterprise({ label: authStore.user?.enterprise?.name, value: authStore.user?.enterprise?.id })
    }, [authStore])
    

  return (
    <>
      <div ref={ref} id="profile-avatar">
        <div className="content flex flex-row justify-between items-center rounded-full cursor-pointer"
          onClick={() => setOpen(!open)}>

          <div className="circle-avatar flex items-center justify-center rounded-full" >
            {!authState.urlPicture && getAvatar(authState?.name)}
            {authState.urlPicture && <IonImg src={authState?.urlPicture} />}
          </div>
          <p className="truncate">{authState?.name}</p>
          <IonIcon className="mx-4 w-6" icon={open ? chevronUpOutline : chevronDownOutline} />
        </div>

        <div className={`dropdown rounded absolute shadow-md mt-2 p-3 ${!open && 'hidden'}`}>
          <ProfileAvatarImage auth={authState}></ProfileAvatarImage>
          {optionsEnterprises?.enterprises?.length && authStore?.user?.enterprises?.accepted > 0 ? <Select
            className="mb-2"
            styles={sfnSelectTheme}
            placeholder="Seleccione una empresa"
            value={selectedEnterprise}
            onChange={handleChangeEnterprise}
            components={
              {
                MenuList: (props) => MenuListButton({ ...props, label: "Agregar nueva empresa", callback: setShowBusinessModal,  }),
                NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay empresas" })
              }
            }
            options={formatSelectData({ value: 'id', label: 'name' }, optionsEnterprises?.enterprises?.filter(e => e.status))}
          /> : null}
          <div style={{ borderTop: '2px solid var(--input-background)' }} className="mx-4 mt-8 mb-6" ></div>
          <OptionItem icon={businessOutline} event={goToBusinessPage} caption="Mis empresas"></OptionItem>
          <OptionItem icon={lockClosedOutline} event={openModal} caption="Cambiar Contraseña"></OptionItem>
          {/* <OptionItem icon={helpCircleOutline} event={helpLink} caption="Centro de ayuda"></OptionItem> */}
          <div style={{ borderTop: '2px solid var(--input-background)' }} className="mx-4" ></div>
          <OptionItem event={handleLogout} caption="Cerrar Sesión" className="justify-center" ></OptionItem>
          <ResetModal showModal={showModal} setShowModal={setShowModal}></ResetModal>
        </div>
      </div>
      {showBusinessModal && <BusinessModal showBusinessModal={showBusinessModal} setShowBusinessModal={setShowBusinessModal} />}
    </>
  )
}

export default ProfileAvatar;