import gql from 'graphql-tag'

export const getAllVendorsQuery = gql`
	query vendors($options: Options, $page: Int) {
		vendors(options: $options, page: $page) {
			rows {
				id
				name
				emails
				overdueAmount
				outstandingAmount
				phones {
					type
					areaCode
					number
				}
				bill {
					currencyId
					nit
				}
				bank {
					type
					number
					name
				}
				description
				address {
					countryId
					address
					zip
					state
				}
				bills {
					id
					number
					exchangeRate
					description
					date
					dueDate
					
				}
			}
		}
	}
`

export const getOneVendorQuery = gql`
	query vendor($input: ID!, $options: Options) {
		vendor(id: $input) {
			id
			name
			emails
			overdueAmount 
			outstandingAmount 
			totalPaid 
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}			
			description
			address {
				countryId
				state
				address
				zip
			}
			invoices: bills(options: $options) {
				id
				date
				dueDate
				number
				remaining 
				convertedRemaining
				total
				convertedTotal
				status
				currency{
					symbol
					code
				}
			}
			createdAt
		}
	}
`

export const purchasesByVendor = gql`
query purchasesByVendor($from: LocalDate!, $to: LocalDate) {
	purchasesByVendor(from: $from, to: $to) {
		name
		total
		paid
	}
}
`