// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createUserMutation,
	deleteUserMutation,
	updateUserMutation,
	updatePasswordMutation
} from './mutations'
import { getAllUsersQuery } from './queries'

export const useUsersService = () => {
	const [createUser, { loading: cLoading, error: cError }] = useMutation(
		createUserMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createUser } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllUsersQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllUsersQuery,
					data: {
						users: { rows: [createUser, ...data.users.rows] },
					},
				})
			},
		},
	)

	const [updateUser, { loading: uLoading, error: uError }] = useMutation(
		updateUserMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateUser } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllUsersQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllUsersQuery,
					data: {
						users: {
							rows: data.users.rows.map((user) =>
								user.id === updateUser.id ? updateUser : user,
							),
						},
					},
				})
			},
		},
	)

	const [deleteUser, { loading: dLoading, error: dError }] = useMutation(
		deleteUserMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteUser } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllUsersQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllUsersQuery,
					data: {
						users: {
							rows: data.users.rows.filter(
								(user) => user.id !== deleteUser.id,
							),
						},
					},
				})
			},
		},
	)

	const [uPassword, { loading: uPLoading, error: uPError }] = useMutation(updatePasswordMutation, {errorPolicy: "all"})


	return {
		createUser,
		cLoading,
		cError,

		updateUser,
		uLoading,
		uError,

		deleteUser,
		dLoading,
		dError,

		uPassword,
		uPLoading,
		uPError
	}
}
