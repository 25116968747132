import gql from 'graphql-tag';

export const getAllLegalBenefitQuery = gql`
  query legalBenefits($options: Options, $page: Int) {
    legalBenefits(options: $options, page: $page) {
      rows {
        id
        legalBenefitType {
          id
          name
          group
        }
        dueDate
        frequency
        amount
        description
        employees {
          id
          name
        }
        isPercent
      }
    }
  }
`;

export const getOneLegalBenefitQuery = gql`
  query legalBenefit($input: ID!) {
    legalBenefit(id: $input) {
      id
      legalBenefitType {
        value: id
        label: name
        group
      }
      dueDate
      frequency
      amount
      description
      employees {
        value: id
        label: name
      }
      isPercent
    }
  }
`;
