import { useMutation } from "@apollo/client";
import { setPayrollConfigMutation,  createPayrollMutation} from "./mutations";

export const usePayrollService = () => {
  const [setPayrollConfig, { loading: sLoading, error: sError }] = useMutation(
    setPayrollConfigMutation,
    {
      errorPolicy: "all",
      update(cache, { data: { setPayrollConfig } }) {
        // obtener el objeto de cache
      },
    }
  );

  const [createPayroll, { loading: cLoading, error: cError }] = useMutation(
    createPayrollMutation,
    {
      errorPolicy: "all",
      update(cache, { data: { createPayroll } }) {
        // obtener el objeto de cache
      },
    }
  );

  return {
    setPayrollConfig,
    sLoading,
    sError,
    createPayroll,
    cLoading,
    cError
  };
};
