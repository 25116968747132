
import { useState } from 'react';
import './Toggle.scss';

const Toggle = ({ id, onChange, buttons, checked, className = 'flex items-start justify-start w-full' }) => {
    const [value, setValue] = useState(checked || false);
    const style = value ? ['', 'active-color-text'] : ['active-color-text', '']
    const [styleText, setStyleText] = useState(style)

    const change = (e) => {
        setValue(e);
        const style = e ? ['opacity-70', 'active-color-text'] : ['active-color-text', 'opacity-70']
        setStyleText(style);
        onChange(e ? buttons[1].value : buttons[0].value);
    }
    return (
        <div className={`toggle-content w-full ${className}`}>
            <input type="checkbox" id={id} value={value} onChange={e => (change(e?.target?.checked))} />
            <label htmlFor={id}>
                <div className={`toggle ${value ? 'active': ''}`}></div>
                <div className="names flex justify-center items-center w-full">
                   {buttons.map((button, index) => (
                        <p key={index} className={styleText[index] + ' text-gray-light'}>{button?.label}</p>
                   ))}
                </div>
            </label>
        </div>
    );
}

export default Toggle;