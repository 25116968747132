import { IonItem, IonLabel, IonIcon, IonInput, IonCol } from '@ionic/react';
import { useEffect, useState } from 'react';
import { eyeOutline, eyeOffOutline, cardOutline } from 'ionicons/icons';
import './InputCardNumber.scss';
import { isValid, isExpirationDateValid, isSecurityCodeValid, getCreditCardNameByNumber } from 'creditcard.js';
import Tooltip from '../tooltip-secondary/Tooltip';
const InputCardNumber = ({ klass = "", label, name, required, value, onChange, type = 'text', placeholder = '', autocomplete = "" }) => {
    const [typeCard, setTypeCard] = useState('')
    const cards = ['visa', 'mastercard']
    useEffect(() => {
        formatNumberCard()
    }, [value])


    function formatNumberCard() {
        let cardNumber = value;
        const cardNumberMask = '#### #### #### ####';
        for (let index = 0; index < cardNumber.length; index++) {
            if (cardNumberMask[index] == ' ' && cardNumber[index] !== ' ') cardNumber = cardNumber.substr(0, index) + ' ' + cardNumber.substr(index, cardNumber.length - index)
        }
        if (cardNumber?.substr('-1') == ' ') cardNumber = cardNumber?.substr(0, cardNumber?.length - 1)
        cardNumber = cardNumber?.substr(0, cardNumberMask?.length).replace(/[^0-9 ]/g, '')
        const number = cardNumber?.replaceAll(' ', '')
        const enterprise = getCreditCardNameByNumber(number).toLowerCase()
        setTypeCard(cards.includes(enterprise) ? enterprise : '')
        onChange(cardNumber, name);
    }

    return (
        <div className={`flex flex-col ${klass}`}>
            <IonItem className={`sfn-input input-card-number z-1`} mode="md" lines="none">
                <IonLabel position="stacked">{label}{required && <span className="text-base text-danger">*</span>} </IonLabel>
                <IonInput type={type} name={name} value={value} onIonChange={onChange} placeholder={placeholder} required={required} autocomplete={autocomplete}> </IonInput>
                <span className="icon">
                    {typeCard !== "" ? <img className='w-full animate__animated animate__fadeIn' src={`assets/icon/${typeCard}_logo.svg`} /> : <IonIcon className="w-full h-full text-gray-light animate__animated animate__fadeIn" src={`assets/icon/credit-card.svg`} />}
                </span>
            </IonItem>
            {(value !== '' && !cards.includes(typeCard)) && <p className={`text-sm text-gray-light mt-2`}>Symfonia te permite realizar pagos a través de tus tarjetas VISA y MASTERCARD.</p>}
        </div>
    )
}

export default InputCardNumber;