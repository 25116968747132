import gql from 'graphql-tag'

export const getAllAccountsQuery = gql`
	query accounts($input: EnumAccountTypeGroup ){
		accounts(group: $input) {
			data
			length  {
				asset
				income
				liability
				expense
				equity
			}
		}
	}
`

export const getOneAccountQuery = gql`
	query account($input: ID!) {
		account(id: $input) {
			id
			name
			customId
			description
			currency {
				id
				name
				code
				symbol
			}
			accountType {
				id
				name
				group
			}
		}
	}
`

export const productIncomeAccountsQuery = gql`
	query productAccounts {
		productAccounts(group: INCOME) {
			value: id
			label: name
		}
	}
`

export const productExpenseAccountsQuery = gql`
	query expenseAccounts  {
		productAccounts(group: EXPENSE) {
			value: id
			label: name
		}
	}
`

export const productAssetAccountsQuery = gql `
	query assetAccounts {
		productAccounts(group: ASSET) {
			value: id
			label: name
		}
	}
`

export const assetAccountsQuery = gql `
	query assetAccounts {
		productAccounts(group: ASSET) {
			id
			name
			currency{
				id
				name
				code
				symbol
			}
		}
	}
`

