import { useState } from "react";
import { set } from "lodash";

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(
    JSON.parse(JSON.stringify(initialState))
  );

  const reset = (newFormState) => {
    setValues({ ...newFormState });
  };

  const handleInputBlur = (e, key) => {
    if (["tel", "number"].includes(e.target.type)) {
      e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
    }
    if (e && e.target) {
      const { target } = e;
      setValues({
        ...set(values, target.name, target.value),
      });
    } else {
      setValues({
        ...set(values, key, e),
      });
    }
  };

  const handleInputChange = (e, key) => {
    if (e && e.target) {
      const { target } = e;
      setValues({
        ...set(values, target.name, target.value),
      });
    } else {
      setValues({
        ...set(values, key, e),
      });
    }
  };

  const resetProps = (formState) => {
    Object.keys(formState).map((key) => {
      if (key in formState) {
        handleInputChange(formState[key], key);
      }
    });
  };

  const numberHandleInputChange = (e, key) => {
    let max = e.target.max || undefined;
    let oldValue = values[e.target.name];
    let value = e.target.value;
    if (!/^-?\d*[.]?\d{0,2}$/.test(value)) {
      value = oldValue;
    }

    if (max !== undefined && Number(value) > Number(max)) {
      value = max;
    }

    setValues({
      ...set(values, e.target.name, value),
    });
  };

  const numberHandleInputBlur = (e, key) => {
    let value = `${Number(e.target.value).toFixed(2)}`;

    setValues({
      ...set(values, e.target.name, value),
    });
  };

  function removeCharacter(e) {
    if (e?.keyCode === 189) {
      e.target.value = e.target.value.replaceAll("-", "").trim();
    }
    if (e.target.value.length > 0) {
      e.target.value = e.target.value.replaceAll("-", "").trim();
    }
    return handleInputChange(e);
  }

  function validateMaxLengthInput(e) {
    if (e?.target?.value?.length > e?.target?.maxLength) {
      e.target.value = e?.target?.value?.substring(0, e?.target?.max);
    }
    return handleInputChange(e);
  }

  return {
    formValues: values,
    handleInputChange,
    reset,
    resetProps,
    handleInputBlur,
    numberHandleInputBlur,
    numberHandleInputChange,
    removeCharacter,
    validateMaxLengthInput,
  };
};
