import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal } from "@ionic/react"
import { addCircleOutline, closeOutline, pricetagsOutline, reorderFourOutline } from "ionicons/icons";
import InputDate from "../../../../components/input-date/InputDate";
import Placeholder from "../../../../components/placeholder/Placeholder";
import Wrapper from "../../../../components/wrapper/Wrapper"
import { useForm } from "../../../../hooks/useForm";
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';

const initialState = {
    date: null,
    description: null,
    items: [{
        description: null,
        accountId: null,
        amout: 0,
        type: "DEBIT"
    },
    {
        description: null,
        accountId: null,
        amout: 0,
        type: "DEBIT"
    }]
}

const JournalModal = ({showModal, setShowModal}) => {

    const {formValues, handleInputChange, reset} = useForm({ ...initialState });
    const { date, description } = formValues;

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
    }

    const handleAddRow = () => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const columns = [
        {
            id: 1,
            field: 'description',
            label: 'Descripción',
        },
        {
            id: 2,
            field: 'accountId',
            label: 'Cuenta contable',
        },
        {
            id: 3,
            field: 'debit',
            label: 'Débito',
        },
        {
            id: 4,
            field: 'credit',
            label: 'Crédito',
        },
    ]
    

    const tableProps = {
        showSearch: false,
        showRowsInformation: false,
        isPaginated: false,
        showColumnVisibilityManager: false
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal'>
            <IonContent>
                <Wrapper max="xl">
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button" />
                    <h3 className="text-center text-xl font-bold">Agregar registro diario</h3>
                    <p className="text-center">Registra actividades comerciales como depreciaciones, ajustes de saldos <br/> de cuenta, ajustes al final del período o ingreso de saldos de cuentas iniciales.</p>

                    <form onSubmit={handleSubmit} className="h-full p-2">
                        <div className="flex md:flex-row flex-col w-full justify-between items-end">
                            <InputDate className="md:w-80" label="Fecha de creación" icon={false} value={date} handleInput={handleInputChange} name="date" />

                            <IonItem className="sfn-input ml-8 w-full" mode="md" lines="none">
                                <IonLabel position="stacked">Descripción<span className="text-base text-danger">*</span></IonLabel>
                                <IonInput
                                    name="description"
                                    placeholder="Indica nombre o descripción para esta transacción "
                                    type="text"
                                    onIonChange={handleInputChange}
                                    value={description}
                                    required />
                            </IonItem>
                        </div>

                        <GridTable
                        className="mt-8 sfn-data-table sfn-data-table-sm"
                        columns={columns}
                        rows={[]}
                        {...tableProps}
                        components={{
                            NoResults: () => (
                                <Placeholder icon={reorderFourOutline} description='Aún no tienes registros agregados, haz clic en el botón "Agregar registro"' />
                            )
                        }}
                    />
                    <IonButton className="sfn-button" fill="clear" expand="block" onClick={handleAddRow}>
                    <IonIcon icon={addCircleOutline} slot="start" />
                    AGREGAR UN REGISTRO
                    </IonButton>
                    </form>
                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default JournalModal
