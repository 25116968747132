import { useQuery } from "@apollo/client";
import { IonContent, IonIcon, IonModal } from "@ionic/react";
import { cardOutline, chatbubbleOutline, closeOutline, personOutline, receiptOutline } from "ionicons/icons";
import { useState } from "react";
import { getOneCustomerQuery } from "../../../graphql/customer/queries";
import { getOneVendorQuery } from "../../../graphql/vendor/queries";
import { countries, currencies, phoneTypeName, bankAccountTypesName } from "../../../helpers/catalogs";
import Wrapper from "../../wrapper/Wrapper";

const CustomerDetailModal = ({ showModal, setShowModal, customerId = null, setCustomerId=()=>{}, vendorId = null, setVendorId=()=>{} }) => {
    const [person, setPerson] = useState([]);

    useQuery(getOneCustomerQuery, {
        variables: { input: customerId },
        skip: !customerId,
        fetchPolicy: "network-only",
        async onCompleted(data) {
            setPerson(data.customer);
        }
    });

    useQuery(getOneVendorQuery, {
        variables: { input: vendorId },
        skip: !vendorId,
        fetchPolicy: "network-only",
        async onCompleted(data) {
            setPerson(data.vendor);
        }
    });


    
    const onClose = () => {
        setShowModal(false);
        setCustomerId(null);
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal-small' onDidDismiss={onClose} >
            <IonContent>
                <Wrapper>
                    <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold">Información de {person?.name}</h3>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>

                    <div className="flex items-center p-2 mb-1">
                        <IonIcon className="mr-4" icon={personOutline}  />
                        <h3 className="font-bold text-lg">Contacto</h3>
                    </div>

                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2">Correo electrónico</h3>
                        {person?.emails?.length ? person?.emails?.map(email => ( <p key={email} >{email}</p> )) : '-'}
                        <h3 className="font-bold text-gray mb-2 mt-4">Teléfono</h3>
                        {person?.phones?.length ? person?.phones?.map(phone => ( <p key={phone.number}>{phoneTypeName[phone.type]} ({phone.areaCode}){phone.number}</p> )): '-'}
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={receiptOutline}  />
                        <h3 className="font-bold text-lg">Facturación</h3>
                    </div>
                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2">Moneda</h3>
                        <p>{currencies.find(c => c.value.id === person?.bill?.currencyId)?.label || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Identifcador tributario</h3>
                        <p>{person?.bill?.nit || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Dirección</h3>
                        <p>{person?.address?.address || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">País</h3>
                        <p>{countries.find(c => c.id === person?.address?.countryId)?.name || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Zip</h3>
                        <p>{person?.address?.zip || '-'}</p>
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={cardOutline}  />
                        <h3 className="font-bold text-lg">Información bancaria</h3>
                    </div>
                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2 mt-4">Banco</h3>
                        <p>{person?.bank?.name || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Tipo de cuenta</h3>
                        <p>{bankAccountTypesName[person?.bank?.type] || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">No. de cuenta</h3>
                        <p>{person?.bank?.number || '-'}</p>
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={chatbubbleOutline}  />
                        <h3 className="font-bold text-lg mb-2 mt-4">Notas</h3>
                    </div>
                    <div className="ml-10">
                        <p>{person?.description || '-'}</p>
                    </div>

                </Wrapper>
           </IonContent>
        </IonModal>
    )
}

export default CustomerDetailModal;

