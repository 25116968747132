import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonIcon, IonButton, useIonAlert } from "@ionic/react";
import { flagOutline, fileTrayOutline, filterOutline, personOutline, eyeOutline, createOutline, trashOutline } from "ionicons/icons";
import Kpi from "../../components/kpi/Kpi";
import GridTable from '@nadavshaar/react-grid-table';
import { useQuery, useApolloClient } from '@apollo/client';
import InputDate from '../../components/input-date/InputDate';
import Placeholder from '../../components/placeholder/Placeholder';
import Header from '../../components/header/Header';
import { getDate, getRange } from '../../helpers/date'
import { useState } from 'react';
import { texts } from "../../theme/dataTabelTexts";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { getOneEmployeeQuery } from "../../graphql/employee/queries";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import EmployeeDetailModal from '../../components/modals/employee-detail-modal/EmployeeDetailModal';
import { useAuth } from '../../context/auth/AuthState';
import EmployeeModal from '../../components/modals/employee-modal/EmployeeModal';
import { useEmployeesService } from "../../graphql/employee/service";
import { useHistory, useParams } from "react-router";
import { typePayroll } from '../../helpers/catalogs'
import SearchTable from "../../components/search-table/SearchTable";
import { formatMoney, isEmpty } from "../../helpers/utils";

const EmployeePage = ({ match }) => {
  const history = useHistory();
  let { params } = match;
  const { authState } = useAuth();
  const { enterprise } = authState;
  const { deleteEmployee, dLoading, dError } = useEmployeesService();
  let [fromDate, setFromDate] = useState(getDate(new Date(getRange(-365)), false))
  let [toDate, setToDate] = useState(getDate(new Date(), false))
  
  const [options, setOptions] = useState(undefined);
  const { data, loading, error } = useQuery(getOneEmployeeQuery, { variables: { input: params.id, startDate: fromDate, endDate: toDate}, skip: !params.id, });
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [employeeId, setEmployeeId] = useState(params.id || null);
  const [showModal, setShowModal] = useState(false);
  const [present] = useIonAlert();

  const columns = [{
    id: 1,
    label: 'Fecha',
    field: 'payroll',
    getValue: ({ value }) => `${new Date(value?.startDate).toLocaleDateString()} - ${new Date(value?.endDate).toLocaleDateString()}`
  }, {
    id: 2,
    label: 'Nómina',
    field: 'payroll',
    width: '100px',
    getValue: ({ value }) => isEmpty(value?.month)
  }, {
    id: 3,
    label: 'Año',
    field: 'payroll',
    width: '80px',
    getValue: ({ value }) => isEmpty(value?.year)
  },
  {
    id: 4,
    label: 'Concepto',
    field: 'type',
    getValue: ({ value }) => isEmpty(typePayroll[value])
  },
  {
    id: 5,
    label: 'Monto',
    field: 'total',
    getValue: ({ value }) => formatMoney(enterprise, value)

  }, {
    id: 6,
    label: 'Descripción',
    field: 'legalBenefit',
    getValue: ({ value }) => isEmpty(value?.description)
  }]


  const tableProps = {
    isPaginated: true,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
  }



  const handleChangeDate = async e => {
    let { name, value } = e.target;
    let from = fromDate, to = toDate
    if (name == 'fromDate') {
      from = value
      setFromDate(from)
    }
    if (name == 'toDate') {
      to = value
      setToDate(to)
    }
  }

  const breads = [{
    title: 'Nómina',
    url: '/payrolls?tab=employee'
  }, {
    title: data?.employee?.name
  }];

  const handleDelete = () => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar colaborador',
      subHeader: 'Este colaborador se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este colaborador?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar colaborador',
          cssClass: 'delete',
          handler: () => {
            deleteEmployee({ variables: { input: params.id } });
            history.goBack();
          }
        }
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
    if (dError) {
      console.log(dError);
    }
  }

  const handleUpdate = () => {
    setEmployeeId(params.id);
    setShowModal(true);
  }
  const handleViewDetails = () => {
    setShowDetailModal(true);
  }

  const ItemsPopover = ({ onHide }) => {
    return (
      <>
        <OptionItem onHide={onHide} event={handleViewDetails} icon={eyeOutline} caption="Ver más información" />
        <OptionItem onHide={onHide} event={handleUpdate} icon={createOutline} caption="Editar" />
        <OptionItem onHide={onHide} event={handleDelete} icon={trashOutline} caption="Eliminar" className="danger" />
      </>)
  }
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);


  const EmployeeHeader = () => {
    return (
      <div className="shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white" >
        <div className="flex w-full justify-between items-center">
          <div className="flex">
            <div className="bg-gray-blue rounded-full p-4 sm:w-16 w-12 h-12 sm:h-16 hidden sm:flex justify-center items-center">
              <IonIcon color="light" size="large" icon={personOutline}></IonIcon>
            </div>
            <div className="flex flex-col sm:ml-4 justify-center">
              <h3 className="font-bold text-base sm:text-3xl">{data?.employee?.name}</h3>
              <h3 className="text-base">Ingreso {new Date(data?.employee?.startDate).toLocaleDateString()} <span className="mx-2">|</span> <span className={`${data?.employee?.status == 'ACTIVE' ? 'text-secondary' : 'text-danger'}`}>{data?.employee?.status == 'ACTIVE' ? 'Activo' : 'Inactivo'}</span></h3>
            </div>
          </div>
          <Kpi icon={flagOutline} className="hidden sm:flex" title={formatMoney(enterprise, data?.employee?.salary)} description="pago mes actual" />
          <Options />
        </div>
        <Kpi icon={flagOutline} className="mt-8 flex sm:hidden" title={formatMoney(enterprise, data?.employee?.salary)} description="pago mes actual" />

      </div>
    )
  }

  return (
    <IonPage>
      <Header title="Detalle colaborador" subtitle="" />
      <IonContent className="sfn-content">
        <BreadCrumbs breads={breads} className="md:-ml-6 ml-0 mb-12" />
        <EmployeeHeader />
        <section className="flex flex-col justify-around mt-8">
          <div className="flex flex-row">
            <div className="px-3">
              <InputDate label="Desde" value={fromDate} handleInputChange={handleChangeDate} name="fromDate" icon={false}  ></InputDate>
            </div>
            <div className="px-3">
              <InputDate label="Hasta" value={toDate} handleInputChange={handleChangeDate} name="toDate" icon={false}  ></InputDate>
            </div>
          </div>
        </section>
        <GridTable {...tableProps} className="sfn-data-table mt-8"
          isLoading={loading}
          rows={data?.employee?.records}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          columns={columns} texts={texts}
          components={{
            NoResults: () => (
              <Placeholder icon={fileTrayOutline} description='No tienes historial de pago' />
            ),
            Search: SearchTable
          }} />
        {showModal && <EmployeeModal employeeId={employeeId} setEmployeeId={setEmployeeId} showModal={showModal} setShowModal={setShowModal} />}
        {showDetailModal && <EmployeeDetailModal employee={data?.employee} auth={authState} employeeId={params.id} setEmployeeId={() => { }} showModal={showDetailModal} setShowModal={setShowDetailModal} />}
      </IonContent>
    </IonPage>
  )
}

export default EmployeePage;