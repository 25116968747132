import gql from 'graphql-tag'

export const getAllTransactionsQuery = gql`
	query transactions($options: Options, $page: Int) {
		transactions(options: $options, page: $page) {
			rows {
				id
				date
				description
				amount: currencyAmount
				type
				order
				notes
				attachments
				vendor {
					id
					name
				}
				customer {
					id
					name
				}
				account {
					id
					name
					currency {
						id
						name
						code
						symbol
					}
				}
				items {
					description
					amount
					type
					order
					vendor {
						id
						name
					}
					customer {
						id
						name
					}
					account {
						id
						name
						currency {
							id
							name
							code
							symbol
						}
					}
					taxes {
						id
						name
					}
				}
			}
		}
	}
`

export const getOneTransactionQuery = gql`
	query transaction($input: ID!) {
		transaction(id: $input) {
			id
			date
			description
			amount
			type
			order
			notes
			attachments
			vendorId: vendor {
				id
				name
			}
			customerId: customer {
				id
				name
			}
			accountId: account {
				id
				name
				currency {
					id
					name
					code
					symbol
				}
			}
			items {
				description
				amount
				type
				order
				taxRate { 
					id
					rate
					tax {
						accountId
						abbreviation
					}
				}
				vendorId: vendor {
					id
					name
				}
				customerId: customer {
					id
					name
				}
				accountId: account {
					value: id
					label: name
				}
				exchangeRate
			}
		}
	}
`
export const transactionCategoriesQuery = gql`
	query transactionCategories($type: EnumCategoryTypeGroup) {
		transactionCategories(type: $type)
	}`