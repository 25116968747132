import { IonButton, IonContent, IonIcon, IonNote, IonPage } from '@ionic/react';
import { useState } from 'react';
import Header from '../../../../components/header/Header';
import InputDate from '../../../../components/input-date/InputDate';
import { getDate, getRange, parseDate } from '../../../../helpers/date';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Select from 'react-select';
import { ListNoOptionsMessage } from '../../../../components/select-menu-components/SelectMenuComponents';
import GridTable from '@nadavshaar/react-grid-table';
import { useAuth } from '../../../../context/auth/AuthState';
import SearchTable from '../../../../components/search-table/SearchTable';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { calendarClearOutline, filterOutline, receiptOutline, refresh } from 'ionicons/icons';
import { texts } from '../../../../theme/dataTabelTexts';
import { useQuery } from '@apollo/client';
import './IncomeByCustomerPage.scss';
import { incomeByCustumer } from '../../../../graphql/customer/queries';
import { useForm } from '../../../../hooks/useForm';
import { generateYearsReports } from '../../../../helpers/utils';
import BreadCrumbs from '../../../../components/breadcrumbs/BreadCrumbs';


const initialState = {
  year: { value: new Date().getFullYear(), label: new Date().getFullYear() },
  from: getDate(new Date(new Date().getFullYear(), 0, 1), false),
  to: getDate(new Date(new Date().getFullYear(), 11, 31), false),
  min: `${new Date().getFullYear()}-01-01`,
  max: `${new Date().getFullYear()}-12-31`,
}

const IncomeByCustomerPage = () => {
  const { authState } = useAuth();
  const { enterprise } = authState;
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const { year, from, to, min, max } = formValues;
  const [filter, setFilter] = useState({ from, to })
  const { data, loading, error, refetch } = useQuery(incomeByCustumer, { variables: { ...filter }, fetchPolicy: 'cache-and-network' });

  const columns = [{
    id: 1,
    width: '40%',
    field: 'name',
    label: 'Nombre',
  }, {
    id: 2,
    field: 'total',
    label: 'Total',
    headerCellRenderer: ({ tableManager, column }) => (<div className="ml-auto mr-2">{column.label}</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          <p>{enterprise?.currency?.symbol}{data.total} {enterprise?.currency?.code}</p>
        </div>)
    }
  }, {
    id: 3,
    field: 'paid',
    label: 'Pagado',
    headerCellRenderer: ({ tableManager, column }) => (<div className="ml-auto mr-2">{column.label}</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          <p>{enterprise?.currency?.symbol}{data.paid} {enterprise?.currency?.code}</p>
        </div>)
    }
  },];

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  });

  const handleInputChangeSelect = (e, key) => {
    console.log(e, key)
    if (!key) {
      let { value, name } = e.target;
    } else {
      if (key == 'year') {
        if (e !== null) {
          handleInputChange(`${e?.value}-01-01`, 'min')
          handleInputChange(`${e?.value}-12-31`, 'max')
          handleInputChange(`${e?.value}-01-01`, 'from')
          handleInputChange(`${e?.value}-12-31`, 'to')
        } else {
          handleInputChange('', 'from')
          handleInputChange('', 'to')
        }
      }
      handleInputChange(e, key)
    }
  }

  const breads = [{
    title: 'Reportes',
    url: '/reports',
  }, {
    title: 'General de clientes',
    url: '/reports/income-by-customer'
  }];


  return (
    <IonPage>
      <Header title="Reporte general de clientes"></Header>
      <IonContent className="sfn-content report-profit" fullscreen>
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />

        <section className="flex flex-col sm:flex-row justify-around  mb-8 items-end">
          <div className="md:w-80 w-full">
            <p className="text-sm mb-2 hover:text-secondary w-80">Año</p>
            <div className="flex items-center dropdown-month">
              <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
              <Select isClearable={true} placeholder="Seleccione el año" value={year} options={generateYearsReports(new Date('1900-01-01'))} onChange={e => (handleInputChangeSelect(e, 'year'))} className="sfn-select md:w-60 w-full z-20" styles={sfnSelectTheme} />
            </div>
          </div>
          <InputDate min={min} max={max} className="sm:mr-4 mr-0" label="Desde" value={from} handleInputChange={handleInputChange} name="from" icon={false} />
          <InputDate min={min} max={max} label="Hasta" value={to} handleInputChange={handleInputChange} name="to" icon={false} />
          <IonButton className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" shape="round" onClick={() => { setFilter({ from: from !== '' ? from : undefined, to: to !== '' ? to: undefined }) }}>
            <IonIcon slot="start" icon={refresh} />
            Actualizar reporte
          </IonButton>
        </section>
        <section className="mt-20">
          <h3 className="font-bold text-base text-gray-blue sm:text-2xl mb-4">Período de {parseDate(filter.from).replace(/\//g, '-')} a {parseDate(filter.to).replace(/\//g, '-')} </h3>
        </section>
        <GridTable
          className="mt-10 sfn-data-table h-screen"
          isLoading={loading}
          columns={columns}
          rows={data?.incomeByCustumer}
          components={{
            NoResults: () => (
              <Placeholder icon={receiptOutline} description='No tienes información de clientes en el período de tiempo seleccionado.' />
            ),
            Search: SearchTable
          }}
          {...tableProps(false)}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
        // onRowClick={({data}) => { 
        //  goToInvoicePage(data.id);}}
        />
      </IonContent>
    </IonPage>
  )
}

export default IncomeByCustomerPage
