import { IonSegment, IonSegmentButton, IonLabel, IonBadge } from "@ionic/react";
import { useEffect } from "react";
import "./Tabs.scss";
const Tabs = ({
  tabs,
  selected,
  setSelected,
  url,
  path,
  history,
  className = "",
  scrollable = false,
}) => {
  const onChange = (e) => {
    const { value } = e.target;
    console.log(value);
    if (url) {
      history.replace(`${path}/?tab=${value}`);
    }
    setSelected(value);
  };
  return (
    <IonSegment
      className={`tabs ${className}`}
      value={selected}
      onIonChange={onChange}
      scrollable={scrollable}
      mode="md"
    >
      {tabs.length &&
        tabs.map((tab) => (
          <IonSegmentButton
            key={tab.value}
            value={tab.value}
            className={`${selected === tab.value ? "selected" : ""}`}
          >
            <IonLabel>
              {tab.title}{" "}
              {tab.total > -1 && <p className="badge">{tab.total}</p>}
            </IonLabel>
          </IonSegmentButton>
        ))}
    </IonSegment>
  );
};

export default Tabs;
