import { IonButton } from '@ionic/react';
import { setShowUpgradeMyPlanModal } from '../../store/myPlan';
import './Banner.scss';

export const Banner = ({ tlte, description, link }) => {
    
    return (
        <div className="sfn-banner h-20 flex justify-center items-center w-full text-white">
            <p>Tu plan gratis ha terminado. ¿Quieres aumentar tu plan?</p>
            <IonButton color="light" shape="round" fill="outline" className="sfn-button ml-4" onClick={() => setShowUpgradeMyPlanModal(true)}>
                Aumentar plan
            </IonButton>
        </div>
    );
}