export const getDate = (date, time = true) => {
    let d = new Date(date)
    if ((d.getTime() >= 0 || d.getTime() <= 0) == false) return '-'
    if (time == true) {
        return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}T${d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`}:${d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`}`
    } else {
        return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`
    }
}

export const startOfMonth = (date, offset = 0) => {
    date = new Date(date)
    date.setDate(1)
    date.setHours(0)
    date.setMinutes(0)
    date.setMilliseconds(0)
    date.setSeconds(0)
    let currentMonth = date.getMonth()
    date.setMonth(currentMonth + offset)
    return date
}

export const getRange = (days) => {
    let today = new Date();
    return today.setDate(today.getDate() + days)
}

export const formatDate = function (date = new Date(), locale = "es-GT", options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) {
    // gets date, locale? and options? parameters and returns formatted date
    let dateObject = new Date(date);
    return dateObject.toLocaleString(locale, options)
}

export const daysBetween = (d1, d2) => {
    // To set two dates to two variables
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    return Difference_In_Time / (1000 * 3600 * 24);

}

export const dateTimeFormat = (date = new Date(), lang = 'es-GT', options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) => {
    const _date = new Date(date);
    let dateUTC = new Date(Date.UTC(_date.getUTCFullYear(), _date.getUTCMonth(), _date.getUTCHours() > 0 && _date.getUTCMinutes() > 0 && _date.getUTCSeconds() > 0 ? _date.getUTCDate() : _date.getUTCDate()+1,
        _date.getUTCHours(), _date.getUTCMinutes(), _date.getUTCSeconds()));
    const formatter = new Intl.DateTimeFormat(lang, options);
    return {
        format: formatter.format(dateUTC),
        formatToParts: formatter.formatToParts(dateUTC),
    };
}

export const parseDate = (date = '') => {
    let d = new Date(date);
    let regex = /^[0-9]{4,}[-][0-9]{2}[-][0-9]{2}$/g

    return regex.test(date) ?
        new Date(d.getTime() + (d.getTimezoneOffset() * 60 * 1000)).toLocaleDateString() :
        d.toLocaleString();
}