import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, IonNote, useIonAlert, useIonToast } from '@ionic/react';
import Header from '../../components/header/Header';
import Kpi from '../../components/kpi/Kpi';
import { addCircleOutline, cashOutline, walletOutline, downloadOutline, play, eye, create, trash, documentOutline, duplicateOutline, filterOutline, receiptOutline, playOutline, createOutline, trashOutline, filter, eyeOutline } from 'ionicons/icons';
import { useForm } from '../../hooks/useForm';
import GridTable from '@nadavshaar/react-grid-table';
import OptionItem from '../../components/option-item/OptionItem';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import { useState, useEffect } from 'react';
import ExpenseModal from '../../components/modals/expense-modal/ExpenseModal';
import Select from 'react-select';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import InputDate from '../../components/input-date/InputDate';
import { useQuery, useApolloClient } from '@apollo/client';
import { formatSelectData, defaultValue, formatMoney, isEmpty } from '../../helpers/utils';
import { getAllBillsQuery } from '../../graphql/bill/queries';
import Placeholder from '../../components/placeholder/Placeholder';
import { dateTimeFormat, parseDate, getDate, getRange } from '../../helpers/date';
import { texts } from '../../theme/dataTabelTexts';
import ReceiptModal from '../../components/modals/receipt-modal/ReceiptModal';
import { getAllReceiptQuery, getAllMerchantsQuery } from '../../graphql/receipt/queries';
import { useReceiptsService } from '../../graphql/receipt/service';
import { downloadFile } from '../../helpers/uploadFiles';
import { useAuth } from '../../context/auth/AuthState';
import SearchTable from '../../components/search-table/SearchTable';
import { AuthStore } from '../../store/auth';



const ReceiptsPage = () => {
  const user = AuthStore.useState(s => s.user);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showPaymentRecordModal, setShowPaymentRecordModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [receiptId, setReceiptId] = useState(null)
  const [grandTotal, setGrandTotal] = useState(0);
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [options, setOptions] = useState({
    where: {
      // date: {
      //   _between: [fromDate, toDate]
      // }
    },
    order: [
      ['date', 'DESC']
    ]
  })

  const [optionsMerchant, setOptionsMerchant] = useState({
    where: {
      // date: {
      //   _between: [fromDate, toDate]
      // }
    }
  })

  const { data: dataReceipts, loading: receiptLoading, error: receiptError } = useQuery(getAllReceiptQuery, {
    variables: { options },
    fetchPolicy: 'cache-and-network',
  });

  const { data: dataMerchants, loading: merchantLoading, error: merchantError } = useQuery(getAllMerchantsQuery, {
    variables: { options: optionsMerchant }
  })

  const client = useApolloClient();
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const { deleteReceipt, dLoading, dError } = useReceiptsService();

  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
      <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" />
    </>
  )
  const Options = ({ data }) => (!['READ'].includes(user?.role?.permissions['expenses']) && <ButtonPopover options={ItemsPopover} data={data} />);

  const handleUpdate = (id) => {
    setReceiptId(id);
    setShowReceiptModal(true);
  }

  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar recibo',
      subHeader: 'Este recibo se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este recibo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: blah => {
          }
        },
        {
          text: 'Eliminar recibo',
          cssClass: 'delete',
          handler: async () => {
            await deleteReceipt({ variables: { input: id } });
            if (!dError) {
              presentToast({ message: `El recibo se ha eliminado exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            } else {
              presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
            }
          }
        }
      ],
      onDidDismiss: (e) => { },
    })
  }

  const columns = [
    {
      id: 1,
      field: 'date',
      label: 'Fecha de Registro',
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    }, {
      id: 2,
      field: 'merchant',
      label: 'Establecimiento',
      getValue: ({ value }) => isEmpty(value)
    }, {
      id: 3,
      field: 'category',
      label: 'Categoría',
      width: '180px',
      getValue: ({ value }) => isEmpty(value?.name)
    }, {
      id: 4,
      field: 'total',
      label: 'Total',
      width: '120px',
      cellRenderer: ({ data }) => (
        <div className="text-right w-full m-4" key={data.total}>
          <p>{formatMoney(enterprise, data.total)}</p>
          {(data.total !== data.convertedTotal) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedTotal)}</IonNote>}
        </div>
      )
    }, {
      id: 5,
      field: 'voucher',
      label: '',
      cellRenderer: ({ data }) => (
        <div className="text-center w-full">
        <IonButton className="sfn-button" fill="outline" shape="round" disabled={data.picture == ''} onClick={(e) =>{
          e.stopPropagation();
          downloadFile(client, data.picture)
        }} >Ver comprobante</IonButton></div>
      )
    }, {
      id: 'option',
      label: '',
      width: "max-content",
      pinned: true,
      sortable: true,
      resizable: false,
      cellRenderer: Options
    }
  ];

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    let where = {};
    if (selectedOption) {
      where = {
        ...options.where || {},
        merchant: selectedOption.value
      }
      setOptions({ ...options, where });
    } else {
      where = {
        ...options.where || {}
      }
      where.merchant = undefined;
      setOptions({ where });
    }
  };

  const filterDate = (e) => {
    let from = fromDate, to = toDate
    if (e.target.name == 'date') {
      setFromDate(e.target.value)
      from = e.target.value
    } else {
      setToDate(e.target.value)
      to = e.target.value
    }
    
    const  between = [from, to]?.filter(v => v !== '').length ? [from, to]?.filter(v => v !== '') : undefined
    let where
    if(between) {
      where = {
        ...options.where,
        date: {
          _between: between
        },
      };
    }

    setOptions({ ...options, where })

    // delete where?.merchant;
    // setSelectedOption(null);
    // setOptionsMerchant({ where });
  }

 


  return (
    <IonPage>
      <Header title="Recibos" subtitle={`Cantidad de recibos: ${dataReceipts?.receipts?.rows.length || 0}`}></Header>

      <IonContent className="sfn-content">
        {/* <div className="flex flex-col md:flex-row justify-between items-center my-8">
          <Kpi icon={cashOutline} title={grandTotal} description="Monto total recibos" />
        </div> */}
        <div className="flex flex-col justify-between items-center md:flex-row  md:items-center">
          <div className="mt-5">
            <p className="text-sm mb-2 hover:text-secondary w-80">Establecimiento</p>
            <Select isClearable={true} value={selectedOption} onChange={handleChange} options={dataMerchants?.merchants} placeholder="Selecciona establecimiento" className="sfn-select z-10" styles={sfnSelectTheme} />
          </div>

          <InputDate label="Desde" value={fromDate} handleInputChange={e => filterDate(e)} name="date" icon={false} />
          <InputDate label="Hasta" value={toDate} handleInputChange={e => filterDate(e)} name="dueDate" icon={false} />

          <div className="buttons flex self-end  items-center justify-center">
            {/* <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => { }}>
              <IonIcon slot="start" icon={downloadOutline} />
              Descargar
            </IonButton> */}
            <IonButton disabled={['READ'].includes(user?.role?.permissions['expenses'])} className="sfn-button" shape="round" onClick={() => { setShowReceiptModal(true) }}>
              <IonIcon slot="start" icon={addCircleOutline} />
              Agregar recibo
            </IonButton>
          </div>
        </div>

        <GridTable
          className="mt-10 sfn-data-table"
          columns={columns}
          rows={dataReceipts?.receipts?.rows}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          isLoading={receiptLoading}
          components={{
            NoResults: () => (
              <Placeholder icon={receiptOutline} description='Aún no tienes recibos agregados haz clic en el botón "Agregar recibo"' />
            ),
            Search: SearchTable
          }}
          {...tableProps(dataReceipts?.receipts?.rows.length ? true : false)} />


        {showReceiptModal && <ReceiptModal receiptId={receiptId} setReceiptId={setReceiptId} showReceiptModal={showReceiptModal} setShowReceiptModal={setShowReceiptModal} />}
      </IonContent>
    </IonPage>
  );
};

export default ReceiptsPage;
