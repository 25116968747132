import { useApolloClient } from '@apollo/client';
import domtoimage from 'dom-to-image';
import printJS from 'print-js';
import { accountTypes, typePharaseAndStage } from './catalogs';
import { groupBy } from 'lodash';

export const formatSelectData = ({ value, label }, data) => {
  if (!data) return;
  return data.map((d) => {
    return {
      value: d[value],
      label: d[label],
    };
  });
};

const print = (element, props = {}) => {
  domtoimage
    .toPng(element, props)
    .then(function (blob) {
      printJS({ printable: blob, type: 'image', base64: true });
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
};

export const generatePDF = (id) => {
  let element = document.getElementById(id);
  print(element);
};

export const defaultValue = ({ value, label }, data, selected = '') => {
  return !selected
    ? null
    : (() => {
        let i = data.filter((d) => d[value] === selected);
        return i.length ? { value: i[0][value], label: i[0][label] } : null;
      })();
};

export const roundValue = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2);
};

export const formatSelect = (data, lab = 'name') => {
  data = data.map((el) => {
    let opt = {
      value: el,
      label: el[lab],
    };
    return opt;
  });
  return data;
};

export const getAvatar = (name = '') => {
  let words = name.trim().split(/\s/);
  let avatar = '';

  for (let i = 0; i < words.length && i < 2; i++) {
    avatar += words[i][0];
  }

  return avatar.toUpperCase();
};

export const getBackground = (str, s = 100, l = 61) => {
  let hash = 0;
  let length = str.length < 5 ? str.length : 5;
  for (let i = 0; i < length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const generateYears = (date, value = 5) => {
  const createdAt = new Date(date).getFullYear();
  const current = new Date().getFullYear();
  let data = [];
  for (let index = 0; index < value; index++) {
    if (current == createdAt) {
      data.push({ value: current + index, label: current + index });
    } else {
      data.push({ value: createdAt + index, label: createdAt + index });
    }
  }
  return data;
};

export const generateYearsReports = (date) => {
  let max = new Date().getFullYear() + 20;
  let data = [];
  for (let index = 1990; index <= max; index++) {
    data.push({ value: index, label: index });
    // if (current == createdAt) {
    // } else {
    //   data.push({ value: createdAt + index, label: createdAt + index });
    // }
  }
  return data;
};

export const formatAccountTypes = () => {
  let groupedData = groupBy(accountTypes, 'group');
  return Object.keys(groupedData).map((key) => {
    return {
      label: key,
      options: groupedData[key].map((acc) => {
        return {
          label: acc.name,
          value: acc,
        };
      }),
    };
  });
};

export const getRoundExchangeRate = (rate = 0) => {
  let val = new Intl.NumberFormat('en-US', {
    useGrouping: false,
    minimumSignificantDigits: 6,
    maximumSignificantDigits: 6,
  }).format(1 / rate);

  return Number(val);
};

export const tableProps = (show, options = {}) => ({
  showSearch: show,
  showRowsInformation: show,
  isPaginated: show,
  showColumnVisibilityManager: show,
  ...options,
});

export const fixedFloat = (value) => {
  return new Intl.NumberFormat('en-US', {
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const isEmpty = (field) => {
  return !Boolean(field) ? ' - - -' : field;
};

export const formatMoney = (enterprise, value, format = false) => {
  let currency = enterprise?.currency;
  let val = parseFloat(`${value}`.replaceAll(',', ''));
  let amount = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(val));
  
  return `${val < 0 ? '-' : ''}${currency?.symbol || ''}${amount} ${
    currency?.code || ''
  }`;
};


export const setPhraseAndStage = (data = [], update = false) => {
  let value = []

  if (update) {
      value = data.map((e, index) => {
          e = e ? typePharaseAndStage[index] : undefined
          return e
      }).filter(e => e !== undefined)
  } else {
      value = typePharaseAndStage.map(e => {
          return data.find(f => f?.escenario == e?.escenario && f?.tipo == e?.tipo) ? true : false
      })

  }
  return value
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});