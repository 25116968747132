import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

export const authLink = setContext((_, { headers }) => {
  let token;
  token = localStorage.getItem("token");
  if (!token) return { headers };

  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem("token") || "",
      "x-timezone-offset": new Date().getTimezoneOffset(),
      // "x-timezone": new Date().getTimezoneOffset(),
    },
  };
});

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);
