import { useQuery } from "@apollo/client";
import { IonContent, IonIcon, IonModal } from "@ionic/react";
import { cardOutline, chatbubbleOutline, closeOutline, personOutline, receiptOutline } from "ionicons/icons";
import { useState } from "react";
import { getOneCustomerQuery } from "../../../graphql/customer/queries";
import { getOneEmployeeQuery } from "../../../graphql/employee/queries";
import { getOneVendorQuery } from "../../../graphql/vendor/queries";
import { countries, currencies, phoneTypeName, bankAccountTypesName, typeEmployees} from "../../../helpers/catalogs";
import Wrapper from "../../wrapper/Wrapper";

const CustomerDetailModal = ({ employee, showModal, setShowModal, employeeId = null, setEmployeeId, auth}) => {
    const {enterprise} = auth;
    
    const onClose = () => {
        setShowModal(false);
        setEmployeeId(null);
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal-small' onDidDismiss={onClose} >
            <IonContent>
                <Wrapper>
                    <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold">Información de {employee?.name}</h3>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>

                    <div className="flex items-center p-2 mb-1">
                        <IonIcon className="mr-4" icon={personOutline}  />
                        <h3 className="font-bold text-lg">Colaborador</h3>
                    </div>

                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2">Tipo</h3>
                        <p>{typeEmployees.find(t => t.value === employee?.type)?.label}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Salario</h3>
                        <p>{`${enterprise?.currency?.symbol} ${(Math.round(employee?.salary * 100) / 100).toFixed(2)}`}</p>
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={receiptOutline}  />
                        <h3 className="font-bold text-lg">Información general</h3>
                    </div>
                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2">DPI</h3>
                        <p>{employee?.bill?.dni}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Identifcador tributario</h3>
                        <p>{employee?.bill?.nit}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Dirección</h3>
                        <p>{employee?.bill?.address}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">País</h3>
                        <p>{countries.find(c => c.id === employee?.bill?.countryId)?.name}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Ciudad</h3>
                        <p>{employee?.bill?.state}</p>
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={personOutline}  />
                        <h3 className="font-bold text-lg">Contacto</h3>
                    </div>
                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2 mt-4">Correo electrónico</h3>
                        {employee?.contact?.emails?.map(email => ( <p key={email} >{email}</p> ))}
                        <h3 className="font-bold text-gray mb-2 mt-4">Teléfono</h3>
                        {employee?.contact?.phones?.map(phone => ( <p key={phone.number}>{phoneTypeName[phone.type]} ({phone.areaCode}){phone.number}</p> ))}
                        <h3 className="font-bold text-gray mb-2 mt-4">Dirección</h3>
                        <p>{employee?.contact?.address}</p>
                    </div>

                    <div className="flex items-center p-2 mb-1 mt-2">
                        <IonIcon className="mr-4" icon={chatbubbleOutline}  />
                        <h3 className="font-bold text-lg">Otra información</h3>
                    </div>
                    <div className="ml-10">
                        <h3 className="font-bold text-gray mb-2 mt-4">Estado civil</h3>
                        <p>{employee?.extra?.civilStatus}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Nacionalidad</h3>
                        <p>{employee?.extra?.nationality}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Tipo de sangre</h3>
                        <p>{employee?.extra?.bloodType}</p>
                    </div>

                </Wrapper>
           </IonContent>
        </IonModal>
    )
}

export default CustomerDetailModal;

