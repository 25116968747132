import { IonIcon, useIonAlert, IonButton } from '@ionic/react';
import { addCircleOutline, playOutline, createOutline, trashOutline, calendarClearOutline, peopleOutline, filterOutline } from 'ionicons/icons';
import { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import ButtonPopover from '../../../../components/button-popover/ButtonPopover';
import OptionItem from '../../../../components/option-item/OptionItem';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { texts } from '../../../../theme/dataTabelTexts';
import { useHistory } from 'react-router';
import InputDate from '../../../../components/input-date/InputDate';
import './Employee.scss';
import EmployeeModal from '../../../../components/modals/employee-modal/EmployeeModal';
import { getAllEmployeesQuery } from '../../../../graphql/employee/queries';
import { useQuery } from '@apollo/client';
import { useEmployeesService } from '../../../../graphql/employee/service';
import { useForm } from '../../../../hooks/useForm';
import { typeEmployees, listStatusEmployee } from '../../../../helpers/catalogs';
import { dateTimeFormat, parseDate, formatDate, getDate, getRange } from '../../../../helpers/date'
import { ListNoOptionsMessage } from '../../../../components/select-menu-components/SelectMenuComponents';
import Avatar from '../../../../components/avatar/Avatar';
import SearchTable from '../../../../components/search-table/SearchTable';
import SettingPayrollModal from '../../../../components/modals/setting-payroll-modal/SettingPayrollModal';
import WarningPayrollModal from '../../../../components/modals/warning-payroll-modal/WarningPayrollModal';
import { formatMoney, isEmpty } from '../../../../helpers/utils';

const initialState = {
    type: '',
    fromDate: '',
    toDate: '',
}
const Employee = ({ enterprise, setShowSettingPayrollModal, refetchIndicators}) => {
    const { payroll } = enterprise;
    const {formValues, handleInputChange, reset} = useForm(initialState);
    const { type, fromDate, toDate, status } = formValues;
    const history = useHistory();
    const [present] = useIonAlert();
    const [showModal, setShowModal] = useState(false);
    const [employeeId, setEmployeeId] = useState(null)
    const [options, setOptions] = useState({});
    const { data, loading, error } = useQuery(getAllEmployeesQuery, { variables: { options }, fetchPolicy: 'cache-and-network' });
    const { deleteEmployee, dLoading, dError } = useEmployeesService();
    const [showWarningPayrollModal, setShowWarningPayrollModal] = useState(false)

    const ItemsPopover = ({ onHide, data }) => {
        return (
            <>
                <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
                <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" />
            </>)
    }

    const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);


    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Colaborador',
            cellRenderer: ({ data: user }) => {
                return (
                    <div className="flex flex-row self-auto items-center p-4">
                        <Avatar user={user}></Avatar>
                        <div className="flex-initial">{isEmpty(user?.name)}</div>
                    </div>
                )
            }
        },
        {
            id: 2,
            field: 'status',
            label: 'Estado',
            getValue: ({ value }) => `${value == 'ACTIVE' ? 'Activo' : 'Inactivo'}`
        },
        {
            id: 3,
            field: 'startDate',
            label: 'Fecha ingreso',
            getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
        },
        {
            id: 4,
            field: 'contact',
            label: 'Teléfono',
            getValue: ({ value }) => value.phones != null ? `${'(' + value.phones[0].areaCode + ')' + ' ' + value.phones[0].number}` : ' - - -'
        },
        {
            id: 5,
            field: 'contact',
            label: 'Correo electrónico',
            getValue: ({ value }) => value.emails != null ? value.emails[0] : ' - - -'
        },
        {
            id: 6,
            field: 'salary',
            label: 'Salario',
            headerCellRenderer: () => (<div className="ml-auto mr-2">Salario</div>),
            cellRenderer: ({ data }) => {
                return (
                    <div className="text-right w-full m-4">
                        <p>{formatMoney(enterprise, data?.salary)}</p>
                    </div>)
            },
        },
        {
            id: 'option',
            label: '',
            width: "max-content",
            pinned: true,
            sortable: true,
            resizable: false,
            cellRenderer: Options
        },

    ];

    const goToEmployeePage = (id) => {
        history.push(`/employee/${id}`);
    }

    const handleDelete = (id) => {
        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: 'Eliminar colaborador',
            subHeader: 'Este colaborador se eliminará permanentemente',
            message: '¿Esta seguro de eliminar a este colaborador?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Eliminar colaborador',
                    cssClass: 'delete',
                    handler: () => {
                        deleteEmployee({ variables: { input: id } });
                        refetchIndicators();
                    }
                }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
        })
        if (dError) {
            console.log(dError);
        }
    }

    const handleUpdate = (id) => {
        setEmployeeId(id);
        setShowModal(true);
    }

    const tableProps = (show) => ({
        showSearch: show,
        showRowsInformation: show,
        isPaginated: show,
        showColumnVisibilityManager: show
    })

    const handleAddEmployee = () => {
        setEmployeeId(null);
        setShowModal(true)
    }

    const onRowClick = ({ event, data }) => {
        event.stopPropagation();
        goToEmployeePage(data.id);
    }

    const filters = (e, name) => {
        handleInputChange(e, name);
        setOptions({ ...options, where: { ...options.where, [name]: e?.value } });
    }

    const handleChangeDate = async e => {
        let { name, value } = e.target;
        let from = fromDate, to = toDate
        if (name == 'fromDate') {
            from = value
        }
        if (name == 'toDate') {
            to = value
        }
        handleInputChange(value, name);
        let where = {
            ...options.where,
            startDate: {
                _between: [from, to]
            }
        }
        setOptions({ ...options, where });

    }

    const goToPayroll = () => {
        if (!payroll?.configured) {
            setShowWarningPayrollModal(true);
        } else {
            history.push('/payroll');
        }
    }

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between items-end my-8">
                <div className="mb-1 sm:w-80 w-full">
                    <p className="text-sm mb-2 hover:text-secondary w-96">Tipo de colaborador</p>
                    <Select isClearable={true} placeholder="Seleccione el tipo de colaborador"
                        value={type}
                        onChange={e => filters(e, 'type')}
                        options={typeEmployees}
                        className="sfn-select z-10" styles={sfnSelectTheme}
                        components={
                            {
                                NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay tipo de colaborador" })
                            }
                        }
                    />
                </div>
                <div className="mb-1 sm:w-80 w-full">
                    <p className="text-sm mb-2 hover:text-secondary w-96">Estado de colaborador</p>
                    <Select isClearable={true} placeholder="Seleccione el estado"
                        value={status}
                        onChange={e => filters(e, 'status')}
                        options={listStatusEmployee}
                        className="sfn-select z-10" styles={sfnSelectTheme}
                        components={
                            {
                                NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay estado de colaborador" })
                            }
                        }
                    />
                </div>
                {/* <InputDate label="Desde" value={fromDate} handleInputChange={handleChangeDate} name="fromDate" icon={false}></InputDate>
                <InputDate label="Hasta" value={toDate} handleInputChange={handleChangeDate} name="toDate" icon={false}></InputDate> */}
                <div className="flex mt-2 md:flex-row lg:flex-row flex-col items-center md:items-end sm:w-auto w-full sm:mt-0 mt-8">
                    <IonButton className="sfn-button sm:mr-8 " shape="round" fill="outline" onClick={goToPayroll}>
                        Calcular nómina
                    </IonButton>
                    <IonButton className="sfn-button" shape="round" onClick={handleAddEmployee}>
                        <IonIcon slot="start" icon={addCircleOutline} />
                        Agregar colaborador
                    </IonButton>
                </div>
            </div>
            <GridTable
                className="max-h-80 sfn-data-table"
                columns={columns}
                rows={data?.employees?.rows}
                texts={texts}
                isLoading={loading}
                onRowClick={onRowClick}
                components={{
                    NoResults: () => (
                        <Placeholder icon={peopleOutline} description='Aún no tienes colaboradores agregados, haz clic en el botón "Agregar colaborador"' />
                    ),
                    Search: SearchTable
                }}
                {...tableProps(data?.employees?.rows?.length ? true : false)}
                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            />
            {showWarningPayrollModal && <WarningPayrollModal showModal={showWarningPayrollModal} setShowModal={setShowWarningPayrollModal} setShowSettingPayrollModal={setShowSettingPayrollModal} />}
            {showModal && <EmployeeModal showModal={showModal} setShowModal={setShowModal} employeeId={employeeId} setEmployeeId={setEmployeeId} enterprise={enterprise} hookClose={() => {refetchIndicators()}} />}
        </>
    )
}

export default Employee