import {IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, useIonToast} from '@ionic/react';
import { closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import Select from 'react-select';
import { sfnSelectTheme } from '../../..//theme/sfnSelectTheme';
import { useBankAccountsService } from '../../../graphql/bankAccount/service'
import { bankAccountTypes, currencies } from '../../../helpers/catalogs'
import SfnButton from '../../button/Button';
import { getOneBankAccountQuery } from '../../../graphql/bankAccount/queries';
import { useQuery } from '@apollo/client';
import { formatSelectData, defaultValue } from '../../../helpers/utils';
import { validationMap } from './validation';
import { validate, validateKey } from '../../../helpers/validations';

const initialState = {
    type: '',
    number: '',
    bank: '',
    alias: '',
    currencyId: '',
}

const BankAccountModal = ({showModal, setShowModal, bankId, setBankId}) => {
    const { createBankAccount, updateBankAccount, cError,cLoading, uError, uLoading } = useBankAccountsService();
    const {formValues, handleInputChange, reset} = useForm(JSON.parse(JSON.stringify(initialState)));
    const {bank, type, number, alias, currencyId} = formValues;
    const [selectedOption, setSelectedOption ] = useState(null);
    const [present] = useIonToast();
    const [valid, setValid] = useState({});

    let {data, loading, error} = useQuery(getOneBankAccountQuery, {
        variables: {input: bankId},
        skip: !bankId, 
        fetchPolicy: 'no-cache',
        async onCompleted(data) {
            data = JSON.parse(JSON.stringify(data));
            data.bankAccount.type = defaultValue({value: 'id', label: 'name' }, bankAccountTypes, data.bankAccount.type);
            data.bankAccount.currencyId = currencies.find(c => c.value.id === data.bankAccount.currency.id)
            delete data.bankAccount.currency
            reset(data.bankAccount);
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = {
            bank,
            alias,
            type: type.value,
            number,
            currencyId:  formValues?.currencyId?.value?.id || ''
        }
        let valid = validate(input, validationMap);
        setValid(valid);
        if (!valid.valid) {
            present({ message: 'Debes llenar todos los campos obligatorios', color: "warning", mode: "md", duration: 4000});
            return;
        }
        if (bankId) {
            await updateBankAccount({ variables: { input: {...input, id: bankId} } })
        } else {
            await createBankAccount({ variables: { input } })
        }
        if (!uLoading && !cLoading) {
            if (cError || uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            } 
            present({ message: `El banco se ${bankId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration:4000 })
            reset();
            onClose();
            e.target.reset();
        }
    }

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const onClose = () => {
        setBankId(null);
        reset(JSON.parse(JSON.stringify(initialState)));
        setShowModal(false);
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <Wrapper>
            <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                <h2 className="text-center text-xl font-bold">{bankId ? 'Editar' : 'Agregar'} cuenta bancaria</h2>
                <p className="text-center text-base">Completa el siguiente formulario para {bankId ? 'editar la' : 'agregar una nueva'} cuenta bancaria</p>

                <form id='bank-form' onSubmit={handleSubmit} className="h-full p-2">
                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Nombre de banco<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="bank" type="bank" placeholder="Ingresa el nombre del banco" onIonChange={handleInputChange} value={bank} required />
                    </IonItem>

                    <div className="sfn-input mb-4" mode="md" lines="none">
                        <span position="stacked" className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'type') ? 'text-danger' : ''}`}>Tipo de cuenta<span className="text-base text-danger">*</span></span>
                        <Select name='type' onChange={(e) => handleInputChange(e, 'type')} placeholder="Tipo de cuenta" value={type} options={bankAccountTypes.map(o => {return {label: o.name, value: o.id}})} className="sfn-select z-auto w-full mt-2" styles={sfnSelectTheme} />
                    </div>

                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Número de cuenta<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="number" type="number" placeholder="Indica el número de cuenta" onIonChange={handleInputChange} value={number} required />
                    </IonItem>

                    <div className="sfn-input mb-4">
                        <p className="text-sm mb-2 hover:text-secondary w-80 ">Moneda<span className="text-base text-danger">*</span></p>
                        <Select
                            placeholder="Selecciona moneda"
                            value={currencyId}
                            onChange={(e) => handleInputChange(e, 'currencyId')}
                            options={currencies}
                            className="sfn-select"
                            styles={sfnSelectTheme}
                        />
                    </div>
                    
                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Alias (Opcional) </IonLabel>
                        <IonInput name="alias" type="text" placeholder="Indica un alias para tu cuenta" onIonChange={handleInputChange} value={alias} />
                    </IonItem>
                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}> 
                            Cancelar
                        </IonButton>
                        <SfnButton form="bank-form" label={bankId ? 'Guardar cambios' : 'Guardar cuenta bancaria'} loading={cLoading || uLoading} />
                    </div>
                </form>
            </Wrapper>
        </IonModal>

    )

}

export default BankAccountModal;