// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createTransactionMutation,
	deleteTransactionMutation,
	updateTransactionMutation,
} from './mutations'
import { getAllTransactionsQuery } from './queries'

export const useTransactionsService = () => {
	const [createTransaction, { loading: cLoading, error: cError }] = useMutation(
		createTransactionMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createTransaction } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTransactionsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllTransactionsQuery,
					data: {
						transactions: { rows: [createTransaction, ...data.transactions.rows] },
					},
				})
			},
		},
	)

	const [updateTransaction, { loading: uLoading, error: uError }] = useMutation(
		updateTransactionMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateTransaction } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTransactionsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllTransactionsQuery,
					data: {
						transactions: {
							rows: data.transactions.rows.map((transaction) =>
								transaction.id === updateTransaction.id ? updateTransaction : transaction,
							),
						},
					},
				})
			},
		},
	)

	const [deleteTransaction, { loading: dLoading, error: dError }] = useMutation(
		deleteTransactionMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteTransaction } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTransactionsQuery });
				console.log(data);
					if(deleteTransaction){
					// reescribir ese objeto
					cache.writeQuery({
						query: getAllTransactionsQuery,
						data: {
							transactions: {
								rows: data?.transactions?.rows?.filter(
									(transaction) => transaction.id !== deleteTransaction.id,
								),
							},
						},
					})
				}
			},
		},
	)

	return {
		createTransaction,
		cLoading,
		cError,

		updateTransaction,
		uLoading,
		uError,

		deleteTransaction,
		dLoading,
		dError,
	}
}
