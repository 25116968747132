import { useQuery } from "@apollo/client";
import { IonContent, IonIcon, IonModal } from "@ionic/react"
import { closeOutline } from "ionicons/icons";
import { getOneProductQuery } from "../../../graphql/product/queries";
import Wrapper from "../../wrapper/Wrapper"

const ProductDetailModal = ({ showModal, setShowModal, productId=null}) => {

    const { loading, data } = useQuery(getOneProductQuery, {
        variables: { input: productId },
        skip: !productId,
        fetchPolicy: "no-cache"
      });

    const {product} = data || [];

    const onClose = () => {
        setShowModal(false);
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal-small' onDidDismiss={onClose} >
            <IonContent>
                <Wrapper>
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">Información de Producto</h3>
                        <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>

                    <div className="ml-4">
                        <h3 className="font-bold text-gray mb-2 mt-4">Nombre producto/servicio</h3>
                        <p>{product?.name}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Precio</h3>
                        <p>{product?.price}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">SKU</h3>
                        <p>{product?.sku || '-'}</p>
                        <h3 className="font-bold text-gray mb-2 mt-4">Descripción</h3>
                        <p>{product?.description || '-'}</p>
                        {product?.expenseAccountId && 
                        <><h3 className="font-bold text-gray mb-2 mt-4">Categoría (compra)</h3>
                        <p>{product?.expenseAccountId.label || '-'}</p></>}
                        {product?.incomeAccountId && 
                        <><h3 className="font-bold text-gray mb-2 mt-4">Categoría (venta)</h3>
                        <p>{product?.incomeAccountId.label || '-'}</p></>}     
                        <h3 className="font-bold text-gray mb-2 mt-4">Impuestos</h3>
                        {!product?.taxes.length && '-'}
                        {product?.taxes?.length ? product?.taxes?.map(tax => ( <span className="bg-gray-blue text-white mr-4 py-1 px-2 rounded text-sm" key={tax.label} >{tax.label}</span> )) : ''}
                    </div>


                </Wrapper>
            </IonContent>
        </IonModal>
                    
    )
}

export default ProductDetailModal
