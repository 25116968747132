import { IonContent, IonNote, IonPage, IonButton, IonIcon } from "@ionic/react";
import React, { useMemo, useState } from 'react';
import {
  downloadOutline,
  pricetagsOutline,
  eyeOutline,
  receiptOutline,
  pieChartOutline,
} from "ionicons/icons";
import { useQuery } from "@apollo/client";
import "./DashboardPage.scss";
import Header from "../../components/header/Header";
import { generatePDF } from "../../helpers/utils";
import PaymentRecordModal from "../../components/modals/payment-record-modal/PaymentRecordModal";
import "./DashboardPage.scss";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import Invoice from "./components/Invoice";
import { statusName } from "../../helpers/catalogs";
import { useAuth } from "../../context/auth/AuthState";
import { getDate, getRange } from "../../helpers/date";
import CostsExpenses from "./components/CostsExpenses";
import CashFlow from "./components/CashFlow";
import {
  expenseChartData,
  cashFlowChartData,
  profitLossChartData,
  indicators,
} from "./chart";
import ProfitLoss from "./components/ProfitLoss";
import CurrentMonthIncome from "./components/CurrentMonthIncome";
import Select from "react-select";
import { sfnSelectTheme } from "../../theme/sfnSelectTheme";
import NetIncome from "./components/NetIncome";

const DashboardPage = () => {
  const [date, setDate] = useState(getDate(new Date(getRange(-1)), false));
  const [dueDate, setDueDate] = useState(
    getDate(new Date().setDate(new Date(date).getDate() + 30), false)
  );
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [indicator, setIndicator] = useState({
    value: "ALL",
    label: "Todos los indicadores",
  });
  const [options, setOptions] = useState({
    where: {
      limit: 5,
    },
  });

  const handleChangeIndicator = (e) => {
    setIndicator(e);
  };

  return (
    <IonPage>
      <Header
        title="Dashboard"
        subtitle={`¡Hola, ${authState.name
          }! Última actualización ${new Date().toLocaleString()}`}
      ></Header>
      <IonContent className="sfn-content sfn-dasboard mb-8">
        <div id="sfn-dashboard-content">
          {/* <h3 className="text-xl my-8"><strong>¡Hola, {`${authState.name}`}!</strong> Última actualización {`${new Date().toLocaleString()}`}</h3> */}
          <div className="flex items-center justify-between">
            <div className="sm:w-80 w-60">
              <p className="text-sm mb-2 hover:text-secondary w-80">Mostrar</p>
              <Select
                value={indicator}
                onChange={handleChangeIndicator}
                options={indicators}
                placeholder="Selecciona comerciante"
                className="sfn-select z-10"
                styles={sfnSelectTheme}
              />
            </div>
            <div className="flex md:items-center self-end h-12 md:h-full md:my-0">
              {/* <IonButton
                className="sfn-button sm:w-60 w-full normal-case font-bold"
                fill="none"
                shape="round"
                onClick={() => {}}
              >
                <IonIcon slot="start" icon={downloadOutline} />
                Descargar
              </IonButton> */}
            </div>
          </div>

          {/* Ingresos mes actual */}
          {["ALL", "CURRENT_MONTH_INCOME"].includes(indicator?.value) && (
            <CurrentMonthIncome enterprise={enterprise} />
          )}

          {/* Flujo de caja */}
          {/* {["ALL", "CASH_FLOW"].includes(indicator?.value) && (
            <>
              <hr className="w-full border-gray-space  my-16 border-solid" />
              <CashFlow enterprise={enterprise} />
              <hr className="w-full border-gray-space  my-16 border-solid" />
            </>
          )} */}

          {/* Ganancias y perdidas */}
          {["ALL", "PROFIT_LOSS"].includes(indicator?.value) && (
            <>
              <ProfitLoss enterprise={enterprise} />
              <hr className="w-full border-gray-space  my-16 border-solid" />
            </>
          )}

          {/* Costos y gastos */}
          {["ALL", "COST_EXPENSE"].includes(indicator?.value) && (
            <>
              <CostsExpenses enterprise={enterprise} />
              <hr className="w-full border-gray-space  my-16 border-solid" />
            </>
          )}

          {/*Cuentas por cobrar y cuentas por pagar */}
          {["ALL", "INVOICE"].includes(indicator?.value) && (
            <>
              <Invoice
                unpaid={[]}
                loading={false}
                overdue={[]}
                enterprise={enterprise}
              />
              <hr className="w-full border-gray-space  my-16 border-solid" />
            </>
          )}

          {["ALL", "NET_INCOME"].includes(indicator?.value) && (
            <NetIncome enterprise={enterprise} />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DashboardPage;
