import { IonButton, IonIcon, IonProgressBar, useIonToast } from '@ionic/react'
import { closeCircleOutline, closeOutline, documentOutline, trashOutline, tvSharp } from "ionicons/icons"
import { useEffect, useState } from 'react';
import './UploadFiles.scss';


const UploadFiles = ({files, setFiles, multiple = false, accept}) => {
    const [present] = useIonToast();

    const handleFileInput = e => {
        let { files: fileList } = e.target;
        if(multiple) {
            setFiles([...files, ...Object.keys(fileList).map(i => fileList[i])]);
        }else if(!multiple && fileList[0]) {
            if(fileList[0].size >= 5000000) {
                present({ message: `El archivo debe ser menor a 5mb 😯`, color: "warning", mode: "md", duration: 4000 });
                return;
            }
            setFiles([fileList[0]]);
        }
    }
    const deleteFiles = (file, i) => {
        document.getElementById('file-upload').value = ''
        setFiles([...files.filter((f, index) => index !== i)])
    }

    const cancelUpload = (file, index) => {
        file.cancel("Operation canceled.");
        delete file.cancel;
        file.upload = false;
        file.progress = 0;
        files[index] = file;
        setFiles([...files]);
    }

    return (
        <div id="uploadContent">
            <div className="uploader">
                <div className="file-btn">
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Adjuntar archivo
                    </label>
                    <input id="file-upload" type="file" multiple={multiple} accept={accept} onChange={handleFileInput} />
                </div>
                {Array.isArray(files) && files.length ?
                    (<ul className="list mt-4 shadow">
                        {files.map((file, index) => (
                            <li className="item" key={index}>
                                <IonIcon icon={documentOutline} className="flex ml-auto"></IonIcon>
                                <p>{file?.name}</p>
                                {!file?.upload &&
                                    (
                                        <div className="actions">
                                            <IonIcon icon={trashOutline} className="flex ml-auto cursor-pointer" onClick={() => {deleteFiles(file, index)}}></IonIcon>
                                        </div>
                                    )
                                }
                                {file?.upload &&
                                    (
                                        <div className="progress">
                                            <IonProgressBar color="primary" value={file.progress}></IonProgressBar>
                                            <IonIcon icon={closeCircleOutline} className="flex ml-2 cursor-pointer" onClick={() => cancelUpload(file, index)}></IonIcon>
                                        </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>) : ''
                }
            </div>
        </div>
    );

}

export default UploadFiles;