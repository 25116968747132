import { IonIcon, useIonAlert, IonButton } from '@ionic/react';
import { addCircleOutline, playOutline, createOutline, trashOutline, calendarClearOutline, readerOutline, filterOutline, removeCircleOutline } from 'ionicons/icons';
import { useState, useEffect } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import ButtonPopover from '../../../../components/button-popover/ButtonPopover';
import OptionItem from '../../../../components/option-item/OptionItem';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { texts } from '../../../../theme/dataTabelTexts';
import { useHistory } from 'react-router';
import InputDate from '../../../../components/input-date/InputDate';
import BenefitDiscountModal from '../../../../components/modals/benefitDiscount-modal/BenefitDiscountModal';
import { getAllLegalBenefitQuery } from '../../../../graphql/legalBenefit/queries';
import { useLegalBenefitService } from '../../../../graphql/legalBenefit/service';
import ElipisisUsers from '../../../../components/elipsis-users/ElipisisUsers';
import { useQuery } from '@apollo/client';
import { allBenefits, allDiscounts, typeFrequencyNames } from '../../../../helpers/catalogs'
import { formatMoney, formatSelectData, isEmpty } from '../../../../helpers/utils';
import { useForm } from '../../../../hooks/useForm';

import './BenefitDiscount.scss';
import { ListNoOptionsMessage } from '../../../../components/select-menu-components/SelectMenuComponents';
import SearchTable from '../../../../components/search-table/SearchTable';
import { dateTimeFormat, parseDate } from '../../../../helpers/date';


let initialState = {
    legalBenefitTypeId: [],
    year: '',
    month: '',
}
const BenefitDiscount = ({ enterprise, refetchIndicators }) => {
    const {formValues, handleInputChange, reset} = useForm(initialState);
    const { legalBenefitTypeId, year, month } = formValues;

    const history = useHistory();
    const [present] = useIonAlert();
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(null)
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [type, setType] = useState(null);
    const [options, setOptions] = useState(undefined);
    let { data, loading, error } = useQuery(getAllLegalBenefitQuery, {
        variables: { options },
        fetchPolicy: 'cache-and-network',
    });
    const { deleteLegalBenefit, dLoading, dError } = useLegalBenefitService();
    let typeGroup = [{
        label: 'Beneficio',
        options: formatSelectData({ value: 'id', label: 'name' }, allBenefits)
    }, {
        label: 'Descuento',
        options: formatSelectData({ value: 'id', label: 'name' }, allDiscounts)
    }]
    const ItemsPopover = ({ onHide, data }) => {
        return (
            <>
                <OptionItem onHide={onHide} event={() => handleUpdate(data)} icon={createOutline} caption="Editar" />
                <OptionItem onHide={onHide} event={() => handleDelete(data)} icon={trashOutline} caption="Eliminar" className="danger" />
            </>)
    }

    const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);


    const columns = [
        {
            id: 1,
            field: 'dueDate',
            label: 'Fecha de pago / descuento',
            getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
        },
        {
            id: 2,
            field: 'legalBenefitType',
            label: 'Categoría',
            getValue: ({ value }) => value.group == 'BENEFIT' ? 'Bonificación' : 'Descuento'
        },
        {
            id: 3,
            field: 'legalBenefitType',
            label: 'Tipo',
            getValue: ({ value }) => isEmpty(value.name)
        },
        {
            id: 4,
            field: 'frequency',
            label: 'Frecuencia',
            getValue: ({ value }) => isEmpty(typeFrequencyNames[value])
        },
        {
            id: 5,
            field: 'employees',
            label: 'Colaboradores',
            cellRenderer: ({ data }) => <ElipisisUsers users={data?.employees} itemsFit={5} />
        },
        {
            id: 6,
            field: 'description',
            label: 'Descripción',
            getValue: ({ value }) => isEmpty(value)
        },
        {
            id: 7,
            field: 'amount',
            label: 'Monto',
            headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
            cellRenderer: ({ data }) => {
                return (
                    <div className="text-right w-full m-4">
                        <p>{formatMoney(enterprise, data?.amount)}</p>
                    </div>)
            },
        },
        {
            id: 'option',
            label: '',
            width: "max-content",
            pinned: true,
            sortable: true,
            resizable: false,
            cellRenderer: Options
        },

    ];

    const goToEmployeePage = (id) => {
        history.push(`/customers/${id}`);
    }

    const handleDelete = (data) => {
        console.log(data);
        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: `Eliminar ${data?.legalBenefitType?.group == 'BENEFIT' ? 'beneficio' : 'descuento'}`,
            subHeader: `Este ${data?.legalBenefitType?.group == 'BENEFIT' ? 'beneficio' : 'descuento'} se eliminará permanentemente`,
            message: `¿Esta seguro de eliminar a este ${data?.legalBenefitType?.group == 'BENEFIT' ? 'beneficio' : 'descuento'}?`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: `Eliminar ${data?.legalBenefitType?.group == 'BENEFIT' ? 'beneficio' : 'descuento'}`,
                    cssClass: 'delete',
                    handler: () => {
                        deleteLegalBenefit({ variables: { input: data.id } });
                        refetchIndicators();
                    }
                }
            ],
            onDidDismiss: (e) => { },
        })
        if (dError) {
            console.log(dError);
        }
    }

    const handleUpdate = (data) => {
        setType(data?.legalBenefitType?.group);
        setId(data.id);
        setShowModal(true);
    }

    const tableProps = (show) => ({
        showSearch: show,
        showRowsInformation: show,
        isPaginated: show,
        showColumnVisibilityManager: show
    })

    const handleAddBenefitDiscount = (type) => {
        setType(type);
        setId(null);
        setShowModal(true)
    }

    const onRowClick = ({ event }) => {
        event.stopPropagation();
    }

    const getData = (data) => {
        if (data && data.length) {
            data = JSON.parse(JSON.stringify(data))
            data.forEach(row => {
                row.employees.forEach(user => {
                    user.bg = '#' + Math.floor(Math.random() * 16777215).toString(16);
                })
            })
            return data;
        }

        return [];
    }

    const filters = (e, name) => {
        if (name == 'legalBenefitTypeId') {
            setOptions({ ...options, where: { [name]: e?.value } })
            handleInputChange(e, name);
        }
    }
    return (
        <>
            <div className="flex flex-col justify-end items-center md:flex-row md:justify-between md:items-end my-8">
                <div className="flex flex-col md:flex-row items-center">
                    <div>
                        <p className="text-sm mb-2 hover:text-secondary w-80">Tipo de beneficio o descuento</p>
                        <Select isClearable={true}
                            placeholder="Seleccione el tipo"
                            value={legalBenefitTypeId}
                            options={typeGroup}
                            onChange={e => (filters(e, 'legalBenefitTypeId'))}
                            className="sfn-select z-20" styles={sfnSelectTheme}
                            components={
                                {
                                    NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay tipo de beneficio/descuento" })
                                }
                            } />
                    </div>
                    {/* <div className="mx-2 my-4 md:my-0">
                        <p className="text-sm mb-2 hover:text-secondary w-60">Año</p>
                        <div className="flex items-center dropdown-month">
                            <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
                            <Select isClearable={true} placeholder="Seleccione el año" value={year} className="sfn-select w-60 z-10" styles={sfnSelectTheme} />
                        </div>
                    </div>
                    <div className="mx-2 my-4 md:my-0">
                        <p className="text-sm mb-2 hover:text-secondary w-60">Mes</p>
                        <div className="flex items-center dropdown-month">
                            <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
                            <Select isClearable={true} placeholder="Seleccione el mes" value={month} className="sfn-select w-60 z-10" styles={sfnSelectTheme} />
                        </div>
                    </div> */}
                </div>
                <div className="flex items-end justify-end flex-col sm:flex-row sm:mt-0 mt-8">
                    <IonButton className="sfn-button sm:mr-8 " shape="round" fill="outline" onClick={() => handleAddBenefitDiscount('BENEFIT')}>
                        <IonIcon slot="start" icon={addCircleOutline} />
                        Agregar beneficio
                    </IonButton>
                    <IonButton className="sfn-button" shape="round" onClick={() => handleAddBenefitDiscount('DISCOUNT')}>
                        <IonIcon slot="start" icon={removeCircleOutline} />
                        Agregar descuento
                    </IonButton>
                </div>
            </div>
            <GridTable
                className="max-h-80 sfn-data-table"
                columns={columns}
                isLoading={loading}
                rows={getData(data?.legalBenefits?.rows)}
                texts={texts} onRowClick={onRowClick}
                components={{
                    NoResults: () => (
                        <Placeholder icon={readerOutline} description='Aún no tienes beneficios o descuentos agregados, haz clic en el botón "Agregar beneficio/descuento"' />
                    ),
                    Search: SearchTable
                }}
                {...tableProps(data?.legalBenefits?.rows?.length ? true : false)}
                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            />
            {showModal && <BenefitDiscountModal type={type} showModal={showModal} setShowModal={setShowModal} id={id} setId={setId} hookClose={() => {refetchIndicators()}} />}
        </>
    )
}

export default BenefitDiscount