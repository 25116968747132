import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonList, IonRadioGroup, IonListHeader, IonRadio, useIonToast, IonContent } from '@ionic/react';
import { closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import './InviteModal.scss';
// import { defaultValue, formatSelectData } from '../../../helpers/utils';
import { useEffect } from 'react';
import language from './language.json';
import { validate } from '../../../helpers/validations';
import { useUsersService } from '../../../graphql/user/service';
import { validationMap } from './validation';
import { roles } from '../../../helpers/catalogs';
import { useQuery } from '@apollo/client';
import { getOneUserQuery } from '../../../graphql/user/queries';
import SfnButton from '../../button/Button';
import { Tooltip } from '../../tooltip/Tooltip';
import { addUserStore, updateUserStore } from '../../../store/users';

const text = language.es;
const initialState = {
    id: null,
    email: null,
    roleId: "7491c3c3-874a-4940-ae75-954dd4e51e1d",
}
const InviteModal = ({ showModal, setShowModal, id = null, setId, users = [], setUsers = () => {} }) => {

    const { formValues, handleInputChange, reset } = useForm({ ...initialState });
    const { email, roleId } = formValues;
    const { createUser, cLoading, cError, updateUser, uLoading, uError } = useUsersService();
    const [present] = useIonToast();
    const { loading, data } = useQuery(getOneUserQuery, {
        variables: { input: id },
        skip: !id,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (id && !loading) {
            const { user } = data;
            handleInputChange(user.email, "email");
            handleInputChange(user.id, "id");
            user.roleId = user?.role?.id || "7be4c100-dca5-4203-a479-d71d979772bb";
            reset(user);
        }
    }, [data])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const valid = validate(formValues, validationMap);
        if (valid.valid) {
            let values = { id: formValues.id, email: formValues.email, roleId: formValues.roleId }
            if (id) {
                let {data: update, errors} = await updateUser({ variables: { id, input: values } })
                if(!errors) {
                    updateUserStore(update.updateUser);
                }
            } else {
                delete values.id;
                let{data: create, errors} = await createUser({ variables: { input: values } });
                if(!errors) {
                    addUserStore(create.createUser);
                }
            }

            if (!uLoading && !cLoading) {
                if (cError || uError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                    return
                }
                present({ message: `Usuario ${id ? 'actualizado' : 'invitado'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
                reset();
                onClose();
            }
        }
    }

    const onClose = () => {
        reset();
        handleInputChange("", "email");
        setShowModal(false);
        setId(null);
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-xl font-bold">{text?.title}</h3>
                    <p className="text-center">{text?.description}</p>

                    <form id='invite-form' onSubmit={handleSubmit} className="p-2 xl:h-full md:h-4/5 sm:h-1/2">

                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">{text?.input?.title}<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput
                                name="email"
                                type="email"
                                placeholder={text?.input?.placeholder}
                                value={email}
                                onIonChange={handleInputChange}
                                disabled={id}
                                required> </IonInput>
                        </IonItem>
                        <section className="flex flex-col">
                            <p className="pt-12 pb-5 text-lg font-bold text-gray-light">{text?.list?.title}</p>
                            <div className="flex flex-col md:flex-row">
                                <IonList className="pr-12">
                                    <IonRadioGroup
                                        name="roleId"
                                        onIonChange={handleInputChange}
                                        value={roleId}>

                                        {
                                            roles && roles.map((role) =>
                                                <IonItem lines="none" key={role.id}>
                                                    <IonLabel>
                                                    {text?.list?.roles[role.id]?.title}
                                                    </IonLabel>
                                                    <IonRadio slot="start" color="secondary" value={role.id} />
                                                </IonItem>
                                            )
                                        }

                                    </IonRadioGroup>
                                </IonList>

                                <div className="pt-3.5">
                                    <div className="p-3">
                                        <h2 className="font-bold">
                                            {text?.list?.roles[roleId]?.subTitle}
                                        </h2>
                                        <p>
                                            {text?.list?.roles[roleId]?.description}
                                        </p>
                                    </div>
                                    {
                                        text?.list?.roles[roleId] && text?.list?.roles[roleId]?.items.map(el =>
                                            <div className="p-3" key={Math.random()}>
                                                <h2 className="font-bold">
                                                    {el?.title} 
                                                        {
                                                            String(el?.title).toLowerCase().includes('reportes') && <Tooltip className="ml-1 mt-2 text-sm" label="Los reportes que podrás visualizar en esta sección dependerán del plan adquirido por tu empresa en Symfonia" />
                                                        }
                                                </h2>
                                                <p>
                                                    {el?.description}
                                                </p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </section>

                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                                {text?.actions?.cancel}
                            </IonButton>
                            <SfnButton form="invite-form" label={id ? text?.actions?.edit : text?.actions?.invite} loading={uLoading || cLoading} />

                        </div>

                    </form>
                </Wrapper>
            </IonContent>
        </IonModal>

    )

}

export default InviteModal;