import { IonContent, IonPage, IonButton, IonIcon, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Tabs from '../../components/tabs/Tabs';
import AccountModal from '../../components/modals/account-modal/AccountModal';
import { addCircleOutline, filterOutline, trashOutline, create, trash, fileTrayFullOutline } from 'ionicons/icons';
import './AccountsPage.scss';
import queryString from 'query-string'
import { useQuery } from '@apollo/client';
import { getAllAccountsQuery } from '../../graphql/account/queries';
import GridTable from '@nadavshaar/react-grid-table';
import { accountTypes } from '../../helpers/catalogs';
import OptionItem from '../../components/option-item/OptionItem';
import { useAccountsService } from '../../graphql/account/service';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import { groupBy } from 'lodash';
import Placeholder from '../../components/placeholder/Placeholder';
import { isEmpty } from '../../helpers/utils';
import { useAuth } from '../../context/auth/AuthState';
import { AuthStore } from '../../store/auth';

let tableProps = {
  showSearch: false,
  showRowsInformation: false,
  isPaginated: false,
  showColumnVisibilityManager: false
}

const AccountsPage = ({ location, history }) => {
  const user = AuthStore.useState(s => s.user);
  let values = queryString.parse(location.search);
  const [present] = useIonAlert();
  const { deleteAccount, dLoading, dError } = useAccountsService();
  const [presentToast] = useIonToast();
  const [selected, setSelected] = useState(values.tab || 'asset');
  const [showModal, setShowModal] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [total, setTotal] = useState({});
  const [typeAccount, setTypeAccount] = useState();
  const {authState} = useAuth();

  const { data, loading, error, refetch } = useQuery(getAllAccountsQuery, {
    variables: { input: selected.toUpperCase() }, fetchPolicy: 'cache-and-network'
  });

  useIonViewDidEnter(() => {
		refetch()
  });

  let groupAccounts = groupBy(accountTypes, 'group');

  let tabs = [
    {
      title: "Activos",
      value: 'asset',
      total: total?.asset || 0,
    },
    {
      title: "Pasivos",
      value: 'liability',
      total: total?.liability || 0,
    },
    {
      title: "Ingresos",
      value: 'income',
      total: total?.income || 0,
    },
    {
      title: "Costos y gastos",
      value: 'expense',
      total: total?.expense || 0,
    },
    {
      title: "Cápital",
      value: 'equity',
      total: total?.equity || 0,
    },
  ];
  const handleUpdate = (id) => {
    setAccountId(id);
    setShowModal(true);
  }
  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar cuenta',
      subHeader: 'Esta cuenta se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a esta cuenta?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar cuenta',
          cssClass: 'delete',
          handler: async () => {
            let resp = await deleteAccount({ variables: { input: id } });
            if(resp.errors){
              showErrorAlert(resp.errors)
             } else {
              presentToast({ message: 'La cuenta se ha eliminado correctamente 👍', color: "success", mode: "ios", duration: 4000 });
            }
          }
        }
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
    console.log(id);
  }

  const showErrorAlert = (errors) => {
    const {extensions} = errors[0];
    if(extensions?.code === "LOCKED"){
       present({
         cssClass: 'sfn-alert-dialog',
         mode: 'md',
         header: 'No podemos eliminar esta cuenta',
         subHeader: `No podemos eliminar esta cuenta porque está asociado con lo siguiente:
         ${extensions?.transactions?.map( t => {
           return `\n• Transacción ${t?.id}`
         })}
         ${extensions?.products?.map( p => {
          return `\n• ${p?.name}`
        })}
         `,
         message: `Mostrando ${extensions?.products?.length || 0 + extensions?.transactions?.length || 0}/${extensions?.length || 0}, Para eliminar esta cuenta, elimínelo de los elementos enumerados anteriormente`,
         buttons: [
           {
             text: 'Cerrar',
             role: 'cancel',
             cssClass: 'secondary',
           },
         ],
       })
     } else {
       presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
     }
  }

  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={create} caption="Editar" />
      {data.isRemovable && <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trash} caption="Eliminar" className="danger" />}
    </>
  )

  const Options = ({ data }) => (!['READ'].includes(user?.role?.permissions['accounting']) && <ButtonPopover options={ItemsPopover} data={data} />)


  const columns = [
    {
      id: 1,
      field: 'name',
      label: 'Nombre de cuenta',
      width: '250px',
      getvalue: ({value}) => isEmpty(value)
    },
    {
      id: 2,
      field: 'description',
      label: 'Descripción',
      width: '900px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-left w-full m-4">
            <p>{isEmpty(data?.description)}</p>
          </div>
        )
      }
    },
    {
      id: 'option',
      label: '',
      width: "max-content",
      pinned: true,
      sortable: true,
      resizable: false,
      cellRenderer: Options
    }
  ];

  const handleAddAccount = (typeAccount = null) => {
    setTypeAccount(typeAccount)
    setShowModal(true);
  }

  useEffect(() => {
    setTotal(data?.accounts?.length);
  }, [data])
  return (
    <IonPage>
      <Header title="Catálogo de cuentas">
        <div className="section-actions flex flex-col md:flex-row items-center mt-10 px-12">
          <Tabs className="w-full mr-8" tabs={tabs} selected={selected} setSelected={setSelected} url={true} path="/accounts" history={history} />
          <IonButton className="sfn-button md:mt-0 mt-8" shape="round" onClick={() => (handleAddAccount())} disabled={['READ'].includes(user?.role?.permissions['accounting'])}>
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar cuenta
          </IonButton>
        </div>
      </Header>
      <IonContent className="account-content" fullscreen>
        <section className="pt-10">
          {data != null && groupAccounts[String(values.tab || 'ASSET').toLocaleUpperCase()]?.map(({ id, name, descrption }, index) => (
            <div className="mb-8" key={index}>
              <h3 className="text-xl mb-4 text-gray-blue font-bold">{name || 'NA'}</h3>
              <p className="text-base">{descrption}</p>

              {
                (!loading && data?.accounts?.data[id]?.length)
                &&
                <div className="flex flex-col w-full justify-start">
                  <GridTable {...tableProps}
                    className={`sfn-data-table-slim ${data?.accounts?.data[id]?.length ? 'sfn-data-table' : 'h-40'}`}
                    columns={columns}
                    rows={data.accounts.data[id]}
                    icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                    components={{
                      NoResults: () => (
                        <Placeholder icon={fileTrayFullOutline} description='Aún no tienes cuentas' />
                      )
                    }} />
                  <IonButton disabled={['READ'].includes(user?.role?.permissions['accounting'])} className="sfn-button sm:w-60 w-full text-primary normal-case font-bold" fill="none" shape="round" onClick={() => (handleAddAccount(id))}>
                    <IonIcon slot="start" icon={addCircleOutline} />
                    Agregar cuenta
                  </IonButton>
                </div>
              }
              {
                (!loading && !data?.accounts?.data[id]?.length)
                &&
                <div className="flex flex-col w-full justify-start">
                  <p className="italic text-gray h-10 items-center flex w-full">Aún no ha agregado ninguna cuenta de {String(name).toLocaleLowerCase()}</p>
                  <IonButton disabled={['READ'].includes(user?.role?.permissions['accounting'])}  className="sfn-button sm:w-60 w-full text-primary normal-case font-bold" fill="none" shape="round" onClick={() => (handleAddAccount(id))}>
                    <IonIcon slot="start" icon={addCircleOutline} />
                    Agregar cuenta
                  </IonButton>
                </div>
              }
            </div>
          ))}
        </section>
        {showModal && <AccountModal showModal={showModal} setShowModal={setShowModal} accountId={accountId} setAccountId={setAccountId} total={total} setTotal={setTotal} typeAccount={typeAccount} />}
      </IonContent>
    </IonPage>
  );
};

export default AccountsPage;
