import React, { useEffect, useState } from 'react';
import { IonPopover, IonButton, IonIcon, useIonPopover } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';

const ButtonPopover = ({options, data, enterprise}) => {
  const [present, dismiss] = useIonPopover(options, { onHide: () => dismiss() , data, enterprise});


  return (
    <IonButton shape="round" fill="clear" className="sfn-button-rounder-full" onClick={
      (e) => {
          e.stopPropagation();
          e.persist();
          present({
            event: e.nativeEvent,
            cssClass: 'sfn-table-popover'
          });
      }}
    >
      <IonIcon color="medium" size="small" slot="icon-only" icon={ellipsisVertical} ></IonIcon>
    </IonButton>
  );
};

export default ButtonPopover;