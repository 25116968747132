import { useEffect, useRef, useState } from "react";
import { getAllTaxRates } from "../graphql/tax/queries";
import { getDate } from "../helpers/date";
import { roundValue } from "../helpers/utils";
import { useApolloClient } from '@apollo/client';


export const useInvoice = () => {
    const [subTotal, setSubTotal] = useState(0);
    const [totalTaxes, setTotalTaxes] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [allRates, setAllRates] = useState([]);
    const [ratesLoading, setLoading] = useState(true)
    const client = useApolloClient();
    const [includeTaxes, setIncludeTaxes] = useState(false);

    useEffect(() => {
        getGrandTotal();
    }, [subTotal, totalTaxes, includeTaxes]);

    const getGrandTotal = () => {
        let newTotal = 0; 
        if(includeTaxes){
            newTotal = parseFloat(subTotal)
        } else {
            newTotal = parseFloat(subTotal) + parseFloat(totalTaxes)
        }
        setGrandTotal(roundValue(newTotal));
    }

    const calculate = (allRows, it) => {
        let subtotal = 0;
        let taxes = 0;
        allRows.forEach(p=>{
            let temp = p.price * p.quantity;
            if(p.taxes){
                let rate = p.taxes.reduce((a, t)=>a + t.value?.rate/100, 1)
                taxes += it ?  temp - (temp/rate)  :  (temp*rate) - temp
            }
            subtotal += temp;
        })
        setTotalTaxes(roundValue(taxes));
        setSubTotal(roundValue(subtotal));
    }

    const getRates = (taxes, taxRates, event="update") => {
        console.log('GET RATES', taxes, taxRates);
        if(!taxes) return [];
        let taxIds = taxes.map(t => {
            return (event === 'update')
            ? t.tax.id
            : t.id
        })    
        return taxRates.map(i => {
            return {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
        }).filter(i => taxIds.includes(i.value.tax.id))
    }

    const getAllRates = async (date = getDate(new Date(), false)) => {
        let { data, errors, loading: load } = await client.query({ query: getAllTaxRates, 
                variables: {date}, 
                fetchPolicy: "no-cache"});
        setLoading(load);
        if (!errors) {
          setAllRates(data.taxRates);
          console.log(data.taxRates);
        }
      }

      const formatSelectTaxes = (allRates) => {
        allRates = allRates.map(i => {
            let temp =  {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
            return temp;
        })
        return allRates
    }

    return {
        calculate,
        subTotal,
        totalTaxes,
        grandTotal,
        getRates,
        getAllRates,
        allRates,
        setAllRates,
        ratesLoading,
        formatSelectTaxes,
        includeTaxes, 
        setIncludeTaxes
    }

}
