import gql from 'graphql-tag';

export const agedPayables = gql`
  query agedPayables($from: LocalDate!, $to: LocalDate!, $vendorId: ID) {
    agedPayables(from: $from, to: $to, vendorId: $vendorId) {
      name
      total
      noYetOverdue
      _30OrLess
      _31To60
      _61To90
      _91OrMore
    }
  }
`;

export const agedReceivables = gql`
  query agedReceivables($from: LocalDate!, $to: LocalDate!, $customerId: ID) {
    agedReceivables(from: $from, to: $to, customerId: $customerId) {
      name
      total
      noYetOverdue
      _30OrLess
      _31To60
      _61To90
      _91OrMore
    }
  }
`;

export const cashFlow = gql`
  query cashFlow($from: LocalDate!, $to: LocalDate!) {
    cashFlow(from: $from, to: $to) {
      id
      label
      val
    }
  }
`;

export const profitAndLoss = gql`
  query profitAndLossReport($from: LocalDate!, $to: LocalDate!) {
    profitAndLossReport(from: $from, to: $to) {
      indicators {
        income
        expense
        costOfGoodsSold
        netIncome
        grossProfit
      }
      income { #Ingresos
        id
        name
        amount
      }
      expense { #gastos operativos ultimo
        id
        name
        amount
      }
      costOfGoodsSold { #costo de los bienes vendidos
        id
        name
        amount
      } #ganancia bruta income - costofGoodsSold
    }
  }
`;
