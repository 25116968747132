import {
	pieChartOutline,
	pieChart,
	cartOutline,
	cart,
	podiumOutline,
	podium,
	clipboardOutline,
	clipboard,
	briefcaseOutline,
	briefcase,
	peopleOutline,
	people,
	settingsOutline,
	settings,
} from "ionicons/icons";

export const Items = [
	{
        title: "Indicadores",
        open: true,
        children: [
			{
				url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg/",
				title: "¿Cómo cálculamos el total de ingreso de mes actual?",
			},{
				url: "",
				title: "¿Qué es el flujo de efectivo?",
			},{
				url: "",
				title: "¿Cómo cálculamos ganancias y pérdidas?",
			},{
				url: "",
				title: "¿Qué son las cuentas por cobrar?",
			},{
				url: "",
				title: "¿Qué son las cuentas por pagar?",
			},{
				url: "",
				title: "La importancia de tu ingreso neto anual",
			}
		],
    },
    {
        title: "Ventas",
        children: [
			{
				url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg",
				title: "¿Cómo crear tu primera factura de ventas?",
			},{
				url: "https://youtu.be/i0i4qY4rJrk?t=34",
				title: "¿Cómo crear agregar clientes?",
			},{
				url: "https://youtu.be/i0i4qY4rJrk?t=64",
				title: "¿Cómo crear agregar los productos o servicios de tu empresa?",
			}
		],
	},{
        title: "Costos, gastos y egresos",
        children: [
			{
				url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg",
				title: "¿Cómo registrar el pago total o parcial que recibes de un cliente en una factura de ventas?",
			},{
				url: "https://youtu.be/cSUVgvtYIuQ?t=12",
				title: "¿Cómo agregar una factura de un gasto realizado?",
			},{
				url: "https://youtu.be/cSUVgvtYIuQ?t=27",
				title: "¿Cómo agregar a tus proveedores?",
			},{
				url: "https://youtu.be/cSUVgvtYIuQ?t=49",
				title: "¿Cómo agregar los productos o servicios que le compras a tus proveedores?",
			},{
				url: "https://youtu.be/cSUVgvtYIuQ?t=91",
				title: "¿Cómo registrar un pago realizado a uno de tus proveedores por la compra de un producto o servicio?",
			}
		],
	},{
        title: "Nómina",
        children: [
			{
				url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg",
				title: "¿Cómo agregar a tus colaboradores?",
			},{
				url: "https://symfonia.io/blog/negocios/nomina-como-configurar-la-frecuencia-de-pago-de-nomina",
				title: "¿Cómo configurar la frecuencia de pago de nómina?",
			},{
				url: "https://symfonia.io/blog/negocios/nomina-como-agregar-beneficios-a-tus-colaboradores",
				title: "¿Cómo agregar beneficios a tus colaboradores?",
			},{
				url: "https://symfonia.io/blog/negocios/nomina-como-agregar-descuentos-a-tus-colaboradores",
				title: "¿Cómo agregar descuentos a tus colaboradores?",
			},{
				url: "",
				title: "¿Cómo calcular el total de tu nómina?",
			},{
				url: "",
				title: "¿Cómo descargar o enviar las boletas de pago de tus colaboradores?",
			},{
				url: "",
				title: "¿Cómo visualizar el historial de pagos de nómina?",
			}
		],
    },{
        title: "Contabilidad",
        children: [
            {
                url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg",
                title: "¿Cómo funciona el módulo de transacciones?",
            }, {
                url: "",
                title: "¿Qué son las cuentas contables y para que sirven?",
            }, {
                url: "",
                title: "¿Cómo agregar beneficios a tus colaboradores?",
            }
        ]
    },{
        title: "Configuración",
        children: [
            {
                url: "https://www.youtube.com/channel/UCMNnfRFa_ThIm4_GqSevqbg",
                title: "¿Cómo agregar impuestos de venta según mi país?",
            }, {
                url: "https://symfonia.io/blog/negocios/como-agregar-mis-cuentas-bancarias-de-entrada-y-salida-de-dinero",
                title: "¿Cómo agregar mis cuentas bancarias de entrada y salida de dinero?",
            }, {
                url: "",
                title: "¿Cómo personalizo mis facturas con el logotipo de mi empresa?",
            }
        ]
    }
];
