export const DashboardOnboarding = [
  {
    title: "Te damos la bienvenida a Symfonia",
    description:
      "Te mostramos cómo iniciar de manera fácil y rápida a organizar las ganancias y gastos de tu empresa.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Reportes.gif",
    alt: "Bienvenido",
  },
  {
    title: "Indicadores financieros",
    description:
      "Para visualizar información del estado financiero actual de tu empresa empieza agregando tus ingresos y gastos en los módulos de ventas y costos/egresos.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Ventas1-min.gif",
    alt: "Indicadores financieros",
  },
];
