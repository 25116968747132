export const InvoicesOnboarding = [
  {
    title: "¿Cómo crear tu primera factura de ventas?",
    description:
      "Para crear facturas de ventas, debes agregar primero los productos de tu empresa y a tus cientes para luego asociarlos a la factura que deseas crear.",
    image:
      "https://cdn01.prd.symfonia.io/Gifs/Ventas1-min.gif",
    alt: "Factura de ventas",
  },
  {
    title: "Agrega impuestos de venta a tus productos",
    description:
      "En Configuración pestaña impuestos puedes agregar los impuestos que apliquen en tu país para la venta de productos o servicios.",
    image:
      "https://cdn01.prd.symfonia.io/Gifs/Ventas2-min.gif",
    alt: "Impuestos de venta",
  },
  {
    title: "Registra los pagos que realicen tus clientes",
    description:
      "Cuando recibas el pago parcial o total de una factura de ventas, registralo y lleva el control detallado de tus ingresos.",
    image:
      "https://cdn01.prd.symfonia.io/Gifs/Ventas3-min.gif",
    alt: "Registrar pagos",
  },
];
