import { IonContent, IonPage, IonIcon, IonButton, IonNote, useIonAlert } from '@ionic/react';
import Header from '../../components/header/Header';
import Kpi from '../../components/kpi/Kpi';
import { addCircleOutline, walletOutline, downloadOutline, filterOutline, playOutline, createOutline, trashOutline, flagOutline, timeOutline, calendarClearOutline, documentAttachOutline, eyeOutline } from 'ionicons/icons';
import GridTable from '@nadavshaar/react-grid-table';
import OptionItem from '../../components/option-item/OptionItem';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import { useState } from 'react';
import ExpenseModal from '../../components/modals/expense-modal/ExpenseModal';
import Select from 'react-select';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import InputDate from '../../components/input-date/InputDate';
import { useQuery } from '@apollo/client';
import { getAllVendorsQuery } from '../../graphql/vendor/queries';
import { formatMoney, formatSelectData, isEmpty } from '../../helpers/utils';
import { billIndicatorsQuery, getAllBillsQuery } from '../../graphql/bill/queries';
import Placeholder from '../../components/placeholder/Placeholder';
import { dateTimeFormat, getDate, getRange, parseDate } from '../../helpers/date';
import { texts } from '../../theme/dataTabelTexts';
import { useBillsService } from '../../graphql/bill/service';
import PaymentRecordModal from '../../components/modals/payment-record-modal/PaymentRecordModal';
import { documentStatus, statusName } from '../../helpers/catalogs';
import { useHistory } from 'react-router';
import { useAuth } from '../../context/auth/AuthState';
import { ListNoOptionsMessage, MenuListButton } from '../../components/select-menu-components/SelectMenuComponents';
import VendorModal from '../../components/modals/vendor-modal/VendorModal';
import SearchTable from '../../components/search-table/SearchTable';
import { AuthStore } from '../../store/auth';

const ExpensesPage = () => {
  const user = AuthStore.useState(s => s.user);
  const history = useHistory();
  // const [date, setDate] = useState(getDate(new Date(getRange(-1)), false));
  // const [dueDate, setDueDate] = useState(getDate(new Date().setDate(new Date(date).getDate() + 30), false));
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [showPaymentRecordModal, setShowPaymentRecordModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [billId, setBillId] = useState(null)
  const { data: dataVendors, loading, error } = useQuery(getAllVendorsQuery);
  const [status, setStatus] = useState(undefined);
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [options, setOptions] = useState({
    where: {
      date: date != null && dueDate != null ? {
        _between: [date, dueDate]
      } : undefined
    }
  })

  const { data: allBills, loading: billsLoading, error: billsError } = useQuery(getAllBillsQuery, { variables: { options, status }, fetchPolicy: 'cache-and-network' });
  const [present] = useIonAlert();
  const { deleteBill, dLoading, dError } = useBillsService();
  const { data: indicators, loading: indicatorsLoading, error: indicatorsError, refetch: refetchIndicator } = useQuery(billIndicatorsQuery);
  const { billIndicators } = indicators || [];
  const [showVendorModal, setShowVendorModal] = useState(false);


  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => goToExpensePage(data.id)} icon={eyeOutline} caption="Ver detalle" />
      {(data.status != 'CANCELLED' && !['READ'].includes(user?.role?.permissions['expenses'])) &&
        <>
          <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
          <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Anular" className="danger" />
        </>}
    </>
  )
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);

  const handleUpdate = (id) => {
    console.log(id);
    setBillId(id);
    setShowExpenseModal(true);
  }

  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Anular factura',
      subHeader: 'Este factura se anulará permanentemente',
      message: '¿Esta seguro de anular a esta factura?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Anular factura',
          cssClass: 'delete',
          handler: () => {
            console.log(id);
            deleteBill({ variables: { input: id } });
          }
        }
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
    if (dError) {
      console.log(dError);
    }
  }

  const goToExpensePage = (id) => {
    history.push(`/expenses/${id}`);
  }

  const columns = [
    {
      id: 1,
      field: 'status',
      label: 'Estado',
      width: '120px',
      cellRenderer: ({ value }) => (
        <IonNote className={`w-full text-center ${value} with-bg`}>{isEmpty(statusName[value])}</IonNote>
      )
    }, {
      id: 2,
      field: 'number',
      label: 'No. Factura',
      width: '130px',
      getValue: ({ value }) => isEmpty(value)

    },
    {
      id: 3,
      field: 'vendor',
      label: 'Proveedor',
      width: '130px',
      getValue: ({ value }) => isEmpty(value?.name)
    },
    {
      id: 4,
      label: 'Fecha creación',
      field: 'date',
      width: '135px',
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    },
    {
      id: 5,
      field: 'dueDate',
      label: 'Fecha límite',
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    }, {
      id: 6,
      field: 'total',
      label: 'Monto',
      width: 'max-content',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{formatMoney(data, data.total)}</p>
            {(data.total !== data.convertedTotal) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedTotal)}</IonNote>}
          </div>)
      }
    }, {
      id: 7,
      field: 'remaining',
      label: 'Saldo',
      width: 'max-content',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Saldo</div>),
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{formatMoney(data, data.remaining)}</p>
            {(data.remaining !== data.convertedRemaining) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedRemaining)}</IonNote>}
          </div>)
      }
    }, {
      id: 8,
      label: '',
      cellRenderer: ({ data }) => (
        <IonButton  className="sfn-button" fill="outline" shape="round" disabled={!(data.remaining > 0 && data.status != 'CANCELLED') || ['READ'].includes(user?.role?.permissions['expenses'])} onClick={(e) => {
          e.stopPropagation();
          setBillId(data.id);
          setShowPaymentRecordModal(true);
        }} >Registrar Pago</IonButton>
      )
    }, {
      id: 'option',
      label: '',
      cellRenderer: Options,
      pinned: true,
      width: "max-content",
    }
  ];

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })

  const handleChange = (selectedOption, source) => {
    if (source === 'customer') {
      let where = {};
      setSelectedOption(selectedOption);
      if (selectedOption) {
        where = {
          ...options.where || {},
          vendorId: selectedOption.value
        }
        setOptions({ ...options, where });
      } else {
        setOptions({ ...options, where: { ...options.where, vendorId: undefined } });
      }
    }
    if (source === 'status') {
      setSelectedStatusOption(selectedOption);
      if (selectedOption) {
        setStatus(selectedOption.value)
      } else {
        setStatus(undefined)
      }
    }
  };

  const filterDate = (e) => {
    let from = date, to = dueDate
    if (e.target.name == 'date') {
      setDate(e.target.value)
      from = e.target.value
    } else {
      setDueDate(e.target.value)
      to = e.target.value
    }

    const  between = [from, to]?.filter(v => v !== '').length ? [from, to]?.filter(v => v !== '') : undefined
    let where
    if(between) {
      where = {
        ...options.where,
        date: {
          _between: between
        },
      };
    }
    
    setOptions({ ...options, where })
  }

  const generateBill = () => { }

  return (
    <IonPage>
      <Header title="Facturación - gastos" subtitle={`Indicadores a la fecha ${new Date().toLocaleDateString()}`}></Header>

      <IonContent className="sfn-content">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center my-8">

          <Kpi iconColor="danger" icon={flagOutline} loading={indicatorsLoading} title={formatMoney(enterprise, billIndicators?.overdue?.value, true)} description="Facturación atrasada" />
          <Kpi iconColor="warning" className="my-6 md:my-0" icon={timeOutline} loading={indicatorsLoading} title={formatMoney(enterprise, billIndicators?.unpaid?.value, true)} description="Facturación pendiente y parcial" />
          <Kpi iconColor="secondary" icon={calendarClearOutline} loading={indicatorsLoading} title={formatMoney(enterprise, billIndicators?.paid?.value)} description="Facturación pagada últimos 30 días" />
        </div>

        <section className="flex flex-col sm:flex-row justify-between mb-4 items-end" >
          <div className="sm:w-64 w-full">
            <p className="text-sm mb-2 hover:text-secondary w-64" >Proveedor</p>
            <Select
              isClearable={true}
              value={selectedOption}
              onChange={(e) => handleChange(e, 'customer')}
              options={formatSelectData({ value: 'id', label: 'name' }, dataVendors?.vendors?.rows)} placeholder="Selecciona proveedor" className="sfn-select z-10" styles={sfnSelectTheme}
              components={
                {
                  // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo proveedor", callback:setShowVendorModal}),
                  NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay proveedores" })
                }
              }
            />
          </div>

          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64" >Estado</p>
            <Select
              isClearable={true}
              options={documentStatus}
              value={selectedStatusOption}
              onChange={(e) => handleChange(e, 'status')}
              placeholder="Seleccione un estado"
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
            />
          </div>

          <InputDate label="Desde" value={date} handleInputChange={e => filterDate(e)} name="date" icon={false} />
          <InputDate label="Hasta" value={dueDate} handleInputChange={e => filterDate(e)} name="dueDate" icon={false} />

          <IonButton disabled={['READ'].includes(user?.role?.permissions['expenses'])} className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" shape="round" onClick={() => { setShowExpenseModal(true) }}>
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar gasto
          </IonButton>

        </section>

        <GridTable
          className="mt-10 sfn-data-table"
          columns={columns}
          rows={allBills?.bills?.rows}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          isLoading={billsLoading}
          components={{
            NoResults: () => (
              <Placeholder icon={walletOutline} description='Aún no tienes gastos (facturas) agregados haz clic en el botón "Agregar gastos"' />
            ),
            Search: SearchTable
          }}
          {...tableProps(allBills?.bills?.rows.length ? true : false)}
          onRowClick={({ data }) => {
            goToExpensePage(data.id);
          }} />


        {showExpenseModal && <ExpenseModal billId={billId} setBillId={setBillId} showExpenseModal={showExpenseModal} setShowExpenseModal={setShowExpenseModal} hookClose={() => { refetchIndicator() }} />}
        {showPaymentRecordModal && <PaymentRecordModal billId={billId} setBillId={setBillId} showPaymentRecordModal={showPaymentRecordModal} setShowPaymentRecordModal={setShowPaymentRecordModal} hookClose={() => { refetchIndicator() }}></PaymentRecordModal>}
        {/* {showVendorModal && <VendorModal showModal={showVendorModal} setShowModal={setShowVendorModal}/>} */}

      </IonContent>
    </IonPage>
  );
};

export default ExpensesPage;
