import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, useIonToast, IonCheckbox, useIonViewWillEnter } from '@ionic/react';
import { closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from "../../../../../../hooks/useForm";

import { defaultValue, formatSelectData } from '../../../../../../helpers/utils';
import { areaCodes, countries, departments, departmentsBilling, phoneTypes, statesGuatemala } from '../../../../../../helpers/catalogs';
import Select from 'react-select';
import { AuthStore } from '../../../../../../store/auth';
import SfnButton from "../../../../../../components/button/Button";
import { Tooltip } from "../../../../../../components/tooltip/Tooltip";
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import { sfnSelectTheme } from '../../../../../../theme/sfnSelectTheme';
import { MyPlanStore, updateSubscription } from '../../../../../../store/myPlan';
import { useSubscriptionService } from '../../../../../../graphql/subscription/services';


const initialState = {
    billingData: {
        firstName: '',
        lastName: '',
        phone: { type: '', areaCode: '', number: '' },
        email: '',
        nit: '',
        address: '',
        countryISO2: 'GT',
        city: '',
        state: '',
        postalCode: '',
    }
}

const transformData = (subscription) => {
    if (!subscription) return initialState
    let data = JSON.parse(JSON.stringify(subscription));
    data.billingData.phone.type = defaultValue({ value: 'id', label: 'name' }, phoneTypes, data?.billingData?.phone.type);
    data.billingData.phone.areaCode = defaultValue({ value: 'value', label: 'label' }, areaCodes, data?.billingData?.phone.areaCode);
    let city = Object.keys(departmentsBilling).find(key => departmentsBilling[key].ISOCode.includes(data?.billingData?.city));
    data.billingData.city = departments.find(department => department.label === city);
    data.billingData.state = data.billingData.city.value.find(state => state.label === data.billingData.state);
    return data
}

const BillingModal = ({ showModal, setShowModal }) => {
    const { subscription } = MyPlanStore.useState(s => s);
    const { formValues, handleInputChange, reset, removeCharacter } = useForm(transformData(subscription));
    const { billingData } = formValues;
    const [isConsumer, setIsConsumer] = useState(subscription?.billingData?.nit === 'C/F' ? true : false);
    const [showPostalCode, setShowPostalCode] = useState(false)
    const [present] = useIonToast();
    const { updateBillingData, uBillingLoading, uBillingError } = useSubscriptionService();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let input = { ...formValues };
            if (invalidForm()) return
            input.billingData.phone = {
                type: billingData.phone.type.value,
                areaCode: billingData.phone.areaCode.value,
                number: billingData.phone.number
            }
            input.billingData.firstName = input?.billingData?.firstName?.trim();
            input.billingData.lastName = input?.billingData?.lastName?.trim();
            input.billingData.city = departmentsBilling[billingData.city.label]?.ISOCode?.trim();
            input.billingData.state = billingData?.state?.value?.trim();

            let { errors } = await updateBillingData({ variables: { input: input.billingData } });

            if (errors) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
                return
            } else {
                present({ message: 'Tu Información de facturación se actualizo, exitosamente!', color: "success", mode: "md", duration: 4000 });
                updateSubscription(input);
            }
            e.target.reset();
            onClose();
        } catch (error) {
            console.log(error)
        }
    }

    const onClose = async () => {
        reset({ ...initialState });
        setShowModal(false);
    }

    useEffect(() => {
        if (billingData?.city === null || billingData?.state === null) return
        let postalCode = departmentsBilling[billingData?.city?.label]?.postalCode;
        handleInputChange(postalCode !== '' ? postalCode : statesGuatemala[billingData.state.value]?.postalCode, 'billingData.postalCode');
        if (billingData.city.label === 'Guatemala' && billingData.state.label === 'Guatemala') {
            setShowPostalCode(true);
        } else {
            setShowPostalCode(false);
        }
    }, [billingData.city, billingData.state])


    function invalidForm() {
        if (billingData.phone?.type === '' || billingData.phone?.type == null || billingData.phone?.type == '') {
            present({ message: `Debe seleccionar un tipo de teléfono!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (billingData.phone?.areaCode === '' || billingData.phone?.areaCode == null || billingData.phone?.areaCode == '') {
            present({ message: `Debe seleccionar un área de código!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (billingData?.city?.value === '' || billingData?.city == null || billingData?.city == '') {
            present({ message: `Debe seleccionar un departamento!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (billingData?.state?.value === '' || billingData?.state == null || billingData?.state == '') {
            present({ message: `Debe seleccionar un municipio!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        return false
    }


    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={() => setShowModal(false)}>
            <Wrapper>
                <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                <h3 className="text-center text-xl font-bold">Editar informacíon</h3>
                <p className="text-center text-base">Indica los datos de tu tarjeta con la que harás el pago de tu plan en Symfonia</p>

                <form id='billing-form' onSubmit={handleSubmit} className="h-full p-2">
                    <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">No. de identificación tributaria<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="billingData.nit" type="text" maxlength="13" placeholder="Indica No. de identificación tributaria" onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} value={billingData.nit} autocomplete="off" disabled={isConsumer} required> </IonInput>
                    </IonItem>
                    <div className="flex items-center my-4">
                        <IonCheckbox className="sfn-checkbox mr-2" checked={isConsumer} onIonChange={e => {
                            handleInputChange(e.detail.checked ? 'C/F' : '', 'billingData.nit')
                            setIsConsumer(e.detail.checked)
                        }} />
                        <IonLabel onClick={e => {
                            handleInputChange(isConsumer ? 'C/F' : '', 'billingData.nit')
                            setIsConsumer(!isConsumer)
                        }} className="hover:cursor-pointer mr-1">Consumidor final</IonLabel>
                    </div>
                    <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">Nombre<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="billingData.firstName"
                            type="text"
                            placeholder="Indica tu nombre"
                            onIonChange={handleInputChange}
                            value={billingData.firstName}
                            required
                            autocomplete="off"
                        > </IonInput>
                    </IonItem>
                    <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">Apellido<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="billingData.lastName"
                            type="text"
                            placeholder="Indica tu apellido"
                            onIonChange={handleInputChange}
                            value={billingData.lastName}
                            required
                            autocomplete="off"
                        > </IonInput>
                    </IonItem>
                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Correo electrónico<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="billingData.email" type="email" placeholder="Indica el correo electrónico" onIonChange={handleInputChange} value={billingData.email} required />
                    </IonItem>
                    <div className="flex flex-col my-4">
                        <p className={`text-left text-sm mb-1`}>Teléfono<span className="text-base text-danger">*</span></p>
                        <div className="flex justify-between items-center">
                            <Select value={billingData.phone.type} onChange={(e) => handleInputChange(e, `billingData.phone.type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-60 z-10" styles={sfnSelectTheme} />
                            <Select value={billingData.phone.areaCode} onChange={(e) => handleInputChange(e, `billingData.phone.areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-40 z-10" styles={sfnSelectTheme} />
                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonInput name="billingData.phone.number" type="number" maxlength="8" placeholder="5555-5555" value={billingData.phone.number} onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} required> </IonInput>
                            </IonItem>
                        </div>
                    </div>
                    <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">Dirección<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="billingData.address"
                            type="text"
                            placeholder="Indica tu dirección de facturación"
                            onIonChange={handleInputChange}
                            value={billingData.address}
                            required
                            autocomplete="off"
                        > </IonInput>
                    </IonItem>
                    <div className="flex w-full md:flex-row flex-col">
                        <div className="my-4 w-full">
                            <p className={`text-sm mb-2 hover:text-secondary w-80`}>Departamento<span className="text-base text-danger">*</span></p>
                            <Select
                                placeholder="Seleccione departamento"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                value={billingData.city}
                                isClearable={true}
                                onChange={(e) => handleInputChange(e, 'billingData.city')}
                                options={departments}
                                className="sfn-select md:mr-4 mr-0"
                                styles={sfnSelectTheme}
                            />
                        </div>
                        <div className="my-4 w-full">
                            <p className={`text-sm mb-2 hover:text-secondary w-80`}>Municipio<span className="text-base text-danger">*</span></p>
                            <Select
                                placeholder="Seleccione municipio"
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                value={billingData.state}
                                isClearable={true}
                                onChange={(e) => handleInputChange(e, 'billingData.state')}
                                options={billingData.city?.value}
                                className="sfn-select"
                                styles={sfnSelectTheme}
                            />
                        </div>
                    </div>
                    {showPostalCode && <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">Código postal<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="billingData.postalCode"
                            type="text"
                            placeholder="Indica tu código postal"
                            onIonChange={handleInputChange}
                            value={billingData.postalCode}
                            required
                            autocomplete="off"
                        > </IonInput>
                    </IonItem>}

                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                            Cancelar
                        </IonButton>
                        <SfnButton form="billing-form" label="Guardar cambios" loading={uBillingLoading} />
                    </div>

                </form>
            </Wrapper>
        </IonModal >
    );
}

export default BillingModal;