import gql from 'graphql-tag'

export const createVendorMutation = gql`
	mutation createVendor($input: CreateVendor!) {
		createVendor(input: $input) {
			id
			name
			emails
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}
			description
			
			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
				
			}
		}
	}
`

export const updateVendorMutation = gql`
	mutation updateVendor($input: UpdateVendor!) {
		updateVendor(input: $input) {
			id
			name
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}
			description
			
			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
				
			}
		}
	}
`

export const deleteVendorMutation = gql`
	mutation deleteVendor($input: ID!) {
		deleteVendor(id: $input) {
			id
			name
			emails
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}
			description
			
			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
				
			}
		}
	}
`
