import { IonIcon } from "@ionic/react";
import { cloudUploadOutline, documentOutline } from "ionicons/icons";
import './Upload.scss';
const Upload = ({name="", file, onChange=() => {}, title ="", description ="", accept="", required = false}) => {

    const handleFileInput = e => { 
        onChange(e.target.files[0])
    }

    return (<div className="flex flex-row items-center justify-between sfn-upload">
        <div className="text-content">
            <h3 className="font-bold mb-1">{title}{required && <span className="text-base text-danger">*</span>}</h3>
            <p className="text-gray-2">{description}</p>
        </div>
        <label htmlFor="fileInput" className="button-upload flex flex-col text-center p-2 bg-gray-white items-center justify-center hover:cursor-pointer">
            <IonIcon className="text-4xl pointer-events-none animate__animated animate__fadeIn" icon={!file ? cloudUploadOutline : documentOutline} color="dark"/>
            {file && <span className="text-xs mt-2 w-40 truncate text-gray-2 animate__animated animate__fadeIn">{file?.name}</span>}
            <input id="fileInput" className="" name={name} type="file" accept={accept} onChange={handleFileInput} />
        </label>
    </div>);
}

export default Upload;