import gql from "graphql-tag";

export const createLegalBenefitMutation = gql`
  mutation createLegalBenefit($input: CreateLegalBenefit!) {
    createLegalBenefit(input: $input) {
      id
      legalBenefitType {
        id
        name
        group
      }
      dueDate
      frequency
      amount
      description
      employees {
        id
        name
      }
      isPercent
    }
  }
`;

export const updateLegalBenefitMutation = gql`
  mutation updateLegalBenefit($input: UpdateLegalBenefit!) {
    updateLegalBenefit(input: $input) {
      id
      legalBenefitType {
        id
        name
        group
      }
      dueDate
      frequency
      amount
      description
      employees {
        id
        name
      }
      isPercent
    }
  }
`;

export const deleteLegalBenefitMutation = gql`
  mutation deleteLegalBenefit($input: ID!) {
    deleteLegalBenefit(id: $input) {
      id
      legalBenefitType {
        id
        name
        group
      }
      dueDate
      frequency
      amount
      description
      employees {
        id
        name
      }
      isPercent
    }
  }
`;
