import gql from "graphql-tag";

export const setPayrollConfigMutation = gql`
  mutation setPayrollConfig($input: UpdateEnterprise!) {
    updateEnterprise(input: $input) {
      payroll {
        frequency
        payday
        firstFortnight
        secondFortnight
        configured
      }
    }
  }
`;

export const createPayrollMutation = gql`
  mutation createPayroll($input: CreatePayroll!) {
    createPayroll(input: $input) {
      total
      startDate
      endDate
      month
      year
    }
  }
`;
