import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
// import { RouteComponentProps } from '@ionic/react-router';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import Menu from '../components/menu/Menu';

//pages
import AccountsPage from '../pages/accounts/AccountsPage';
import BusinessesPage from '../pages/businesses/BusinessesPage';
import ProductPage from '../pages/product/ProductPage';
import BuyingsPage from '../pages/buyings/BuyingsPage';
import CashFlowPage from '../pages/reports/components/cash-flow-page/CashFlowPage';
import CustomerPage from '../pages/customer/CustomerPage';
import CustomersPage from '../pages/customers/CustomersPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import ExpensesPage from '../pages/expenses/ExpensesPage';
import InvoicesPage from '../pages/invoices/InvoicesPage';
import ReportsPage from '../pages/reports/ReportsPage';
import SellingPage from '../pages/selling/SellingPage';
import SettingsPage from '../pages/settings/SettingsPage';
import UserManagementPage from '../pages/user-management/UserManagementPage';
import VendorPage from '../pages/vendor/VendorPage';
import VendorsPage from '../pages/vendors/VendorsPage';
import LoginPage from '../pages/login/LoginPage';
import ReceiptsPage from '../pages/receipts/ReceiptsPage';
import InvoicePage from '../pages/invoice/InvoicePage';
import PayrollsPage from '../pages/payrolls/PayrollsPage';
import EmployeePage from '../pages/employee/EmployeePage';
import PayrollPage from '../pages/payroll/PayrollPage';
import TransactionsPage from '../pages/transactions/TransactionsPage';
import ComingSoonPage from '../pages/coming-soon/ComingSoonPage';
import AgedPayablesPage from '../pages/reports/components/aged-payables-page/AgedPayablesPage';
import AgedReceivablesPage from '../pages/reports/components/aged-receivables-page/AgedReceivablesPage';
import BalanceSheetPage from '../pages/reports/components/balance-sheet-page/BalanceSheetPage';
import ProfitAndLossPage from '../pages/reports/components/profit-and-loss-page/ProfitAndLossPage';
import DetailRecordPayrollPage from '../pages/DetailRecordPayroll/DetailRecordPayrollPage';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Onboarding from '../components/onboarding/Onboarding';
// import ReactGA from 'react-ga4';
// import ReactPixel from 'react-facebook-pixel';
// import useGaTracker from '../components/google-analytics/useGaTracker';
import PurchaseByVendorPage from '../pages/reports/components/purchase-by-vendor-page/PurchaseByVendorPage';
import IncomeByCustomerPage from '../pages/reports/components/income-by-customer-page/IncomeByCustomerPage';
import { MenuStore, handleMenu } from "../store/menu";
import { routes } from './routes';
import ResetPasswordPage from '../pages/reset-password/ResetPasswordPage';
import RegistryPage from '../pages/registry/RegistryPage';
import { AuthStore } from '../store/auth';

const PrivateRoutes = ({ authStore }) => {
  const location = useLocation();
  const [path, setPath] = useState('');

  /**
   * @description Keep track of the current route the user is
   */
  // useGaTracker();

  useEffect(() => {
    // .substring(1) is used to remove the '/' in the pathname
    const currentPath = location.pathname.substring(1);
    setPath(currentPath);
    // ReactGA.pageview(currentPath || '/');
    // ReactPixel.pageView(); // For tracking page view
  }, [location]);


  const validateEnterprise = () => {
    if (authStore.user?.enterprises?.accepted === 0 && authStore.user?.enterprises?.pendings >= 0) {
      return true
    } else if (authStore.user?.enterprises?.accepted > 0) {
      return false
    }
    return false
  }
  const protectedRoutes = () => {
    if (validateEnterprise()) {
      return routes?.filter(({ path }) => path === '/businesses')?.map(({ path, screen }) => <Route key={path} path={path} exact={true} render={(props) => authStore.isAuthenticated ? screen(props) : <Redirect to="/login" />} />)
    } else {
      return routes?.map(({ path, screen }) => <Route key={path} path={path} exact={true} render={(props) => authStore.isAuthenticated ? screen(props) : <Redirect to="/login" />} />)
    }
  }


  return (
    <>
      {path === '' ? null : <Onboarding path={path} />}
      <IonSplitPane contentId="menuContent">
        <Menu />
        <IonRouterOutlet id="menuContent" animated={false} >
          {protectedRoutes()}
          <Route exact path="/" render={(props) => authStore.isAuthenticated ? <Redirect to={validateEnterprise() ? "/businesses" : "/dashboard"} /> : <Redirect to="/login" />} />
          <Route render={() => <Redirect to={authStore.isAuthenticated && validateEnterprise() ? "/businesses" : "/dashboard"} />} />
        </IonRouterOutlet>
      </IonSplitPane>
    </>
  );
};

export default PrivateRoutes;
