import { IonButton, IonContent, IonIcon, IonNote, IonPage, useIonAlert, useIonToast } from "@ionic/react";
import { addCircleOutline, create, filterOutline, trash, businessOutline, enter } from "ionicons/icons";
import { useState } from "react";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import Header from "../../components/header/Header";
import BusinessModal from "../../components/modals/business-modal/BusinessModal";
import OptionItem from "../../components/option-item/OptionItem";
import GridTable from '@nadavshaar/react-grid-table';
import { getAllEnterprisesQuery } from '../../graphql/enterprise/queries';
import { useMutation, useQuery } from '@apollo/client';
import Placeholder from '../../components/placeholder/Placeholder';
import { useEnterprisesService } from '../../graphql/enterprise/service';
import { useAuth } from '../../context/auth/AuthState'
import { texts } from "../../theme/dataTabelTexts";
import { rolesName } from "../../helpers/catalogs";
import SearchTable from "../../components/search-table/SearchTable";
import { isEmpty } from "../../helpers/utils";
import AcceptInvitationModal from "../../components/modals/accept-invitation-modal/AcceptInvitationModal";
import { acceptInvitationMutation } from "../../graphql/enterprise/mutations";

const BusinessesPage = () => {
    const { changeEnterpriseDelete, deleteEnterprise, dLoading, dError, changeEnterprise } = useEnterprisesService();
    const [showBusinessModal, setShowBusinessModal] = useState(false);
    const [present] = useIonAlert();
    const [presentToast] = useIonToast();
    const [businessId, setBusinessId] = useState(null);
    const { data, loading, error } = useQuery(getAllEnterprisesQuery);
    const { authState, setAuth, whoiam } = useAuth();
    const { plan } = authState;
    const [showAcceptInvitationModal, setShowAcceptInvitationModal] = useState(false);
    const [acceptInvitation, { loading: dipLoading, error: dipError }] = useMutation(acceptInvitationMutation, { refetchQueries: [{ query: getAllEnterprisesQuery }] });
    const [enterprise, setEnterprise] = useState(null)

    const handleUpdate = (id) => {
        setBusinessId(id);
        setShowBusinessModal(true);
    }
    const handleDelete = (id) => {
        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: 'Eliminar empresa',
            subHeader: 'Esta empresa se eliminará permanentemente. Perderás toda la información financiera que hayas ingresado, esta acción no se puede revertir.',
            message: '¿Estás seguro de eliminar esta empresa?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Eliminar empresa',
                    cssClass: 'delete',
                    handler: async () => {
                        const { data, errors } = await deleteEnterprise({ variables: { input: id } });
                        if (!dError) {
                            const { data: enterprise, errors: enterpriseError } = await changeEnterpriseDelete()
                            if (!enterpriseError && data) {
                                const { changeEnterprise } = enterprise
                                const { accepted, pendings } = changeEnterprise.enterprises
                                setAuth({ token: changeEnterprise.token });
                                window.location.reload();
                                // if(accepted == 0 || pendings > 0) {
                                // }else if(accepted > 0 && pendings == 0) {
                                //     return false
                                // }
                            }
                            presentToast({ message: `La empresa se ha eliminado exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })

                        } else {
                            presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                        }
                    }
                }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
        })
    }

    const ItemsPopover = ({ onHide, data }) => {
        return (
            <>
                <OptionItem disabled={!data.editable} onHide={onHide} event={() => handleUpdate(data.id)} icon={create} caption="Editar" />
                <OptionItem disabled={!data.removable} onHide={onHide} event={() => handleDelete(data.id)} icon={trash} caption="Eliminar" className="danger" />
            </>
        )
    }
    const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);

    const rows = [
        { id: 1, name: 'Pentcloud' },
        { id: 2, name: 'Personal' }
    ]

    const handleAcceptInvitation = async (id) => {
        let { data, errors } = await acceptInvitation({ variables: { input: id } });
        console.log(data)
        if (!errors) {
            setEnterprise(data?.acceptInvitation)
            setShowAcceptInvitationModal(true);
        } else {
            presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
        }

    }

    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Nombre',
            getValue: ({ value }) => isEmpty(value)
        },
        {
            id: 2,
            field: 'role.name',
            label: 'Rol',
            cellRenderer: ({ data }) => (<p className="mx-5">{rolesName[data.role.name] ? rolesName[data.role.name] : 'N/A'}</p>)
        },
        {
            id: 3,
            field: 'currency',
            label: 'Moneda',
            getValue: ({ value }) => isEmpty(value?.name)
        },
        {
            id: 4,
            field: 'bussinesType',
            label: 'Giro de negocio',
            getValue: ({ value }) => isEmpty(value?.name)
        },
        {
            id: 5,
            field: 'size',
            label: 'Cantidad de colaboradores',
            getValue: ({ value }) => isEmpty(value?.name)
        },
        {
            id: 6,
            field: 'status',
            label: '',
            cellRenderer: ({ data }) => (
                <div className="text-center w-full">
                    {!data?.status && <IonButton className="sfn-button" fill="outline" shape="round" onClick={() => { handleAcceptInvitation(data?.id) }}>Aceptar invitación</IonButton>}
                </div>
            )
        },
        {
            id: 'option',
            label: '',
            width: "max-content",
            pinned: true,
            sortable: true,
            resizable: false,
            cellRenderer: Options
        },

    ];

    const tableProps = (show) => ({
        showSearch: show,
        showRowsInformation: show,
        isPaginated: show,
        showColumnVisibilityManager: show
    })



    return (
        <IonPage>
            <Header title="Empresas" subtitle="Empresas que has creado y a las que te han invitado."></Header>
            <IonContent className="sfn-content">

                <section className="flex justify-between mb-8">
                    <div>
                        <h3 className="text-2xl font-bold">Mis empresas</h3>
                        {/* <IonNote color="medium">Plan {plan?.name}: mis empresas restantes {(plan?.enterprises - data?.enterprises?.length)}</IonNote> */}
                    </div>
                    <IonButton className="sfn-button" shape="round" onClick={() => setShowBusinessModal(true)}>
                        <IonIcon slot="start" icon={addCircleOutline} />
                        Agregar empresa
                    </IonButton>
                </section>

                <GridTable
                    className="sfn-data-table sfn-data-table-xs"
                    columns={columns}
                    rows={data?.enterprises}
                    isLoading={loading}
                    {...tableProps(data?.enterprises?.length ? true : false)}
                    components={{
                        NoResults: () => (
                            <Placeholder icon={businessOutline} description='Aún no tienes empresas agregadas haz clic en el botón "Agregar empresa"' />
                        ),
                        Search: SearchTable
                    }}
                    texts={texts}
                    icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                />
            </IonContent>
            {showBusinessModal && <BusinessModal showBusinessModal={showBusinessModal} setShowBusinessModal={setShowBusinessModal} setBusinessId={setBusinessId} businessId={businessId} />}
            {showAcceptInvitationModal && <AcceptInvitationModal showModal={showAcceptInvitationModal} setShowModal={setShowAcceptInvitationModal} user={authState} enterprise={enterprise} changeEnterprise={changeEnterprise} setAuth={setAuth} />}
        </IonPage>
    )
}

export default BusinessesPage;