import { IonContent, IonPage, IonButton, IonIcon, useIonAlert, IonNote } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { flagOutline, settingsOutline } from 'ionicons/icons';
import Header from '../../components/header/Header';
import { getAllEmployeesQuery } from '../../graphql/employee/queries';
import Kpi from '../../components/kpi/Kpi';
import Tabs from '../../components/tabs/Tabs';
import Employee from './tabs/employee/Employee';
import BenefitDiscount from './tabs/benefitDiscount/BenefitDiscount';
import SettingPayrollModal from './../../components/modals/setting-payroll-modal/SettingPayrollModal'
import Record from './tabs/record/Record';
import { useAuth } from '../../context/auth/AuthState';
import queryString from 'query-string'
import { getPayrollIndicator } from '../../graphql/payroll/queries';
import { formatMoney } from '../../helpers/utils';
import { AuthStore } from '../../store/auth';

const PayrollsPage = ({ location, history }) => {
  let values = queryString.parse(location.search);
  const [selected, setSelected] = useState(values.tab || 'employee');
  const [showSettingPayrollModal, setShowSettingPayrollModal] = useState(false);
  const { data, loading, error } = useQuery(getAllEmployeesQuery, { fetchPolicy: 'cache-and-network' });
  const { data: dataIndicators, loading: loadingIndicators, error: errorIndicators, refetch: refetchIndicators } = useQuery(getPayrollIndicator);
  const { authState } = useAuth();
  const { enterprise } = authState;
  const user = AuthStore.useState(s => s.user)

  let tabs = [
    {
      title: "Colaboradores",
      value: 'employee',
    },
    {
      title: "Beneficios y descuentos",
      value: 'benefit',
    },
    {
      title: "Historial de nóminas",
      value: 'record',
    },
  ];


  return (
    <IonPage>
      <Header title="Pago de nómina" subtitle={`Cantidad de colaboradores: ${(data?.employees?.rows.length) || 0}`}></Header>
      <IonContent className="sfn-content">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center my-8">
          <Kpi icon={flagOutline} loading={loadingIndicators} title={formatMoney(user?.enterprise, dataIndicators?.payrollIndicators?.salaries?.value, true)} description={dataIndicators?.payrollIndicators?.salaries?.label} />
          <Kpi icon={flagOutline} className="my-6 md:my-0" loading={loadingIndicators} title={formatMoney(user?.enterprise, dataIndicators?.payrollIndicators?.benefits?.value, true)} description={dataIndicators?.payrollIndicators?.benefits?.label} />
          <Kpi icon={flagOutline} loading={loadingIndicators} title={formatMoney(user?.enterprise, dataIndicators?.payrollIndicators?.discounts?.value, true)} description={dataIndicators?.payrollIndicators?.discounts?.label} />
        </div>

        <div className="flex w-full items-end mt-12 mb-20">
          <Tabs className="w-full justify-start" scrollable={true} tabs={tabs} selected={selected} setSelected={setSelected} url={true} path="/payrolls" history={history} />
          <IonButton shape="round" fill="clear" className="sfn-button-rounder-full" onClick={() => setShowSettingPayrollModal(true)}>
            <IonIcon color="medium" size="large" slot="icon-only" icon={settingsOutline} ></IonIcon>
          </IonButton>
        </div>
        {selected == 'employee' && <Employee enterprise={user?.enterprise} setShowSettingPayrollModal={setShowSettingPayrollModal} refetchIndicators={refetchIndicators}/>}
        {selected == 'benefit' && <BenefitDiscount enterprise={user?.enterprise} refetchIndicators={refetchIndicators}  />}
        {selected == 'record' && <Record enterprise={user?.enterprise}/>}

        {showSettingPayrollModal && <SettingPayrollModal showSettingPayrollModal={showSettingPayrollModal} setShowSettingPayrollModal={setShowSettingPayrollModal} />}
      </IonContent>
    </IonPage>
  );
};

export default PayrollsPage;
