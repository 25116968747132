import { Store } from 'pullstate';

export const UsersStore = new Store({ users: [] });

export const updateUserStore = (data) => {
  UsersStore.update((s) => {
    let index = s.users.findIndex((user) => user.id === data.id);
    s.users[index] = data;
  });
};

export const addUserStore = (data) => {
  UsersStore.update((s) => {
    if(Array.isArray(data)) {
        s.users = [...data];
    }else {
        s.users = [...s.users, data];
    }
  });
};


export const removeUserStore = (id) => {
  UsersStore.update((s) => {
    s.users = [...s.users.filter((f) => f.id !== id)];
  });
};
