import gql from 'graphql-tag'

export const createUserMutation = gql`
	mutation createUser($input: CreateUser!) {
		createUser(input: $input) {
			id
			email
			name
			status
			picture
			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
			}
			role {
				id
				name
				permissions
			}

		}
	}
`

export const updateUserMutation = gql`
	mutation updateUser($input: UpdateUser!) {
		updateUser(input: $input) {
			id
			email
			name
			picture

			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
			}
			role {
				id
				name
				permissions
			}

		}
	}
`

export const deleteUserMutation = gql`
	mutation deleteUser($input: ID!) {
		deleteUser(id: $input) {
			id
			email
			name
			picture

			bills {
				id
				number
				exchangeRate
				description
				date
				dueDate
			}
			role {
				id
				name
				permissions
			}

		}
	}
`

export const updatePasswordMutation = gql`
	mutation updatePassword($password: String!, $newPassword: String!) {
		updatePassword(password: $password, newPassword: $newPassword) {
			message
		}
	}
`