import { types } from "../types/types";

const AuthReducer = (state = {}, action) => {
    switch (action.type) {
        case types.AUTH_LOGIN:
            return {
                ...state,
                ...action.payload,
                checking: false,
                isAuthenticated: true
            }
        case types.AUTH_LOGOUT: 
            return {
                checking: false,
                isAuthenticated: false
            }
        default:
            break;
    }
    return state
}

export default AuthReducer;