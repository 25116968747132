import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, IonContent, useIonToast, IonCheckbox } from '@ionic/react';
import { closeOutline, trashOutline, } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import Select from 'react-select';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { currencies, countries, phoneTypes, bankAccountTypes, areaCodes } from '../../../helpers/catalogs';
import { validate, validateKey } from '../../../helpers/validations';
import { formatSelectData, defaultValue } from '../../../helpers/utils';
import { useVendorsService } from '../../../graphql/vendor/service';
import SfnButton from '../../button/Button';
import { validationMap } from './validation';
import { getOneVendorQuery } from '../../../graphql/vendor/queries';
import { useApolloClient, useQuery } from '@apollo/client';
import './VendorModal.scss';
import { Tooltip } from '../../tooltip/Tooltip';
import { AuthStore } from '../../../store/auth';
import { findCustomerByIdentifier } from '../../../graphql/customer/queries';

const initialState = {
    name: '',
    emails: [''],
    phones: [{ number: '', areaCode: '', type: '' }],
    bill: {
        currencyId: '',
        nit: '',
    },
    bank: {
        type: '',
        number: '',
        name: '',
    },
    description: '',
    address: {
        address: '',
        state: '',
        countryId: '',
        zip: '',
    }
}


const VendorModal = ({ showModal, setShowModal, vendorId = null, setVendorId = () => { }, afterCreate = () => {}}) => {
    const user = AuthStore.useState(s => s.user);
    const client = useApolloClient();
    const [isConsumer, setIsConsumer] = useState(false)
    const [isLoadingSearchVendor, setIsLoadingSearchVendor] = useState(false);

    const [step, setStep] = useState(1);
    const listSection = ['Contacto', 'Información cuentas bancarias'];
    const {formValues, handleInputChange, reset} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { name, emails, phones, bill, bank, description, address } = formValues;
    const { createVendor, cLoading, cError, updateVendor, uLoading, uError } = useVendorsService();
    const [valid, setValid] = useState({});
    const [present] = useIonToast();
    let { data, loading, error } = useQuery(getOneVendorQuery, {
        variables: { input: vendorId },
        skip: !vendorId,
        fetchPolicy: 'no-cache'
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const valid = validate(formValues, validationMap);
        setValid(valid);

        if (!valid.valid) {
            present({ message: 'Debes llenar todos los campos obligatorios', color: "warning", mode: "md", duration: 4000 });
            return;
        }
        let input = { ...formValues };
        input.bill.currencyId = formValues?.bill?.currencyId?.value?.id || null;
        input.address.countryId = formValues?.address?.countryId?.value || null;
        input.bank.type = formValues?.bank?.type?.value || null;
        input = {...validateField(input)};

        if (!vendorId) {
            await createVendor({ variables: { input } });
            afterCreate(input)
        } else {
            delete input.createdAt;
            delete input.bills;
            await updateVendor({ variables: { input } });
        }
        if (!cLoading && !uLoading) {
            if (cError && uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            }
            present({ message: `El proveedor se ${vendorId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            e.target.reset();
            onClose();
        }
        
        present({ message: `El proveedor se ${vendorId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
        e.target.reset();
        onClose();
    }

    const addElement = (type) => {
        switch (type) {
            case 'email':
                handleInputChange([...emails, ''], 'emails');
                break;
            case 'phone':
                handleInputChange([...phones, { number: '', areaCode: '', type: '' }], 'phones');
                break;
            default:
                break;
        }
    }

    const removeElement = (type, index) => {
        switch (type) {
            case 'email':
                handleInputChange(emails.filter((e, i) => i !== index), 'emails');
                break;
            case 'phone':
                handleInputChange(phones.filter((e, i) => i !== index), 'phones');
                break;
            default:
                break;
        }
    }

    const onClose = async () => {
        setVendorId(null);
        reset(JSON.parse(JSON.stringify(initialState)));
        setShowModal(false);
    }

    const stepPage = () => {
        const content = document.querySelector('#wrapper');
        let currentStep = step === listSection.length ? 1 : 2;
        setStep(currentStep);
        content.scrollTop = 0;
    }

    const validateField = (form) => {
        form.emails = form.emails.filter(email => email.trim() !== '')
        form.phones = form.phones.filter(({number, areaCode, type}) => number && areaCode && type)
        .map(phone => {
            phone.type = phone?.type?.value;
            phone.areaCode = phone?.areaCode?.value;
            return phone;
        })
        let {bill, ...more} = form;
        if(bill.currencyId == null && bill.nit == '') {
            form = {...more};
        }
        return form;
    }

    useEffect(() => {
        if (vendorId && !loading) {
            data = JSON.parse(JSON.stringify(data))
            if(data.vendor.bill == null){
                data.vendor.bill = {currencyId: '', nit: ''}
            }
            data.vendor.bill.currencyId = currencies.find(c => c.value.id === data.vendor.bill.currencyId);
            data.vendor.address.countryId = data.vendor.address.countryId == null ? '' : data.vendor.address.countryId;
            data.vendor.address.countryId = defaultValue({ value: 'id', label: 'name' }, countries, data.vendor.address.countryId)
            data.vendor.emails = Array.isArray(data.vendor.emails) && data.vendor.emails?.length > 0 ? data.vendor.emails : ['']
            data.vendor.phones = Array.isArray(data.vendor.phones) && data.vendor.phones?.length > 0 ? data.vendor.phones.map(phone => {
                phone.type = defaultValue({ value: 'id', label: 'name' }, phoneTypes, phone.type);
                phone.areaCode = defaultValue({ value: 'value', label: 'label' }, areaCodes, phone.areaCode);
                return phone;
            }) : [{ number: '', areaCode: '', type: '' }]

            delete data.vendor.createdAt;
            delete data.vendor.invoices
            delete data.vendor.outstandingAmount
            delete data.vendor.overdueAmount
            delete data.vendor.totalPaid

            reset(data.vendor);
        }
    }, [data]);

    const searchVendorByIdentifier = async(e) => {
        try {
            if(bill.nit === '' || bill.nit === null) return;
            e.preventDefault();
            setIsLoadingSearchVendor(true);
            handleInputChange('', 'name')
            handleInputChange('', 'address.address')
            const {data, errors} = await client.query({query: findCustomerByIdentifier,  fetchPolicy: 'no-cache', variables: {input: bill.nit}});
            
            if(data.findCustomerByIdentifier) {
                const {address, name} = data?.findCustomerByIdentifier;
                if(name === null && address === null) {
                    present({ message: 'El número de NIT no es válido, intentalo nuevamente 🤔', color: "warning", mode: "ios", duration:4000 });
                }else {
                    present({ message: 'El número de NIT es válido!', color: "success", mode: "ios", duration:4000 });
                    handleInputChange(name, 'name')
                    handleInputChange(address, 'address.address')
                }
            }
            if(errors) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration:4000 });
            }
            setTimeout(() => {
                setIsLoadingSearchVendor(false);
            }, 500);
        } catch (error) {
            if(error) {
                present({ message: 'El número de NIT no es válido, intentalo nuevamente 🤔', color: "warning", mode: "ios", duration:4000 });
            }
            setIsLoadingSearchVendor(false);
        }
   
    }

    function removeCharacter(e) {
        if(e?.keyCode === 189) {
            e.target.value = e.target.value.replaceAll('-', '').trim();
        }
        if(e.target.value.length > 0) {
            e.target.value = e.target.value.replaceAll('-', '').trim();
        }
        return handleInputChange(e)
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent id="contentVendor">
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-xl font-bold">{vendorId ? 'Editar' : 'Agregar'} proveedor</h3>
                    <p className="text-center text-base">Completa el siguiente formulario para {vendorId ? 'editar un proveedor de tu empresa' : 'agregar un nuevo proveedor de tu empresa'}</p>
                    <div className="flex justify-between my-5">
                        <h2 className="text-center text-xl font-bold">{listSection[step - 1]}</h2>
                        <p className="text-center text-xl">Sección {step}/{listSection.length}</p>
                    </div>
                    <div className={step != listSection.length ? 'h-full' : ' w-medium'}>
                        <div className="w-full">
                            {step == 1 && (
                                <form id="vendor-form" onSubmit={handleSubmit} className="h-full p-2">
                                    <div className="flex justify-between items-center">
                                        <IonItem className="sfn-input w-full" mode="md" lines="none">
                                            <IonLabel position="stacked">No. de identificación tributaria</IonLabel>
                                            <IonInput name="bill.nit" type="text" maxlength="13" placeholder="Indica No. de identificación tributaria del proveedor o CF" onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} value={bill.nit} autocomplete="off" disabled={isConsumer}></IonInput>
                                        </IonItem>
                                        {user?.enterprise?.bill?.issuer !== 'NONE' && <SfnButton btnClass="mt-10 ml-2" onClick={searchVendorByIdentifier} label="Buscar" loading={isLoadingSearchVendor}/>}
                                    </div>
                                    <div className="flex items-center my-4">
                                        <IonCheckbox className="sfn-checkbox mr-2" checked={isConsumer} onIonChange={e => {
                                            handleInputChange(e.detail.checked ? 'CF' : '', 'bill.nit')
                                            setIsConsumer(e.detail.checked)
                                        }} />
                                        <IonLabel className="mr-1">Consumidor final</IonLabel>
                                    </div>
                                    <div className="sfn-input" mode="md" lines="none">
                                        <div position="stacked" className="text-sm flex align-center mb-4">
                                            <p className="mr-1">Nombre de proveedor<span className="text-base text-danger">*</span></p>
                                            <Tooltip label="Persona o empresa a la que le compras un producto o servicio."/>
                                        </div>
                                        <IonInput name="name" type="text" placeholder="Indica el nombre del proveedor" onIonChange={handleInputChange} value={name} required autocomplete="off"> </IonInput>
                                    </div>
                                    <div className="flex flex-col my-4">
                                        <p className="text-xs mb-2 hover:text-secondary w-80">Correo electrónico</p>
                                        {emails.map((email, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <IonItem className="sfn-input w-full" mode="md" lines="none">
                                                    <IonInput name={`emails[${index}]`} type="email" required={emails.length > 1 && emails.length - 1 !== index} placeholder="correo@electronico.com" onIonChange={handleInputChange} value={email} autocomplete="off"> </IonInput>
                                                </IonItem>
                                                <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('email', index))}>
                                                    <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                </IonButton>
                                            </div>
                                        ))}
                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('email'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-4">
                                        <p className="text-sm mb-2 hover:text-secondary w-80">Teléfono</p>
                                        {phones.map((phone, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <Select value={phone.type} onChange={(e) => handleInputChange(e, `phones[${index}].type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-3/6 mx-3" styles={sfnSelectTheme} />
                                                <Select value={phone.areaCode} onChange={(e) => handleInputChange(e, `phones[${index}].areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-3/6 mx-3" styles={sfnSelectTheme} />
                                                <IonItem className="sfn-input" mode="md" lines="none">
                                                    <IonInput name={`phones[${index}].number`} type="text" placeholder="5555-5555" value={phone.number} onIonChange={handleInputChange} autocomplete="off"> </IonInput>
                                                </IonItem>
                                                <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('phone', index))}>
                                                    <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                </IonButton>
                                            </div>
                                        ))}
                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('phone'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h3 className="text-left text-xl font-bold">Facturación</h3>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary" >Moneda</p>
                                            <Select name="bill.currencyId" placeholder="Seleccione moneda" value={bill.currencyId} onChange={e => handleInputChange(e, 'bill.currencyId')} options={currencies} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">Dirección</IonLabel>
                                            <IonInput name="address.address" type="text" placeholder="Indica dirección" onIonChange={handleInputChange} value={address.address} autocomplete="off"></IonInput>
                                        </IonItem>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">Ciudad</IonLabel>
                                            <IonInput name="address.state" type="text" placeholder="Indica ciudad" onIonChange={handleInputChange} value={address.state} autocomplete="off"></IonInput>
                                        </IonItem>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary">País</p>
                                            <Select name="address.countryId" placeholder="Seleccione país" value={address.countryId} onChange={e => handleInputChange(e, 'address.countryId')} options={formatSelectData({ value: 'id', label: 'name' }, countries)} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">ZIP</IonLabel>
                                            <IonInput name="address.zip" type="text" placeholder="Indica ZIP de área" onIonChange={handleInputChange} value={address.zip} autocomplete="off"> </IonInput>
                                        </IonItem>
                                    </div>
                                </form>

                            )}
                            {step == 2 && (
                                <form id="vendor-form2" onSubmit={handleSubmit} className="h-full p-2">
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Banco</IonLabel>
                                        <IonInput name="bank.name" type="text" placeholder="Nombre de banco de cuenta" onIonChange={handleInputChange} value={bank.name} autocomplete="off"> </IonInput>
                                    </IonItem>
                                    <div className="mt-4">
                                        <p className="text-sm mb-2 hover:text-secondary w-80">Tipo de cuenta</p>
                                        <Select placeholder="Seleccione tipo de cuenta" value={formatSelectData({ value: 'id', label: 'name' }, bankAccountTypes).find(f => f.value == bank.type)} onChange={e => handleInputChange(e, 'bank.type')} options={formatSelectData({ value: 'id', label: 'name' }, bankAccountTypes)} className="sfn-select" styles={sfnSelectTheme} />
                                    </div>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Número de cuenta bancaria</IonLabel>
                                        <IonInput name="bank.number" type="text" placeholder="000000000" onIonChange={handleInputChange} value={bank.number} autocomplete="off"> </IonInput>
                                    </IonItem>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Notas</IonLabel>
                                        <IonTextarea name="description" placeholder="Comentarios a tomar en cuenta sobre cliente..." onIonChange={handleInputChange} value={description} autocomplete="off"> </IonTextarea>
                                    </IonItem>
                                </form>
                            )}
                        </div>

                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={onClose}>Cancelar</IonButton>
                            <IonButton className="sfn-button sm:mr-8 " shape="round" fill="outline" onClick={() => stepPage()}>
                                {step === listSection.length ? 'Regresar' : 'Siguiente'}
                            </IonButton>
                            <SfnButton form={step === listSection.length ? 'vendor-form2' : 'vendor-form'} label={vendorId ? 'Guardar cambios' : 'Guardar proveedor'} loading={vendorId ? uLoading : cLoading} />
                        </div><br /><br />
                    </div>
                </Wrapper>
            </IonContent>
        </IonModal>

    )

}

export default VendorModal;