import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, IonNote, useIonAlert, useIonToast } from '@ionic/react';
import Header from '../../components/header/Header';
import { peopleOutline, addCircleOutline, pushOutline, downloadOutline, play, eye, create, trash, document, duplicate, filterOutline, createOutline, trashOutline, eyeOutline } from 'ionicons/icons';
import { useForm } from '../../hooks/useForm';
import GridTable from '@nadavshaar/react-grid-table';
import OptionItem from '../../components/option-item/OptionItem';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import VendorModal from '../../components/modals/vendor-modal/VendorModal';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import { getAllVendorsQuery } from '../../graphql/vendor/queries';
import { useQuery } from '@apollo/client';
import { bankAccountTypes } from '../../helpers/catalogs';
import { useVendorsService } from '../../graphql/vendor/service';
import Placeholder from '../../components/placeholder/Placeholder';
import { texts } from '../../theme/dataTabelTexts';
import { useAuth } from '../../context/auth/AuthState';
import SearchTable from '../../components/search-table/SearchTable';
import { formatMoney, isEmpty } from '../../helpers/utils';
import { AuthStore } from '../../store/auth';

const VendorsPage = ({ history }) => {
  const user = AuthStore.useState(s => s.user);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const { deleteVendor, dError, dLoading, createVendor, cError, cLoading } = useVendorsService();
  const { data, loading, error } = useQuery(getAllVendorsQuery);
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const {authState} = useAuth();
  const {enterprise} = authState;

  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => goToVendorPage(data.id)} icon={eyeOutline} caption="Ver detalle" />
      {!['READ'].includes(user?.role?.permissions['expenses']) && <><OptionItem onHide={onHide} event={() => handleEdit(data.id)} icon={createOutline} caption="Editar" />
      <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" /> </>}
    </>
  );

  function countItems(data = [], quantity = 1) {
    return Array.isArray(data) && data?.length === quantity
  }

  const columns = [{
    id: 1,
    field: 'name',
    label: 'Nombre'
  },
  {
    id: 2,
    field: 'bill',
    label: 'Identificador tributario',
    getValue: ({ value }) => isEmpty(value?.nit)
  },
  {
    id: 3,
    field: 'phones',
    label: 'Teléfono',
    getValue: ({ value }) => countItems(value, 1) ? `(+${value[0]?.areaCode}) ${value[0]?.number}` : ' - - -'
  },
{
    id: 4,
    field: 'emails',
    label: 'Correo electrónico',
    getValue: ({ value }) => countItems(value, 1) ? value[0] : ' - - -'
  },
  {
    id: 5, 
    field: 'outstandingAmount', 
    label: 'Saldo',
    cellRenderer: ({ data }) => {
      return(
      <div className="text-right w-full m-4">
        <p>{formatMoney(enterprise, data.outstandingAmount, true)}</p>
      </div>)
    }
  },
  {
    id: 6, 
    field: 'overdueAmount', 
    label: 'Atrasado',
    cellRenderer: ({ data }) => {
      return(
      <div className="text-right w-full m-4">
        <p>{formatMoney(enterprise, data.overdueAmount, true)}</p>
      </div>)
    }
  },
  {
    id: 'option',
    label: '',
    width: "max-content",
    pinned: true,
    cellRenderer: ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />)
  }];


  const goToVendorPage = (id) => {
    history.push(`/vendors/${id}`);
  }
  const onRowClick = ({ event, data }) => {
    event.stopPropagation();
    goToVendorPage(data.id);
  }

  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar proveedor',
      subHeader: 'Este proveedor se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este proveedor?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar proveedor',
          cssClass: 'delete',
          handler: async () => {
            let resp = await deleteVendor({ variables: { input: id } });
            if(resp.errors){
              showErrorAlert(resp.errors)
             } else {
              presentToast({ message: 'El proveedor se ha eliminado correctamente 👍', color: "success", mode: "ios", duration: 4000 });
             }
          }
        }
      ],
    })
  }

  const showErrorAlert = (errors) => {
    const {extensions} = errors[0];
    if(extensions?.code === "LOCKED"){
       present({
         cssClass: 'sfn-alert-dialog',
         mode: 'md',
         header: 'Oops! ha ocurrido un problema',
         subHeader: `No podemos eliminar este proveedor porque está asociado con lo siguiente:
         ${extensions?.bills?.rows?.map( bill => {
           return `\n• Factura ${bill?.number}`
         })}
         `,
         message: `Mostrando ${extensions?.bills?.rows?.length}/${extensions?.bills?.length} Para eliminar este proveedor, elimínelo de los elementos enumerados anteriormente`,
         buttons: [
           {
             text: 'Cerrar',
             role: 'cancel',
             cssClass: 'secondary',
           },
         ],
       })
     } else {
       presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
     }
  }


  const handleEdit = (id) => {
    setId(id);
    setShowModal(true);
  }

  const handleDuplicate = async (data) => {
    let input = { ...data };
    delete input.id;
    await createVendor({ variables: { input } });
    if (!cError) {
      presentToast({ message: `Se ha duplicado el proveedor! 👍`, color: "success", mode: "md", duration: 4000 })
    } else {
      presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
    }
  }
  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })

  return (
    <IonPage>
      <Header title="Mis proveedores" subtitle={`Cantidad de proveedores: ${data?.vendors?.rows.length || 0}`}></Header>
      <IonContent className="sfn-content">
        <div className="flex flex-col justify-end items-center md:flex-row md:justify-end md:items-center">
          {/* <div>
            <p className="text-xs mb-2 hover:text-secondary w-80" >Tipo de Cliente</p>
            <Select placeholder="Tipo de Cliente" value={selectedOption} options={options} className="sfn-select z-10" styles={sfnSelectTheme} />
          </div> */}
          <div className="buttons flex self-end items-center justify-end">
            {/* <IonButton className="sfn-button" shape="round" fill="clear">
              <IonIcon slot="start" icon={pushOutline} />
              Importar
            </IonButton>
            <IonButton className="sfn-button" shape="round" fill="clear">
              <IonIcon slot="start" icon={downloadOutline} />
              Descargar
            </IonButton> */}
            <IonButton disabled={['READ'].includes(user?.role?.permissions['expenses'])} className="sfn-button" shape="round" onClick={() => (setShowModal(true))}>
              <IonIcon slot="start" icon={addCircleOutline} />
              Agregar proveedor
            </IonButton>
          </div>
        </div>
        
          <GridTable className="sfn-data-table pt-10" columns={columns} rows={data?.vendors?.rows}
            isLoading={loading} texts={texts} onRowClick={onRowClick}
            components={{
              NoResults: () => (
                <Placeholder icon={peopleOutline} description='Aún no tienes proveedores agregados haz clic en el botón "Agregar proveedor"' />
              ),
              Search: SearchTable
            }}
            {...tableProps(data?.vendors?.rows.length ? true : false)}
            icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }} />
        
        {showModal && <VendorModal showModal={showModal} setShowModal={setShowModal} vendorId={id} setVendorId={setId} />}
      </IonContent>
    </IonPage>
  );
};

export default VendorsPage;
