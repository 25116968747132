import Kpi from "../../../../../components/kpi/Kpi";

const Indicator = ({loading, enterprise, data}) => {
    return (<div className="flex mt-12 flex-col md:flex-row items-start justify-start md:items-center md:justify-center">
        <div className="flex flex-row">
            <Kpi loading={loading} title={`${enterprise?.currency?.symbol}${data?.cash?.value} ${enterprise?.currency?.code}`} description='Efectivo y Banco' />
            <h3 className="text-xl md:text-3xl font-bold text-danger mx-8">+</h3>
        </div>
        <div className="flex flex-row">
            <Kpi loading={loading} title={`${enterprise?.currency?.symbol}${data?.received?.value} ${enterprise?.currency?.code}`} description='Recibido' />
            <h3 className="text-xl md:text-3xl font-bold text-danger mx-8">-</h3>
        </div>
        <div className="flex flex-row">
            <Kpi loading={loading} title={`${enterprise?.currency?.symbol}${data?.paid?.value} ${enterprise?.currency?.code}`} description='Pagado' />
            <h3 className="text-xl md:text-3xl font-bold mx-8">=</h3>
        </div>
        <Kpi loading={loading} title={`${enterprise?.currency?.symbol}${data?.total?.value} ${enterprise?.currency?.code}`} colorTitle="text-secondary" description='Total' />
    </div>);
}

export default Indicator;