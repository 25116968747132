import { useHistory } from 'react-router';
import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import './BreadCrumbs.scss';
const BreadCrumbs = ({ breads, className="" }) => {
    const history = useHistory()
    const goTo = url => history.replace(url)

    return (
        <div className={`flex items-center justify-start breads ${className}`}>
            {breads.map((bread, index) => (
                <div key={'bread'+index} className="items-center flex">
                    {!bread.url ? <p  className="">{bread.title}</p> : <a onClick={e => goTo(bread.url)}>{bread.title}</a>}
                    {index < breads.length-1 && <IonIcon  slot="start" className="mx-2" icon={chevronForwardOutline} />}
                </div>
            ))}
        </div>
    )
}

export default BreadCrumbs;