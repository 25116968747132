import { IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import { useForm } from '../../hooks/useForm';
import InputPassword from '../../components/input-password/InputPassword';
import './LoginPage.scss';
import SfnButton from '../../components/button/Button';
// import useGaTracker from '../../components/google-analytics/usePageTracking'

const initialState = {
  email: "",
  password: ""
}
const LoginPage = () => {
  const [present] = useIonToast();
  const { signin, loading, error} = useAuth();
  const {formValues, handleInputChange} = useForm(initialState);
  const { email, password } = formValues;

  const handleSubmit = (e) => {
    e.preventDefault();
    signin(email, password);
  }

  // useGaTracker()
  useEffect(() => {
    if(error){
      present({ message: 'El correo eletrónico o contraseña no son válidos 🤔', color: "danger", mode: "md", duration: 4000});
    }
  }, [error])
  
  return (
    <IonPage>
      <IonContent>
        <div className="login-container">
          <div className="box">
            <div className="login-wrapper">
              <img className="w-56 py-8" src="../assets/icon/logo.svg" alt="logo" />
              <div className="welcome">
                <h3 className="text-center">Iniciar Sesión</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <IonItem className="sfn-input" mode="md" lines="none">
                  <IonLabel position="stacked">Correo electrónico</IonLabel>
                  <IonInput type="email" name="email" value={email} onIonChange={handleInputChange} required> </IonInput>
                </IonItem>
                <InputPassword label="Contraseña" name="password" value={password} onChange={handleInputChange} required={true} />
                <SfnButton label="Iniciar sesión" btnClass="w-full mt-8" loading={loading} />
              </form>
              <div className="text-l hover:text-primary">
                <a href="/reset">¿Has olvidado tu contraseña?</a>
              </div>
              
                <a className="hover:text-primary sfn-form-button m-6 sfn-registry" href="/signup">
                  <IonButton className="sfn-button sfn-form-button w-full h-10 text-white" color="secondary" shape="round">Registrate aquí</IonButton>
                </a>
              
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
