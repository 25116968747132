import gql from 'graphql-tag'

export const createReceiptMutation = gql `
	mutation createReceipt($input: CreateReceipt!) {
		createReceipt(input: $input) {
			id
			merchant
			description
			subtotal
			total
			date
			account {
				id
				name
			}
			category {
				id
				name
			}
			currency {
				id
				name
				symbol
			}
			taxes {
				id
				tax {
					id
					name
				}
				rate
			}
		}
	}
`

export const updateReceiptMutation = gql `
	mutation updateReceipt($input: UpdateReceipt!) {
		updateReceipt(input: $input) {
			id
			merchant
			description
			subtotal
			total
			date
			picture
			account {
				id
				name
			}
			category {
				id
				name
			}
			currency {
				id
				name
				symbol
			}
			taxes {
				id
				tax {
					id
					name
				}
				rate
			}
		}
	}
`

export const deleteReceiptMutation = gql `
	mutation deleteReceipt($input: ID!) {
		deleteReceipt(id: $input) {
			id
		}
	}
`