import { mailOutline } from "ionicons/icons";
import { useState } from "react";
import AlertModal from "./components/alert-modal/AlertModal";
import BillingModal from "./components/billing-modal/BillingModal";
import Detail from "./components/detail/Detail";
import VerifyRTUModal from "./components/verifiy-rtu-modal/VerifiyRTUModal";

const Billing = ({ authState, enterprise, refetchEnterprise, }) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalVerifyRTU, setShowModalVerifyRTU] = useState(false)
    const [findCustomer, setFindCustomer] = useState(null)
    const [showAlertConfirSignatureModal, setShowAlertConfirSignatureModal] = useState(false)

    return (
        <>
            <Detail {...{ authState, enterprise, showModal: showModalVerifyRTU, setShowModal: setShowModalVerifyRTU }} />
            {showModalVerifyRTU && <VerifyRTUModal showModal={showModalVerifyRTU} setShowModal={setShowModalVerifyRTU} setShowBillingModal={setShowModal} setFindCustomer={setFindCustomer} />}
            {(enterprise && showModal) && <BillingModal {...{ enterprise, showModal, setShowModal, refetchEnterprise, notUpdate: true, findCustomer, setShowModalVerifyRTU, setShowAlertConfirSignatureModal}} />}
            <AlertModal icon={mailOutline} colorIcon="PRIMARY" hideCancelButton={true} showModal={showAlertConfirSignatureModal} setShowModal={setShowAlertConfirSignatureModal} type="SUCCESS" title="Revisa tu correo electrónico para firmar tu contrato" description={`Para facturar electrónicamente debes hacer clic en el enlace del correo electrónico que tu certificadora te ha enviado a tu correo de registro.`} />

        </>
    );
}

export default Billing;