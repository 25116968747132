import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, useIonToast, IonContent } from '@ionic/react';
import { checkbox, checkmark, closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import language from './language.json';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { validationMap } from './validation';
import { useUsersService } from '../../../graphql/user/service';
import './ResetModal.scss';
import SfnButton from '../../button/Button';

const text = language.es;
const initialState = {
    password: "",
    newPassword: "",
    // confirmPassword: ""
};
const values = {
    'lower': false,
    'upper': false,
    'number': false,
    'symbol': false,
    'length': false,
    'spaces': false
}
const ResetModal = ({ showModal, setShowModal }) => {

    const { formValues, handleInputChange, reset } = useForm(initialState);
    const { password, newPassword, confirmPassword } = formValues;
    const [passwordType, setPaswordType] = useState('password');
    const [newType, setNewType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');
    const [present] = useIonToast();
    const [validation, setValidity] = useState(values);
    const { createUser, cLoading, cError, updateUser, uLoading, uError, uPassword, uPLoading, uPError } = useUsersService();

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formValues);
        if (validation.length &&
            validation.upper &&
            validation.lower &&
            validation.number &&
            validation.symbol &&
            password !== "") {

            console.log(formValues);
            let values = { password: formValues.password, newPassword: formValues.newPassword }
            await uPassword({ variables: values })

            if (!uPLoading) {
                if (uPError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
                    // reset();
                    // onClose();
                    return
                } else { }
                present({ message: `Contraseña actualizada exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
                reset();
                onClose();
            }
        }

    }

    const validate = (event, key) => {
        handleInputChange(event.detail.value, key)
        let str = event.detail.value || ""
        let a = /[a-z]/g.test(str);
        let b = /[A-Z]/g.test(str);
        let c = /[0-9]/g.test(str);
        let d = /[^a-zA-Z\d]/g.test(str);
        let e = str.length >= 8;
        let f = !(/\s/g.test(str));

        let valid = {
            values: {
                'lower': a,
                'upper': b,
                'number': c,
                'symbol': d,
                'length': e,
                'spaces': f
            },
        };
        setValidity(valid.values);
    }

    const onClose = () => {
        reset();
        setShowModal(false);
    }

    return (
        <IonModal isOpen={showModal} className="rounded-2xl snf-reset-modal sfn-modal-medium-height" backdropDismiss="false" onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-xl font-bold">{text.title}</h3>
                    <p className="text-center">{text.description}</p>

                    <form id="reset-form" onSubmit={handleSubmit} className="h-full p-2">

                        <IonItem className="sfn-input snf-input-password" mode="md" lines="none">
                            <IonLabel position="stacked">{text.password}</IonLabel>
                            <IonInput
                                name="password"
                                type={passwordType}
                                onIonChange={handleInputChange}
                                value={password}
                                required> </IonInput>
                            <span className="btn_eye_icon" onClick={() => setPaswordType(passwordType == 'text' ? 'password' : 'text')}>
                                <IonIcon icon={passwordType == 'password' ? eyeOutline : eyeOffOutline} />
                            </span>
                        </IonItem>

                        <IonItem className="sfn-input snf-input-password" mode="md" lines="none">
                            <IonLabel position="stacked">{text.newPassword}</IonLabel>
                            <IonInput
                                name="newPassword"
                                type={newType}
                                onIonChange={e => validate(e, 'newPassword')}
                                value={newPassword}
                                required> </IonInput>
                            <span className="btn_eye_icon" onClick={() => setNewType(newType == 'text' ? 'password' : 'text')}>
                                <IonIcon icon={newType == 'password' ? eyeOutline : eyeOffOutline} />
                            </span>
                        </IonItem>

                        {/* <IonItem className="sfn-input snf-input-password" mode="md" lines="none">
                        <IonLabel position="stacked">{text.confirmPassword}</IonLabel>
                        <IonInput
                            name="confirmPassword"
                            type={confirmType}
                            onIonChange={handleInputChange}
                            value={confirmPassword}
                            required> </IonInput>
                        <span className="btn_eye_icon" onClick={() => setConfirmType(confirmType == 'text' ? 'password' : 'text')}>
                            <IonIcon icon={confirmType == 'password' ? eyeOutline : eyeOffOutline} />
                        </span>
                    </IonItem> */}

                        <div className="flex flex-col items-center justify-center mt-8">
                            {/* {(newPassword != "") && <div className="w-4/5 my-1"><IonIcon icon={newPassword == confirmPassword ? checkmark : closeCircleOutline} color={newPassword == confirmPassword ? "success" : "danger"} /> {text.validators.equale}</div>} */}
                            <div className="w-4/5 my-1"><IonIcon icon={validation.length ? checkmark : closeCircleOutline} color={validation.length ? "success" : "danger"} /> {text.validators.length}</div>
                            <div className="w-4/5 my-1"><IonIcon icon={validation.lower ? checkmark : closeCircleOutline} color={validation.lower ? "success" : "danger"} /> {text.validators.lower}</div>
                            <div className="w-4/5 my-1"><IonIcon icon={validation.upper ? checkmark : closeCircleOutline} color={validation.upper ? "success" : "danger"} /> {text.validators.upper}</div>
                            <div className="w-4/5 my-1"><IonIcon icon={validation.number ? checkmark : closeCircleOutline} color={validation.number ? "success" : "danger"} /> {text.validators.number}</div>
                            <div className="w-4/5 my-1"><IonIcon icon={validation.symbol ? checkmark : closeCircleOutline} color={validation.symbol ? "success" : "danger"} /> {text.validators.symbol}</div>
                            <div className="w-4/5 my-1"><IonIcon icon={validation.spaces ? checkmark : closeCircleOutline} color={validation.spaces ? "success" : "danger"} /> {text.validators.spaces}</div>
                        </div>

                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => onClose()}>
                                {text.actions.cancel}
                            </IonButton>
                            <SfnButton form="reset-form" loading={uPLoading} label={text.actions.confirm} className="sfn-button" shape="round" disabled={!validation.length ||
                                !validation.upper ||
                                !validation.lower ||
                                !validation.number ||
                                !validation.symbol ||
                                password == ""}
                            />
                        </div>

                    </form>
                </Wrapper>
            </IonContent>

        </IonModal>

    )

}

export default ResetModal;