import { IonIcon } from "@ionic/react";
import './Placeholder.scss';

const Placeholder = ({klass="", descriptionKlass="text-gray", colorIcon="secondary", icon, title = "", description="No hay resultados", btnLabel="", event = () => {}}) => (
    <div id="sfn-placeholder" className={`flex flex-col items-center justify-center w-full h-full ${klass}`}>
        <div className="sfn-placeholder-icon rounded-full w-32 h-32 flex items-center justify-center">
            <IonIcon icon={icon} color={colorIcon}/>
        </div>
        {title && <h3 className="font-bold text-xl mt-4">{title}</h3>}
        {description && <p className={`text-base font-normal ${title ? 'mt-4' : 'mt-8'}  max-w-lg text-center ${descriptionKlass}`}>{description}</p>}
    </div>
)

export default Placeholder;