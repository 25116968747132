import { IonContent, IonPage, IonImg } from "@ionic/react";
import { useHistory } from "react-router";
import Header from "../../components/header/Header";
import { modules } from './utils';

const ReportsPage = () => {
  const history = useHistory();

  const goTo = (path) => {
    history.push(path);
  }


  const CardModule = ({ module, index, klass }) => {
    return (
      <div className={`shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white mx-12 mb-12 ${klass}`}>
        <div className="flex md:flex-row flex-col w-full items-center">
          <div className="p-4 justify-center items-center">
            <IonImg src={module?.image} className="h-28 w-28" />
          </div>
          <div className="flex flex-col justify-start md:text-left text-center w-full">
            <h3 className="font-bold text-base sm:text-3xl text-gray-blue">
              {module?.title}
            </h3>
            <p className="mt-1 text-base">
              {module?.description}
            </p>
          </div>
          <div className="flex flex-col ml-auto md:w-72 w-full md:mt-0 mt-8">
            {module?.items.map((item, index) => (<p key={index} className="cursor-pointer text-gray-blue hover:text-primary my-2" onClick={() => goTo(item?.link)}>{item?.name}</p>))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <IonPage>
      <Header title="Reportes"></Header>

      <IonContent fullscreen>
        <CardModule module={modules[0]} klass='mt-12' />
        {modules.filter((e, i) => i !== 0).map((module, index) => (<CardModule key={index} module={module} />))}
      </IonContent>
    </IonPage>
  );
};

export default ReportsPage;
