import { useEffect, useState } from 'react';
import { IonIcon, IonButton, IonTextarea, useIonToast, IonProgressBar } from '@ionic/react';
import { closeCircleOutline, arrowUpOutline, trashOutline } from 'ionicons/icons';
import { uploadFileMutation } from '../../../graphql/file/mutations';
import { useEnterprisesService } from '../../../graphql/enterprise/service';
import { useAuth } from '../../../context/auth/AuthState';
import { useForm } from '../../../hooks/useForm';
import './Customization.scss';
import { readURL, uploadFiles, getUrlFile } from '../../../helpers/uploadFiles';
import { useQuery, useApolloClient } from '@apollo/client';
import SfnButton from '../../../components/button/Button';
import { AuthStore } from '../../../store/auth';


const CustomizationSettingsPage = ({}) => {
	const user = AuthStore.useState(s => s.user);
	const { updateEnterprise, uLoading, uError, } = useEnterprisesService();
	const {formValues, handleInputChange, reset} = useForm({
		invoiceFooter: ''
	});
	const { invoiceFooter } = formValues;
	const [showUploadModal, setUploadModal] = useState(false)
	const { authState, setAuth } = useAuth();
	const [present] = useIonToast();
	const [files, setFiles] = useState();
	const client = useApolloClient();
	const [url, setUrl] = useState('');

	

	useEffect(async () => {
		try {
			if(authState?.enterprise?.data?.urlPicture) {
				console.log(authState?.enterprise?.data?.urlPicture);
				setUrl(authState?.enterprise?.data?.urlPicture)
			}
		} catch (error) {
		}
		handleInputChange(authState?.enterprise?.data?.footer, 'invoiceFooter')
	}, [])


	const onSave = async () => {
		let _files;
		if (files) {
			_files = await uploadFiles(client, files, setFiles)
		}
		let input = {
			id: authState.enterprise.id,
			data: {
				picture: _files && _files[0] ? _files[0].ufid : url !== '' ? authState.enterprise.data.picture : null,
				footer: invoiceFooter
			}
		}
		await updateEnterprise({ variables: { input } })
		if (!uLoading) {
			if (uError) {
				present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
				return
			}
			let auth = JSON.parse(JSON.stringify(authState));
			auth.enterprise.data.picture = input.data.picture;
			if(_files) {
				auth.enterprise.data.urlPicture = _files[0]?.url;
			}
			auth.enterprise.data.footer = input.data.footer;
			handleInputChange(auth.enterprise.data.footer, 'invoiceFooter');
			setAuth({ user: auth, token: localStorage.getItem('token') });
			setUrl(auth.enterprise.data.urlPicture)
			setFiles([]);
			present({ message: `Tu empresa se actualizó exitosamente! 👍`, color: "success", mode: "ios", duration: 4000 });
		}
	}

	const handleFileInput = async (e) => {
		let { files: fileList } = e.target;
		if (fileList[0]) {
			if (fileList[0].size >= 5000000) {
				present({ message: `El archivo debe ser menor a 5mb 😯`, color: "warning", mode: "md", duration: 4000 });
				return;
			}
			let file = fileList[0];
			file.url = await readURL(file);
			setFiles([file]);
		}
	}

	const cancelUpload = (file) => {
		file.cancel("Operation canceled.");
        delete file.cancel;
        file.upload = false;
        file.progress = 0;
		setFiles([file]);
	}

	const deletePicture = () => {
		setFiles([]);
		setUrl('');
	}


	return (
		<div className="p-4 flex flex-col flex-wrap lg:w-1/2 w-full customization-content">
			<h2 className="text-xl text-gray-blue font-bold mb-4">
				Logotipo
			</h2>
			<p className="text-base text-gray-light mb-12">
				Sube el logotipo de tu empresa para que aparezca en tus facturas de venta
			</p>
			<div className="flex sm:flex-row flex-col justify-between items-center mb-8">
				<div>
					<h3 className="body-tag">
						Subir imagen (.jpg .png)
					</h3>
					<p className="text-base text-gray-light">
						Tamaño recomendado 350x75, Peso máx 5MB
					</p>
				</div>
				<div className="file-btn">
					<label htmlFor="file-upload" className="custom-file-upload">
						<IonIcon icon={arrowUpOutline} size="small" className="flex mr-2"></IonIcon>
						<span>Subir</span>
					</label>
					<input id="file-upload" type="file" onChange={handleFileInput} accept="image/x-png, image/jpeg" />
				</div>
			</div>
			{(files && files.length) ? (
				<>
					<h3 className="body-tag">
						Logotipo
					</h3>
					<div className="p-4 shadow-md flex flex-col justify-between mb-12 w-1/2">
						<img src={files[0].url} className="w-full object-contain h-60" />
						{files[0].progress ? <div className="flex items-center">
							<IonProgressBar color="primary" value={files[0].progress}></IonProgressBar>
							{files[0].progress && <IonIcon icon={closeCircleOutline} size="large" className="flex ml-2 cursor-pointer" onClick={() => {cancelUpload(files[0])}}></IonIcon> }
						</div> : ''}
						<div className="flex justify-end">
							<IonIcon icon={trashOutline} size="large" color="medium" className="flex mt-2 cursor-pointer" onClick={deletePicture}></IonIcon> 
						</div>
					</div>
				</>
			) :
				(url && url != '') &&
				<>
					<h3 className="body-tag">
						Logotipo
				</h3>
					<div className="p-4 shadow-md flex flex-col justify-between mb-12 w-1/2">
						<img src={url} className="w-full object-contain h-60" />
						<div className="flex justify-end">
							<IonIcon icon={trashOutline} size="large" color="medium" className="flex mt-2 cursor-pointer" onClick={deletePicture}></IonIcon> 
						</div>
					</div>
				</>
			}
			<h2 className="text-xl text-gray-blue font-bold mb-4">
				Pie de factura
			</h2>
			<p className="text-base text-gray-light mb-12">
				Agrega los términos tributarios e indicaciones importantes que deben mostrarse en el pie de tus facturas de venta.
			</p>
			<h3 className="body-tag">
				Pie de factura
			</h3>
			<IonTextarea rows={3} value={invoiceFooter} onIonChange={handleInputChange} placeholder="Indica términos y condiciones de factura y otra información importante..." className="bg-gray-white p-4 mb-12" name="invoiceFooter" />
			<div className="flex items-center justify-end">
				<SfnButton disabled={['READ'].includes(user?.role?.permissions['configuration'])} label="Guardar cambios" btnClass='w-1/2' loading={uLoading} onClick={onSave} />
			</div>
		</div>
	)
}

export default CustomizationSettingsPage;
