import React, { useEffect, useRef, useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonTextarea, IonImg, IonCheckbox, IonToggle } from "@ionic/react";
import './InvoiceGenerator.scss';
import { addCircleOutline, pricetagsOutline, trash, triangleOutline } from 'ionicons/icons';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import InputDate from '../input-date/InputDate';
import { useApolloClient, useQuery } from '@apollo/client';
import { getAllProductsQuery } from '../../graphql/product/queries';
import { getAllCustomersQuery } from '../../graphql/customer/queries';
import { currencies } from '../../helpers/catalogs';
import { fixedFloat, formatSelect, getRoundExchangeRate, roundValue } from '../../helpers/utils';
import { useAuth } from '../../context/auth/AuthState';
import Placeholder from '../placeholder/Placeholder';
import { useInvoice } from '../../hooks/useInvoice';
import { cardCustomer, ListNoOptionsMessage, MenuListButton } from '../select-menu-components/SelectMenuComponents';
import CustomerModal from '../modals/customer-modal/CustomerModal';
import ProductModal from '../modals/product-modal/ProductModal';
import TaxModal from '../modals/add-tax-modal/TaxModal';
import { getAllTaxRates } from '../../graphql/tax/queries';

const InvoiceGenerator = ({ formValues, handleInputChange, reset, rows, setRows, taxes, setTaxes, refetchTaxes, ratesLoading, setRatesLoading,}) => {
    const { getExchangeRate, authState } = useAuth();
    const client = useApolloClient();
    const { enterprise } = authState;
    const { date, dueDate, number, currencyId, customerId, products, poso, description, extras, includeTaxes } = formValues;
    const { data: allCustomer, loading: customersLoading, error: customersError } = useQuery(getAllCustomersQuery);
    const { calculate, totalTaxes, grandTotal, getRates, formatSelectTaxes, setIncludeTaxes } = useInvoice();
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);
    const [showTaxModal, setShowTaxModal] = useState(false);
    const [positionProduct, setPositionProduct] = useState(null)
    const [positionTax, setPositionTax] = useState(null)
    const getAllProducts = async () => {
        setProductsLoading(true);
        const { data, loading, error } = await client.query({
            query: getAllProductsQuery, variables: {
                options: {
                    where: {
                        incomeAccountId: {
                            _not: null
                        }
                    }
                }
            },
            fetchPolicy: 'no-cache'
        });
        if (!loading && !error) {
            setAllProducts(data);
            setProductsLoading(loading);
        }

    }

    useEffect(() => {
        if (products.length) calculate(rows, includeTaxes);
        handleInputChange(rows, 'products');
    }, [rows]);

    useEffect(() => {
        if (!showProductModal) {
            getAllProducts();
        }
    }, [showProductModal])


    const handleIncludeTaxes = (e) => {
        handleInputChange(e, 'includeTaxes');
        setIncludeTaxes(e);
        calculate(rows, e);
    }

    const handleCustomerChange = (e) => {
        let currencyId = e.value?.bill?.currencyId || enterprise?.currency?.id;
        let tmpCurrency = currencies.find(c => c.value?.id === currencyId);
        handleInputChange(tmpCurrency, 'currencyId');
        handleInputChange(e, 'customerId');
        handleInputChange(getRoundExchangeRate(getExchangeRate(tmpCurrency?.value?.code)), 'exchangeRate');
    }

    const handleCurrencyChance = (e) => {
        handleInputChange(e, 'currencyId');
        handleInputChange(getRoundExchangeRate(getExchangeRate(e?.value?.code)), 'exchangeRate');
    }

    const handleAddRow = () => {
        const newRow = {
            productId: null,
            name: null,
            description: null,
            price: "0",
            quantity: 1,
            taxes: []
        }
        setRows([...rows, newRow]);
    }

    const handleDeleteRow = (i, tableManager) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone = rowsClone.filter((f, index) => index !== i);
        setRows(rowsClone);
    }


    const handleProductChange = (e, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone[index] = {
            ...rowsClone[index],
            productId: e.value.id,
            name: e.value.name,
            pDescription: e.value.description,
            price: e.value.price,
            taxes: getRates(e.value.taxes, taxes || [], "add")
        };
        setRows(rowsClone);
    };


    const handleInputInvoiceChange = ({ target }, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];

        let field = target.name;
        let value = target.value;
        if (field === 'price') {
            let oldValue = rowsClone[index][field];
            if (!/^-?\d*[.]?\d{0,2}$/.test(value)) {
              value = oldValue;
            }
        }

        if (field === 'quantity') {
            let oldValue = rowsClone[index][field];
            if (!/^\d*$/.test(value)) {
              value = oldValue;
            }
        }

        rowsClone[index][field] = value;
        setRows(rowsClone);
    }

    const handleTaxChange = (e, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        console.log(e)
        rowsClone[index].taxes = e;
        setRows(rowsClone);
    }


    const tableProps = {
        showSearch: false,
        showRowsInformation: false,
        isPaginated: false,
        showColumnVisibilityManager: false
    }

    const fetchTaxes = async () => {
        setRatesLoading(true);
        let { data, errors, loading: load } = await refetchTaxes();
        if (!errors) {
            setTaxes([...data.taxRates]);
            setRatesLoading(false);
        }
    }

    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Producto/Servicio',
            cellRenderer: ({ tableManager, rowIndex, data }) => {
                let row = { value: data, label: data.name };
                return (
                    <Select
                        menuPortalTarget={document.body}
                        menuPosition="fixed" 
                        placeholder="Seleccion un procuto/servicio"
                        value={row}
                        isLoading={productsLoading}
                        options={formatSelect(allProducts?.products?.rows || [])}
                        onChange={(e) => handleProductChange(e, tableManager, rowIndex - 1)}
                        className="w-full m-2"
                        styles={sfnSelectTheme}
                        components={
                            {
                                MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo", callback: () => {
                                    setPositionProduct(rowIndex - 1)
                                    setShowProductModal(true)
                                } }),
                                NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay productos" })
                            }
                        }
                    />
                )
            }
        },
        {
            id: 2,
            field: 'pDescription',
            label: 'Descripción',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonTextarea
                        value={data?.pDescription}
                        name="pDescription"
                        type="text"
                        placeholder="Breve descripción"
                        onIonChange={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                        rows="1"
                        autoGrow={true}
                    />
                </IonItem>
            )

        },
        {
            id: 3,
            field: 'quantity',
            label: 'Cantidad',
            width: '125px',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonInput
                        value={data?.quantity}
                        name="quantity"
                        type="tel"
                        placeholder="Cantidad"
                        onKeyPress={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onChange={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onKeyUp={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onKeyDown={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                        required
                    />

                </IonItem>
            )

        },
        {
            id: 4,
            field: 'price',
            label: 'Precio',
            width: '125px',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonInput
                        value={data.price}
                        name="price"
                        type="text"
                        placeholder="Precio"
                        onKeyPress={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onChange={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onKeyUp={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)} 
                        onKeyDown={(e) => handleInputInvoiceChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                        required
                    />

                </IonItem>
            )
        },
        {
            id: 5,
            field: 'tax',
            label: 'Impuesto',
            cellRenderer: ({ tableManager, rowIndex, data }) => {
                return (
                    <Select
                    menuPortalTarget={document.body}
                    menuPosition="fixed" 
                    placeholder="Impuesto"
                    value={data?.taxes}
                    isMulti
                    isLoading={ratesLoading}
                    options={formatSelectTaxes([...taxes])}
                    onChange={(e) => handleTaxChange(e, tableManager, rowIndex - 1)}
                    className="w-full m-2 z-10"
                    styles={sfnSelectTheme}
                    components={
                        {
                            MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo", callback: () => {
                                setPositionTax(rowIndex - 1)
                                setShowTaxModal(true)
                            } }),
                            NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay impuestos" })
                        }
                    }
                />
                )
            }
        },
        {
            id: 6,
            label: 'Total',
            width: '100px',
            cellRenderer: ({ data }) => {
                return <IonNote className="m-2 text-center w-full">{fixedFloat(data.quantity * data.price)}</IonNote>
            }
        },
        {
            id: 7,
            width: '100px',
            cellRenderer: ({ tableManager, rowIndex }) => (
                <IonButton size="small" fill="clear" color="medium" onClick={() => (handleDeleteRow(rowIndex - 1, tableManager))}>
                    <IonIcon slot="icon-only" icon={trash} />
                </IonButton>
            )
        },
    ]

    const filterCustomer = ({ label, value }, inputValue) => (value?.bill?.nit?.includes(inputValue) || label.includes(inputValue))


    const afterCreateCustomer = (customer) => {
        handleCustomerChange({label: customer?.name, value: customer})
    }

    const afterCreateProduct = (index, product) => {
        let rowsClone = [...rows];
        rowsClone[index] = {
            ...rowsClone[index],
            productId: product.id,
            name: product.name,
            pDescription: product.description,
            price: product.price,
            taxes: getRates(product.taxes, taxes || [], "add")
        };
        setRows(rowsClone);
    }

    const afterCreateTax = (index, tax) => {
        let rowsClone = [...rows]
        rowsClone[index].taxes = [...rowsClone[index].taxes, {label: tax?.name, value: tax}];
        setRows(rowsClone);
    }
    return (
        <div id="sfn-invoice-generator" className="flex flex-col shadow-md rounded w-full">
            <div className="invoice-container md:p-8 p-4">
                <div className="invoice-header">

                    <div className="flex justify-between">
                        <div className="business-logo">
                            {enterprise?.data?.urlPicture &&
                                <div className="rounded shadow-md flex flex-col justify-center items-center mb-4 h-16 max-w-full">
                                    <img className="object-contain h-16 p-1.5 max-w-full" height="4rem" src={enterprise?.data?.urlPicture} />
                                </div>}
                            <p className="font-bold text-xl">{authState.enterprise.name}</p>
                            <p className="font-bold text-xl">Identificador tributario: {authState.enterprise.bill.nit}</p>
                            <p>Tel {authState.enterprise.bill.phone.areaCode}{authState.enterprise.bill.phone.number}</p>
                            <p>{authState.enterprise.bill.address}</p>
                            <p>{authState.enterprise.bill.state}</p>
                        </div>

                        <div className="invoice-identifier ml-4 ">
                            <h3 className="text-2xl font-bold mb-4" >FACTURA</h3>

                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonLabel position="stacked">No. de serie</IonLabel>
                                <IonInput name="extras.serie" type="text" onIonChange={handleInputChange} value={extras.serie} placeholder="No. de serie"> </IonInput>
                            </IonItem>
                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonLabel position="stacked">No. de FACTURA</IonLabel>
                                <IonInput name="number" type="text" onIonChange={handleInputChange} value={number} placeholder="Ingresa no. de factura"> </IonInput>
                            </IonItem>
                        </div>
                    </div>

                </div>

                <section className="flex flex-col sm:flex-row justify-between mt-8 items-end">
                    <div className="md:w-80 w-full">
                        <p className="text-sm mb-2 hover:text-secondary w-80" >Cliente<span className="text-base text-danger">*</span></p>
                        <Select
                            placeholder="Seleccione un cliente"
                            name="customerId"
                            isLoading={customersLoading}
                            value={customerId}
                            onChange={handleCustomerChange}
                            options={formatSelect(allCustomer?.customers?.rows || [])}
                            className="sfn-select z-10 mb-1"
                            styles={sfnSelectTheme}
                            filterOption={filterCustomer}
                            components={
                                {
                                    Option: (props) => cardCustomer({ ...props }),
                                    MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo cliente", callback: setShowCustomerModal }),
                                    NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay clientes" })
                                }
                            }
                        />
                    </div>

                    <InputDate label="Fecha de creación" icon={false} value={date} handleInput={handleInputChange} name="date" required={true} ></InputDate>
                    <InputDate label="Fecha límite de pago" icon={false} value={dueDate} handleInputChange={handleInputChange} name="dueDate" required={true} ></InputDate>

                    <IonItem className="sfn-input md:w-48 w-full" mode="md" lines="none">
                        <IonLabel position="stacked">P.O/S.O (Opcional)</IonLabel>
                        <IonInput
                            name="poso"
                            type="text"
                            placeholder="No. Orden"
                            onIonChange={handleInputChange}
                            value={poso}
                        >
                        </IonInput>
                    </IonItem>

                    <IonItem className="sfn-input md:w-48 w-full" mode="md" lines="none">
                        <IonLabel position="stacked">No. de autorización (Opcional)</IonLabel>
                        <IonInput
                            name="extras.authorization"
                            type="text"
                            placeholder="No. de autorización"
                            onIonChange={handleInputChange}
                            value={extras.authorization}
                        >
                        </IonInput>
                    </IonItem>

                </section>
                {(!productsLoading && !ratesLoading) &&
                    <GridTable
                        className={`z-0 mt-8 sfn-data-table sfn-data-table-overflow-none w-full ${!rows?.length ? 'sfn-data-table-xs': 'max-h-screen h-auto'}`}
                        columns={columns}
                        rows={rows}
                        {...tableProps}
                        components={{
                            NoResults: () => (
                                <Placeholder icon={pricetagsOutline} description='Aún no tienes productos agregados haz clic en el botón "Agregar producto/servicio"' />
                            )
                        }}
                    />}

                <IonButton className="sfn-button" fill="clear" expand="block" onClick={handleAddRow}>
                    <IonIcon icon={addCircleOutline} slot="start" />
                    AGREGAR UN PRODUCTO/SERVICIO
                </IonButton>

                <div className="summary p-4 mb-8" >
                    {/* <div className="flex justify-end my-2 mr-4">
                        <p>Subtotal</p>
                        <p className="totals text-right" >{currencyId?.value?.symbol || '¤'}. {subTotal}</p>
                    </div> */}
                    <div className="flex justify-end my-2 mr-4 items-center">
                        <p className="mr-14">¿Los productos ya incluyen impuestos?</p>
                        <IonToggle mode="ios" checked={includeTaxes} onIonChange={e => handleIncludeTaxes(e.detail.checked)} />
                    </div>
                    <div className="flex justify-end my-2 mr-4 item">
                        <p>Total de impuestos</p>
                        <p className="totals text-right">{currencyId?.value?.symbol || '¤'}. {totalTaxes}</p>
                    </div>

                    

                    <div className="flex flex-col md:flex-row justify-center md:justify-end items-center mt-8 rounded py-4 pr-4" style={{ background: '#3D4D5A', color: '#ffffff' }}>
                        <p className="font-bold md:mr-8 mr-0 md:mb-0 mb-4">TOTAL</p>
                        <Select name="currencyId" placeholder="Seleccione moneda" value={currencyId} onChange={handleCurrencyChance} options={currencies} className="sfn-select z-20 mb-1 w-60 md:mb-0 mb-4" styles={sfnSelectTheme} />
                        <p className="font-bold totals md:text-right text-center">{currencyId?.value?.symbol || '¤'}. {grandTotal}</p>
                    </div>
                    {(currencyId?.value?.code && currencyId?.value?.code !== enterprise?.currency?.code)
                        && <p className="text-right my-4">Conversion de moneda: {`${enterprise?.currency?.symbol}${roundValue(grandTotal / getExchangeRate(currencyId?.value?.code))} (${enterprise?.currency?.code})`} a {getRoundExchangeRate(getExchangeRate(currencyId?.value?.code))}</p>}
                </div>

            </div>
            <div className="invoice-footer p-8">
                <p className="text-sm">Pie de factura</p>
                {enterprise?.data?.footer && <p>{enterprise?.data?.footer}</p>}
                {!enterprise.data.footer && <p>-</p>}
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">Nota (Opcional)</IonLabel>
                    <IonTextarea
                        id="note"
                        name="description"
                        type="text"
                        placeholder="Agrega una nota extra en esta factura"
                        onIonChange={handleInputChange}
                        value={description}
                        className="md"
                        rows="1"
                        autoGrow="true"
                    />
                </IonItem>
            </div>
            {showTaxModal && <TaxModal position={positionTax} afterCreate={afterCreateTax}  showModal={showTaxModal} setShowModal={setShowTaxModal} eventsClose={fetchTaxes} />}
            {showCustomerModal && <CustomerModal afterCreate={afterCreateCustomer} showModal={showCustomerModal} setShowModal={setShowCustomerModal}></CustomerModal>}
            {showProductModal && <ProductModal position={positionProduct} afterCreate={afterCreateProduct} showModal={showProductModal} setShowModal={setShowProductModal}></ProductModal>}

        </div>

    )
}

export default React.memo(InvoiceGenerator);