import gql from 'graphql-tag'

export const getAllBankAccountsQuery = gql`
	query bankAccounts($options: Options, $page: Int) {
		bankAccounts(options: $options, page: $page) {
			rows {
				id
				type
				number
				alias
				bank
			}
		}
	}
`

export const getOneBankAccountQuery = gql`
	query bankAccount($input: ID!) {
		bankAccount(id: $input) {
			id
			type
			number
			alias
			bank
			currency {
				id
				name
			}
		}
	}
`
