import './Record.scss';
import { IonIcon, useIonAlert, IonButton } from '@ionic/react';
import { fileTrayOutline, calendarClearOutline, filterOutline } from 'ionicons/icons';
import { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import ButtonPopover from '../../../../components/button-popover/ButtonPopover';
import OptionItem from '../../../../components/option-item/OptionItem';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { texts } from '../../../../theme/dataTabelTexts';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { useForm } from '../../../../hooks/useForm';
import SfnButton from '../../../../components/button/Button';
import { getAllPayrollQuery } from '../../../../graphql/payroll/queries';
import {months } from '../../../../helpers/catalogs'
import {formatMoney, generateYears, isEmpty } from '../../../../helpers/utils'
import SearchTable from '../../../../components/search-table/SearchTable';
import { dateTimeFormat, parseDate} from '../../../../helpers/date';
const initialState = {
    year: '',
    month: ''
}

const Record = ({ enterprise }) => {
    const {formValues, handleInputChange, reset} = useForm(initialState);
    const { year, month } = formValues;
    const [options, setOptions] = useState({});
    const { data, loading, error } = useQuery(getAllPayrollQuery, { variables: { options }, fetchPolicy: 'cache-and-network' });
    const history = useHistory();
    const [present] = useIonAlert();

    const columns = [
        {
            id: 1,
            field: 'month',
            label: 'Número nómina',
            width: '150px',
            getValue: ({value}) => isEmpty(value)
        },
        {
            id: 2,
            field: 'rangeDate',
            label: 'Período',
            cellRenderer: ({ data }) => {
                return (
                    <div className="flex flex-row self-auto items-center p-4">
                        <p>{parseDate(data?.startDate).replace(/\//g, '-')} - {parseDate(data?.endDate).replace(/\//g, '-')}</p>
                    </div>
                )
            }
        },
        {
            id: 3,
            field: 'employees',
            label: 'Colaboradores',
        },
        {
            id: 4,
            field: 'total',
            label: 'Monto total',
            headerCellRenderer: () => (<div className="ml-auto mr-2">Monto total</div>),
            cellRenderer: ({ data }) => {
                return (
                    <div className="text-right w-full m-4">
                        <p>{formatMoney(enterprise, data.total)}</p>
                    </div>)
            },
        }
    ];
    const tableProps = (show) => ({
        showSearch: false,
        showRowsInformation: true,
        isPaginated: show,
        showColumnVisibilityManager: false
    })
    const onRowClick = ({event, data}) => {
        event.stopPropagation();
        goToDetailRecordPayrollPage(data.id);
    }

    const goToDetailRecordPayrollPage = (id) => {
        history.push(`/payroll/${id}`);
    }


    const handleSendvoucher = (id) => {
        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: 'Enviar boletas de pago',
            subHeader: `Esta acción enviará la boleta de pago de nómina no. {{4}} del período {{dd/mm/yyyy}} al {{dd/mm/yyyy}} al correo electrónico correspondiente de cada colaborador.`,
            message: '¿Esta seguro de enviar boletas de pago?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Enviar boletas de pago',
                    cssClass: 'primary',
                    handler: () => {
                        console.log(id);
                    }
                }
            ],
            onDidDismiss: (e) => { },
        })
    }

    const filters = (e, name) => {
        handleInputChange(e, name);
        setOptions({...options, where: { ...options?.where, [name]: e?.value }})
    }

    return (
        <>
            <div className="flex flex-col justify-end items-center sm:flex-row md:flex-row md:justify-between md:items-end my-8">
                <div className="flex flex-col md:flex-row items-center">
                    <div>
                        <p className="text-sm mb-2 hover:text-secondary w-80">Año</p>
                        <div className="flex items-center dropdown-month">
                            <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
                            <Select isClearable={true} placeholder="Seleccione el año" value={year} options={generateYears(enterprise?.createdAt)} onChange={e => (filters(e, 'year'))} className="sfn-select w-60 z-20" styles={sfnSelectTheme} />
                        </div>
                    </div>
                    <div className="mx-8 my-4 md:my-0">
                        <p className="text-sm mb-2 hover:text-secondary w-80">Mes</p>
                        <div className="flex items-center dropdown-month">
                            <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
                            <Select isClearable={true} placeholder="Seleccione el mes" value={month} options={months} onChange={e => (filters(e, 'month'))} className="sfn-select w-60 z-10" styles={sfnSelectTheme} />
                        </div>
                    </div>
                </div>
                {/* <div className="flex">
                    <SfnButton label="Enviar boletas" onClick={handleSendvoucher} />
                </div> */}
            </div>
            <GridTable
                className="max-h-80 sfn-data-table"
                columns={columns}
                isLoading={loading}
                rows={data?.payrolls?.rows}
                texts={texts}
                onRowClick={onRowClick}
                components={{
                    NoResults: () => (
                        <Placeholder icon={fileTrayOutline} description='No tienes historial de nómina' />
                    ),
                    Search: SearchTable
                }}
                {...tableProps(data?.payrolls?.rows ? true : false)}
                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            />
        </>
    )
}

export default Record;