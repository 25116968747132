import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { IonIcon, IonButton, useIonAlert, useIonToast } from '@ionic/react';
import { getAllBankAccountsQuery } from '../../../graphql/bankAccount/queries';
import GridTable from '@nadavshaar/react-grid-table';
import OptionItem from '../../../components/option-item/OptionItem';
import ButtonPopover from '../../../components/button-popover/ButtonPopover';
import BankAccountModal from '../../../components/modals/add-bank-account-modal/BankAccountModal';

import { addCircleOutline, trash, create, idCardOutline } from 'ionicons/icons';
import { useBankAccountsService } from '../../../graphql/bankAccount/service';
import { texts } from '../../../theme/dataTabelTexts';
import Placeholder from '../../../components/placeholder/Placeholder';
import { bankAccountTypesName } from '../../../helpers/catalogs'
import SearchTable from '../../../components/search-table/SearchTable';
import { AuthStore } from '../../../store/auth';
const AccountingSettingsPage = ({authState}) => {
	const user = AuthStore.useState(s => s.user);
	const [present] = useIonToast();
	const [presentM] = useIonAlert();

	const ItemsPopover = ({ onHide, data }) => (
		<>
			<OptionItem key='1' onHide={onHide} icon={create} event={() => (handleEdit(data))} caption="Editar" />
			<OptionItem key='2' onHide={onHide} icon={trash} event={() => (handleDelete(data.id))} caption="Eliminar" className="danger" />
		</>
	)
	const Options = ({ data }) => (!['READ'].includes(user?.role?.permissions['configuration']) && <ButtonPopover options={ItemsPopover} data={data} />)
	const [bankId, setBankId] = useState(null)

	const { deleteBankAccount, dError, dLoading } = useBankAccountsService();
	const handleDelete = (id) => {
		presentM({
			cssClass: "sfn-alert-dialog",
			mode: "md",
			header: "Eliminar banco",
			subHeader: "Este banco se eliminará permanentemente",
			message: "¿Esta seguro de eliminar este banco?",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
					cssClass: "secondary",
					handler: (blah) => {
						console.log("Confirm Cancel: blah");
					},
				},
				{
					text: "Eliminar banco",
					cssClass: "delete",
					handler: async () => {
						await deleteBankAccount({ variables: { input: id } });
						if (!dError) {
							present({
								message: `El banco se ha eliminado exitosamente! 👍`,
								color: "success",
								mode: "ios",
								duration: 4000,
							});
						} else {
							present({
								message:
									"Oops! ha ocurrido un error, intentalo nuevamente 🤔",
								color: "danger",
								mode: "ios",
								duration: 4000
							});
						}
					},
				},
			],
			onDidDismiss: (e) => console.log("did dismiss"),
		});
	};

	const handleEdit = (data) => {
		setBankId(data.id)
		setShowModal(true)
	}
	const showModalCreate = () => {
		setBankId(null)
		setShowModal(true)
	}

	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const columns = [
		{
			id: 1,
			field: 'type',
			label: 'Tipo de cuenta',
			widht: '25%',
			getValue: ({ value }) => bankAccountTypesName[value]
		},
		{
			id: 2,
			field: 'number',
			label: 'No. de cuenta bancaria',
			widht: '25%',
		},
		{
			id: 3,
			field: 'bank',
			label: 'Nombre de banco',
			widht: '25%',
		},
		{
			id: 4,
			field: 'alias',
			label: 'Alias',
			widht: '25%',
		},
		{
			id: 'option',
			label: '',
			width: "max-content",
			pinned: true,
			sortable: true,
			resizable: false,
			cellRenderer: Options
		}
	];

	const tableProps = (show) => ({
		showSearch: show,
		showRowsInformation: show,
		isPaginated: show,
		showColumnVisibilityManager: show
	})

	const { data, loading, error } = useQuery(getAllBankAccountsQuery);
	return (
		<div className="p-4">
			<div className="flex flex-row flex-wrap justify-between items-start mb-8" >
				<h2 className="text-xl text-gray-blue font-bold mb-4">
					Cuentas bancarias
				</h2>
				<IonButton disabled={['READ'].includes(user?.role?.permissions['configuration'])} className="sfn-button" shape="round" expand="block" onClick={showModalCreate}>
					<IonIcon slot="start" icon={addCircleOutline} />
					Agregar cuenta
				</IonButton>
			</div>
			<div className={`flex ${data?.bankAccounts?.rows.length ? 'h-screen' : 'h-96'}`}>
				<GridTable
					className="sfn-data-table row-cursor-pointer "
					columns={columns}
					rows={data?.bankAccounts?.rows}
					texts={texts}
					components={{
						NoResults: () => (
							<Placeholder icon={idCardOutline} description='Aún no tienes cuentas de bancarias agregadas, haz clic en el botón "Agregar cuenta"' />
						),
						Search: SearchTable
					}}
					{...tableProps(data?.bankAccounts?.rows.length ? true : false)} />
			</div>
			<BankAccountModal showModal={showModal} setShowModal={setShowModal} bankId={bankId} setBankId={setBankId} />
		</div>
	)
}


export default AccountingSettingsPage;