import gql from 'graphql-tag';

export const getAllInvoicesQuery = gql`
  query invoices($options: Options, $page: Int, $status: EnumStatusInvoice) {
    invoices(options: $options, page: $page, status: $status) {
      rows {
        id
        date
        dueDate
        number
        remaining
        convertedRemaining
        total
        convertedTotal
        status
        isFEL
        createCreditNote
        currency {
          symbol
          code
        }
        customer {
          name
          bill {
            nit
          }
        }
        extras {
          serie
          authorization
        }
      }
    }
  }
`;

export const getOneInvoiceQuery = gql`
  query invoice($input: ID!) {
    invoice(id: $input) {
      id
      number
      exchangeRate
      description
      date
      dueDate
      poso
      includeTaxes
      customerId: customer {
        value: id
        label: name
      }
      currencyId: currency {
        id
      }
      extras {
        serie
        authorization
      }
      products {
        id
        description
        quantity
        price
        product {
          name
          id
        }
        taxes {
          id
          rate
          tax {
            id
          }
        }
      }
    }
  }
`;

export const getOneInvoiceDetailQuery = gql`
  query invoice($input: ID!) {
    invoice(id: $input) {
      id
      remaining
      status
      currency {
        id
        code
        symbol
        name
        rate
      }
    }
  }
`;

export const getOneInvoicePaymentQuery = gql`
  query invoice($input: ID!) {
    invoice(id: $input) {
      id
      number
      exchangeRate
      description
      date
      dueDate
      poso
      status
      total
      remaining
      creditNotesAmount
      createCreditNote
      residue
      isFEL
      notes {
        id
        date
        products
        amount
        status
      }
      extras {
        serie
        authorization
      }
      customer {
        id
        name
        bill {
          nit
        }
      }
      currency {
        name
        code
        symbol
      }
      payments {
        id
        date
        amount
        convertedAmount
        exchangeRate
        description
        method
        account {
          id
          name
          currency {
            id
            code
            name
            symbol
          }
        }
      }
    }
  }
`;
export const invoiceIndicatorsQuery = gql`
  query invoiceIndicators {
    invoiceIndicators {
      overdue {
        label
        value
      }
      unpaid {
        label
        value
      }
      paid {
        label
        value
      }
    }
  }
`;

export const getLastInvoces = gql`
  query invoices($options: Options, $page: Int, $status: EnumStatusInvoice) {
    invoices(options: $options, page: $page, status: $status) {
      rows {
        id
        date
        dueDate
        number
        remaining
        total
        convertedTotal
        status
        currency {
          symbol
          code
        }
        customer {
          name
        }
      }
    }
  }
`;

export const overdueInvoices = gql`
  query overdueInvoices($options: Options, $status: EnumStatusInvoice) {
    invoices(options: $options, status: $status) {
      rows {
        id
        date
      }
    }
  }
`;

export const accountsPayable = gql`
  query accountsPayable {
    accountsPayable {
      total
      items {
        label
        amount
        range
      }
    }
  }
`;

export const accountsReceivable = gql`
  query accountsReceivable {
    accountsReceivable {
      total
      items {
        label
        amount
        range
      }
    }
  }
`;

export const downloadInvoice = gql`
  query downloadInvoice($input: ID!) {
    downloadInvoice(id: $input)
  }
`;
