// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createCustomerMutation,
	deleteCustomerMutation,
	updateCustomerMutation,
} from './mutations'
import { getAllCustomersQuery, getOneCustomerQuery } from './queries'

export const useCustomersService = () => {
	const [createCustomer, { loading: cLoading, error: cError }] = useMutation(
		createCustomerMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createCustomer } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllCustomersQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllCustomersQuery,
					data: {
						customers: {
							rows: [createCustomer, ...data.customers.rows],
						},
					},
				})
			},
		},
	)

	const [updateCustomer, { loading: uLoading, error: uError }] = useMutation(
		updateCustomerMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateCustomer } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllCustomersQuery })
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllCustomersQuery,
					data: {
						customers: {
							rows: data?.customers ? data.customers.rows.map((customer) =>
								customer.id === updateCustomer.id
									? updateCustomer
									: customer,
							): [],
						},
					},
				})
			},
		},
	)

	const [deleteCustomer, {loading: dLoading, error: dError}] = useMutation(
		deleteCustomerMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteCustomer } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllCustomersQuery })
				if(deleteCustomer){
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllCustomersQuery,
					data: {
						customers: {
							rows: data.customers.rows.filter(
								(customer) => customer.id !== deleteCustomer.id,
							),
						},
					},
				})
			}

			},
			onError: (err) => {
				console.log(err);
			}
		},
		)

	return {
		createCustomer,
		cLoading,
		cError,

		updateCustomer,
		uLoading,
		uError,

		deleteCustomer,
		dLoading,
		dError
		
	}
}
