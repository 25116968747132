import gql from 'graphql-tag';

export const createSubscriptionMutation = gql`
  mutation createSubscription($input: CreateSubscription!) {
    createSubscription(input: $input) {
      plan {
        id
        name
      }
      billingData {
        firstName
        lastName
        phone {
          type
          areaCode
          number
        }
        email
        nit
        address
        countryISO2
        city
        state
        postalCode
      }
      card {
        enterprise
        number
      }
    }
  }
`;

export const updateBillingDataMutation = gql`
  mutation updateBillingData($input: CreateBillingData!) {
    updateBillingData(input: $input) {
      plan {
        id
        name
      }
      billingData {
        firstName
        lastName
        phone {
          type
          areaCode
          number
        }
        email
        nit
        address
        countryISO2
        city
        state
        postalCode
      }
      card {
        enterprise
        number
      }
    }
  }
`;

export const updateCreditCardMutation = gql`
  mutation updateCreditCard($input: CreateCard!) {
    updateCreditCard(input: $input) {
      plan {
        id
        name
      }
      billingData {
        firstName
        lastName
        phone {
          type
          areaCode
          number
        }
        email
        nit
        address
        countryISO2
        city
        state
        postalCode
      }
      card {
        number
      }
    }
  }
`;

export const cancelSubscriptionMutation = gql`
  mutation cancelSubscription($input: CancelSubscription!) {
    cancelSubscription(input: $input) {
      plan {
        id
        name
      }
      billingData {
        firstName
        lastName
        phone {
          type
          areaCode
          number
        }
        email
        nit
        address
        countryISO2
        city
        state
        postalCode
      }
      card {
        enterprise
        number
      }
    }
  }
`;
