import { IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline, chevronUpOutline } from 'ionicons/icons';
import { useState } from 'react';
import './Accordion.scss';


const Accordion = ({ header, title, children, hideButton = false, notCollapse = false}) => {
  const [open, setOpen] = useState(notCollapse);

  return (
    <div className='accordion static'>
      <div
        className={`accordion-header w-full ${!open ? 'open divider' : ''}`}
      >
        {/* <IonIcon color="secondary" className="text-xl mr-4 cursor-pointer" icon={!open ? chevronForwardOutline : chevronDownOutline}></IonIcon> */}
        {header ? header : <h3 className="text-base font-bold cursor-pointer">{title}</h3>}
        <div className="flex justify-end items-center">
        {!hideButton && <a className="text-primary hover:cursor-pointer" onClick={() => setOpen(!open)}>Mostrar {!open ? 'más': 'menos'}</a>}
        </div>
      </div>
      <div className={`accordion-item static ${!open ? 'collapsed animate__animated animate__fadeOut' : 'animate__animated animate__fadeIn'}`}>
        <div className='accordion-content'>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
