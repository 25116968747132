// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createEnterpriseMutation,
	deleteEnterpriseMutation,
	updateEnterpriseMutation,
	changeEnterpriseMutation,
	changeEnterpriseDeleteMutation
} from './mutations'
import { getAllEnterprisesQuery, getOneEnterpriseQuery } from './queries'

export const useEnterprisesService = (notUpdate = false) => {
	const [
		createEnterprise,
		{ loading: cLoading, error: cError },
	] = useMutation(createEnterpriseMutation, {
		errorPolicy: 'all',
		update(cache, { data: { createEnterprise } }) {
			// obtener el objeto de cache
			const data = cache.readQuery({ query: getAllEnterprisesQuery })

			// reescribir ese objeto
			cache.writeQuery({
				query: getAllEnterprisesQuery,
				data: {
					enterprises: [createEnterprise, ...data.enterprises],
				},
			})
		},
	})

	const [
		updateEnterprise,
		{ loading: uLoading, error: uError },
	] = useMutation(updateEnterpriseMutation, {
		errorPolicy: 'all',
		update(cache, { data: { updateEnterprise } }) {
			if(notUpdate)  return
			// obtener el objeto de cache
			let data = cache.readQuery({ query: getAllEnterprisesQuery}) ?? []
			// reescribir ese objeto
			data = JSON.parse(JSON.stringify(data))
			const index = data.enterprises.findIndex(e => e.id === updateEnterprise.id)

			if(index < 0) {
				data = [...data?.enterprises, updateEnterprise]
			}else {
				data.enterprises[index] = updateEnterprise
			}

			cache.writeQuery({
				query: getAllEnterprisesQuery,
				data,
			})
		},
	})

	const [
		deleteEnterprise,
		{ loading: dLoading, error: dError },
	] = useMutation(deleteEnterpriseMutation, {
		errorPolicy: 'all',
		update(cache, { data: { deleteEnterprise } }) {
			// obtener el objeto de cache
			const data = cache.readQuery({ query: getAllEnterprisesQuery })

			// reescribir ese objeto
			cache.writeQuery({
				query: getAllEnterprisesQuery,
				data: {
					enterprises: data.enterprises.filter(
							(enterprise) =>
								enterprise.id !== deleteEnterprise.id,
						),
					},
			})
		},
	})

	const [
		changeEnterprise,
		{ loading: chLoading, error: chError },
	] = useMutation(changeEnterpriseMutation)


	const [
		changeEnterpriseDelete,
		{ loading: chdLoading, error: chdError },
	] = useMutation(changeEnterpriseDeleteMutation)


	return {
		createEnterprise,
		cLoading,
		cError,

		updateEnterprise,
		uLoading,
		uError,

		deleteEnterprise,
		dLoading,
		dError,

		changeEnterprise,
		chLoading,
		chError,

		changeEnterpriseDelete,
		chdLoading,
		chdError
	}
}
