import gql from 'graphql-tag';

export const getPayrollConfigQuery = gql`
  query payrollConfig($id: ID!) {
    enterprise(id: $id) {
      payroll {
        frequency
        payday
        firstFortnight
        secondFortnight
        configured
        editable
      }
    }
  }
`;

export const getCalculatePayrollQuery = gql`
  query {
    calculatePayroll {
      total
      startDate
      endDate
      month
      year
      employees {
        id
        name
        salary
        startDate
        amountBenefits
        amountDiscounts
        discounts {
          id
          description
          amount
        }
        benefits {
          id
          description
          amount
        }
      }
    }
  }
`;

export const getOnePayrollQuery = gql`
  query ($id: ID!) {
    payroll(id: $id) {
      id  
      total
      startDate
      endDate
      month
      year
      employees
      records
    }
  }
`;

export const getAllPayrollQuery = gql`
  query ($options: Options) {
    payrolls(options: $options) {
      rows {
        id
        total
        startDate
        endDate
        month
        year
        employees
      }
    }
  }
`;

export const getPayrollIndicator = gql`
  query {
    payrollIndicators {
      salaries {
        value
        label
      }
      benefits {
        value
        label
      }
      discounts {
        value
        label
      }
    }
  }
`;
