import { IonButton, IonContent, IonIcon, IonModal, useIonToast } from '@ionic/react'
import { useEffect, useRef, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import { generatePDF } from '../../../helpers/utils';
import { closeOutline } from 'ionicons/icons';
import InvoiceGenerator from '../../invoice-generator/InvoiceGenerator';
import Wrapper from '../../wrapper/Wrapper';
import { getDate } from '../../../helpers/date';
import { useInvoicesService } from '../../../graphql/invoice/service';
import { useQuery } from '@apollo/client';
import { getOneInvoiceQuery } from '../../../graphql/invoice/queries';
import { currencies } from '../../../helpers/catalogs';
import { getAllTaxRates } from '../../../graphql/tax/queries';
import SfnButton from '../../button/Button';
import './InvoiceModal.scss'
import { AuthStore } from '../../../store/auth';

const initialState = {
    date: getDate(new Date(), false),
    dueDate: getDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 28), false),
    number: "",
    description: "",
    poso: "",
    customerId: "",
    currencyId: null,
    exchangeRate: 0,
    products: [],
    includeTaxes: false,
    extras: {
        serie: '',
        authorization: '',
    }
}

const transformData = (enterprise) => {
    if (!enterprise) return enterprise
    let data = JSON.parse(JSON.stringify(initialState));
    data.currencyId = currencies.find(c => c?.value?.id === enterprise?.currency?.id)
    return data
}

const InvoiceModal = ({ showModal, setShowModal, invoiceId = null, setInvoiceId, hookClose = () => { } }) => {
    const user = AuthStore.useState(s => s.user);
    const { formValues, handleInputChange, reset, resetProps } = useForm(transformData(user?.enterprise));
    const { createInvoice, cLoading, cError, updateInvoice, uLoading, uError } = useInvoicesService();

    const { loading, data } = useQuery(getOneInvoiceQuery, {
        variables: { input: invoiceId },
        skip: !invoiceId,
        fetchPolicy: "no-cache"
    });
    const [taxesLoading, setTaxesLoading] = useState(true);

    const { data: allRates, loading: ratesLoading, error: ratesError, refetch: refetchTaxes } = useQuery(getAllTaxRates,
        {
            variables: { date: formValues.date },
            fetchPolicy: 'no-cache',
            async onCompleted(data) {
                updateRates();
                setTaxesLoading(false);
                setTaxes(data?.taxRates);
            }
        });
    const [rows, setRows] = useState([]);
    const [present] = useIonToast();
    const isMounted = useRef(true);
    const [taxes, setTaxes] = useState([]);

    useEffect(() => {
        if (invoiceId && !loading && data) {
            let tmpProducts = [];
            data.invoice.products.forEach(p => {
                let tmpProduct = {
                    ...p,
                    name: p.product.name,
                    pDescription: p.description,
                    productId: p.product.id,
                    taxes: p.taxes
                }
                delete tmpProduct.product;
                tmpProducts.push(tmpProduct);
            })
            console.log('UPDATE', tmpProducts);
            data.invoice.products = tmpProducts;
            data.invoice.currencyId = currencies.find(c => c.value.id === data.invoice.currencyId.id)
            setRows(data.invoice.products);
            reset(data.invoice);
            updateRates(tmpProducts)
            return () => {
                isMounted.current = false;
            }
        }
    }, [data]);

    const handleCreateInvoice = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (invoiceId) {
                await updateInvoice({ variables: { invoiceId, input: formValues } })
            } else {
                await createInvoice({ variables: { input: formValues } });
            }

            if (!uLoading && !cLoading) {
                if (cError || uError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 😑', color: "danger", mode: "md", duration: 4000 });
                    return
                }
                present({ message: `La factura se ${invoiceId ? 'actualizó' : 'creó'} exitosamente! 🎉`, color: "success", mode: "md", duration: 4000 })
                onClose();
                console.log(formValues);
            }
        }
    }

    const validateForm = () => {

        if (!formValues.currencyId) {
            present({ message: 'Debes agregar una moneda 💲', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if (!formValues.customerId) {
            present({ message: 'Debes agregar un cliente 🤷‍♂️', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if (!formValues.products.length) {
            present({ message: 'Debes agregar productos 🛒', color: "danger", mode: "md", duration: 4000 });
            return
        }

        if (!formValues.products.every(p => p.name !== '' && (p.quantity !== '' && p.quantity > 0) && (p.price !== '' || p.price !== null))) {
            present({ message: 'Llena los campos que faltan en tus productos 🛒', color: "danger", mode: "md", duration: 4000 });
            return
        }

        // if (!formValues.number) {
        //     present({ message: 'Debes agregar un número de factura 📃', color: "danger", mode: "md", duration: 4000 });
        //     return
        // }
        formValues.currencyId = formValues.currencyId.value.id;
        formValues.customerId = formValues.customerId.value.id;
        formValues.exchangeRate = parseFloat(formValues.exchangeRate);
        let tmpProducts = [];
        formValues.products.forEach(p => {
            let prod = {
                productId: p.productId,
                price: parseFloat(p.price),
                quantity: parseFloat(p.quantity),
                description: p.pDescription,
                taxes: p.taxes ? p.taxes.map(t => t.value.id) : []
            }
            tmpProducts.push(prod);
        })
        formValues.products = tmpProducts;
        return true;
    }

    const onClose = async () => {
        hookClose();
        resetProps({ ...initialState });
        setShowModal(false);
        setInvoiceId(null);
    }

    const updateRates = async (products) => {
        products = formValues.products.length ? formValues.products : products
        if (products) {
            let rowsClone = [...products];
            if (!ratesLoading) {
                let tmp = rowsClone.map(r => {
                    r.taxes = getUpdateRates(r.taxes, allRates?.taxRates);
                    return r
                })
                setRows(tmp);
            }
        }
    }

    const getUpdateRates = (taxes, taxRates) => {
        if (!taxes) return [];
        let taxIds = taxes.map(t => {
            return t.tax?.id || t.value.tax.id
        })
        return taxRates.map(i => {
            return {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
        }).filter(i => taxIds.includes(i.value.tax.id))
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper max="xl">
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-3xl font-bold">Nueva factura</h3>
                    <p className="text-center text-base">Completa el siguiente formulario para crear una factura a un cliente</p>
                    <InvoiceGenerator
                        rows={rows}
                        setRows={setRows}
                        formValues={formValues}
                        handleInputChange={handleInputChange}
                        reset={reset}
                        taxes={taxes}
                        setTaxes={setTaxes}
                        refetchTaxes={refetchTaxes}
                        ratesLoading={taxesLoading}
                        setRatesLoading={setTaxesLoading}
                        className="max-w-screen-md" />
                    <div className="flex flex-col md:flex-row justify-end items-center my-8">
                        <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={() => setShowModal(false)}>
                            Cancelar
                        </IonButton>
                        <IonButton className="sfn-button" shape="round" fill="outline" onClick={() => { generatePDF('sfn-invoice-generator') }}>
                            Previsualización
                        </IonButton>
                        <SfnButton label={invoiceId ? 'Guardar cambios' : 'Guardar factura'} btnClass={'ml-4'} loading={uLoading || cLoading} onClick={handleCreateInvoice} />
                    </div>
                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default InvoiceModal;