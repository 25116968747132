import {
  pieChartOutline,
  pieChart,
  cartOutline,
  cart,
  podiumOutline,
  podium,
  clipboardOutline,
  clipboard,
  briefcaseOutline,
  briefcase,
  peopleOutline,
  people,
  settingsOutline,
  settings,
  cashOutline,
  cash,
} from 'ionicons/icons';

export const InitialAppPages = [
  {
    key: 'dashboard',
    url: '/dashboard',
    title: 'Dashboard',
    icon: pieChartOutline,
    iconSolid: pieChart,
  },
  {
    key: 'sales',
    title: 'Ventas',
    icon: podiumOutline,
    iconSolid: podium,
    open: false,
    children: [
      {
        url: '/invoices',
        title: 'Facturación',
        icon: '',
      },
      {
        url: '/customers',
        title: 'Clientes',
        icon: '',
      },
      {
        url: '/selling',
        title: 'Productos o servicios',
        icon: '',
      },
    ],
  },
  {
    key: 'expenses',
    title: 'Costos y gastos',
    icon: cartOutline,
    iconSolid: cart,
    open: false,
    children: [
      {
        url: '/expenses',
        title: 'Facturación',
        icon: '',
      },
      {
        url: '/receipts',
        title: 'Recibos',
        icon: '',
      },
      {
        url: '/vendors',
        title: 'Mis proveedores',
        icon: '',
      },
      {
        url: '/buyings',
        title: 'Productos o servicios',
        icon: '',
      },
    ],
  },
  {
    key: 'payroll',
    url: '/payrolls',
    title: 'Nómina',
    icon: cashOutline,
    iconSolid: cash,
  },
  {
    key: 'accounting',
    title: 'Contabilidad',
    icon: briefcaseOutline,
    iconSolid: briefcase,
    open: false,
    children: [
      {
        url: '/transactions',
        title: 'Transacciones',
        icon: '',
      },
      {
        url: '/accounts',
        title: 'Cuentas',
        icon: '',
      },
    ],
  },
  {
    key: 'reports',
    url: '/reports',
    title: 'Reportes',
    icon: clipboardOutline,
    iconSolid: clipboard,
  },
  {
    key: 'users',
    url: '/user-management',
    title: 'Usuarios',
    icon: peopleOutline,
    iconSolid: people,
  },
  {
    key: 'configuration',
    url: '/settings',
    title: 'Configuración',
    icon: settingsOutline,
    iconSolid: settings,
  },
];
