import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, useIonToast } from '@ionic/react';
import { closeCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import Select from 'react-select';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { currencies, countries, bankAccountTypes, phoneTypes, accountTypes, accountTypesName } from '../../../helpers/catalogs';
import { validate, validateKey } from '../../../helpers/validations';
import { validationMap } from './validation';
import { formatSelectData, defaultValue, formatAccountTypes } from '../../../helpers/utils';
import { useAccountsService } from '../../../graphql/account/service';
import SfnButton from '../../button/Button';
import { getOneAccountQuery } from '../../../graphql/account/queries';
import { useQuery } from '@apollo/client';
import { useAuth } from '../../../context/auth/AuthState';

const initialState = {
    name: '',
    accountTypeId: '',
    customId: '',
    currencyId: '',
    description: '',
}

const AccountModal = ({ showModal, setShowModal, accountId, setAccountId, total, setTotal, typeAccount = null }) => {

    const {formValues, handleInputChange, reset} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { name, accountTypeId, code, description, currencyId, customId } = formValues;
    const { createAccount, cLoading, cError, updateAccount, uLoading, uError } = useAccountsService();
    const [valid, setValid] = useState({});
    const [present] = useIonToast();
    const { authState } = useAuth();


    let { data, loading, error } = useQuery(getOneAccountQuery, {
        variables: { input: accountId },
        skip: !accountId,
        fetchPolicy: 'no-cache'
    });

    const accountsMatch = ['8decaeea-26f2-4ef5-b9ec-01d9e63462c4', 
    '5230b031-6fe8-45b2-827f-5ab4cbf7fb90',
    '69cc6bde-7b29-4e70-ad70-cfb572ad32c6',
    '846cfe95-a341-42a5-8eaf-939678be3ee4',
    '1fe6fc44-b934-4992-91b1-61171c7ad7e0',
    '441ac4b4-3853-4849-919c-e52d9a1535e4',
    '9a933044-cc18-4086-9699-19a16cd17378',
    '676b252b-78f9-47ce-8207-8d94e05c65b4',
    '6ea31e9b-adc8-4b50-9617-1076e14025a6',
    'c21b3b42-ee31-4c13-b790-829d02b72daf',
    '3778c78f-7cf2-4389-9c6e-ccc98c36ab5a',
    '9cdd90b1-32bd-4b9c-8746-19f907ef5439',
    '92ecaa2d-5eb9-488e-a748-7a5b2412cb18',
    '02116fff-24ec-41d9-bcac-cb0516c5316c',]

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = { ...formValues };
        console.log(input)
        let valid = validate(input, validationMap);
        setValid(valid);
        console.log({valid})

        if (!valid.valid) {
            present({ message: 'Debes llenar todos los campos obligatorios', color: "warning", mode: "md", duration: 4000 });
            return;
        }
        input.accountTypeId = formValues?.accountTypeId?.value.id ? formValues?.accountTypeId?.value.id || null : formValues?.accountTypeId?.value || null
        input.currencyId = formValues?.currencyId?.value?.id || '';
        
        if (!accountId) {
            await createAccount({ variables: { input } })
        } else {
            await updateAccount({ variables: { input } })
        }

        if (!cLoading && !uLoading) {
            if (cError && uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            }
            present({ message: `La cuenta se ${accountId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
        }
        present({ message: `La cuenta se ${accountId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
        onClose();
        e.target.reset();
    }

    const onClose = async () => {
        setAccountId(null);
        reset({ ...initialState });
        setShowModal(false);
    }

    useEffect(() => {
        if (accountId && !loading) {
            let { account } = data;
            account = { ...account };
            account.accountTypeId = defaultValue({ value: 'id', label: 'name' }, accountTypes, account.accountType.id);
            account.currencyId = currencies.find(c => c.value.id === account.currency.id);
            delete account.accountType
            delete account.currency
            reset({ ...account });
        } else {
            if (typeAccount != null) {
                handleInputChange(defaultValue({ value: 'id', label: 'name' }, accountTypes, typeAccount), 'accountTypeId')
            }
            handleInputChange(currencies.find(c => c.value.id === authState.enterprise.currency.id), 'currencyId')
        }
    }, [data]);

    useEffect(() => {
        const id = accountTypeId?.value?.id ? accountTypeId?.value.id || null : accountTypeId?.value || null

        if(accountsMatch.includes(id)) {
            handleInputChange(currencies.find(c => c.value.id === authState.enterprise.currency.id), 'currencyId')
        }
    }, [accountTypeId])

    const formatGroupLabel = data => (
        <div className="flex justify-between text-gray-blue font-bold my-4">
            <span className="text-sm">{accountTypesName[data.label]}</span>
        </div>
    );

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={() => setShowModal(false)}>
            <Wrapper>
                <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                <h3 className="text-center text-xl font-bold">{accountId ? 'Editar' : 'Agregar'} cuenta contable</h3>
                <p className="text-center text-base">Completa el siguiente formulario para agregar una nueva cuenta contable</p>

                <form id='account-form' onSubmit={handleSubmit} className="h-full p-2">
                    <div>
                        <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'accountTypeId') ? 'text-danger' : ''}`} >Tipo de cuenta contable<span className="text-base text-danger">*</span></p>
                        <Select
                            isDisabled={accountId ? true : false}
                            placeholder="Selecciona tipo de cuenta"
                            value={accountTypeId}
                            onChange={(e) => handleInputChange(e, 'accountTypeId')}
                            options={formatAccountTypes()}
                            className="sfn-select"
                            styles={{
                                ...sfnSelectTheme,
                            }}
                            formatGroupLabel={formatGroupLabel}
                        />
                    </div>

                    <IonItem className={`sfn-input ${validateKey(valid, 'name') ? 'sfn-input-validate' : ''}`} mode="md" lines="none">
                        <IonLabel position="stacked">Nombre de cuenta contable<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput
                            name="name"
                            type="text"
                            placeholder="Indica nombre de cuenta"
                            onIonChange={handleInputChange}
                            value={name}
                            required> </IonInput>
                    </IonItem>

                    <div className="mt-3">
                        <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'currencyId') ? 'text-danger' : ''}`}>Moneda<span className="text-base text-danger">*</span></p>
                        <Select
                            isDisabled={accountId || accountsMatch.includes(accountTypeId?.value?.id || accountTypeId?.value) ? true : false}
                            placeholder="Selecciona moneda"
                            value={currencyId}
                            onChange={(e) => handleInputChange(e, 'currencyId')}
                            options={currencies}
                            className="sfn-select"
                            styles={sfnSelectTheme}
                        />
                    </div>

                    <IonItem className={`sfn-input`} mode="md" lines="none">
                        <IonLabel position="stacked">ID de cuenta contable (Opcional)</IonLabel>
                        <IonInput
                            name="customId"
                            type="text"
                            placeholder="Organiza tu cuenta contable asignando número o ID"
                            onIonChange={handleInputChange}
                            value={customId}> </IonInput>
                    </IonItem>

                    <IonItem className={`sfn-input`} mode="md" lines="none">
                        <IonLabel position="stacked">Descripción (Opcional)</IonLabel>
                        <IonTextarea
                            name="description"
                            placeholder=""
                            onIonChange={handleInputChange}
                            value={description}> </IonTextarea>
                    </IonItem>

                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                            Cancelar
                        </IonButton>
                        <SfnButton form="account-form" label={accountId ? 'Guardar cambios' : 'Guardar cuenta contable'} loading={accountId ? uLoading : cLoading} />
                    </div>

                </form>
            </Wrapper>
        </IonModal>

    )

}

export default AccountModal;