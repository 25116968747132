export const sizes = [
  {
    id: '7491c3c3-874a-4940-ae75-954dd4e51e1d',
    name: '1-5',
  },
  {
    id: '7be4c100-dca5-4203-a479-d71d979772bb',
    name: '6-10',
  },
  {
    id: 'e5c0c380-5d01-405e-b5b1-6afaa5b794a7',
    name: '11-25',
  },
  {
    id: '0af6d48e-d933-4514-bab0-e1723e05121e',
    name: '26-50',
  },
  {
    id: 'c69ab68f-d9d3-47d8-8e5c-6ddbe5dc1c74',
    name: '51+',
  },
];

export const frequencyPayroll = [
  // {
  //   label: "Cada semana",
  //   description:
  //     "Los colaboradores son pagados cada semana. Esto es igual a 52 o 53 días de pago por año.",
  //   value: "EVERY_WEEK",
  // },
  {
    label: 'Una vez al mes',
    description:
      'Los colaboradores son pagados una vez al mes. Esto es igual a 12 salarios por año.',
    value: 'ONCE_A_MONTH',
  },
  {
    label: 'Dos veces al mes',
    description:
      'Los colaboradores son pagados dos veces al mes. Esto es igual a 24 salarios por año.',
    value: 'TWICE_A_MONTH',
  },
];

export const allBenefits = [
  {
    id: '57e77802-7223-433e-9522-a39201d78f39',
    name: 'Un bono',
    formula: '',
    group: 'BENEFIT',
    description:
      'Un bono es dinero que se agrega a la paga de un empleado para recompensar el buen desempeño. Se grava como ingresos. Los ejemplos incluyen: bonificación por rendimiento, bonificación por ventas, bonificación estacional. Esto solo se aplicará cuando el empleado esté activo y de licencia.',
  },
  {
    id: 'ee4615d2-e6f7-4c30-9c2e-6b2daa7067fe',
    name: 'Una comisión',
    formula: '',
    group: 'BENEFIT',
    description:
      'Una comisión es el dinero que se paga a un empleado como pago total o parcial por el trabajo. Se grava como ingresos. Los ejemplos incluyen: comisión de ventas, comisión de referencia. Esto solo se aplicará cuando el empleado esté activo y de licencia.',
  },
  // {
  //   id: "604cab16-3649-4584-a2c3-540e6360d0e5",
  //   name: "Una mesada",
  //   formula: "",
  //   group: "BENEFIT",
  //   description:
  //     "Una asignación es dinero que se paga a un empleado además de su salario regular para cubrir varios costos pagados directamente por el empleado. Se grava como ingresos. Los ejemplos incluyen: servicio de teléfono celular, membresía en un gimnasio, gastos de mudanza y reubicación, pases de tránsito, tasas de matrícula. Esto solo se aplicará cuando el empleado esté activo y de licencia.",
  // },
  {
    id: 'c1dc6a68-5ffa-4b99-84e6-bddec3efa47b',
    name: 'Un reembolso por gasto',
    formula: '',
    group: 'BENEFIT',
    description:
      'Un reembolso antes de impuestos pagado a un empleado para cubrir los gastos de bolsillo incurridos en nombre de la empresa, es decir, gasolina para un automóvil de la empresa, suministros de oficina, comida para una reunión de la empresa. Los reembolsos de gastos no aparecen en el formulario W-2. Esto solo se aplicará cuando el empleado esté activo y de licencia',
  },
  {
    id: '08f337ac-ea2e-43c4-92a7-3dc5bb3cb4fc',
    name: 'Pago por despido',
    formula: '',
    group: 'BENEFIT',
    description:
      'Salarios agregados a un recibo de pago final en lugar de un aviso de despido suficiente de acuerdo con las normas laborales o los términos de un contrato de trabajo. Los ejemplos incluyen: Despido sin aviso suficiente Esto solo se aplicará cuando se despida al empleado.',
  },
  {
    id: 'cc9a6d36-a852-465b-8ea2-7de44a7d4627',
    name: 'Horas extras',
    formula: '',
    group: 'BENEFIT',
    description:
      'Un bono es dinero que se agrega a la paga de un empleado para recompensar el buen desempeño. Se grava como ingresos. Los ejemplos incluyen: bonificación por rendimiento, bonificación por ventas, bonificación estacional. Esto solo se aplicará cuando el empleado esté activo y de licencia.',
  },
  // {
  //   id: "be910bd0-1dd4-4092-af86-6e21d816d021",
  //   name: "Primas médicas por servicio",
  //   formula: "",
  //   group: "BENEFIT",
  //   description:
  //     'Primas médicas, dentales y de la vista para accionistas superiores al 2%. Los montos de las primas se gravan como salarios a los fines del impuesto sobre la renta federal, pero no afectan los salarios del Seguro Social y Medicare. En el Formulario W2, aumenta los salarios en el Cuadro 1 y se completa en el Cuadro 14 con el código "Otro". Esto solo se aplicará cuando el empleado esté activo.',
  // },
  // {
  //   id: "cc5ffeb1-c3ae-44e5-8f60-03a859e03344",
  //   name: "Primas médicas por servicio",
  //   formula: "",
  //   group: "BENEFIT",
  //   description:
  //     'Primas médicas, dentales y de la vista para accionistas superiores al 2%. Los montos de las primas se gravan como salarios a los fines del impuesto sobre la renta federal, pero no afectan los salarios del Seguro Social y Medicare. En el Formulario W2, aumenta los salarios en el Cuadro 1 y se completa en el Cuadro 14 con el código "Otro". Esto solo se aplicará cuando el empleado esté activo.',
  // },
];

export const allDiscounts = [
  {
    id: '1bbe2c16-63a1-40e9-b040-e958bdc7abed',
    name: 'Una contribución',
    formula: '',
    group: 'DISCOUNT',
    description: '',
  },
  {
    id: 'ce784e9d-4caf-46a7-965d-dd267be926a3',
    name: 'Anticipo salario base',
    formula: '',
    group: 'DISCOUNT',
    description: '',
  },
  {
    id: 'f8fc9f85-3652-475e-bfeb-265de2885549',
    name: 'Seguro médico privado',
    formula: '',
    group: 'DISCOUNT',
    description: '',
  },
  {
    id: 'be910bd0-1dd4-4092-af86-6e21d816d021',
    name: 'Primas médicas por servicio',
    formula: '',
    group: 'DISCOUNT',
    description:
      'Primas médicas, dentales y de la vista para accionistas superiores al 2%. Los montos de las primas se gravan como salarios a los fines del impuesto sobre la renta federal, pero no afectan los salarios del Seguro Social y Medicare. En el Formulario W2, aumenta los salarios en el Cuadro 1 y se completa en el Cuadro 14 con el código "Otro". Esto solo se aplicará cuando el empleado esté activo.',
  },
];

export const bloodTypes = [
  {
    value: 'A+',
    label: 'A Positivo',
  },
  {
    value: 'A-',
    label: 'A Negativo',
  },
  {
    value: 'B+',
    label: 'B Positivo',
  },
  {
    value: 'B-',
    label: 'B Negativo',
  },
  {
    value: 'O+',
    label: 'O Positivo',
  },
  {
    value: 'O-',
    label: 'O Negativo',
  },
  {
    value: 'AB+',
    label: 'AB Positivo',
  },
  {
    value: 'AB-',
    label: 'AB Negativo',
  },
];

export const maritalStatuses = [
  {
    value: 'UNMARRIED',
    label: 'Soltero (a)',
  },
  {
    value: 'MARRIED',
    label: 'Casado (a)',
  },
  {
    value: 'DIVORCED',
    label: 'Divorciado (a)',
  },
  {
    value: 'WIDOWER',
    label: 'Viudo (a)',
  },
];

export const accountTypes = [
  {
    id: '3de9c91f-cafb-49e9-81fa-7ae1562581ab',
    //  name: "Investments",
    name: 'Inversiones',
    group: 'ASSET',
  },
  {
    id: '4cde4532-cb12-4014-8dea-14c963f0e3e4',
    // name: "Cash and Bank",
    name: 'Efectivo y bancos',
    group: 'ASSET',
  },
  {
    id: 'c403815e-3d25-47b9-b846-5b565fcd1a91',
    // name: "Depreciation and Amortization",
    name: 'Depreciaciones y amortizaciones',
    group: 'ASSET',
  },
  {
    id: '8decaeea-26f2-4ef5-b9ec-01d9e63462c4',
    // name: "Expected Payments from Customers", // duda *nombre
    name: 'Cuentas por cobrar de clientes',
    group: 'ASSET',
  },
  {
    id: '6c5561e3-8ecb-4300-b5d7-59bf2b076746',
    // name: "Inventory",
    name: 'Inventario',
    group: 'ASSET',
  },
  {
    id: '4d420e8a-5a24-4ad1-956f-04c84bfba7df',
    // name: "Money in Transit",
    name: 'Dinero en tránsito',
    group: 'ASSET',
  },
  {
    id: 'bfd9c980-5ceb-4351-a6c0-c58094cfdedd',
    // name: "Other Short-Term Asset",
    name: 'Otro activo a corto plazo',
    group: 'ASSET',
  },
  {
    id: '13049f23-31d6-4c9e-99ea-9dd5097117f9',
    // name: "Other Long-Term Asset",
    name: 'Otro activo a largo plazo',
    group: 'ASSET',
  },
  {
    id: '738ec4bb-310c-4d6d-8ecc-cb7f131f52dc',
    // name: "Property, Plant, Equipment",
    name: 'Propiedad, planta y equipo',
    group: 'ASSET',
  },
  {
    id: '8e24e34e-19e1-4f8f-8a9f-a20b31f50b72',
    // name: "Vendor Prepayments and Vendor Credits",
    name: 'Pagos anticipados a proveedores y créditos a proveedores',
    group: 'ASSET',
  },
  {
    id: 'f2d82c7d-14a1-424e-bdac-65a8a39c04f7',
    // name: "Credit Card",
    name: 'Tarjeta de crédito',
    group: 'LIABILITY',
  },
  {
    id: '3c8cdb84-c0e1-4616-a040-4c97a4322b64',
    // name: "Customer Prepayments and Customer Credits", // duda *nombre consistencia
    name: 'Pagos anticipados de clientes y créditos de clientes',
    group: 'LIABILITY',
  },
  {
    id: '5230b031-6fe8-45b2-827f-5ab4cbf7fb90',
    // name: "Due For Payroll",
    name: 'Pago de nómina',
    group: 'LIABILITY',
  },
  {
    id: '84af1ddf-0b34-44d4-8b60-f3f4cb586d05',
    // name: "Due to You and Other Business Owners", // duda *nombre
    name: 'Préstamos de accionistas',
    group: 'LIABILITY',
  },
  {
    id: '6f396cdc-118e-4cfa-bad4-1b50e0c463ad',
    // name: "Expected Payments to Vendors", // duda *nombre consistencia
    name: 'Cuentas por pagar a proveedores',
    group: 'LIABILITY',
  },
  {
    id: '4407f486-45f6-470b-90a6-caf83b89d9be',
    // name: "Loan and Line of Credit", // duda *nombre
    name: 'Préstamo y línea de crédito',
    group: 'LIABILITY',
  },
  {
    id: '923e3091-e48c-4551-a36b-0a72efd79839',
    // name: "Other Short-Term Liability", // duda *nombre consistencia
    name: 'Otro pasivo a corto plazo',
    group: 'LIABILITY',
  },
  {
    id: 'e006eb3d-8642-493e-bbec-7e4a9c46be10',
    // name: "Other Long-Term Liability",  // duda *nombre consistencia
    name: 'Otro pasivo a largo plazo',
    group: 'LIABILITY',
  },
  {
    id: '61045645-4f80-4567-870b-17dafb256af6',
    // name: "Sales Taxes",
    name: 'Impuestos de venta',
    group: 'LIABILITY',
  },
  {
    id: '846cfe95-a341-42a5-8eaf-939678be3ee4',
    // name: "Discount",
    name: 'Descuentos',
    group: 'INCOME',
  },
  {
    id: '9a933044-cc18-4086-9699-19a16cd17378',
    // name: "Gain On Foreign Exchange",
    name: 'Ganancias en divisas',
    group: 'INCOME',
  },
  {
    id: '69cc6bde-7b29-4e70-ad70-cfb572ad32c6',
    // name: "Income",
    name: 'Ingresos',
    group: 'INCOME',
  },
  {
    id: '1fe6fc44-b934-4992-91b1-61171c7ad7e0',
    // name: "Other Income",
    name: 'Otros ingresos',
    group: 'INCOME',
  },
  {
    id: '441ac4b4-3853-4849-919c-e52d9a1535e4',
    // name: "Uncategorized Income",
    name: 'Ingresos sin categoría',
    group: 'INCOME',
  },
  {
    id: '6ea31e9b-adc8-4b50-9617-1076e14025a6',
    // name: "Cost of Goods Sold", // duda *nombre
    name: 'Costo de lo vendido',
    group: 'EXPENSE',
  },
  {
    id: '92ecaa2d-5eb9-488e-a748-7a5b2412cb18',
    // name: "Loss On Foreign Exchange",
    name: 'Pérdida de cambio de divisas',
    group: 'EXPENSE',
  },
  {
    id: '676b252b-78f9-47ce-8207-8d94e05c65b4',
    // name: "Operating Expense",
    name: 'Gastos operativos',
    group: 'EXPENSE',
  },
  {
    id: 'c21b3b42-ee31-4c13-b790-829d02b72daf',
    // name: "Payment Processing Fee",
    name: 'Tarifas de preocesamiento de pago',
    group: 'EXPENSE',
  },
  {
    id: '3778c78f-7cf2-4389-9c6e-ccc98c36ab5a',
    // name: "Payroll Expense",
    name: 'Gastos de nómina',
    group: 'EXPENSE',
  },
  {
    id: '9cdd90b1-32bd-4b9c-8746-19f907ef5439',
    // name: "Uncategorized Expense",
    name: 'Gastos sin categoría',
    group: 'EXPENSE',
  },
  {
    id: '38f68678-04e2-4d49-8648-af51245b25ac',
    // name: "Other Expenses",
    name: 'Otros gastos',
    group: 'EXPENSE',
  },
  {
    id: 'd6a3eb33-e21d-45d8-9bce-ddb2e5fb469f',
    // name: "Business Owner Contribution and Drawing", // duda *nombre
    name: 'Contribuciones de socios o propietarios',
    group: 'EQUITY',
  },
  {
    id: '02116fff-24ec-41d9-bcac-cb0516c5316c',
    // name: "Retained Earnings: Profit", // duda *nombre
    name: 'Ganancias retenidas: Utilidad',
    group: 'EQUITY',
  },
];

export const currencies = [
  {
    label: 'AED (AED) UAE dirham',
    value: {
      id: '9150bbce-dc63-49ab-b000-f5aa3f68ac02',
      code: 'AED',
      symbol: 'AED',
      name: 'UAE dirham',
      plural: 'UAE dirhams',
    },
  },
  {
    label: 'AFN (؋) Afghani',
    value: {
      id: 'd2149188-2828-42b4-a828-5b7396a645a1',
      code: 'AFN',
      symbol: '؋',
      name: 'Afghani',
      plural: 'Afganis',
    },
  },
  {
    label: 'ALL (Lek) Lek',
    value: {
      id: '4f5669a7-893d-4677-886c-0a0a26b2f68d',
      code: 'ALL',
      symbol: 'Lek',
      name: 'Lek',
      plural: 'Lekë',
    },
  },
  {
    label: 'AMD (֏) Armenian dram',
    value: {
      id: '8e36514d-67e2-46b6-aa5b-8bb0394e2f17',
      code: 'AMD',
      symbol: '֏',
      name: 'Armenian dram',
      plural: 'Armenian drams',
    },
  },
  {
    label: 'ANG (ƒ) Netherlands Antillean guilder',
    value: {
      id: 'd39a838a-9dc9-4f52-9efe-ee593d005afc',
      code: 'ANG',
      symbol: 'ƒ',
      name: 'Netherlands Antillean guilder',
      plural: 'Netherlands Antillean guilders',
    },
  },
  {
    label: 'AOA (Kz) Kwanza',
    value: {
      id: 'bcd0cebf-3bfd-4cc5-b755-d36d288e9514',
      code: 'AOA',
      symbol: 'Kz',
      name: 'Kwanza',
      plural: 'Kwanzas',
    },
  },
  {
    label: 'ARS ($) Argentinian peso',
    value: {
      id: '07bc68ef-6369-4837-b158-9e156a77ba06',
      code: 'ARS',
      symbol: '$',
      name: 'Argentinian peso',
      plural: 'Argentinian pesos',
    },
  },
  {
    label: 'AUD ($) Australian dollar',
    value: {
      id: 'c1a4c166-0d2a-4055-a132-87f33257e34a',
      code: 'AUD',
      symbol: '$',
      name: 'Australian dollar',
      plural: 'Australian dollars',
    },
  },
  {
    label: 'AWG (ƒ) Aruban florin',
    value: {
      id: '4dc31c7f-7ce9-4e43-8577-a8c6bf4c9c9b',
      code: 'AWG',
      symbol: 'ƒ',
      name: 'Aruban florin',
      plural: 'Aruban florin',
    },
  },
  {
    label: 'AZN (ман) New Manat',
    value: {
      id: '4e4363bc-7793-4124-9072-b44b0e410115',
      code: 'AZN',
      symbol: 'ман',
      name: 'New Manat',
      plural: 'New Manat',
    },
  },
  {
    label: 'BAM (KM) Convertible Marks',
    value: {
      id: '4d68a17a-9a39-437b-a471-ab4f3eb330f7',
      code: 'BAM',
      symbol: 'KM',
      name: 'Convertible Marks',
      plural: 'Convertible Marks',
    },
  },
  {
    label: 'BBD ($) Barbados dollar',
    value: {
      id: 'c53c7db0-f833-49d5-bce1-7c17e5dc4518',
      code: 'BBD',
      symbol: '$',
      name: 'Barbados dollar',
      plural: 'Barbados dollars',
    },
  },
  {
    label: 'BDT (৳) Taka',
    value: {
      id: '4d942df2-f1a9-4269-be7f-82837e4935c7',
      code: 'BDT',
      symbol: '৳',
      name: 'Taka',
      plural: 'Takas',
    },
  },
  {
    label: 'BGN (лв) Lev',
    value: {
      id: '932fe400-a2b6-4424-a597-dccf43886c55',
      code: 'BGN',
      symbol: 'лв',
      name: 'Lev',
      plural: 'Leva',
    },
  },
  {
    label: 'BHD (BD) Bahraini dinar',
    value: {
      id: '09ec2481-d826-41fe-b135-1687008a59cc',
      code: 'BHD',
      symbol: 'BD',
      name: 'Bahraini dinar',
      plural: 'Bahraini dinars',
    },
  },
  {
    label: 'BIF (FBu) Burundi franc',
    value: {
      id: '325b6070-4a9b-4a9f-a011-9593c533ba00',
      code: 'BIF',
      symbol: 'FBu',
      name: 'Burundi franc',
      plural: 'Burundi francs',
    },
  },
  {
    label: 'BMD ($) Bermuda dollar',
    value: {
      id: '15bc3d0e-ed46-444a-b1ff-42b4b6e0d088',
      code: 'BMD',
      symbol: '$',
      name: 'Bermuda dollar',
      plural: 'Bermuda dollars',
    },
  },
  {
    label: 'BND ($) Brunei dollar',
    value: {
      id: 'aa35057d-93fb-4e72-9ee0-322eb7304827',
      code: 'BND',
      symbol: '$',
      name: 'Brunei dollar',
      plural: 'Brunei dollars',
    },
  },
  {
    label: 'BOB ($b) Boliviano',
    value: {
      id: 'd4b766fb-ea38-4a9d-84dc-21296bd45039',
      code: 'BOB',
      symbol: '$b',
      name: 'Boliviano',
      plural: 'Bolivianos',
    },
  },
  {
    label: 'BRL (R$) Real',
    value: {
      id: '2d7dee8b-7f02-4405-b5f1-b234b9b5a7e3',
      code: 'BRL',
      symbol: 'R$',
      name: 'Real',
      plural: 'Reales',
    },
  },
  {
    label: 'BSD ($) Bahamian dollar',
    value: {
      id: 'defc2512-ab9f-4ff2-ae81-b2602b382e42',
      code: 'BSD',
      symbol: '$',
      name: 'Bahamian dollar',
      plural: 'Bahamian dollars',
    },
  },
  {
    label: 'BTN (Nu.) Ngultrum',
    value: {
      id: '983f3bd6-2168-4c63-b893-28eaef2d4608',
      code: 'BTN',
      symbol: 'Nu.',
      name: 'Ngultrum',
      plural: 'Ngultrums',
    },
  },
  {
    label: 'BWP (P) Pula',
    value: {
      id: 'e498dcf3-5e5f-4f4a-94eb-0e57f5718d8e',
      code: 'BWP',
      symbol: 'P',
      name: 'Pula',
      plural: 'Pula',
    },
  },
  {
    label: 'BYR (p.) Belarussian rouble',
    value: {
      id: 'd0fb05ec-bb2f-45f6-a081-d4bbc976f80f',
      code: 'BYR',
      symbol: 'p.',
      name: 'Belarussian rouble',
      plural: 'Belarussian roubles',
    },
  },
  {
    label: 'BZD (BZ$) Belize dollar',
    value: {
      id: '1434e15d-6ef4-4c4b-adbe-7bc46073f220',
      code: 'BZD',
      symbol: 'BZ$',
      name: 'Belize dollar',
      plural: 'Belize dollars',
    },
  },
  {
    label: 'CAD ($) Canadian dollar',
    value: {
      id: 'd334870e-9f2d-4508-842c-d3ff5209f7ec',
      code: 'CAD',
      symbol: '$',
      name: 'Canadian dollar',
      plural: 'Canadian dollars',
    },
  },
  {
    label: 'CDF (₣) Franc congolais',
    value: {
      id: 'ecbcd14e-269d-47d0-9adc-2246abb9fb1d',
      code: 'CDF',
      symbol: '₣',
      name: 'Franc congolais',
      plural: 'Francs congolais',
    },
  },
  {
    label: 'CHF (CHF) Swiss franc',
    value: {
      id: '4eb9fd79-d162-4ef4-a021-c73d7fdc0241',
      code: 'CHF',
      symbol: 'CHF',
      name: 'Swiss franc',
      plural: 'Swiss francs',
    },
  },
  {
    label: 'CLP ($) Chilean peso',
    value: {
      id: '415d1fcd-34f3-46bb-aa54-8fba852866b9',
      code: 'CLP',
      symbol: '$',
      name: 'Chilean peso',
      plural: 'Chilean pesos',
    },
  },
  {
    label: 'CNY (¥) Ren-Min-Bi yuan',
    value: {
      id: 'c220b4b3-016b-4c54-a210-a04448b93e7d',
      code: 'CNY',
      symbol: '¥',
      name: 'Ren-Min-Bi yuan',
      plural: 'Ren-Min-Bi yuan',
    },
  },
  {
    label: 'COP ($) Colombian peso',
    value: {
      id: 'e3307353-de9d-4ad0-b0e3-7da620dd77c5',
      code: 'COP',
      symbol: '$',
      name: 'Colombian peso',
      plural: 'Colombian pesos',
    },
  },
  {
    label: 'CRC (₡) Costa Rican colon',
    value: {
      id: 'f4eb17dd-41aa-4831-9f0a-5b9f33c4c6aa',
      code: 'CRC',
      symbol: '₡',
      name: 'Costa Rican colon',
      plural: 'Costa Rican colones',
    },
  },
  {
    label: 'CUP (₱) Cuban peso',
    value: {
      id: 'c3361e26-35e4-4528-9dde-5afd66c73248',
      code: 'CUP',
      symbol: '₱',
      name: 'Cuban peso',
      plural: 'Cuban pesos',
    },
  },
  {
    label: 'CVE (Esc) Cape Verde escudo',
    value: {
      id: 'f4dc3b3b-9fe3-46d4-966f-881915c6f41c',
      code: 'CVE',
      symbol: 'Esc',
      name: 'Cape Verde escudo',
      plural: 'Cape Verde escudos',
    },
  },
  {
    label: 'CZK (Kč) Czech koruna',
    value: {
      id: '8fe1baf4-9a99-49b7-a334-6224149e2520',
      code: 'CZK',
      symbol: 'Kč',
      name: 'Czech koruna',
      plural: 'Czech korun',
    },
  },
  {
    label: 'DJF (₣) Djibouti franc',
    value: {
      id: 'b37b4300-5cfa-49e1-ac26-2d3f49f6072f',
      code: 'DJF',
      symbol: '₣',
      name: 'Djibouti franc',
      plural: 'Djibouti francs',
    },
  },
  {
    label: 'DKK (kr) Danish krone',
    value: {
      id: '37418ddd-cdaa-4dae-b8ca-df8ed7766e3b',
      code: 'DKK',
      symbol: 'kr',
      name: 'Danish krone',
      plural: 'Danish kroner',
    },
  },
  {
    label: 'DOP (RD$) Dominican peso',
    value: {
      id: '2bd4fd1d-a2b8-4a85-beab-4f424749dd1d',
      code: 'DOP',
      symbol: 'RD$',
      name: 'Dominican peso',
      plural: 'Dominican pesos',
    },
  },
  {
    label: 'DZD (د.ج) Algerian dinar',
    value: {
      id: 'a9ecec85-a7bc-4f92-a437-437023ea009d',
      code: 'DZD',
      symbol: 'د.ج',
      name: 'Algerian dinar',
      plural: 'Algerian dinars',
    },
  },
  {
    label: 'EGP (E £) Egyptian pound',
    value: {
      id: '78861175-3f09-472c-a53c-bff5a4f9cd28',
      code: 'EGP',
      symbol: 'E £',
      name: 'Egyptian pound',
      plural: 'Egyptian pounds',
    },
  },
  {
    label: 'ERN (Nfk) Nakfa',
    value: {
      id: '7483b7a1-38ed-4955-8dc5-a20ab9511faf',
      code: 'ERN',
      symbol: 'Nfk',
      name: 'Nakfa',
      plural: 'Nakfas',
    },
  },
  {
    label: 'ETB (Br) Ethiopian birr',
    value: {
      id: '9e4bbc44-08ea-48a8-b9d0-bbd87b6c4324',
      code: 'ETB',
      symbol: 'Br',
      name: 'Ethiopian birr',
      plural: 'Ethiopian birrs',
    },
  },
  {
    label: 'EUR (€) Euro',
    value: {
      id: 'f2076c0a-bf4c-4a9b-b2f5-49bd52c25808',
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
      plural: 'Euros',
    },
  },
  {
    label: 'FJD ($) Fiji dollar',
    value: {
      id: '214b93c3-09e6-4017-8b59-2174be16783d',
      code: 'FJD',
      symbol: '$',
      name: 'Fiji dollar',
      plural: 'Fiji dollars',
    },
  },
  {
    label: 'FKP (£) Falkland Islands (Malvinas) pound',
    value: {
      id: '6d4203ca-584d-4604-aee7-3e1ed557cec1',
      code: 'FKP',
      symbol: '£',
      name: 'Falkland Islands (Malvinas) pound',
      plural: 'Falkland Islands (Malvinas) pounds',
    },
  },
  {
    label: 'GBP (£) Pound sterling',
    value: {
      id: '4b1161fa-ec00-4c67-bd11-ac3ae93fba59',
      code: 'GBP',
      symbol: '£',
      name: 'Pound sterling',
      plural: 'Pounds sterling',
    },
  },
  {
    label: 'GEL (ლ) Lari',
    value: {
      id: 'df6e6aa2-d0df-488a-aa8a-a0a1e560617c',
      code: 'GEL',
      symbol: 'ლ',
      name: 'Lari',
      plural: 'Lari',
    },
  },
  {
    label: 'GHS (GH¢) Ghana cedi',
    value: {
      id: 'd43f31f0-5775-4d43-8005-a269f8da762d',
      code: 'GHS',
      symbol: 'GH¢',
      name: 'Ghana cedi',
      plural: 'Ghana cedis',
    },
  },
  {
    label: 'GIP (£) Gibraltar pound',
    value: {
      id: 'b6fbcc5a-d6d0-44de-8494-676d0ac2b06c',
      code: 'GIP',
      symbol: '£',
      name: 'Gibraltar pound',
      plural: 'Gibraltar pounds',
    },
  },
  {
    label: 'GMD (D) Dalasi',
    value: {
      id: '29f645bb-7eb3-464f-b490-566366102e9e',
      code: 'GMD',
      symbol: 'D',
      name: 'Dalasi',
      plural: 'Dalasi',
    },
  },
  {
    label: 'GNF (₣) Guinean franc',
    value: {
      id: 'de36c9af-3fe0-4649-a697-29fda48f6f35',
      code: 'GNF',
      symbol: '₣',
      name: 'Guinean franc',
      plural: 'Guinean francs',
    },
  },
  {
    label: 'GTQ (Q) Quetzal',
    value: {
      id: '7202bcbb-6743-4103-83df-c6700f7f1935',
      code: 'GTQ',
      symbol: 'Q',
      name: 'Quetzal',
      plural: 'Quetzales',
    },
  },
  {
    label: 'GYD ($) Guyana dollar',
    value: {
      id: '33fa5d8e-aa31-4918-984a-4ab22938eb63',
      code: 'GYD',
      symbol: '$',
      name: 'Guyana dollar',
      plural: 'Guyana dollars',
    },
  },
  {
    label: 'HKD ($) Hong Kong dollar',
    value: {
      id: '0e7cac6e-3763-4614-b735-94b92e764369',
      code: 'HKD',
      symbol: '$',
      name: 'Hong Kong dollar',
      plural: 'Hong Kong dollars',
    },
  },
  {
    label: 'HNL (L) Lempira',
    value: {
      id: '5a1554bd-a6f9-4f49-b274-9dde6ddc591c',
      code: 'HNL',
      symbol: 'L',
      name: 'Lempira',
      plural: 'Lempiras',
    },
  },
  {
    label: 'HRK (kn) Kuna',
    value: {
      id: '368d98e5-55fd-4e1d-a9f7-5fc8bd06a119',
      code: 'HRK',
      symbol: 'kn',
      name: 'Kuna',
      plural: 'Kunas',
    },
  },
  {
    label: 'HTG (G) Haitian gourde',
    value: {
      id: '7133b8f7-6a84-46f9-ace2-4978916d22a6',
      code: 'HTG',
      symbol: 'G',
      name: 'Haitian gourde',
      plural: 'Haitian gourdes',
    },
  },
  {
    label: 'HUF (Ft) Forint',
    value: {
      id: '8b8cf875-7f24-497e-803d-c60e3581322e',
      code: 'HUF',
      symbol: 'Ft',
      name: 'Forint',
      plural: 'Forints',
    },
  },
  {
    label: 'IDR (Rp) Rupiah',
    value: {
      id: '6dca7076-5713-493b-8d96-75428d2960d3',
      code: 'IDR',
      symbol: 'Rp',
      name: 'Rupiah',
      plural: 'Rupiahs',
    },
  },
  {
    label: 'ILS (₪) New Israeli sheqel',
    value: {
      id: '6d8125dd-2eea-4fe6-b9f7-cec33ed7dd10',
      code: 'ILS',
      symbol: '₪',
      name: 'New Israeli sheqel',
      plural: 'New Israeli sheqels',
    },
  },
  {
    label: 'INR (₹) Indian rupee',
    value: {
      id: '94922a06-9fe1-439f-b1e7-ada5397468cf',
      code: 'INR',
      symbol: '₹',
      name: 'Indian rupee',
      plural: 'Indian rupees',
    },
  },
  {
    label: 'IQD (د.ع) Iraqi dinar',
    value: {
      id: '05f33f49-d6ab-4eac-9b61-8938eab67d26',
      code: 'IQD',
      symbol: 'د.ع',
      name: 'Iraqi dinar',
      plural: 'Iraqi dinars',
    },
  },
  {
    label: 'IRR (﷼) Iranian rial',
    value: {
      id: 'd5a5bad6-adaf-4f50-b4ad-80b45f59fccf',
      code: 'IRR',
      symbol: '﷼',
      name: 'Iranian rial',
      plural: 'Iranian rials',
    },
  },
  {
    label: 'ISK (kr) Icelandic króna',
    value: {
      id: '721511ec-fc16-425c-a973-c97d1a6689a2',
      code: 'ISK',
      symbol: 'kr',
      name: 'Icelandic króna',
      plural: 'Icelandic krónur',
    },
  },
  {
    label: 'JMD (J$) Jamaican dollar',
    value: {
      id: '9038760e-e727-4584-8481-86e4f2c2a005',
      code: 'JMD',
      symbol: 'J$',
      name: 'Jamaican dollar',
      plural: 'Jamaican dollars',
    },
  },
  {
    label: 'JOD (د.ا) Jordanian dinar',
    value: {
      id: '0cd68fa6-ae2b-4ea1-8d51-3103c56a1216',
      code: 'JOD',
      symbol: 'د.ا',
      name: 'Jordanian dinar',
      plural: 'Jordanian dinars',
    },
  },
  {
    label: 'JPY (¥) Yen',
    value: {
      id: 'bb81ed72-b3bd-4d19-91e8-4978caab5979',
      code: 'JPY',
      symbol: '¥',
      name: 'Yen',
      plural: 'Yen',
    },
  },
  {
    label: 'KES (SH) Kenyan shilling',
    value: {
      id: '6281c36a-0ea5-43ab-a51c-2239876c7855',
      code: 'KES',
      symbol: 'SH',
      name: 'Kenyan shilling',
      plural: 'Kenyan shillings',
    },
  },
  {
    label: 'KGS (лв) Kyrgyz Som',
    value: {
      id: 'aede023e-e98d-44aa-8e6c-0b8cf729bcd0',
      code: 'KGS',
      symbol: 'лв',
      name: 'Kyrgyz Som',
      plural: 'Kyrgyz Soms',
    },
  },
  {
    label: 'KHR (៛) Riel',
    value: {
      id: '27dddfaa-edf4-4454-ab37-cb04f1ba856e',
      code: 'KHR',
      symbol: '៛',
      name: 'Riel',
      plural: 'Riels',
    },
  },
  {
    label: 'KMF (₣) Comoro franc',
    value: {
      id: '757588d2-c81c-43fa-a82f-d2ace964df7e',
      code: 'KMF',
      symbol: '₣',
      name: 'Comoro franc',
      plural: 'Comoro francs',
    },
  },
  {
    label: 'KRW (₩) Won',
    value: {
      id: '78daeb6e-fcaf-4597-ac88-e7e8e6851b26',
      code: 'KRW',
      symbol: '₩',
      name: 'Won',
      plural: 'Won',
    },
  },
  {
    label: 'KWD (د.ك) Kuwaiti dinar',
    value: {
      id: 'cd7319a8-0edd-4261-a98d-8f4786d846c7',
      code: 'KWD',
      symbol: 'د.ك',
      name: 'Kuwaiti dinar',
      plural: 'Kuwaiti dinars',
    },
  },
  {
    label: 'KYD ($) Cayman Islands dollar',
    value: {
      id: 'c00fbe70-7e58-419d-bdd4-0a50cec469ad',
      code: 'KYD',
      symbol: '$',
      name: 'Cayman Islands dollar',
      plural: 'Cayman Islands dollars',
    },
  },
  {
    label: 'KZT (лв) Tenge',
    value: {
      id: '742c0e00-5761-4c8e-a924-d36b80dd31dc',
      code: 'KZT',
      symbol: 'лв',
      name: 'Tenge',
      plural: 'Tenge',
    },
  },
  {
    label: 'LAK (₭) Kip',
    value: {
      id: '89a52ec1-1ea6-4c94-b0c8-94ee3e63cb0c',
      code: 'LAK',
      symbol: '₭',
      name: 'Kip',
      plural: 'Kips',
    },
  },
  {
    label: 'LBP (LBP) Lebanese pound',
    value: {
      id: '921cb585-66d2-4db1-8b7f-c91175a39f9f',
      code: 'LBP',
      symbol: 'LBP',
      name: 'Lebanese pound',
      plural: 'Lebanese pounds',
    },
  },
  {
    label: 'LKR (₨) Sri Lankan rupee',
    value: {
      id: '31128d7f-375f-44d9-8d43-4ed74cecdf49',
      code: 'LKR',
      symbol: '₨',
      name: 'Sri Lankan rupee',
      plural: 'Sri Lankan rupees',
    },
  },
  {
    label: 'LRD ($) Liberian dollar',
    value: {
      id: '3233bf9e-f7e3-474b-8fb5-35533cb7dc6e',
      code: 'LRD',
      symbol: '$',
      name: 'Liberian dollar',
      plural: 'Liberian dollars',
    },
  },
  {
    label: 'LSL (M) Loti',
    value: {
      id: '0584968b-e80c-42b5-87c7-9a7e4daa6334',
      code: 'LSL',
      symbol: 'M',
      name: 'Loti',
      plural: 'Maloti',
    },
  },
  {
    label: 'LTL (Lt) Lithuanian litus',
    value: {
      id: '68fa76e8-4cf5-4402-abb6-9a3f43b2092f',
      code: 'LTL',
      symbol: 'Lt',
      name: 'Lithuanian litus',
      plural: 'Lithuanian litai',
    },
  },
  {
    label: 'LVL (Ls) Latvian lats',
    value: {
      id: '6d1d3174-18ef-4e5a-8cd8-9f40d742a6fe',
      code: 'LVL',
      symbol: 'Ls',
      name: 'Latvian lats',
      plural: 'Latvian lats',
    },
  },
  {
    label: 'LYD (ل.د) Libyan dinar',
    value: {
      id: 'b39403e1-e1c0-4243-a091-49f765d04343',
      code: 'LYD',
      symbol: 'ل.د',
      name: 'Libyan dinar',
      plural: 'Libyan dinar',
    },
  },
  {
    label: 'MAD (د.م) Moroccan dirham',
    value: {
      id: 'bf362200-4341-40eb-bfda-3557cd691d6d',
      code: 'MAD',
      symbol: 'د.م',
      name: 'Moroccan dirham',
      plural: 'Moroccan dirhams',
    },
  },
  {
    label: 'MDL (L) Moldovan leu',
    value: {
      id: '0402f8a2-a0c9-4a14-86fa-3da23cfe096a',
      code: 'MDL',
      symbol: 'L',
      name: 'Moldovan leu',
      plural: 'Moldovan lei',
    },
  },
  {
    label: 'MGA (Ar) Malagasy Ariary',
    value: {
      id: '0327eb77-e18a-44ff-9d4b-5b4d9a60ad8d',
      code: 'MGA',
      symbol: 'Ar',
      name: 'Malagasy Ariary',
      plural: 'Malagasy Ariaries',
    },
  },
  {
    label: 'MKD (ден) Denar',
    value: {
      id: '6632de47-0167-49ba-be69-77a86a763667',
      code: 'MKD',
      symbol: 'ден',
      name: 'Denar',
      plural: 'Denari',
    },
  },
  {
    label: 'MMK (K) Kyat',
    value: {
      id: '551800a2-dfe5-496c-bc62-360c9bce4019',
      code: 'MMK',
      symbol: 'K',
      name: 'Kyat',
      plural: 'Kyats',
    },
  },
  {
    label: 'MNT (₮) Tugrik',
    value: {
      id: '983c726a-5330-4a94-bd1c-d7b9742391bd',
      code: 'MNT',
      symbol: '₮',
      name: 'Tugrik',
      plural: 'Tugriks',
    },
  },
  {
    label: 'MOP (MOP$) Pataca',
    value: {
      id: 'a03a23fa-90da-437e-9490-82d5b0c6fe59',
      code: 'MOP',
      symbol: 'MOP$',
      name: 'Pataca',
      plural: 'Patacas',
    },
  },
  {
    label: 'MRO (UM) Ouguiya',
    value: {
      id: 'c874ef3e-74f8-4b2e-b1f5-577f41bc6385',
      code: 'MRO',
      symbol: 'UM',
      name: 'Ouguiya',
      plural: 'Ouguiyas',
    },
  },
  {
    label: 'MUR (₨) Mauritian rupee',
    value: {
      id: 'a08f6ccb-46d6-44bb-a647-dd2621959d85',
      code: 'MUR',
      symbol: '₨',
      name: 'Mauritian rupee',
      plural: 'Mauritian rupees',
    },
  },
  {
    label: 'MVR (Rf) Rufiyaa',
    value: {
      id: '297dc727-1904-48f3-ba9b-cb3fa90aeb5f',
      code: 'MVR',
      symbol: 'Rf',
      name: 'Rufiyaa',
      plural: 'Rufiyaas',
    },
  },
  {
    label: 'MWK (MK) Kwacha',
    value: {
      id: '35c8011e-aad4-4550-ad8b-f60bffaf4c1f',
      code: 'MWK',
      symbol: 'MK',
      name: 'Kwacha',
      plural: 'Kwacha',
    },
  },
  {
    label: 'MXN ($) Mexican peso',
    value: {
      id: '32e07ea6-a368-4d83-9385-788cd301d7e9',
      code: 'MXN',
      symbol: '$',
      name: 'Mexican peso',
      plural: 'Mexican pesos',
    },
  },
  {
    label: 'MYR (RM) Malaysian ringgit',
    value: {
      id: 'c684c2b8-c20b-4cae-b4ad-c349a5a7dc5f',
      code: 'MYR',
      symbol: 'RM',
      name: 'Malaysian ringgit',
      plural: 'Malaysian ringgit',
    },
  },
  {
    label: 'MZN (MT) Metical',
    value: {
      id: 'ec0c3f99-f33b-45d9-9fc9-6081411fb489',
      code: 'MZN',
      symbol: 'MT',
      name: 'Metical',
      plural: 'Meticais',
    },
  },
  {
    label: 'NAD (N$) Namibian dollar',
    value: {
      id: 'd5988d16-ed14-4103-b2af-cc45daac6709',
      code: 'NAD',
      symbol: 'N$',
      name: 'Namibian dollar',
      plural: 'Namibian dollar',
    },
  },
  {
    label: 'NGN (₦) Naira',
    value: {
      id: 'c6a6df35-d62e-477f-ae48-ecc0bb48fcfd',
      code: 'NGN',
      symbol: '₦',
      name: 'Naira',
      plural: 'Naira',
    },
  },
  {
    label: 'NIO (C$) Cordoba Oro',
    value: {
      id: 'cbb4f09a-12fb-4597-843f-995fd68a486d',
      code: 'NIO',
      symbol: 'C$',
      name: 'Cordoba Oro',
      plural: 'Cordobas Oro',
    },
  },
  {
    label: 'NOK (kr) Norwegian krone',
    value: {
      id: 'f8c9b7ef-f5ec-4d48-8361-e7cfa452d717',
      code: 'NOK',
      symbol: 'kr',
      name: 'Norwegian krone',
      plural: 'Norwegian kroner',
    },
  },
  {
    label: 'NPR (₨) Nepalese rupee',
    value: {
      id: '4940a542-9dad-4042-911d-ea4afaf70924',
      code: 'NPR',
      symbol: '₨',
      name: 'Nepalese rupee',
      plural: 'Nepalese rupees',
    },
  },
  {
    label: 'NZD ($) New Zealand dollar',
    value: {
      id: '32d6cda0-1d14-432c-ae09-ec4f57054908',
      code: 'NZD',
      symbol: '$',
      name: 'New Zealand dollar',
      plural: 'New Zealand dollars',
    },
  },
  {
    label: 'OMR (﷼) Omani rial',
    value: {
      id: 'ea15fe6a-c1e0-44f6-b6c9-1e371c07fe20',
      code: 'OMR',
      symbol: '﷼',
      name: 'Omani rial',
      plural: 'Omani rials',
    },
  },
  {
    label: 'PAB (B/.) Balboa',
    value: {
      id: 'b132050b-a00c-497c-b7a2-2f4dc51e7169',
      code: 'PAB',
      symbol: 'B/.',
      name: 'Balboa',
      plural: 'Balboas',
    },
  },
  {
    label: 'PEN (S/.) Nuevo Sol',
    value: {
      id: '591e5fb4-5d17-463f-829d-6631efc4fe42',
      code: 'PEN',
      symbol: 'S/.',
      name: 'Nuevo Sol',
      plural: 'Nuevo Soles',
    },
  },
  {
    label: 'PGK (K) Kina',
    value: {
      id: '87ddb1e3-f3f1-4f2f-9654-e8f786afcaa4',
      code: 'PGK',
      symbol: 'K',
      name: 'Kina',
      plural: 'Kinas',
    },
  },
  {
    label: 'PHP (Php) Philippine peso',
    value: {
      id: '41ee6ee5-bed7-40e3-80ff-37a2bd0c47ee',
      code: 'PHP',
      symbol: 'Php',
      name: 'Philippine peso',
      plural: 'Philippine pesos',
    },
  },
  {
    label: 'PKR (₨) Pakistani rupee',
    value: {
      id: 'af69c309-4ccd-493e-ad0a-47971377e450',
      code: 'PKR',
      symbol: '₨',
      name: 'Pakistani rupee',
      plural: 'Pakistani rupees',
    },
  },
  {
    label: 'PLN (zł) Zloty',
    value: {
      id: '53d1159b-3e0a-468e-a7b6-3e2c86457bae',
      code: 'PLN',
      symbol: 'zł',
      name: 'Zloty',
      plural: 'Zloty',
    },
  },
  {
    label: 'PYG (Gs) Guarani',
    value: {
      id: 'c29e6596-f7be-4398-bc19-66fe460337ab',
      code: 'PYG',
      symbol: 'Gs',
      name: 'Guarani',
      plural: 'Guaranis',
    },
  },
  {
    label: 'QAR (﷼) Qatari riyal',
    value: {
      id: 'e8e13322-ae30-454f-9dbc-e2a196a4c0d7',
      code: 'QAR',
      symbol: '﷼',
      name: 'Qatari riyal',
      plural: 'Qatari riyals',
    },
  },
  {
    label: 'RON (lei) New Leu',
    value: {
      id: '6d551f81-eac7-4d4f-8743-ad0563b3d045',
      code: 'RON',
      symbol: 'lei',
      name: 'New Leu',
      plural: 'New Lei',
    },
  },
  {
    label: 'RSD (Дин.) Serbian dinar',
    value: {
      id: '568fb5f0-d45d-4cb1-ae8a-1f204bf72a48',
      code: 'RSD',
      symbol: 'Дин.',
      name: 'Serbian dinar',
      plural: 'Serbian dinars',
    },
  },
  {
    label: 'RUB (руб) Russian rouble',
    value: {
      id: '5346e79d-0e9a-4b2c-a33d-f5381f527541',
      code: 'RUB',
      symbol: 'руб',
      name: 'Russian rouble',
      plural: 'Russian roubles',
    },
  },
  {
    label: 'RWF (R₣) Rwanda franc',
    value: {
      id: '571a437e-9c95-47c1-996b-f0b71ec6427c',
      code: 'RWF',
      symbol: 'R₣',
      name: 'Rwanda franc',
      plural: 'Rwanda francs',
    },
  },
  {
    label: 'SAR (﷼) Saudi riyal',
    value: {
      id: 'd4553d81-c97c-456b-a357-f2895c004551',
      code: 'SAR',
      symbol: '﷼',
      name: 'Saudi riyal',
      plural: 'Saudi riyals',
    },
  },
  {
    label: 'SBD (SI$) Solomon Islands dollar',
    value: {
      id: '46f8e6aa-1aa4-4a85-86de-8877ff958535',
      code: 'SBD',
      symbol: 'SI$',
      name: 'Solomon Islands dollar',
      plural: 'Solomon Islands dollars',
    },
  },
  {
    label: 'SCR (₨) Seychelles rupee',
    value: {
      id: '7b6b9bb3-96e2-4ffd-9470-4a92ff82c406',
      code: 'SCR',
      symbol: '₨',
      name: 'Seychelles rupee',
      plural: 'Seychelles rupees',
    },
  },
  {
    label: 'SDG (£) Sudanese pound',
    value: {
      id: '805a7b63-0ea9-41c4-ad64-bcb1aa0459f5',
      code: 'SDG',
      symbol: '£',
      name: 'Sudanese pound',
      plural: 'Sudanese pounds',
    },
  },
  {
    label: 'SEK (kr) Swedish krona',
    value: {
      id: 'e1d6f7f2-d3c4-4434-b5dd-dd4bd429c1cd',
      code: 'SEK',
      symbol: 'kr',
      name: 'Swedish krona',
      plural: 'Swedish kronur',
    },
  },
  {
    label: 'SGD ($) Singapore dollar',
    value: {
      id: 'aaea250c-f43c-404e-925e-101834205ecf',
      code: 'SGD',
      symbol: '$',
      name: 'Singapore dollar',
      plural: 'Singapore dollars',
    },
  },
  {
    label: 'SHP (£) Saint Helena pound',
    value: {
      id: '5984f7cd-c0be-4743-b93b-ea1f6d41df4a',
      code: 'SHP',
      symbol: '£',
      name: 'Saint Helena pound',
      plural: 'Saint Helena pounds',
    },
  },
  {
    label: 'SLL (Le) Leone',
    value: {
      id: '66552e9a-2901-4de7-bd2c-770837369e43',
      code: 'SLL',
      symbol: 'Le',
      name: 'Leone',
      plural: 'Leones',
    },
  },
  {
    label: 'SOS (S) Somali shilling',
    value: {
      id: '0f309941-be7b-48d1-b853-0830afa0d11c',
      code: 'SOS',
      symbol: 'S',
      name: 'Somali shilling',
      plural: 'Somali shillings',
    },
  },
  {
    label: 'SRD ($) Surinam dollar',
    value: {
      id: '15228ed1-2c91-4a99-8d3a-b5995b1e63b8',
      code: 'SRD',
      symbol: '$',
      name: 'Surinam dollar',
      plural: 'Surinam dollars',
    },
  },
  {
    label: 'STD (Db) Dobra',
    value: {
      id: 'e4b7f8a4-5c29-40da-8fec-340b10e93381',
      code: 'STD',
      symbol: 'Db',
      name: 'Dobra',
      plural: 'Dobras',
    },
  },
  {
    label: 'SVC ($) El Salvador colon',
    value: {
      id: '41561fce-06c8-4d44-9757-e3933e617005',
      code: 'SVC',
      symbol: '$',
      name: 'El Salvador colon',
      plural: 'El Salvador colones',
    },
  },
  {
    label: 'SYP (£S) Syrian pound',
    value: {
      id: '7d70e229-dab7-4cb4-97f0-606c2425f7fd',
      code: 'SYP',
      symbol: '£S',
      name: 'Syrian pound',
      plural: 'Syrian pounds',
    },
  },
  {
    label: 'SZL (E) Lilangeni',
    value: {
      id: 'd60d841a-b9a5-4922-b581-bc8f80bcb238',
      code: 'SZL',
      symbol: 'E',
      name: 'Lilangeni',
      plural: 'Emalangeni',
    },
  },
  {
    label: 'THB (฿) Baht',
    value: {
      id: '216f8e8c-0ab2-429f-8ea3-c9e4b6a51c36',
      code: 'THB',
      symbol: '฿',
      name: 'Baht',
      plural: 'Baht',
    },
  },
  {
    label: 'TJS (SM) Somoni',
    value: {
      id: 'e5897ecf-d6c8-4f3e-af6f-34cc072ab69c',
      code: 'TJS',
      symbol: 'SM',
      name: 'Somoni',
      plural: 'Somonis',
    },
  },
  {
    label: 'TND (TND) Tunisian dinar',
    value: {
      id: '83466756-25c9-40a0-8c54-fe951e2049ef',
      code: 'TND',
      symbol: 'TND',
      name: 'Tunisian dinar',
      plural: 'Tunisian dinars',
    },
  },
  {
    label: "TOP ($) Pa'anga",
    value: {
      id: '1386e85d-1ce4-4058-a506-e93189a03dee',
      code: 'TOP',
      symbol: '$',
      name: "Pa'anga",
      plural: "Pa'anga",
    },
  },
  {
    label: 'TRY (TL) Turkish lira',
    value: {
      id: '4f91e9d6-820d-445e-855f-c35c348e708a',
      code: 'TRY',
      symbol: 'TL',
      name: 'Turkish lira',
      plural: 'Turkish liras',
    },
  },
  {
    label: 'TTD (TT$) Trinidad and Tobago dollar',
    value: {
      id: 'a4511232-2ea9-4310-b71a-693b9a30c4b2',
      code: 'TTD',
      symbol: 'TT$',
      name: 'Trinidad and Tobago dollar',
      plural: 'Trinidad and Tobago dollars',
    },
  },
  {
    label: 'TWD (NT$) New Taiwan dollar',
    value: {
      id: 'f85e0a62-51ac-471f-8f46-4bc64201faa7',
      code: 'TWD',
      symbol: 'NT$',
      name: 'New Taiwan dollar',
      plural: 'New Taiwan dollars',
    },
  },
  {
    label: 'TZS (Sh) Tanzanian shilling',
    value: {
      id: 'f45cd689-43ed-4d9b-ac5d-197efb306031',
      code: 'TZS',
      symbol: 'Sh',
      name: 'Tanzanian shilling',
      plural: 'Tanzanian shillings',
    },
  },
  {
    label: 'UAH (₴) Hryvnia',
    value: {
      id: '8711fee5-62f0-45ef-abed-1343021ca4a7',
      code: 'UAH',
      symbol: '₴',
      name: 'Hryvnia',
      plural: 'Hryvni',
    },
  },
  {
    label: 'UGX (UGX) Ugandan shilling',
    value: {
      id: '31bd034d-d1a8-4012-9f6e-183bf388af58',
      code: 'UGX',
      symbol: 'UGX',
      name: 'Ugandan shilling',
      plural: 'Ugandan shillings',
    },
  },
  {
    label: 'USD ($) U.S. dollar',
    value: {
      id: '42bf873f-2d23-47e9-9c16-f19bdb8b4f61',
      code: 'USD',
      symbol: '$',
      name: 'U.S. dollar',
      plural: 'U.S. dollars',
    },
  },
  {
    label: 'UYU ($U) Uruguayo peso',
    value: {
      id: '59e6059e-9fe2-47db-81f8-b0a5dc5ef74d',
      code: 'UYU',
      symbol: '$U',
      name: 'Uruguayo peso',
      plural: 'Uruguayo pesos',
    },
  },
  {
    label: 'UZS (лв) Uzbekistan sum',
    value: {
      id: 'c62c74cb-7bda-488b-aa4c-81867b757521',
      code: 'UZS',
      symbol: 'лв',
      name: 'Uzbekistan sum',
      plural: 'Uzbekistan sum',
    },
  },
  {
    label: 'VEF (Bs) Bolivar Fuerte',
    value: {
      id: '642f2305-41ab-4573-a159-8a8a80186972',
      code: 'VEF',
      symbol: 'Bs',
      name: 'Bolivar Fuerte',
      plural: 'Bolivares Fuerte',
    },
  },
  {
    label: 'VND (₫) Dong',
    value: {
      id: '758b37ac-d148-458c-963b-82e18233c336',
      code: 'VND',
      symbol: '₫',
      name: 'Dong',
      plural: 'Dongs',
    },
  },
  {
    label: 'VUV (VT) Vatu',
    value: {
      id: '16e5945f-a1f3-464e-9d1d-1370244cdc3e',
      code: 'VUV',
      symbol: 'VT',
      name: 'Vatu',
      plural: 'Vatu',
    },
  },
  {
    label: 'WST ($) Samoan Tala',
    value: {
      id: 'c0a1d434-5933-4d46-8f7e-f2b82953e448',
      code: 'WST',
      symbol: '$',
      name: 'Samoan Tala',
      plural: 'Samoan Talas',
    },
  },
  {
    label: 'XAF (Fr) CFA Franc - BEAC',
    value: {
      id: 'af5624bf-130a-4a28-a693-c4cc5aea4d33',
      code: 'XAF',
      symbol: 'Fr',
      name: 'CFA Franc - BEAC',
      plural: 'CFA Francs - BEAC',
    },
  },
  {
    label: 'XCD ($) Eastern Caribbean dollar',
    value: {
      id: '272d45e2-c1e2-4cab-81b6-7aa8a231c1c2',
      code: 'XCD',
      symbol: '$',
      name: 'Eastern Caribbean dollar',
      plural: 'Eastern Caribbean dollars',
    },
  },
  {
    label: 'XOF (CFA) CFA franc - BCEAO',
    value: {
      id: '6f1c38c7-265b-4638-ad64-e1021994ba7b',
      code: 'XOF',
      symbol: 'CFA',
      name: 'CFA franc - BCEAO',
      plural: 'CFA francs - BCEAO',
    },
  },
  {
    label: 'XPF (₣) Comptoirs Francais du Pacifique Francs',
    value: {
      id: '8f177137-c51b-422f-8e31-b670473f9d6d',
      code: 'XPF',
      symbol: '₣',
      name: 'Comptoirs Francais du Pacifique Francs',
      plural: 'Comptoirs Francais du Pacifique Francs',
    },
  },
  {
    label: 'YER (﷼) Yemeni rial',
    value: {
      id: '9fde7105-1b2f-4b73-9974-d41ae34f6a0d',
      code: 'YER',
      symbol: '﷼',
      name: 'Yemeni rial',
      plural: 'Yemeni rials',
    },
  },
  {
    label: 'ZAR (R) Rand',
    value: {
      id: 'f3f1b636-1c6b-4e9c-9adc-b49bd259a458',
      code: 'ZAR',
      symbol: 'R',
      name: 'Rand',
      plural: 'Rand',
    },
  },
  {
    label: 'ZMK (ZK) Kwacha',
    value: {
      id: '61999f71-6adc-4bc6-9180-1a5e8b5a88dd',
      code: 'ZMK',
      symbol: 'ZK',
      name: 'Kwacha',
      plural: 'Kwachas',
    },
  },
  {
    label: 'ZMW (ZK) Kwacha',
    value: {
      id: 'b01b783e-8714-4e71-99bf-43d7a6268c38',
      code: 'ZMW',
      symbol: 'ZK',
      name: 'Kwacha',
      plural: 'Kwachas',
    },
  },
  {
    label: 'BTC (BTC) Bitcoin',
    value: {
      id: 'd3d13b1f-4df8-4ff5-860b-a0aeb8cc0f9e',
      code: 'BTC',
      symbol: 'BTC',
      name: 'Bitcoin',
      plural: 'Bitcoin',
    },
  },
  {
    label: 'BYN (BYN) New Belarusian Ruble',
    value: {
      id: 'f1c3853c-0c52-4ab3-a3b8-48c000f1c576',
      code: 'BYN',
      symbol: 'BYN',
      name: 'New Belarusian Ruble',
      plural: 'New Belarusian Ruble',
    },
  },
  {
    label: 'CLF (CLF) Chilean Unit of Account (UF)',
    value: {
      id: '0a954638-a7fb-4d31-bc6f-e19fb44f5179',
      code: 'CLF',
      symbol: 'CLF',
      name: 'Chilean Unit of Account (UF)',
      plural: 'Chilean Unit of Account (UF)',
    },
  },
  {
    label: 'CUC (CUC) Cuban Convertible Peso',
    value: {
      id: 'dc162ebe-6053-46b8-ab6f-f4018aa1eec4',
      code: 'CUC',
      symbol: 'CUC',
      name: 'Cuban Convertible Peso',
      plural: 'Cuban Convertible Peso',
    },
  },
  {
    label: 'GGP (GGP) Guernsey Pound',
    value: {
      id: '440f2c50-f557-496c-9cc2-0521b62fbbbc',
      code: 'GGP',
      symbol: 'GGP',
      name: 'Guernsey Pound',
      plural: 'Guernsey Pound',
    },
  },
  {
    label: 'IMP (IMP) Manx pound',
    value: {
      id: '6cb5c294-6553-44b3-88ef-940158055566',
      code: 'IMP',
      symbol: 'IMP',
      name: 'Manx pound',
      plural: 'Manx pound',
    },
  },
  {
    label: 'JEP (JEP) Jersey Pound',
    value: {
      id: 'dd10cd9d-25ff-41fc-a871-e7899f12a894',
      code: 'JEP',
      symbol: 'JEP',
      name: 'Jersey Pound',
      plural: 'Jersey Pound',
    },
  },
  {
    label: 'KPW (KPW) North Korean Won',
    value: {
      id: '770e52a2-31e9-4e27-8d1a-6596f01facf3',
      code: 'KPW',
      symbol: 'KPW',
      name: 'North Korean Won',
      plural: 'North Korean Won',
    },
  },
  {
    label: 'TMT (TMT) Turkmenistani Manat',
    value: {
      id: 'd9a151f1-2d84-43de-bad1-852a98966b27',
      code: 'TMT',
      symbol: 'TMT',
      name: 'Turkmenistani Manat',
      plural: 'Turkmenistani Manat',
    },
  },
  {
    label: 'XAG (XAG) Silver (troy ounce)',
    value: {
      id: 'aaaca4d8-42ac-4a8b-ac2c-8d3480f9ae89',
      code: 'XAG',
      symbol: 'XAG',
      name: 'Silver (troy ounce)',
      plural: 'Silver (troy ounce)',
    },
  },
  {
    label: 'XAU (XAU) Gold (troy ounce)',
    value: {
      id: '85e34a93-fdc7-4517-a41d-53a385228530',
      code: 'XAU',
      symbol: 'XAU',
      name: 'Gold (troy ounce)',
      plural: 'Gold (troy ounce)',
    },
  },
  {
    label: 'XDR (XDR) Special Drawing Rights',
    value: {
      id: '453be0a7-339a-4eb2-aa2a-f9e64303cde9',
      code: 'XDR',
      symbol: 'XDR',
      name: 'Special Drawing Rights',
      plural: 'Special Drawing Rights',
    },
  },
  {
    label: 'ZWL (ZWL) Zimbabwean Dollar',
    value: {
      id: '6fef1a1a-d93e-4d5d-9dbf-e89bf2838655',
      code: 'ZWL',
      symbol: 'ZWL',
      name: 'Zimbabwean Dollar',
      plural: 'Zimbabwean Dollar',
    },
  },
];

export const countries = [
  {
    id: 'dcd9b6d7-0220-4785-9b49-613104115c54',
    name: 'Afghanistan',
    data: {
      area: 652230,
      cioc: 'AFG',
      gini: 27.8,
      latlng: [33, 65],
      region: 'Asia',
      borders: ['IRN', 'PAK', 'TKM', 'UZB', 'TJK', 'CHN'],
      capital: 'Kabul',
      demonym: 'Afghan',
      languages: [
        {
          name: 'Pashto',
          iso639_1: 'ps',
          iso639_2: 'pus',
          nativeName: 'پښتو',
        },
        {
          name: 'Uzbek',
          iso639_1: 'uz',
          iso639_2: 'uzb',
          nativeName: 'Oʻzbek',
        },
        {
          name: 'Turkmen',
          iso639_1: 'tk',
          iso639_2: 'tuk',
          nativeName: 'Türkmen',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+04:30'],
      alpha2Code: 'AF',
      currencies: [
        {
          code: 'AFN',
          name: 'Afghan afghani',
          symbol: '؋',
        },
      ],
      nativeName: 'افغانستان',
      population: 27657145,
      numericCode: '004',
      altSpellings: ['AF', 'Afġānistān'],
      translations: {
        br: 'Afeganistão',
        de: 'Afghanistan',
        es: 'Afganistán',
        fa: 'افغانستان',
        fr: 'Afghanistan',
        hr: 'Afganistan',
        it: 'Afghanistan',
        ja: 'アフガニスタン',
        nl: 'Afghanistan',
        pt: 'Afeganistão',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.af'],
    },
    alpha3: 'AFG',
    areaCode: '93',
    picture: 'https://restcountries.eu/data/afg.svg',
  },
  {
    id: '38e54d9d-aafc-41db-b4b0-1d6298cf42a7',
    name: 'Åland Islands',
    data: {
      area: 1580,
      cioc: '',
      gini: null,
      latlng: [60.116667, 19.9],
      region: 'Europe',
      borders: [],
      capital: 'Mariehamn',
      demonym: 'Ålandish',
      languages: [
        {
          name: 'Swedish',
          iso639_1: 'sv',
          iso639_2: 'swe',
          nativeName: 'svenska',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'AX',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Åland',
      population: 28875,
      numericCode: '248',
      altSpellings: ['AX', 'Aaland', 'Aland', 'Ahvenanmaa'],
      translations: {
        br: 'Ilhas de Aland',
        de: 'Åland',
        es: 'Alandia',
        fa: 'جزایر الند',
        fr: 'Åland',
        hr: 'Ålandski otoci',
        it: 'Isole Aland',
        ja: 'オーランド諸島',
        nl: 'Ålandeilanden',
        pt: 'Ilhas de Aland',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ax'],
    },
    alpha3: 'ALA',
    areaCode: '358',
    picture: 'https://restcountries.eu/data/ala.svg',
  },
  {
    id: 'f6b40792-33f9-4b64-a351-aa5a6399ac33',
    name: 'Albania',
    data: {
      area: 28748,
      cioc: 'ALB',
      gini: 34.5,
      latlng: [41, 20],
      region: 'Europe',
      borders: ['MNE', 'GRC', 'MKD', 'KOS'],
      capital: 'Tirana',
      demonym: 'Albanian',
      languages: [
        {
          name: 'Albanian',
          iso639_1: 'sq',
          iso639_2: 'sqi',
          nativeName: 'Shqip',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'AL',
      currencies: [
        {
          code: 'ALL',
          name: 'Albanian lek',
          symbol: 'L',
        },
      ],
      nativeName: 'Shqipëria',
      population: 2886026,
      numericCode: '008',
      altSpellings: ['AL', 'Shqipëri', 'Shqipëria', 'Shqipnia'],
      translations: {
        br: 'Albânia',
        de: 'Albanien',
        es: 'Albania',
        fa: 'آلبانی',
        fr: 'Albanie',
        hr: 'Albanija',
        it: 'Albania',
        ja: 'アルバニア',
        nl: 'Albanië',
        pt: 'Albânia',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.al'],
    },
    alpha3: 'ALB',
    areaCode: '355',
    picture: 'https://restcountries.eu/data/alb.svg',
  },
  {
    id: '144c0b6f-20d5-4daf-9b35-2135ce09760b',
    name: 'Algeria',
    data: {
      area: 2381741,
      cioc: 'ALG',
      gini: 35.3,
      latlng: [28, 3],
      region: 'Africa',
      borders: ['TUN', 'LBY', 'NER', 'ESH', 'MRT', 'MLI', 'MAR'],
      capital: 'Algiers',
      demonym: 'Algerian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'DZ',
      currencies: [
        {
          code: 'DZD',
          name: 'Algerian dinar',
          symbol: 'د.ج',
        },
      ],
      nativeName: 'الجزائر',
      population: 40400000,
      numericCode: '012',
      altSpellings: ['DZ', 'Dzayer', 'Algérie'],
      translations: {
        br: 'Argélia',
        de: 'Algerien',
        es: 'Argelia',
        fa: 'الجزایر',
        fr: 'Algérie',
        hr: 'Alžir',
        it: 'Algeria',
        ja: 'アルジェリア',
        nl: 'Algerije',
        pt: 'Argélia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.dz'],
    },
    alpha3: 'DZA',
    areaCode: '213',
    picture: 'https://restcountries.eu/data/dza.svg',
  },
  {
    id: '531cbe5c-282a-4e59-b60a-d1d19a6c15d7',
    name: 'American Samoa',
    data: {
      area: 199,
      cioc: 'ASA',
      gini: null,
      latlng: [-14.33333333, -170],
      region: 'Oceania',
      borders: [],
      capital: 'Pago Pago',
      demonym: 'American Samoan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Samoan',
          iso639_1: 'sm',
          iso639_2: 'smo',
          nativeName: "gagana fa'a Samoa",
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC-11:00'],
      alpha2Code: 'AS',
      currencies: [
        {
          code: 'USD',
          name: 'United State Dollar',
          symbol: '$',
        },
      ],
      nativeName: 'American Samoa',
      population: 57100,
      numericCode: '016',
      altSpellings: ['AS', 'Amerika Sāmoa', 'Amelika Sāmoa', 'Sāmoa Amelika'],
      translations: {
        br: 'Samoa Americana',
        de: 'Amerikanisch-Samoa',
        es: 'Samoa Americana',
        fa: 'ساموآی آمریکا',
        fr: 'Samoa américaines',
        hr: 'Američka Samoa',
        it: 'Samoa Americane',
        ja: 'アメリカ領サモア',
        nl: 'Amerikaans Samoa',
        pt: 'Samoa Americana',
      },
      regionalBlocs: [],
      topLevelDomain: ['.as'],
    },
    alpha3: 'ASM',
    areaCode: '1684',
    picture: 'https://restcountries.eu/data/asm.svg',
  },
  {
    id: '9f401ec5-3863-4c0d-96f6-2cfc632279ad',
    name: 'Andorra',
    data: {
      area: 468,
      cioc: 'AND',
      gini: null,
      latlng: [42.5, 1.5],
      region: 'Europe',
      borders: ['FRA', 'ESP'],
      capital: 'Andorra la Vella',
      demonym: 'Andorran',
      languages: [
        {
          name: 'Catalan',
          iso639_1: 'ca',
          iso639_2: 'cat',
          nativeName: 'català',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'AD',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Andorra',
      population: 78014,
      numericCode: '020',
      altSpellings: ['AD', 'Principality of Andorra', "Principat d'Andorra"],
      translations: {
        br: 'Andorra',
        de: 'Andorra',
        es: 'Andorra',
        fa: 'آندورا',
        fr: 'Andorre',
        hr: 'Andora',
        it: 'Andorra',
        ja: 'アンドラ',
        nl: 'Andorra',
        pt: 'Andorra',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ad'],
    },
    alpha3: 'AND',
    areaCode: '376',
    picture: 'https://restcountries.eu/data/and.svg',
  },
  {
    id: '675b2b46-a306-4d47-b6ee-706e80017955',
    name: 'Angola',
    data: {
      area: 1246700,
      cioc: 'ANG',
      gini: 58.6,
      latlng: [-12.5, 18.5],
      region: 'Africa',
      borders: ['COG', 'COD', 'ZMB', 'NAM'],
      capital: 'Luanda',
      demonym: 'Angolan',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'AO',
      currencies: [
        {
          code: 'AOA',
          name: 'Angolan kwanza',
          symbol: 'Kz',
        },
      ],
      nativeName: 'Angola',
      population: 25868000,
      numericCode: '024',
      altSpellings: ['AO', 'República de Angola', "ʁɛpublika de an'ɡɔla"],
      translations: {
        br: 'Angola',
        de: 'Angola',
        es: 'Angola',
        fa: 'آنگولا',
        fr: 'Angola',
        hr: 'Angola',
        it: 'Angola',
        ja: 'アンゴラ',
        nl: 'Angola',
        pt: 'Angola',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ao'],
    },
    alpha3: 'AGO',
    areaCode: '244',
    picture: 'https://restcountries.eu/data/ago.svg',
  },
  {
    id: 'fc922d64-fdb7-4c87-997d-3c2e157baf18',
    name: 'Anguilla',
    data: {
      area: 91,
      cioc: '',
      gini: null,
      latlng: [18.25, -63.16666666],
      region: 'Americas',
      borders: [],
      capital: 'The Valley',
      demonym: 'Anguillian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'AI',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Anguilla',
      population: 13452,
      numericCode: '660',
      altSpellings: ['AI'],
      translations: {
        br: 'Anguila',
        de: 'Anguilla',
        es: 'Anguilla',
        fa: 'آنگویلا',
        fr: 'Anguilla',
        hr: 'Angvila',
        it: 'Anguilla',
        ja: 'アンギラ',
        nl: 'Anguilla',
        pt: 'Anguila',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ai'],
    },
    alpha3: 'AIA',
    areaCode: '1264',
    picture: 'https://restcountries.eu/data/aia.svg',
  },
  {
    id: '7b89f7c5-f08e-4a19-978c-9ca04fad9f3c',
    name: 'Antarctica',
    data: {
      area: 14000000,
      cioc: '',
      gini: null,
      latlng: [-74.65, 4.48],
      region: 'Polar',
      borders: [],
      capital: '',
      demonym: '',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: '',
      timezones: [
        'UTC-03:00',
        'UTC+03:00',
        'UTC+05:00',
        'UTC+06:00',
        'UTC+07:00',
        'UTC+08:00',
        'UTC+10:00',
        'UTC+12:00',
      ],
      alpha2Code: 'AQ',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
      ],
      nativeName: 'Antarctica',
      population: 1000,
      numericCode: '010',
      altSpellings: [],
      translations: {
        br: 'Antártida',
        de: 'Antarktika',
        es: 'Antártida',
        fa: 'جنوبگان',
        fr: 'Antarctique',
        hr: 'Antarktika',
        it: 'Antartide',
        ja: '南極大陸',
        nl: 'Antarctica',
        pt: 'Antárctida',
      },
      regionalBlocs: [],
      topLevelDomain: ['.aq'],
    },
    alpha3: 'ATA',
    areaCode: '672',
    picture: 'https://restcountries.eu/data/ata.svg',
  },
  {
    id: '4b089e7a-413b-4890-9a23-4a9715221e6c',
    name: 'Antigua and Barbuda',
    data: {
      area: 442,
      cioc: 'ANT',
      gini: null,
      latlng: [17.05, -61.8],
      region: 'Americas',
      borders: [],
      capital: "Saint John's",
      demonym: 'Antiguan, Barbudan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'AG',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Antigua and Barbuda',
      population: 86295,
      numericCode: '028',
      altSpellings: ['AG'],
      translations: {
        br: 'Antígua e Barbuda',
        de: 'Antigua und Barbuda',
        es: 'Antigua y Barbuda',
        fa: 'آنتیگوا و باربودا',
        fr: 'Antigua-et-Barbuda',
        hr: 'Antigva i Barbuda',
        it: 'Antigua e Barbuda',
        ja: 'アンティグア・バーブーダ',
        nl: 'Antigua en Barbuda',
        pt: 'Antígua e Barbuda',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ag'],
    },
    alpha3: 'ATG',
    areaCode: '1268',
    picture: 'https://restcountries.eu/data/atg.svg',
  },
  {
    id: '19c0d222-93ae-44e1-91b9-d2b03acb385f',
    name: 'Armenia',
    data: {
      area: 29743,
      cioc: 'ARM',
      gini: 30.9,
      latlng: [40, 45],
      region: 'Asia',
      borders: ['AZE', 'GEO', 'IRN', 'TUR'],
      capital: 'Yerevan',
      demonym: 'Armenian',
      languages: [
        {
          name: 'Armenian',
          iso639_1: 'hy',
          iso639_2: 'hye',
          nativeName: 'Հայերեն',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+04:00'],
      alpha2Code: 'AM',
      currencies: [
        {
          code: 'AMD',
          name: 'Armenian dram',
          symbol: null,
        },
      ],
      nativeName: 'Հայաստան',
      population: 2994400,
      numericCode: '051',
      altSpellings: [
        'AM',
        'Hayastan',
        'Republic of Armenia',
        'Հայաստանի Հանրապետություն',
      ],
      translations: {
        br: 'Armênia',
        de: 'Armenien',
        es: 'Armenia',
        fa: 'ارمنستان',
        fr: 'Arménie',
        hr: 'Armenija',
        it: 'Armenia',
        ja: 'アルメニア',
        nl: 'Armenië',
        pt: 'Arménia',
      },
      regionalBlocs: [
        {
          name: 'Eurasian Economic Union',
          acronym: 'EEU',
          otherNames: [],
          otherAcronyms: ['EAEU'],
        },
      ],
      topLevelDomain: ['.am'],
    },
    alpha3: 'ARM',
    areaCode: '374',
    picture: 'https://restcountries.eu/data/arm.svg',
  },
  {
    id: 'f0f32076-5a83-4a46-91fa-e4b79c4998c5',
    name: 'Aruba',
    data: {
      area: 180,
      cioc: 'ARU',
      gini: null,
      latlng: [12.5, -69.96666666],
      region: 'Americas',
      borders: [],
      capital: 'Oranjestad',
      demonym: 'Aruban',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
        {
          name: '(Eastern) Punjabi',
          iso639_1: 'pa',
          iso639_2: 'pan',
          nativeName: 'ਪੰਜਾਬੀ',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'AW',
      currencies: [
        {
          code: 'AWG',
          name: 'Aruban florin',
          symbol: 'ƒ',
        },
      ],
      nativeName: 'Aruba',
      population: 107394,
      numericCode: '533',
      altSpellings: ['AW'],
      translations: {
        br: 'Aruba',
        de: 'Aruba',
        es: 'Aruba',
        fa: 'آروبا',
        fr: 'Aruba',
        hr: 'Aruba',
        it: 'Aruba',
        ja: 'アルバ',
        nl: 'Aruba',
        pt: 'Aruba',
      },
      regionalBlocs: [],
      topLevelDomain: ['.aw'],
    },
    alpha3: 'ABW',
    areaCode: '297',
    picture: 'https://restcountries.eu/data/abw.svg',
  },
  {
    id: '92e7e7c9-913c-42eb-8271-3923a6cd0c4c',
    name: 'Argentina',
    data: {
      area: 2780400,
      cioc: 'ARG',
      gini: 44.5,
      latlng: [-34, -64],
      region: 'Americas',
      borders: ['BOL', 'BRA', 'CHL', 'PRY', 'URY'],
      capital: 'Buenos Aires',
      demonym: 'Argentinean',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
        {
          name: 'Guaraní',
          iso639_1: 'gn',
          iso639_2: 'grn',
          nativeName: "Avañe'ẽ",
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-03:00'],
      alpha2Code: 'AR',
      currencies: [
        {
          code: 'ARS',
          name: 'Argentine peso',
          symbol: '$',
        },
      ],
      nativeName: 'Argentina',
      population: 43590400,
      numericCode: '032',
      altSpellings: ['AR', 'Argentine Republic', 'República Argentina'],
      translations: {
        br: 'Argentina',
        de: 'Argentinien',
        es: 'Argentina',
        fa: 'آرژانتین',
        fr: 'Argentine',
        hr: 'Argentina',
        it: 'Argentina',
        ja: 'アルゼンチン',
        nl: 'Argentinië',
        pt: 'Argentina',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.ar'],
    },
    alpha3: 'ARG',
    areaCode: '54',
    picture: 'https://restcountries.eu/data/arg.svg',
  },
  {
    id: '10475f9d-0935-405e-88fd-5261746d5355',
    name: 'Australia',
    data: {
      area: 7692024,
      cioc: 'AUS',
      gini: 30.5,
      latlng: [-27, 133],
      region: 'Oceania',
      borders: [],
      capital: 'Canberra',
      demonym: 'Australian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Australia and New Zealand',
      timezones: [
        'UTC+05:00',
        'UTC+06:30',
        'UTC+07:00',
        'UTC+08:00',
        'UTC+09:30',
        'UTC+10:00',
        'UTC+10:30',
        'UTC+11:30',
      ],
      alpha2Code: 'AU',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Australia',
      population: 24117360,
      numericCode: '036',
      altSpellings: ['AU'],
      translations: {
        br: 'Austrália',
        de: 'Australien',
        es: 'Australia',
        fa: 'استرالیا',
        fr: 'Australie',
        hr: 'Australija',
        it: 'Australia',
        ja: 'オーストラリア',
        nl: 'Australië',
        pt: 'Austrália',
      },
      regionalBlocs: [],
      topLevelDomain: ['.au'],
    },
    alpha3: 'AUS',
    areaCode: '61',
    picture: 'https://restcountries.eu/data/aus.svg',
  },
  {
    id: '62fdddd5-c80f-4db0-bb52-17a8a8198841',
    name: 'Belarus',
    data: {
      area: 207600,
      cioc: 'BLR',
      gini: 26.5,
      latlng: [53, 28],
      region: 'Europe',
      borders: ['LVA', 'LTU', 'POL', 'RUS', 'UKR'],
      capital: 'Minsk',
      demonym: 'Belarusian',
      languages: [
        {
          name: 'Belarusian',
          iso639_1: 'be',
          iso639_2: 'bel',
          nativeName: 'беларуская мова',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+03:00'],
      alpha2Code: 'BY',
      currencies: [
        {
          code: 'BYN',
          name: 'New Belarusian ruble',
          symbol: 'Br',
        },
        {
          code: 'BYR',
          name: 'Old Belarusian ruble',
          symbol: 'Br',
        },
      ],
      nativeName: 'Белару́сь',
      population: 9498700,
      numericCode: '112',
      altSpellings: [
        'BY',
        'Bielaruś',
        'Republic of Belarus',
        'Белоруссия',
        'Республика Беларусь',
        'Belorussiya',
        'Respublika Belarus’',
      ],
      translations: {
        br: 'Bielorrússia',
        de: 'Weißrussland',
        es: 'Bielorrusia',
        fa: 'بلاروس',
        fr: 'Biélorussie',
        hr: 'Bjelorusija',
        it: 'Bielorussia',
        ja: 'ベラルーシ',
        nl: 'Wit-Rusland',
        pt: 'Bielorrússia',
      },
      regionalBlocs: [
        {
          name: 'Eurasian Economic Union',
          acronym: 'EEU',
          otherNames: [],
          otherAcronyms: ['EAEU'],
        },
      ],
      topLevelDomain: ['.by'],
    },
    alpha3: 'BLR',
    areaCode: '375',
    picture: 'https://restcountries.eu/data/blr.svg',
  },
  {
    id: '3bb90ea6-4441-40cb-bd2a-9e982944fadb',
    name: 'Belgium',
    data: {
      area: 30528,
      cioc: 'BEL',
      gini: 33,
      latlng: [50.83333333, 4],
      region: 'Europe',
      borders: ['FRA', 'DEU', 'LUX', 'NLD'],
      capital: 'Brussels',
      demonym: 'Belgian',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'BE',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'België',
      population: 11319511,
      numericCode: '056',
      altSpellings: [
        'BE',
        'België',
        'Belgie',
        'Belgien',
        'Belgique',
        'Kingdom of Belgium',
        'Koninkrijk België',
        'Royaume de Belgique',
        'Königreich Belgien',
      ],
      translations: {
        br: 'Bélgica',
        de: 'Belgien',
        es: 'Bélgica',
        fa: 'بلژیک',
        fr: 'Belgique',
        hr: 'Belgija',
        it: 'Belgio',
        ja: 'ベルギー',
        nl: 'België',
        pt: 'Bélgica',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.be'],
    },
    alpha3: 'BEL',
    areaCode: '32',
    picture: 'https://restcountries.eu/data/bel.svg',
  },
  {
    id: '672849ba-dc6b-465c-a652-672cadf6aebe',
    name: 'Belize',
    data: {
      area: 22966,
      cioc: 'BIZ',
      gini: 53.1,
      latlng: [17.25, -88.75],
      region: 'Americas',
      borders: ['GTM', 'MEX'],
      capital: 'Belmopan',
      demonym: 'Belizean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'BZ',
      currencies: [
        {
          code: 'BZD',
          name: 'Belize dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Belize',
      population: 370300,
      numericCode: '084',
      altSpellings: ['BZ'],
      translations: {
        br: 'Belize',
        de: 'Belize',
        es: 'Belice',
        fa: 'بلیز',
        fr: 'Belize',
        hr: 'Belize',
        it: 'Belize',
        ja: 'ベリーズ',
        nl: 'Belize',
        pt: 'Belize',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.bz'],
    },
    alpha3: 'BLZ',
    areaCode: '501',
    picture: 'https://restcountries.eu/data/blz.svg',
  },
  {
    id: '6baad4be-c543-437b-8aa7-3d196e774fe2',
    name: 'Bermuda',
    data: {
      area: 54,
      cioc: 'BER',
      gini: null,
      latlng: [32.33333333, -64.75],
      region: 'Americas',
      borders: [],
      capital: 'Hamilton',
      demonym: 'Bermudian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'BM',
      currencies: [
        {
          code: 'BMD',
          name: 'Bermudian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Bermuda',
      population: 61954,
      numericCode: '060',
      altSpellings: [
        'BM',
        'The Islands of Bermuda',
        'The Bermudas',
        'Somers Isles',
      ],
      translations: {
        br: 'Bermudas',
        de: 'Bermuda',
        es: 'Bermudas',
        fa: 'برمودا',
        fr: 'Bermudes',
        hr: 'Bermudi',
        it: 'Bermuda',
        ja: 'バミューダ',
        nl: 'Bermuda',
        pt: 'Bermudas',
      },
      regionalBlocs: [],
      topLevelDomain: ['.bm'],
    },
    alpha3: 'BMU',
    areaCode: '1441',
    picture: 'https://restcountries.eu/data/bmu.svg',
  },
  {
    id: 'a7df879d-bc6d-4cf3-8f4a-5198d38bed6c',
    name: 'Austria',
    data: {
      area: 83871,
      cioc: 'AUT',
      gini: 26,
      latlng: [47.33333333, 13.33333333],
      region: 'Europe',
      borders: ['CZE', 'DEU', 'HUN', 'ITA', 'LIE', 'SVK', 'SVN', 'CHE'],
      capital: 'Vienna',
      demonym: 'Austrian',
      languages: [
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'AT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Österreich',
      population: 8725931,
      numericCode: '040',
      altSpellings: ['AT', 'Österreich', 'Osterreich', 'Oesterreich'],
      translations: {
        br: 'áustria',
        de: 'Österreich',
        es: 'Austria',
        fa: 'اتریش',
        fr: 'Autriche',
        hr: 'Austrija',
        it: 'Austria',
        ja: 'オーストリア',
        nl: 'Oostenrijk',
        pt: 'áustria',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.at'],
    },
    alpha3: 'AUT',
    areaCode: '43',
    picture: 'https://restcountries.eu/data/aut.svg',
  },
  {
    id: '4590de2b-54ef-411c-98ff-ae4c94ac3170',
    name: 'Azerbaijan',
    data: {
      area: 86600,
      cioc: 'AZE',
      gini: 33.7,
      latlng: [40.5, 47.5],
      region: 'Asia',
      borders: ['ARM', 'GEO', 'IRN', 'RUS', 'TUR'],
      capital: 'Baku',
      demonym: 'Azerbaijani',
      languages: [
        {
          name: 'Azerbaijani',
          iso639_1: 'az',
          iso639_2: 'aze',
          nativeName: 'azərbaycan dili',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+04:00'],
      alpha2Code: 'AZ',
      currencies: [
        {
          code: 'AZN',
          name: 'Azerbaijani manat',
          symbol: null,
        },
      ],
      nativeName: 'Azərbaycan',
      population: 9730500,
      numericCode: '031',
      altSpellings: ['AZ', 'Republic of Azerbaijan', 'Azərbaycan Respublikası'],
      translations: {
        br: 'Azerbaijão',
        de: 'Aserbaidschan',
        es: 'Azerbaiyán',
        fa: 'آذربایجان',
        fr: 'Azerbaïdjan',
        hr: 'Azerbajdžan',
        it: 'Azerbaijan',
        ja: 'アゼルバイジャン',
        nl: 'Azerbeidzjan',
        pt: 'Azerbaijão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.az'],
    },
    alpha3: 'AZE',
    areaCode: '994',
    picture: 'https://restcountries.eu/data/aze.svg',
  },
  {
    id: 'be5cb03f-906c-4921-968c-f17b64e4e826',
    name: 'Bahamas',
    data: {
      area: 13943,
      cioc: 'BAH',
      gini: null,
      latlng: [24.25, -76],
      region: 'Americas',
      borders: [],
      capital: 'Nassau',
      demonym: 'Bahamian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-05:00'],
      alpha2Code: 'BS',
      currencies: [
        {
          code: 'BSD',
          name: 'Bahamian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Bahamas',
      population: 378040,
      numericCode: '044',
      altSpellings: ['BS', 'Commonwealth of the Bahamas'],
      translations: {
        br: 'Bahamas',
        de: 'Bahamas',
        es: 'Bahamas',
        fa: 'باهاما',
        fr: 'Bahamas',
        hr: 'Bahami',
        it: 'Bahamas',
        ja: 'バハマ',
        nl: 'Bahama’s',
        pt: 'Baamas',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bs'],
    },
    alpha3: 'BHS',
    areaCode: '1242',
    picture: 'https://restcountries.eu/data/bhs.svg',
  },
  {
    id: '544a5abb-0252-4861-9c01-a4d057c35b32',
    name: 'Bahrain',
    data: {
      area: 765,
      cioc: 'BRN',
      gini: null,
      latlng: [26, 50.55],
      region: 'Asia',
      borders: [],
      capital: 'Manama',
      demonym: 'Bahraini',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'BH',
      currencies: [
        {
          code: 'BHD',
          name: 'Bahraini dinar',
          symbol: '.د.ب',
        },
      ],
      nativeName: '‏البحرين',
      population: 1404900,
      numericCode: '048',
      altSpellings: ['BH', 'Kingdom of Bahrain', 'Mamlakat al-Baḥrayn'],
      translations: {
        br: 'Bahrein',
        de: 'Bahrain',
        es: 'Bahrein',
        fa: 'بحرین',
        fr: 'Bahreïn',
        hr: 'Bahrein',
        it: 'Bahrein',
        ja: 'バーレーン',
        nl: 'Bahrein',
        pt: 'Barém',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bh'],
    },
    alpha3: 'BHR',
    areaCode: '973',
    picture: 'https://restcountries.eu/data/bhr.svg',
  },
  {
    id: '3458f822-8aff-4931-a743-308aaef4a3c0',
    name: 'Bangladesh',
    data: {
      area: 147570,
      cioc: 'BAN',
      gini: 32.1,
      latlng: [24, 90],
      region: 'Asia',
      borders: ['MMR', 'IND'],
      capital: 'Dhaka',
      demonym: 'Bangladeshi',
      languages: [
        {
          name: 'Bengali',
          iso639_1: 'bn',
          iso639_2: 'ben',
          nativeName: 'বাংলা',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+06:00'],
      alpha2Code: 'BD',
      currencies: [
        {
          code: 'BDT',
          name: 'Bangladeshi taka',
          symbol: '৳',
        },
      ],
      nativeName: 'Bangladesh',
      population: 161006790,
      numericCode: '050',
      altSpellings: [
        'BD',
        "People's Republic of Bangladesh",
        'Gônôprôjatôntri Bangladesh',
      ],
      translations: {
        br: 'Bangladesh',
        de: 'Bangladesch',
        es: 'Bangladesh',
        fa: 'بنگلادش',
        fr: 'Bangladesh',
        hr: 'Bangladeš',
        it: 'Bangladesh',
        ja: 'バングラデシュ',
        nl: 'Bangladesh',
        pt: 'Bangladeche',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bd'],
    },
    alpha3: 'BGD',
    areaCode: '880',
    picture: 'https://restcountries.eu/data/bgd.svg',
  },
  {
    id: '3c53fa5e-7f69-4a32-8536-51c50203b83c',
    name: 'Barbados',
    data: {
      area: 430,
      cioc: 'BAR',
      gini: null,
      latlng: [13.16666666, -59.53333333],
      region: 'Americas',
      borders: [],
      capital: 'Bridgetown',
      demonym: 'Barbadian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'BB',
      currencies: [
        {
          code: 'BBD',
          name: 'Barbadian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Barbados',
      population: 285000,
      numericCode: '052',
      altSpellings: ['BB'],
      translations: {
        br: 'Barbados',
        de: 'Barbados',
        es: 'Barbados',
        fa: 'باربادوس',
        fr: 'Barbade',
        hr: 'Barbados',
        it: 'Barbados',
        ja: 'バルバドス',
        nl: 'Barbados',
        pt: 'Barbados',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bb'],
    },
    alpha3: 'BRB',
    areaCode: '1246',
    picture: 'https://restcountries.eu/data/brb.svg',
  },
  {
    id: 'bd627c6a-9753-45f5-bc59-a4d061710375',
    name: 'Benin',
    data: {
      area: 112622,
      cioc: 'BEN',
      gini: 38.6,
      latlng: [9.5, 2.25],
      region: 'Africa',
      borders: ['BFA', 'NER', 'NGA', 'TGO'],
      capital: 'Porto-Novo',
      demonym: 'Beninese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'BJ',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Bénin',
      population: 10653654,
      numericCode: '204',
      altSpellings: ['BJ', 'Republic of Benin', 'République du Bénin'],
      translations: {
        br: 'Benin',
        de: 'Benin',
        es: 'Benín',
        fa: 'بنین',
        fr: 'Bénin',
        hr: 'Benin',
        it: 'Benin',
        ja: 'ベナン',
        nl: 'Benin',
        pt: 'Benim',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bj'],
    },
    alpha3: 'BEN',
    areaCode: '229',
    picture: 'https://restcountries.eu/data/ben.svg',
  },
  {
    id: '291dac24-a18f-48c2-8123-ff2fb216d108',
    name: 'Bosnia and Herzegovina',
    data: {
      area: 51209,
      cioc: 'BIH',
      gini: 36.2,
      latlng: [44, 18],
      region: 'Europe',
      borders: ['HRV', 'MNE', 'SRB'],
      capital: 'Sarajevo',
      demonym: 'Bosnian, Herzegovinian',
      languages: [
        {
          name: 'Bosnian',
          iso639_1: 'bs',
          iso639_2: 'bos',
          nativeName: 'bosanski jezik',
        },
        {
          name: 'Croatian',
          iso639_1: 'hr',
          iso639_2: 'hrv',
          nativeName: 'hrvatski jezik',
        },
        {
          name: 'Serbian',
          iso639_1: 'sr',
          iso639_2: 'srp',
          nativeName: 'српски језик',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'BA',
      currencies: [
        {
          code: 'BAM',
          name: 'Bosnia and Herzegovina convertible mark',
          symbol: null,
        },
      ],
      nativeName: 'Bosna i Hercegovina',
      population: 3531159,
      numericCode: '070',
      altSpellings: ['BA', 'Bosnia-Herzegovina', 'Босна и Херцеговина'],
      translations: {
        br: 'Bósnia e Herzegovina',
        de: 'Bosnien und Herzegowina',
        es: 'Bosnia y Herzegovina',
        fa: 'بوسنی و هرزگوین',
        fr: 'Bosnie-Herzégovine',
        hr: 'Bosna i Hercegovina',
        it: 'Bosnia ed Erzegovina',
        ja: 'ボスニア・ヘルツェゴビナ',
        nl: 'Bosnië en Herzegovina',
        pt: 'Bósnia e Herzegovina',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ba'],
    },
    alpha3: 'BIH',
    areaCode: '387',
    picture: 'https://restcountries.eu/data/bih.svg',
  },
  {
    id: 'b5ddf376-f8a2-4a4f-89e6-b0fcfc840402',
    name: 'Botswana',
    data: {
      area: 582000,
      cioc: 'BOT',
      gini: 61,
      latlng: [-22, 24],
      region: 'Africa',
      borders: ['NAM', 'ZAF', 'ZMB', 'ZWE'],
      capital: 'Gaborone',
      demonym: 'Motswana',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Tswana',
          iso639_1: 'tn',
          iso639_2: 'tsn',
          nativeName: 'Setswana',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'BW',
      currencies: [
        {
          code: 'BWP',
          name: 'Botswana pula',
          symbol: 'P',
        },
      ],
      nativeName: 'Botswana',
      population: 2141206,
      numericCode: '072',
      altSpellings: ['BW', 'Republic of Botswana', 'Lefatshe la Botswana'],
      translations: {
        br: 'Botsuana',
        de: 'Botswana',
        es: 'Botswana',
        fa: 'بوتسوانا',
        fr: 'Botswana',
        hr: 'Bocvana',
        it: 'Botswana',
        ja: 'ボツワナ',
        nl: 'Botswana',
        pt: 'Botsuana',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bw'],
    },
    alpha3: 'BWA',
    areaCode: '267',
    picture: 'https://restcountries.eu/data/bwa.svg',
  },
  {
    id: '155b9b06-e594-4c24-a6b5-175025f5d8a7',
    name: 'Bouvet Island',
    data: {
      area: 49,
      cioc: '',
      gini: null,
      latlng: [-54.43333333, 3.4],
      region: '',
      borders: [],
      capital: '',
      demonym: '',
      languages: [
        {
          name: 'Norwegian',
          iso639_1: 'no',
          iso639_2: 'nor',
          nativeName: 'Norsk',
        },
        {
          name: 'Norwegian Bokmål',
          iso639_1: 'nb',
          iso639_2: 'nob',
          nativeName: 'Norsk bokmål',
        },
        {
          name: 'Norwegian Nynorsk',
          iso639_1: 'nn',
          iso639_2: 'nno',
          nativeName: 'Norsk nynorsk',
        },
      ],
      subregion: '',
      timezones: ['UTC+01:00'],
      alpha2Code: 'BV',
      currencies: [
        {
          code: 'NOK',
          name: 'Norwegian krone',
          symbol: 'kr',
        },
      ],
      nativeName: 'Bouvetøya',
      population: 0,
      numericCode: '074',
      altSpellings: ['BV', 'Bouvetøya', 'Bouvet-øya'],
      translations: {
        br: 'Ilha Bouvet',
        de: 'Bouvetinsel',
        es: 'Isla Bouvet',
        fa: 'جزیره بووه',
        fr: 'Île Bouvet',
        hr: 'Otok Bouvet',
        it: 'Isola Bouvet',
        ja: 'ブーベ島',
        nl: 'Bouveteiland',
        pt: 'Ilha Bouvet',
      },
      regionalBlocs: [],
      topLevelDomain: ['.bv'],
    },
    alpha3: 'BVT',
    areaCode: '',
    picture: 'https://restcountries.eu/data/bvt.svg',
  },
  {
    id: '5540f35f-5844-4cb3-a828-d371aaf35a40',
    name: 'Brazil',
    data: {
      area: 8515767,
      cioc: 'BRA',
      gini: 54.7,
      latlng: [-10, -55],
      region: 'Americas',
      borders: [
        'ARG',
        'BOL',
        'COL',
        'GUF',
        'GUY',
        'PRY',
        'PER',
        'SUR',
        'URY',
        'VEN',
      ],
      capital: 'Brasília',
      demonym: 'Brazilian',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-05:00', 'UTC-04:00', 'UTC-03:00', 'UTC-02:00'],
      alpha2Code: 'BR',
      currencies: [
        {
          code: 'BRL',
          name: 'Brazilian real',
          symbol: 'R$',
        },
      ],
      nativeName: 'Brasil',
      population: 206135893,
      numericCode: '076',
      altSpellings: [
        'BR',
        'Brasil',
        'Federative Republic of Brazil',
        'República Federativa do Brasil',
      ],
      translations: {
        br: 'Brasil',
        de: 'Brasilien',
        es: 'Brasil',
        fa: 'برزیل',
        fr: 'Brésil',
        hr: 'Brazil',
        it: 'Brasile',
        ja: 'ブラジル',
        nl: 'Brazilië',
        pt: 'Brasil',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.br'],
    },
    alpha3: 'BRA',
    areaCode: '55',
    picture: 'https://restcountries.eu/data/bra.svg',
  },
  {
    id: 'c49a3160-a526-4dde-9fc7-e01420a416a4',
    name: 'Christmas Island',
    data: {
      area: 135,
      cioc: '',
      gini: null,
      latlng: [-10.5, 105.66666666],
      region: 'Oceania',
      borders: [],
      capital: 'Flying Fish Cove',
      demonym: 'Christmas Island',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Australia and New Zealand',
      timezones: ['UTC+07:00'],
      alpha2Code: 'CX',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Christmas Island',
      population: 2072,
      numericCode: '162',
      altSpellings: ['CX', 'Territory of Christmas Island'],
      translations: {
        br: 'Ilha Christmas',
        de: 'Weihnachtsinsel',
        es: 'Isla de Navidad',
        fa: 'جزیره کریسمس',
        fr: 'Île Christmas',
        hr: 'Božićni otok',
        it: 'Isola di Natale',
        ja: 'クリスマス島',
        nl: 'Christmaseiland',
        pt: 'Ilha do Natal',
      },
      regionalBlocs: [],
      topLevelDomain: ['.cx'],
    },
    alpha3: 'CXR',
    areaCode: '61',
    picture: 'https://restcountries.eu/data/cxr.svg',
  },
  {
    id: '372cee3c-5796-4986-b3ed-1be515c88a4b',
    name: 'Bhutan',
    data: {
      area: 38394,
      cioc: 'BHU',
      gini: 38.1,
      latlng: [27.5, 90.5],
      region: 'Asia',
      borders: ['CHN', 'IND'],
      capital: 'Thimphu',
      demonym: 'Bhutanese',
      languages: [
        {
          name: 'Dzongkha',
          iso639_1: 'dz',
          iso639_2: 'dzo',
          nativeName: 'རྫོང་ཁ',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+06:00'],
      alpha2Code: 'BT',
      currencies: [
        {
          code: 'BTN',
          name: 'Bhutanese ngultrum',
          symbol: 'Nu.',
        },
        {
          code: 'INR',
          name: 'Indian rupee',
          symbol: '₹',
        },
      ],
      nativeName: 'ʼbrug-yul',
      population: 775620,
      numericCode: '064',
      altSpellings: ['BT', 'Kingdom of Bhutan'],
      translations: {
        br: 'Butão',
        de: 'Bhutan',
        es: 'Bután',
        fa: 'بوتان',
        fr: 'Bhoutan',
        hr: 'Butan',
        it: 'Bhutan',
        ja: 'ブータン',
        nl: 'Bhutan',
        pt: 'Butão',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bt'],
    },
    alpha3: 'BTN',
    areaCode: '975',
    picture: 'https://restcountries.eu/data/btn.svg',
  },
  {
    id: '652ff1d6-d9fa-4721-a40c-85e51310764f',
    name: 'Bolivia (Plurinational State of)',
    data: {
      area: 1098581,
      cioc: 'BOL',
      gini: 56.3,
      latlng: [-17, -65],
      region: 'Americas',
      borders: ['ARG', 'BRA', 'CHL', 'PRY', 'PER'],
      capital: 'Sucre',
      demonym: 'Bolivian',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
        {
          name: 'Aymara',
          iso639_1: 'ay',
          iso639_2: 'aym',
          nativeName: 'aymar aru',
        },
        {
          name: 'Quechua',
          iso639_1: 'qu',
          iso639_2: 'que',
          nativeName: 'Runa Simi',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'BO',
      currencies: [
        {
          code: 'BOB',
          name: 'Bolivian boliviano',
          symbol: 'Bs.',
        },
      ],
      nativeName: 'Bolivia',
      population: 10985059,
      numericCode: '068',
      altSpellings: [
        'BO',
        'Buliwya',
        'Wuliwya',
        'Plurinational State of Bolivia',
        'Estado Plurinacional de Bolivia',
        'Buliwya Mamallaqta',
        'Wuliwya Suyu',
        'Tetã Volívia',
      ],
      translations: {
        br: 'Bolívia',
        de: 'Bolivien',
        es: 'Bolivia',
        fa: 'بولیوی',
        fr: 'Bolivie',
        hr: 'Bolivija',
        it: 'Bolivia',
        ja: 'ボリビア多民族国',
        nl: 'Bolivia',
        pt: 'Bolívia',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.bo'],
    },
    alpha3: 'BOL',
    areaCode: '591',
    picture: 'https://restcountries.eu/data/bol.svg',
  },
  {
    id: 'd4d57c0f-c144-4d83-a7a0-86dfdedca375',
    name: 'Bonaire, Sint Eustatius and Saba',
    data: {
      area: 294,
      cioc: null,
      gini: null,
      latlng: [12.15, -68.266667],
      region: 'Americas',
      borders: [],
      capital: 'Kralendijk',
      demonym: 'Dutch',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'BQ',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Bonaire',
      population: 17408,
      numericCode: '535',
      altSpellings: ['BQ', 'Boneiru'],
      translations: {
        br: 'Bonaire',
        de: 'Bonaire, Sint Eustatius und Saba',
        es: null,
        fa: 'بونیر',
        fr: 'Bonaire, Saint-Eustache et Saba',
        hr: null,
        it: 'Bonaire, Saint-Eustache e Saba',
        ja: null,
        nl: null,
        pt: 'Bonaire',
      },
      regionalBlocs: [],
      topLevelDomain: ['.an', '.nl'],
    },
    alpha3: 'BES',
    areaCode: '5997',
    picture: 'https://restcountries.eu/data/bes.svg',
  },
  {
    id: 'a5c2a20b-088f-4188-8b9f-393828bf5136',
    name: 'British Indian Ocean Territory',
    data: {
      area: 60,
      cioc: '',
      gini: null,
      latlng: [-6, 71.5],
      region: 'Africa',
      borders: [],
      capital: 'Diego Garcia',
      demonym: 'Indian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+06:00'],
      alpha2Code: 'IO',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'British Indian Ocean Territory',
      population: 3000,
      numericCode: '086',
      altSpellings: ['IO'],
      translations: {
        br: 'Território Britânico do Oceano íÍdico',
        de: 'Britisches Territorium im Indischen Ozean',
        es: 'Territorio Británico del Océano Índico',
        fa: 'قلمرو بریتانیا در اقیانوس هند',
        fr: "Territoire britannique de l'océan Indien",
        hr: 'Britanski Indijskooceanski teritorij',
        it: "Territorio britannico dell'oceano indiano",
        ja: 'イギリス領インド洋地域',
        nl: 'Britse Gebieden in de Indische Oceaan',
        pt: 'Território Britânico do Oceano Índico',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.io'],
    },
    alpha3: 'IOT',
    areaCode: '246',
    picture: 'https://restcountries.eu/data/iot.svg',
  },
  {
    id: 'fae86872-7ad4-4d83-8d18-22040b795d3f',
    name: 'United States Minor Outlying Islands',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [],
      region: 'Americas',
      borders: [],
      capital: '',
      demonym: 'American',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern America',
      timezones: ['UTC-11:00', 'UTC-10:00', 'UTC+12:00'],
      alpha2Code: 'UM',
      currencies: [
        {
          code: 'USD',
          name: 'United States Dollar',
          symbol: '$',
        },
      ],
      nativeName: 'United States Minor Outlying Islands',
      population: 300,
      numericCode: '581',
      altSpellings: ['UM'],
      translations: {
        br: 'Ilhas Menores Distantes dos Estados Unidos',
        de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
        es: 'Islas Ultramarinas Menores de Estados Unidos',
        fa: 'جزایر کوچک حاشیه‌ای ایالات متحده آمریکا',
        fr: 'Îles mineures éloignées des États-Unis',
        hr: 'Mali udaljeni otoci SAD-a',
        it: "Isole minori esterne degli Stati Uniti d'America",
        ja: '合衆国領有小離島',
        nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
        pt: 'Ilhas Menores Distantes dos Estados Unidos',
      },
      regionalBlocs: [],
      topLevelDomain: ['.us'],
    },
    alpha3: 'UMI',
    areaCode: '',
    picture: 'https://restcountries.eu/data/umi.svg',
  },
  {
    id: '834a373f-e5c0-4d78-8dc6-c633191fe3e4',
    name: 'Virgin Islands (British)',
    data: {
      area: 151,
      cioc: 'IVB',
      gini: null,
      latlng: [18.431383, -64.62305],
      region: 'Americas',
      borders: [],
      capital: 'Road Town',
      demonym: 'Virgin Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'VG',
      currencies: [
        {
          code: null,
          name: '[D]',
          symbol: '$',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'British Virgin Islands',
      population: 28514,
      numericCode: '092',
      altSpellings: ['VG'],
      translations: {
        br: 'Ilhas Virgens Britânicas',
        de: 'Britische Jungferninseln',
        es: 'Islas Vírgenes del Reino Unido',
        fa: 'جزایر ویرجین بریتانیا',
        fr: 'Îles Vierges britanniques',
        hr: 'Britanski Djevičanski Otoci',
        it: 'Isole Vergini Britanniche',
        ja: 'イギリス領ヴァージン諸島',
        nl: 'Britse Maagdeneilanden',
        pt: 'Ilhas Virgens Britânicas',
      },
      regionalBlocs: [],
      topLevelDomain: ['.vg'],
    },
    alpha3: 'VGB',
    areaCode: '1284',
    picture: 'https://restcountries.eu/data/vgb.svg',
  },
  {
    id: '9aae3fc0-0b0f-4d84-aaf9-703cad83c99a',
    name: 'Virgin Islands (U.S.)',
    data: {
      area: 346.36,
      cioc: 'ISV',
      gini: null,
      latlng: [18.34, -64.93],
      region: 'Americas',
      borders: [],
      capital: 'Charlotte Amalie',
      demonym: 'Virgin Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'VI',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Virgin Islands of the United States',
      population: 114743,
      numericCode: '850',
      altSpellings: [
        'VI',
        'USVI',
        'American Virgin Islands',
        'U.S. Virgin Islands',
      ],
      translations: {
        br: 'Ilhas Virgens Americanas',
        de: 'Amerikanische Jungferninseln',
        es: 'Islas Vírgenes de los Estados Unidos',
        fa: 'جزایر ویرجین آمریکا',
        fr: 'Îles Vierges des États-Unis',
        hr: null,
        it: 'Isole Vergini americane',
        ja: 'アメリカ領ヴァージン諸島',
        nl: 'Verenigde Staten Maagdeneilanden',
        pt: 'Ilhas Virgens Americanas',
      },
      regionalBlocs: [],
      topLevelDomain: ['.vi'],
    },
    alpha3: 'VIR',
    areaCode: '1 340',
    picture: 'https://restcountries.eu/data/vir.svg',
  },
  {
    id: 'fc15244e-32b1-404a-8743-bc76314dfadb',
    name: 'Brunei Darussalam',
    data: {
      area: 5765,
      cioc: 'BRU',
      gini: null,
      latlng: [4.5, 114.66666666],
      region: 'Asia',
      borders: ['MYS'],
      capital: 'Bandar Seri Begawan',
      demonym: 'Bruneian',
      languages: [
        {
          name: 'Malay',
          iso639_1: 'ms',
          iso639_2: 'msa',
          nativeName: 'bahasa Melayu',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'BN',
      currencies: [
        {
          code: 'BND',
          name: 'Brunei dollar',
          symbol: '$',
        },
        {
          code: 'SGD',
          name: 'Singapore dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Negara Brunei Darussalam',
      population: 411900,
      numericCode: '096',
      altSpellings: ['BN', 'Nation of Brunei', ' the Abode of Peace'],
      translations: {
        br: 'Brunei',
        de: 'Brunei',
        es: 'Brunei',
        fa: 'برونئی',
        fr: 'Brunei',
        hr: 'Brunej',
        it: 'Brunei',
        ja: 'ブルネイ・ダルサラーム',
        nl: 'Brunei',
        pt: 'Brunei',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bn'],
    },
    alpha3: 'BRN',
    areaCode: '673',
    picture: 'https://restcountries.eu/data/brn.svg',
  },
  {
    id: '98c4f25f-16ba-43c1-bf7a-5571548c39e7',
    name: 'Bulgaria',
    data: {
      area: 110879,
      cioc: 'BUL',
      gini: 28.2,
      latlng: [43, 25],
      region: 'Europe',
      borders: ['GRC', 'MKD', 'ROU', 'SRB', 'TUR'],
      capital: 'Sofia',
      demonym: 'Bulgarian',
      languages: [
        {
          name: 'Bulgarian',
          iso639_1: 'bg',
          iso639_2: 'bul',
          nativeName: 'български език',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'BG',
      currencies: [
        {
          code: 'BGN',
          name: 'Bulgarian lev',
          symbol: 'лв',
        },
      ],
      nativeName: 'България',
      population: 7153784,
      numericCode: '100',
      altSpellings: ['BG', 'Republic of Bulgaria', 'Република България'],
      translations: {
        br: 'Bulgária',
        de: 'Bulgarien',
        es: 'Bulgaria',
        fa: 'بلغارستان',
        fr: 'Bulgarie',
        hr: 'Bugarska',
        it: 'Bulgaria',
        ja: 'ブルガリア',
        nl: 'Bulgarije',
        pt: 'Bulgária',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bg'],
    },
    alpha3: 'BGR',
    areaCode: '359',
    picture: 'https://restcountries.eu/data/bgr.svg',
  },
  {
    id: '12853024-4c45-46a8-8509-aafb4472ff25',
    name: 'Burkina Faso',
    data: {
      area: 272967,
      cioc: 'BUR',
      gini: 39.8,
      latlng: [13, -2],
      region: 'Africa',
      borders: ['BEN', 'CIV', 'GHA', 'MLI', 'NER', 'TGO'],
      capital: 'Ouagadougou',
      demonym: 'Burkinabe',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Fula',
          iso639_1: 'ff',
          iso639_2: 'ful',
          nativeName: 'Fulfulde',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'BF',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Burkina Faso',
      population: 19034397,
      numericCode: '854',
      altSpellings: ['BF'],
      translations: {
        br: 'Burkina Faso',
        de: 'Burkina Faso',
        es: 'Burkina Faso',
        fa: 'بورکینافاسو',
        fr: 'Burkina Faso',
        hr: 'Burkina Faso',
        it: 'Burkina Faso',
        ja: 'ブルキナファソ',
        nl: 'Burkina Faso',
        pt: 'Burquina Faso',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bf'],
    },
    alpha3: 'BFA',
    areaCode: '226',
    picture: 'https://restcountries.eu/data/bfa.svg',
  },
  {
    id: '1c58b4c6-3d67-40ce-9373-d3e6abe72222',
    name: 'Burundi',
    data: {
      area: 27834,
      cioc: 'BDI',
      gini: 33.3,
      latlng: [-3.5, 30],
      region: 'Africa',
      borders: ['COD', 'RWA', 'TZA'],
      capital: 'Bujumbura',
      demonym: 'Burundian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Kirundi',
          iso639_1: 'rn',
          iso639_2: 'run',
          nativeName: 'Ikirundi',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'BI',
      currencies: [
        {
          code: 'BIF',
          name: 'Burundian franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Burundi',
      population: 10114505,
      numericCode: '108',
      altSpellings: [
        'BI',
        'Republic of Burundi',
        "Republika y'Uburundi",
        'République du Burundi',
      ],
      translations: {
        br: 'Burundi',
        de: 'Burundi',
        es: 'Burundi',
        fa: 'بوروندی',
        fr: 'Burundi',
        hr: 'Burundi',
        it: 'Burundi',
        ja: 'ブルンジ',
        nl: 'Burundi',
        pt: 'Burúndi',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.bi'],
    },
    alpha3: 'BDI',
    areaCode: '257',
    picture: 'https://restcountries.eu/data/bdi.svg',
  },
  {
    id: '393eab20-b073-4d5e-b552-86f03279cf63',
    name: 'Cambodia',
    data: {
      area: 181035,
      cioc: 'CAM',
      gini: 37.9,
      latlng: [13, 105],
      region: 'Asia',
      borders: ['LAO', 'THA', 'VNM'],
      capital: 'Phnom Penh',
      demonym: 'Cambodian',
      languages: [
        {
          name: 'Khmer',
          iso639_1: 'km',
          iso639_2: 'khm',
          nativeName: 'ខ្មែរ',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+07:00'],
      alpha2Code: 'KH',
      currencies: [
        {
          code: 'KHR',
          name: 'Cambodian riel',
          symbol: '៛',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Kâmpŭchéa',
      population: 15626444,
      numericCode: '116',
      altSpellings: ['KH', 'Kingdom of Cambodia'],
      translations: {
        br: 'Camboja',
        de: 'Kambodscha',
        es: 'Camboya',
        fa: 'کامبوج',
        fr: 'Cambodge',
        hr: 'Kambodža',
        it: 'Cambogia',
        ja: 'カンボジア',
        nl: 'Cambodja',
        pt: 'Camboja',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.kh'],
    },
    alpha3: 'KHM',
    areaCode: '855',
    picture: 'https://restcountries.eu/data/khm.svg',
  },
  {
    id: '2d1f778a-fd97-4380-bd3e-07272134543f',
    name: 'Cameroon',
    data: {
      area: 475442,
      cioc: 'CMR',
      gini: 38.9,
      latlng: [6, 12],
      region: 'Africa',
      borders: ['CAF', 'TCD', 'COG', 'GNQ', 'GAB', 'NGA'],
      capital: 'Yaoundé',
      demonym: 'Cameroonian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'CM',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Cameroon',
      population: 22709892,
      numericCode: '120',
      altSpellings: ['CM', 'Republic of Cameroon', 'République du Cameroun'],
      translations: {
        br: 'Camarões',
        de: 'Kamerun',
        es: 'Camerún',
        fa: 'کامرون',
        fr: 'Cameroun',
        hr: 'Kamerun',
        it: 'Camerun',
        ja: 'カメルーン',
        nl: 'Kameroen',
        pt: 'Camarões',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cm'],
    },
    alpha3: 'CMR',
    areaCode: '237',
    picture: 'https://restcountries.eu/data/cmr.svg',
  },
  {
    id: '1c4f84fe-6298-421b-92db-b3acbc8c06a4',
    name: 'Canada',
    data: {
      area: 9984670,
      cioc: 'CAN',
      gini: 32.6,
      latlng: [60, -95],
      region: 'Americas',
      borders: ['USA'],
      capital: 'Ottawa',
      demonym: 'Canadian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Northern America',
      timezones: [
        'UTC-08:00',
        'UTC-07:00',
        'UTC-06:00',
        'UTC-05:00',
        'UTC-04:00',
        'UTC-03:30',
      ],
      alpha2Code: 'CA',
      currencies: [
        {
          code: 'CAD',
          name: 'Canadian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Canada',
      population: 36155487,
      numericCode: '124',
      altSpellings: ['CA'],
      translations: {
        br: 'Canadá',
        de: 'Kanada',
        es: 'Canadá',
        fa: 'کانادا',
        fr: 'Canada',
        hr: 'Kanada',
        it: 'Canada',
        ja: 'カナダ',
        nl: 'Canada',
        pt: 'Canadá',
      },
      regionalBlocs: [
        {
          name: 'North American Free Trade Agreement',
          acronym: 'NAFTA',
          otherNames: [
            'Tratado de Libre Comercio de América del Norte',
            'Accord de Libre-échange Nord-Américain',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ca'],
    },
    alpha3: 'CAN',
    areaCode: '1',
    picture: 'https://restcountries.eu/data/can.svg',
  },
  {
    id: '3b296c5a-ebb7-47ca-8caa-bcf2aee7a0a0',
    name: 'Cocos (Keeling) Islands',
    data: {
      area: 14,
      cioc: '',
      gini: null,
      latlng: [-12.5, 96.83333333],
      region: 'Oceania',
      borders: [],
      capital: 'West Island',
      demonym: 'Cocos Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Australia and New Zealand',
      timezones: ['UTC+06:30'],
      alpha2Code: 'CC',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Cocos (Keeling) Islands',
      population: 550,
      numericCode: '166',
      altSpellings: [
        'CC',
        'Territory of the Cocos (Keeling) Islands',
        'Keeling Islands',
      ],
      translations: {
        br: 'Ilhas Cocos',
        de: 'Kokosinseln',
        es: 'Islas Cocos o Islas Keeling',
        fa: 'جزایر کوکوس',
        fr: 'Îles Cocos',
        hr: 'Kokosovi Otoci',
        it: 'Isole Cocos e Keeling',
        ja: 'ココス（キーリング）諸島',
        nl: 'Cocoseilanden',
        pt: 'Ilhas dos Cocos',
      },
      regionalBlocs: [],
      topLevelDomain: ['.cc'],
    },
    alpha3: 'CCK',
    areaCode: '61',
    picture: 'https://restcountries.eu/data/cck.svg',
  },
  {
    id: '88ca2c0c-725a-4c66-8bda-ecfa18f05cf3',
    name: 'Cabo Verde',
    data: {
      area: 4033,
      cioc: 'CPV',
      gini: 50.5,
      latlng: [16, -24],
      region: 'Africa',
      borders: [],
      capital: 'Praia',
      demonym: 'Cape Verdian',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC-01:00'],
      alpha2Code: 'CV',
      currencies: [
        {
          code: 'CVE',
          name: 'Cape Verdean escudo',
          symbol: 'Esc',
        },
      ],
      nativeName: 'Cabo Verde',
      population: 531239,
      numericCode: '132',
      altSpellings: ['CV', 'Republic of Cabo Verde', 'República de Cabo Verde'],
      translations: {
        br: 'Cabo Verde',
        de: 'Kap Verde',
        es: 'Cabo Verde',
        fa: 'کیپ ورد',
        fr: 'Cap Vert',
        hr: 'Zelenortska Republika',
        it: 'Capo Verde',
        ja: 'カーボベルデ',
        nl: 'Kaapverdië',
        pt: 'Cabo Verde',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cv'],
    },
    alpha3: 'CPV',
    areaCode: '238',
    picture: 'https://restcountries.eu/data/cpv.svg',
  },
  {
    id: 'adc57248-bcad-4580-8830-68221d31d1a0',
    name: 'Cayman Islands',
    data: {
      area: 264,
      cioc: 'CAY',
      gini: null,
      latlng: [19.5, -80.5],
      region: 'Americas',
      borders: [],
      capital: 'George Town',
      demonym: 'Caymanian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-05:00'],
      alpha2Code: 'KY',
      currencies: [
        {
          code: 'KYD',
          name: 'Cayman Islands dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Cayman Islands',
      population: 58238,
      numericCode: '136',
      altSpellings: ['KY'],
      translations: {
        br: 'Ilhas Cayman',
        de: 'Kaimaninseln',
        es: 'Islas Caimán',
        fa: 'جزایر کیمن',
        fr: 'Îles Caïmans',
        hr: 'Kajmanski otoci',
        it: 'Isole Cayman',
        ja: 'ケイマン諸島',
        nl: 'Caymaneilanden',
        pt: 'Ilhas Caimão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ky'],
    },
    alpha3: 'CYM',
    areaCode: '1345',
    picture: 'https://restcountries.eu/data/cym.svg',
  },
  {
    id: '9df080e0-aa65-4f17-8924-a2ac697e2cea',
    name: 'Central African Republic',
    data: {
      area: 622984,
      cioc: 'CAF',
      gini: 56.3,
      latlng: [7, 21],
      region: 'Africa',
      borders: ['CMR', 'TCD', 'COD', 'COG', 'SSD', 'SDN'],
      capital: 'Bangui',
      demonym: 'Central African',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Sango',
          iso639_1: 'sg',
          iso639_2: 'sag',
          nativeName: 'yângâ tî sängö',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'CF',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Ködörösêse tî Bêafrîka',
      population: 4998000,
      numericCode: '140',
      altSpellings: [
        'CF',
        'Central African Republic',
        'République centrafricaine',
      ],
      translations: {
        br: 'República Centro-Africana',
        de: 'Zentralafrikanische Republik',
        es: 'República Centroafricana',
        fa: 'جمهوری آفریقای مرکزی',
        fr: 'République centrafricaine',
        hr: 'Srednjoafrička Republika',
        it: 'Repubblica Centrafricana',
        ja: '中央アフリカ共和国',
        nl: 'Centraal-Afrikaanse Republiek',
        pt: 'República Centro-Africana',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cf'],
    },
    alpha3: 'CAF',
    areaCode: '236',
    picture: 'https://restcountries.eu/data/caf.svg',
  },
  {
    id: '3953817f-b55c-438d-8291-3a26a6a75f96',
    name: 'Chad',
    data: {
      area: 1284000,
      cioc: 'CHA',
      gini: 39.8,
      latlng: [15, 19],
      region: 'Africa',
      borders: ['CMR', 'CAF', 'LBY', 'NER', 'NGA', 'SSD'],
      capital: "N'Djamena",
      demonym: 'Chadian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'TD',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Tchad',
      population: 14497000,
      numericCode: '148',
      altSpellings: ['TD', 'Tchad', 'Republic of Chad', 'République du Tchad'],
      translations: {
        br: 'Chade',
        de: 'Tschad',
        es: 'Chad',
        fa: 'چاد',
        fr: 'Tchad',
        hr: 'Čad',
        it: 'Ciad',
        ja: 'チャド',
        nl: 'Tsjaad',
        pt: 'Chade',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.td'],
    },
    alpha3: 'TCD',
    areaCode: '235',
    picture: 'https://restcountries.eu/data/tcd.svg',
  },
  {
    id: '4cde2f3a-57c8-4660-a722-8a10e8b4a79b',
    name: 'Chile',
    data: {
      area: 756102,
      cioc: 'CHI',
      gini: 52.1,
      latlng: [-30, -71],
      region: 'Americas',
      borders: ['ARG', 'BOL', 'PER'],
      capital: 'Santiago',
      demonym: 'Chilean',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-06:00', 'UTC-04:00'],
      alpha2Code: 'CL',
      currencies: [
        {
          code: 'CLP',
          name: 'Chilean peso',
          symbol: '$',
        },
      ],
      nativeName: 'Chile',
      population: 18191900,
      numericCode: '152',
      altSpellings: ['CL', 'Republic of Chile', 'República de Chile'],
      translations: {
        br: 'Chile',
        de: 'Chile',
        es: 'Chile',
        fa: 'شیلی',
        fr: 'Chili',
        hr: 'Čile',
        it: 'Cile',
        ja: 'チリ',
        nl: 'Chili',
        pt: 'Chile',
      },
      regionalBlocs: [
        {
          name: 'Pacific Alliance',
          acronym: 'PA',
          otherNames: ['Alianza del Pacífico'],
          otherAcronyms: [],
        },
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.cl'],
    },
    alpha3: 'CHL',
    areaCode: '56',
    picture: 'https://restcountries.eu/data/chl.svg',
  },
  {
    id: 'eb62af7c-3d6c-4dd4-ace8-dac44a47fba3',
    name: 'China',
    data: {
      area: 9640011,
      cioc: 'CHN',
      gini: 47,
      latlng: [35, 105],
      region: 'Asia',
      borders: [
        'AFG',
        'BTN',
        'MMR',
        'HKG',
        'IND',
        'KAZ',
        'PRK',
        'KGZ',
        'LAO',
        'MAC',
        'MNG',
        'PAK',
        'RUS',
        'TJK',
        'VNM',
      ],
      capital: 'Beijing',
      demonym: 'Chinese',
      languages: [
        {
          name: 'Chinese',
          iso639_1: 'zh',
          iso639_2: 'zho',
          nativeName: '中文 (Zhōngwén)',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'CN',
      currencies: [
        {
          code: 'CNY',
          name: 'Chinese yuan',
          symbol: '¥',
        },
      ],
      nativeName: '中国',
      population: 1377422166,
      numericCode: '156',
      altSpellings: [
        'CN',
        'Zhōngguó',
        'Zhongguo',
        'Zhonghua',
        "People's Republic of China",
        '中华人民共和国',
        'Zhōnghuá Rénmín Gònghéguó',
      ],
      translations: {
        br: 'China',
        de: 'China',
        es: 'China',
        fa: 'چین',
        fr: 'Chine',
        hr: 'Kina',
        it: 'Cina',
        ja: '中国',
        nl: 'China',
        pt: 'China',
      },
      regionalBlocs: [],
      topLevelDomain: ['.cn'],
    },
    alpha3: 'CHN',
    areaCode: '86',
    picture: 'https://restcountries.eu/data/chn.svg',
  },
  {
    id: 'b3608768-c56d-4d11-9b4c-856d1a3f927a',
    name: 'Colombia',
    data: {
      area: 1141748,
      cioc: 'COL',
      gini: 55.9,
      latlng: [4, -72],
      region: 'Americas',
      borders: ['BRA', 'ECU', 'PAN', 'PER', 'VEN'],
      capital: 'Bogotá',
      demonym: 'Colombian',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-05:00'],
      alpha2Code: 'CO',
      currencies: [
        {
          code: 'COP',
          name: 'Colombian peso',
          symbol: '$',
        },
      ],
      nativeName: 'Colombia',
      population: 48759958,
      numericCode: '170',
      altSpellings: ['CO', 'Republic of Colombia', 'República de Colombia'],
      translations: {
        br: 'Colômbia',
        de: 'Kolumbien',
        es: 'Colombia',
        fa: 'کلمبیا',
        fr: 'Colombie',
        hr: 'Kolumbija',
        it: 'Colombia',
        ja: 'コロンビア',
        nl: 'Colombia',
        pt: 'Colômbia',
      },
      regionalBlocs: [
        {
          name: 'Pacific Alliance',
          acronym: 'PA',
          otherNames: ['Alianza del Pacífico'],
          otherAcronyms: [],
        },
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.co'],
    },
    alpha3: 'COL',
    areaCode: '57',
    picture: 'https://restcountries.eu/data/col.svg',
  },
  {
    id: '4dc9964b-9192-4fbe-b2c4-c2b2cb707dd1',
    name: 'Comoros',
    data: {
      area: 1862,
      cioc: 'COM',
      gini: 64.3,
      latlng: [-12.16666666, 44.25],
      region: 'Africa',
      borders: [],
      capital: 'Moroni',
      demonym: 'Comoran',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'KM',
      currencies: [
        {
          code: 'KMF',
          name: 'Comorian franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Komori',
      population: 806153,
      numericCode: '174',
      altSpellings: [
        'KM',
        'Union of the Comoros',
        'Union des Comores',
        'Udzima wa Komori',
        'al-Ittiḥād al-Qumurī',
      ],
      translations: {
        br: 'Comores',
        de: 'Union der Komoren',
        es: 'Comoras',
        fa: 'کومور',
        fr: 'Comores',
        hr: 'Komori',
        it: 'Comore',
        ja: 'コモロ',
        nl: 'Comoren',
        pt: 'Comores',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.km'],
    },
    alpha3: 'COM',
    areaCode: '269',
    picture: 'https://restcountries.eu/data/com.svg',
  },
  {
    id: 'e4b98b49-c878-4faa-99c9-4b00deaa7f96',
    name: 'Congo',
    data: {
      area: 342000,
      cioc: 'CGO',
      gini: 47.3,
      latlng: [-1, 15],
      region: 'Africa',
      borders: ['AGO', 'CMR', 'CAF', 'COD', 'GAB'],
      capital: 'Brazzaville',
      demonym: 'Congolese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Lingala',
          iso639_1: 'ln',
          iso639_2: 'lin',
          nativeName: 'Lingála',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'CG',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'République du Congo',
      population: 4741000,
      numericCode: '178',
      altSpellings: ['CG', 'Congo-Brazzaville'],
      translations: {
        br: 'Congo',
        de: 'Kongo',
        es: 'Congo',
        fa: 'کنگو',
        fr: 'Congo',
        hr: 'Kongo',
        it: 'Congo',
        ja: 'コンゴ共和国',
        nl: 'Congo [Republiek]',
        pt: 'Congo',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cg'],
    },
    alpha3: 'COG',
    areaCode: '242',
    picture: 'https://restcountries.eu/data/cog.svg',
  },
  {
    id: '47fcf552-ddce-47a3-9e3f-5f1e3a7fdcd9',
    name: 'Congo (Democratic Republic of the)',
    data: {
      area: 2344858,
      cioc: 'COD',
      gini: null,
      latlng: [0, 25],
      region: 'Africa',
      borders: ['AGO', 'BDI', 'CAF', 'COG', 'RWA', 'SSD', 'TZA', 'UGA', 'ZMB'],
      capital: 'Kinshasa',
      demonym: 'Congolese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Lingala',
          iso639_1: 'ln',
          iso639_2: 'lin',
          nativeName: 'Lingála',
        },
        {
          name: 'Kongo',
          iso639_1: 'kg',
          iso639_2: 'kon',
          nativeName: 'Kikongo',
        },
        {
          name: 'Swahili',
          iso639_1: 'sw',
          iso639_2: 'swa',
          nativeName: 'Kiswahili',
        },
        {
          name: 'Luba-Katanga',
          iso639_1: 'lu',
          iso639_2: 'lub',
          nativeName: 'Tshiluba',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00', 'UTC+02:00'],
      alpha2Code: 'CD',
      currencies: [
        {
          code: 'CDF',
          name: 'Congolese franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'République démocratique du Congo',
      population: 85026000,
      numericCode: '180',
      altSpellings: ['CD', 'DR Congo', 'Congo-Kinshasa', 'DRC'],
      translations: {
        br: 'RD Congo',
        de: 'Kongo (Dem. Rep.)',
        es: 'Congo (Rep. Dem.)',
        fa: 'جمهوری کنگو',
        fr: 'Congo (Rép. dém.)',
        hr: 'Kongo, Demokratska Republika',
        it: 'Congo (Rep. Dem.)',
        ja: 'コンゴ民主共和国',
        nl: 'Congo [DRC]',
        pt: 'RD Congo',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cd'],
    },
    alpha3: 'COD',
    areaCode: '243',
    picture: 'https://restcountries.eu/data/cod.svg',
  },
  {
    id: '9a41e8e7-ecc1-4765-a772-94cd1e90b5fc',
    name: 'Cook Islands',
    data: {
      area: 236,
      cioc: 'COK',
      gini: null,
      latlng: [-21.23333333, -159.76666666],
      region: 'Oceania',
      borders: [],
      capital: 'Avarua',
      demonym: 'Cook Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC-10:00'],
      alpha2Code: 'CK',
      currencies: [
        {
          code: 'NZD',
          name: 'New Zealand dollar',
          symbol: '$',
        },
        {
          code: 'CKD',
          name: 'Cook Islands dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Cook Islands',
      population: 18100,
      numericCode: '184',
      altSpellings: ['CK', "Kūki 'Āirani"],
      translations: {
        br: 'Ilhas Cook',
        de: 'Cookinseln',
        es: 'Islas Cook',
        fa: 'جزایر کوک',
        fr: 'Îles Cook',
        hr: 'Cookovo Otočje',
        it: 'Isole Cook',
        ja: 'クック諸島',
        nl: 'Cookeilanden',
        pt: 'Ilhas Cook',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ck'],
    },
    alpha3: 'COK',
    areaCode: '682',
    picture: 'https://restcountries.eu/data/cok.svg',
  },
  {
    id: 'e31dffb9-f5d3-4bd4-8f31-368c47e6b20e',
    name: 'Costa Rica',
    data: {
      area: 51100,
      cioc: 'CRC',
      gini: 50.7,
      latlng: [10, -84],
      region: 'Americas',
      borders: ['NIC', 'PAN'],
      capital: 'San José',
      demonym: 'Costa Rican',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'CR',
      currencies: [
        {
          code: 'CRC',
          name: 'Costa Rican colón',
          symbol: '₡',
        },
      ],
      nativeName: 'Costa Rica',
      population: 4890379,
      numericCode: '188',
      altSpellings: ['CR', 'Republic of Costa Rica', 'República de Costa Rica'],
      translations: {
        br: 'Costa Rica',
        de: 'Costa Rica',
        es: 'Costa Rica',
        fa: 'کاستاریکا',
        fr: 'Costa Rica',
        hr: 'Kostarika',
        it: 'Costa Rica',
        ja: 'コスタリカ',
        nl: 'Costa Rica',
        pt: 'Costa Rica',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.cr'],
    },
    alpha3: 'CRI',
    areaCode: '506',
    picture: 'https://restcountries.eu/data/cri.svg',
  },
  {
    id: '2e98d983-29f2-4af7-bdde-ddf38946fcf0',
    name: 'Croatia',
    data: {
      area: 56594,
      cioc: 'CRO',
      gini: 33.7,
      latlng: [45.16666666, 15.5],
      region: 'Europe',
      borders: ['BIH', 'HUN', 'MNE', 'SRB', 'SVN'],
      capital: 'Zagreb',
      demonym: 'Croatian',
      languages: [
        {
          name: 'Croatian',
          iso639_1: 'hr',
          iso639_2: 'hrv',
          nativeName: 'hrvatski jezik',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'HR',
      currencies: [
        {
          code: 'HRK',
          name: 'Croatian kuna',
          symbol: 'kn',
        },
      ],
      nativeName: 'Hrvatska',
      population: 4190669,
      numericCode: '191',
      altSpellings: [
        'HR',
        'Hrvatska',
        'Republic of Croatia',
        'Republika Hrvatska',
      ],
      translations: {
        br: 'Croácia',
        de: 'Kroatien',
        es: 'Croacia',
        fa: 'کرواسی',
        fr: 'Croatie',
        hr: 'Hrvatska',
        it: 'Croazia',
        ja: 'クロアチア',
        nl: 'Kroatië',
        pt: 'Croácia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.hr'],
    },
    alpha3: 'HRV',
    areaCode: '385',
    picture: 'https://restcountries.eu/data/hrv.svg',
  },
  {
    id: '1862c029-e339-4631-9b84-6ee79f5caa69',
    name: 'Cuba',
    data: {
      area: 109884,
      cioc: 'CUB',
      gini: null,
      latlng: [21.5, -80],
      region: 'Americas',
      borders: [],
      capital: 'Havana',
      demonym: 'Cuban',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-05:00'],
      alpha2Code: 'CU',
      currencies: [
        {
          code: 'CUC',
          name: 'Cuban convertible peso',
          symbol: '$',
        },
        {
          code: 'CUP',
          name: 'Cuban peso',
          symbol: '$',
        },
      ],
      nativeName: 'Cuba',
      population: 11239004,
      numericCode: '192',
      altSpellings: ['CU', 'Republic of Cuba', 'República de Cuba'],
      translations: {
        br: 'Cuba',
        de: 'Kuba',
        es: 'Cuba',
        fa: 'کوبا',
        fr: 'Cuba',
        hr: 'Kuba',
        it: 'Cuba',
        ja: 'キューバ',
        nl: 'Cuba',
        pt: 'Cuba',
      },
      regionalBlocs: [],
      topLevelDomain: ['.cu'],
    },
    alpha3: 'CUB',
    areaCode: '53',
    picture: 'https://restcountries.eu/data/cub.svg',
  },
  {
    id: '7d03efda-2989-41ec-9e71-0a6fd0072cfb',
    name: 'Curaçao',
    data: {
      area: 444,
      cioc: '',
      gini: null,
      latlng: [12.116667, -68.933333],
      region: 'Americas',
      borders: [],
      capital: 'Willemstad',
      demonym: 'Dutch',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
        {
          name: '(Eastern) Punjabi',
          iso639_1: 'pa',
          iso639_2: 'pan',
          nativeName: 'ਪੰਜਾਬੀ',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'CW',
      currencies: [
        {
          code: 'ANG',
          name: 'Netherlands Antillean guilder',
          symbol: 'ƒ',
        },
      ],
      nativeName: 'Curaçao',
      population: 154843,
      numericCode: '531',
      altSpellings: [
        'CW',
        'Curacao',
        'Kòrsou',
        'Country of Curaçao',
        'Land Curaçao',
        'Pais Kòrsou',
      ],
      translations: {
        br: 'Curaçao',
        de: 'Curaçao',
        es: null,
        fa: 'کوراسائو',
        fr: 'Curaçao',
        hr: null,
        it: 'Curaçao',
        ja: null,
        nl: 'Curaçao',
        pt: 'Curaçao',
      },
      regionalBlocs: [],
      topLevelDomain: ['.cw'],
    },
    alpha3: 'CUW',
    areaCode: '599',
    picture: 'https://restcountries.eu/data/cuw.svg',
  },
  {
    id: 'b594ee9b-18b0-4a9d-813b-38d2e798b359',
    name: 'Cyprus',
    data: {
      area: 9251,
      cioc: 'CYP',
      gini: null,
      latlng: [35, 33],
      region: 'Europe',
      borders: ['GBR'],
      capital: 'Nicosia',
      demonym: 'Cypriot',
      languages: [
        {
          name: 'Greek (modern)',
          iso639_1: 'el',
          iso639_2: 'ell',
          nativeName: 'ελληνικά',
        },
        {
          name: 'Turkish',
          iso639_1: 'tr',
          iso639_2: 'tur',
          nativeName: 'Türkçe',
        },
        {
          name: 'Armenian',
          iso639_1: 'hy',
          iso639_2: 'hye',
          nativeName: 'Հայերեն',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'CY',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Κύπρος',
      population: 847000,
      numericCode: '196',
      altSpellings: [
        'CY',
        'Kýpros',
        'Kıbrıs',
        'Republic of Cyprus',
        'Κυπριακή Δημοκρατία',
        'Kıbrıs Cumhuriyeti',
      ],
      translations: {
        br: 'Chipre',
        de: 'Zypern',
        es: 'Chipre',
        fa: 'قبرس',
        fr: 'Chypre',
        hr: 'Cipar',
        it: 'Cipro',
        ja: 'キプロス',
        nl: 'Cyprus',
        pt: 'Chipre',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cy'],
    },
    alpha3: 'CYP',
    areaCode: '357',
    picture: 'https://restcountries.eu/data/cyp.svg',
  },
  {
    id: '3c131400-c10c-481e-be20-a5265452f671',
    name: 'Czech Republic',
    data: {
      area: 78865,
      cioc: 'CZE',
      gini: 26,
      latlng: [49.75, 15.5],
      region: 'Europe',
      borders: ['AUT', 'DEU', 'POL', 'SVK'],
      capital: 'Prague',
      demonym: 'Czech',
      languages: [
        {
          name: 'Czech',
          iso639_1: 'cs',
          iso639_2: 'ces',
          nativeName: 'čeština',
        },
        {
          name: 'Slovak',
          iso639_1: 'sk',
          iso639_2: 'slk',
          nativeName: 'slovenčina',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'CZ',
      currencies: [
        {
          code: 'CZK',
          name: 'Czech koruna',
          symbol: 'Kč',
        },
      ],
      nativeName: 'Česká republika',
      population: 10558524,
      numericCode: '203',
      altSpellings: ['CZ', 'Česká republika', 'Česko'],
      translations: {
        br: 'República Tcheca',
        de: 'Tschechische Republik',
        es: 'República Checa',
        fa: 'جمهوری چک',
        fr: 'République tchèque',
        hr: 'Češka',
        it: 'Repubblica Ceca',
        ja: 'チェコ',
        nl: 'Tsjechië',
        pt: 'República Checa',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.cz'],
    },
    alpha3: 'CZE',
    areaCode: '420',
    picture: 'https://restcountries.eu/data/cze.svg',
  },
  {
    id: '2b34cd71-a4e8-4102-8181-fb3cc3f22c9c',
    name: 'Denmark',
    data: {
      area: 43094,
      cioc: 'DEN',
      gini: 24,
      latlng: [56, 10],
      region: 'Europe',
      borders: ['DEU'],
      capital: 'Copenhagen',
      demonym: 'Danish',
      languages: [
        {
          name: 'Danish',
          iso639_1: 'da',
          iso639_2: 'dan',
          nativeName: 'dansk',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC-04:00', 'UTC-03:00', 'UTC-01:00', 'UTC', 'UTC+01:00'],
      alpha2Code: 'DK',
      currencies: [
        {
          code: 'DKK',
          name: 'Danish krone',
          symbol: 'kr',
        },
      ],
      nativeName: 'Danmark',
      population: 5717014,
      numericCode: '208',
      altSpellings: [
        'DK',
        'Danmark',
        'Kingdom of Denmark',
        'Kongeriget Danmark',
      ],
      translations: {
        br: 'Dinamarca',
        de: 'Dänemark',
        es: 'Dinamarca',
        fa: 'دانمارک',
        fr: 'Danemark',
        hr: 'Danska',
        it: 'Danimarca',
        ja: 'デンマーク',
        nl: 'Denemarken',
        pt: 'Dinamarca',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.dk'],
    },
    alpha3: 'DNK',
    areaCode: '45',
    picture: 'https://restcountries.eu/data/dnk.svg',
  },
  {
    id: '5d0e070c-ff31-476b-92f4-d3425618f79b',
    name: 'Djibouti',
    data: {
      area: 23200,
      cioc: 'DJI',
      gini: 40,
      latlng: [11.5, 43],
      region: 'Africa',
      borders: ['ERI', 'ETH', 'SOM'],
      capital: 'Djibouti',
      demonym: 'Djibouti',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'DJ',
      currencies: [
        {
          code: 'DJF',
          name: 'Djiboutian franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Djibouti',
      population: 900000,
      numericCode: '262',
      altSpellings: [
        'DJ',
        'Jabuuti',
        'Gabuuti',
        'Republic of Djibouti',
        'République de Djibouti',
        'Gabuutih Ummuuno',
        'Jamhuuriyadda Jabuuti',
      ],
      translations: {
        br: 'Djibuti',
        de: 'Dschibuti',
        es: 'Yibuti',
        fa: 'جیبوتی',
        fr: 'Djibouti',
        hr: 'Džibuti',
        it: 'Gibuti',
        ja: 'ジブチ',
        nl: 'Djibouti',
        pt: 'Djibuti',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.dj'],
    },
    alpha3: 'DJI',
    areaCode: '253',
    picture: 'https://restcountries.eu/data/dji.svg',
  },
  {
    id: 'df432973-c567-4c85-8d63-d3cf2849a207',
    name: 'Dominica',
    data: {
      area: 751,
      cioc: 'DMA',
      gini: null,
      latlng: [15.41666666, -61.33333333],
      region: 'Americas',
      borders: [],
      capital: 'Roseau',
      demonym: 'Dominican',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'DM',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Dominica',
      population: 71293,
      numericCode: '212',
      altSpellings: [
        'DM',
        'Dominique',
        'Wai‘tu kubuli',
        'Commonwealth of Dominica',
      ],
      translations: {
        br: 'Dominica',
        de: 'Dominica',
        es: 'Dominica',
        fa: 'دومینیکا',
        fr: 'Dominique',
        hr: 'Dominika',
        it: 'Dominica',
        ja: 'ドミニカ国',
        nl: 'Dominica',
        pt: 'Dominica',
      },
      regionalBlocs: [],
      topLevelDomain: ['.dm'],
    },
    alpha3: 'DMA',
    areaCode: '1767',
    picture: 'https://restcountries.eu/data/dma.svg',
  },
  {
    id: 'ff89c011-4288-4f00-8d3e-6de44360f519',
    name: 'Dominican Republic',
    data: {
      area: 48671,
      cioc: 'DOM',
      gini: 47.2,
      latlng: [19, -70.66666666],
      region: 'Americas',
      borders: ['HTI'],
      capital: 'Santo Domingo',
      demonym: 'Dominican',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'DO',
      currencies: [
        {
          code: 'DOP',
          name: 'Dominican peso',
          symbol: '$',
        },
      ],
      nativeName: 'República Dominicana',
      population: 10075045,
      numericCode: '214',
      altSpellings: ['DO'],
      translations: {
        br: 'República Dominicana',
        de: 'Dominikanische Republik',
        es: 'República Dominicana',
        fa: 'جمهوری دومینیکن',
        fr: 'République dominicaine',
        hr: 'Dominikanska Republika',
        it: 'Repubblica Dominicana',
        ja: 'ドミニカ共和国',
        nl: 'Dominicaanse Republiek',
        pt: 'República Dominicana',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.do'],
    },
    alpha3: 'DOM',
    areaCode: '1809',
    picture: 'https://restcountries.eu/data/dom.svg',
  },
  {
    id: '199d4aab-afea-4ccb-97bf-286b504f72b5',
    name: 'Ecuador',
    data: {
      area: 276841,
      cioc: 'ECU',
      gini: 49.3,
      latlng: [-2, -77.5],
      region: 'Americas',
      borders: ['COL', 'PER'],
      capital: 'Quito',
      demonym: 'Ecuadorean',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-06:00', 'UTC-05:00'],
      alpha2Code: 'EC',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Ecuador',
      population: 16545799,
      numericCode: '218',
      altSpellings: ['EC', 'Republic of Ecuador', 'República del Ecuador'],
      translations: {
        br: 'Equador',
        de: 'Ecuador',
        es: 'Ecuador',
        fa: 'اکوادور',
        fr: 'Équateur',
        hr: 'Ekvador',
        it: 'Ecuador',
        ja: 'エクアドル',
        nl: 'Ecuador',
        pt: 'Equador',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.ec'],
    },
    alpha3: 'ECU',
    areaCode: '593',
    picture: 'https://restcountries.eu/data/ecu.svg',
  },
  {
    id: 'acecf1c6-e0c3-4ff6-82c1-d2bcb52b9543',
    name: 'Germany',
    data: {
      area: 357114,
      cioc: 'GER',
      gini: 28.3,
      latlng: [51, 9],
      region: 'Europe',
      borders: ['AUT', 'BEL', 'CZE', 'DNK', 'FRA', 'LUX', 'NLD', 'POL', 'CHE'],
      capital: 'Berlin',
      demonym: 'German',
      languages: [
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'DE',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Deutschland',
      population: 81770900,
      numericCode: '276',
      altSpellings: [
        'DE',
        'Federal Republic of Germany',
        'Bundesrepublik Deutschland',
      ],
      translations: {
        br: 'Alemanha',
        de: 'Deutschland',
        es: 'Alemania',
        fa: 'آلمان',
        fr: 'Allemagne',
        hr: 'Njemačka',
        it: 'Germania',
        ja: 'ドイツ',
        nl: 'Duitsland',
        pt: 'Alemanha',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.de'],
    },
    alpha3: 'DEU',
    areaCode: '49',
    picture: 'https://restcountries.eu/data/deu.svg',
  },
  {
    id: '59886b52-c2ac-4106-9114-897a2abe86a7',
    name: 'Egypt',
    data: {
      area: 1002450,
      cioc: 'EGY',
      gini: 30.8,
      latlng: [27, 30],
      region: 'Africa',
      borders: ['ISR', 'LBY', 'SDN'],
      capital: 'Cairo',
      demonym: 'Egyptian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'EG',
      currencies: [
        {
          code: 'EGP',
          name: 'Egyptian pound',
          symbol: '£',
        },
      ],
      nativeName: 'مصر‎',
      population: 91290000,
      numericCode: '818',
      altSpellings: ['EG', 'Arab Republic of Egypt'],
      translations: {
        br: 'Egito',
        de: 'Ägypten',
        es: 'Egipto',
        fa: 'مصر',
        fr: 'Égypte',
        hr: 'Egipat',
        it: 'Egitto',
        ja: 'エジプト',
        nl: 'Egypte',
        pt: 'Egipto',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.eg'],
    },
    alpha3: 'EGY',
    areaCode: '20',
    picture: 'https://restcountries.eu/data/egy.svg',
  },
  {
    id: '18c2a492-ff15-411b-8028-9a338c1b72a1',
    name: 'El Salvador',
    data: {
      area: 21041,
      cioc: 'ESA',
      gini: 48.3,
      latlng: [13.83333333, -88.91666666],
      region: 'Americas',
      borders: ['GTM', 'HND'],
      capital: 'San Salvador',
      demonym: 'Salvadoran',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'SV',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'El Salvador',
      population: 6520675,
      numericCode: '222',
      altSpellings: [
        'SV',
        'Republic of El Salvador',
        'República de El Salvador',
      ],
      translations: {
        br: 'El Salvador',
        de: 'El Salvador',
        es: 'El Salvador',
        fa: 'السالوادور',
        fr: 'Salvador',
        hr: 'Salvador',
        it: 'El Salvador',
        ja: 'エルサルバドル',
        nl: 'El Salvador',
        pt: 'El Salvador',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.sv'],
    },
    alpha3: 'SLV',
    areaCode: '503',
    picture: 'https://restcountries.eu/data/slv.svg',
  },
  {
    id: '7bdd717a-8164-42be-81c4-82154e53e4f8',
    name: 'Equatorial Guinea',
    data: {
      area: 28051,
      cioc: 'GEQ',
      gini: null,
      latlng: [2, 10],
      region: 'Africa',
      borders: ['CMR', 'GAB'],
      capital: 'Malabo',
      demonym: 'Equatorial Guinean',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'GQ',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Guinea Ecuatorial',
      population: 1222442,
      numericCode: '226',
      altSpellings: [
        'GQ',
        'Republic of Equatorial Guinea',
        'República de Guinea Ecuatorial',
        'République de Guinée équatoriale',
        'República da Guiné Equatorial',
      ],
      translations: {
        br: 'Guiné Equatorial',
        de: 'Äquatorial-Guinea',
        es: 'Guinea Ecuatorial',
        fa: 'گینه استوایی',
        fr: 'Guinée-Équatoriale',
        hr: 'Ekvatorijalna Gvineja',
        it: 'Guinea Equatoriale',
        ja: '赤道ギニア',
        nl: 'Equatoriaal-Guinea',
        pt: 'Guiné Equatorial',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gq'],
    },
    alpha3: 'GNQ',
    areaCode: '240',
    picture: 'https://restcountries.eu/data/gnq.svg',
  },
  {
    id: '2c645689-461e-4437-9a3a-139ad9da7af4',
    name: 'Eritrea',
    data: {
      area: 117600,
      cioc: 'ERI',
      gini: null,
      latlng: [15, 39],
      region: 'Africa',
      borders: ['DJI', 'ETH', 'SDN'],
      capital: 'Asmara',
      demonym: 'Eritrean',
      languages: [
        {
          name: 'Tigrinya',
          iso639_1: 'ti',
          iso639_2: 'tir',
          nativeName: 'ትግርኛ',
        },
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'ER',
      currencies: [
        {
          code: 'ERN',
          name: 'Eritrean nakfa',
          symbol: 'Nfk',
        },
      ],
      nativeName: 'ኤርትራ',
      population: 5352000,
      numericCode: '232',
      altSpellings: [
        'ER',
        'State of Eritrea',
        'ሃገረ ኤርትራ',
        'Dawlat Iritriyá',
        'ʾErtrā',
        'Iritriyā',
        '',
      ],
      translations: {
        br: 'Eritreia',
        de: 'Eritrea',
        es: 'Eritrea',
        fa: 'اریتره',
        fr: 'Érythrée',
        hr: 'Eritreja',
        it: 'Eritrea',
        ja: 'エリトリア',
        nl: 'Eritrea',
        pt: 'Eritreia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.er'],
    },
    alpha3: 'ERI',
    areaCode: '291',
    picture: 'https://restcountries.eu/data/eri.svg',
  },
  {
    id: '8857c786-c2ca-4c12-ad32-3fa4c4380a6c',
    name: 'Estonia',
    data: {
      area: 45227,
      cioc: 'EST',
      gini: 36,
      latlng: [59, 26],
      region: 'Europe',
      borders: ['LVA', 'RUS'],
      capital: 'Tallinn',
      demonym: 'Estonian',
      languages: [
        {
          name: 'Estonian',
          iso639_1: 'et',
          iso639_2: 'est',
          nativeName: 'eesti',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'EE',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Eesti',
      population: 1315944,
      numericCode: '233',
      altSpellings: ['EE', 'Eesti', 'Republic of Estonia', 'Eesti Vabariik'],
      translations: {
        br: 'Estônia',
        de: 'Estland',
        es: 'Estonia',
        fa: 'استونی',
        fr: 'Estonie',
        hr: 'Estonija',
        it: 'Estonia',
        ja: 'エストニア',
        nl: 'Estland',
        pt: 'Estónia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ee'],
    },
    alpha3: 'EST',
    areaCode: '372',
    picture: 'https://restcountries.eu/data/est.svg',
  },
  {
    id: '16f41344-542f-42fc-80c0-327225f9fa03',
    name: 'Martinique',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [14.666667, -61],
      region: 'Americas',
      borders: [],
      capital: 'Fort-de-France',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'MQ',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Martinique',
      population: 378243,
      numericCode: '474',
      altSpellings: ['MQ'],
      translations: {
        br: 'Martinica',
        de: 'Martinique',
        es: 'Martinica',
        fa: 'مونتسرات',
        fr: 'Martinique',
        hr: 'Martinique',
        it: 'Martinica',
        ja: 'マルティニーク',
        nl: 'Martinique',
        pt: 'Martinica',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mq'],
    },
    alpha3: 'MTQ',
    areaCode: '596',
    picture: 'https://restcountries.eu/data/mtq.svg',
  },
  {
    id: '37300e3c-854e-4b03-8440-587a58504de5',
    name: 'Ethiopia',
    data: {
      area: 1104300,
      cioc: 'ETH',
      gini: 29.8,
      latlng: [8, 38],
      region: 'Africa',
      borders: ['DJI', 'ERI', 'KEN', 'SOM', 'SSD', 'SDN'],
      capital: 'Addis Ababa',
      demonym: 'Ethiopian',
      languages: [
        {
          name: 'Amharic',
          iso639_1: 'am',
          iso639_2: 'amh',
          nativeName: 'አማርኛ',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'ET',
      currencies: [
        {
          code: 'ETB',
          name: 'Ethiopian birr',
          symbol: 'Br',
        },
      ],
      nativeName: 'ኢትዮጵያ',
      population: 92206005,
      numericCode: '231',
      altSpellings: [
        'ET',
        'ʾĪtyōṗṗyā',
        'Federal Democratic Republic of Ethiopia',
        'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ',
      ],
      translations: {
        br: 'Etiópia',
        de: 'Äthiopien',
        es: 'Etiopía',
        fa: 'اتیوپی',
        fr: 'Éthiopie',
        hr: 'Etiopija',
        it: 'Etiopia',
        ja: 'エチオピア',
        nl: 'Ethiopië',
        pt: 'Etiópia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.et'],
    },
    alpha3: 'ETH',
    areaCode: '251',
    picture: 'https://restcountries.eu/data/eth.svg',
  },
  {
    id: '421961c5-3ffc-42d0-8eb5-bfc11d684c08',
    name: 'Falkland Islands (Malvinas)',
    data: {
      area: 12173,
      cioc: '',
      gini: null,
      latlng: [-51.75, -59],
      region: 'Americas',
      borders: [],
      capital: 'Stanley',
      demonym: 'Falkland Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'FK',
      currencies: [
        {
          code: 'FKP',
          name: 'Falkland Islands pound',
          symbol: '£',
        },
      ],
      nativeName: 'Falkland Islands',
      population: 2563,
      numericCode: '238',
      altSpellings: ['FK', 'Islas Malvinas'],
      translations: {
        br: 'Ilhas Malvinas',
        de: 'Falklandinseln',
        es: 'Islas Malvinas',
        fa: 'جزایر فالکلند',
        fr: 'Îles Malouines',
        hr: 'Falklandski Otoci',
        it: 'Isole Falkland o Isole Malvine',
        ja: 'フォークランド（マルビナス）諸島',
        nl: 'Falklandeilanden [Islas Malvinas]',
        pt: 'Ilhas Falkland',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.fk'],
    },
    alpha3: 'FLK',
    areaCode: '500',
    picture: 'https://restcountries.eu/data/flk.svg',
  },
  {
    id: 'c054f478-3d34-44b1-ae90-bd985c7a9fcf',
    name: 'Faroe Islands',
    data: {
      area: 1393,
      cioc: '',
      gini: null,
      latlng: [62, -7],
      region: 'Europe',
      borders: [],
      capital: 'Tórshavn',
      demonym: 'Faroese',
      languages: [
        {
          name: 'Faroese',
          iso639_1: 'fo',
          iso639_2: 'fao',
          nativeName: 'føroyskt',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+00:00'],
      alpha2Code: 'FO',
      currencies: [
        {
          code: 'DKK',
          name: 'Danish krone',
          symbol: 'kr',
        },
        {
          code: '(none)',
          name: 'Faroese króna',
          symbol: 'kr',
        },
      ],
      nativeName: 'Føroyar',
      population: 49376,
      numericCode: '234',
      altSpellings: ['FO', 'Føroyar', 'Færøerne'],
      translations: {
        br: 'Ilhas Faroé',
        de: 'Färöer-Inseln',
        es: 'Islas Faroe',
        fa: 'جزایر فارو',
        fr: 'Îles Féroé',
        hr: 'Farski Otoci',
        it: 'Isole Far Oer',
        ja: 'フェロー諸島',
        nl: 'Faeröer',
        pt: 'Ilhas Faroé',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.fo'],
    },
    alpha3: 'FRO',
    areaCode: '298',
    picture: 'https://restcountries.eu/data/fro.svg',
  },
  {
    id: '19127ca7-4792-4fed-b3bc-afb58d116adc',
    name: 'Fiji',
    data: {
      area: 18272,
      cioc: 'FIJ',
      gini: 42.8,
      latlng: [-18, 175],
      region: 'Oceania',
      borders: [],
      capital: 'Suva',
      demonym: 'Fijian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Fijian',
          iso639_1: 'fj',
          iso639_2: 'fij',
          nativeName: 'vosa Vakaviti',
        },
        {
          name: 'Hindi',
          iso639_1: 'hi',
          iso639_2: 'hin',
          nativeName: 'हिन्दी',
        },
        {
          name: 'Urdu',
          iso639_1: 'ur',
          iso639_2: 'urd',
          nativeName: 'اردو',
        },
      ],
      subregion: 'Melanesia',
      timezones: ['UTC+12:00'],
      alpha2Code: 'FJ',
      currencies: [
        {
          code: 'FJD',
          name: 'Fijian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Fiji',
      population: 867000,
      numericCode: '242',
      altSpellings: [
        'FJ',
        'Viti',
        'Republic of Fiji',
        'Matanitu ko Viti',
        'Fijī Gaṇarājya',
      ],
      translations: {
        br: 'Fiji',
        de: 'Fidschi',
        es: 'Fiyi',
        fa: 'فیجی',
        fr: 'Fidji',
        hr: 'Fiđi',
        it: 'Figi',
        ja: 'フィジー',
        nl: 'Fiji',
        pt: 'Fiji',
      },
      regionalBlocs: [],
      topLevelDomain: ['.fj'],
    },
    alpha3: 'FJI',
    areaCode: '679',
    picture: 'https://restcountries.eu/data/fji.svg',
  },
  {
    id: '8f74ef00-f622-4ab0-9be1-48d6f3dbb261',
    name: 'Finland',
    data: {
      area: 338424,
      cioc: 'FIN',
      gini: 26.9,
      latlng: [64, 26],
      region: 'Europe',
      borders: ['NOR', 'SWE', 'RUS'],
      capital: 'Helsinki',
      demonym: 'Finnish',
      languages: [
        {
          name: 'Finnish',
          iso639_1: 'fi',
          iso639_2: 'fin',
          nativeName: 'suomi',
        },
        {
          name: 'Swedish',
          iso639_1: 'sv',
          iso639_2: 'swe',
          nativeName: 'svenska',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'FI',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Suomi',
      population: 5491817,
      numericCode: '246',
      altSpellings: [
        'FI',
        'Suomi',
        'Republic of Finland',
        'Suomen tasavalta',
        'Republiken Finland',
      ],
      translations: {
        br: 'Finlândia',
        de: 'Finnland',
        es: 'Finlandia',
        fa: 'فنلاند',
        fr: 'Finlande',
        hr: 'Finska',
        it: 'Finlandia',
        ja: 'フィンランド',
        nl: 'Finland',
        pt: 'Finlândia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.fi'],
    },
    alpha3: 'FIN',
    areaCode: '358',
    picture: 'https://restcountries.eu/data/fin.svg',
  },
  {
    id: '173e1516-6685-43e4-aabd-38b3938e6b68',
    name: 'France',
    data: {
      area: 640679,
      cioc: 'FRA',
      gini: 32.7,
      latlng: [46, 2],
      region: 'Europe',
      borders: ['AND', 'BEL', 'DEU', 'ITA', 'LUX', 'MCO', 'ESP', 'CHE'],
      capital: 'Paris',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Europe',
      timezones: [
        'UTC-10:00',
        'UTC-09:30',
        'UTC-09:00',
        'UTC-08:00',
        'UTC-04:00',
        'UTC-03:00',
        'UTC+01:00',
        'UTC+03:00',
        'UTC+04:00',
        'UTC+05:00',
        'UTC+11:00',
        'UTC+12:00',
      ],
      alpha2Code: 'FR',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'France',
      population: 66710000,
      numericCode: '250',
      altSpellings: ['FR', 'French Republic', 'République française'],
      translations: {
        br: 'França',
        de: 'Frankreich',
        es: 'Francia',
        fa: 'فرانسه',
        fr: 'France',
        hr: 'Francuska',
        it: 'Francia',
        ja: 'フランス',
        nl: 'Frankrijk',
        pt: 'França',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.fr'],
    },
    alpha3: 'FRA',
    areaCode: '33',
    picture: 'https://restcountries.eu/data/fra.svg',
  },
  {
    id: 'f3edf6fc-a641-4687-a648-b39a47b01b26',
    name: 'French Guiana',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [4, -53],
      region: 'Americas',
      borders: ['BRA', 'SUR'],
      capital: 'Cayenne',
      demonym: '',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-03:00'],
      alpha2Code: 'GF',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Guyane française',
      population: 254541,
      numericCode: '254',
      altSpellings: ['GF', 'Guiana', 'Guyane'],
      translations: {
        br: 'Guiana Francesa',
        de: 'Französisch Guyana',
        es: 'Guayana Francesa',
        fa: 'گویان فرانسه',
        fr: 'Guayane',
        hr: 'Francuska Gvajana',
        it: 'Guyana francese',
        ja: 'フランス領ギアナ',
        nl: 'Frans-Guyana',
        pt: 'Guiana Francesa',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gf'],
    },
    alpha3: 'GUF',
    areaCode: '594',
    picture: 'https://restcountries.eu/data/guf.svg',
  },
  {
    id: '8fe938e2-61f2-4e18-8aa0-4e87bbc17541',
    name: 'French Polynesia',
    data: {
      area: 4167,
      cioc: '',
      gini: null,
      latlng: [-15, -140],
      region: 'Oceania',
      borders: [],
      capital: 'Papeetē',
      demonym: 'French Polynesian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC-10:00', 'UTC-09:30', 'UTC-09:00'],
      alpha2Code: 'PF',
      currencies: [
        {
          code: 'XPF',
          name: 'CFP franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Polynésie française',
      population: 271800,
      numericCode: '258',
      altSpellings: [
        'PF',
        'Polynésie française',
        'French Polynesia',
        'Pōrīnetia Farāni',
      ],
      translations: {
        br: 'Polinésia Francesa',
        de: 'Französisch-Polynesien',
        es: 'Polinesia Francesa',
        fa: 'پلی‌نزی فرانسه',
        fr: 'Polynésie française',
        hr: 'Francuska Polinezija',
        it: 'Polinesia Francese',
        ja: 'フランス領ポリネシア',
        nl: 'Frans-Polynesië',
        pt: 'Polinésia Francesa',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pf'],
    },
    alpha3: 'PYF',
    areaCode: '689',
    picture: 'https://restcountries.eu/data/pyf.svg',
  },
  {
    id: 'ceec04df-0ce4-4c00-bb8f-713fa276e50d',
    name: 'French Southern Territories',
    data: {
      area: 7747,
      cioc: '',
      gini: null,
      latlng: [-49.25, 69.167],
      region: 'Africa',
      borders: [],
      capital: 'Port-aux-Français',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+05:00'],
      alpha2Code: 'TF',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Territoire des Terres australes et antarctiques françaises',
      population: 140,
      numericCode: '260',
      altSpellings: ['TF'],
      translations: {
        br: 'Terras Austrais e Antárticas Francesas',
        de: 'Französische Süd- und Antarktisgebiete',
        es: 'Tierras Australes y Antárticas Francesas',
        fa: 'سرزمین‌های جنوبی و جنوبگانی فرانسه',
        fr: 'Terres australes et antarctiques françaises',
        hr: 'Francuski južni i antarktički teritoriji',
        it: 'Territori Francesi del Sud',
        ja: 'フランス領南方・南極地域',
        nl: 'Franse Gebieden in de zuidelijke Indische Oceaan',
        pt: 'Terras Austrais e Antárticas Francesas',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.tf'],
    },
    alpha3: 'ATF',
    areaCode: '',
    picture: 'https://restcountries.eu/data/atf.svg',
  },
  {
    id: 'cdbac4b3-39fb-4c59-9b57-c3b363193049',
    name: 'Gabon',
    data: {
      area: 267668,
      cioc: 'GAB',
      gini: 41.5,
      latlng: [-1, 11.75],
      region: 'Africa',
      borders: ['CMR', 'COG', 'GNQ'],
      capital: 'Libreville',
      demonym: 'Gabonese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'GA',
      currencies: [
        {
          code: 'XAF',
          name: 'Central African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Gabon',
      population: 1802278,
      numericCode: '266',
      altSpellings: ['GA', 'Gabonese Republic', 'République Gabonaise'],
      translations: {
        br: 'Gabão',
        de: 'Gabun',
        es: 'Gabón',
        fa: 'گابن',
        fr: 'Gabon',
        hr: 'Gabon',
        it: 'Gabon',
        ja: 'ガボン',
        nl: 'Gabon',
        pt: 'Gabão',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ga'],
    },
    alpha3: 'GAB',
    areaCode: '241',
    picture: 'https://restcountries.eu/data/gab.svg',
  },
  {
    id: '5d57cc35-03d5-4625-a2b5-ed310982ae46',
    name: 'Gambia',
    data: {
      area: 11295,
      cioc: 'GAM',
      gini: null,
      latlng: [13.46666666, -16.56666666],
      region: 'Africa',
      borders: ['SEN'],
      capital: 'Banjul',
      demonym: 'Gambian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC+00:00'],
      alpha2Code: 'GM',
      currencies: [
        {
          code: 'GMD',
          name: 'Gambian dalasi',
          symbol: 'D',
        },
      ],
      nativeName: 'Gambia',
      population: 1882450,
      numericCode: '270',
      altSpellings: ['GM', 'Republic of the Gambia'],
      translations: {
        br: 'Gâmbia',
        de: 'Gambia',
        es: 'Gambia',
        fa: 'گامبیا',
        fr: 'Gambie',
        hr: 'Gambija',
        it: 'Gambia',
        ja: 'ガンビア',
        nl: 'Gambia',
        pt: 'Gâmbia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gm'],
    },
    alpha3: 'GMB',
    areaCode: '220',
    picture: 'https://restcountries.eu/data/gmb.svg',
  },
  {
    id: 'de57529a-2184-4fca-b7b9-1d1e353c59be',
    name: 'Georgia',
    data: {
      area: 69700,
      cioc: 'GEO',
      gini: 41.3,
      latlng: [42, 43.5],
      region: 'Asia',
      borders: ['ARM', 'AZE', 'RUS', 'TUR'],
      capital: 'Tbilisi',
      demonym: 'Georgian',
      languages: [
        {
          name: 'Georgian',
          iso639_1: 'ka',
          iso639_2: 'kat',
          nativeName: 'ქართული',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC-05:00'],
      alpha2Code: 'GE',
      currencies: [
        {
          code: 'GEL',
          name: 'Georgian Lari',
          symbol: 'ლ',
        },
      ],
      nativeName: 'საქართველო',
      population: 3720400,
      numericCode: '268',
      altSpellings: ['GE', 'Sakartvelo'],
      translations: {
        br: 'Geórgia',
        de: 'Georgien',
        es: 'Georgia',
        fa: 'گرجستان',
        fr: 'Géorgie',
        hr: 'Gruzija',
        it: 'Georgia',
        ja: 'グルジア',
        nl: 'Georgië',
        pt: 'Geórgia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ge'],
    },
    alpha3: 'GEO',
    areaCode: '995',
    picture: 'https://restcountries.eu/data/geo.svg',
  },
  {
    id: '1450fda2-b602-453f-b5eb-a3152b354662',
    name: 'Ghana',
    data: {
      area: 238533,
      cioc: 'GHA',
      gini: 42.8,
      latlng: [8, -2],
      region: 'Africa',
      borders: ['BFA', 'CIV', 'TGO'],
      capital: 'Accra',
      demonym: 'Ghanaian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'GH',
      currencies: [
        {
          code: 'GHS',
          name: 'Ghanaian cedi',
          symbol: '₵',
        },
      ],
      nativeName: 'Ghana',
      population: 27670174,
      numericCode: '288',
      altSpellings: ['GH'],
      translations: {
        br: 'Gana',
        de: 'Ghana',
        es: 'Ghana',
        fa: 'غنا',
        fr: 'Ghana',
        hr: 'Gana',
        it: 'Ghana',
        ja: 'ガーナ',
        nl: 'Ghana',
        pt: 'Gana',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gh'],
    },
    alpha3: 'GHA',
    areaCode: '233',
    picture: 'https://restcountries.eu/data/gha.svg',
  },
  {
    id: 'd75eda1e-63d3-416f-90e6-a051a4e789ef',
    name: 'Gibraltar',
    data: {
      area: 6,
      cioc: '',
      gini: null,
      latlng: [36.13333333, -5.35],
      region: 'Europe',
      borders: ['ESP'],
      capital: 'Gibraltar',
      demonym: 'Gibraltar',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'GI',
      currencies: [
        {
          code: 'GIP',
          name: 'Gibraltar pound',
          symbol: '£',
        },
      ],
      nativeName: 'Gibraltar',
      population: 33140,
      numericCode: '292',
      altSpellings: ['GI'],
      translations: {
        br: 'Gibraltar',
        de: 'Gibraltar',
        es: 'Gibraltar',
        fa: 'جبل‌طارق',
        fr: 'Gibraltar',
        hr: 'Gibraltar',
        it: 'Gibilterra',
        ja: 'ジブラルタル',
        nl: 'Gibraltar',
        pt: 'Gibraltar',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gi'],
    },
    alpha3: 'GIB',
    areaCode: '350',
    picture: 'https://restcountries.eu/data/gib.svg',
  },
  {
    id: 'eea970a3-0812-4286-873c-71e9e4cfd1b8',
    name: 'Greece',
    data: {
      area: 131990,
      cioc: 'GRE',
      gini: 34.3,
      latlng: [39, 22],
      region: 'Europe',
      borders: ['ALB', 'BGR', 'TUR', 'MKD'],
      capital: 'Athens',
      demonym: 'Greek',
      languages: [
        {
          name: 'Greek (modern)',
          iso639_1: 'el',
          iso639_2: 'ell',
          nativeName: 'ελληνικά',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'GR',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Ελλάδα',
      population: 10858018,
      numericCode: '300',
      altSpellings: [
        'GR',
        'Elláda',
        'Hellenic Republic',
        'Ελληνική Δημοκρατία',
      ],
      translations: {
        br: 'Grécia',
        de: 'Griechenland',
        es: 'Grecia',
        fa: 'یونان',
        fr: 'Grèce',
        hr: 'Grčka',
        it: 'Grecia',
        ja: 'ギリシャ',
        nl: 'Griekenland',
        pt: 'Grécia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gr'],
    },
    alpha3: 'GRC',
    areaCode: '30',
    picture: 'https://restcountries.eu/data/grc.svg',
  },
  {
    id: 'effd0565-ec8a-4a14-adb2-c4618254608d',
    name: 'Greenland',
    data: {
      area: 2166086,
      cioc: '',
      gini: null,
      latlng: [72, -40],
      region: 'Americas',
      borders: [],
      capital: 'Nuuk',
      demonym: 'Greenlandic',
      languages: [
        {
          name: 'Kalaallisut',
          iso639_1: 'kl',
          iso639_2: 'kal',
          nativeName: 'kalaallisut',
        },
      ],
      subregion: 'Northern America',
      timezones: ['UTC-04:00', 'UTC-03:00', 'UTC-01:00', 'UTC+00:00'],
      alpha2Code: 'GL',
      currencies: [
        {
          code: 'DKK',
          name: 'Danish krone',
          symbol: 'kr',
        },
      ],
      nativeName: 'Kalaallit Nunaat',
      population: 55847,
      numericCode: '304',
      altSpellings: ['GL', 'Grønland'],
      translations: {
        br: 'Groelândia',
        de: 'Grönland',
        es: 'Groenlandia',
        fa: 'گرینلند',
        fr: 'Groenland',
        hr: 'Grenland',
        it: 'Groenlandia',
        ja: 'グリーンランド',
        nl: 'Groenland',
        pt: 'Gronelândia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.gl'],
    },
    alpha3: 'GRL',
    areaCode: '299',
    picture: 'https://restcountries.eu/data/grl.svg',
  },
  {
    id: '1243745f-7e9b-47da-922e-6493108a53ea',
    name: 'Grenada',
    data: {
      area: 344,
      cioc: 'GRN',
      gini: null,
      latlng: [12.11666666, -61.66666666],
      region: 'Americas',
      borders: [],
      capital: "St. George's",
      demonym: 'Grenadian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'GD',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Grenada',
      population: 103328,
      numericCode: '308',
      altSpellings: ['GD'],
      translations: {
        br: 'Granada',
        de: 'Grenada',
        es: 'Grenada',
        fa: 'گرنادا',
        fr: 'Grenade',
        hr: 'Grenada',
        it: 'Grenada',
        ja: 'グレナダ',
        nl: 'Grenada',
        pt: 'Granada',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gd'],
    },
    alpha3: 'GRD',
    areaCode: '1473',
    picture: 'https://restcountries.eu/data/grd.svg',
  },
  {
    id: '35416582-e9fa-4bb7-9fcd-c30209a838b1',
    name: 'Guadeloupe',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [16.25, -61.583333],
      region: 'Americas',
      borders: [],
      capital: 'Basse-Terre',
      demonym: 'Guadeloupian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'GP',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Guadeloupe',
      population: 400132,
      numericCode: '312',
      altSpellings: ['GP', 'Gwadloup'],
      translations: {
        br: 'Guadalupe',
        de: 'Guadeloupe',
        es: 'Guadalupe',
        fa: 'جزیره گوادلوپ',
        fr: 'Guadeloupe',
        hr: 'Gvadalupa',
        it: 'Guadeloupa',
        ja: 'グアドループ',
        nl: 'Guadeloupe',
        pt: 'Guadalupe',
      },
      regionalBlocs: [],
      topLevelDomain: ['.gp'],
    },
    alpha3: 'GLP',
    areaCode: '590',
    picture: 'https://restcountries.eu/data/glp.svg',
  },
  {
    id: '49a912d4-9d37-467d-bde0-355f93612cc7',
    name: 'Guam',
    data: {
      area: 549,
      cioc: 'GUM',
      gini: null,
      latlng: [13.46666666, 144.78333333],
      region: 'Oceania',
      borders: [],
      capital: 'Hagåtña',
      demonym: 'Guamanian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Chamorro',
          iso639_1: 'ch',
          iso639_2: 'cha',
          nativeName: 'Chamoru',
        },
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+10:00'],
      alpha2Code: 'GU',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Guam',
      population: 184200,
      numericCode: '316',
      altSpellings: ['GU', 'Guåhån'],
      translations: {
        br: 'Guam',
        de: 'Guam',
        es: 'Guam',
        fa: 'گوام',
        fr: 'Guam',
        hr: 'Guam',
        it: 'Guam',
        ja: 'グアム',
        nl: 'Guam',
        pt: 'Guame',
      },
      regionalBlocs: [],
      topLevelDomain: ['.gu'],
    },
    alpha3: 'GUM',
    areaCode: '1671',
    picture: 'https://restcountries.eu/data/gum.svg',
  },
  {
    id: 'bc995fa7-a19c-45ed-b48d-412e954e83f2',
    name: 'Guatemala',
    data: {
      area: 108889,
      cioc: 'GUA',
      gini: 55.9,
      latlng: [15.5, -90.25],
      region: 'Americas',
      borders: ['BLZ', 'SLV', 'HND', 'MEX'],
      capital: 'Guatemala City',
      demonym: 'Guatemalan',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'GT',
      currencies: [
        {
          code: 'GTQ',
          name: 'Guatemalan quetzal',
          symbol: 'Q',
        },
      ],
      nativeName: 'Guatemala',
      population: 16176133,
      numericCode: '320',
      altSpellings: ['GT'],
      translations: {
        br: 'Guatemala',
        de: 'Guatemala',
        es: 'Guatemala',
        fa: 'گواتمالا',
        fr: 'Guatemala',
        hr: 'Gvatemala',
        it: 'Guatemala',
        ja: 'グアテマラ',
        nl: 'Guatemala',
        pt: 'Guatemala',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.gt'],
    },
    alpha3: 'GTM',
    areaCode: '502',
    picture: 'https://restcountries.eu/data/gtm.svg',
  },
  {
    id: '246ebc91-e02d-4b98-8306-e9c8f529c73f',
    name: 'Guernsey',
    data: {
      area: 78,
      cioc: '',
      gini: null,
      latlng: [49.46666666, -2.58333333],
      region: 'Europe',
      borders: [],
      capital: 'St. Peter Port',
      demonym: 'Channel Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+00:00'],
      alpha2Code: 'GG',
      currencies: [
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
        {
          code: '(none)',
          name: 'Guernsey pound',
          symbol: '£',
        },
      ],
      nativeName: 'Guernsey',
      population: 62999,
      numericCode: '831',
      altSpellings: ['GG', 'Bailiwick of Guernsey', 'Bailliage de Guernesey'],
      translations: {
        br: 'Guernsey',
        de: 'Guernsey',
        es: 'Guernsey',
        fa: 'گرنزی',
        fr: 'Guernesey',
        hr: 'Guernsey',
        it: 'Guernsey',
        ja: 'ガーンジー',
        nl: 'Guernsey',
        pt: 'Guernsey',
      },
      regionalBlocs: [],
      topLevelDomain: ['.gg'],
    },
    alpha3: 'GGY',
    areaCode: '44',
    picture: 'https://restcountries.eu/data/ggy.svg',
  },
  {
    id: 'c1f7662d-3826-4e67-8380-697b6b38df0f',
    name: 'Guinea',
    data: {
      area: 245857,
      cioc: 'GUI',
      gini: 39.4,
      latlng: [11, -10],
      region: 'Africa',
      borders: ['CIV', 'GNB', 'LBR', 'MLI', 'SEN', 'SLE'],
      capital: 'Conakry',
      demonym: 'Guinean',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Fula',
          iso639_1: 'ff',
          iso639_2: 'ful',
          nativeName: 'Fulfulde',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'GN',
      currencies: [
        {
          code: 'GNF',
          name: 'Guinean franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Guinée',
      population: 12947000,
      numericCode: '324',
      altSpellings: ['GN', 'Republic of Guinea', 'République de Guinée'],
      translations: {
        br: 'Guiné',
        de: 'Guinea',
        es: 'Guinea',
        fa: 'گینه',
        fr: 'Guinée',
        hr: 'Gvineja',
        it: 'Guinea',
        ja: 'ギニア',
        nl: 'Guinee',
        pt: 'Guiné',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gn'],
    },
    alpha3: 'GIN',
    areaCode: '224',
    picture: 'https://restcountries.eu/data/gin.svg',
  },
  {
    id: '92694a21-faf7-4436-9cfc-8adac69fa813',
    name: 'Guinea-Bissau',
    data: {
      area: 36125,
      cioc: 'GBS',
      gini: 35.5,
      latlng: [12, -15],
      region: 'Africa',
      borders: ['GIN', 'SEN'],
      capital: 'Bissau',
      demonym: 'Guinea-Bissauan',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'GW',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Guiné-Bissau',
      population: 1547777,
      numericCode: '624',
      altSpellings: [
        'GW',
        'Republic of Guinea-Bissau',
        'República da Guiné-Bissau',
      ],
      translations: {
        br: 'Guiné-Bissau',
        de: 'Guinea-Bissau',
        es: 'Guinea-Bisáu',
        fa: 'گینه بیسائو',
        fr: 'Guinée-Bissau',
        hr: 'Gvineja Bisau',
        it: 'Guinea-Bissau',
        ja: 'ギニアビサウ',
        nl: 'Guinee-Bissau',
        pt: 'Guiné-Bissau',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.gw'],
    },
    alpha3: 'GNB',
    areaCode: '245',
    picture: 'https://restcountries.eu/data/gnb.svg',
  },
  {
    id: '67fe7774-76fa-4339-ba4b-dbf84c704e3c',
    name: 'Guyana',
    data: {
      area: 214969,
      cioc: 'GUY',
      gini: 44.5,
      latlng: [5, -59],
      region: 'Americas',
      borders: ['BRA', 'SUR', 'VEN'],
      capital: 'Georgetown',
      demonym: 'Guyanese',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'GY',
      currencies: [
        {
          code: 'GYD',
          name: 'Guyanese dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Guyana',
      population: 746900,
      numericCode: '328',
      altSpellings: ['GY', 'Co-operative Republic of Guyana'],
      translations: {
        br: 'Guiana',
        de: 'Guyana',
        es: 'Guyana',
        fa: 'گویان',
        fr: 'Guyane',
        hr: 'Gvajana',
        it: 'Guyana',
        ja: 'ガイアナ',
        nl: 'Guyana',
        pt: 'Guiana',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.gy'],
    },
    alpha3: 'GUY',
    areaCode: '592',
    picture: 'https://restcountries.eu/data/guy.svg',
  },
  {
    id: 'b00467cf-b9a1-4586-a181-af2dc3fab913',
    name: 'Haiti',
    data: {
      area: 27750,
      cioc: 'HAI',
      gini: 59.2,
      latlng: [19, -72.41666666],
      region: 'Americas',
      borders: ['DOM'],
      capital: 'Port-au-Prince',
      demonym: 'Haitian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Haitian',
          iso639_1: 'ht',
          iso639_2: 'hat',
          nativeName: 'Kreyòl ayisyen',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-05:00'],
      alpha2Code: 'HT',
      currencies: [
        {
          code: 'HTG',
          name: 'Haitian gourde',
          symbol: 'G',
        },
      ],
      nativeName: 'Haïti',
      population: 11078033,
      numericCode: '332',
      altSpellings: [
        'HT',
        'Republic of Haiti',
        "République d'Haïti",
        'Repiblik Ayiti',
      ],
      translations: {
        br: 'Haiti',
        de: 'Haiti',
        es: 'Haiti',
        fa: 'هائیتی',
        fr: 'Haïti',
        hr: 'Haiti',
        it: 'Haiti',
        ja: 'ハイチ',
        nl: 'Haïti',
        pt: 'Haiti',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ht'],
    },
    alpha3: 'HTI',
    areaCode: '509',
    picture: 'https://restcountries.eu/data/hti.svg',
  },
  {
    id: '83a97cd9-4ef8-4e16-bc01-a49b37d9b9e6',
    name: 'Heard Island and McDonald Islands',
    data: {
      area: 412,
      cioc: '',
      gini: null,
      latlng: [-53.1, 72.51666666],
      region: '',
      borders: [],
      capital: '',
      demonym: 'Heard and McDonald Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: '',
      timezones: ['UTC+05:00'],
      alpha2Code: 'HM',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Heard Island and McDonald Islands',
      population: 0,
      numericCode: '334',
      altSpellings: ['HM'],
      translations: {
        br: 'Ilha Heard e Ilhas McDonald',
        de: 'Heard und die McDonaldinseln',
        es: 'Islas Heard y McDonald',
        fa: 'جزیره هرد و جزایر مک‌دونالد',
        fr: 'Îles Heard-et-MacDonald',
        hr: 'Otok Heard i otočje McDonald',
        it: 'Isole Heard e McDonald',
        ja: 'ハード島とマクドナルド諸島',
        nl: 'Heard- en McDonaldeilanden',
        pt: 'Ilha Heard e Ilhas McDonald',
      },
      regionalBlocs: [],
      topLevelDomain: ['.hm', '.aq'],
    },
    alpha3: 'HMD',
    areaCode: '',
    picture: 'https://restcountries.eu/data/hmd.svg',
  },
  {
    id: 'c58d6174-1e80-45f2-b244-adb6ad30c95a',
    name: 'Holy See',
    data: {
      area: 0.44,
      cioc: '',
      gini: null,
      latlng: [41.9, 12.45],
      region: 'Europe',
      borders: ['ITA'],
      capital: 'Rome',
      demonym: '',
      languages: [
        {
          name: 'Latin',
          iso639_1: 'la',
          iso639_2: 'lat',
          nativeName: 'latine',
        },
        {
          name: 'Italian',
          iso639_1: 'it',
          iso639_2: 'ita',
          nativeName: 'Italiano',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'VA',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Sancta Sedes',
      population: 451,
      numericCode: '336',
      altSpellings: ['Sancta Sedes', 'Vatican', 'The Vatican'],
      translations: {
        br: 'Vaticano',
        de: 'Heiliger Stuhl',
        es: 'Santa Sede',
        fa: 'سریر مقدس',
        fr: 'voir Saint',
        hr: 'Sveta Stolica',
        it: 'Santa Sede',
        ja: '聖座',
        nl: 'Heilige Stoel',
        pt: 'Vaticano',
      },
      regionalBlocs: [],
      topLevelDomain: ['.va'],
    },
    alpha3: 'VAT',
    areaCode: '379',
    picture: 'https://restcountries.eu/data/vat.svg',
  },
  {
    id: 'e6c1f0a6-fe86-4e5e-bfd9-34482d334c3a',
    name: 'Honduras',
    data: {
      area: 112492,
      cioc: 'HON',
      gini: 57,
      latlng: [15, -86.5],
      region: 'Americas',
      borders: ['GTM', 'SLV', 'NIC'],
      capital: 'Tegucigalpa',
      demonym: 'Honduran',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'HN',
      currencies: [
        {
          code: 'HNL',
          name: 'Honduran lempira',
          symbol: 'L',
        },
      ],
      nativeName: 'Honduras',
      population: 8576532,
      numericCode: '340',
      altSpellings: ['HN', 'Republic of Honduras', 'República de Honduras'],
      translations: {
        br: 'Honduras',
        de: 'Honduras',
        es: 'Honduras',
        fa: 'هندوراس',
        fr: 'Honduras',
        hr: 'Honduras',
        it: 'Honduras',
        ja: 'ホンジュラス',
        nl: 'Honduras',
        pt: 'Honduras',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.hn'],
    },
    alpha3: 'HND',
    areaCode: '504',
    picture: 'https://restcountries.eu/data/hnd.svg',
  },
  {
    id: 'add87c78-216e-4ebe-a813-dfb29d4c56e0',
    name: 'Hong Kong',
    data: {
      area: 1104,
      cioc: 'HKG',
      gini: 53.3,
      latlng: [22.25, 114.16666666],
      region: 'Asia',
      borders: ['CHN'],
      capital: 'City of Victoria',
      demonym: 'Chinese',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Chinese',
          iso639_1: 'zh',
          iso639_2: 'zho',
          nativeName: '中文 (Zhōngwén)',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'HK',
      currencies: [
        {
          code: 'HKD',
          name: 'Hong Kong dollar',
          symbol: '$',
        },
      ],
      nativeName: '香港',
      population: 7324300,
      numericCode: '344',
      altSpellings: ['HK', '香港'],
      translations: {
        br: 'Hong Kong',
        de: 'Hong Kong',
        es: 'Hong Kong',
        fa: 'هنگ‌کنگ',
        fr: 'Hong Kong',
        hr: 'Hong Kong',
        it: 'Hong Kong',
        ja: '香港',
        nl: 'Hongkong',
        pt: 'Hong Kong',
      },
      regionalBlocs: [],
      topLevelDomain: ['.hk'],
    },
    alpha3: 'HKG',
    areaCode: '852',
    picture: 'https://restcountries.eu/data/hkg.svg',
  },
  {
    id: '96f3afaf-7cfe-47a8-8f91-c95b76e57fde',
    name: 'Hungary',
    data: {
      area: 93028,
      cioc: 'HUN',
      gini: 31.2,
      latlng: [47, 20],
      region: 'Europe',
      borders: ['AUT', 'HRV', 'ROU', 'SRB', 'SVK', 'SVN', 'UKR'],
      capital: 'Budapest',
      demonym: 'Hungarian',
      languages: [
        {
          name: 'Hungarian',
          iso639_1: 'hu',
          iso639_2: 'hun',
          nativeName: 'magyar',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'HU',
      currencies: [
        {
          code: 'HUF',
          name: 'Hungarian forint',
          symbol: 'Ft',
        },
      ],
      nativeName: 'Magyarország',
      population: 9823000,
      numericCode: '348',
      altSpellings: ['HU'],
      translations: {
        br: 'Hungria',
        de: 'Ungarn',
        es: 'Hungría',
        fa: 'مجارستان',
        fr: 'Hongrie',
        hr: 'Mađarska',
        it: 'Ungheria',
        ja: 'ハンガリー',
        nl: 'Hongarije',
        pt: 'Hungria',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.hu'],
    },
    alpha3: 'HUN',
    areaCode: '36',
    picture: 'https://restcountries.eu/data/hun.svg',
  },
  {
    id: 'acacfd24-a3e2-4fb1-8eb8-4b631cac4607',
    name: 'Iceland',
    data: {
      area: 103000,
      cioc: 'ISL',
      gini: null,
      latlng: [65, -18],
      region: 'Europe',
      borders: [],
      capital: 'Reykjavík',
      demonym: 'Icelander',
      languages: [
        {
          name: 'Icelandic',
          iso639_1: 'is',
          iso639_2: 'isl',
          nativeName: 'Íslenska',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC'],
      alpha2Code: 'IS',
      currencies: [
        {
          code: 'ISK',
          name: 'Icelandic króna',
          symbol: 'kr',
        },
      ],
      nativeName: 'Ísland',
      population: 334300,
      numericCode: '352',
      altSpellings: ['IS', 'Island', 'Republic of Iceland', 'Lýðveldið Ísland'],
      translations: {
        br: 'Islândia',
        de: 'Island',
        es: 'Islandia',
        fa: 'ایسلند',
        fr: 'Islande',
        hr: 'Island',
        it: 'Islanda',
        ja: 'アイスランド',
        nl: 'IJsland',
        pt: 'Islândia',
      },
      regionalBlocs: [
        {
          name: 'European Free Trade Association',
          acronym: 'EFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.is'],
    },
    alpha3: 'ISL',
    areaCode: '354',
    picture: 'https://restcountries.eu/data/isl.svg',
  },
  {
    id: 'f3a9ca50-228e-48b0-a945-f7e8357bfd09',
    name: 'India',
    data: {
      area: 3287590,
      cioc: 'IND',
      gini: 33.4,
      latlng: [20, 77],
      region: 'Asia',
      borders: ['AFG', 'BGD', 'BTN', 'MMR', 'CHN', 'NPL', 'PAK', 'LKA'],
      capital: 'New Delhi',
      demonym: 'Indian',
      languages: [
        {
          name: 'Hindi',
          iso639_1: 'hi',
          iso639_2: 'hin',
          nativeName: 'हिन्दी',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+05:30'],
      alpha2Code: 'IN',
      currencies: [
        {
          code: 'INR',
          name: 'Indian rupee',
          symbol: '₹',
        },
      ],
      nativeName: 'भारत',
      population: 1295210000,
      numericCode: '356',
      altSpellings: ['IN', 'Bhārat', 'Republic of India', 'Bharat Ganrajya'],
      translations: {
        br: 'Índia',
        de: 'Indien',
        es: 'India',
        fa: 'هند',
        fr: 'Inde',
        hr: 'Indija',
        it: 'India',
        ja: 'インド',
        nl: 'India',
        pt: 'Índia',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.in'],
    },
    alpha3: 'IND',
    areaCode: '91',
    picture: 'https://restcountries.eu/data/ind.svg',
  },
  {
    id: '99723396-abef-47c1-a52f-7fc05f0aa80a',
    name: 'Indonesia',
    data: {
      area: 1904569,
      cioc: 'INA',
      gini: 34,
      latlng: [-5, 120],
      region: 'Asia',
      borders: ['TLS', 'MYS', 'PNG'],
      capital: 'Jakarta',
      demonym: 'Indonesian',
      languages: [
        {
          name: 'Indonesian',
          iso639_1: 'id',
          iso639_2: 'ind',
          nativeName: 'Bahasa Indonesia',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+07:00', 'UTC+08:00', 'UTC+09:00'],
      alpha2Code: 'ID',
      currencies: [
        {
          code: 'IDR',
          name: 'Indonesian rupiah',
          symbol: 'Rp',
        },
      ],
      nativeName: 'Indonesia',
      population: 258705000,
      numericCode: '360',
      altSpellings: ['ID', 'Republic of Indonesia', 'Republik Indonesia'],
      translations: {
        br: 'Indonésia',
        de: 'Indonesien',
        es: 'Indonesia',
        fa: 'اندونزی',
        fr: 'Indonésie',
        hr: 'Indonezija',
        it: 'Indonesia',
        ja: 'インドネシア',
        nl: 'Indonesië',
        pt: 'Indonésia',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.id'],
    },
    alpha3: 'IDN',
    areaCode: '62',
    picture: 'https://restcountries.eu/data/idn.svg',
  },
  {
    id: 'b90fd36a-b988-47a6-8e71-4068656f7a5b',
    name: "Côte d'Ivoire",
    data: {
      area: 322463,
      cioc: 'CIV',
      gini: 41.5,
      latlng: [8, -5],
      region: 'Africa',
      borders: ['BFA', 'GHA', 'GIN', 'LBR', 'MLI'],
      capital: 'Yamoussoukro',
      demonym: 'Ivorian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'CI',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: "Côte d'Ivoire",
      population: 22671331,
      numericCode: '384',
      altSpellings: [
        'CI',
        'Ivory Coast',
        "Republic of Côte d'Ivoire",
        "République de Côte d'Ivoire",
      ],
      translations: {
        br: 'Costa do Marfim',
        de: 'Elfenbeinküste',
        es: 'Costa de Marfil',
        fa: 'ساحل عاج',
        fr: "Côte d'Ivoire",
        hr: 'Obala Bjelokosti',
        it: "Costa D'Avorio",
        ja: 'コートジボワール',
        nl: 'Ivoorkust',
        pt: 'Costa do Marfim',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ci'],
    },
    alpha3: 'CIV',
    areaCode: '225',
    picture: 'https://restcountries.eu/data/civ.svg',
  },
  {
    id: '010f6290-25fe-420b-9cff-291314da2e23',
    name: 'Iran (Islamic Republic of)',
    data: {
      area: 1648195,
      cioc: 'IRI',
      gini: 38.3,
      latlng: [32, 53],
      region: 'Asia',
      borders: ['AFG', 'ARM', 'AZE', 'IRQ', 'PAK', 'TUR', 'TKM'],
      capital: 'Tehran',
      demonym: 'Iranian',
      languages: [
        {
          name: 'Persian (Farsi)',
          iso639_1: 'fa',
          iso639_2: 'fas',
          nativeName: 'فارسی',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+03:30'],
      alpha2Code: 'IR',
      currencies: [
        {
          code: 'IRR',
          name: 'Iranian rial',
          symbol: '﷼',
        },
      ],
      nativeName: 'ایران',
      population: 79369900,
      numericCode: '364',
      altSpellings: [
        'IR',
        'Islamic Republic of Iran',
        'Jomhuri-ye Eslāmi-ye Irān',
      ],
      translations: {
        br: 'Irã',
        de: 'Iran',
        es: 'Iran',
        fa: 'ایران',
        fr: 'Iran',
        hr: 'Iran',
        it: null,
        ja: 'イラン・イスラム共和国',
        nl: 'Iran',
        pt: 'Irão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ir'],
    },
    alpha3: 'IRN',
    areaCode: '98',
    picture: 'https://restcountries.eu/data/irn.svg',
  },
  {
    id: 'a5d0a156-dd59-4424-885f-043c5d428a4d',
    name: 'Iraq',
    data: {
      area: 438317,
      cioc: 'IRQ',
      gini: 30.9,
      latlng: [33, 44],
      region: 'Asia',
      borders: ['IRN', 'JOR', 'KWT', 'SAU', 'SYR', 'TUR'],
      capital: 'Baghdad',
      demonym: 'Iraqi',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
        {
          name: 'Kurdish',
          iso639_1: 'ku',
          iso639_2: 'kur',
          nativeName: 'Kurdî',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'IQ',
      currencies: [
        {
          code: 'IQD',
          name: 'Iraqi dinar',
          symbol: 'ع.د',
        },
      ],
      nativeName: 'العراق',
      population: 37883543,
      numericCode: '368',
      altSpellings: ['IQ', 'Republic of Iraq', 'Jumhūriyyat al-‘Irāq'],
      translations: {
        br: 'Iraque',
        de: 'Irak',
        es: 'Irak',
        fa: 'عراق',
        fr: 'Irak',
        hr: 'Irak',
        it: 'Iraq',
        ja: 'イラク',
        nl: 'Irak',
        pt: 'Iraque',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.iq'],
    },
    alpha3: 'IRQ',
    areaCode: '964',
    picture: 'https://restcountries.eu/data/irq.svg',
  },
  {
    id: '7770f207-c152-4cc3-9937-6cc69bd1ce92',
    name: 'Ireland',
    data: {
      area: 70273,
      cioc: 'IRL',
      gini: 34.3,
      latlng: [53, -8],
      region: 'Europe',
      borders: ['GBR'],
      capital: 'Dublin',
      demonym: 'Irish',
      languages: [
        {
          name: 'Irish',
          iso639_1: 'ga',
          iso639_2: 'gle',
          nativeName: 'Gaeilge',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC'],
      alpha2Code: 'IE',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Éire',
      population: 6378000,
      numericCode: '372',
      altSpellings: [
        'IE',
        'Éire',
        'Republic of Ireland',
        'Poblacht na hÉireann',
      ],
      translations: {
        br: 'Irlanda',
        de: 'Irland',
        es: 'Irlanda',
        fa: 'ایرلند',
        fr: 'Irlande',
        hr: 'Irska',
        it: 'Irlanda',
        ja: 'アイルランド',
        nl: 'Ierland',
        pt: 'Irlanda',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ie'],
    },
    alpha3: 'IRL',
    areaCode: '353',
    picture: 'https://restcountries.eu/data/irl.svg',
  },
  {
    id: '7dfb9ec1-91a3-489d-bb49-bf4c307d13de',
    name: 'Isle of Man',
    data: {
      area: 572,
      cioc: '',
      gini: null,
      latlng: [54.25, -4.5],
      region: 'Europe',
      borders: [],
      capital: 'Douglas',
      demonym: 'Manx',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Manx',
          iso639_1: 'gv',
          iso639_2: 'glv',
          nativeName: 'Gaelg',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+00:00'],
      alpha2Code: 'IM',
      currencies: [
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
        {
          code: 'IMP[G]',
          name: 'Manx pound',
          symbol: '£',
        },
      ],
      nativeName: 'Isle of Man',
      population: 84497,
      numericCode: '833',
      altSpellings: ['IM', 'Ellan Vannin', 'Mann', 'Mannin'],
      translations: {
        br: 'Ilha de Man',
        de: 'Insel Man',
        es: 'Isla de Man',
        fa: 'جزیره من',
        fr: 'Île de Man',
        hr: 'Otok Man',
        it: 'Isola di Man',
        ja: 'マン島',
        nl: 'Isle of Man',
        pt: 'Ilha de Man',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.im'],
    },
    alpha3: 'IMN',
    areaCode: '44',
    picture: 'https://restcountries.eu/data/imn.svg',
  },
  {
    id: 'cfd2e14a-951e-40c1-b200-27e70191ad3c',
    name: 'Israel',
    data: {
      area: 20770,
      cioc: 'ISR',
      gini: 39.2,
      latlng: [31.5, 34.75],
      region: 'Asia',
      borders: ['EGY', 'JOR', 'LBN', 'SYR'],
      capital: 'Jerusalem',
      demonym: 'Israeli',
      languages: [
        {
          name: 'Hebrew (modern)',
          iso639_1: 'he',
          iso639_2: 'heb',
          nativeName: 'עברית',
        },
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+02:00'],
      alpha2Code: 'IL',
      currencies: [
        {
          code: 'ILS',
          name: 'Israeli new shekel',
          symbol: '₪',
        },
      ],
      nativeName: 'יִשְׂרָאֵל',
      population: 8527400,
      numericCode: '376',
      altSpellings: ['IL', 'State of Israel', "Medīnat Yisrā'el"],
      translations: {
        br: 'Israel',
        de: 'Israel',
        es: 'Israel',
        fa: 'اسرائیل',
        fr: 'Israël',
        hr: 'Izrael',
        it: 'Israele',
        ja: 'イスラエル',
        nl: 'Israël',
        pt: 'Israel',
      },
      regionalBlocs: [],
      topLevelDomain: ['.il'],
    },
    alpha3: 'ISR',
    areaCode: '972',
    picture: 'https://restcountries.eu/data/isr.svg',
  },
  {
    id: '98c6d26c-9d17-46f5-a69d-94627d8007d6',
    name: 'Italy',
    data: {
      area: 301336,
      cioc: 'ITA',
      gini: 36,
      latlng: [42.83333333, 12.83333333],
      region: 'Europe',
      borders: ['AUT', 'FRA', 'SMR', 'SVN', 'CHE', 'VAT'],
      capital: 'Rome',
      demonym: 'Italian',
      languages: [
        {
          name: 'Italian',
          iso639_1: 'it',
          iso639_2: 'ita',
          nativeName: 'Italiano',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'IT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Italia',
      population: 60665551,
      numericCode: '380',
      altSpellings: ['IT', 'Italian Republic', 'Repubblica italiana'],
      translations: {
        br: 'Itália',
        de: 'Italien',
        es: 'Italia',
        fa: 'ایتالیا',
        fr: 'Italie',
        hr: 'Italija',
        it: 'Italia',
        ja: 'イタリア',
        nl: 'Italië',
        pt: 'Itália',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.it'],
    },
    alpha3: 'ITA',
    areaCode: '39',
    picture: 'https://restcountries.eu/data/ita.svg',
  },
  {
    id: 'b6629aa2-d12d-4cb8-8fdf-589586b5f24d',
    name: 'Jamaica',
    data: {
      area: 10991,
      cioc: 'JAM',
      gini: 45.5,
      latlng: [18.25, -77.5],
      region: 'Americas',
      borders: [],
      capital: 'Kingston',
      demonym: 'Jamaican',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-05:00'],
      alpha2Code: 'JM',
      currencies: [
        {
          code: 'JMD',
          name: 'Jamaican dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Jamaica',
      population: 2723246,
      numericCode: '388',
      altSpellings: ['JM'],
      translations: {
        br: 'Jamaica',
        de: 'Jamaika',
        es: 'Jamaica',
        fa: 'جامائیکا',
        fr: 'Jamaïque',
        hr: 'Jamajka',
        it: 'Giamaica',
        ja: 'ジャマイカ',
        nl: 'Jamaica',
        pt: 'Jamaica',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.jm'],
    },
    alpha3: 'JAM',
    areaCode: '1876',
    picture: 'https://restcountries.eu/data/jam.svg',
  },
  {
    id: 'ffd66705-abd0-402c-aece-c23dc866bc10',
    name: 'Japan',
    data: {
      area: 377930,
      cioc: 'JPN',
      gini: 38.1,
      latlng: [36, 138],
      region: 'Asia',
      borders: [],
      capital: 'Tokyo',
      demonym: 'Japanese',
      languages: [
        {
          name: 'Japanese',
          iso639_1: 'ja',
          iso639_2: 'jpn',
          nativeName: '日本語 (にほんご)',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+09:00'],
      alpha2Code: 'JP',
      currencies: [
        {
          code: 'JPY',
          name: 'Japanese yen',
          symbol: '¥',
        },
      ],
      nativeName: '日本',
      population: 126960000,
      numericCode: '392',
      altSpellings: ['JP', 'Nippon', 'Nihon'],
      translations: {
        br: 'Japão',
        de: 'Japan',
        es: 'Japón',
        fa: 'ژاپن',
        fr: 'Japon',
        hr: 'Japan',
        it: 'Giappone',
        ja: '日本',
        nl: 'Japan',
        pt: 'Japão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.jp'],
    },
    alpha3: 'JPN',
    areaCode: '81',
    picture: 'https://restcountries.eu/data/jpn.svg',
  },
  {
    id: '1e289360-7010-4643-a415-a9d7abba2297',
    name: 'Jersey',
    data: {
      area: 116,
      cioc: '',
      gini: null,
      latlng: [49.25, -2.16666666],
      region: 'Europe',
      borders: [],
      capital: 'Saint Helier',
      demonym: 'Channel Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'JE',
      currencies: [
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
        {
          code: 'JEP[G]',
          name: 'Jersey pound',
          symbol: '£',
        },
      ],
      nativeName: 'Jersey',
      population: 100800,
      numericCode: '832',
      altSpellings: [
        'JE',
        'Bailiwick of Jersey',
        'Bailliage de Jersey',
        'Bailliage dé Jèrri',
      ],
      translations: {
        br: 'Jersey',
        de: 'Jersey',
        es: 'Jersey',
        fa: 'جرزی',
        fr: 'Jersey',
        hr: 'Jersey',
        it: 'Isola di Jersey',
        ja: 'ジャージー',
        nl: 'Jersey',
        pt: 'Jersey',
      },
      regionalBlocs: [],
      topLevelDomain: ['.je'],
    },
    alpha3: 'JEY',
    areaCode: '44',
    picture: 'https://restcountries.eu/data/jey.svg',
  },
  {
    id: '63b70051-964f-426e-9c5f-2cbac629e86f',
    name: 'Monaco',
    data: {
      area: 2.02,
      cioc: 'MON',
      gini: null,
      latlng: [43.73333333, 7.4],
      region: 'Europe',
      borders: ['FRA'],
      capital: 'Monaco',
      demonym: 'Monegasque',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'MC',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Monaco',
      population: 38400,
      numericCode: '492',
      altSpellings: ['MC', 'Principality of Monaco', 'Principauté de Monaco'],
      translations: {
        br: 'Mônaco',
        de: 'Monaco',
        es: 'Mónaco',
        fa: 'موناکو',
        fr: 'Monaco',
        hr: 'Monako',
        it: 'Principato di Monaco',
        ja: 'モナコ',
        nl: 'Monaco',
        pt: 'Mónaco',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mc'],
    },
    alpha3: 'MCO',
    areaCode: '377',
    picture: 'https://restcountries.eu/data/mco.svg',
  },
  {
    id: '54bab75f-30cb-4be0-a676-cf5ebd516061',
    name: 'Jordan',
    data: {
      area: 89342,
      cioc: 'JOR',
      gini: 35.4,
      latlng: [31, 36],
      region: 'Asia',
      borders: ['IRQ', 'ISR', 'SAU', 'SYR'],
      capital: 'Amman',
      demonym: 'Jordanian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'JO',
      currencies: [
        {
          code: 'JOD',
          name: 'Jordanian dinar',
          symbol: 'د.ا',
        },
      ],
      nativeName: 'الأردن',
      population: 9531712,
      numericCode: '400',
      altSpellings: [
        'JO',
        'Hashemite Kingdom of Jordan',
        'al-Mamlakah al-Urdunīyah al-Hāshimīyah',
      ],
      translations: {
        br: 'Jordânia',
        de: 'Jordanien',
        es: 'Jordania',
        fa: 'اردن',
        fr: 'Jordanie',
        hr: 'Jordan',
        it: 'Giordania',
        ja: 'ヨルダン',
        nl: 'Jordanië',
        pt: 'Jordânia',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.jo'],
    },
    alpha3: 'JOR',
    areaCode: '962',
    picture: 'https://restcountries.eu/data/jor.svg',
  },
  {
    id: 'bfc22cf6-214f-4129-832e-93b2f09b6efa',
    name: 'Kazakhstan',
    data: {
      area: 2724900,
      cioc: 'KAZ',
      gini: 29,
      latlng: [48, 68],
      region: 'Asia',
      borders: ['CHN', 'KGZ', 'RUS', 'TKM', 'UZB'],
      capital: 'Astana',
      demonym: 'Kazakhstani',
      languages: [
        {
          name: 'Kazakh',
          iso639_1: 'kk',
          iso639_2: 'kaz',
          nativeName: 'қазақ тілі',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Central Asia',
      timezones: ['UTC+05:00', 'UTC+06:00'],
      alpha2Code: 'KZ',
      currencies: [
        {
          code: 'KZT',
          name: 'Kazakhstani tenge',
          symbol: null,
        },
      ],
      nativeName: 'Қазақстан',
      population: 17753200,
      numericCode: '398',
      altSpellings: [
        'KZ',
        'Qazaqstan',
        'Казахстан',
        'Republic of Kazakhstan',
        'Қазақстан Республикасы',
        'Qazaqstan Respublïkası',
        'Республика Казахстан',
        'Respublika Kazakhstan',
      ],
      translations: {
        br: 'Cazaquistão',
        de: 'Kasachstan',
        es: 'Kazajistán',
        fa: 'قزاقستان',
        fr: 'Kazakhstan',
        hr: 'Kazahstan',
        it: 'Kazakistan',
        ja: 'カザフスタン',
        nl: 'Kazachstan',
        pt: 'Cazaquistão',
      },
      regionalBlocs: [
        {
          name: 'Eurasian Economic Union',
          acronym: 'EEU',
          otherNames: [],
          otherAcronyms: ['EAEU'],
        },
      ],
      topLevelDomain: ['.kz', '.қаз'],
    },
    alpha3: 'KAZ',
    areaCode: '76',
    picture: 'https://restcountries.eu/data/kaz.svg',
  },
  {
    id: 'c5baa793-4f43-4eae-b753-445453f770ea',
    name: 'Kenya',
    data: {
      area: 580367,
      cioc: 'KEN',
      gini: 47.7,
      latlng: [1, 38],
      region: 'Africa',
      borders: ['ETH', 'SOM', 'SSD', 'TZA', 'UGA'],
      capital: 'Nairobi',
      demonym: 'Kenyan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Swahili',
          iso639_1: 'sw',
          iso639_2: 'swa',
          nativeName: 'Kiswahili',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'KE',
      currencies: [
        {
          code: 'KES',
          name: 'Kenyan shilling',
          symbol: 'Sh',
        },
      ],
      nativeName: 'Kenya',
      population: 47251000,
      numericCode: '404',
      altSpellings: ['KE', 'Republic of Kenya', 'Jamhuri ya Kenya'],
      translations: {
        br: 'Quênia',
        de: 'Kenia',
        es: 'Kenia',
        fa: 'کنیا',
        fr: 'Kenya',
        hr: 'Kenija',
        it: 'Kenya',
        ja: 'ケニア',
        nl: 'Kenia',
        pt: 'Quénia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ke'],
    },
    alpha3: 'KEN',
    areaCode: '254',
    picture: 'https://restcountries.eu/data/ken.svg',
  },
  {
    id: 'd138008a-2c0b-4321-b7e7-b75822de94a0',
    name: 'Kiribati',
    data: {
      area: 811,
      cioc: 'KIR',
      gini: null,
      latlng: [1.41666666, 173],
      region: 'Oceania',
      borders: [],
      capital: 'South Tarawa',
      demonym: 'I-Kiribati',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+12:00', 'UTC+13:00', 'UTC+14:00'],
      alpha2Code: 'KI',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
        {
          code: '(none)',
          name: 'Kiribati dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Kiribati',
      population: 113400,
      numericCode: '296',
      altSpellings: ['KI', 'Republic of Kiribati', 'Ribaberiki Kiribati'],
      translations: {
        br: 'Kiribati',
        de: 'Kiribati',
        es: 'Kiribati',
        fa: 'کیریباتی',
        fr: 'Kiribati',
        hr: 'Kiribati',
        it: 'Kiribati',
        ja: 'キリバス',
        nl: 'Kiribati',
        pt: 'Quiribáti',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ki'],
    },
    alpha3: 'KIR',
    areaCode: '686',
    picture: 'https://restcountries.eu/data/kir.svg',
  },
  {
    id: 'c9d35759-f5ba-4405-866a-b59312f59c0c',
    name: 'Kuwait',
    data: {
      area: 17818,
      cioc: 'KUW',
      gini: null,
      latlng: [29.5, 45.75],
      region: 'Asia',
      borders: ['IRN', 'SAU'],
      capital: 'Kuwait City',
      demonym: 'Kuwaiti',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'KW',
      currencies: [
        {
          code: 'KWD',
          name: 'Kuwaiti dinar',
          symbol: 'د.ك',
        },
      ],
      nativeName: 'الكويت',
      population: 4183658,
      numericCode: '414',
      altSpellings: ['KW', 'State of Kuwait', 'Dawlat al-Kuwait'],
      translations: {
        br: 'Kuwait',
        de: 'Kuwait',
        es: 'Kuwait',
        fa: 'کویت',
        fr: 'Koweït',
        hr: 'Kuvajt',
        it: 'Kuwait',
        ja: 'クウェート',
        nl: 'Koeweit',
        pt: 'Kuwait',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.kw'],
    },
    alpha3: 'KWT',
    areaCode: '965',
    picture: 'https://restcountries.eu/data/kwt.svg',
  },
  {
    id: '4dfc7fd6-e4e8-47b9-9f57-4137f33bcd12',
    name: 'Kyrgyzstan',
    data: {
      area: 199951,
      cioc: 'KGZ',
      gini: 36.2,
      latlng: [41, 75],
      region: 'Asia',
      borders: ['CHN', 'KAZ', 'TJK', 'UZB'],
      capital: 'Bishkek',
      demonym: 'Kirghiz',
      languages: [
        {
          name: 'Kyrgyz',
          iso639_1: 'ky',
          iso639_2: 'kir',
          nativeName: 'Кыргызча',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Central Asia',
      timezones: ['UTC+06:00'],
      alpha2Code: 'KG',
      currencies: [
        {
          code: 'KGS',
          name: 'Kyrgyzstani som',
          symbol: 'с',
        },
      ],
      nativeName: 'Кыргызстан',
      population: 6047800,
      numericCode: '417',
      altSpellings: [
        'KG',
        'Киргизия',
        'Kyrgyz Republic',
        'Кыргыз Республикасы',
        'Kyrgyz Respublikasy',
      ],
      translations: {
        br: 'Quirguistão',
        de: 'Kirgisistan',
        es: 'Kirguizistán',
        fa: 'قرقیزستان',
        fr: 'Kirghizistan',
        hr: 'Kirgistan',
        it: 'Kirghizistan',
        ja: 'キルギス',
        nl: 'Kirgizië',
        pt: 'Quirguizistão',
      },
      regionalBlocs: [
        {
          name: 'Eurasian Economic Union',
          acronym: 'EEU',
          otherNames: [],
          otherAcronyms: ['EAEU'],
        },
      ],
      topLevelDomain: ['.kg'],
    },
    alpha3: 'KGZ',
    areaCode: '996',
    picture: 'https://restcountries.eu/data/kgz.svg',
  },
  {
    id: 'c723e38c-06d2-4e8d-9990-53b00b21fe4a',
    name: "Lao People's Democratic Republic",
    data: {
      area: 236800,
      cioc: 'LAO',
      gini: 36.7,
      latlng: [18, 105],
      region: 'Asia',
      borders: ['MMR', 'KHM', 'CHN', 'THA', 'VNM'],
      capital: 'Vientiane',
      demonym: 'Laotian',
      languages: [
        {
          name: 'Lao',
          iso639_1: 'lo',
          iso639_2: 'lao',
          nativeName: 'ພາສາລາວ',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+07:00'],
      alpha2Code: 'LA',
      currencies: [
        {
          code: 'LAK',
          name: 'Lao kip',
          symbol: '₭',
        },
      ],
      nativeName: 'ສປປລາວ',
      population: 6492400,
      numericCode: '418',
      altSpellings: [
        'LA',
        'Lao',
        'Laos',
        "Lao People's Democratic Republic",
        'Sathalanalat Paxathipatai Paxaxon Lao',
      ],
      translations: {
        br: 'Laos',
        de: 'Laos',
        es: 'Laos',
        fa: 'لائوس',
        fr: 'Laos',
        hr: 'Laos',
        it: 'Laos',
        ja: 'ラオス人民民主共和国',
        nl: 'Laos',
        pt: 'Laos',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.la'],
    },
    alpha3: 'LAO',
    areaCode: '856',
    picture: 'https://restcountries.eu/data/lao.svg',
  },
  {
    id: '8255e755-8647-4876-92b0-556ddb86504b',
    name: 'Latvia',
    data: {
      area: 64559,
      cioc: 'LAT',
      gini: 36.6,
      latlng: [57, 25],
      region: 'Europe',
      borders: ['BLR', 'EST', 'LTU', 'RUS'],
      capital: 'Riga',
      demonym: 'Latvian',
      languages: [
        {
          name: 'Latvian',
          iso639_1: 'lv',
          iso639_2: 'lav',
          nativeName: 'latviešu valoda',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'LV',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Latvija',
      population: 1961600,
      numericCode: '428',
      altSpellings: ['LV', 'Republic of Latvia', 'Latvijas Republika'],
      translations: {
        br: 'Letônia',
        de: 'Lettland',
        es: 'Letonia',
        fa: 'لتونی',
        fr: 'Lettonie',
        hr: 'Latvija',
        it: 'Lettonia',
        ja: 'ラトビア',
        nl: 'Letland',
        pt: 'Letónia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lv'],
    },
    alpha3: 'LVA',
    areaCode: '371',
    picture: 'https://restcountries.eu/data/lva.svg',
  },
  {
    id: 'cd37dabb-05ff-4c5b-a18d-954dd781ca78',
    name: 'Lebanon',
    data: {
      area: 10452,
      cioc: 'LIB',
      gini: null,
      latlng: [33.83333333, 35.83333333],
      region: 'Asia',
      borders: ['ISR', 'SYR'],
      capital: 'Beirut',
      demonym: 'Lebanese',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+02:00'],
      alpha2Code: 'LB',
      currencies: [
        {
          code: 'LBP',
          name: 'Lebanese pound',
          symbol: 'ل.ل',
        },
      ],
      nativeName: 'لبنان',
      population: 5988000,
      numericCode: '422',
      altSpellings: ['LB', 'Lebanese Republic', 'Al-Jumhūrīyah Al-Libnānīyah'],
      translations: {
        br: 'Líbano',
        de: 'Libanon',
        es: 'Líbano',
        fa: 'لبنان',
        fr: 'Liban',
        hr: 'Libanon',
        it: 'Libano',
        ja: 'レバノン',
        nl: 'Libanon',
        pt: 'Líbano',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lb'],
    },
    alpha3: 'LBN',
    areaCode: '961',
    picture: 'https://restcountries.eu/data/lbn.svg',
  },
  {
    id: '9677ed21-5508-4f2e-aed8-0d1cc6762cf0',
    name: 'Lesotho',
    data: {
      area: 30355,
      cioc: 'LES',
      gini: 52.5,
      latlng: [-29.5, 28.5],
      region: 'Africa',
      borders: ['ZAF'],
      capital: 'Maseru',
      demonym: 'Mosotho',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Southern Sotho',
          iso639_1: 'st',
          iso639_2: 'sot',
          nativeName: 'Sesotho',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'LS',
      currencies: [
        {
          code: 'LSL',
          name: 'Lesotho loti',
          symbol: 'L',
        },
        {
          code: 'ZAR',
          name: 'South African rand',
          symbol: 'R',
        },
      ],
      nativeName: 'Lesotho',
      population: 1894194,
      numericCode: '426',
      altSpellings: ['LS', 'Kingdom of Lesotho', 'Muso oa Lesotho'],
      translations: {
        br: 'Lesoto',
        de: 'Lesotho',
        es: 'Lesotho',
        fa: 'لسوتو',
        fr: 'Lesotho',
        hr: 'Lesoto',
        it: 'Lesotho',
        ja: 'レソト',
        nl: 'Lesotho',
        pt: 'Lesoto',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ls'],
    },
    alpha3: 'LSO',
    areaCode: '266',
    picture: 'https://restcountries.eu/data/lso.svg',
  },
  {
    id: 'b2564bae-e01b-4dad-9390-33b96ca605a1',
    name: 'Liberia',
    data: {
      area: 111369,
      cioc: 'LBR',
      gini: 38.2,
      latlng: [6.5, -9.5],
      region: 'Africa',
      borders: ['GIN', 'CIV', 'SLE'],
      capital: 'Monrovia',
      demonym: 'Liberian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'LR',
      currencies: [
        {
          code: 'LRD',
          name: 'Liberian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Liberia',
      population: 4615000,
      numericCode: '430',
      altSpellings: ['LR', 'Republic of Liberia'],
      translations: {
        br: 'Libéria',
        de: 'Liberia',
        es: 'Liberia',
        fa: 'لیبریا',
        fr: 'Liberia',
        hr: 'Liberija',
        it: 'Liberia',
        ja: 'リベリア',
        nl: 'Liberia',
        pt: 'Libéria',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lr'],
    },
    alpha3: 'LBR',
    areaCode: '231',
    picture: 'https://restcountries.eu/data/lbr.svg',
  },
  {
    id: '0462584c-c7ec-4d3d-89da-c556492a2723',
    name: 'Libya',
    data: {
      area: 1759540,
      cioc: 'LBA',
      gini: null,
      latlng: [25, 17],
      region: 'Africa',
      borders: ['DZA', 'TCD', 'EGY', 'NER', 'SDN', 'TUN'],
      capital: 'Tripoli',
      demonym: 'Libyan',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'LY',
      currencies: [
        {
          code: 'LYD',
          name: 'Libyan dinar',
          symbol: 'ل.د',
        },
      ],
      nativeName: '‏ليبيا',
      population: 6385000,
      numericCode: '434',
      altSpellings: ['LY', 'State of Libya', 'Dawlat Libya'],
      translations: {
        br: 'Líbia',
        de: 'Libyen',
        es: 'Libia',
        fa: 'لیبی',
        fr: 'Libye',
        hr: 'Libija',
        it: 'Libia',
        ja: 'リビア',
        nl: 'Libië',
        pt: 'Líbia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ly'],
    },
    alpha3: 'LBY',
    areaCode: '218',
    picture: 'https://restcountries.eu/data/lby.svg',
  },
  {
    id: '3420a0ee-38dd-4c50-96c3-c57280feb09d',
    name: 'Liechtenstein',
    data: {
      area: 160,
      cioc: 'LIE',
      gini: null,
      latlng: [47.26666666, 9.53333333],
      region: 'Europe',
      borders: ['AUT', 'CHE'],
      capital: 'Vaduz',
      demonym: 'Liechtensteiner',
      languages: [
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'LI',
      currencies: [
        {
          code: 'CHF',
          name: 'Swiss franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Liechtenstein',
      population: 37623,
      numericCode: '438',
      altSpellings: [
        'LI',
        'Principality of Liechtenstein',
        'Fürstentum Liechtenstein',
      ],
      translations: {
        br: 'Liechtenstein',
        de: 'Liechtenstein',
        es: 'Liechtenstein',
        fa: 'لیختن‌اشتاین',
        fr: 'Liechtenstein',
        hr: 'Lihtenštajn',
        it: 'Liechtenstein',
        ja: 'リヒテンシュタイン',
        nl: 'Liechtenstein',
        pt: 'Listenstaine',
      },
      regionalBlocs: [
        {
          name: 'European Free Trade Association',
          acronym: 'EFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.li'],
    },
    alpha3: 'LIE',
    areaCode: '423',
    picture: 'https://restcountries.eu/data/lie.svg',
  },
  {
    id: '668505be-1fba-4ebf-a8ee-1a0466e5884b',
    name: 'Lithuania',
    data: {
      area: 65300,
      cioc: 'LTU',
      gini: 37.6,
      latlng: [56, 24],
      region: 'Europe',
      borders: ['BLR', 'LVA', 'POL', 'RUS'],
      capital: 'Vilnius',
      demonym: 'Lithuanian',
      languages: [
        {
          name: 'Lithuanian',
          iso639_1: 'lt',
          iso639_2: 'lit',
          nativeName: 'lietuvių kalba',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'LT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Lietuva',
      population: 2872294,
      numericCode: '440',
      altSpellings: ['LT', 'Republic of Lithuania', 'Lietuvos Respublika'],
      translations: {
        br: 'Lituânia',
        de: 'Litauen',
        es: 'Lituania',
        fa: 'لیتوانی',
        fr: 'Lituanie',
        hr: 'Litva',
        it: 'Lituania',
        ja: 'リトアニア',
        nl: 'Litouwen',
        pt: 'Lituânia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lt'],
    },
    alpha3: 'LTU',
    areaCode: '370',
    picture: 'https://restcountries.eu/data/ltu.svg',
  },
  {
    id: '4e5aeb2e-a7a8-4ef5-89ea-496a1b7d4b40',
    name: 'Luxembourg',
    data: {
      area: 2586,
      cioc: 'LUX',
      gini: 30.8,
      latlng: [49.75, 6.16666666],
      region: 'Europe',
      borders: ['BEL', 'FRA', 'DEU'],
      capital: 'Luxembourg',
      demonym: 'Luxembourger',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
        {
          name: 'Luxembourgish',
          iso639_1: 'lb',
          iso639_2: 'ltz',
          nativeName: 'Lëtzebuergesch',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'LU',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Luxembourg',
      population: 576200,
      numericCode: '442',
      altSpellings: [
        'LU',
        'Grand Duchy of Luxembourg',
        'Grand-Duché de Luxembourg',
        'Großherzogtum Luxemburg',
        'Groussherzogtum Lëtzebuerg',
      ],
      translations: {
        br: 'Luxemburgo',
        de: 'Luxemburg',
        es: 'Luxemburgo',
        fa: 'لوکزامبورگ',
        fr: 'Luxembourg',
        hr: 'Luksemburg',
        it: 'Lussemburgo',
        ja: 'ルクセンブルク',
        nl: 'Luxemburg',
        pt: 'Luxemburgo',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lu'],
    },
    alpha3: 'LUX',
    areaCode: '352',
    picture: 'https://restcountries.eu/data/lux.svg',
  },
  {
    id: '0a5a5335-391b-45bc-9c69-78ff68a6c847',
    name: 'Macao',
    data: {
      area: 30,
      cioc: '',
      gini: null,
      latlng: [22.16666666, 113.55],
      region: 'Asia',
      borders: ['CHN'],
      capital: '',
      demonym: 'Chinese',
      languages: [
        {
          name: 'Chinese',
          iso639_1: 'zh',
          iso639_2: 'zho',
          nativeName: '中文 (Zhōngwén)',
        },
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'MO',
      currencies: [
        {
          code: 'MOP',
          name: 'Macanese pataca',
          symbol: 'P',
        },
      ],
      nativeName: '澳門',
      population: 649100,
      numericCode: '446',
      altSpellings: [
        'MO',
        '澳门',
        "Macao Special Administrative Region of the People's Republic of China",
        '中華人民共和國澳門特別行政區',
        'Região Administrativa Especial de Macau da República Popular da China',
      ],
      translations: {
        br: 'Macau',
        de: 'Macao',
        es: 'Macao',
        fa: 'مکائو',
        fr: 'Macao',
        hr: 'Makao',
        it: 'Macao',
        ja: 'マカオ',
        nl: 'Macao',
        pt: 'Macau',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mo'],
    },
    alpha3: 'MAC',
    areaCode: '853',
    picture: 'https://restcountries.eu/data/mac.svg',
  },
  {
    id: '83494000-9010-4bba-a247-a39a5bd41622',
    name: 'Macedonia (the former Yugoslav Republic of)',
    data: {
      area: 25713,
      cioc: 'MKD',
      gini: 43.2,
      latlng: [41.83333333, 22],
      region: 'Europe',
      borders: ['ALB', 'BGR', 'GRC', 'KOS', 'SRB'],
      capital: 'Skopje',
      demonym: 'Macedonian',
      languages: [
        {
          name: 'Macedonian',
          iso639_1: 'mk',
          iso639_2: 'mkd',
          nativeName: 'македонски јазик',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'MK',
      currencies: [
        {
          code: 'MKD',
          name: 'Macedonian denar',
          symbol: 'ден',
        },
      ],
      nativeName: 'Македонија',
      population: 2058539,
      numericCode: '807',
      altSpellings: ['MK', 'Republic of Macedonia', 'Република Македонија'],
      translations: {
        br: 'Macedônia',
        de: 'Mazedonien',
        es: 'Macedonia',
        fa: '',
        fr: 'Macédoine',
        hr: 'Makedonija',
        it: 'Macedonia',
        ja: 'マケドニア旧ユーゴスラビア共和国',
        nl: 'Macedonië',
        pt: 'Macedónia',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mk'],
    },
    alpha3: 'MKD',
    areaCode: '389',
    picture: 'https://restcountries.eu/data/mkd.svg',
  },
  {
    id: '394b654d-3464-478e-a66d-c5d8c2cadbac',
    name: 'Madagascar',
    data: {
      area: 587041,
      cioc: 'MAD',
      gini: 44.1,
      latlng: [-20, 47],
      region: 'Africa',
      borders: [],
      capital: 'Antananarivo',
      demonym: 'Malagasy',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Malagasy',
          iso639_1: 'mg',
          iso639_2: 'mlg',
          nativeName: 'fiteny malagasy',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'MG',
      currencies: [
        {
          code: 'MGA',
          name: 'Malagasy ariary',
          symbol: 'Ar',
        },
      ],
      nativeName: 'Madagasikara',
      population: 22434363,
      numericCode: '450',
      altSpellings: [
        'MG',
        'Republic of Madagascar',
        "Repoblikan'i Madagasikara",
        'République de Madagascar',
      ],
      translations: {
        br: 'Madagascar',
        de: 'Madagaskar',
        es: 'Madagascar',
        fa: 'ماداگاسکار',
        fr: 'Madagascar',
        hr: 'Madagaskar',
        it: 'Madagascar',
        ja: 'マダガスカル',
        nl: 'Madagaskar',
        pt: 'Madagáscar',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mg'],
    },
    alpha3: 'MDG',
    areaCode: '261',
    picture: 'https://restcountries.eu/data/mdg.svg',
  },
  {
    id: 'f85a40c7-d337-4e52-96ba-f4e1ffd68f42',
    name: 'Malawi',
    data: {
      area: 118484,
      cioc: 'MAW',
      gini: 39,
      latlng: [-13.5, 34],
      region: 'Africa',
      borders: ['MOZ', 'TZA', 'ZMB'],
      capital: 'Lilongwe',
      demonym: 'Malawian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Chichewa',
          iso639_1: 'ny',
          iso639_2: 'nya',
          nativeName: 'chiCheŵa',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'MW',
      currencies: [
        {
          code: 'MWK',
          name: 'Malawian kwacha',
          symbol: 'MK',
        },
      ],
      nativeName: 'Malawi',
      population: 16832910,
      numericCode: '454',
      altSpellings: ['MW', 'Republic of Malawi'],
      translations: {
        br: 'Malawi',
        de: 'Malawi',
        es: 'Malawi',
        fa: 'مالاوی',
        fr: 'Malawi',
        hr: 'Malavi',
        it: 'Malawi',
        ja: 'マラウイ',
        nl: 'Malawi',
        pt: 'Malávi',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mw'],
    },
    alpha3: 'MWI',
    areaCode: '265',
    picture: 'https://restcountries.eu/data/mwi.svg',
  },
  {
    id: '8b589429-9bbb-414f-bbf6-dc671d036445',
    name: 'Malaysia',
    data: {
      area: 330803,
      cioc: 'MAS',
      gini: 46.2,
      latlng: [2.5, 112.5],
      region: 'Asia',
      borders: ['BRN', 'IDN', 'THA'],
      capital: 'Kuala Lumpur',
      demonym: 'Malaysian',
      languages: [
        {
          name: 'Malaysian',
          iso639_1: null,
          iso639_2: 'zsm',
          nativeName: 'بهاس مليسيا',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'MY',
      currencies: [
        {
          code: 'MYR',
          name: 'Malaysian ringgit',
          symbol: 'RM',
        },
      ],
      nativeName: 'Malaysia',
      population: 31405416,
      numericCode: '458',
      altSpellings: ['MY'],
      translations: {
        br: 'Malásia',
        de: 'Malaysia',
        es: 'Malasia',
        fa: 'مالزی',
        fr: 'Malaisie',
        hr: 'Malezija',
        it: 'Malesia',
        ja: 'マレーシア',
        nl: 'Maleisië',
        pt: 'Malásia',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.my'],
    },
    alpha3: 'MYS',
    areaCode: '60',
    picture: 'https://restcountries.eu/data/mys.svg',
  },
  {
    id: 'f10e4384-9acd-459c-819f-eb76c55cb2ae',
    name: 'Maldives',
    data: {
      area: 300,
      cioc: 'MDV',
      gini: 37.4,
      latlng: [3.25, 73],
      region: 'Asia',
      borders: [],
      capital: 'Malé',
      demonym: 'Maldivan',
      languages: [
        {
          name: 'Divehi',
          iso639_1: 'dv',
          iso639_2: 'div',
          nativeName: 'ދިވެހި',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+05:00'],
      alpha2Code: 'MV',
      currencies: [
        {
          code: 'MVR',
          name: 'Maldivian rufiyaa',
          symbol: '.ރ',
        },
      ],
      nativeName: 'Maldives',
      population: 344023,
      numericCode: '462',
      altSpellings: [
        'MV',
        'Maldive Islands',
        'Republic of the Maldives',
        'Dhivehi Raajjeyge Jumhooriyya',
      ],
      translations: {
        br: 'Maldivas',
        de: 'Malediven',
        es: 'Maldivas',
        fa: 'مالدیو',
        fr: 'Maldives',
        hr: 'Maldivi',
        it: 'Maldive',
        ja: 'モルディブ',
        nl: 'Maldiven',
        pt: 'Maldivas',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mv'],
    },
    alpha3: 'MDV',
    areaCode: '960',
    picture: 'https://restcountries.eu/data/mdv.svg',
  },
  {
    id: '70b22ba0-7842-4790-8378-6d92f936470d',
    name: 'Mali',
    data: {
      area: 1240192,
      cioc: 'MLI',
      gini: 33,
      latlng: [17, -4],
      region: 'Africa',
      borders: ['DZA', 'BFA', 'GIN', 'CIV', 'MRT', 'NER', 'SEN'],
      capital: 'Bamako',
      demonym: 'Malian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'ML',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Mali',
      population: 18135000,
      numericCode: '466',
      altSpellings: ['ML', 'Republic of Mali', 'République du Mali'],
      translations: {
        br: 'Mali',
        de: 'Mali',
        es: 'Mali',
        fa: 'مالی',
        fr: 'Mali',
        hr: 'Mali',
        it: 'Mali',
        ja: 'マリ',
        nl: 'Mali',
        pt: 'Mali',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ml'],
    },
    alpha3: 'MLI',
    areaCode: '223',
    picture: 'https://restcountries.eu/data/mli.svg',
  },
  {
    id: '443fa7b5-d05c-4e55-9e1c-e4841ade9444',
    name: 'Malta',
    data: {
      area: 316,
      cioc: 'MLT',
      gini: null,
      latlng: [35.83333333, 14.58333333],
      region: 'Europe',
      borders: [],
      capital: 'Valletta',
      demonym: 'Maltese',
      languages: [
        {
          name: 'Maltese',
          iso639_1: 'mt',
          iso639_2: 'mlt',
          nativeName: 'Malti',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'MT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Malta',
      population: 425384,
      numericCode: '470',
      altSpellings: ['MT', 'Republic of Malta', "Repubblika ta' Malta"],
      translations: {
        br: 'Malta',
        de: 'Malta',
        es: 'Malta',
        fa: 'مالت',
        fr: 'Malte',
        hr: 'Malta',
        it: 'Malta',
        ja: 'マルタ',
        nl: 'Malta',
        pt: 'Malta',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mt'],
    },
    alpha3: 'MLT',
    areaCode: '356',
    picture: 'https://restcountries.eu/data/mlt.svg',
  },
  {
    id: 'ba25d4a0-d163-4541-a6ae-4080b5d70a59',
    name: 'Marshall Islands',
    data: {
      area: 181,
      cioc: 'MHL',
      gini: null,
      latlng: [9, 168],
      region: 'Oceania',
      borders: [],
      capital: 'Majuro',
      demonym: 'Marshallese',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Marshallese',
          iso639_1: 'mh',
          iso639_2: 'mah',
          nativeName: 'Kajin M̧ajeļ',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+12:00'],
      alpha2Code: 'MH',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'M̧ajeļ',
      population: 54880,
      numericCode: '584',
      altSpellings: [
        'MH',
        'Republic of the Marshall Islands',
        'Aolepān Aorōkin M̧ajeļ',
      ],
      translations: {
        br: 'Ilhas Marshall',
        de: 'Marshallinseln',
        es: 'Islas Marshall',
        fa: 'جزایر مارشال',
        fr: 'Îles Marshall',
        hr: 'Maršalovi Otoci',
        it: 'Isole Marshall',
        ja: 'マーシャル諸島',
        nl: 'Marshalleilanden',
        pt: 'Ilhas Marshall',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mh'],
    },
    alpha3: 'MHL',
    areaCode: '692',
    picture: 'https://restcountries.eu/data/mhl.svg',
  },
  {
    id: '7c8fe0cf-2673-44ec-bd82-8cc0d9853d19',
    name: 'Mauritania',
    data: {
      area: 1030700,
      cioc: 'MTN',
      gini: 40.5,
      latlng: [20, -12],
      region: 'Africa',
      borders: ['DZA', 'MLI', 'SEN', 'ESH'],
      capital: 'Nouakchott',
      demonym: 'Mauritanian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'MR',
      currencies: [
        {
          code: 'MRO',
          name: 'Mauritanian ouguiya',
          symbol: 'UM',
        },
      ],
      nativeName: 'موريتانيا',
      population: 3718678,
      numericCode: '478',
      altSpellings: [
        'MR',
        'Islamic Republic of Mauritania',
        'al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah',
      ],
      translations: {
        br: 'Mauritânia',
        de: 'Mauretanien',
        es: 'Mauritania',
        fa: 'موریتانی',
        fr: 'Mauritanie',
        hr: 'Mauritanija',
        it: 'Mauritania',
        ja: 'モーリタニア',
        nl: 'Mauritanië',
        pt: 'Mauritânia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mr'],
    },
    alpha3: 'MRT',
    areaCode: '222',
    picture: 'https://restcountries.eu/data/mrt.svg',
  },
  {
    id: '69e6f26d-462a-41a3-afce-d27f29ed8bec',
    name: 'Mauritius',
    data: {
      area: 2040,
      cioc: 'MRI',
      gini: null,
      latlng: [-20.28333333, 57.55],
      region: 'Africa',
      borders: [],
      capital: 'Port Louis',
      demonym: 'Mauritian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+04:00'],
      alpha2Code: 'MU',
      currencies: [
        {
          code: 'MUR',
          name: 'Mauritian rupee',
          symbol: '₨',
        },
      ],
      nativeName: 'Maurice',
      population: 1262879,
      numericCode: '480',
      altSpellings: ['MU', 'Republic of Mauritius', 'République de Maurice'],
      translations: {
        br: 'Maurício',
        de: 'Mauritius',
        es: 'Mauricio',
        fa: 'موریس',
        fr: 'Île Maurice',
        hr: 'Mauricijus',
        it: 'Mauritius',
        ja: 'モーリシャス',
        nl: 'Mauritius',
        pt: 'Maurícia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mu'],
    },
    alpha3: 'MUS',
    areaCode: '230',
    picture: 'https://restcountries.eu/data/mus.svg',
  },
  {
    id: '9e008e74-a0c4-4001-9229-c15a00e6561f',
    name: 'Mayotte',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [-12.83333333, 45.16666666],
      region: 'Africa',
      borders: [],
      capital: 'Mamoudzou',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'YT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Mayotte',
      population: 226915,
      numericCode: '175',
      altSpellings: ['YT', 'Department of Mayotte', 'Département de Mayotte'],
      translations: {
        br: 'Mayotte',
        de: 'Mayotte',
        es: 'Mayotte',
        fa: 'مایوت',
        fr: 'Mayotte',
        hr: 'Mayotte',
        it: 'Mayotte',
        ja: 'マヨット',
        nl: 'Mayotte',
        pt: 'Mayotte',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.yt'],
    },
    alpha3: 'MYT',
    areaCode: '262',
    picture: 'https://restcountries.eu/data/myt.svg',
  },
  {
    id: '62754868-2449-413e-8cd6-b1ff1c43a24e',
    name: 'Mexico',
    data: {
      area: 1964375,
      cioc: 'MEX',
      gini: 47,
      latlng: [23, -102],
      region: 'Americas',
      borders: ['BLZ', 'GTM', 'USA'],
      capital: 'Mexico City',
      demonym: 'Mexican',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-08:00', 'UTC-07:00', 'UTC-06:00'],
      alpha2Code: 'MX',
      currencies: [
        {
          code: 'MXN',
          name: 'Mexican peso',
          symbol: '$',
        },
      ],
      nativeName: 'México',
      population: 122273473,
      numericCode: '484',
      altSpellings: [
        'MX',
        'Mexicanos',
        'United Mexican States',
        'Estados Unidos Mexicanos',
      ],
      translations: {
        br: 'México',
        de: 'Mexiko',
        es: 'México',
        fa: 'مکزیک',
        fr: 'Mexique',
        hr: 'Meksiko',
        it: 'Messico',
        ja: 'メキシコ',
        nl: 'Mexico',
        pt: 'México',
      },
      regionalBlocs: [
        {
          name: 'Pacific Alliance',
          acronym: 'PA',
          otherNames: ['Alianza del Pacífico'],
          otherAcronyms: [],
        },
        {
          name: 'North American Free Trade Agreement',
          acronym: 'NAFTA',
          otherNames: [
            'Tratado de Libre Comercio de América del Norte',
            'Accord de Libre-échange Nord-Américain',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mx'],
    },
    alpha3: 'MEX',
    areaCode: '52',
    picture: 'https://restcountries.eu/data/mex.svg',
  },
  {
    id: '01855100-7e6e-47d8-b013-ce6a9c81ee0a',
    name: 'Micronesia (Federated States of)',
    data: {
      area: 702,
      cioc: 'FSM',
      gini: null,
      latlng: [6.91666666, 158.25],
      region: 'Oceania',
      borders: [],
      capital: 'Palikir',
      demonym: 'Micronesian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+10:00', 'UTC+11'],
      alpha2Code: 'FM',
      currencies: [
        {
          code: null,
          name: '[D]',
          symbol: '$',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Micronesia',
      population: 102800,
      numericCode: '583',
      altSpellings: ['FM', 'Federated States of Micronesia'],
      translations: {
        br: 'Micronésia',
        de: 'Mikronesien',
        es: 'Micronesia',
        fa: 'ایالات فدرال میکرونزی',
        fr: 'Micronésie',
        hr: 'Mikronezija',
        it: 'Micronesia',
        ja: 'ミクロネシア連邦',
        nl: 'Micronesië',
        pt: 'Micronésia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.fm'],
    },
    alpha3: 'FSM',
    areaCode: '691',
    picture: 'https://restcountries.eu/data/fsm.svg',
  },
  {
    id: 'fce8f8ea-a7aa-4c4a-ab30-1162887c3070',
    name: 'Moldova (Republic of)',
    data: {
      area: 33846,
      cioc: 'MDA',
      gini: 33,
      latlng: [47, 29],
      region: 'Europe',
      borders: ['ROU', 'UKR'],
      capital: 'Chișinău',
      demonym: 'Moldovan',
      languages: [
        {
          name: 'Romanian',
          iso639_1: 'ro',
          iso639_2: 'ron',
          nativeName: 'Română',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'MD',
      currencies: [
        {
          code: 'MDL',
          name: 'Moldovan leu',
          symbol: 'L',
        },
      ],
      nativeName: 'Moldova',
      population: 3553100,
      numericCode: '498',
      altSpellings: ['MD', 'Republic of Moldova', 'Republica Moldova'],
      translations: {
        br: 'Moldávia',
        de: 'Moldawie',
        es: 'Moldavia',
        fa: 'مولداوی',
        fr: 'Moldavie',
        hr: 'Moldova',
        it: 'Moldavia',
        ja: 'モルドバ共和国',
        nl: 'Moldavië',
        pt: 'Moldávia',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.md'],
    },
    alpha3: 'MDA',
    areaCode: '373',
    picture: 'https://restcountries.eu/data/mda.svg',
  },
  {
    id: 'd4600997-d481-4f8e-a4cb-b352da76ee17',
    name: 'Mongolia',
    data: {
      area: 1564110,
      cioc: 'MGL',
      gini: 36.5,
      latlng: [46, 105],
      region: 'Asia',
      borders: ['CHN', 'RUS'],
      capital: 'Ulan Bator',
      demonym: 'Mongolian',
      languages: [
        {
          name: 'Mongolian',
          iso639_1: 'mn',
          iso639_2: 'mon',
          nativeName: 'Монгол хэл',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+07:00', 'UTC+08:00'],
      alpha2Code: 'MN',
      currencies: [
        {
          code: 'MNT',
          name: 'Mongolian tögrög',
          symbol: '₮',
        },
      ],
      nativeName: 'Монгол улс',
      population: 3093100,
      numericCode: '496',
      altSpellings: ['MN'],
      translations: {
        br: 'Mongólia',
        de: 'Mongolei',
        es: 'Mongolia',
        fa: 'مغولستان',
        fr: 'Mongolie',
        hr: 'Mongolija',
        it: 'Mongolia',
        ja: 'モンゴル',
        nl: 'Mongolië',
        pt: 'Mongólia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mn'],
    },
    alpha3: 'MNG',
    areaCode: '976',
    picture: 'https://restcountries.eu/data/mng.svg',
  },
  {
    id: '1da10850-17e2-46d7-85f4-fd7be88dd609',
    name: 'Montenegro',
    data: {
      area: 13812,
      cioc: 'MNE',
      gini: 30,
      latlng: [42.5, 19.3],
      region: 'Europe',
      borders: ['ALB', 'BIH', 'HRV', 'KOS', 'SRB'],
      capital: 'Podgorica',
      demonym: 'Montenegrin',
      languages: [
        {
          name: 'Serbian',
          iso639_1: 'sr',
          iso639_2: 'srp',
          nativeName: 'српски језик',
        },
        {
          name: 'Bosnian',
          iso639_1: 'bs',
          iso639_2: 'bos',
          nativeName: 'bosanski jezik',
        },
        {
          name: 'Albanian',
          iso639_1: 'sq',
          iso639_2: 'sqi',
          nativeName: 'Shqip',
        },
        {
          name: 'Croatian',
          iso639_1: 'hr',
          iso639_2: 'hrv',
          nativeName: 'hrvatski jezik',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'ME',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Црна Гора',
      population: 621810,
      numericCode: '499',
      altSpellings: ['ME', 'Crna Gora'],
      translations: {
        br: 'Montenegro',
        de: 'Montenegro',
        es: 'Montenegro',
        fa: 'مونته‌نگرو',
        fr: 'Monténégro',
        hr: 'Crna Gora',
        it: 'Montenegro',
        ja: 'モンテネグロ',
        nl: 'Montenegro',
        pt: 'Montenegro',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.me'],
    },
    alpha3: 'MNE',
    areaCode: '382',
    picture: 'https://restcountries.eu/data/mne.svg',
  },
  {
    id: '5a604725-47fa-4107-8238-549298ab7a3b',
    name: 'Montserrat',
    data: {
      area: 102,
      cioc: '',
      gini: null,
      latlng: [16.75, -62.2],
      region: 'Americas',
      borders: [],
      capital: 'Plymouth',
      demonym: 'Montserratian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'MS',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Montserrat',
      population: 4922,
      numericCode: '500',
      altSpellings: ['MS'],
      translations: {
        br: 'Montserrat',
        de: 'Montserrat',
        es: 'Montserrat',
        fa: 'مایوت',
        fr: 'Montserrat',
        hr: 'Montserrat',
        it: 'Montserrat',
        ja: 'モントセラト',
        nl: 'Montserrat',
        pt: 'Monserrate',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ms'],
    },
    alpha3: 'MSR',
    areaCode: '1664',
    picture: 'https://restcountries.eu/data/msr.svg',
  },
  {
    id: 'aeeae8fe-0892-434b-89f7-067bd7e4b6a2',
    name: 'Morocco',
    data: {
      area: 446550,
      cioc: 'MAR',
      gini: 40.9,
      latlng: [32, -5],
      region: 'Africa',
      borders: ['DZA', 'ESH', 'ESP'],
      capital: 'Rabat',
      demonym: 'Moroccan',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC'],
      alpha2Code: 'MA',
      currencies: [
        {
          code: 'MAD',
          name: 'Moroccan dirham',
          symbol: 'د.م.',
        },
      ],
      nativeName: 'المغرب',
      population: 33337529,
      numericCode: '504',
      altSpellings: ['MA', 'Kingdom of Morocco', 'Al-Mamlakah al-Maġribiyah'],
      translations: {
        br: 'Marrocos',
        de: 'Marokko',
        es: 'Marruecos',
        fa: 'مراکش',
        fr: 'Maroc',
        hr: 'Maroko',
        it: 'Marocco',
        ja: 'モロッコ',
        nl: 'Marokko',
        pt: 'Marrocos',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ma'],
    },
    alpha3: 'MAR',
    areaCode: '212',
    picture: 'https://restcountries.eu/data/mar.svg',
  },
  {
    id: '386dfbe9-ce3b-4850-8a8c-82281e794360',
    name: 'Mozambique',
    data: {
      area: 801590,
      cioc: 'MOZ',
      gini: 45.7,
      latlng: [-18.25, 35],
      region: 'Africa',
      borders: ['MWI', 'ZAF', 'SWZ', 'TZA', 'ZMB', 'ZWE'],
      capital: 'Maputo',
      demonym: 'Mozambican',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'MZ',
      currencies: [
        {
          code: 'MZN',
          name: 'Mozambican metical',
          symbol: 'MT',
        },
      ],
      nativeName: 'Moçambique',
      population: 26423700,
      numericCode: '508',
      altSpellings: ['MZ', 'Republic of Mozambique', 'República de Moçambique'],
      translations: {
        br: 'Moçambique',
        de: 'Mosambik',
        es: 'Mozambique',
        fa: 'موزامبیک',
        fr: 'Mozambique',
        hr: 'Mozambik',
        it: 'Mozambico',
        ja: 'モザンビーク',
        nl: 'Mozambique',
        pt: 'Moçambique',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mz'],
    },
    alpha3: 'MOZ',
    areaCode: '258',
    picture: 'https://restcountries.eu/data/moz.svg',
  },
  {
    id: '831c0c30-f09f-4e0f-ad54-14341715393d',
    name: 'Myanmar',
    data: {
      area: 676578,
      cioc: 'MYA',
      gini: null,
      latlng: [22, 98],
      region: 'Asia',
      borders: ['BGD', 'CHN', 'IND', 'LAO', 'THA'],
      capital: 'Naypyidaw',
      demonym: 'Burmese',
      languages: [
        {
          name: 'Burmese',
          iso639_1: 'my',
          iso639_2: 'mya',
          nativeName: 'ဗမာစာ',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+06:30'],
      alpha2Code: 'MM',
      currencies: [
        {
          code: 'MMK',
          name: 'Burmese kyat',
          symbol: 'Ks',
        },
      ],
      nativeName: 'Myanma',
      population: 51419420,
      numericCode: '104',
      altSpellings: [
        'MM',
        'Burma',
        'Republic of the Union of Myanmar',
        'Pyidaunzu Thanmăda Myăma Nainngandaw',
      ],
      translations: {
        br: 'Myanmar',
        de: 'Myanmar',
        es: 'Myanmar',
        fa: 'میانمار',
        fr: 'Myanmar',
        hr: 'Mijanmar',
        it: 'Birmania',
        ja: 'ミャンマー',
        nl: 'Myanmar',
        pt: 'Myanmar',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.mm'],
    },
    alpha3: 'MMR',
    areaCode: '95',
    picture: 'https://restcountries.eu/data/mmr.svg',
  },
  {
    id: '2622b425-5aa7-43fe-ac7e-018829f7317f',
    name: 'Namibia',
    data: {
      area: 825615,
      cioc: 'NAM',
      gini: 63.9,
      latlng: [-22, 17],
      region: 'Africa',
      borders: ['AGO', 'BWA', 'ZAF', 'ZMB'],
      capital: 'Windhoek',
      demonym: 'Namibian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Afrikaans',
          iso639_1: 'af',
          iso639_2: 'afr',
          nativeName: 'Afrikaans',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'NA',
      currencies: [
        {
          code: 'NAD',
          name: 'Namibian dollar',
          symbol: '$',
        },
        {
          code: 'ZAR',
          name: 'South African rand',
          symbol: 'R',
        },
      ],
      nativeName: 'Namibia',
      population: 2324388,
      numericCode: '516',
      altSpellings: ['NA', 'Namibië', 'Republic of Namibia'],
      translations: {
        br: 'Namíbia',
        de: 'Namibia',
        es: 'Namibia',
        fa: 'نامیبیا',
        fr: 'Namibie',
        hr: 'Namibija',
        it: 'Namibia',
        ja: 'ナミビア',
        nl: 'Namibië',
        pt: 'Namíbia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.na'],
    },
    alpha3: 'NAM',
    areaCode: '264',
    picture: 'https://restcountries.eu/data/nam.svg',
  },
  {
    id: '42d57321-4acb-41ea-b5d0-d3265397059b',
    name: 'Nauru',
    data: {
      area: 21,
      cioc: 'NRU',
      gini: null,
      latlng: [-0.53333333, 166.91666666],
      region: 'Oceania',
      borders: [],
      capital: 'Yaren',
      demonym: 'Nauruan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Nauruan',
          iso639_1: 'na',
          iso639_2: 'nau',
          nativeName: 'Dorerin Naoero',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+12:00'],
      alpha2Code: 'NR',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
        {
          code: '(none)',
          name: null,
          symbol: '$',
        },
      ],
      nativeName: 'Nauru',
      population: 10084,
      numericCode: '520',
      altSpellings: [
        'NR',
        'Naoero',
        'Pleasant Island',
        'Republic of Nauru',
        'Ripublik Naoero',
      ],
      translations: {
        br: 'Nauru',
        de: 'Nauru',
        es: 'Nauru',
        fa: 'نائورو',
        fr: 'Nauru',
        hr: 'Nauru',
        it: 'Nauru',
        ja: 'ナウル',
        nl: 'Nauru',
        pt: 'Nauru',
      },
      regionalBlocs: [],
      topLevelDomain: ['.nr'],
    },
    alpha3: 'NRU',
    areaCode: '674',
    picture: 'https://restcountries.eu/data/nru.svg',
  },
  {
    id: '4aeba888-ee73-4102-ad21-06abba75f1a0',
    name: 'Nepal',
    data: {
      area: 147181,
      cioc: 'NEP',
      gini: 32.8,
      latlng: [28, 84],
      region: 'Asia',
      borders: ['CHN', 'IND'],
      capital: 'Kathmandu',
      demonym: 'Nepalese',
      languages: [
        {
          name: 'Nepali',
          iso639_1: 'ne',
          iso639_2: 'nep',
          nativeName: 'नेपाली',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+05:45'],
      alpha2Code: 'NP',
      currencies: [
        {
          code: 'NPR',
          name: 'Nepalese rupee',
          symbol: '₨',
        },
      ],
      nativeName: 'नेपाल',
      population: 28431500,
      numericCode: '524',
      altSpellings: [
        'NP',
        'Federal Democratic Republic of Nepal',
        'Loktāntrik Ganatantra Nepāl',
      ],
      translations: {
        br: 'Nepal',
        de: 'Népal',
        es: 'Nepal',
        fa: 'نپال',
        fr: 'Népal',
        hr: 'Nepal',
        it: 'Nepal',
        ja: 'ネパール',
        nl: 'Nepal',
        pt: 'Nepal',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.np'],
    },
    alpha3: 'NPL',
    areaCode: '977',
    picture: 'https://restcountries.eu/data/npl.svg',
  },
  {
    id: '395d47c6-3d35-40a8-a92c-ba82e725e39f',
    name: 'Netherlands',
    data: {
      area: 41850,
      cioc: 'NED',
      gini: 30.9,
      latlng: [52.5, 5.75],
      region: 'Europe',
      borders: ['BEL', 'DEU'],
      capital: 'Amsterdam',
      demonym: 'Dutch',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC-04:00', 'UTC+01:00'],
      alpha2Code: 'NL',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Nederland',
      population: 17019800,
      numericCode: '528',
      altSpellings: ['NL', 'Holland', 'Nederland'],
      translations: {
        br: 'Holanda',
        de: 'Niederlande',
        es: 'Países Bajos',
        fa: 'پادشاهی هلند',
        fr: 'Pays-Bas',
        hr: 'Nizozemska',
        it: 'Paesi Bassi',
        ja: 'オランダ',
        nl: 'Nederland',
        pt: 'Países Baixos',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.nl'],
    },
    alpha3: 'NLD',
    areaCode: '31',
    picture: 'https://restcountries.eu/data/nld.svg',
  },
  {
    id: '2da9c494-425e-4fb8-8bf8-23dc60bd770a',
    name: 'New Caledonia',
    data: {
      area: 18575,
      cioc: '',
      gini: null,
      latlng: [-21.5, 165.5],
      region: 'Oceania',
      borders: [],
      capital: 'Nouméa',
      demonym: 'New Caledonian',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Melanesia',
      timezones: ['UTC+11:00'],
      alpha2Code: 'NC',
      currencies: [
        {
          code: 'XPF',
          name: 'CFP franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Nouvelle-Calédonie',
      population: 268767,
      numericCode: '540',
      altSpellings: ['NC'],
      translations: {
        br: 'Nova Caledônia',
        de: 'Neukaledonien',
        es: 'Nueva Caledonia',
        fa: 'کالدونیای جدید',
        fr: 'Nouvelle-Calédonie',
        hr: 'Nova Kaledonija',
        it: 'Nuova Caledonia',
        ja: 'ニューカレドニア',
        nl: 'Nieuw-Caledonië',
        pt: 'Nova Caledónia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.nc'],
    },
    alpha3: 'NCL',
    areaCode: '687',
    picture: 'https://restcountries.eu/data/ncl.svg',
  },
  {
    id: '10470de4-c175-4f49-9006-29052549e065',
    name: 'New Zealand',
    data: {
      area: 270467,
      cioc: 'NZL',
      gini: 36.2,
      latlng: [-41, 174],
      region: 'Oceania',
      borders: [],
      capital: 'Wellington',
      demonym: 'New Zealander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Māori',
          iso639_1: 'mi',
          iso639_2: 'mri',
          nativeName: 'te reo Māori',
        },
      ],
      subregion: 'Australia and New Zealand',
      timezones: [
        'UTC-11:00',
        'UTC-10:00',
        'UTC+12:00',
        'UTC+12:45',
        'UTC+13:00',
      ],
      alpha2Code: 'NZ',
      currencies: [
        {
          code: 'NZD',
          name: 'New Zealand dollar',
          symbol: '$',
        },
      ],
      nativeName: 'New Zealand',
      population: 4697854,
      numericCode: '554',
      altSpellings: ['NZ', 'Aotearoa'],
      translations: {
        br: 'Nova Zelândia',
        de: 'Neuseeland',
        es: 'Nueva Zelanda',
        fa: 'نیوزیلند',
        fr: 'Nouvelle-Zélande',
        hr: 'Novi Zeland',
        it: 'Nuova Zelanda',
        ja: 'ニュージーランド',
        nl: 'Nieuw-Zeeland',
        pt: 'Nova Zelândia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.nz'],
    },
    alpha3: 'NZL',
    areaCode: '64',
    picture: 'https://restcountries.eu/data/nzl.svg',
  },
  {
    id: '3875e485-6fa0-4944-86dd-6df364fe67c0',
    name: 'Nicaragua',
    data: {
      area: 130373,
      cioc: 'NCA',
      gini: 40.5,
      latlng: [13, -85],
      region: 'Americas',
      borders: ['CRI', 'HND'],
      capital: 'Managua',
      demonym: 'Nicaraguan',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-06:00'],
      alpha2Code: 'NI',
      currencies: [
        {
          code: 'NIO',
          name: 'Nicaraguan córdoba',
          symbol: 'C$',
        },
      ],
      nativeName: 'Nicaragua',
      population: 6262703,
      numericCode: '558',
      altSpellings: ['NI', 'Republic of Nicaragua', 'República de Nicaragua'],
      translations: {
        br: 'Nicarágua',
        de: 'Nicaragua',
        es: 'Nicaragua',
        fa: 'نیکاراگوئه',
        fr: 'Nicaragua',
        hr: 'Nikaragva',
        it: 'Nicaragua',
        ja: 'ニカラグア',
        nl: 'Nicaragua',
        pt: 'Nicarágua',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.ni'],
    },
    alpha3: 'NIC',
    areaCode: '505',
    picture: 'https://restcountries.eu/data/nic.svg',
  },
  {
    id: 'b750b65b-ea72-4119-83f8-7cda19a0d842',
    name: 'Niger',
    data: {
      area: 1267000,
      cioc: 'NIG',
      gini: 34.6,
      latlng: [16, 8],
      region: 'Africa',
      borders: ['DZA', 'BEN', 'BFA', 'TCD', 'LBY', 'MLI', 'NGA'],
      capital: 'Niamey',
      demonym: 'Nigerien',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'NE',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Niger',
      population: 20715000,
      numericCode: '562',
      altSpellings: ['NE', 'Nijar', 'Republic of Niger', 'République du Niger'],
      translations: {
        br: 'Níger',
        de: 'Niger',
        es: 'Níger',
        fa: 'نیجر',
        fr: 'Niger',
        hr: 'Niger',
        it: 'Niger',
        ja: 'ニジェール',
        nl: 'Niger',
        pt: 'Níger',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ne'],
    },
    alpha3: 'NER',
    areaCode: '227',
    picture: 'https://restcountries.eu/data/ner.svg',
  },
  {
    id: '73e7a95f-86c9-4275-8e19-81f28723151d',
    name: 'Nigeria',
    data: {
      area: 923768,
      cioc: 'NGR',
      gini: 48.8,
      latlng: [10, 8],
      region: 'Africa',
      borders: ['BEN', 'CMR', 'TCD', 'NER'],
      capital: 'Abuja',
      demonym: 'Nigerian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'NG',
      currencies: [
        {
          code: 'NGN',
          name: 'Nigerian naira',
          symbol: '₦',
        },
      ],
      nativeName: 'Nigeria',
      population: 186988000,
      numericCode: '566',
      altSpellings: [
        'NG',
        'Nijeriya',
        'Naíjíríà',
        'Federal Republic of Nigeria',
      ],
      translations: {
        br: 'Nigéria',
        de: 'Nigeria',
        es: 'Nigeria',
        fa: 'نیجریه',
        fr: 'Nigéria',
        hr: 'Nigerija',
        it: 'Nigeria',
        ja: 'ナイジェリア',
        nl: 'Nigeria',
        pt: 'Nigéria',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ng'],
    },
    alpha3: 'NGA',
    areaCode: '234',
    picture: 'https://restcountries.eu/data/nga.svg',
  },
  {
    id: '7ed8f332-6186-4005-b581-b9d2b2e8a8d5',
    name: 'Niue',
    data: {
      area: 260,
      cioc: '',
      gini: null,
      latlng: [-19.03333333, -169.86666666],
      region: 'Oceania',
      borders: [],
      capital: 'Alofi',
      demonym: 'Niuean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC-11:00'],
      alpha2Code: 'NU',
      currencies: [
        {
          code: 'NZD',
          name: 'New Zealand dollar',
          symbol: '$',
        },
        {
          code: '(none)',
          name: 'Niue dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Niuē',
      population: 1470,
      numericCode: '570',
      altSpellings: ['NU'],
      translations: {
        br: 'Niue',
        de: 'Niue',
        es: 'Niue',
        fa: 'نیووی',
        fr: 'Niue',
        hr: 'Niue',
        it: 'Niue',
        ja: 'ニウエ',
        nl: 'Niue',
        pt: 'Niue',
      },
      regionalBlocs: [],
      topLevelDomain: ['.nu'],
    },
    alpha3: 'NIU',
    areaCode: '683',
    picture: 'https://restcountries.eu/data/niu.svg',
  },
  {
    id: '68b5ece4-d881-451e-918c-ca74cef79faa',
    name: 'Norfolk Island',
    data: {
      area: 36,
      cioc: '',
      gini: null,
      latlng: [-29.03333333, 167.95],
      region: 'Oceania',
      borders: [],
      capital: 'Kingston',
      demonym: 'Norfolk Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Australia and New Zealand',
      timezones: ['UTC+11:30'],
      alpha2Code: 'NF',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Norfolk Island',
      population: 2302,
      numericCode: '574',
      altSpellings: [
        'NF',
        'Territory of Norfolk Island',
        "Teratri of Norf'k Ailen",
      ],
      translations: {
        br: 'Ilha Norfolk',
        de: 'Norfolkinsel',
        es: 'Isla de Norfolk',
        fa: 'جزیره نورفک',
        fr: 'Île de Norfolk',
        hr: 'Otok Norfolk',
        it: 'Isola Norfolk',
        ja: 'ノーフォーク島',
        nl: 'Norfolkeiland',
        pt: 'Ilha Norfolk',
      },
      regionalBlocs: [],
      topLevelDomain: ['.nf'],
    },
    alpha3: 'NFK',
    areaCode: '672',
    picture: 'https://restcountries.eu/data/nfk.svg',
  },
  {
    id: '37853524-d79e-4cf6-91ff-da4572e9935b',
    name: "Korea (Democratic People's Republic of)",
    data: {
      area: 120538,
      cioc: 'PRK',
      gini: null,
      latlng: [40, 127],
      region: 'Asia',
      borders: ['CHN', 'KOR', 'RUS'],
      capital: 'Pyongyang',
      demonym: 'North Korean',
      languages: [
        {
          name: 'Korean',
          iso639_1: 'ko',
          iso639_2: 'kor',
          nativeName: '한국어',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+09:00'],
      alpha2Code: 'KP',
      currencies: [
        {
          code: 'KPW',
          name: 'North Korean won',
          symbol: '₩',
        },
      ],
      nativeName: '북한',
      population: 25281000,
      numericCode: '408',
      altSpellings: [
        'KP',
        "Democratic People's Republic of Korea",
        '조선민주주의인민공화국',
        'Chosŏn Minjujuŭi Inmin Konghwaguk',
      ],
      translations: {
        br: 'Coreia do Norte',
        de: 'Nordkorea',
        es: 'Corea del Norte',
        fa: 'کره جنوبی',
        fr: 'Corée du Nord',
        hr: 'Sjeverna Koreja',
        it: 'Corea del Nord',
        ja: '朝鮮民主主義人民共和国',
        nl: 'Noord-Korea',
        pt: 'Coreia do Norte',
      },
      regionalBlocs: [],
      topLevelDomain: ['.kp'],
    },
    alpha3: 'PRK',
    areaCode: '850',
    picture: 'https://restcountries.eu/data/prk.svg',
  },
  {
    id: 'ddfa937b-0f4d-4b4a-94d2-5f8b2b1abc79',
    name: 'Northern Mariana Islands',
    data: {
      area: 464,
      cioc: '',
      gini: null,
      latlng: [15.2, 145.75],
      region: 'Oceania',
      borders: [],
      capital: 'Saipan',
      demonym: 'American',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Chamorro',
          iso639_1: 'ch',
          iso639_2: 'cha',
          nativeName: 'Chamoru',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+10:00'],
      alpha2Code: 'MP',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Northern Mariana Islands',
      population: 56940,
      numericCode: '580',
      altSpellings: [
        'MP',
        'Commonwealth of the Northern Mariana Islands',
        'Sankattan Siha Na Islas Mariånas',
      ],
      translations: {
        br: 'Ilhas Marianas',
        de: 'Nördliche Marianen',
        es: 'Islas Marianas del Norte',
        fa: 'جزایر ماریانای شمالی',
        fr: 'Îles Mariannes du Nord',
        hr: 'Sjevernomarijanski otoci',
        it: 'Isole Marianne Settentrionali',
        ja: '北マリアナ諸島',
        nl: 'Noordelijke Marianeneilanden',
        pt: 'Ilhas Marianas',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mp'],
    },
    alpha3: 'MNP',
    areaCode: '1670',
    picture: 'https://restcountries.eu/data/mnp.svg',
  },
  {
    id: 'dea0e1f5-5a37-4d2a-86c6-745165397c66',
    name: 'Norway',
    data: {
      area: 323802,
      cioc: 'NOR',
      gini: 25.8,
      latlng: [62, 10],
      region: 'Europe',
      borders: ['FIN', 'SWE', 'RUS'],
      capital: 'Oslo',
      demonym: 'Norwegian',
      languages: [
        {
          name: 'Norwegian',
          iso639_1: 'no',
          iso639_2: 'nor',
          nativeName: 'Norsk',
        },
        {
          name: 'Norwegian Bokmål',
          iso639_1: 'nb',
          iso639_2: 'nob',
          nativeName: 'Norsk bokmål',
        },
        {
          name: 'Norwegian Nynorsk',
          iso639_1: 'nn',
          iso639_2: 'nno',
          nativeName: 'Norsk nynorsk',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'NO',
      currencies: [
        {
          code: 'NOK',
          name: 'Norwegian krone',
          symbol: 'kr',
        },
      ],
      nativeName: 'Norge',
      population: 5223256,
      numericCode: '578',
      altSpellings: [
        'NO',
        'Norge',
        'Noreg',
        'Kingdom of Norway',
        'Kongeriket Norge',
        'Kongeriket Noreg',
      ],
      translations: {
        br: 'Noruega',
        de: 'Norwegen',
        es: 'Noruega',
        fa: 'نروژ',
        fr: 'Norvège',
        hr: 'Norveška',
        it: 'Norvegia',
        ja: 'ノルウェー',
        nl: 'Noorwegen',
        pt: 'Noruega',
      },
      regionalBlocs: [
        {
          name: 'European Free Trade Association',
          acronym: 'EFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.no'],
    },
    alpha3: 'NOR',
    areaCode: '47',
    picture: 'https://restcountries.eu/data/nor.svg',
  },
  {
    id: 'd8c3de9f-ac7b-403b-8b0f-be220e40722a',
    name: 'Oman',
    data: {
      area: 309500,
      cioc: 'OMA',
      gini: null,
      latlng: [21, 57],
      region: 'Asia',
      borders: ['SAU', 'ARE', 'YEM'],
      capital: 'Muscat',
      demonym: 'Omani',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+04:00'],
      alpha2Code: 'OM',
      currencies: [
        {
          code: 'OMR',
          name: 'Omani rial',
          symbol: 'ر.ع.',
        },
      ],
      nativeName: 'عمان',
      population: 4420133,
      numericCode: '512',
      altSpellings: ['OM', 'Sultanate of Oman', 'Salṭanat ʻUmān'],
      translations: {
        br: 'Omã',
        de: 'Oman',
        es: 'Omán',
        fa: 'عمان',
        fr: 'Oman',
        hr: 'Oman',
        it: 'oman',
        ja: 'オマーン',
        nl: 'Oman',
        pt: 'Omã',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.om'],
    },
    alpha3: 'OMN',
    areaCode: '968',
    picture: 'https://restcountries.eu/data/omn.svg',
  },
  {
    id: '1ae0c6c3-e1fe-482a-a642-212eeb94844a',
    name: 'Pakistan',
    data: {
      area: 881912,
      cioc: 'PAK',
      gini: 30,
      latlng: [30, 70],
      region: 'Asia',
      borders: ['AFG', 'CHN', 'IND', 'IRN'],
      capital: 'Islamabad',
      demonym: 'Pakistani',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Urdu',
          iso639_1: 'ur',
          iso639_2: 'urd',
          nativeName: 'اردو',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+05:00'],
      alpha2Code: 'PK',
      currencies: [
        {
          code: 'PKR',
          name: 'Pakistani rupee',
          symbol: '₨',
        },
      ],
      nativeName: 'Pakistan',
      population: 194125062,
      numericCode: '586',
      altSpellings: [
        'PK',
        'Pākistān',
        'Islamic Republic of Pakistan',
        "Islāmī Jumhūriya'eh Pākistān",
      ],
      translations: {
        br: 'Paquistão',
        de: 'Pakistan',
        es: 'Pakistán',
        fa: 'پاکستان',
        fr: 'Pakistan',
        hr: 'Pakistan',
        it: 'Pakistan',
        ja: 'パキスタン',
        nl: 'Pakistan',
        pt: 'Paquistão',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.pk'],
    },
    alpha3: 'PAK',
    areaCode: '92',
    picture: 'https://restcountries.eu/data/pak.svg',
  },
  {
    id: 'a2926679-5f49-4d40-b81c-5a3f8ae3943c',
    name: 'Palau',
    data: {
      area: 459,
      cioc: 'PLW',
      gini: null,
      latlng: [7.5, 134.5],
      region: 'Oceania',
      borders: [],
      capital: 'Ngerulmud',
      demonym: 'Palauan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Micronesia',
      timezones: ['UTC+09:00'],
      alpha2Code: 'PW',
      currencies: [
        {
          code: '(none)',
          name: '[E]',
          symbol: '$',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Palau',
      population: 17950,
      numericCode: '585',
      altSpellings: ['PW', 'Republic of Palau', 'Beluu er a Belau'],
      translations: {
        br: 'Palau',
        de: 'Palau',
        es: 'Palau',
        fa: 'پالائو',
        fr: 'Palaos',
        hr: 'Palau',
        it: 'Palau',
        ja: 'パラオ',
        nl: 'Palau',
        pt: 'Palau',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pw'],
    },
    alpha3: 'PLW',
    areaCode: '680',
    picture: 'https://restcountries.eu/data/plw.svg',
  },
  {
    id: '58382965-caac-49df-8b3d-efd8f1205ee2',
    name: 'Palestine, State of',
    data: {
      area: null,
      cioc: 'PLE',
      gini: 35.5,
      latlng: [31.9, 35.2],
      region: 'Asia',
      borders: ['ISR', 'EGY', 'JOR'],
      capital: 'Ramallah',
      demonym: 'Palestinian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+02:00'],
      alpha2Code: 'PS',
      currencies: [
        {
          code: 'ILS',
          name: 'Israeli new sheqel',
          symbol: '₪',
        },
      ],
      nativeName: 'فلسطين',
      population: 4682467,
      numericCode: '275',
      altSpellings: ['PS', 'State of Palestine', 'Dawlat Filasṭin'],
      translations: {
        br: 'Palestina',
        de: 'Palästina',
        es: 'Palestina',
        fa: 'فلسطین',
        fr: 'Palestine',
        hr: 'Palestina',
        it: 'Palestina',
        ja: 'パレスチナ',
        nl: 'Palestijnse gebieden',
        pt: 'Palestina',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ps'],
    },
    alpha3: 'PSE',
    areaCode: '970',
    picture: 'https://restcountries.eu/data/pse.svg',
  },
  {
    id: '3251df54-2a0a-4c87-a66b-081fc0b036b4',
    name: 'Panama',
    data: {
      area: 75417,
      cioc: 'PAN',
      gini: 51.9,
      latlng: [9, -80],
      region: 'Americas',
      borders: ['COL', 'CRI'],
      capital: 'Panama City',
      demonym: 'Panamanian',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Central America',
      timezones: ['UTC-05:00'],
      alpha2Code: 'PA',
      currencies: [
        {
          code: 'PAB',
          name: 'Panamanian balboa',
          symbol: 'B/.',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Panamá',
      population: 3814672,
      numericCode: '591',
      altSpellings: ['PA', 'Republic of Panama', 'República de Panamá'],
      translations: {
        br: 'Panamá',
        de: 'Panama',
        es: 'Panamá',
        fa: 'پاناما',
        fr: 'Panama',
        hr: 'Panama',
        it: 'Panama',
        ja: 'パナマ',
        nl: 'Panama',
        pt: 'Panamá',
      },
      regionalBlocs: [
        {
          name: 'Central American Integration System',
          acronym: 'CAIS',
          otherNames: ['Sistema de la Integración Centroamericana,'],
          otherAcronyms: ['SICA'],
        },
      ],
      topLevelDomain: ['.pa'],
    },
    alpha3: 'PAN',
    areaCode: '507',
    picture: 'https://restcountries.eu/data/pan.svg',
  },
  {
    id: 'f09af98c-ad17-4e86-a221-3bec1dd9c008',
    name: 'Papua New Guinea',
    data: {
      area: 462840,
      cioc: 'PNG',
      gini: 50.9,
      latlng: [-6, 147],
      region: 'Oceania',
      borders: ['IDN'],
      capital: 'Port Moresby',
      demonym: 'Papua New Guinean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Melanesia',
      timezones: ['UTC+10:00'],
      alpha2Code: 'PG',
      currencies: [
        {
          code: 'PGK',
          name: 'Papua New Guinean kina',
          symbol: 'K',
        },
      ],
      nativeName: 'Papua Niugini',
      population: 8083700,
      numericCode: '598',
      altSpellings: [
        'PG',
        'Independent State of Papua New Guinea',
        'Independen Stet bilong Papua Niugini',
      ],
      translations: {
        br: 'Papua Nova Guiné',
        de: 'Papua-Neuguinea',
        es: 'Papúa Nueva Guinea',
        fa: 'پاپوآ گینه نو',
        fr: 'Papouasie-Nouvelle-Guinée',
        hr: 'Papua Nova Gvineja',
        it: 'Papua Nuova Guinea',
        ja: 'パプアニューギニア',
        nl: 'Papoea-Nieuw-Guinea',
        pt: 'Papua Nova Guiné',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pg'],
    },
    alpha3: 'PNG',
    areaCode: '675',
    picture: 'https://restcountries.eu/data/png.svg',
  },
  {
    id: '482f86e2-4f76-4f7a-813e-13044697b437',
    name: 'Paraguay',
    data: {
      area: 406752,
      cioc: 'PAR',
      gini: 52.4,
      latlng: [-23, -58],
      region: 'Americas',
      borders: ['ARG', 'BOL', 'BRA'],
      capital: 'Asunción',
      demonym: 'Paraguayan',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
        {
          name: 'Guaraní',
          iso639_1: 'gn',
          iso639_2: 'grn',
          nativeName: "Avañe'ẽ",
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'PY',
      currencies: [
        {
          code: 'PYG',
          name: 'Paraguayan guaraní',
          symbol: '₲',
        },
      ],
      nativeName: 'Paraguay',
      population: 6854536,
      numericCode: '600',
      altSpellings: [
        'PY',
        'Republic of Paraguay',
        'República del Paraguay',
        'Tetã Paraguái',
      ],
      translations: {
        br: 'Paraguai',
        de: 'Paraguay',
        es: 'Paraguay',
        fa: 'پاراگوئه',
        fr: 'Paraguay',
        hr: 'Paragvaj',
        it: 'Paraguay',
        ja: 'パラグアイ',
        nl: 'Paraguay',
        pt: 'Paraguai',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.py'],
    },
    alpha3: 'PRY',
    areaCode: '595',
    picture: 'https://restcountries.eu/data/pry.svg',
  },
  {
    id: 'db1b07d5-9f26-4a4f-9a9a-09e1a6ef4cf2',
    name: 'Peru',
    data: {
      area: 1285216,
      cioc: 'PER',
      gini: 48.1,
      latlng: [-10, -76],
      region: 'Americas',
      borders: ['BOL', 'BRA', 'CHL', 'COL', 'ECU'],
      capital: 'Lima',
      demonym: 'Peruvian',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-05:00'],
      alpha2Code: 'PE',
      currencies: [
        {
          code: 'PEN',
          name: 'Peruvian sol',
          symbol: 'S/.',
        },
      ],
      nativeName: 'Perú',
      population: 31488700,
      numericCode: '604',
      altSpellings: ['PE', 'Republic of Peru', ' República del Perú'],
      translations: {
        br: 'Peru',
        de: 'Peru',
        es: 'Perú',
        fa: 'پرو',
        fr: 'Pérou',
        hr: 'Peru',
        it: 'Perù',
        ja: 'ペルー',
        nl: 'Peru',
        pt: 'Peru',
      },
      regionalBlocs: [
        {
          name: 'Pacific Alliance',
          acronym: 'PA',
          otherNames: ['Alianza del Pacífico'],
          otherAcronyms: [],
        },
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.pe'],
    },
    alpha3: 'PER',
    areaCode: '51',
    picture: 'https://restcountries.eu/data/per.svg',
  },
  {
    id: '57f6a83c-0807-49ac-afe1-5b5c63b21ede',
    name: 'Philippines',
    data: {
      area: 342353,
      cioc: 'PHI',
      gini: 43,
      latlng: [13, 122],
      region: 'Asia',
      borders: [],
      capital: 'Manila',
      demonym: 'Filipino',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'PH',
      currencies: [
        {
          code: 'PHP',
          name: 'Philippine peso',
          symbol: '₱',
        },
      ],
      nativeName: 'Pilipinas',
      population: 103279800,
      numericCode: '608',
      altSpellings: [
        'PH',
        'Republic of the Philippines',
        'Repúblika ng Pilipinas',
      ],
      translations: {
        br: 'Filipinas',
        de: 'Philippinen',
        es: 'Filipinas',
        fa: 'جزایر الندفیلیپین',
        fr: 'Philippines',
        hr: 'Filipini',
        it: 'Filippine',
        ja: 'フィリピン',
        nl: 'Filipijnen',
        pt: 'Filipinas',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ph'],
    },
    alpha3: 'PHL',
    areaCode: '63',
    picture: 'https://restcountries.eu/data/phl.svg',
  },
  {
    id: '26460265-7e32-4253-989f-168002f05d68',
    name: 'Pitcairn',
    data: {
      area: 47,
      cioc: '',
      gini: null,
      latlng: [-25.06666666, -130.1],
      region: 'Oceania',
      borders: [],
      capital: 'Adamstown',
      demonym: 'Pitcairn Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC-08:00'],
      alpha2Code: 'PN',
      currencies: [
        {
          code: 'NZD',
          name: 'New Zealand dollar',
          symbol: '$',
        },
        {
          code: null,
          name: 'Pitcairn Islands dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Pitcairn Islands',
      population: 56,
      numericCode: '612',
      altSpellings: ['PN', 'Pitcairn Henderson Ducie and Oeno Islands'],
      translations: {
        br: 'Ilhas Pitcairn',
        de: 'Pitcairn',
        es: 'Islas Pitcairn',
        fa: 'پیتکرن',
        fr: 'Îles Pitcairn',
        hr: 'Pitcairnovo otočje',
        it: 'Isole Pitcairn',
        ja: 'ピトケアン',
        nl: 'Pitcairneilanden',
        pt: 'Ilhas Picárnia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pn'],
    },
    alpha3: 'PCN',
    areaCode: '64',
    picture: 'https://restcountries.eu/data/pcn.svg',
  },
  {
    id: 'be839a61-8fc1-4552-9427-a41156b1b0dc',
    name: 'Poland',
    data: {
      area: 312679,
      cioc: 'POL',
      gini: 34.1,
      latlng: [52, 20],
      region: 'Europe',
      borders: ['BLR', 'CZE', 'DEU', 'LTU', 'RUS', 'SVK', 'UKR'],
      capital: 'Warsaw',
      demonym: 'Polish',
      languages: [
        {
          name: 'Polish',
          iso639_1: 'pl',
          iso639_2: 'pol',
          nativeName: 'język polski',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'PL',
      currencies: [
        {
          code: 'PLN',
          name: 'Polish złoty',
          symbol: 'zł',
        },
      ],
      nativeName: 'Polska',
      population: 38437239,
      numericCode: '616',
      altSpellings: ['PL', 'Republic of Poland', 'Rzeczpospolita Polska'],
      translations: {
        br: 'Polônia',
        de: 'Polen',
        es: 'Polonia',
        fa: 'لهستان',
        fr: 'Pologne',
        hr: 'Poljska',
        it: 'Polonia',
        ja: 'ポーランド',
        nl: 'Polen',
        pt: 'Polónia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.pl'],
    },
    alpha3: 'POL',
    areaCode: '48',
    picture: 'https://restcountries.eu/data/pol.svg',
  },
  {
    id: 'e719226f-5158-4885-803a-8edb3abdc795',
    name: 'Portugal',
    data: {
      area: 92090,
      cioc: 'POR',
      gini: 38.5,
      latlng: [39.5, -8],
      region: 'Europe',
      borders: ['ESP'],
      capital: 'Lisbon',
      demonym: 'Portuguese',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC-01:00', 'UTC'],
      alpha2Code: 'PT',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Portugal',
      population: 10374822,
      numericCode: '620',
      altSpellings: [
        'PT',
        'Portuguesa',
        'Portuguese Republic',
        'República Portuguesa',
      ],
      translations: {
        br: 'Portugal',
        de: 'Portugal',
        es: 'Portugal',
        fa: 'پرتغال',
        fr: 'Portugal',
        hr: 'Portugal',
        it: 'Portogallo',
        ja: 'ポルトガル',
        nl: 'Portugal',
        pt: 'Portugal',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.pt'],
    },
    alpha3: 'PRT',
    areaCode: '351',
    picture: 'https://restcountries.eu/data/prt.svg',
  },
  {
    id: '12830ae4-0960-471b-b79c-2d415fe3f103',
    name: 'Puerto Rico',
    data: {
      area: 8870,
      cioc: 'PUR',
      gini: null,
      latlng: [18.25, -66.5],
      region: 'Americas',
      borders: [],
      capital: 'San Juan',
      demonym: 'Puerto Rican',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'PR',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Puerto Rico',
      population: 3474182,
      numericCode: '630',
      altSpellings: [
        'PR',
        'Commonwealth of Puerto Rico',
        'Estado Libre Asociado de Puerto Rico',
      ],
      translations: {
        br: 'Porto Rico',
        de: 'Puerto Rico',
        es: 'Puerto Rico',
        fa: 'پورتو ریکو',
        fr: 'Porto Rico',
        hr: 'Portoriko',
        it: 'Porto Rico',
        ja: 'プエルトリコ',
        nl: 'Puerto Rico',
        pt: 'Porto Rico',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pr'],
    },
    alpha3: 'PRI',
    areaCode: '1787',
    picture: 'https://restcountries.eu/data/pri.svg',
  },
  {
    id: 'c47232e1-688d-4e5d-81cf-68d1679937fa',
    name: 'Qatar',
    data: {
      area: 11586,
      cioc: 'QAT',
      gini: 41.1,
      latlng: [25.5, 51.25],
      region: 'Asia',
      borders: ['SAU'],
      capital: 'Doha',
      demonym: 'Qatari',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'QA',
      currencies: [
        {
          code: 'QAR',
          name: 'Qatari riyal',
          symbol: 'ر.ق',
        },
      ],
      nativeName: 'قطر',
      population: 2587564,
      numericCode: '634',
      altSpellings: ['QA', 'State of Qatar', 'Dawlat Qaṭar'],
      translations: {
        br: 'Catar',
        de: 'Katar',
        es: 'Catar',
        fa: 'قطر',
        fr: 'Qatar',
        hr: 'Katar',
        it: 'Qatar',
        ja: 'カタール',
        nl: 'Qatar',
        pt: 'Catar',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.qa'],
    },
    alpha3: 'QAT',
    areaCode: '974',
    picture: 'https://restcountries.eu/data/qat.svg',
  },
  {
    id: '5f211f3d-591d-4d23-93a5-d8387e2e5525',
    name: 'Republic of Kosovo',
    data: {
      area: 10908,
      cioc: null,
      gini: null,
      latlng: [42.666667, 21.166667],
      region: 'Europe',
      borders: ['ALB', 'MKD', 'MNE', 'SRB'],
      capital: 'Pristina',
      demonym: 'Kosovar',
      languages: [
        {
          name: 'Albanian',
          iso639_1: 'sq',
          iso639_2: 'sqi',
          nativeName: 'Shqip',
        },
        {
          name: 'Serbian',
          iso639_1: 'sr',
          iso639_2: 'srp',
          nativeName: 'српски језик',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'XK',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Republika e Kosovës',
      population: 1733842,
      numericCode: null,
      altSpellings: ['XK', 'Република Косово'],
      translations: {
        br: 'Kosovo',
        de: null,
        es: 'Kosovo',
        fa: 'کوزوو',
        fr: null,
        hr: 'Kosovo',
        it: null,
        ja: null,
        nl: null,
        pt: 'Kosovo',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: [''],
    },
    alpha3: 'KOS',
    areaCode: '383',
    picture: 'https://restcountries.eu/data/kos.svg',
  },
  {
    id: '085e90ec-8f46-45b5-ae5b-1e48b10e4d03',
    name: 'Réunion',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [-21.15, 55.5],
      region: 'Africa',
      borders: [],
      capital: 'Saint-Denis',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+04:00'],
      alpha2Code: 'RE',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'La Réunion',
      population: 840974,
      numericCode: '638',
      altSpellings: ['RE', 'Reunion'],
      translations: {
        br: 'Reunião',
        de: 'Réunion',
        es: 'Reunión',
        fa: 'رئونیون',
        fr: 'Réunion',
        hr: 'Réunion',
        it: 'Riunione',
        ja: 'レユニオン',
        nl: 'Réunion',
        pt: 'Reunião',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.re'],
    },
    alpha3: 'REU',
    areaCode: '262',
    picture: 'https://restcountries.eu/data/reu.svg',
  },
  {
    id: 'ada0d998-3461-4281-8e58-022390329e1d',
    name: 'Romania',
    data: {
      area: 238391,
      cioc: 'ROU',
      gini: 30,
      latlng: [46, 25],
      region: 'Europe',
      borders: ['BGR', 'HUN', 'MDA', 'SRB', 'UKR'],
      capital: 'Bucharest',
      demonym: 'Romanian',
      languages: [
        {
          name: 'Romanian',
          iso639_1: 'ro',
          iso639_2: 'ron',
          nativeName: 'Română',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'RO',
      currencies: [
        {
          code: 'RON',
          name: 'Romanian leu',
          symbol: 'lei',
        },
      ],
      nativeName: 'România',
      population: 19861408,
      numericCode: '642',
      altSpellings: ['RO', 'Rumania', 'Roumania', 'România'],
      translations: {
        br: 'Romênia',
        de: 'Rumänien',
        es: 'Rumania',
        fa: 'رومانی',
        fr: 'Roumanie',
        hr: 'Rumunjska',
        it: 'Romania',
        ja: 'ルーマニア',
        nl: 'Roemenië',
        pt: 'Roménia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ro'],
    },
    alpha3: 'ROU',
    areaCode: '40',
    picture: 'https://restcountries.eu/data/rou.svg',
  },
  {
    id: '62e131c9-f21b-457d-9108-06bac420a212',
    name: 'Russian Federation',
    data: {
      area: 17124442,
      cioc: 'RUS',
      gini: 40.1,
      latlng: [60, 100],
      region: 'Europe',
      borders: [
        'AZE',
        'BLR',
        'CHN',
        'EST',
        'FIN',
        'GEO',
        'KAZ',
        'PRK',
        'LVA',
        'LTU',
        'MNG',
        'NOR',
        'POL',
        'UKR',
      ],
      capital: 'Moscow',
      demonym: 'Russian',
      languages: [
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: [
        'UTC+03:00',
        'UTC+04:00',
        'UTC+06:00',
        'UTC+07:00',
        'UTC+08:00',
        'UTC+09:00',
        'UTC+10:00',
        'UTC+11:00',
        'UTC+12:00',
      ],
      alpha2Code: 'RU',
      currencies: [
        {
          code: 'RUB',
          name: 'Russian ruble',
          symbol: '₽',
        },
      ],
      nativeName: 'Россия',
      population: 146599183,
      numericCode: '643',
      altSpellings: [
        'RU',
        'Rossiya',
        'Russian Federation',
        'Российская Федерация',
        'Rossiyskaya Federatsiya',
      ],
      translations: {
        br: 'Rússia',
        de: 'Russland',
        es: 'Rusia',
        fa: 'روسیه',
        fr: 'Russie',
        hr: 'Rusija',
        it: 'Russia',
        ja: 'ロシア連邦',
        nl: 'Rusland',
        pt: 'Rússia',
      },
      regionalBlocs: [
        {
          name: 'Eurasian Economic Union',
          acronym: 'EEU',
          otherNames: [],
          otherAcronyms: ['EAEU'],
        },
      ],
      topLevelDomain: ['.ru'],
    },
    alpha3: 'RUS',
    areaCode: '7',
    picture: 'https://restcountries.eu/data/rus.svg',
  },
  {
    id: '430a032d-5046-48ae-a657-8d611442ba4a',
    name: 'Rwanda',
    data: {
      area: 26338,
      cioc: 'RWA',
      gini: 50.8,
      latlng: [-2, 30],
      region: 'Africa',
      borders: ['BDI', 'COD', 'TZA', 'UGA'],
      capital: 'Kigali',
      demonym: 'Rwandan',
      languages: [
        {
          name: 'Kinyarwanda',
          iso639_1: 'rw',
          iso639_2: 'kin',
          nativeName: 'Ikinyarwanda',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'RW',
      currencies: [
        {
          code: 'RWF',
          name: 'Rwandan franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Rwanda',
      population: 11553188,
      numericCode: '646',
      altSpellings: [
        'RW',
        'Republic of Rwanda',
        "Repubulika y'u Rwanda",
        'République du Rwanda',
      ],
      translations: {
        br: 'Ruanda',
        de: 'Ruanda',
        es: 'Ruanda',
        fa: 'رواندا',
        fr: 'Rwanda',
        hr: 'Ruanda',
        it: 'Ruanda',
        ja: 'ルワンダ',
        nl: 'Rwanda',
        pt: 'Ruanda',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.rw'],
    },
    alpha3: 'RWA',
    areaCode: '250',
    picture: 'https://restcountries.eu/data/rwa.svg',
  },
  {
    id: '1f4c0b1b-fa59-4523-9db1-5ce61f1f39bc',
    name: 'Saint Barthélemy',
    data: {
      area: 21,
      cioc: '',
      gini: null,
      latlng: [18.5, -63.41666666],
      region: 'Americas',
      borders: [],
      capital: 'Gustavia',
      demonym: 'Saint Barthélemy Islander',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'BL',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Saint-Barthélemy',
      population: 9417,
      numericCode: '652',
      altSpellings: [
        'BL',
        'St. Barthelemy',
        'Collectivity of Saint Barthélemy',
        'Collectivité de Saint-Barthélemy',
      ],
      translations: {
        br: 'São Bartolomeu',
        de: 'Saint-Barthélemy',
        es: 'San Bartolomé',
        fa: 'سن-بارتلمی',
        fr: 'Saint-Barthélemy',
        hr: 'Saint Barthélemy',
        it: 'Antille Francesi',
        ja: 'サン・バルテルミー',
        nl: 'Saint Barthélemy',
        pt: 'São Bartolomeu',
      },
      regionalBlocs: [],
      topLevelDomain: ['.bl'],
    },
    alpha3: 'BLM',
    areaCode: '590',
    picture: 'https://restcountries.eu/data/blm.svg',
  },
  {
    id: 'c7ca9f36-eccd-4ef0-8f62-43d42697cea8',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    data: {
      area: null,
      cioc: null,
      gini: null,
      latlng: [-15.95, -5.7],
      region: 'Africa',
      borders: [],
      capital: 'Jamestown',
      demonym: 'Saint Helenian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC+00:00'],
      alpha2Code: 'SH',
      currencies: [
        {
          code: 'SHP',
          name: 'Saint Helena pound',
          symbol: '£',
        },
      ],
      nativeName: 'Saint Helena',
      population: 4255,
      numericCode: '654',
      altSpellings: ['SH'],
      translations: {
        br: 'Santa Helena',
        de: 'Sankt Helena',
        es: 'Santa Helena',
        fa: 'سنت هلنا، اسنشن و تریستان دا کونا',
        fr: 'Sainte-Hélène',
        hr: 'Sveta Helena',
        it: "Sant'Elena",
        ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
        nl: 'Sint-Helena',
        pt: 'Santa Helena',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sh'],
    },
    alpha3: 'SHN',
    areaCode: '290',
    picture: 'https://restcountries.eu/data/shn.svg',
  },
  {
    id: 'f38fc032-059e-4afc-855c-47785d83068e',
    name: 'Saint Kitts and Nevis',
    data: {
      area: 261,
      cioc: 'SKN',
      gini: null,
      latlng: [17.33333333, -62.75],
      region: 'Americas',
      borders: [],
      capital: 'Basseterre',
      demonym: 'Kittian and Nevisian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'KN',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Saint Kitts and Nevis',
      population: 46204,
      numericCode: '659',
      altSpellings: ['KN', 'Federation of Saint Christopher and Nevis'],
      translations: {
        br: 'São Cristóvão e Neves',
        de: 'St. Kitts und Nevis',
        es: 'San Cristóbal y Nieves',
        fa: 'سنت کیتس و نویس',
        fr: 'Saint-Christophe-et-Niévès',
        hr: 'Sveti Kristof i Nevis',
        it: 'Saint Kitts e Nevis',
        ja: 'セントクリストファー・ネイビス',
        nl: 'Saint Kitts en Nevis',
        pt: 'São Cristóvão e Neves',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.kn'],
    },
    alpha3: 'KNA',
    areaCode: '1869',
    picture: 'https://restcountries.eu/data/kna.svg',
  },
  {
    id: '5e2fbcd6-27fe-4187-97cd-a8ebca269107',
    name: 'Saint Lucia',
    data: {
      area: 616,
      cioc: 'LCA',
      gini: 42.6,
      latlng: [13.88333333, -60.96666666],
      region: 'Americas',
      borders: [],
      capital: 'Castries',
      demonym: 'Saint Lucian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'LC',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Saint Lucia',
      population: 186000,
      numericCode: '662',
      altSpellings: ['LC'],
      translations: {
        br: 'Santa Lúcia',
        de: 'Saint Lucia',
        es: 'Santa Lucía',
        fa: 'سنت لوسیا',
        fr: 'Saint-Lucie',
        hr: 'Sveta Lucija',
        it: 'Santa Lucia',
        ja: 'セントルシア',
        nl: 'Saint Lucia',
        pt: 'Santa Lúcia',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lc'],
    },
    alpha3: 'LCA',
    areaCode: '1758',
    picture: 'https://restcountries.eu/data/lca.svg',
  },
  {
    id: '9d27513d-6043-409b-9708-cbdddb076a2e',
    name: 'Saint Martin (French part)',
    data: {
      area: 53,
      cioc: '',
      gini: null,
      latlng: [18.08333333, -63.95],
      region: 'Americas',
      borders: ['SXM', 'NLD'],
      capital: 'Marigot',
      demonym: 'Saint Martin Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'MF',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Saint-Martin',
      population: 36979,
      numericCode: '663',
      altSpellings: [
        'MF',
        'Collectivity of Saint Martin',
        'Collectivité de Saint-Martin',
      ],
      translations: {
        br: 'Saint Martin',
        de: 'Saint Martin',
        es: 'Saint Martin',
        fa: 'سینت مارتن',
        fr: 'Saint-Martin',
        hr: 'Sveti Martin',
        it: 'Saint Martin',
        ja: 'サン・マルタン（フランス領）',
        nl: 'Saint-Martin',
        pt: 'Ilha São Martinho',
      },
      regionalBlocs: [],
      topLevelDomain: ['.mf', '.fr', '.gp'],
    },
    alpha3: 'MAF',
    areaCode: '590',
    picture: 'https://restcountries.eu/data/maf.svg',
  },
  {
    id: '091eee61-0782-4535-ae9d-4f1acf7460b0',
    name: 'Saint Pierre and Miquelon',
    data: {
      area: 242,
      cioc: '',
      gini: null,
      latlng: [46.83333333, -56.33333333],
      region: 'Americas',
      borders: [],
      capital: 'Saint-Pierre',
      demonym: 'French',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Northern America',
      timezones: ['UTC-03:00'],
      alpha2Code: 'PM',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Saint-Pierre-et-Miquelon',
      population: 6069,
      numericCode: '666',
      altSpellings: [
        'PM',
        'Collectivité territoriale de Saint-Pierre-et-Miquelon',
      ],
      translations: {
        br: 'Saint-Pierre e Miquelon',
        de: 'Saint-Pierre und Miquelon',
        es: 'San Pedro y Miquelón',
        fa: 'سن پیر و میکلن',
        fr: 'Saint-Pierre-et-Miquelon',
        hr: 'Sveti Petar i Mikelon',
        it: 'Saint-Pierre e Miquelon',
        ja: 'サンピエール島・ミクロン島',
        nl: 'Saint Pierre en Miquelon',
        pt: 'São Pedro e Miquelon',
      },
      regionalBlocs: [],
      topLevelDomain: ['.pm'],
    },
    alpha3: 'SPM',
    areaCode: '508',
    picture: 'https://restcountries.eu/data/spm.svg',
  },
  {
    id: '39d5fc07-aeb1-4a52-8092-3e2a35c72552',
    name: 'Saint Vincent and the Grenadines',
    data: {
      area: 389,
      cioc: 'VIN',
      gini: null,
      latlng: [13.25, -61.2],
      region: 'Americas',
      borders: [],
      capital: 'Kingstown',
      demonym: 'Saint Vincentian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'VC',
      currencies: [
        {
          code: 'XCD',
          name: 'East Caribbean dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Saint Vincent and the Grenadines',
      population: 109991,
      numericCode: '670',
      altSpellings: ['VC'],
      translations: {
        br: 'São Vicente e Granadinas',
        de: 'Saint Vincent und die Grenadinen',
        es: 'San Vicente y Granadinas',
        fa: 'سنت وینسنت و گرنادین‌ها',
        fr: 'Saint-Vincent-et-les-Grenadines',
        hr: 'Sveti Vincent i Grenadini',
        it: 'Saint Vincent e Grenadine',
        ja: 'セントビンセントおよびグレナディーン諸島',
        nl: 'Saint Vincent en de Grenadines',
        pt: 'São Vicente e Granadinas',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.vc'],
    },
    alpha3: 'VCT',
    areaCode: '1784',
    picture: 'https://restcountries.eu/data/vct.svg',
  },
  {
    id: 'd0c23a9d-919e-41ed-b8f6-2e00381dcea8',
    name: 'Samoa',
    data: {
      area: 2842,
      cioc: 'SAM',
      gini: null,
      latlng: [-13.58333333, -172.33333333],
      region: 'Oceania',
      borders: [],
      capital: 'Apia',
      demonym: 'Samoan',
      languages: [
        {
          name: 'Samoan',
          iso639_1: 'sm',
          iso639_2: 'smo',
          nativeName: "gagana fa'a Samoa",
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC+13:00'],
      alpha2Code: 'WS',
      currencies: [
        {
          code: 'WST',
          name: 'Samoan tālā',
          symbol: 'T',
        },
      ],
      nativeName: 'Samoa',
      population: 194899,
      numericCode: '882',
      altSpellings: [
        'WS',
        'Independent State of Samoa',
        'Malo Saʻoloto Tutoʻatasi o Sāmoa',
      ],
      translations: {
        br: 'Samoa',
        de: 'Samoa',
        es: 'Samoa',
        fa: 'ساموآ',
        fr: 'Samoa',
        hr: 'Samoa',
        it: 'Samoa',
        ja: 'サモア',
        nl: 'Samoa',
        pt: 'Samoa',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ws'],
    },
    alpha3: 'WSM',
    areaCode: '685',
    picture: 'https://restcountries.eu/data/wsm.svg',
  },
  {
    id: '9f2ab1cc-8943-4d0f-8666-3900685840b7',
    name: 'San Marino',
    data: {
      area: 61,
      cioc: 'SMR',
      gini: null,
      latlng: [43.76666666, 12.41666666],
      region: 'Europe',
      borders: ['ITA'],
      capital: 'City of San Marino',
      demonym: 'Sammarinese',
      languages: [
        {
          name: 'Italian',
          iso639_1: 'it',
          iso639_2: 'ita',
          nativeName: 'Italiano',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'SM',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'San Marino',
      population: 33005,
      numericCode: '674',
      altSpellings: [
        'SM',
        'Republic of San Marino',
        'Repubblica di San Marino',
      ],
      translations: {
        br: 'San Marino',
        de: 'San Marino',
        es: 'San Marino',
        fa: 'سان مارینو',
        fr: 'Saint-Marin',
        hr: 'San Marino',
        it: 'San Marino',
        ja: 'サンマリノ',
        nl: 'San Marino',
        pt: 'São Marinho',
      },
      regionalBlocs: [],
      topLevelDomain: ['.sm'],
    },
    alpha3: 'SMR',
    areaCode: '378',
    picture: 'https://restcountries.eu/data/smr.svg',
  },
  {
    id: 'ddc5b245-c642-4531-8e37-36bf14c95ba8',
    name: 'Sao Tome and Principe',
    data: {
      area: 964,
      cioc: 'STP',
      gini: 50.8,
      latlng: [1, 7],
      region: 'Africa',
      borders: [],
      capital: 'São Tomé',
      demonym: 'Sao Tomean',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC'],
      alpha2Code: 'ST',
      currencies: [
        {
          code: 'STD',
          name: 'São Tomé and Príncipe dobra',
          symbol: 'Db',
        },
      ],
      nativeName: 'São Tomé e Príncipe',
      population: 187356,
      numericCode: '678',
      altSpellings: [
        'ST',
        'Democratic Republic of São Tomé and Príncipe',
        'República Democrática de São Tomé e Príncipe',
      ],
      translations: {
        br: 'São Tomé e Príncipe',
        de: 'São Tomé und Príncipe',
        es: 'Santo Tomé y Príncipe',
        fa: 'کواترو دو فرویرو',
        fr: 'Sao Tomé-et-Principe',
        hr: 'Sveti Toma i Princip',
        it: 'São Tomé e Príncipe',
        ja: 'サントメ・プリンシペ',
        nl: 'Sao Tomé en Principe',
        pt: 'São Tomé e Príncipe',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.st'],
    },
    alpha3: 'STP',
    areaCode: '239',
    picture: 'https://restcountries.eu/data/stp.svg',
  },
  {
    id: '5f8a87d6-51a4-494d-a59b-fddcc6363dc3',
    name: 'Saudi Arabia',
    data: {
      area: 2149690,
      cioc: 'KSA',
      gini: null,
      latlng: [25, 45],
      region: 'Asia',
      borders: ['IRQ', 'JOR', 'KWT', 'OMN', 'QAT', 'ARE', 'YEM'],
      capital: 'Riyadh',
      demonym: 'Saudi Arabian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'SA',
      currencies: [
        {
          code: 'SAR',
          name: 'Saudi riyal',
          symbol: 'ر.س',
        },
      ],
      nativeName: 'العربية السعودية',
      population: 32248200,
      numericCode: '682',
      altSpellings: [
        'SA',
        'Kingdom of Saudi Arabia',
        'Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah',
      ],
      translations: {
        br: 'Arábia Saudita',
        de: 'Saudi-Arabien',
        es: 'Arabia Saudí',
        fa: 'عربستان سعودی',
        fr: 'Arabie Saoudite',
        hr: 'Saudijska Arabija',
        it: 'Arabia Saudita',
        ja: 'サウジアラビア',
        nl: 'Saoedi-Arabië',
        pt: 'Arábia Saudita',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sa'],
    },
    alpha3: 'SAU',
    areaCode: '966',
    picture: 'https://restcountries.eu/data/sau.svg',
  },
  {
    id: '37b27127-74e0-4365-b49f-d5a0c412c9f5',
    name: 'Senegal',
    data: {
      area: 196722,
      cioc: 'SEN',
      gini: 39.2,
      latlng: [14, -14],
      region: 'Africa',
      borders: ['GMB', 'GIN', 'GNB', 'MLI', 'MRT'],
      capital: 'Dakar',
      demonym: 'Senegalese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'SN',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Sénégal',
      population: 14799859,
      numericCode: '686',
      altSpellings: ['SN', 'Republic of Senegal', 'République du Sénégal'],
      translations: {
        br: 'Senegal',
        de: 'Senegal',
        es: 'Senegal',
        fa: 'سنگال',
        fr: 'Sénégal',
        hr: 'Senegal',
        it: 'Senegal',
        ja: 'セネガル',
        nl: 'Senegal',
        pt: 'Senegal',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sn'],
    },
    alpha3: 'SEN',
    areaCode: '221',
    picture: 'https://restcountries.eu/data/sen.svg',
  },
  {
    id: '4392a8c5-d270-45b0-b1ab-6aa35001341e',
    name: 'Serbia',
    data: {
      area: 88361,
      cioc: 'SRB',
      gini: 27.8,
      latlng: [44, 21],
      region: 'Europe',
      borders: ['BIH', 'BGR', 'HRV', 'HUN', 'KOS', 'MKD', 'MNE', 'ROU'],
      capital: 'Belgrade',
      demonym: 'Serbian',
      languages: [
        {
          name: 'Serbian',
          iso639_1: 'sr',
          iso639_2: 'srp',
          nativeName: 'српски језик',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'RS',
      currencies: [
        {
          code: 'RSD',
          name: 'Serbian dinar',
          symbol: 'дин.',
        },
      ],
      nativeName: 'Србија',
      population: 7076372,
      numericCode: '688',
      altSpellings: [
        'RS',
        'Srbija',
        'Republic of Serbia',
        'Република Србија',
        'Republika Srbija',
      ],
      translations: {
        br: 'Sérvia',
        de: 'Serbien',
        es: 'Serbia',
        fa: 'صربستان',
        fr: 'Serbie',
        hr: 'Srbija',
        it: 'Serbia',
        ja: 'セルビア',
        nl: 'Servië',
        pt: 'Sérvia',
      },
      regionalBlocs: [
        {
          name: 'Central European Free Trade Agreement',
          acronym: 'CEFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.rs'],
    },
    alpha3: 'SRB',
    areaCode: '381',
    picture: 'https://restcountries.eu/data/srb.svg',
  },
  {
    id: '4c64745a-ee9e-4cd6-882d-7614b134c198',
    name: 'Seychelles',
    data: {
      area: 452,
      cioc: 'SEY',
      gini: 65.8,
      latlng: [-4.58333333, 55.66666666],
      region: 'Africa',
      borders: [],
      capital: 'Victoria',
      demonym: 'Seychellois',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+04:00'],
      alpha2Code: 'SC',
      currencies: [
        {
          code: 'SCR',
          name: 'Seychellois rupee',
          symbol: '₨',
        },
      ],
      nativeName: 'Seychelles',
      population: 91400,
      numericCode: '690',
      altSpellings: [
        'SC',
        'Republic of Seychelles',
        'Repiblik Sesel',
        'République des Seychelles',
      ],
      translations: {
        br: 'Seicheles',
        de: 'Seychellen',
        es: 'Seychelles',
        fa: 'سیشل',
        fr: 'Seychelles',
        hr: 'Sejšeli',
        it: 'Seychelles',
        ja: 'セーシェル',
        nl: 'Seychellen',
        pt: 'Seicheles',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sc'],
    },
    alpha3: 'SYC',
    areaCode: '248',
    picture: 'https://restcountries.eu/data/syc.svg',
  },
  {
    id: '0bc1e100-cd26-480d-85f5-5b4725d619f3',
    name: 'Spain',
    data: {
      area: 505992,
      cioc: 'ESP',
      gini: 34.7,
      latlng: [40, -4],
      region: 'Europe',
      borders: ['AND', 'FRA', 'GIB', 'PRT', 'MAR'],
      capital: 'Madrid',
      demonym: 'Spanish',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC', 'UTC+01:00'],
      alpha2Code: 'ES',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'España',
      population: 46438422,
      numericCode: '724',
      altSpellings: ['ES', 'Kingdom of Spain', 'Reino de España'],
      translations: {
        br: 'Espanha',
        de: 'Spanien',
        es: 'España',
        fa: 'اسپانیا',
        fr: 'Espagne',
        hr: 'Španjolska',
        it: 'Spagna',
        ja: 'スペイン',
        nl: 'Spanje',
        pt: 'Espanha',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.es'],
    },
    alpha3: 'ESP',
    areaCode: '34',
    picture: 'https://restcountries.eu/data/esp.svg',
  },
  {
    id: '7625bc07-16d5-4a93-9d7b-ae67a47b37c0',
    name: 'Sierra Leone',
    data: {
      area: 71740,
      cioc: 'SLE',
      gini: 42.5,
      latlng: [8.5, -11.5],
      region: 'Africa',
      borders: ['GIN', 'LBR'],
      capital: 'Freetown',
      demonym: 'Sierra Leonean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'SL',
      currencies: [
        {
          code: 'SLL',
          name: 'Sierra Leonean leone',
          symbol: 'Le',
        },
      ],
      nativeName: 'Sierra Leone',
      population: 7075641,
      numericCode: '694',
      altSpellings: ['SL', 'Republic of Sierra Leone'],
      translations: {
        br: 'Serra Leoa',
        de: 'Sierra Leone',
        es: 'Sierra Leone',
        fa: 'سیرالئون',
        fr: 'Sierra Leone',
        hr: 'Sijera Leone',
        it: 'Sierra Leone',
        ja: 'シエラレオネ',
        nl: 'Sierra Leone',
        pt: 'Serra Leoa',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sl'],
    },
    alpha3: 'SLE',
    areaCode: '232',
    picture: 'https://restcountries.eu/data/sle.svg',
  },
  {
    id: '1e1bea8d-f5a2-4b06-afa1-1dea7591f8ee',
    name: 'Singapore',
    data: {
      area: 710,
      cioc: 'SIN',
      gini: 48.1,
      latlng: [1.36666666, 103.8],
      region: 'Asia',
      borders: [],
      capital: 'Singapore',
      demonym: 'Singaporean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Malay',
          iso639_1: 'ms',
          iso639_2: 'msa',
          nativeName: 'bahasa Melayu',
        },
        {
          name: 'Tamil',
          iso639_1: 'ta',
          iso639_2: 'tam',
          nativeName: 'தமிழ்',
        },
        {
          name: 'Chinese',
          iso639_1: 'zh',
          iso639_2: 'zho',
          nativeName: '中文 (Zhōngwén)',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'SG',
      currencies: [
        {
          code: 'BND',
          name: 'Brunei dollar',
          symbol: '$',
        },
        {
          code: 'SGD',
          name: 'Singapore dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Singapore',
      population: 5535000,
      numericCode: '702',
      altSpellings: ['SG', 'Singapura', 'Republik Singapura', '新加坡共和国'],
      translations: {
        br: 'Singapura',
        de: 'Singapur',
        es: 'Singapur',
        fa: 'سنگاپور',
        fr: 'Singapour',
        hr: 'Singapur',
        it: 'Singapore',
        ja: 'シンガポール',
        nl: 'Singapore',
        pt: 'Singapura',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sg'],
    },
    alpha3: 'SGP',
    areaCode: '65',
    picture: 'https://restcountries.eu/data/sgp.svg',
  },
  {
    id: '4165fccc-34ac-4641-88ed-af6fcd01aa81',
    name: 'Sint Maarten (Dutch part)',
    data: {
      area: 34,
      cioc: '',
      gini: null,
      latlng: [18.033333, -63.05],
      region: 'Americas',
      borders: ['MAF'],
      capital: 'Philipsburg',
      demonym: 'Dutch',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'SX',
      currencies: [
        {
          code: 'ANG',
          name: 'Netherlands Antillean guilder',
          symbol: 'ƒ',
        },
      ],
      nativeName: 'Sint Maarten',
      population: 38247,
      numericCode: '534',
      altSpellings: ['SX'],
      translations: {
        br: 'Sint Maarten',
        de: 'Sint Maarten (niederl. Teil)',
        es: null,
        fa: 'سینت مارتن',
        fr: 'Saint Martin (partie néerlandaise)',
        hr: null,
        it: 'Saint Martin (parte olandese)',
        ja: null,
        nl: 'Sint Maarten',
        pt: 'São Martinho',
      },
      regionalBlocs: [],
      topLevelDomain: ['.sx'],
    },
    alpha3: 'SXM',
    areaCode: '1721',
    picture: 'https://restcountries.eu/data/sxm.svg',
  },
  {
    id: '8bc74eda-32eb-42bd-acf8-43aa0bf67177',
    name: 'Slovakia',
    data: {
      area: 49037,
      cioc: 'SVK',
      gini: 26,
      latlng: [48.66666666, 19.5],
      region: 'Europe',
      borders: ['AUT', 'CZE', 'HUN', 'POL', 'UKR'],
      capital: 'Bratislava',
      demonym: 'Slovak',
      languages: [
        {
          name: 'Slovak',
          iso639_1: 'sk',
          iso639_2: 'slk',
          nativeName: 'slovenčina',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'SK',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Slovensko',
      population: 5426252,
      numericCode: '703',
      altSpellings: ['SK', 'Slovak Republic', 'Slovenská republika'],
      translations: {
        br: 'Eslováquia',
        de: 'Slowakei',
        es: 'República Eslovaca',
        fa: 'اسلواکی',
        fr: 'Slovaquie',
        hr: 'Slovačka',
        it: 'Slovacchia',
        ja: 'スロバキア',
        nl: 'Slowakije',
        pt: 'Eslováquia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sk'],
    },
    alpha3: 'SVK',
    areaCode: '421',
    picture: 'https://restcountries.eu/data/svk.svg',
  },
  {
    id: '91dfa39d-1451-4ac9-878d-fb36d87b1e3e',
    name: 'Slovenia',
    data: {
      area: 20273,
      cioc: 'SLO',
      gini: 31.2,
      latlng: [46.11666666, 14.81666666],
      region: 'Europe',
      borders: ['AUT', 'HRV', 'ITA', 'HUN'],
      capital: 'Ljubljana',
      demonym: 'Slovene',
      languages: [
        {
          name: 'Slovene',
          iso639_1: 'sl',
          iso639_2: 'slv',
          nativeName: 'slovenski jezik',
        },
      ],
      subregion: 'Southern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'SI',
      currencies: [
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
      ],
      nativeName: 'Slovenija',
      population: 2064188,
      numericCode: '705',
      altSpellings: ['SI', 'Republic of Slovenia', 'Republika Slovenija'],
      translations: {
        br: 'Eslovênia',
        de: 'Slowenien',
        es: 'Eslovenia',
        fa: 'اسلوونی',
        fr: 'Slovénie',
        hr: 'Slovenija',
        it: 'Slovenia',
        ja: 'スロベニア',
        nl: 'Slovenië',
        pt: 'Eslovénia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.si'],
    },
    alpha3: 'SVN',
    areaCode: '386',
    picture: 'https://restcountries.eu/data/svn.svg',
  },
  {
    id: '655ff8b1-f8ea-485e-ae24-4526f9bb2c01',
    name: 'Solomon Islands',
    data: {
      area: 28896,
      cioc: 'SOL',
      gini: null,
      latlng: [-8, 159],
      region: 'Oceania',
      borders: [],
      capital: 'Honiara',
      demonym: 'Solomon Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Melanesia',
      timezones: ['UTC+11:00'],
      alpha2Code: 'SB',
      currencies: [
        {
          code: 'SBD',
          name: 'Solomon Islands dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Solomon Islands',
      population: 642000,
      numericCode: '090',
      altSpellings: ['SB'],
      translations: {
        br: 'Ilhas Salomão',
        de: 'Salomonen',
        es: 'Islas Salomón',
        fa: 'جزایر سلیمان',
        fr: 'Îles Salomon',
        hr: 'Solomonski Otoci',
        it: 'Isole Salomone',
        ja: 'ソロモン諸島',
        nl: 'Salomonseilanden',
        pt: 'Ilhas Salomão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.sb'],
    },
    alpha3: 'SLB',
    areaCode: '677',
    picture: 'https://restcountries.eu/data/slb.svg',
  },
  {
    id: '31173be7-b9e7-403f-9213-3cc72af58c60',
    name: 'Somalia',
    data: {
      area: 637657,
      cioc: 'SOM',
      gini: null,
      latlng: [10, 49],
      region: 'Africa',
      borders: ['DJI', 'ETH', 'KEN'],
      capital: 'Mogadishu',
      demonym: 'Somali',
      languages: [
        {
          name: 'Somali',
          iso639_1: 'so',
          iso639_2: 'som',
          nativeName: 'Soomaaliga',
        },
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'SO',
      currencies: [
        {
          code: 'SOS',
          name: 'Somali shilling',
          symbol: 'Sh',
        },
      ],
      nativeName: 'Soomaaliya',
      population: 11079000,
      numericCode: '706',
      altSpellings: [
        'SO',
        'aṣ-Ṣūmāl',
        'Federal Republic of Somalia',
        'Jamhuuriyadda Federaalka Soomaaliya',
        'Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya',
      ],
      translations: {
        br: 'Somália',
        de: 'Somalia',
        es: 'Somalia',
        fa: 'سومالی',
        fr: 'Somalie',
        hr: 'Somalija',
        it: 'Somalia',
        ja: 'ソマリア',
        nl: 'Somalië',
        pt: 'Somália',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.so'],
    },
    alpha3: 'SOM',
    areaCode: '252',
    picture: 'https://restcountries.eu/data/som.svg',
  },
  {
    id: 'cc357517-357e-4d77-b3d1-48dd257383d0',
    name: 'South Africa',
    data: {
      area: 1221037,
      cioc: 'RSA',
      gini: 63.1,
      latlng: [-29, 24],
      region: 'Africa',
      borders: ['BWA', 'LSO', 'MOZ', 'NAM', 'SWZ', 'ZWE'],
      capital: 'Pretoria',
      demonym: 'South African',
      languages: [
        {
          name: 'Afrikaans',
          iso639_1: 'af',
          iso639_2: 'afr',
          nativeName: 'Afrikaans',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Southern Ndebele',
          iso639_1: 'nr',
          iso639_2: 'nbl',
          nativeName: 'isiNdebele',
        },
        {
          name: 'Southern Sotho',
          iso639_1: 'st',
          iso639_2: 'sot',
          nativeName: 'Sesotho',
        },
        {
          name: 'Swati',
          iso639_1: 'ss',
          iso639_2: 'ssw',
          nativeName: 'SiSwati',
        },
        {
          name: 'Tswana',
          iso639_1: 'tn',
          iso639_2: 'tsn',
          nativeName: 'Setswana',
        },
        {
          name: 'Tsonga',
          iso639_1: 'ts',
          iso639_2: 'tso',
          nativeName: 'Xitsonga',
        },
        {
          name: 'Venda',
          iso639_1: 've',
          iso639_2: 'ven',
          nativeName: 'Tshivenḓa',
        },
        {
          name: 'Xhosa',
          iso639_1: 'xh',
          iso639_2: 'xho',
          nativeName: 'isiXhosa',
        },
        {
          name: 'Zulu',
          iso639_1: 'zu',
          iso639_2: 'zul',
          nativeName: 'isiZulu',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'ZA',
      currencies: [
        {
          code: 'ZAR',
          name: 'South African rand',
          symbol: 'R',
        },
      ],
      nativeName: 'South Africa',
      population: 55653654,
      numericCode: '710',
      altSpellings: ['ZA', 'RSA', 'Suid-Afrika', 'Republic of South Africa'],
      translations: {
        br: 'República Sul-Africana',
        de: 'Republik Südafrika',
        es: 'República de Sudáfrica',
        fa: 'آفریقای جنوبی',
        fr: 'Afrique du Sud',
        hr: 'Južnoafrička Republika',
        it: 'Sud Africa',
        ja: '南アフリカ',
        nl: 'Zuid-Afrika',
        pt: 'República Sul-Africana',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.za'],
    },
    alpha3: 'ZAF',
    areaCode: '27',
    picture: 'https://restcountries.eu/data/zaf.svg',
  },
  {
    id: 'b85eb204-853b-4ba1-b6df-dcf97adf3d02',
    name: 'South Georgia and the South Sandwich Islands',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [-54.5, -37],
      region: 'Americas',
      borders: [],
      capital: 'King Edward Point',
      demonym: 'South Georgia and the South Sandwich Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-02:00'],
      alpha2Code: 'GS',
      currencies: [
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
        {
          code: '(none)',
          name: null,
          symbol: '£',
        },
      ],
      nativeName: 'South Georgia',
      population: 30,
      numericCode: '239',
      altSpellings: ['GS', 'South Georgia and the South Sandwich Islands'],
      translations: {
        br: 'Ilhas Geórgias do Sul e Sandwich do Sul',
        de: 'Südgeorgien und die Südlichen Sandwichinseln',
        es: 'Islas Georgias del Sur y Sandwich del Sur',
        fa: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
        fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        hr: 'Južna Georgija i otočje Južni Sandwich',
        it: 'Georgia del Sud e Isole Sandwich Meridionali',
        ja: 'サウスジョージア・サウスサンドウィッチ諸島',
        nl: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
        pt: 'Ilhas Geórgia do Sul e Sanduíche do Sul',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.gs'],
    },
    alpha3: 'SGS',
    areaCode: '500',
    picture: 'https://restcountries.eu/data/sgs.svg',
  },
  {
    id: 'ac89e809-ace2-478d-bc99-9d8c8043aad2',
    name: 'Korea (Republic of)',
    data: {
      area: 100210,
      cioc: 'KOR',
      gini: 31.3,
      latlng: [37, 127.5],
      region: 'Asia',
      borders: ['PRK'],
      capital: 'Seoul',
      demonym: 'South Korean',
      languages: [
        {
          name: 'Korean',
          iso639_1: 'ko',
          iso639_2: 'kor',
          nativeName: '한국어',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+09:00'],
      alpha2Code: 'KR',
      currencies: [
        {
          code: 'KRW',
          name: 'South Korean won',
          symbol: '₩',
        },
      ],
      nativeName: '대한민국',
      population: 50801405,
      numericCode: '410',
      altSpellings: ['KR', 'Republic of Korea'],
      translations: {
        br: 'Coreia do Sul',
        de: 'Südkorea',
        es: 'Corea del Sur',
        fa: 'کره شمالی',
        fr: 'Corée du Sud',
        hr: 'Južna Koreja',
        it: 'Corea del Sud',
        ja: '大韓民国',
        nl: 'Zuid-Korea',
        pt: 'Coreia do Sul',
      },
      regionalBlocs: [],
      topLevelDomain: ['.kr'],
    },
    alpha3: 'KOR',
    areaCode: '82',
    picture: 'https://restcountries.eu/data/kor.svg',
  },
  {
    id: '91881aa9-b850-48c1-9333-aa638471f1dc',
    name: 'South Sudan',
    data: {
      area: 619745,
      cioc: '',
      gini: 45.5,
      latlng: [7, 30],
      region: 'Africa',
      borders: ['CAF', 'COD', 'ETH', 'KEN', 'SDN', 'UGA'],
      capital: 'Juba',
      demonym: 'South Sudanese',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Middle Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'SS',
      currencies: [
        {
          code: 'SSP',
          name: 'South Sudanese pound',
          symbol: '£',
        },
      ],
      nativeName: 'South Sudan',
      population: 12131000,
      numericCode: '728',
      altSpellings: ['SS'],
      translations: {
        br: 'Sudão do Sul',
        de: 'Südsudan',
        es: 'Sudán del Sur',
        fa: 'سودان جنوبی',
        fr: 'Soudan du Sud',
        hr: 'Južni Sudan',
        it: 'Sudan del sud',
        ja: '南スーダン',
        nl: 'Zuid-Soedan',
        pt: 'Sudão do Sul',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ss'],
    },
    alpha3: 'SSD',
    areaCode: '211',
    picture: 'https://restcountries.eu/data/ssd.svg',
  },
  {
    id: 'ac2c5a67-0592-4658-af89-8a31efe4f2eb',
    name: 'Sri Lanka',
    data: {
      area: 65610,
      cioc: 'SRI',
      gini: 40.3,
      latlng: [7, 81],
      region: 'Asia',
      borders: ['IND'],
      capital: 'Colombo',
      demonym: 'Sri Lankan',
      languages: [
        {
          name: 'Sinhalese',
          iso639_1: 'si',
          iso639_2: 'sin',
          nativeName: 'සිංහල',
        },
        {
          name: 'Tamil',
          iso639_1: 'ta',
          iso639_2: 'tam',
          nativeName: 'தமிழ்',
        },
      ],
      subregion: 'Southern Asia',
      timezones: ['UTC+05:30'],
      alpha2Code: 'LK',
      currencies: [
        {
          code: 'LKR',
          name: 'Sri Lankan rupee',
          symbol: 'Rs',
        },
      ],
      nativeName: 'śrī laṃkāva',
      population: 20966000,
      numericCode: '144',
      altSpellings: [
        'LK',
        'ilaṅkai',
        'Democratic Socialist Republic of Sri Lanka',
      ],
      translations: {
        br: 'Sri Lanka',
        de: 'Sri Lanka',
        es: 'Sri Lanka',
        fa: 'سری‌لانکا',
        fr: 'Sri Lanka',
        hr: 'Šri Lanka',
        it: 'Sri Lanka',
        ja: 'スリランカ',
        nl: 'Sri Lanka',
        pt: 'Sri Lanka',
      },
      regionalBlocs: [
        {
          name: 'South Asian Association for Regional Cooperation',
          acronym: 'SAARC',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.lk'],
    },
    alpha3: 'LKA',
    areaCode: '94',
    picture: 'https://restcountries.eu/data/lka.svg',
  },
  {
    id: '2b8c0fa8-6c51-4a42-a7ab-796ef6820a84',
    name: 'Sudan',
    data: {
      area: 1886068,
      cioc: 'SUD',
      gini: 35.3,
      latlng: [15, 30],
      region: 'Africa',
      borders: ['CAF', 'TCD', 'EGY', 'ERI', 'ETH', 'LBY', 'SSD'],
      capital: 'Khartoum',
      demonym: 'Sudanese',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'SD',
      currencies: [
        {
          code: 'SDG',
          name: 'Sudanese pound',
          symbol: 'ج.س.',
        },
      ],
      nativeName: 'السودان',
      population: 39598700,
      numericCode: '729',
      altSpellings: ['SD', 'Republic of the Sudan', 'Jumhūrīyat as-Sūdān'],
      translations: {
        br: 'Sudão',
        de: 'Sudan',
        es: 'Sudán',
        fa: 'سودان',
        fr: 'Soudan',
        hr: 'Sudan',
        it: 'Sudan',
        ja: 'スーダン',
        nl: 'Soedan',
        pt: 'Sudão',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sd'],
    },
    alpha3: 'SDN',
    areaCode: '249',
    picture: 'https://restcountries.eu/data/sdn.svg',
  },
  {
    id: '30622a8a-6f6b-41c0-a665-73e0c811240b',
    name: 'Suriname',
    data: {
      area: 163820,
      cioc: 'SUR',
      gini: 52.9,
      latlng: [4, -56],
      region: 'Americas',
      borders: ['BRA', 'GUF', 'FRA', 'GUY'],
      capital: 'Paramaribo',
      demonym: 'Surinamer',
      languages: [
        {
          name: 'Dutch',
          iso639_1: 'nl',
          iso639_2: 'nld',
          nativeName: 'Nederlands',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-03:00'],
      alpha2Code: 'SR',
      currencies: [
        {
          code: 'SRD',
          name: 'Surinamese dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Suriname',
      population: 541638,
      numericCode: '740',
      altSpellings: [
        'SR',
        'Sarnam',
        'Sranangron',
        'Republic of Suriname',
        'Republiek Suriname',
      ],
      translations: {
        br: 'Suriname',
        de: 'Suriname',
        es: 'Surinam',
        fa: 'سورینام',
        fr: 'Surinam',
        hr: 'Surinam',
        it: 'Suriname',
        ja: 'スリナム',
        nl: 'Suriname',
        pt: 'Suriname',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.sr'],
    },
    alpha3: 'SUR',
    areaCode: '597',
    picture: 'https://restcountries.eu/data/sur.svg',
  },
  {
    id: '6fab16e3-a1bc-4ae2-90da-055782b777f1',
    name: 'Svalbard and Jan Mayen',
    data: {
      area: null,
      cioc: '',
      gini: null,
      latlng: [78, 20],
      region: 'Europe',
      borders: [],
      capital: 'Longyearbyen',
      demonym: 'Norwegian',
      languages: [
        {
          name: 'Norwegian',
          iso639_1: 'no',
          iso639_2: 'nor',
          nativeName: 'Norsk',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'SJ',
      currencies: [
        {
          code: 'NOK',
          name: 'Norwegian krone',
          symbol: 'kr',
        },
      ],
      nativeName: 'Svalbard og Jan Mayen',
      population: 2562,
      numericCode: '744',
      altSpellings: ['SJ', 'Svalbard and Jan Mayen Islands'],
      translations: {
        br: 'Svalbard',
        de: 'Svalbard und Jan Mayen',
        es: 'Islas Svalbard y Jan Mayen',
        fa: 'سوالبارد و یان ماین',
        fr: 'Svalbard et Jan Mayen',
        hr: 'Svalbard i Jan Mayen',
        it: 'Svalbard e Jan Mayen',
        ja: 'スヴァールバル諸島およびヤンマイエン島',
        nl: 'Svalbard en Jan Mayen',
        pt: 'Svalbard',
      },
      regionalBlocs: [],
      topLevelDomain: ['.sj'],
    },
    alpha3: 'SJM',
    areaCode: '4779',
    picture: 'https://restcountries.eu/data/sjm.svg',
  },
  {
    id: '01319548-821e-40b6-9748-df6f81ab5f71',
    name: 'Swaziland',
    data: {
      area: 17364,
      cioc: 'SWZ',
      gini: 51.5,
      latlng: [-26.5, 31.5],
      region: 'Africa',
      borders: ['MOZ', 'ZAF'],
      capital: 'Lobamba',
      demonym: 'Swazi',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Swati',
          iso639_1: 'ss',
          iso639_2: 'ssw',
          nativeName: 'SiSwati',
        },
      ],
      subregion: 'Southern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'SZ',
      currencies: [
        {
          code: 'SZL',
          name: 'Swazi lilangeni',
          symbol: 'L',
        },
      ],
      nativeName: 'Swaziland',
      population: 1132657,
      numericCode: '748',
      altSpellings: [
        'SZ',
        'weSwatini',
        'Swatini',
        'Ngwane',
        'Kingdom of Swaziland',
        'Umbuso waseSwatini',
      ],
      translations: {
        br: 'Suazilândia',
        de: 'Swasiland',
        es: 'Suazilandia',
        fa: 'سوازیلند',
        fr: 'Swaziland',
        hr: 'Svazi',
        it: 'Swaziland',
        ja: 'スワジランド',
        nl: 'Swaziland',
        pt: 'Suazilândia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sz'],
    },
    alpha3: 'SWZ',
    areaCode: '268',
    picture: 'https://restcountries.eu/data/swz.svg',
  },
  {
    id: '67c0472c-c145-4ae4-b7c7-c7d218d3a667',
    name: 'Sweden',
    data: {
      area: 450295,
      cioc: 'SWE',
      gini: 25,
      latlng: [62, 15],
      region: 'Europe',
      borders: ['FIN', 'NOR'],
      capital: 'Stockholm',
      demonym: 'Swedish',
      languages: [
        {
          name: 'Swedish',
          iso639_1: 'sv',
          iso639_2: 'swe',
          nativeName: 'svenska',
        },
      ],
      subregion: 'Northern Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'SE',
      currencies: [
        {
          code: 'SEK',
          name: 'Swedish krona',
          symbol: 'kr',
        },
      ],
      nativeName: 'Sverige',
      population: 9894888,
      numericCode: '752',
      altSpellings: ['SE', 'Kingdom of Sweden', 'Konungariket Sverige'],
      translations: {
        br: 'Suécia',
        de: 'Schweden',
        es: 'Suecia',
        fa: 'سوئد',
        fr: 'Suède',
        hr: 'Švedska',
        it: 'Svezia',
        ja: 'スウェーデン',
        nl: 'Zweden',
        pt: 'Suécia',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.se'],
    },
    alpha3: 'SWE',
    areaCode: '46',
    picture: 'https://restcountries.eu/data/swe.svg',
  },
  {
    id: 'e8553cff-6403-45f4-8e90-91633289cd7e',
    name: 'Switzerland',
    data: {
      area: 41284,
      cioc: 'SUI',
      gini: 33.7,
      latlng: [47, 8],
      region: 'Europe',
      borders: ['AUT', 'FRA', 'ITA', 'LIE', 'DEU'],
      capital: 'Bern',
      demonym: 'Swiss',
      languages: [
        {
          name: 'German',
          iso639_1: 'de',
          iso639_2: 'deu',
          nativeName: 'Deutsch',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
        {
          name: 'Italian',
          iso639_1: 'it',
          iso639_2: 'ita',
          nativeName: 'Italiano',
        },
      ],
      subregion: 'Western Europe',
      timezones: ['UTC+01:00'],
      alpha2Code: 'CH',
      currencies: [
        {
          code: 'CHF',
          name: 'Swiss franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Schweiz',
      population: 8341600,
      numericCode: '756',
      altSpellings: [
        'CH',
        'Swiss Confederation',
        'Schweiz',
        'Suisse',
        'Svizzera',
        'Svizra',
      ],
      translations: {
        br: 'Suíça',
        de: 'Schweiz',
        es: 'Suiza',
        fa: 'سوئیس',
        fr: 'Suisse',
        hr: 'Švicarska',
        it: 'Svizzera',
        ja: 'スイス',
        nl: 'Zwitserland',
        pt: 'Suíça',
      },
      regionalBlocs: [
        {
          name: 'European Free Trade Association',
          acronym: 'EFTA',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ch'],
    },
    alpha3: 'CHE',
    areaCode: '41',
    picture: 'https://restcountries.eu/data/che.svg',
  },
  {
    id: '35606ae2-3994-43d7-a596-6d32442c0423',
    name: 'Syrian Arab Republic',
    data: {
      area: 185180,
      cioc: 'SYR',
      gini: 35.8,
      latlng: [35, 38],
      region: 'Asia',
      borders: ['IRQ', 'ISR', 'JOR', 'LBN', 'TUR'],
      capital: 'Damascus',
      demonym: 'Syrian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+02:00'],
      alpha2Code: 'SY',
      currencies: [
        {
          code: 'SYP',
          name: 'Syrian pound',
          symbol: '£',
        },
      ],
      nativeName: 'سوريا',
      population: 18564000,
      numericCode: '760',
      altSpellings: [
        'SY',
        'Syrian Arab Republic',
        'Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah',
      ],
      translations: {
        br: 'Síria',
        de: 'Syrien',
        es: 'Siria',
        fa: 'سوریه',
        fr: 'Syrie',
        hr: 'Sirija',
        it: 'Siria',
        ja: 'シリア・アラブ共和国',
        nl: 'Syrië',
        pt: 'Síria',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.sy'],
    },
    alpha3: 'SYR',
    areaCode: '963',
    picture: 'https://restcountries.eu/data/syr.svg',
  },
  {
    id: 'a6fa36bc-13dc-482f-9470-fc1f7efaf78e',
    name: 'Taiwan',
    data: {
      area: 36193,
      cioc: 'TPE',
      gini: null,
      latlng: [23.5, 121],
      region: 'Asia',
      borders: [],
      capital: 'Taipei',
      demonym: 'Taiwanese',
      languages: [
        {
          name: 'Chinese',
          iso639_1: 'zh',
          iso639_2: 'zho',
          nativeName: '中文 (Zhōngwén)',
        },
      ],
      subregion: 'Eastern Asia',
      timezones: ['UTC+08:00'],
      alpha2Code: 'TW',
      currencies: [
        {
          code: 'TWD',
          name: 'New Taiwan dollar',
          symbol: '$',
        },
      ],
      nativeName: '臺灣',
      population: 23503349,
      numericCode: '158',
      altSpellings: [
        'TW',
        'Táiwān',
        'Republic of China',
        '中華民國',
        'Zhōnghuá Mínguó',
      ],
      translations: {
        br: 'Taiwan',
        de: 'Taiwan',
        es: 'Taiwán',
        fa: 'تایوان',
        fr: 'Taïwan',
        hr: 'Tajvan',
        it: 'Taiwan',
        ja: '台湾（中華民国）',
        nl: 'Taiwan',
        pt: 'Taiwan',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tw'],
    },
    alpha3: 'TWN',
    areaCode: '886',
    picture: 'https://restcountries.eu/data/twn.svg',
  },
  {
    id: 'ee9a03a4-ad29-4055-82b4-463e3d6685dc',
    name: 'Tajikistan',
    data: {
      area: 143100,
      cioc: 'TJK',
      gini: 30.8,
      latlng: [39, 71],
      region: 'Asia',
      borders: ['AFG', 'CHN', 'KGZ', 'UZB'],
      capital: 'Dushanbe',
      demonym: 'Tadzhik',
      languages: [
        {
          name: 'Tajik',
          iso639_1: 'tg',
          iso639_2: 'tgk',
          nativeName: 'тоҷикӣ',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Central Asia',
      timezones: ['UTC+05:00'],
      alpha2Code: 'TJ',
      currencies: [
        {
          code: 'TJS',
          name: 'Tajikistani somoni',
          symbol: 'ЅМ',
        },
      ],
      nativeName: 'Тоҷикистон',
      population: 8593600,
      numericCode: '762',
      altSpellings: [
        'TJ',
        'Toçikiston',
        'Republic of Tajikistan',
        'Ҷумҳурии Тоҷикистон',
        'Çumhuriyi Toçikiston',
      ],
      translations: {
        br: 'Tajiquistão',
        de: 'Tadschikistan',
        es: 'Tayikistán',
        fa: 'تاجیکستان',
        fr: 'Tadjikistan',
        hr: 'Tađikistan',
        it: 'Tagikistan',
        ja: 'タジキスタン',
        nl: 'Tadzjikistan',
        pt: 'Tajiquistão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tj'],
    },
    alpha3: 'TJK',
    areaCode: '992',
    picture: 'https://restcountries.eu/data/tjk.svg',
  },
  {
    id: '0bbce7a8-b199-4908-b305-5117e56ef5f5',
    name: 'Tanzania, United Republic of',
    data: {
      area: 945087,
      cioc: 'TAN',
      gini: 37.6,
      latlng: [-6, 35],
      region: 'Africa',
      borders: ['BDI', 'COD', 'KEN', 'MWI', 'MOZ', 'RWA', 'UGA', 'ZMB'],
      capital: 'Dodoma',
      demonym: 'Tanzanian',
      languages: [
        {
          name: 'Swahili',
          iso639_1: 'sw',
          iso639_2: 'swa',
          nativeName: 'Kiswahili',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'TZ',
      currencies: [
        {
          code: 'TZS',
          name: 'Tanzanian shilling',
          symbol: 'Sh',
        },
      ],
      nativeName: 'Tanzania',
      population: 55155000,
      numericCode: '834',
      altSpellings: [
        'TZ',
        'United Republic of Tanzania',
        'Jamhuri ya Muungano wa Tanzania',
      ],
      translations: {
        br: 'Tanzânia',
        de: 'Tansania',
        es: 'Tanzania',
        fa: 'تانزانیا',
        fr: 'Tanzanie',
        hr: 'Tanzanija',
        it: 'Tanzania',
        ja: 'タンザニア',
        nl: 'Tanzania',
        pt: 'Tanzânia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.tz'],
    },
    alpha3: 'TZA',
    areaCode: '255',
    picture: 'https://restcountries.eu/data/tza.svg',
  },
  {
    id: 'eff4eb30-916f-4063-ae3b-2413524437bd',
    name: 'Thailand',
    data: {
      area: 513120,
      cioc: 'THA',
      gini: 40,
      latlng: [15, 100],
      region: 'Asia',
      borders: ['MMR', 'KHM', 'LAO', 'MYS'],
      capital: 'Bangkok',
      demonym: 'Thai',
      languages: [
        {
          name: 'Thai',
          iso639_1: 'th',
          iso639_2: 'tha',
          nativeName: 'ไทย',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+07:00'],
      alpha2Code: 'TH',
      currencies: [
        {
          code: 'THB',
          name: 'Thai baht',
          symbol: '฿',
        },
      ],
      nativeName: 'ประเทศไทย',
      population: 65327652,
      numericCode: '764',
      altSpellings: [
        'TH',
        'Prathet',
        'Thai',
        'Kingdom of Thailand',
        'ราชอาณาจักรไทย',
        'Ratcha Anachak Thai',
      ],
      translations: {
        br: 'Tailândia',
        de: 'Thailand',
        es: 'Tailandia',
        fa: 'تایلند',
        fr: 'Thaïlande',
        hr: 'Tajland',
        it: 'Tailandia',
        ja: 'タイ',
        nl: 'Thailand',
        pt: 'Tailândia',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.th'],
    },
    alpha3: 'THA',
    areaCode: '66',
    picture: 'https://restcountries.eu/data/tha.svg',
  },
  {
    id: '5e41702d-4fa4-4196-8419-c59c0d383b9b',
    name: 'Timor-Leste',
    data: {
      area: 14874,
      cioc: 'TLS',
      gini: 31.9,
      latlng: [-8.83333333, 125.91666666],
      region: 'Asia',
      borders: ['IDN'],
      capital: 'Dili',
      demonym: 'East Timorese',
      languages: [
        {
          name: 'Portuguese',
          iso639_1: 'pt',
          iso639_2: 'por',
          nativeName: 'Português',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+09:00'],
      alpha2Code: 'TL',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
        {
          code: null,
          name: null,
          symbol: null,
        },
      ],
      nativeName: 'Timor-Leste',
      population: 1167242,
      numericCode: '626',
      altSpellings: [
        'TL',
        'East Timor',
        'Democratic Republic of Timor-Leste',
        'República Democrática de Timor-Leste',
        'Repúblika Demokrátika Timór-Leste',
      ],
      translations: {
        br: 'Timor Leste',
        de: 'Timor-Leste',
        es: 'Timor Oriental',
        fa: 'تیمور شرقی',
        fr: 'Timor oriental',
        hr: 'Istočni Timor',
        it: 'Timor Est',
        ja: '東ティモール',
        nl: 'Oost-Timor',
        pt: 'Timor Leste',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tl'],
    },
    alpha3: 'TLS',
    areaCode: '670',
    picture: 'https://restcountries.eu/data/tls.svg',
  },
  {
    id: '42a18e8a-80a2-4515-82df-9289eaffc791',
    name: 'Togo',
    data: {
      area: 56785,
      cioc: 'TOG',
      gini: 34.4,
      latlng: [8, 1.16666666],
      region: 'Africa',
      borders: ['BEN', 'BFA', 'GHA'],
      capital: 'Lomé',
      demonym: 'Togolese',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Western Africa',
      timezones: ['UTC'],
      alpha2Code: 'TG',
      currencies: [
        {
          code: 'XOF',
          name: 'West African CFA franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Togo',
      population: 7143000,
      numericCode: '768',
      altSpellings: [
        'TG',
        'Togolese',
        'Togolese Republic',
        'République Togolaise',
      ],
      translations: {
        br: 'Togo',
        de: 'Togo',
        es: 'Togo',
        fa: 'توگو',
        fr: 'Togo',
        hr: 'Togo',
        it: 'Togo',
        ja: 'トーゴ',
        nl: 'Togo',
        pt: 'Togo',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.tg'],
    },
    alpha3: 'TGO',
    areaCode: '228',
    picture: 'https://restcountries.eu/data/tgo.svg',
  },
  {
    id: '04530c6e-8b00-4f5c-95d4-a46da735ada8',
    name: 'Tokelau',
    data: {
      area: 12,
      cioc: '',
      gini: null,
      latlng: [-9, -172],
      region: 'Oceania',
      borders: [],
      capital: 'Fakaofo',
      demonym: 'Tokelauan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC+13:00'],
      alpha2Code: 'TK',
      currencies: [
        {
          code: 'NZD',
          name: 'New Zealand dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Tokelau',
      population: 1411,
      numericCode: '772',
      altSpellings: ['TK'],
      translations: {
        br: 'Tokelau',
        de: 'Tokelau',
        es: 'Islas Tokelau',
        fa: 'توکلائو',
        fr: 'Tokelau',
        hr: 'Tokelau',
        it: 'Isole Tokelau',
        ja: 'トケラウ',
        nl: 'Tokelau',
        pt: 'Toquelau',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tk'],
    },
    alpha3: 'TKL',
    areaCode: '690',
    picture: 'https://restcountries.eu/data/tkl.svg',
  },
  {
    id: '19b92c5f-a9f1-4f16-858a-005a51773fdf',
    name: 'Tonga',
    data: {
      area: 747,
      cioc: 'TGA',
      gini: null,
      latlng: [-20, -175],
      region: 'Oceania',
      borders: [],
      capital: "Nuku'alofa",
      demonym: 'Tongan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Tonga (Tonga Islands)',
          iso639_1: 'to',
          iso639_2: 'ton',
          nativeName: 'faka Tonga',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC+13:00'],
      alpha2Code: 'TO',
      currencies: [
        {
          code: 'TOP',
          name: 'Tongan paʻanga',
          symbol: 'T$',
        },
      ],
      nativeName: 'Tonga',
      population: 103252,
      numericCode: '776',
      altSpellings: ['TO'],
      translations: {
        br: 'Tonga',
        de: 'Tonga',
        es: 'Tonga',
        fa: 'تونگا',
        fr: 'Tonga',
        hr: 'Tonga',
        it: 'Tonga',
        ja: 'トンガ',
        nl: 'Tonga',
        pt: 'Tonga',
      },
      regionalBlocs: [],
      topLevelDomain: ['.to'],
    },
    alpha3: 'TON',
    areaCode: '676',
    picture: 'https://restcountries.eu/data/ton.svg',
  },
  {
    id: '35c67022-0595-4728-8317-45c44f560fb6',
    name: 'Trinidad and Tobago',
    data: {
      area: 5130,
      cioc: 'TTO',
      gini: 40.3,
      latlng: [11, -61],
      region: 'Americas',
      borders: [],
      capital: 'Port of Spain',
      demonym: 'Trinidadian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'TT',
      currencies: [
        {
          code: 'TTD',
          name: 'Trinidad and Tobago dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Trinidad and Tobago',
      population: 1349667,
      numericCode: '780',
      altSpellings: ['TT', 'Republic of Trinidad and Tobago'],
      translations: {
        br: 'Trinidad e Tobago',
        de: 'Trinidad und Tobago',
        es: 'Trinidad y Tobago',
        fa: 'ترینیداد و توباگو',
        fr: 'Trinité et Tobago',
        hr: 'Trinidad i Tobago',
        it: 'Trinidad e Tobago',
        ja: 'トリニダード・トバゴ',
        nl: 'Trinidad en Tobago',
        pt: 'Trindade e Tobago',
      },
      regionalBlocs: [
        {
          name: 'Caribbean Community',
          acronym: 'CARICOM',
          otherNames: [
            'Comunidad del Caribe',
            'Communauté Caribéenne',
            'Caribische Gemeenschap',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.tt'],
    },
    alpha3: 'TTO',
    areaCode: '1868',
    picture: 'https://restcountries.eu/data/tto.svg',
  },
  {
    id: '5ef7eb43-59b1-48e3-a1f5-6c0338404b44',
    name: 'Tunisia',
    data: {
      area: 163610,
      cioc: 'TUN',
      gini: 41.4,
      latlng: [34, 9],
      region: 'Africa',
      borders: ['DZA', 'LBY'],
      capital: 'Tunis',
      demonym: 'Tunisian',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+01:00'],
      alpha2Code: 'TN',
      currencies: [
        {
          code: 'TND',
          name: 'Tunisian dinar',
          symbol: 'د.ت',
        },
      ],
      nativeName: 'تونس',
      population: 11154400,
      numericCode: '788',
      altSpellings: [
        'TN',
        'Republic of Tunisia',
        'al-Jumhūriyyah at-Tūnisiyyah',
      ],
      translations: {
        br: 'Tunísia',
        de: 'Tunesien',
        es: 'Túnez',
        fa: 'تونس',
        fr: 'Tunisie',
        hr: 'Tunis',
        it: 'Tunisia',
        ja: 'チュニジア',
        nl: 'Tunesië',
        pt: 'Tunísia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.tn'],
    },
    alpha3: 'TUN',
    areaCode: '216',
    picture: 'https://restcountries.eu/data/tun.svg',
  },
  {
    id: '20168c89-6488-4b57-9187-319c731dbbf8',
    name: 'Turkey',
    data: {
      area: 783562,
      cioc: 'TUR',
      gini: 39,
      latlng: [39, 35],
      region: 'Asia',
      borders: ['ARM', 'AZE', 'BGR', 'GEO', 'GRC', 'IRN', 'IRQ', 'SYR'],
      capital: 'Ankara',
      demonym: 'Turkish',
      languages: [
        {
          name: 'Turkish',
          iso639_1: 'tr',
          iso639_2: 'tur',
          nativeName: 'Türkçe',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'TR',
      currencies: [
        {
          code: 'TRY',
          name: 'Turkish lira',
          symbol: null,
        },
      ],
      nativeName: 'Türkiye',
      population: 78741053,
      numericCode: '792',
      altSpellings: [
        'TR',
        'Turkiye',
        'Republic of Turkey',
        'Türkiye Cumhuriyeti',
      ],
      translations: {
        br: 'Turquia',
        de: 'Türkei',
        es: 'Turquía',
        fa: 'ترکیه',
        fr: 'Turquie',
        hr: 'Turska',
        it: 'Turchia',
        ja: 'トルコ',
        nl: 'Turkije',
        pt: 'Turquia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tr'],
    },
    alpha3: 'TUR',
    areaCode: '90',
    picture: 'https://restcountries.eu/data/tur.svg',
  },
  {
    id: 'f6d4c9e0-4566-49ac-8508-76ff51f4fee2',
    name: 'Turkmenistan',
    data: {
      area: 488100,
      cioc: 'TKM',
      gini: 40.8,
      latlng: [40, 60],
      region: 'Asia',
      borders: ['AFG', 'IRN', 'KAZ', 'UZB'],
      capital: 'Ashgabat',
      demonym: 'Turkmen',
      languages: [
        {
          name: 'Turkmen',
          iso639_1: 'tk',
          iso639_2: 'tuk',
          nativeName: 'Türkmen',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Central Asia',
      timezones: ['UTC+05:00'],
      alpha2Code: 'TM',
      currencies: [
        {
          code: 'TMT',
          name: 'Turkmenistan manat',
          symbol: 'm',
        },
      ],
      nativeName: 'Türkmenistan',
      population: 4751120,
      numericCode: '795',
      altSpellings: ['TM'],
      translations: {
        br: 'Turcomenistão',
        de: 'Turkmenistan',
        es: 'Turkmenistán',
        fa: 'ترکمنستان',
        fr: 'Turkménistan',
        hr: 'Turkmenistan',
        it: 'Turkmenistan',
        ja: 'トルクメニスタン',
        nl: 'Turkmenistan',
        pt: 'Turquemenistão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tm'],
    },
    alpha3: 'TKM',
    areaCode: '993',
    picture: 'https://restcountries.eu/data/tkm.svg',
  },
  {
    id: '527093bf-14b7-4b8d-957b-5d7fb881ddf1',
    name: 'Turks and Caicos Islands',
    data: {
      area: 948,
      cioc: '',
      gini: null,
      latlng: [21.75, -71.58333333],
      region: 'Americas',
      borders: [],
      capital: 'Cockburn Town',
      demonym: 'Turks and Caicos Islander',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Caribbean',
      timezones: ['UTC-04:00'],
      alpha2Code: 'TC',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Turks and Caicos Islands',
      population: 31458,
      numericCode: '796',
      altSpellings: ['TC'],
      translations: {
        br: 'Ilhas Turcas e Caicos',
        de: 'Turks- und Caicosinseln',
        es: 'Islas Turks y Caicos',
        fa: 'جزایر تورکس و کایکوس',
        fr: 'Îles Turques-et-Caïques',
        hr: 'Otoci Turks i Caicos',
        it: 'Isole Turks e Caicos',
        ja: 'タークス・カイコス諸島',
        nl: 'Turks- en Caicoseilanden',
        pt: 'Ilhas Turcas e Caicos',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tc'],
    },
    alpha3: 'TCA',
    areaCode: '1649',
    picture: 'https://restcountries.eu/data/tca.svg',
  },
  {
    id: '2ebfd3b4-edeb-4444-bb6d-192991a0eb8b',
    name: 'Tuvalu',
    data: {
      area: 26,
      cioc: 'TUV',
      gini: null,
      latlng: [-8, 178],
      region: 'Oceania',
      borders: [],
      capital: 'Funafuti',
      demonym: 'Tuvaluan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC+12:00'],
      alpha2Code: 'TV',
      currencies: [
        {
          code: 'AUD',
          name: 'Australian dollar',
          symbol: '$',
        },
        {
          code: 'TVD[G]',
          name: 'Tuvaluan dollar',
          symbol: '$',
        },
      ],
      nativeName: 'Tuvalu',
      population: 10640,
      numericCode: '798',
      altSpellings: ['TV'],
      translations: {
        br: 'Tuvalu',
        de: 'Tuvalu',
        es: 'Tuvalu',
        fa: 'تووالو',
        fr: 'Tuvalu',
        hr: 'Tuvalu',
        it: 'Tuvalu',
        ja: 'ツバル',
        nl: 'Tuvalu',
        pt: 'Tuvalu',
      },
      regionalBlocs: [],
      topLevelDomain: ['.tv'],
    },
    alpha3: 'TUV',
    areaCode: '688',
    picture: 'https://restcountries.eu/data/tuv.svg',
  },
  {
    id: '2daa34ef-e4ab-487c-b52d-42079cad8cde',
    name: 'Uganda',
    data: {
      area: 241550,
      cioc: 'UGA',
      gini: 44.3,
      latlng: [1, 32],
      region: 'Africa',
      borders: ['COD', 'KEN', 'RWA', 'SSD', 'TZA'],
      capital: 'Kampala',
      demonym: 'Ugandan',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Swahili',
          iso639_1: 'sw',
          iso639_2: 'swa',
          nativeName: 'Kiswahili',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+03:00'],
      alpha2Code: 'UG',
      currencies: [
        {
          code: 'UGX',
          name: 'Ugandan shilling',
          symbol: 'Sh',
        },
      ],
      nativeName: 'Uganda',
      population: 33860700,
      numericCode: '800',
      altSpellings: ['UG', 'Republic of Uganda', 'Jamhuri ya Uganda'],
      translations: {
        br: 'Uganda',
        de: 'Uganda',
        es: 'Uganda',
        fa: 'اوگاندا',
        fr: 'Uganda',
        hr: 'Uganda',
        it: 'Uganda',
        ja: 'ウガンダ',
        nl: 'Oeganda',
        pt: 'Uganda',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ug'],
    },
    alpha3: 'UGA',
    areaCode: '256',
    picture: 'https://restcountries.eu/data/uga.svg',
  },
  {
    id: '560d416d-f746-4f84-9d41-8617eab7b23c',
    name: 'Ukraine',
    data: {
      area: 603700,
      cioc: 'UKR',
      gini: 26.4,
      latlng: [49, 32],
      region: 'Europe',
      borders: ['BLR', 'HUN', 'MDA', 'POL', 'ROU', 'RUS', 'SVK'],
      capital: 'Kiev',
      demonym: 'Ukrainian',
      languages: [
        {
          name: 'Ukrainian',
          iso639_1: 'uk',
          iso639_2: 'ukr',
          nativeName: 'Українська',
        },
      ],
      subregion: 'Eastern Europe',
      timezones: ['UTC+02:00'],
      alpha2Code: 'UA',
      currencies: [
        {
          code: 'UAH',
          name: 'Ukrainian hryvnia',
          symbol: '₴',
        },
      ],
      nativeName: 'Україна',
      population: 42692393,
      numericCode: '804',
      altSpellings: ['UA', 'Ukrayina'],
      translations: {
        br: 'Ucrânia',
        de: 'Ukraine',
        es: 'Ucrania',
        fa: 'وکراین',
        fr: 'Ukraine',
        hr: 'Ukrajina',
        it: 'Ucraina',
        ja: 'ウクライナ',
        nl: 'Oekraïne',
        pt: 'Ucrânia',
      },
      regionalBlocs: [],
      topLevelDomain: ['.ua'],
    },
    alpha3: 'UKR',
    areaCode: '380',
    picture: 'https://restcountries.eu/data/ukr.svg',
  },
  {
    id: 'c42a3105-4eaa-4311-bb5b-2a4c8c7390ab',
    name: 'United Arab Emirates',
    data: {
      area: 83600,
      cioc: 'UAE',
      gini: null,
      latlng: [24, 54],
      region: 'Asia',
      borders: ['OMN', 'SAU'],
      capital: 'Abu Dhabi',
      demonym: 'Emirati',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+04'],
      alpha2Code: 'AE',
      currencies: [
        {
          code: 'AED',
          name: 'United Arab Emirates dirham',
          symbol: 'د.إ',
        },
      ],
      nativeName: 'دولة الإمارات العربية المتحدة',
      population: 9856000,
      numericCode: '784',
      altSpellings: ['AE', 'UAE'],
      translations: {
        br: 'Emirados árabes Unidos',
        de: 'Vereinigte Arabische Emirate',
        es: 'Emiratos Árabes Unidos',
        fa: 'امارات متحده عربی',
        fr: 'Émirats arabes unis',
        hr: 'Ujedinjeni Arapski Emirati',
        it: 'Emirati Arabi Uniti',
        ja: 'アラブ首長国連邦',
        nl: 'Verenigde Arabische Emiraten',
        pt: 'Emirados árabes Unidos',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ae'],
    },
    alpha3: 'ARE',
    areaCode: '971',
    picture: 'https://restcountries.eu/data/are.svg',
  },
  {
    id: '4807b907-ba8e-484e-80a1-f9c49b99848f',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    data: {
      area: 242900,
      cioc: 'GBR',
      gini: 34,
      latlng: [54, -2],
      region: 'Europe',
      borders: ['IRL'],
      capital: 'London',
      demonym: 'British',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern Europe',
      timezones: [
        'UTC-08:00',
        'UTC-05:00',
        'UTC-04:00',
        'UTC-03:00',
        'UTC-02:00',
        'UTC',
        'UTC+01:00',
        'UTC+02:00',
        'UTC+06:00',
      ],
      alpha2Code: 'GB',
      currencies: [
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
      ],
      nativeName: 'United Kingdom',
      population: 65110000,
      numericCode: '826',
      altSpellings: ['GB', 'UK', 'Great Britain'],
      translations: {
        br: 'Reino Unido',
        de: 'Vereinigtes Königreich',
        es: 'Reino Unido',
        fa: 'بریتانیای کبیر و ایرلند شمالی',
        fr: 'Royaume-Uni',
        hr: 'Ujedinjeno Kraljevstvo',
        it: 'Regno Unito',
        ja: 'イギリス',
        nl: 'Verenigd Koninkrijk',
        pt: 'Reino Unido',
      },
      regionalBlocs: [
        {
          name: 'European Union',
          acronym: 'EU',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.uk'],
    },
    alpha3: 'GBR',
    areaCode: '44',
    picture: 'https://restcountries.eu/data/gbr.svg',
  },
  {
    id: 'ce63c23a-a2d1-41f7-849d-469d08937069',
    name: 'United States of America',
    data: {
      area: 9629091,
      cioc: 'USA',
      gini: 48,
      latlng: [38, -97],
      region: 'Americas',
      borders: ['CAN', 'MEX'],
      capital: 'Washington, D.C.',
      demonym: 'American',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Northern America',
      timezones: [
        'UTC-12:00',
        'UTC-11:00',
        'UTC-10:00',
        'UTC-09:00',
        'UTC-08:00',
        'UTC-07:00',
        'UTC-06:00',
        'UTC-05:00',
        'UTC-04:00',
        'UTC+10:00',
        'UTC+12:00',
      ],
      alpha2Code: 'US',
      currencies: [
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
      ],
      nativeName: 'United States',
      population: 323947000,
      numericCode: '840',
      altSpellings: ['US', 'USA', 'United States of America'],
      translations: {
        br: 'Estados Unidos',
        de: 'Vereinigte Staaten von Amerika',
        es: 'Estados Unidos',
        fa: 'ایالات متحده آمریکا',
        fr: 'États-Unis',
        hr: 'Sjedinjene Američke Države',
        it: "Stati Uniti D'America",
        ja: 'アメリカ合衆国',
        nl: 'Verenigde Staten',
        pt: 'Estados Unidos',
      },
      regionalBlocs: [
        {
          name: 'North American Free Trade Agreement',
          acronym: 'NAFTA',
          otherNames: [
            'Tratado de Libre Comercio de América del Norte',
            'Accord de Libre-échange Nord-Américain',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.us'],
    },
    alpha3: 'USA',
    areaCode: '1',
    picture: 'https://restcountries.eu/data/usa.svg',
  },
  {
    id: '8b872194-1f11-4481-925d-fd5a7a095cb8',
    name: 'Uruguay',
    data: {
      area: 181034,
      cioc: 'URU',
      gini: 39.7,
      latlng: [-33, -56],
      region: 'Americas',
      borders: ['ARG', 'BRA'],
      capital: 'Montevideo',
      demonym: 'Uruguayan',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-03:00'],
      alpha2Code: 'UY',
      currencies: [
        {
          code: 'UYU',
          name: 'Uruguayan peso',
          symbol: '$',
        },
      ],
      nativeName: 'Uruguay',
      population: 3480222,
      numericCode: '858',
      altSpellings: [
        'UY',
        'Oriental Republic of Uruguay',
        'República Oriental del Uruguay',
      ],
      translations: {
        br: 'Uruguai',
        de: 'Uruguay',
        es: 'Uruguay',
        fa: 'اروگوئه',
        fr: 'Uruguay',
        hr: 'Urugvaj',
        it: 'Uruguay',
        ja: 'ウルグアイ',
        nl: 'Uruguay',
        pt: 'Uruguai',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.uy'],
    },
    alpha3: 'URY',
    areaCode: '598',
    picture: 'https://restcountries.eu/data/ury.svg',
  },
  {
    id: '9ebacc07-af2c-4886-8599-dd7f6c7e411b',
    name: 'Uzbekistan',
    data: {
      area: 447400,
      cioc: 'UZB',
      gini: 36.7,
      latlng: [41, 64],
      region: 'Asia',
      borders: ['AFG', 'KAZ', 'KGZ', 'TJK', 'TKM'],
      capital: 'Tashkent',
      demonym: 'Uzbekistani',
      languages: [
        {
          name: 'Uzbek',
          iso639_1: 'uz',
          iso639_2: 'uzb',
          nativeName: 'Oʻzbek',
        },
        {
          name: 'Russian',
          iso639_1: 'ru',
          iso639_2: 'rus',
          nativeName: 'Русский',
        },
      ],
      subregion: 'Central Asia',
      timezones: ['UTC+05:00'],
      alpha2Code: 'UZ',
      currencies: [
        {
          code: 'UZS',
          name: "Uzbekistani so'm",
          symbol: null,
        },
      ],
      nativeName: 'O‘zbekiston',
      population: 31576400,
      numericCode: '860',
      altSpellings: [
        'UZ',
        'Republic of Uzbekistan',
        'O‘zbekiston Respublikasi',
        'Ўзбекистон Республикаси',
      ],
      translations: {
        br: 'Uzbequistão',
        de: 'Usbekistan',
        es: 'Uzbekistán',
        fa: 'ازبکستان',
        fr: 'Ouzbékistan',
        hr: 'Uzbekistan',
        it: 'Uzbekistan',
        ja: 'ウズベキスタン',
        nl: 'Oezbekistan',
        pt: 'Usbequistão',
      },
      regionalBlocs: [],
      topLevelDomain: ['.uz'],
    },
    alpha3: 'UZB',
    areaCode: '998',
    picture: 'https://restcountries.eu/data/uzb.svg',
  },
  {
    id: 'bec098f5-9ab4-4414-ad19-bb17b8c98d70',
    name: 'Vanuatu',
    data: {
      area: 12189,
      cioc: 'VAN',
      gini: null,
      latlng: [-16, 167],
      region: 'Oceania',
      borders: [],
      capital: 'Port Vila',
      demonym: 'Ni-Vanuatu',
      languages: [
        {
          name: 'Bislama',
          iso639_1: 'bi',
          iso639_2: 'bis',
          nativeName: 'Bislama',
        },
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Melanesia',
      timezones: ['UTC+11:00'],
      alpha2Code: 'VU',
      currencies: [
        {
          code: 'VUV',
          name: 'Vanuatu vatu',
          symbol: 'Vt',
        },
      ],
      nativeName: 'Vanuatu',
      population: 277500,
      numericCode: '548',
      altSpellings: [
        'VU',
        'Republic of Vanuatu',
        'Ripablik blong Vanuatu',
        'République de Vanuatu',
      ],
      translations: {
        br: 'Vanuatu',
        de: 'Vanuatu',
        es: 'Vanuatu',
        fa: 'وانواتو',
        fr: 'Vanuatu',
        hr: 'Vanuatu',
        it: 'Vanuatu',
        ja: 'バヌアツ',
        nl: 'Vanuatu',
        pt: 'Vanuatu',
      },
      regionalBlocs: [],
      topLevelDomain: ['.vu'],
    },
    alpha3: 'VUT',
    areaCode: '678',
    picture: 'https://restcountries.eu/data/vut.svg',
  },
  {
    id: 'f2af70d6-9424-4c21-9abe-0be96225d62c',
    name: 'Venezuela (Bolivarian Republic of)',
    data: {
      area: 916445,
      cioc: 'VEN',
      gini: 44.8,
      latlng: [8, -66],
      region: 'Americas',
      borders: ['BRA', 'COL', 'GUY'],
      capital: 'Caracas',
      demonym: 'Venezuelan',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'South America',
      timezones: ['UTC-04:00'],
      alpha2Code: 'VE',
      currencies: [
        {
          code: 'VEF',
          name: 'Venezuelan bolívar',
          symbol: 'Bs F',
        },
      ],
      nativeName: 'Venezuela',
      population: 31028700,
      numericCode: '862',
      altSpellings: [
        'VE',
        'Bolivarian Republic of Venezuela',
        'República Bolivariana de Venezuela',
      ],
      translations: {
        br: 'Venezuela',
        de: 'Venezuela',
        es: 'Venezuela',
        fa: 'ونزوئلا',
        fr: 'Venezuela',
        hr: 'Venezuela',
        it: 'Venezuela',
        ja: 'ベネズエラ・ボリバル共和国',
        nl: 'Venezuela',
        pt: 'Venezuela',
      },
      regionalBlocs: [
        {
          name: 'Union of South American Nations',
          acronym: 'USAN',
          otherNames: [
            'Unión de Naciones Suramericanas',
            'União de Nações Sul-Americanas',
            'Unie van Zuid-Amerikaanse Naties',
            'South American Union',
          ],
          otherAcronyms: ['UNASUR', 'UNASUL', 'UZAN'],
        },
      ],
      topLevelDomain: ['.ve'],
    },
    alpha3: 'VEN',
    areaCode: '58',
    picture: 'https://restcountries.eu/data/ven.svg',
  },
  {
    id: '9ab35d4d-3b32-4544-b5a3-8dcec7f58c06',
    name: 'Viet Nam',
    data: {
      area: 331212,
      cioc: 'VIE',
      gini: 35.6,
      latlng: [16.16666666, 107.83333333],
      region: 'Asia',
      borders: ['KHM', 'CHN', 'LAO'],
      capital: 'Hanoi',
      demonym: 'Vietnamese',
      languages: [
        {
          name: 'Vietnamese',
          iso639_1: 'vi',
          iso639_2: 'vie',
          nativeName: 'Tiếng Việt',
        },
      ],
      subregion: 'South-Eastern Asia',
      timezones: ['UTC+07:00'],
      alpha2Code: 'VN',
      currencies: [
        {
          code: 'VND',
          name: 'Vietnamese đồng',
          symbol: '₫',
        },
      ],
      nativeName: 'Việt Nam',
      population: 92700000,
      numericCode: '704',
      altSpellings: [
        'VN',
        'Socialist Republic of Vietnam',
        'Cộng hòa Xã hội chủ nghĩa Việt Nam',
      ],
      translations: {
        br: 'Vietnã',
        de: 'Vietnam',
        es: 'Vietnam',
        fa: 'ویتنام',
        fr: 'Viêt Nam',
        hr: 'Vijetnam',
        it: 'Vietnam',
        ja: 'ベトナム',
        nl: 'Vietnam',
        pt: 'Vietname',
      },
      regionalBlocs: [
        {
          name: 'Association of Southeast Asian Nations',
          acronym: 'ASEAN',
          otherNames: [],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.vn'],
    },
    alpha3: 'VNM',
    areaCode: '84',
    picture: 'https://restcountries.eu/data/vnm.svg',
  },
  {
    id: '2dd137d6-5142-46f2-a154-7491678ea6a2',
    name: 'Wallis and Futuna',
    data: {
      area: 142,
      cioc: '',
      gini: null,
      latlng: [-13.3, -176.2],
      region: 'Oceania',
      borders: [],
      capital: 'Mata-Utu',
      demonym: 'Wallis and Futuna Islander',
      languages: [
        {
          name: 'French',
          iso639_1: 'fr',
          iso639_2: 'fra',
          nativeName: 'français',
        },
      ],
      subregion: 'Polynesia',
      timezones: ['UTC+12:00'],
      alpha2Code: 'WF',
      currencies: [
        {
          code: 'XPF',
          name: 'CFP franc',
          symbol: 'Fr',
        },
      ],
      nativeName: 'Wallis et Futuna',
      population: 11750,
      numericCode: '876',
      altSpellings: [
        'WF',
        'Territory of the Wallis and Futuna Islands',
        'Territoire des îles Wallis et Futuna',
      ],
      translations: {
        br: 'Wallis e Futuna',
        de: 'Wallis und Futuna',
        es: 'Wallis y Futuna',
        fa: 'والیس و فوتونا',
        fr: 'Wallis-et-Futuna',
        hr: 'Wallis i Fortuna',
        it: 'Wallis e Futuna',
        ja: 'ウォリス・フツナ',
        nl: 'Wallis en Futuna',
        pt: 'Wallis e Futuna',
      },
      regionalBlocs: [],
      topLevelDomain: ['.wf'],
    },
    alpha3: 'WLF',
    areaCode: '681',
    picture: 'https://restcountries.eu/data/wlf.svg',
  },
  {
    id: 'bb60c6c5-b104-4e60-8afa-e42a670ffeb0',
    name: 'Western Sahara',
    data: {
      area: 266000,
      cioc: '',
      gini: null,
      latlng: [24.5, -13],
      region: 'Africa',
      borders: ['DZA', 'MRT', 'MAR'],
      capital: 'El Aaiún',
      demonym: 'Sahrawi',
      languages: [
        {
          name: 'Spanish',
          iso639_1: 'es',
          iso639_2: 'spa',
          nativeName: 'Español',
        },
      ],
      subregion: 'Northern Africa',
      timezones: ['UTC+00:00'],
      alpha2Code: 'EH',
      currencies: [
        {
          code: 'MAD',
          name: 'Moroccan dirham',
          symbol: 'د.م.',
        },
        {
          code: 'DZD',
          name: 'Algerian dinar',
          symbol: 'د.ج',
        },
      ],
      nativeName: 'الصحراء الغربية',
      population: 510713,
      numericCode: '732',
      altSpellings: ['EH', 'Taneẓroft Tutrimt'],
      translations: {
        br: 'Saara Ocidental',
        de: 'Westsahara',
        es: 'Sahara Occidental',
        fa: 'جمهوری دموکراتیک عربی صحرا',
        fr: 'Sahara Occidental',
        hr: 'Zapadna Sahara',
        it: 'Sahara Occidentale',
        ja: '西サハラ',
        nl: 'Westelijke Sahara',
        pt: 'Saara Ocidental',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.eh'],
    },
    alpha3: 'ESH',
    areaCode: '212',
    picture: 'https://restcountries.eu/data/esh.svg',
  },
  {
    id: '6851db15-1c98-4a9a-9d90-1aaf57a53d7a',
    name: 'Yemen',
    data: {
      area: 527968,
      cioc: 'YEM',
      gini: 37.7,
      latlng: [15, 48],
      region: 'Asia',
      borders: ['OMN', 'SAU'],
      capital: "Sana'a",
      demonym: 'Yemeni',
      languages: [
        {
          name: 'Arabic',
          iso639_1: 'ar',
          iso639_2: 'ara',
          nativeName: 'العربية',
        },
      ],
      subregion: 'Western Asia',
      timezones: ['UTC+03:00'],
      alpha2Code: 'YE',
      currencies: [
        {
          code: 'YER',
          name: 'Yemeni rial',
          symbol: '﷼',
        },
      ],
      nativeName: 'اليَمَن',
      population: 27478000,
      numericCode: '887',
      altSpellings: ['YE', 'Yemeni Republic', 'al-Jumhūriyyah al-Yamaniyyah'],
      translations: {
        br: 'Iêmen',
        de: 'Jemen',
        es: 'Yemen',
        fa: 'یمن',
        fr: 'Yémen',
        hr: 'Jemen',
        it: 'Yemen',
        ja: 'イエメン',
        nl: 'Jemen',
        pt: 'Iémen',
      },
      regionalBlocs: [
        {
          name: 'Arab League',
          acronym: 'AL',
          otherNames: [
            'جامعة الدول العربية',
            'Jāmiʻat ad-Duwal al-ʻArabīyah',
            'League of Arab States',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.ye'],
    },
    alpha3: 'YEM',
    areaCode: '967',
    picture: 'https://restcountries.eu/data/yem.svg',
  },
  {
    id: '0f4178eb-8fe3-494c-b354-ded0faf275fd',
    name: 'Zambia',
    data: {
      area: 752612,
      cioc: 'ZAM',
      gini: 54.6,
      latlng: [-15, 30],
      region: 'Africa',
      borders: ['AGO', 'BWA', 'COD', 'MWI', 'MOZ', 'NAM', 'TZA', 'ZWE'],
      capital: 'Lusaka',
      demonym: 'Zambian',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'ZM',
      currencies: [
        {
          code: 'ZMW',
          name: 'Zambian kwacha',
          symbol: 'ZK',
        },
      ],
      nativeName: 'Zambia',
      population: 15933883,
      numericCode: '894',
      altSpellings: ['ZM', 'Republic of Zambia'],
      translations: {
        br: 'Zâmbia',
        de: 'Sambia',
        es: 'Zambia',
        fa: 'زامبیا',
        fr: 'Zambie',
        hr: 'Zambija',
        it: 'Zambia',
        ja: 'ザンビア',
        nl: 'Zambia',
        pt: 'Zâmbia',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.zm'],
    },
    alpha3: 'ZMB',
    areaCode: '260',
    picture: 'https://restcountries.eu/data/zmb.svg',
  },
  {
    id: '0ea2a685-0400-400a-ae6e-a852818b6b1e',
    name: 'Zimbabwe',
    data: {
      area: 390757,
      cioc: 'ZIM',
      gini: null,
      latlng: [-20, 30],
      region: 'Africa',
      borders: ['BWA', 'MOZ', 'ZAF', 'ZMB'],
      capital: 'Harare',
      demonym: 'Zimbabwean',
      languages: [
        {
          name: 'English',
          iso639_1: 'en',
          iso639_2: 'eng',
          nativeName: 'English',
        },
        {
          name: 'Shona',
          iso639_1: 'sn',
          iso639_2: 'sna',
          nativeName: 'chiShona',
        },
        {
          name: 'Northern Ndebele',
          iso639_1: 'nd',
          iso639_2: 'nde',
          nativeName: 'isiNdebele',
        },
      ],
      subregion: 'Eastern Africa',
      timezones: ['UTC+02:00'],
      alpha2Code: 'ZW',
      currencies: [
        {
          code: 'BWP',
          name: 'Botswana pula',
          symbol: 'P',
        },
        {
          code: 'GBP',
          name: 'British pound',
          symbol: '£',
        },
        {
          code: 'CNY',
          name: 'Chinese yuan',
          symbol: '¥',
        },
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
        {
          code: 'INR',
          name: 'Indian rupee',
          symbol: '₹',
        },
        {
          code: 'JPY',
          name: 'Japanese yen',
          symbol: '¥',
        },
        {
          code: 'ZAR',
          name: 'South African rand',
          symbol: 'Rs',
        },
        {
          code: 'USD',
          name: 'United States dollar',
          symbol: '$',
        },
        {
          code: '(none)',
          name: null,
          symbol: null,
        },
      ],
      nativeName: 'Zimbabwe',
      population: 14240168,
      numericCode: '716',
      altSpellings: ['ZW', 'Republic of Zimbabwe'],
      translations: {
        br: 'Zimbabwe',
        de: 'Simbabwe',
        es: 'Zimbabue',
        fa: 'زیمباوه',
        fr: 'Zimbabwe',
        hr: 'Zimbabve',
        it: 'Zimbabwe',
        ja: 'ジンバブエ',
        nl: 'Zimbabwe',
        pt: 'Zimbabué',
      },
      regionalBlocs: [
        {
          name: 'African Union',
          acronym: 'AU',
          otherNames: [
            'الاتحاد الأفريقي',
            'Union africaine',
            'União Africana',
            'Unión Africana',
            'Umoja wa Afrika',
          ],
          otherAcronyms: [],
        },
      ],
      topLevelDomain: ['.zw'],
    },
    alpha3: 'ZWE',
    areaCode: '263',
    picture: 'https://restcountries.eu/data/zwe.svg',
  },
];

export const bussinesTypes = [
  {
    id: 'a41a9ce6-ef52-40e2-920f-240a5785d416',
    name: 'Agricultura / Acuicultura',
  },
  {
    id: 'af033a55-909f-4cc1-bca9-64d497bb1f1d',
    name: 'Alimentos y bebidas',
  },
  {
    id: '087514d3-d0e5-45e7-9774-0b054b7ac96d',
    name: 'Call Centers / BPO',
  },
  {
    id: '0a315f2d-077d-4338-9aca-60176926605f',
    name: 'Comercio',
  },
  {
    id: 'f1107391-00c7-49f8-920c-64240ffcd2bd',
    name: 'Construcción / Inmobiliario',
  },
  {
    id: 'bf2dcf3f-fd64-4b06-94a2-79524f56d522',
    name: 'Energía / Minería / Petróleo',
  },
  {
    id: '7cd885ed-df79-4930-806d-22e89c45634c',
    name: 'Finanzas / Seguros',
  },
  {
    id: '5a8ad4ad-2d5b-4500-9c91-86ea4a2fc653',
    name: 'Freelance',
  },
  {
    id: 'e0a497d7-a3e4-49d2-961c-381d3fb1ab8a',
    name: 'Gobierno / Sector Público',
  },
  {
    id: '306563c5-61ac-482e-99c0-e71e20083947',
    name: 'Hotelería / Turismo ',
  },
  {
    id: '45ba51a6-3b12-4597-a624-39b39fd6080b',
    name: 'Industria',
  },
  {
    id: 'de7be268-e313-4997-8ef8-7ae7c48a61a9',
    name: 'Manufactura',
  },
  {
    id: '51c05ef6-6be9-4719-8030-79c483c7ac41',
    name: 'Salud / Farmacéutica / Químicos',
  },
  {
    id: '23056ea7-b877-494d-85f3-2134b200d668',
    name: 'Servicios',
  },
  {
    id: 'e48a7953-a353-4086-81e7-5e2affb0e187',
    name: 'Servicios profesionales',
  },
  {
    id: 'bbde2be9-57bc-4d28-b177-94d1106a7d98',
    name: 'Startups',
  },
  {
    id: '07dee2cc-a998-417e-ba50-7464fc80fe0e',
    name: 'Tecnología / Telecomunicaciones',
  },
  {
    id: '0328ecfd-58e2-4da9-8f79-61dd2234c27d',
    name: 'Textiles',
  },
  {
    id: '4bef39c1-1cb5-49b7-98ec-704d8ab3f223',
    name: 'Transporte / Logística',
  },
  {
    id: '3de5d4a1-ee29-4a13-92e1-e596261205a6',
    name: 'Otros',
  },
];

export const enterpriseTypes = [
  {
    id: '7491c3c3-874a-4940-ae75-954dd4e51e1d',
    name: 'Propietario único',
    // name: "Sole Proprietorship",
  },
  {
    id: '7be4c100-dca5-4203-a479-d71d979772bb',
    name: 'Sociedad',
    // name: "Partnership",
  },
  {
    id: 'e5c0c380-5d01-405e-b5b1-6afaa5b794a7',
    name: 'Corporación',
    // name: "Corporation",
  },
];

export const roles = [
  {
    id: '7491c3c3-874a-4940-ae75-954dd4e51e1d',
    name: 'ADMIN',
  },
  {
    id: 'e5c0c380-5d01-405e-b5b1-6afaa5b794a7',
    name: 'WRITE',
  },
  {
    id: '7be4c100-dca5-4203-a479-d71d979772bb',
    name: 'READ',
  },
];

export const plans = [
  {
    id: '7491c3c3-874a-4940-ae75-954dd4e51e1d',
    name: 'Free',
    users: 3,
    enterprises: 3,
    freeDays: 60,
  },
  {
    id: '7be4c100-dca5-4203-a479-d71d979772bb',
    name: 'Advanced',
    users: 3,
    enterprises: 3,
    freeDays: 60,
  },
  {
    id: 'e5c0c380-5d01-405e-b5b1-6afaa5b794a7',
    name: 'Custom',
    users: 3,
    enterprises: 3,
    freeDays: 60,
  },
];

export const phoneTypes = [
  { id: 'MOBILE', name: 'Móvil', value: 'MOBILE' },
  { id: 'HOME', name: 'Casa', value: 'HOME' },
  { id: 'WORK', name: 'Trabajo', value: 'WORK' },
  { id: 'OTHER', name: 'Otro', value: 'OTHER' },
];

export const phoneTypeName = {
  MOBILE: 'Móvil',
  HOME: 'Casa',
  WORK: 'Trabajo',
  OTHER: 'Otro',
};

export const productTypes = [
  { id: 'SALE', name: 'Sale' },
  { id: 'PURCHASE', name: 'Purchase' },
];

export const bankAccountTypes = [
  { id: 'CURRENT', name: 'Cuenta corriente' },
  { id: 'SAVINGS', name: 'Cuenta de ahorros' },
  { id: 'PAYROLL', name: 'Cuenta nómina' },
  { id: 'REMUNERATED', name: 'Cuenta remunerada' },
  { id: 'OTHER', name: 'Otros' },
];

export const bankAccountTypesName = {
  CURRENT: 'Cuenta corriente',
  SAVINGS: 'Cuenta de ahorros',
  PAYROLL: 'Cuenta nómina',
  REMUNERATED: 'Cuenta remunerada',
  OTHER: 'Otros',
};

export const areaCodes = [
  {
    value: '93',
    label: '(+93)',
  },
  {
    value: '358',
    label: '(+358)',
  },
  {
    value: '355',
    label: '(+355)',
  },
  {
    value: '213',
    label: '(+213)',
  },
  {
    value: '1684',
    label: '(+1684)',
  },
  {
    value: '376',
    label: '(+376)',
  },
  {
    value: '244',
    label: '(+244)',
  },
  {
    value: '1264',
    label: '(+1264)',
  },
  {
    value: '672',
    label: '(+672)',
  },
  {
    value: '1268',
    label: '(+1268)',
  },
  {
    value: '374',
    label: '(+374)',
  },
  {
    value: '297',
    label: '(+297)',
  },
  {
    value: '54',
    label: '(+54)',
  },
  {
    value: '61',
    label: '(+61)',
  },
  {
    value: '375',
    label: '(+375)',
  },
  {
    value: '32',
    label: '(+32)',
  },
  {
    value: '501',
    label: '(+501)',
  },
  {
    value: '1441',
    label: '(+1441)',
  },
  {
    value: '43',
    label: '(+43)',
  },
  {
    value: '994',
    label: '(+994)',
  },
  {
    value: '1242',
    label: '(+1242)',
  },
  {
    value: '973',
    label: '(+973)',
  },
  {
    value: '880',
    label: '(+880)',
  },
  {
    value: '1246',
    label: '(+1246)',
  },
  {
    value: '229',
    label: '(+229)',
  },
  {
    value: '387',
    label: '(+387)',
  },
  {
    value: '267',
    label: '(+267)',
  },
  {
    value: '',
    label: '(+)',
  },
  {
    value: '55',
    label: '(+55)',
  },
  {
    value: '61',
    label: '(+61)',
  },
  {
    value: '975',
    label: '(+975)',
  },
  {
    value: '591',
    label: '(+591)',
  },
  {
    value: '5997',
    label: '(+5997)',
  },
  {
    value: '246',
    label: '(+246)',
  },
  {
    value: '',
    label: '(+)',
  },
  {
    value: '1284',
    label: '(+1284)',
  },
  {
    value: '1 340',
    label: '(+1 340)',
  },
  {
    value: '673',
    label: '(+673)',
  },
  {
    value: '359',
    label: '(+359)',
  },
  {
    value: '226',
    label: '(+226)',
  },
  {
    value: '257',
    label: '(+257)',
  },
  {
    value: '855',
    label: '(+855)',
  },
  {
    value: '237',
    label: '(+237)',
  },
  {
    value: '1',
    label: '(+1)',
  },
  {
    value: '61',
    label: '(+61)',
  },
  {
    value: '238',
    label: '(+238)',
  },
  {
    value: '1345',
    label: '(+1345)',
  },
  {
    value: '236',
    label: '(+236)',
  },
  {
    value: '235',
    label: '(+235)',
  },
  {
    value: '56',
    label: '(+56)',
  },
  {
    value: '86',
    label: '(+86)',
  },
  {
    value: '57',
    label: '(+57)',
  },
  {
    value: '269',
    label: '(+269)',
  },
  {
    value: '242',
    label: '(+242)',
  },
  {
    value: '243',
    label: '(+243)',
  },
  {
    value: '682',
    label: '(+682)',
  },
  {
    value: '506',
    label: '(+506)',
  },
  {
    value: '385',
    label: '(+385)',
  },
  {
    value: '53',
    label: '(+53)',
  },
  {
    value: '599',
    label: '(+599)',
  },
  {
    value: '357',
    label: '(+357)',
  },
  {
    value: '420',
    label: '(+420)',
  },
  {
    value: '45',
    label: '(+45)',
  },
  {
    value: '253',
    label: '(+253)',
  },
  {
    value: '1767',
    label: '(+1767)',
  },
  {
    value: '1809',
    label: '(+1809)',
  },
  {
    value: '593',
    label: '(+593)',
  },
  {
    value: '49',
    label: '(+49)',
  },
  {
    value: '20',
    label: '(+20)',
  },
  {
    value: '503',
    label: '(+503)',
  },
  {
    value: '240',
    label: '(+240)',
  },
  {
    value: '291',
    label: '(+291)',
  },
  {
    value: '372',
    label: '(+372)',
  },
  {
    value: '596',
    label: '(+596)',
  },
  {
    value: '251',
    label: '(+251)',
  },
  {
    value: '500',
    label: '(+500)',
  },
  {
    value: '298',
    label: '(+298)',
  },
  {
    value: '679',
    label: '(+679)',
  },
  {
    value: '358',
    label: '(+358)',
  },
  {
    value: '33',
    label: '(+33)',
  },
  {
    value: '594',
    label: '(+594)',
  },
  {
    value: '689',
    label: '(+689)',
  },
  {
    value: '',
    label: '(+)',
  },
  {
    value: '241',
    label: '(+241)',
  },
  {
    value: '220',
    label: '(+220)',
  },
  {
    value: '995',
    label: '(+995)',
  },
  {
    value: '233',
    label: '(+233)',
  },
  {
    value: '350',
    label: '(+350)',
  },
  {
    value: '30',
    label: '(+30)',
  },
  {
    value: '299',
    label: '(+299)',
  },
  {
    value: '1473',
    label: '(+1473)',
  },
  {
    value: '590',
    label: '(+590)',
  },
  {
    value: '1671',
    label: '(+1671)',
  },
  {
    value: '502',
    label: '(+502)',
  },
  {
    value: '44',
    label: '(+44)',
  },
  {
    value: '224',
    label: '(+224)',
  },
  {
    value: '245',
    label: '(+245)',
  },
  {
    value: '592',
    label: '(+592)',
  },
  {
    value: '509',
    label: '(+509)',
  },
  {
    value: '',
    label: '(+)',
  },
  {
    value: '379',
    label: '(+379)',
  },
  {
    value: '504',
    label: '(+504)',
  },
  {
    value: '852',
    label: '(+852)',
  },
  {
    value: '36',
    label: '(+36)',
  },
  {
    value: '354',
    label: '(+354)',
  },
  {
    value: '91',
    label: '(+91)',
  },
  {
    value: '62',
    label: '(+62)',
  },
  {
    value: '225',
    label: '(+225)',
  },
  {
    value: '98',
    label: '(+98)',
  },
  {
    value: '964',
    label: '(+964)',
  },
  {
    value: '353',
    label: '(+353)',
  },
  {
    value: '44',
    label: '(+44)',
  },
  {
    value: '972',
    label: '(+972)',
  },
  {
    value: '39',
    label: '(+39)',
  },
  {
    value: '1876',
    label: '(+1876)',
  },
  {
    value: '81',
    label: '(+81)',
  },
  {
    value: '44',
    label: '(+44)',
  },
  {
    value: '377',
    label: '(+377)',
  },
  {
    value: '962',
    label: '(+962)',
  },
  {
    value: '76',
    label: '(+76)',
  },
  {
    value: '254',
    label: '(+254)',
  },
  {
    value: '686',
    label: '(+686)',
  },
  {
    value: '965',
    label: '(+965)',
  },
  {
    value: '996',
    label: '(+996)',
  },
  {
    value: '856',
    label: '(+856)',
  },
  {
    value: '371',
    label: '(+371)',
  },
  {
    value: '961',
    label: '(+961)',
  },
  {
    value: '266',
    label: '(+266)',
  },
  {
    value: '231',
    label: '(+231)',
  },
  {
    value: '218',
    label: '(+218)',
  },
  {
    value: '423',
    label: '(+423)',
  },
  {
    value: '370',
    label: '(+370)',
  },
  {
    value: '352',
    label: '(+352)',
  },
  {
    value: '853',
    label: '(+853)',
  },
  {
    value: '389',
    label: '(+389)',
  },
  {
    value: '261',
    label: '(+261)',
  },
  {
    value: '265',
    label: '(+265)',
  },
  {
    value: '60',
    label: '(+60)',
  },
  {
    value: '960',
    label: '(+960)',
  },
  {
    value: '223',
    label: '(+223)',
  },
  {
    value: '356',
    label: '(+356)',
  },
  {
    value: '692',
    label: '(+692)',
  },
  {
    value: '222',
    label: '(+222)',
  },
  {
    value: '230',
    label: '(+230)',
  },
  {
    value: '262',
    label: '(+262)',
  },
  {
    value: '52',
    label: '(+52)',
  },
  {
    value: '691',
    label: '(+691)',
  },
  {
    value: '373',
    label: '(+373)',
  },
  {
    value: '976',
    label: '(+976)',
  },
  {
    value: '382',
    label: '(+382)',
  },
  {
    value: '1664',
    label: '(+1664)',
  },
  {
    value: '212',
    label: '(+212)',
  },
  {
    value: '258',
    label: '(+258)',
  },
  {
    value: '95',
    label: '(+95)',
  },
  {
    value: '264',
    label: '(+264)',
  },
  {
    value: '674',
    label: '(+674)',
  },
  {
    value: '977',
    label: '(+977)',
  },
  {
    value: '31',
    label: '(+31)',
  },
  {
    value: '687',
    label: '(+687)',
  },
  {
    value: '64',
    label: '(+64)',
  },
  {
    value: '505',
    label: '(+505)',
  },
  {
    value: '227',
    label: '(+227)',
  },
  {
    value: '234',
    label: '(+234)',
  },
  {
    value: '683',
    label: '(+683)',
  },
  {
    value: '672',
    label: '(+672)',
  },
  {
    value: '850',
    label: '(+850)',
  },
  {
    value: '1670',
    label: '(+1670)',
  },
  {
    value: '47',
    label: '(+47)',
  },
  {
    value: '968',
    label: '(+968)',
  },
  {
    value: '92',
    label: '(+92)',
  },
  {
    value: '680',
    label: '(+680)',
  },
  {
    value: '970',
    label: '(+970)',
  },
  {
    value: '507',
    label: '(+507)',
  },
  {
    value: '675',
    label: '(+675)',
  },
  {
    value: '595',
    label: '(+595)',
  },
  {
    value: '51',
    label: '(+51)',
  },
  {
    value: '63',
    label: '(+63)',
  },
  {
    value: '64',
    label: '(+64)',
  },
  {
    value: '48',
    label: '(+48)',
  },
  {
    value: '351',
    label: '(+351)',
  },
  {
    value: '1787',
    label: '(+1787)',
  },
  {
    value: '974',
    label: '(+974)',
  },
  {
    value: '383',
    label: '(+383)',
  },
  {
    value: '262',
    label: '(+262)',
  },
  {
    value: '40',
    label: '(+40)',
  },
  {
    value: '7',
    label: '(+7)',
  },
  {
    value: '250',
    label: '(+250)',
  },
  {
    value: '590',
    label: '(+590)',
  },
  {
    value: '290',
    label: '(+290)',
  },
  {
    value: '1869',
    label: '(+1869)',
  },
  {
    value: '1758',
    label: '(+1758)',
  },
  {
    value: '590',
    label: '(+590)',
  },
  {
    value: '508',
    label: '(+508)',
  },
  {
    value: '1784',
    label: '(+1784)',
  },
  {
    value: '685',
    label: '(+685)',
  },
  {
    value: '378',
    label: '(+378)',
  },
  {
    value: '239',
    label: '(+239)',
  },
  {
    value: '966',
    label: '(+966)',
  },
  {
    value: '221',
    label: '(+221)',
  },
  {
    value: '381',
    label: '(+381)',
  },
  {
    value: '248',
    label: '(+248)',
  },
  {
    value: '34',
    label: '(+34)',
  },
  {
    value: '232',
    label: '(+232)',
  },
  {
    value: '65',
    label: '(+65)',
  },
  {
    value: '1721',
    label: '(+1721)',
  },
  {
    value: '421',
    label: '(+421)',
  },
  {
    value: '386',
    label: '(+386)',
  },
  {
    value: '677',
    label: '(+677)',
  },
  {
    value: '252',
    label: '(+252)',
  },
  {
    value: '27',
    label: '(+27)',
  },
  {
    value: '500',
    label: '(+500)',
  },
  {
    value: '82',
    label: '(+82)',
  },
  {
    value: '211',
    label: '(+211)',
  },
  {
    value: '94',
    label: '(+94)',
  },
  {
    value: '249',
    label: '(+249)',
  },
  {
    value: '597',
    label: '(+597)',
  },
  {
    value: '4779',
    label: '(+4779)',
  },
  {
    value: '268',
    label: '(+268)',
  },
  {
    value: '46',
    label: '(+46)',
  },
  {
    value: '41',
    label: '(+41)',
  },
  {
    value: '963',
    label: '(+963)',
  },
  {
    value: '886',
    label: '(+886)',
  },
  {
    value: '992',
    label: '(+992)',
  },
  {
    value: '255',
    label: '(+255)',
  },
  {
    value: '66',
    label: '(+66)',
  },
  {
    value: '670',
    label: '(+670)',
  },
  {
    value: '228',
    label: '(+228)',
  },
  {
    value: '690',
    label: '(+690)',
  },
  {
    value: '676',
    label: '(+676)',
  },
  {
    value: '1868',
    label: '(+1868)',
  },
  {
    value: '216',
    label: '(+216)',
  },
  {
    value: '90',
    label: '(+90)',
  },
  {
    value: '993',
    label: '(+993)',
  },
  {
    value: '1649',
    label: '(+1649)',
  },
  {
    value: '688',
    label: '(+688)',
  },
  {
    value: '256',
    label: '(+256)',
  },
  {
    value: '380',
    label: '(+380)',
  },
  {
    value: '971',
    label: '(+971)',
  },
  {
    value: '44',
    label: '(+44)',
  },
  {
    value: '1',
    label: '(+1)',
  },
  {
    value: '598',
    label: '(+598)',
  },
  {
    value: '998',
    label: '(+998)',
  },
  {
    value: '678',
    label: '(+678)',
  },
  {
    value: '58',
    label: '(+58)',
  },
  {
    value: '84',
    label: '(+84)',
  },
  {
    value: '681',
    label: '(+681)',
  },
  {
    value: '212',
    label: '(+212)',
  },
  {
    value: '967',
    label: '(+967)',
  },
  {
    value: '260',
    label: '(+260)',
  },
  {
    value: '263',
    label: '(+263)',
  },
];

export const billDocument = [
  { value: 'BILL', label: 'FACTURA' },
  { value: 'RECEIPT', label: 'RECIBO' },
];

export const billExpense = [
  { value: 'FIXED', label: 'FIJO' },
  { value: 'VARIABLE', label: 'VARIABLE' },
];

export const typeEmployees = [
  { value: 'PAYROLL', label: 'Nómina' },
  { value: 'PROFESSIONAL_SERVICES', label: 'Servicio profesional' },
];

export const paymentMethods = [
  { value: 'CASH', label: 'Efectivo' },
  { value: 'BANK_PAYMENT', label: 'Depósito o transferencia bancaria' },
  { value: 'CHECK', label: 'Cheque' },
  { value: 'CREDIT_CARD', label: 'Tarjeta de crédito' },
  { value: 'PAYPAL', label: 'Paypal' },
  { value: 'OTHER', label: 'Otro' },
];

export const paymentMethodsName = {
  CASH: 'Efectivo',
  BANK_PAYMENT: 'Depósito o transferencia bancaria',
  CHECK: 'Cheque',
  CREDIT_CARD: 'Tarjeta de crédito',
  PAYPAL: 'Paypal',
  OTHER: 'Otro',
};

export const statusName = {
  PAID: 'Pagada',
  OVERDUE: 'Atrasada',
  UNPAID: 'Pendiente',
  PARTIAL: 'Parcial',
  CANCELLED: 'Anulado',
  REJECTED: 'Rechazado',
  ACCEPTED: 'Aceptado',
  ACTIVE: 'Activo',
};

export const statusNamePaymentHistory = {
  'Pendiente': 'UNPAID',
  'Rechazado': 'REJECTED',
  'Aceptado': 'ACCEPTED',
};

export const statusEmployee = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
};

export const documentStatus = [
  { value: 'PAID', label: 'Pagada' },
  { value: 'OVERDUE', label: 'Atrasada' },
  { value: 'UNPAID', label: 'Pendiente' },
  { value: 'PARTIAL', label: 'Parcial' },
  { value: 'CANCELLED', label: 'Anulado' },
];

export const typeFrequency = [
  { value: 'ONLY_ONCE', label: 'Sólo una vez' },
  { value: 'EVERY_MONTH', label: 'Cada mes' },
];

export const months = [
  { value: 1, label: 'Enero' },
  { value: 2, label: 'Febrero' },
  { value: 3, label: 'Marzo' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Mayo' },
  { value: 6, label: 'Junio' },
  { value: 7, label: 'Julio' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Septiembre' },
  { value: 10, label: 'Octubre' },
  { value: 11, label: 'Noviembre' },
  { value: 12, label: 'Diciembre' },
];

export const accountTypesName = {
  ASSET: 'ACTIVOS',
  LIABILITY: 'PASIVOS',
  INCOME: 'INGRESOS',
  EXPENSE: 'COSTOS Y GASTOS',
  EQUITY: 'CAPITAL',
};

export const rolesName = {
  OWNER: 'Propietario',
  ADMIN: 'Administrador',
  READ: 'Lectura',
  WRITE: 'Editor',
};

export const typePayroll = {
  BENEFIT: 'Bonificación',
  DISCOUNT: 'Descuento',
  SALARY: 'Salario',
};

export const enterpriseTypesNames = {
  'Sole Proprietorship': 'Propietario único',
  Partnership: 'Sociedad',
  Corporation: 'Corporación',
};

export const typeFrequencyNames = {
  ONLY_ONCE: 'Sólo una vez',
  EVERY_MONTH: 'Cada mes',
};

export const transactionsType = [
  { value: 'CREDIT', label: 'Egreso' },
  { value: 'DEBIT', label: 'Ingreso' },
  // { value: 'JOURNAL', label: "Diario" },
];

export const listStatusEmployee = [
  {
    label: 'Activo',
    value: 'ACTIVE',
  },
  {
    label: 'Inactivo',
    value: 'INACTIVE',
  },
];

export const typeBilling = [
  { label: 'Infile', value: 'INFILE' },
  {
    label: 'Megaprint',
    value: 'MEGAPRINT',
  },
];

export let departments = [
  {
    label: 'Alta Verapaz',
    value: [
      { label: 'Cahabón', value: 'Cahabón' },
      { label: 'Chahal', value: 'Chahal' },
      { label: 'Chisec', value: 'Chisec' },
      { label: 'Cobán', value: 'Cobán' },
      {
        label: 'Fray Bartolomé de las Casas',
        value: 'Fray Bartolomé de las Casas',
      },
      { label: 'Lanquín', value: 'Lanquín' },
      { label: 'Panzós', value: 'Panzós' },
      { label: 'Raxruha', value: 'Raxruha' },
      { label: 'San Cristóbal Verapaz', value: 'San Cristóbal Verapaz' },
      { label: 'San Juan Chamelco', value: 'San Juan Chamelco' },
      { label: 'San Pedro Carchá', value: 'San Pedro Carchá' },
      { label: 'Santa Cruz Verapaz', value: 'Santa Cruz Verapaz' },
      { label: 'Senahú', value: 'Senahú' },
      { label: 'Tactic', value: 'Tactic' },
      { label: 'Tamahú', value: 'Tamahú' },
      { label: 'Tucurú', value: 'Tucurú' },
      { label: 'Santa Catarina La Tinta', value: 'Santa Catarina La Tinta' },
    ],
  },
  {
    label: 'Baja Verapaz',
    value: [
      { label: 'Cubulco', value: 'Cubulco' },
      { label: 'Granados', value: 'Granados' },
      { label: 'Purulhá', value: 'Purulhá' },
      { label: 'Rabinal', value: 'Rabinal' },
      { label: 'Salamá', value: 'Salamá' },
      { label: 'San Jerónimo', value: 'San Jerónimo' },
      { label: 'San Miguel Chicaj', value: 'San Miguel Chicaj' },
      { label: 'Santa Cruz El Chol', value: 'Santa Cruz El Chol' },
    ],
  },
  {
    label: 'Chimaltenango',
    value: [
      { label: 'Acatenango', value: 'Acatenango' },
      { label: 'Chimaltenango', value: 'Chimaltenango' },
      { label: 'El Tejar', value: 'El Tejar' },
      { label: 'Parramos', value: 'Parramos' },
      { label: 'Patzicía', value: 'Patzicía' },
      { label: 'Patzún', value: 'Patzún' },
      { label: 'Pochuta', value: 'Pochuta' },
      { label: 'San Andrés Itzapa', value: 'San Andrés Itzapa' },
      { label: 'San José Poaquil', value: 'San José Poaquil' },
      { label: 'San Juan Comalapa', value: 'San Juan Comalapa' },
      { label: 'San Martín Jilotepeque', value: 'San Martín Jilotepeque' },
      { label: 'Santa Apolonia', value: 'Santa Apolonia' },
      { label: 'Santa Cruz Balanyá', value: 'Santa Cruz Balanyá' },
      { label: 'Tecpán Guatemala', value: 'Tecpán Guatemala' },
      { label: 'Yepocapa', value: 'Yepocapa' },
      { label: 'Zaragoza', value: 'Zaragoza' },
    ],
  },
  {
    label: 'Chiquimula',
    value: [
      { label: 'Camotán', value: 'Camotán' },
      { label: 'Chiquimula', value: 'Chiquimula' },
      { label: 'Concepción Las Minas', value: 'Concepción Las Minas' },
      { label: 'Esquipulas', value: 'Esquipulas' },
      { label: 'Ipala', value: 'Ipala' },
      { label: 'Jocotán', value: 'Jocotán' },
      { label: 'Olopa', value: 'Olopa' },
      { label: 'Quezaltepeque', value: 'Quezaltepeque' },
      { label: 'San Jacinto', value: 'San Jacinto' },
      { label: 'San José La Arada', value: 'San José La Arada' },
      { label: 'San Juan Ermita', value: 'San Juan Ermita' },
    ],
  },
  {
    label: 'El Progreso',
    value: [
      { label: 'El Jícaro', value: 'El Jícaro' },
      { label: 'Guastatoya', value: 'Guastatoya' },
      { label: 'Morazán', value: 'Morazán' },
      {
        label: 'San Agustín Acasaguastlán',
        value: 'San Agustín Acasaguastlán',
      },
      { label: 'San Antonio La Paz', value: 'San Antonio La Paz' },
      {
        label: 'San Cristóbal Acasaguastlán',
        value: 'San Cristóbal Acasaguastlán',
      },
      { label: 'Sanarate', value: 'Sanarate' },
    ],
  },
  {
    label: 'Escuintla',
    value: [
      { label: 'Escuintla', value: 'Escuintla' },
      { label: 'Guanagazapa', value: 'Guanagazapa' },
      { label: 'Iztapa', value: 'Iztapa' },
      { label: 'La Democracia', value: 'La Democracia' },
      { label: 'La Gomera', value: 'La Gomera' },
      { label: 'Masagua', value: 'Masagua' },
      { label: 'Nueva Concepción', value: 'Nueva Concepción' },
      { label: 'Palín', value: 'Palín' },
      { label: 'San José', value: 'San José' },
      { label: 'San Vicente Pacaya', value: 'San Vicente Pacaya' },
      {
        label: 'Santa Lucía Cotzumalguapa',
        value: 'Santa Lucía Cotzumalguapa',
      },
      { label: 'Siquinalá', value: 'Siquinalá' },
      { label: 'Tiquisate', value: 'Tiquisate' },
    ],
  },
  {
    label: 'Guatemala',
    value: [
      { label: 'Amatitlán', value: 'Amatitlán' },
      { label: 'Chinautla', value: 'Chinautla' },
      { label: 'Chuarrancho', value: 'Chuarrancho' },
      { label: 'Fraijanes', value: 'Fraijanes' },
      { label: 'Guatemala', value: 'Guatemala' },
      { label: 'Mixco', value: 'Mixco' },
      { label: 'Palencia', value: 'Palencia' },
      { label: 'Petapa', value: 'Petapa' },
      { label: 'San José del Golfo', value: 'San José del Golfo' },
      { label: 'San José Pinula', value: 'San José Pinula' },
      { label: 'San Juan Sacatepéquez', value: 'San Juan Sacatepéquez' },
      { label: 'San Pedro Ayampuc', value: 'San Pedro Ayampuc' },
      { label: 'San Pedro Sacatepéquez', value: 'San Pedro Sacatepéquez' },
      { label: 'San Raymundo', value: 'San Raymundo' },
      { label: 'Santa Catarina Pinula', value: 'Santa Catarina Pinula' },
      { label: 'Villa Canales', value: 'Villa Canales' },
    ],
  },
  {
    label: 'Huehuetenango',
    value: [
      { label: 'Aguacatán', value: 'Aguacatán' },
      { label: 'Chiantla', value: 'Chiantla' },
      { label: 'Colotenango', value: 'Colotenango' },
      { label: 'Concepción Huista', value: 'Concepción Huista' },
      { label: 'Cuilco', value: 'Cuilco' },
      { label: 'Huehuetenango', value: 'Huehuetenango' },
      { label: 'Ixtahuacán', value: 'Ixtahuacán' },
      { label: 'Jacaltenango', value: 'Jacaltenango' },
      { label: 'La Democracia', value: 'La Democracia' },
      { label: 'La Libertad', value: 'La Libertad' },
      { label: 'Malacatancito', value: 'Malacatancito' },
      { label: 'Nentón', value: 'Nentón' },
      { label: 'San Antonio Huista', value: 'San Antonio Huista' },
      { label: 'San Gaspar Ixchil', value: 'San Gaspar Ixchil' },
      { label: 'San Juan Atitán', value: 'San Juan Atitán' },
      { label: 'San Juan Ixcoy', value: 'San Juan Ixcoy' },
      { label: 'San Mateo Ixtatán', value: 'San Mateo Ixtatán' },
      { label: 'San Miguel Acatán', value: 'San Miguel Acatán' },
      { label: 'San Pedro Necta', value: 'San Pedro Necta' },
      {
        label: 'San Rafael La Independencia',
        value: 'San Rafael La Independencia',
      },
      { label: 'San Rafael Petzal', value: 'San Rafael Petzal' },
      { label: 'San Sebastián Coatán', value: 'San Sebastián Coatán' },
      {
        label: 'San Sebastián Huehuetenango',
        value: 'San Sebastián Huehuetenango',
      },
      { label: 'Santa Ana Huista', value: 'Santa Ana Huista' },
      { label: 'Santa Bárbara', value: 'Santa Bárbara' },
      { label: 'Santa Cruz Barillas', value: 'Santa Cruz Barillas' },
      { label: 'Santa Eulalia', value: 'Santa Eulalia' },
      { label: 'Santiago Chimaltenango', value: 'Santiago Chimaltenango' },
      { label: 'Soloma', value: 'Soloma' },
      { label: 'Tectitán', value: 'Tectitán' },
      { label: 'Todos Santos Cuchumatan', value: 'Todos Santos Cuchumatan' },
    ],
  },
  {
    label: 'Izabal',
    value: [
      { label: 'El Estor', value: 'El Estor' },
      { label: 'Livingston', value: 'Livingston' },
      { label: 'Los Amates', value: 'Los Amates' },
      { label: 'Morales', value: 'Morales' },
      { label: 'Puerto Barrios', value: 'Puerto Barrios' },
    ],
  },
  {
    label: 'Jutiapa',
    value: [
      { label: 'Agua Blanca', value: 'Agua Blanca' },
      { label: 'Asunción Mita', value: 'Asunción Mita' },
      { label: 'Atescatempa', value: 'Atescatempa' },
      { label: 'Comapa', value: 'Comapa' },
      { label: 'Conguaco', value: 'Conguaco' },
      { label: 'El Adelanto', value: 'El Adelanto' },
      { label: 'El Progreso', value: 'El Progreso' },
      { label: 'Jalpatagua', value: 'Jalpatagua' },
      { label: 'Jerez', value: 'Jerez' },
      { label: 'Jutiapa', value: 'Jutiapa' },
      { label: 'Moyuta', value: 'Moyuta' },
      { label: 'Pasaco', value: 'Pasaco' },
      { label: 'Quezada', value: 'Quezada' },
      { label: 'San José Acatempa', value: 'San José Acatempa' },
      { label: 'Santa Catarina Mita', value: 'Santa Catarina Mita' },
      { label: 'Yupiltepeque', value: 'Yupiltepeque' },
      { label: 'Zapotitlán', value: 'Zapotitlán' },
    ],
  },
  {
    label: 'Petén',
    value: [
      { label: 'Dolores', value: 'Dolores' },
      { label: 'Flores', value: 'Flores' },
      { label: 'La Libertad', value: 'La Libertad' },
      { label: 'Melchor de Mencos', value: 'Melchor de Mencos' },
      { label: 'Poptún', value: 'Poptún' },
      { label: 'San Andrés', value: 'San Andrés' },
      { label: 'San Benito', value: 'San Benito' },
      { label: 'San Francisco', value: 'San Francisco' },
      { label: 'San José', value: 'San José' },
      { label: 'San Luis', value: 'San Luis' },
      { label: 'Santa Ana', value: 'Santa Ana' },
      { label: 'Sayaxché', value: 'Sayaxché' },
      { label: 'Las Cruces', value: 'Las Cruces' },
      { label: 'El Chal', value: 'El Chal' },
    ],
  },
  {
    label: 'Quetzaltenango',
    value: [
      { label: 'Almolonga', value: 'Almolonga' },
      { label: 'Cabricán', value: 'Cabricán' },
      { label: 'Cajolá', value: 'Cajolá' },
      { label: 'Cantel', value: 'Cantel' },
      { label: 'Coatepeque', value: 'Coatepeque' },
      { label: 'Colomba', value: 'Colomba' },
      { label: 'Concepción Chiquirichapa', value: 'Concepción Chiquirichapa' },
      { label: 'El Palmar', value: 'El Palmar' },
      { label: 'Flores Costa Cuca', value: 'Flores Costa Cuca' },
      { label: 'Génova', value: 'Génova' },
      { label: 'Huitán', value: 'Huitán' },
      { label: 'La Esperanza', value: 'La Esperanza' },
      { label: 'Olintepeque', value: 'Olintepeque' },
      { label: 'Ostuncalco', value: 'Ostuncalco' },
      { label: 'Palestina de Los Altos', value: 'Palestina de Los Altos' },
      { label: 'Quetzaltenango', value: 'Quetzaltenango' },
      { label: 'Salcajá', value: 'Salcajá' },
      { label: 'San Carlos Sija', value: 'San Carlos Sija' },
      { label: 'San Francisco La Unión', value: 'San Francisco La Unión' },
      { label: 'San Martín Sacatepéquez', value: 'San Martín Sacatepéquez' },
      { label: 'San Mateo', value: 'San Mateo' },
      { label: 'San Miguel Sigüilá', value: 'San Miguel Sigüilá' },
      { label: 'Sibilia', value: 'Sibilia' },
      { label: 'Zunil', value: 'Zunil' },
    ],
  },
  {
    label: 'Quiché',
    value: [
      { label: 'Canillá', value: 'Canillá' },
      { label: 'Chajul', value: 'Chajul' },
      { label: 'Chicamán', value: 'Chicamán' },
      { label: 'Chiché', value: 'Chiché' },
      { label: 'Chichicastenango', value: 'Chichicastenango' },
      { label: 'Chinique', value: 'Chinique' },
      { label: 'Cunén', value: 'Cunén' },
      { label: 'Ixcán', value: 'Ixcán' },
      { label: 'Joyabaj', value: 'Joyabaj' },
      { label: 'Nebaj', value: 'Nebaj' },
      { label: 'Pachalum', value: 'Pachalum' },
      { label: 'Patzité', value: 'Patzité' },
      { label: 'Sacapulas', value: 'Sacapulas' },
      { label: 'San Andrés Sajcabajá', value: 'San Andrés Sajcabajá' },
      { label: 'San Antonio Ilotenango', value: 'San Antonio Ilotenango' },
      {
        label: 'San Bartolomé Jocotenango',
        value: 'San Bartolomé Jocotenango',
      },
      { label: 'San Juan Cotzal', value: 'San Juan Cotzal' },
      { label: 'San Pedro Jocopilas', value: 'San Pedro Jocopilas' },
      { label: 'Santa Cruz del Quiché', value: 'Santa Cruz del Quiché' },
      { label: 'Uspantán', value: 'Uspantán' },
      { label: 'Zacualpa', value: 'Zacualpa' },
    ],
  },
  {
    label: 'Retalhuleu',
    value: [
      { label: 'Champerico', value: 'Champerico' },
      { label: 'El Asintal', value: 'El Asintal' },
      { label: 'Nuevo San Carlos', value: 'Nuevo San Carlos' },
      { label: 'Retalhuleu', value: 'Retalhuleu' },
      { label: 'San Andrés Villa Seca', value: 'San Andrés Villa Seca' },
      { label: 'San Felipe', value: 'San Felipe' },
      { label: 'San Martín Zapotitlán', value: 'San Martín Zapotitlán' },
      { label: 'San Sebastián', value: 'San Sebastián' },
      { label: 'Santa Cruz Muluá', value: 'Santa Cruz Muluá' },
    ],
  },
  {
    label: 'Sacatepéquez',
    value: [
      { label: 'Alotenango', value: 'Alotenango' },
      { label: 'Antigua', value: 'Antigua' },
      { label: 'Ciudad Vieja', value: 'Ciudad Vieja' },
      { label: 'Jocotenango', value: 'Jocotenango' },
      { label: 'Magdalena Milpas Altas', value: 'Magdalena Milpas Altas' },
      { label: 'Pastores', value: 'Pastores' },
      {
        label: 'San Antonio Aguas Calientes',
        value: 'San Antonio Aguas Calientes',
      },
      {
        label: 'San Bartolomé Milpas Altas',
        value: 'San Bartolomé Milpas Altas',
      },
      { label: 'San Lucas Sacatepéquez', value: 'San Lucas Sacatepéquez' },
      { label: 'San Miguel Dueñas', value: 'San Miguel Dueñas' },
      { label: 'Santa Catarina Barahona', value: 'Santa Catarina Barahona' },
      { label: 'Santa Lucía Milpas Altas', value: 'Santa Lucía Milpas Altas' },
      { label: 'Santa María de Jesús', value: 'Santa María de Jesús' },
      { label: 'Santiago Sacatepéquez', value: 'Santiago Sacatepéquez' },
      { label: 'Santo Domingo Xenacoj', value: 'Santo Domingo Xenacoj' },
      { label: 'Sumpango', value: 'Sumpango' },
    ],
  },
  {
    label: 'San Marcos',
    value: [
      { label: 'Ayutla', value: 'Ayutla' },
      { label: 'Catarina', value: 'Catarina' },
      { label: 'Comitancillo', value: 'Comitancillo' },
      { label: 'Concepción Tutuapa', value: 'Concepción Tutuapa' },
      { label: 'El Quetzal', value: 'El Quetzal' },
      { label: 'El Rodeo', value: 'El Rodeo' },
      { label: 'El Tumbador', value: 'El Tumbador' },
      { label: 'Esquipulas Palo Gordo', value: 'Esquipulas Palo Gordo' },
      { label: 'Ixchiguan', value: 'Ixchiguan' },
      { label: 'La Reforma', value: 'La Reforma' },
      { label: 'Malacatán', value: 'Malacatán' },
      { label: 'Nuevo Progreso', value: 'Nuevo Progreso' },
      { label: 'Ocos', value: 'Ocos' },
      { label: 'Pajapita', value: 'Pajapita' },
      { label: 'Río Blanco', value: 'Río Blanco' },
      { label: 'San Antonio Sacatepéquez', value: 'San Antonio Sacatepéquez' },
      { label: 'San Cristóbal Cucho', value: 'San Cristóbal Cucho' },
      { label: 'San José Ojetenam', value: 'San José Ojetenam' },
      { label: 'San Lorenzo', value: 'San Lorenzo' },
      { label: 'San Marcos', value: 'San Marcos' },
      { label: 'San Miguel Ixtahuacán', value: 'San Miguel Ixtahuacán' },
      { label: 'San Pablo', value: 'San Pablo' },
      { label: 'San Pedro Sacatepéquez', value: 'San Pedro Sacatepéquez' },
      {
        label: 'San Rafael Pie de La Cuesta',
        value: 'San Rafael Pie de La Cuesta',
      },
      { label: 'San Sibinal', value: 'San Sibinal' },
      { label: 'Sipacapa', value: 'Sipacapa' },
      { label: 'Tacaná', value: 'Tacaná' },
      { label: 'Tajumulco', value: 'Tajumulco' },
      { label: 'Tejutla', value: 'Tejutla' },
    ],
  },
  {
    label: 'Jalapa',
    value: [
      { label: 'Jalapa', value: 'Jalapa' },
      { label: 'Mataquescuintla', value: 'Mataquescuintla' },
      { label: 'Monjas', value: 'Monjas' },
      { label: 'San Carlos Alzatate', value: 'San Carlos Alzatate' },
      { label: 'San Luis Jilotepeque', value: 'San Luis Jilotepeque' },
      { label: 'San Pedro Pinula', value: 'San Pedro Pinula' },
      { label: 'San Manuel Chaparrón', value: 'San Manuel Chaparrón' },
    ],
  },
  {
    label: 'Santa Rosa',
    value: [
      { label: 'Barberena', value: 'Barberena' },
      { label: 'Casillas', value: 'Casillas' },
      { label: 'Chiquimulilla', value: 'Chiquimulilla' },
      { label: 'Cuilapa', value: 'Cuilapa' },
      { label: 'Guazacapán', value: 'Guazacapán' },
      { label: 'Nueva Santa Rosa', value: 'Nueva Santa Rosa' },
      { label: 'Oratorio', value: 'Oratorio' },
      { label: 'Pueblo Nuevo Viñas', value: 'Pueblo Nuevo Viñas' },
      { label: 'San Juan Tecuaco', value: 'San Juan Tecuaco' },
      { label: 'San Rafael Las Flores', value: 'San Rafael Las Flores' },
      { label: 'Santa Cruz Naranjo', value: 'Santa Cruz Naranjo' },
      { label: 'Santa María Ixhuatán', value: 'Santa María Ixhuatán' },
      { label: 'Santa Rosa de Lima', value: 'Santa Rosa de Lima' },
      { label: 'Taxisco', value: 'Taxisco' },
    ],
  },
  {
    label: 'Sololá',
    value: [
      { label: 'Concepción', value: 'Concepción' },
      { label: 'Nahualá', value: 'Nahualá' },
      { label: 'Panajachel', value: 'Panajachel' },
      { label: 'San Andrés Semetabaj', value: 'San Andrés Semetabaj' },
      { label: 'San Antonio Palopó', value: 'San Antonio Palopó' },
      { label: 'San José Chacaya', value: 'San José Chacaya' },
      { label: 'San Juan La Laguna', value: 'San Juan La Laguna' },
      { label: 'San Lucas Tolimán', value: 'San Lucas Tolimán' },
      { label: 'San Marcos La Laguna', value: 'San Marcos La Laguna' },
      { label: 'San Pablo La Laguna', value: 'San Pablo La Laguna' },
      { label: 'San Pedro La Laguna', value: 'San Pedro La Laguna' },
      {
        label: 'Santa Catarina Ixtahuacan',
        value: 'Santa Catarina Ixtahuacan',
      },
      { label: 'Santa Catarina Palopó', value: 'Santa Catarina Palopó' },
      { label: 'Santa Clara La Laguna', value: 'Santa Clara La Laguna' },
      { label: 'Santa Cruz La Laguna', value: 'Santa Cruz La Laguna' },
      { label: 'Santa Lucía Utatlán', value: 'Santa Lucía Utatlán' },
      { label: 'Santa María Visitación', value: 'Santa María Visitación' },
      { label: 'Santiago Atitlán', value: 'Santiago Atitlán' },
      { label: 'Sololá', value: 'Sololá' },
    ],
  },
  {
    label: 'Suchitepéquez',
    value: [
      { label: 'Chicacao', value: 'Chicacao' },
      { label: 'Cuyotenango', value: 'Cuyotenango' },
      { label: 'Mazatenango', value: 'Mazatenango' },
      { label: 'Patulul', value: 'Patulul' },
      { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
      { label: 'Río Bravo', value: 'Río Bravo' },
      { label: 'Samayac', value: 'Samayac' },
      {
        label: 'San Antonio Suchitepéquez',
        value: 'San Antonio Suchitepéquez',
      },
      { label: 'San Bernardino', value: 'San Bernardino' },
      { label: 'San Francisco Zapotitlán', value: 'San Francisco Zapotitlán' },
      { label: 'San Gabriel', value: 'San Gabriel' },
      { label: 'San José El Idolo', value: 'San José El Idolo' },
      { label: 'San Juan Bautista', value: 'San Juan Bautista' },
      { label: 'San Lorenzo', value: 'San Lorenzo' },
      { label: 'San Miguel Panán', value: 'San Miguel Panán' },
      { label: 'San Pablo Jocopilas', value: 'San Pablo Jocopilas' },
      { label: 'Santa Bárbara', value: 'Santa Bárbara' },
      {
        label: 'Santo Domingo Suchitepequez',
        value: 'Santo Domingo Suchitepequez',
      },
      { label: 'Santo Tomas La Unión', value: 'Santo Tomas La Unión' },
      { label: 'Zunilito', value: 'Zunilito' },
    ],
  },
  {
    label: 'Totonicapán',
    value: [
      { label: 'Momostenango', value: 'Momostenango' },
      { label: 'San Andrés Xecul', value: 'San Andrés Xecul' },
      { label: 'San Bartolo', value: 'San Bartolo' },
      {
        label: 'San Cristóbal Totonicapán',
        value: 'San Cristóbal Totonicapán',
      },
      { label: 'San Francisco El Alto', value: 'San Francisco El Alto' },
      { label: 'Santa Lucía La Reforma', value: 'Santa Lucía La Reforma' },
      { label: 'Santa María Chiquimula', value: 'Santa María Chiquimula' },
      { label: 'Totonicapán', value: 'Totonicapán' },
    ],
  },
  {
    label: 'Zacapa',
    value: [
      { label: 'Cabañas', value: 'Cabañas' },
      { label: 'Estanzuela', value: 'Estanzuela' },
      { label: 'Gualán', value: 'Gualán' },
      { label: 'Huité', value: 'Huité' },
      { label: 'La Unión', value: 'La Unión' },
      { label: 'Río Hondo', value: 'Río Hondo' },
      { label: 'San Diego', value: 'San Diego' },
      { label: 'Teculután', value: 'Teculután' },
      { label: 'Usumatlán', value: 'Usumatlán' },
      { label: 'Zacapa', value: 'Zacapa' },
    ],
  },
];

export let typePharaseAndStage = [
  {
    escenario: 1,
    tipo: 1,
  },
  {
    escenario: 2,
    tipo: 1,
  },
  {
    escenario: 3,
    tipo: 1,
  },
  {
    escenario: 1,
    tipo: 2,
  },
];

export let checkListPhraseAndStage = [
  'Sujeto a pagos trimestrales ISR',
  'Sujeto a retención definitiva ISR',
  'Sujeto a pago directo ISR',
  'Sujeto agente de retencion del IVA',
];

export const _productTypes = [
  {
    label: 'Bien',
    value: 'ASSET',
  },
  {
    label: 'Servicio',
    value: 'SERVICE',
  },
];

export const translatePlans = {
  '7491c3c3-874a-4940-ae75-954dd4e51e1d': 'Básico',
  '7be4c100-dca5-4203-a479-d71d979772bb': 'Avanzado',
  'e5c0c380-5d01-405e-b5b1-6afaa5b794a7': 'Personalizado',
};

export const departmentsBilling = {
  'Alta Verapaz': { ISOCode: 'GT-AV ', postalCode: '16000' },
  'Baja Verapaz': { ISOCode: 'GT-BV ', postalCode: '15000' },
  Chimaltenango: { ISOCode: 'GT-CM ', postalCode: '04000' },
  Chiquimula: { ISOCode: 'GT-CQ ', postalCode: '20000' },
  'El Progreso': { ISOCode: 'GT-PR ', postalCode: '02000' },
  Escuintla: { ISOCode: 'GT-ES ', postalCode: '05000' },
  Guatemala: { ISOCode: 'GT-GU ', postalCode: '01010' },
  Huehuetenango: { ISOCode: 'GT-HU ', postalCode: '13000' },
  Izabal: { ISOCode: 'GT-IZ ', postalCode: '18000' },
  Jalapa: { ISOCode: 'GT-JA ', postalCode: '21000' },
  Jutiapa: { ISOCode: 'GT-JU ', postalCode: '22000' },
  Petén: { ISOCode: 'GT-PE ', postalCode: '17000' },
  Quetzaltenango: { ISOCode: 'GT-QZ ', postalCode: '09000' },
  Quiché: { ISOCode: 'GT-QC ', postalCode: '14000' },
  Retalhuleu: { ISOCode: 'GT-RE ', postalCode: '11000' },
  Sacatepéquez: { ISOCode: 'GT-SA ', postalCode: '03000' },
  'San Marcos': { ISOCode: 'GT-SM ', postalCode: '12000' },
  'Santa Rosa': { ISOCode: 'GT-SR ', postalCode: '06000' },
};

export const statesGuatemala = {
  Amatitlán: { postalCode: '01063' },
  Chinautla: { postalCode: '01055' },
  Chuarrancho: { postalCode: '01061' },
  Fraijanes: { postalCode: '01062' },
  Guatemala: { postalCode: '' },
  Mixco: { postalCode: '01057' },
  Palencia: { postalCode: '01054' },
  Petapa: { postalCode: '01066' },
  'San José del Golfo': { postalCode: '01053' },
  'San José Pinula': { postalCode: '01052' },
  'San Juan Sacatepéquez': { postalCode: '01059' },
  'San Pedro Ayampuc': { postalCode: '01056' },
  'San Pedro Sacatepéquez': { postalCode: '01058' },
  'San Raymundo': { postalCode: '01060' },
  'Santa Catarina Pinula': { postalCode: '01051' },
  'Villa Canales': { postalCode: '01065' },
};

export const reasonCancelSubscription = [
  {
    label: 'No estoy usando la aplicación regularmente',
    value: 'No estoy usando la aplicación regularmente',
  },
  {
    label: 'Razones relacionadas con el costo',
    value: 'Razones relacionadas con el costo',
  },
  {
    label: 'Encontré una mejor aplicación',
    value: 'Encontré una mejor aplicación',
  },
  {
    label: 'La aplicación no estaba funcionando adecuadamente',
    value: 'La aplicación no estaba funcionando adecuadamente',
  },
  {
    label: 'Solo la necesitaba por un tiempo',
    value: 'Solo la necesitaba por un tiempo',
  },
  { label: 'Otro', value: 'Otro' },
];
