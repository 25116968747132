import gql from 'graphql-tag'

export const createEmployeeMutation = gql`
	mutation createEmployee($input: CreateEmployee!) {
		createEmployee(input: $input) {
			id
			name
			type
			status
			startDate
			endDate
			salary
			bill {
				dni
				nit
				countryId
				state
				address
			}
			contact {
				emails
				phones {
					type
					areaCode
					number
				}
				countryId
				state
				address
			}
			extra {
				civilStatus
				nationality
				bloodType
			}
		}
	}
`

export const updateEmployeeMutation = gql`
	mutation updateEmployee($input: UpdateEmployee!) {
		updateEmployee(input: $input) {
			id
			name
			type
			status
			startDate
			endDate
			salary
			bill {
				dni
				nit
				countryId
				state
				address
			}
			contact {
				emails
				phones {
					type
					areaCode
					number
				}
				countryId
				state
				address
			}
			extra {
				civilStatus
				nationality
				bloodType
			}
		}
	}
`

export const deleteEmployeeMutation = gql`
	mutation deleteEmployee($input: ID!) {
		deleteEmployee(id: $input) {
			id
			name
			type
			status
			startDate
			endDate
			salary
			bill {
				dni
				nit
				countryId
				state
				address
			}
			contact {
				emails
				phones {
					type
					areaCode
					number
				}
				countryId
				state
				address
			}
			extra {
				civilStatus
				nationality
				bloodType
			}
		}
	}
`
