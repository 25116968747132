import {
  IonButton,
  IonIcon,
  IonNote,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  addCircleOutline,
  downloadOutline,
  filterOutline,
  receiptOutline,
  trashOutline,
} from "ionicons/icons";
import Placeholder from "../../../../components/placeholder/Placeholder";
import { parseDate } from "../../../../helpers/date";
import { formatMoney, isEmpty } from "../../../../helpers/utils";
import GridTable from "@nadavshaar/react-grid-table";
import { texts } from "../../../../theme/dataTabelTexts";
import { useEffect, useState } from "react";
import CreditNoteModal from "../../../../components/modals/credit-note-modal/CreditNoteModal";
import { AuthStore } from "../../../../store/auth";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { downloadNote } from "../../../../graphql/creditNote/queries";
import { useApolloClient } from "@apollo/client";
import OptionItem from "../../../../components/option-item/OptionItem";
import ButtonPopover from "../../../../components/button-popover/ButtonPopover";
import { deleteCreditNoteMutation } from "../../../../graphql/creditNote/mutations";
import { statusName } from "../../../../helpers/catalogs";
import { updatePage } from "../../../../store/page";
import { useHistory } from "react-router";

const CreditNotesTab = ({
  enterprise,
  invoice = null,
  setInvoice = () => {},
  loading,
}) => {
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const [load, setLoad] = useState(loading);
  const client = useApolloClient();
  const user = AuthStore.useState((s) => s.user);

  const [creditNotes, setCreditNotes] = useState(
    invoice?.notes?.length ? invoice?.notes : []
  );
  const [showNoteCreditModal, setShowNoteCreditModal] = useState(false);
  const tableProps = {
    isPaginated: false,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
  };
  const [creditNote, setCreditNote] = useState(null);
  const [present] = useIonToast();

  const ItemsPopover = ({ onHide, data }) => (
    <>
      {invoice?.isFEL &&
        !["READ"].includes(user?.role?.permissions["sales"]) && (
          <OptionItem
            onHide={onHide}
            event={() => downloadCreditNote(data)}
            icon={downloadOutline}
            caption="Descargar nota de crédito"
          />
        )}
      {invoice?.status !== "CANCELLED" &&
        !["READ"].includes(user?.role?.permissions["sales"]) && (
          <OptionItem
            onHide={onHide}
            event={() => handleDelete(data)}
            icon={trashOutline}
            caption="Anular"
            className="danger"
          />
        )}
    </>
  );

  const Options = ({ data }) => (
    <ButtonPopover options={ItemsPopover} data={data} />
  );

  const handleDelete = async (input) => {
    presentAlert({
      cssClass: "sfn-alert-dialog",
      mode: "md",
      header: "Anular nota de crédito",
      subHeader: "Esta nota de crédito se anulará permanentemente",
      message: "¿Esta seguro de anular a esta nota de crédito?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Anular nota de crédito",
          cssClass: "delete",
          handler: async () => {
            try {
              setLoad(true);
              const { data, errors } = await client.mutate({
                mutation: deleteCreditNoteMutation,
                variables: { input: input?.id },
              });
              if (data && !errors) {
                present({
                  message: `La nota de crédito se anulo exitosamente! 🎉`,
                  color: "success",
                  mode: "md",
                  duration: 4000,
                });
                let notes = creditNotes;
                const index = notes.findIndex((n) => n.id === input?.id);
                if (index > -1) {
                  notes[index].status = "CANCELLED";
                  setCreditNotes(notes);
                  setInvoice({
                    ...invoice,
                    notes,
                  });
                }
              }
              if (errors) {
                const { extensions } = errors[0];
                let message = "";
                if (extensions?.code === "DTES_NOT_AVAILABLE") {
                  return presentAlert({
                    cssClass: "sfn-alert-dialog",
                    mode: "md",
                    header: `No tienes DTE's disponibles`,
                    subHeader: `Para anular una nota de crédito en SAT necesitas tener DTE's (Documento tributario eléctronico) disponibles.`,
                    buttons: [
                      {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                      },
                      {
                        text: "Comprar paquete",
                        cssClass: "primary",
                        handler: async () => {
                          updatePage({ tab: "plan" });
                          history.push("/settings?tab=plan");
                        },
                      },
                    ],
                    onDidDismiss: (e) => console.log("did dismiss"),
                  });
                }
                if (extensions?.code === "BAD_REQUEST") {
                  message = `El servidor no pudo interpretar la solicitud`;
                }
                if (extensions?.code === "NOT_FOUND") {
                  message = `No se encontro la nota de crédito a eliminar`;
                }
                if (extensions?.code === "FAILED_CREDIT_NOTE") {
                  if (extensions?.errors?.length) {
                    message = `La nota de crédito no se ha podido ser emitida hacia la SAT. Por favor verifica lo siguiente: \n ${extensions?.errors?.map(
                      (e) => `\n• ${e}`
                    )}`;
                  } else {
                    message = `La nota de crédito no se ha podido ser emitida hacia la SAT.`;
                  }
                }
                presentAlert({
                  cssClass: "sfn-alert-dialog",
                  mode: "md",
                  header: `No se ha podido anular la nota de crédito`,
                  subHeader: message,
                  buttons: [
                    {
                      text: "Cerrar",
                      role: "cancel",
                      cssClass: "secondary",
                    },
                  ],
                  onDidDismiss: (e) => console.log("did dismiss"),
                });
              }
              setLoad(false);
            } catch (error) {
              setLoad(false);

              console.log(error);
            } finally {
              setLoad(false);
            }
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
  };

  const downloadCreditNote = async (input) => {
    try {
      const { data, errors } = await client.query({
        query: downloadNote,
        variables: { input: input?.id },
      });
      if (!errors) {
        fetch(data?.downloadNote)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = input?.id;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => null);
      }
      if (errors) {
        const { extensions } = errors[0];
        let message = "";
        if (extensions?.code === "DTES_NOT_AVAILABLE") {
          return presentAlert({
            cssClass: "sfn-alert-dialog",
            mode: "md",
            header: `No tienes DTE's disponibles`,
            subHeader: `Para anular una nota de crédito en SAT necesitas tener DTE's (Documento tributario eléctronico) disponibles.`,
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
                cssClass: "secondary",
              },
              {
                text: "Comprar paquete",
                cssClass: "primary",
                handler: async () => {
                  updatePage({ tab: "plan" });
                  history.push("/settings?tab=plan");
                },
              },
            ],
            onDidDismiss: (e) => console.log("did dismiss"),
          });
        }
        if (extensions?.code === "BAD_REQUEST") {
          message = `El servidor no pudo interpretar la solicitud`;
        }
        if (extensions?.code === "NOT_FOUND") {
          message = `No se encontro la nota de crédito`;
        }
        presentAlert({
          cssClass: "sfn-alert-dialog",
          mode: "md",
          header: `No se ha podido descargar la nota de crédito`,
          subHeader: message,
          buttons: [
            {
              text: "Cerrar",
              role: "cancel",
              cssClass: "secondary",
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        });
      }
    } catch (error) {
      throw error;
    }
  };
  const columns = [
    {
      id: 1,
      label: "Estado",
      field: "status",
      cellRenderer: ({ value }) => (
        <IonNote
          className={`w-full text-center ${
            value === "ACTIVE" ? "ACCEPTED" : value
          } with-bg`}
        >
          {isEmpty(statusName[value])}
        </IonNote>
      ),
    },
    {
      id: 2,
      label: "Fecha de creación",
      field: "date",
      getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, "-")),
    },
    {
      id: 3,
      label: "Productos",
      field: "products",
    },
    {
      id: 4,
      label: "Total",
      field: "amount",
      width: "max-content",
      headerCellRenderer: () => <div className="ml-auto mr-2">Total</div>,
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{formatMoney(enterprise, data.amount, true)}</p>
          </div>
        );
      },
    },
    {
      id: 6,
      field: "voucher",
      label: "",
      cellRenderer: ({ data }) => (
        <div className="text-center w-full items-center justify-center">
          {invoice?.isFEL && (
            <p className="text-primary">
              Emitida en SAT
              <Tooltip
                className="ml-1 mt-1 text-sm"
                label="Esta nota de crédito ya está emitida y registrada en la SAT"
              />
            </p>
          )}
          {!invoice?.isFEL && (
            <p>
              Sin emitir en SAT
              <Tooltip
                className="ml-1 mt-1 text-sm"
                label="Esta nota de crédito no ha sido emitida en la SAT"
              />
            </p>
          )}
        </div>
      ),
    },
    {
      id: 7,
      label: "",
      cellRenderer: ({ data }) =>
        !invoice?.isFEL && data?.status === "CANCELLED" ? null : (
          <Options data={data} />
        ),
      pinned: true,
      width: "max-content",
    },
  ];

  return (
    <>
      <div className="w-full flex justify-end">
        <IonButton
          className="sfn-button"
          shape="round"
          disabled={
            invoice?.status === "CANCELLED" ||
            (["READ"].includes(user?.role?.permissions["expenses"]) &&
              ["READ"].includes(user?.role?.permissions["sales"]))
          }
          onClick={() => {
            setCreditNote(null);
            setShowNoteCreditModal(true);
          }}
        >
          <IonIcon slot="start" icon={addCircleOutline} />
          Agregar nota de crédito
        </IonButton>
      </div>
      <GridTable
        {...tableProps}
        className={
          creditNotes?.length
            ? "sfn-data-table"
            : "justify-start items-start max-h-80"
        }
        components={{
          NoResults: () => (
            <Placeholder
              icon={receiptOutline}
              description="No tienes notas de crédito en esta factura."
            />
          ),
        }}
        isLoading={load}
        icons={{
          columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" />,
        }}
        columns={columns}
        rows={creditNotes?.length ? creditNotes : []}
        onRowClick={({ data }) => {
          setCreditNote(data);
          setShowNoteCreditModal(true);
        }}
        texts={texts}
      />

      {showNoteCreditModal && (
        <CreditNoteModal
          enterprise={enterprise}
          invoice={invoice}
          creditNote={creditNote}
          showModal={showNoteCreditModal}
          setShowModal={setShowNoteCreditModal}
          creditNotes={creditNotes}
          setCreditNotes={setCreditNotes}
        />
      )}
    </>
  );
};

export default CreditNotesTab;
