import {string, email, number} from '../../../helpers/validations';

export const validationMap = {
    merchant: string,
    // description: string,
    subtotal: number,
    total: number,
    date: string,
    accountId: val => string(val.value) || string(val),
    categoryId: val => string(val.value) || string(val),
    currencyId: val => string(val.value.id) || string(val),
    method: val => string(val.value) || string(val),
}
