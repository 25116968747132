import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react"
import { calendarClearOutline, information, refresh } from "ionicons/icons"
import Header from "../../../../components/header/Header"
import Placeholder from "../../../../components/placeholder/Placeholder"
import GridTable from '@nadavshaar/react-grid-table';
import './AgedPayablesPage.scss';
import InputDate from "../../../../components/input-date/InputDate";
import { agedPayables } from "../../../../graphql/report/queries";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../../context/auth/AuthState";
import Select from 'react-select';
import { useForm } from "../../../../hooks/useForm";
import { formatSelectData, generateYears, generateYearsReports } from "../../../../helpers/utils";
import { sfnSelectTheme } from "../../../../theme/sfnSelectTheme";
import InputDatePayroll from "../../../../components/input-date-payroll/InputDatePayroll";
import { useState } from "react";
import { ListNoOptionsMessage } from "../../../../components/select-menu-components/SelectMenuComponents";
import { getAllVendorsQuery } from "../../../../graphql/vendor/queries";
import { getDate, parseDate } from "../../../../helpers/date";
import BreadCrumbs from "../../../../components/breadcrumbs/BreadCrumbs";


const initialState = {
  year: { value: new Date().getFullYear(), label: new Date().getFullYear() },
  from: getDate(new Date(new Date().getFullYear(), 0, 1), false),
  to: getDate(new Date(new Date().getFullYear(), 11, 31), false),
  vendorId: '',
  min: `${new Date().getFullYear()}-01-01`,
  max: `${new Date().getFullYear()}-12-31`,
}

const AgedPayablesPage = () => {
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const { year, from, to, vendorId, min, max } = formValues;
  const [filter, setFilter] = useState({ vendorId, from, to })
  const { data, loading, error, refetch } = useQuery(agedPayables, { variables: { ...filter }, fetchPolicy: 'cache-and-network' });
  const { data: dataVendors, loading: loadingVendor, error: errorVendor } = useQuery(getAllVendorsQuery);

  const { authState } = useAuth();
  const { enterprise } = authState;

  const columns = [
    {
      id: 1,
      field: 'name',
      label: ' ',
      width: '155px'
    },
    {
      id: 2,
      field: 'noYetOverdue',
      label: 'Aún no vencido',
      headerCellRenderer: () => (<div className="m-auto">Aún no vencido</div>),
      width: '150px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data.noYetOverdue} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
    {
      id: 3,
      field: '_30OrLess',
      label: '30 o menos',
      headerCellRenderer: () => (<div className="aged-payable-cell-color">30 o menos</div>),
      width: '150px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data._30OrLess} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
    {
      id: 4,
      field: '_31To60',
      label: '31 a 60',
      headerCellRenderer: () => (<div className="aged-payable-cell-color">31 a 60</div>),
      width: '150px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data._31To60} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
    {
      id: 5,
      field: '_61To90',
      label: '61 a 90',
      headerCellRenderer: () => (<div className="aged-payable-cell-color">61 a 90</div>),
      width: '150px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data._61To90} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
    {
      id: 6,
      field: '_91OrMore',
      label: '91 a más',
      headerCellRenderer: () => (<div className="aged-payable-cell-color">91 a más</div>),
      width: '150px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data._91OrMore} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
    {
      id: 7,
      field: 'total',
      label: 'Total no pagado',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Total no pagado</div>),
      width: '155px',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{enterprise?.currency?.symbol}{data.total} {enterprise?.currency?.code}</p>
          </div>)
      }
    },
  ]
  const tableProps = {
    showSearch: false,
    showRowsInformation: false,
    isPaginated: false,
    showColumnVisibilityManager: false
  }

  const handleInputChangeSelect = (e, key) => {
    console.log(e, key)
    if (!key) {
      let { value, name } = e.target;
    } else {
      if (key == 'year') {
        if (e !== null) {
          handleInputChange(`${e?.value}-01-01`, 'min')
          handleInputChange(`${e?.value}-12-31`, 'max')
          handleInputChange(`${e?.value}-01-01`, 'from')
          handleInputChange(`${e?.value}-12-31`, 'to')
        } else {
          handleInputChange('', 'from')
          handleInputChange('', 'to')
        }
      }
      handleInputChange(e, key)
    }
  }

  const handleFilters = () => {
    setFilter({ from: from !== '' ? from : undefined, to: to !== '' ? to: undefined, vendorId: vendorId?.value || undefined })
  }

  const breads = [{
    title: 'Reportes',
    url: '/reports',
  }, {
    title: 'Cuentas por pagar',
    url: '/reports/aged-payables'
  }];


  return (
    <IonPage id="aged-payables-page">
      <Header title="Cuentas por pagar"></Header>

      <IonContent className="sfn-content">
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />

        <section className="flex w-full flex-col sm:flex-row md:justify-between mb-12 md:items-center  items-end" >
          <div className="md:w-80 w-full">
            <p className="text-sm mb-2 hover:text-secondary w-80">Año</p>
            <div className="flex items-center dropdown-month">
              <IonIcon slot="start" icon={calendarClearOutline} className="pr-2 text-2xl" />
              <Select isClearable={true} placeholder="Seleccione el año" value={year} options={generateYearsReports(enterprise?.createdAt)} onChange={e => (handleInputChangeSelect(e, 'year'))} className="sfn-select md:w-60 w-full z-20" styles={sfnSelectTheme} />
            </div>
          </div>
          <InputDate min={min} max={max} label="Desde" value={from} onChange={handleInputChange} name="from" icon={false} />
          <InputDate min={min} max={max} className="md:mb-0 mb-2" label="Hasta" value={to} onChange={handleInputChange} name="to" icon={false} />
          <div className="md:w-80 w-full">
            <p className="text-sm md:mb-2 hover:text-secondary w-full">Proveedor</p>
            <div className="flex items-center">
              <Select isClearable={true} placeholder="Seleccione el proveedor" value={vendorId} options={formatSelectData({ value: 'id', label: 'name' }, dataVendors?.vendors?.rows)} onChange={e => (handleInputChangeSelect(e, 'vendorId'))} className="sfn-select md:w-60 w-full  z-20" styles={sfnSelectTheme}
                components={
                  {
                    // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo proveedor", callback:setShowVendorModal}),
                    NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay proveedores" })
                  }
                } />
            </div>
          </div>
          <IonButton className="sfn-button sm:mt-0 mt-12 sm:w-auto w-full" shape="round" onClick={handleFilters}>
            <IonIcon slot="start" icon={refresh} />
            Actualizar reporte
          </IonButton>
        </section>
        <section className="aged-payable-table-title mt-20">
          <h3 className="font-bold text-gray-blue text-base sm:text-2xl mb-10">Período de {parseDate(filter.from).replace(/\//g, '-')} a {parseDate(filter.to).replace(/\//g, '-')} </h3>
          <p>Número de días vencidos</p>
        </section>
        <GridTable className="sfn-data-table no-cell-padding pt-1"
          columns={columns}
          rows={data?.agedPayables}
          isLoading={false}
          components={{
            NoResults: () => (
              <Placeholder icon={information} description='No tienes información de cuentas por pagar
              en el período de tiempo seleccionado.' />
            )
          }}
          {...tableProps}
        />
      </IonContent>
    </IonPage>
  )
}

export default AgedPayablesPage
