import GridTable from '@nadavshaar/react-grid-table';
import Kpi from '../../../components/kpi/Kpi';
import { IonIcon, useIonViewDidEnter } from '@ionic/react';
import { texts } from '../../../theme/dataTabelTexts';
import Placeholder from '../../../components/placeholder/Placeholder';
import { flagOutline, filterOutline, peopleOutline, cashOutline } from 'ionicons/icons';
import { getNetIncomeLastFourYearsQuery } from '../../../graphql/dashboard/queries';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../helpers/utils';


const NetIncome = ({ options, enterprise }) => {

    const [table, setTable] = useState({ columns: [], rows: null })
    const { data: expenses, loading, error, refetch } = useQuery(getNetIncomeLastFourYearsQuery, {
        fetchPolicy: 'no-cache',
        async onCompleted(data) {
            let { netIncomeLastFourYears } = data;
            const columns = [{
                id: 0,
                field: 'title',
                label: 'Año fiscal',
            }, ...netIncomeLastFourYears.map((income, index) => ({
                id: index + 1,
                field: income.year,
                label: income.year,
            }))];
            const rows = [{
                title: 'Ingresos',
                ...netIncomeLastFourYears.reduce(((acc, data) => {
                    return {...acc, [data.year]: formatMoney(enterprise, data.income)}
                }), {})
            },{
                title: 'Gastos',
                ...netIncomeLastFourYears.reduce(((acc, data) => {
                    return {...acc, [data.year]: formatMoney(enterprise, data.expenses)}
                }), {})
            },{
                title: 'Ingresos netos',
                ...netIncomeLastFourYears.reduce(((acc, data) => {
                    return {...acc, [data.year]: formatMoney(enterprise, data.netIncome)}
                }), {})
            }]
            setTable({ ...table, columns, rows});
        }
    });

    const tableProps = (show) => ({
        showSearch: show,
        showRowsInformation: show,
        isPaginated: show,
        showColumnVisibilityManager: show
    })

    useIonViewDidEnter(() => {
        refetch();
    });


    return (
        <div className="flex flex-col my-12">
            <div className="flex flex-col">
                <h3 className="text-2xl font-bold text-gray-blue" >Ingresos netos</h3>
                <p className="text-base mt-4">Comparativa anual de los ingresos de tu empresa. Menos el costo de los bienes vendidos, gastos e impuestos.</p>
            </div>
            <div className="flex flex-col h-80 mt-8">
                {table?.columns.length ? (<GridTable
                    className="sfn-data-table row-cursor-pointer"
                    columns={table?.columns}
                    rows={table?.rows}
                    texts={texts}
                    icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                    isLoading={loading}
                    components={{
                        NoResults: () => (
                            <Placeholder icon={cashOutline} description='Aún no tienes ingresos netos' />
                        )
                    }}
                    {...tableProps(false)}
                />) : ''}
            </div>
        </div>
    );
}

export default NetIncome;