import gql from 'graphql-tag';

export const getAllPlansQuery = gql`
  query plans($options: Options, $page: Int) {
    plans(options: $options, page: $page) {
      id
      name
      users
      enterprises
    }
  }
`;

export const getAllBillingPlansQuery = gql`
  query plans($options: Options, $page: Int) {
    plans(options: $options, page: $page) {
      id
      name
      price
      currency
      symbol
    }
  }
`;

export const getOnePlanQuery = gql`
  query plan($input: ID!) {
    plan(id: $input) {
      id
      name
      users
      enterprises
      freeDays
    }
  }
`;
