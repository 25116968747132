import { IonContent, IonPage, IonButton, IonIcon, useIonAlert, IonNote, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import {
  addCircleOutline, createOutline, eyeOutline, filterOutline, peopleOutline, playOutline, trashOutline,
} from 'ionicons/icons';
import CustomerModal from '../../components/modals/customer-modal/CustomerModal';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import Header from '../../components/header/Header';
import OptionItem from '../../components/option-item/OptionItem';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import { getAllCustomersQuery } from '../../graphql/customer/queries';
import { useCustomersService } from '../../graphql/customer/service';
import Placeholder from '../../components/placeholder/Placeholder';
import { texts } from '../../theme/dataTabelTexts';
import { useAuth } from '../../context/auth/AuthState';
import SearchTable from '../../components/search-table/SearchTable';
import { formatMoney, isEmpty } from '../../helpers/utils';
import { AuthStore } from '../../store/auth';


const CustomersPage = () => {

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { data, loading, error } = useQuery(getAllCustomersQuery);
  const [present] = useIonAlert();
  const [customerId, setCustomerId] = useState(null)
  const { deleteCustomer, dLoading, dError } = useCustomersService();
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [presentToast] = useIonToast();
  const user = AuthStore.useState(s => s.user)

  function countItems(data = [], quantity = 1) {
    return Array.isArray(data) && data?.length === quantity
  }

  const ItemsPopover = ({ onHide, data }) => {
    return (
      <>
        <OptionItem onHide={onHide} event={() => goToCustomerPage(data.id)} icon={eyeOutline} caption="Ver detalle" />
        {!['READ'].includes(user?.role?.permissions['sales']) && <><OptionItem onHide={onHide} event={goToInvoicesPage} icon={playOutline} caption="Crea Factura" />
        <OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
        <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" /> </>}
      </>)
  }
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);

  const goToCustomerPage = (id) => {
    history.push(`/customers/${id}`);
  }

  const goToInvoicesPage = () => {
    history.push('/invoices')
  }

  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar cliente',
      subHeader: 'Este cliente se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este cliente?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar cliente',
          cssClass: 'delete',
          handler: async () => {
            let resp = await deleteCustomer({ variables: { input: id } });
            if (resp.errors) {
              showErrorAlert(resp.errors)
            } else {
              presentToast({ message: 'El cliente se ha eliminado correctamente 😀', color: "success", mode: "ios", duration: 4000 });
            }
          }
        }
      ],
    })
  }

  const showErrorAlert = (errors) => {
    const { extensions } = errors[0];
    if (extensions?.code === "LOCKED") {
      present({
        cssClass: 'sfn-alert-dialog',
        mode: 'md',
        header: 'Oops! ha ocurrido un problema',
        subHeader: `No podemos eliminar este cliente porque está asociado con lo siguiente:
         ${extensions?.invoices?.rows?.map(invoice => {
          return `\n• Factura ${invoice?.number}`
        })}
         `,
        message: `Mostrando ${extensions?.invoices?.rows?.length}/${extensions?.invoices?.length} Para eliminar este cliente, elimínelo de los elementos enumerados anteriormente`,
        buttons: [
          {
            text: 'Cerrar',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      })
    } else {
      presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
    }
  }

  const handleUpdate = (id) => {
    setCustomerId(id);
    setShowModal(true);
  }

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })


  const columns = [
    {
      id: 1,
      field: 'name',
      label: 'Nombre',
      getValue: ({ value }) => isEmpty(value)
    },
    {
      id: 2,
      field: 'bill',
      label: 'Identificador tributario',
      getValue: ({ value }) => isEmpty(value?.nit)
    },
    {
      id: 3,
      field: 'phones',
      label: 'Teléfono',
      getValue: ({ value }) => countItems(value, 1) ? `${'(' + value[0]?.areaCode + ')' + ' ' + value[0]?.number}` : ' - - -'
    },
    {
      id: 4,
      field: 'emails',
      label: 'Correo electrónico',
      getValue: ({ value }) => countItems(value, 1) ? value[0] : ' - - -'
    },
    {
      id: 5,
      field: 'outstandingAmount',
      label: 'Saldo',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{formatMoney(enterprise, data.outstandingAmount, true)}</p>
          </div>)
      }
    },
    {
      id: 6,
      field: 'overdueAmount',
      label: 'Atrasado',
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p color="danger">{formatMoney(enterprise, data.overdueAmount, true)}</p>
          </div>)
      }
    },
    {
      id: 'option',
      label: '',
      width: "max-content",
      pinned: true,
      cellRenderer: Options
    },

  ];


  return (
    <IonPage>
      <Header title="Clientes" subtitle={`Cantidad de clientes: ${(data?.customers?.rows.length) || 0}`}></Header>
      <IonContent className="sfn-content">
        <section className="flex flex-col sm:flex-row justify-end mb-4" >
          <IonButton disabled={['READ'].includes(user?.role?.permissions['sales'])} className="sfn-button" shape="round" onClick={() => {
            setCustomerId(null);
            setShowModal(true)
          }}>
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar cliente
          </IonButton>
        </section>

        <GridTable
          className="sfn-data-table row-cursor-pointer"
          columns={columns}
          rows={data?.customers?.rows}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          isLoading={loading}
          components={{
            NoResults: () => (
              <Placeholder icon={peopleOutline} description='Aún no tienes clientes agregados haz clic en el botón "Agregar cliente"' />
            ),
            Search: SearchTable
          }}
          {...tableProps(data?.customers?.rows.length ? true : false)}
          onRowClick={({ data, event }) => {
            event.stopPropagation();
            goToCustomerPage(data.id);
          }}
        />
        {showModal && <CustomerModal showModal={showModal} setShowModal={setShowModal} customerId={customerId} setCustomerId={setCustomerId}></CustomerModal>}
      </IonContent>
    </IonPage>
  );
};

export default CustomersPage;
