import { useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { helpCircleOutline, chatbubbleOutline, videocamOutline, chatboxEllipsesOutline } from "ionicons/icons";
import useOutsideClick from "../../hooks/useOutsideClick";
import OptionItem from "../option-item/OptionItem";
import './HelpHeader.scss';
import IndexModal from '../../components/modals/index-modal/IndexModal'

const HelpHeader = () => {
  const [open, setOpen] = useState(false);
  const [showIndex, setShowIndex] = useState(false)

  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false)
  });



  const feedbackLink = () => {
    window.open(process.env.REACT_APP_SUPPORT_URL,"_blank")
  }

  const helpLink = () => {
    window.open(process.env.REACT_APP_HELP_URL,"_blank")
  }

  const chatLink = () => {
    window.open((process.env.REACT_APP_SUPPORT_CHAT_LINK|| 'https://wa.me/50247444081'),"_blank")
  }

  const toggleIndex = () => {
    setShowIndex(true);
  }

  


  return (
    <>
    <div ref={ref} id="help-header">
      <div className="content flex flex-row justify-start items-center rounded-full cursor-pointer gap-4">
          <IonIcon className="w-[24px] h-[24px]" src="assets/icon/help.svg"  onClick={chatLink}/>
          <IonIcon icon={helpCircleOutline} className="" onClick={() => setOpen(!open)}/>
      </div>
      <div className={`dropdown rounded absolute shadow-md mt-2 p-3 ${!open && 'hidden'}`}>
        {/* <OptionItem icon={videocamOutline} event={toggleIndex} caption="¿Cómo utilizar Symfonia?"></OptionItem> */}
        <OptionItem icon={helpCircleOutline} event={helpLink} caption="Centro de ayuda"></OptionItem>
        <OptionItem icon={chatbubbleOutline} event={feedbackLink} caption="Envíanos tus comentarios"></OptionItem>
        {/* <OptionItem icon={chatboxEllipsesOutline} event={chatLink} caption="Soporte Symfonia"></OptionItem> */}
      </div>
    </div>
    {showIndex && <IndexModal employeeId={'sdfgdsg'} setEmployeeId={'sdfgd'} showModal={showIndex} setShowModal={setShowIndex} />}

    </>
  )
}

export default HelpHeader;