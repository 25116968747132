// prettier-ignore

import {useMutation} from '@apollo/client'
import {
  createLegalBenefitMutation,
  deleteLegalBenefitMutation,
  updateLegalBenefitMutation,
} from "./mutations";
import { getAllLegalBenefitQuery } from "./queries";

export const useLegalBenefitService = () => {
  const [createLegalBenefit, { loading: cLoading, error: cError }] =
    useMutation(createLegalBenefitMutation, {
      errorPolicy: "all",
      update(cache, { data: { createLegalBenefit } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllLegalBenefitQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllLegalBenefitQuery,
          data: {
            legalBenefits: {
              rows: [createLegalBenefit, ...data.legalBenefits.rows],
            },
          },
        });
      },
    });

  const [updateLegalBenefit, { loading: uLoading, error: uError }] =
    useMutation(updateLegalBenefitMutation, {
      errorPolicy: "all",
      update(cache, { data: { updateLegalBenefit } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllLegalBenefitQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllLegalBenefitQuery,
          data: {
            legalBenefits: {
              rows: data.legalBenefits.rows.map((employee) =>
                employee.id === updateLegalBenefit.id
                  ? updateLegalBenefit
                  : employee
              ),
            },
          },
        });
      },
    });

  const [deleteLegalBenefit, { loading: dLoading, error: dError }] =
    useMutation(deleteLegalBenefitMutation, {
      errorPolicy: "all",
      update(cache, { data: { deleteLegalBenefit } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllLegalBenefitQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllLegalBenefitQuery,
          data: {
            legalBenefits: {
              rows: data.legalBenefits.rows.filter(
                (employee) => employee.id !== deleteLegalBenefit.id
              ),
            },
          },
        });
      },
    });

  return {
    createLegalBenefit,
    cLoading,
    cError,

    updateLegalBenefit,
    uLoading,
    uError,

    deleteLegalBenefit,
    dLoading,
    dError,
  };
};
