import { IonIcon } from "@ionic/react";
import { receiptOutline } from "ionicons/icons";
import React, { useState } from "react";
import { formatMoney } from "../../../../helpers/utils";
import './TooltipCreditNote.scss'

const TooltipCreditNote = ({ enterprise, invoice, className }) => {
  const [styleTooltip, setStyleTooltip] = useState({ top: 0, left: 0, display: 'none', position: 'absolute' })
  function getOffset(el) {
    const rect = el?.target?.getBoundingClientRect();
    let top = rect.top - el.target.offsetTop == 0 ? el.target.offsetTop : rect.top - el.target.offsetTop
    return {
      left: rect.left + window.scrollX,
      top: top
    };
  }
  const hoverTooltip = el => {
    setStyleTooltip({
      top: getOffset(el).top - el.target.offsetHeight - 10,
      left: getOffset(el).left,
      display: 'inline-block'
    })
  }

  const onMouseOut = () => {
    setStyleTooltip({
      display: 'none'
    })
  }


  return (
    <div className="tooltip-credit-note">
      <IonIcon onMouseOver={hoverTooltip} className="ml-3 relative mb-2 bg-warning items-center justify-center flex text-sm text-white p-2 w-3 h-3 rounded-full" icon={receiptOutline} />
      <div className={`p-6 tooltip-credit-note-content flex flex-col justify-start bg-blue text-white`}>
          <p className="font-bold">Total factura</p>
          <p>{formatMoney(enterprise, invoice?.total, true)}</p>
          <p  className="font-bold mt-2">Total notas de crédito</p>
          <p>{formatMoney(enterprise, invoice?.creditNotesAmount, true)}</p>
          <p  className="font-bold mt-2">Total</p>
          <p>{formatMoney(enterprise, invoice?.residue, true)}</p>
        </div>
    </div>
  );
};

export default TooltipCreditNote;
