import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, useIonToast, IonTextarea, IonContent, IonCheckbox } from '@ionic/react';
import { closeCircleOutline, filterOutline, fileTrayOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import { useTaxesService } from '../../../graphql/tax/service'
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { getOneTaxQuery } from '../../../graphql/tax/queries';
import { texts } from "../../../theme/dataTabelTexts";
import GridTable from '@nadavshaar/react-grid-table';
import Placeholder from '../../../components/placeholder/Placeholder';
import InputDate from '../../../components/input-date/InputDate';
import { formatDate, getDate, dateTimeFormat, parseDate} from '../../../helpers/date';
import SfnButton from '../../../components/button/Button';
import { Tooltip } from '../../tooltip/Tooltip';
const initialState = {
    name: '',
    abbreviation: '',
    rate: '',
    isRecoverable: false,
    description: '',
    rates: [],
    effectiveDate: getDate(new Date()),
}

const AccountModal = ({ showModal, setShowModal, taxId = null, setTaxId = () => { }, eventsClose = ()=> {}, position= 0, afterCreate = () => {}}) => {
    const { createTax, updateTax, cError, cLoading, uError, uLoading } = useTaxesService();
    const {formValues, handleInputChange, reset, resetProps, numberHandleInputChange} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { name, abbreviation, rate, description, isRecoverable, rates, effectiveDate } = formValues;
    const [present] = useIonToast();

    let { data, loading, error } = useQuery(getOneTaxQuery, {
        variables: { input: taxId },
        skip: !taxId,
        fetchPolicy: 'no-cache',
        async onCompleted(data) {
            let { tax } = data;
            tax.effectiveDate = getDate(new Date(), false);
            resetProps({ ...tax });
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        let input = { ...formValues }
        input.rate = parseFloat(input.rate || 0);
        delete input.rates;
        if (taxId) {
            delete input.isRecoverable;
            await updateTax({ variables: { input } })
        } else {
            delete input.id;
            delete input.effectiveDate;
            let {data, errors} = await createTax({ variables: { input } })
            if(data && !errors) {
                afterCreate(position, {...data?.createTax})
            }
        }
        if (!uLoading && !cLoading) {
            if (cError || uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            }
            present({ message: `El impuesto se ${taxId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            resetProps(JSON.parse(JSON.stringify(initialState)));
            onClose();
        }
    }

    const columns = [{
        id: 1,
        label: 'Impuesto',
        field: 'rate',
        width: '50%',
        getValue: ({ value }) => `${value} %`

    }, {
        id: 2,
        width: '50%',
        label: 'Fecha de vigencia',
        field: 'effectiveDate',
        cellRenderer: ({ data }) => (<p className="mx-5">{data.effectiveDate ? parseDate(data?.effectiveDate).replace(/\//g, '-') : 'N/A'}</p>)

    }]

    const config = {
        isPaginated: false,
        showSearch: false,
        highlightSearch: false,
        showRowsInformation: false
    }

    const onClose = async () => {
        await eventsClose();
        setShowModal(false);
        setTaxId(null);
        resetProps(JSON.parse(JSON.stringify(initialState)));
    }

    const onBlurAbbreviation = () => {
        let names = name.length ? name.split(' ') : '';
        let abbreviation = names.length ? names.reduce((a, v) => a += String(v[0]).toUpperCase(), '') : ''
        handleInputChange(abbreviation, 'abbreviation');
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h2 className="text-center text-xl font-bold">{taxId ? 'Editar' : 'Agregar'} impuesto de ventas</h2>
                    <p className="text-center text-base">{taxId ? 'Edita el' : 'Agrega un nuevo'}  impuesto de ventas a tu empresa</p>

                    <form id="tax-form" onSubmit={handleSubmit} className="h-full p-2">
                        <IonItem className="sfn-input mb-4" mode="md" lines="none">
                            <IonLabel position="stacked">Nombre impuesto<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="name" type="name" placeholder="Indica el nombre del impuesto" onIonChange={handleInputChange} onBlur={onBlurAbbreviation} value={name} required />
                        </IonItem>

                        <IonItem className="sfn-input mb-4" mode="md" lines="none">
                            <IonLabel position="stacked">Abreviación<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="abbreviation" type="abbreviation" placeholder="Indica la abreviación" onIonChange={handleInputChange} value={abbreviation} required />
                        </IonItem>

                        {!taxId && (
                            <IonItem className="sfn-input mb-4" mode="md" lines="none">
                                <IonLabel position="stacked">Porcentaje (%)<span className="text-base text-danger">*</span></IonLabel>
                                <IonInput name="rate" type="text" placeholder="Indica el número sin signo de porcentaje"
                                onKeyPress={numberHandleInputChange} 
                                onChange={numberHandleInputChange} 
                                onKeyUp={numberHandleInputChange} 
                                onKeyDown={numberHandleInputChange}
                                 value={rate} required />
                            </IonItem>
                        )}
                        <div className={`my-4 ${taxId ? 'cursor-not-allowed' : ''}`}>
                            <div className="flex items-center">
                                <IonCheckbox className="sfn-checkbox mr-2" disabled={taxId ? true : false} checked={isRecoverable} onIonChange={e => handleInputChange(e.detail.checked, 'isRecoverable')} />
                                <IonLabel className={`${taxId ? 'text-gray' : ''} mr-1`}>Impuesto recuperable</IonLabel>
                                <Tooltip label="Crédito fiscal que el fisco devuelve al contribuyente después de que es auditado y autorizado."/>
                            </div>
                            {/* <p className="text-base text-gray-light">Un impuesto es recuperable si puede deducir el impuesto que ha pagado del impuesto que ha recaudado.</p> */}
                        </div>
                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">Nota (Opcional)</IonLabel>
                            <IonTextarea name="description" placeholder="Descripción del impuesto" onIonChange={handleInputChange} value={description}> </IonTextarea>
                        </IonItem>
                        {taxId && (
                            <div className="flex flex-col my-4">
                                <div className="border-b-2 border-gray-white my-4"></div>
                                <h3 className="text-left text-xl font-bold">Historial de impuesto</h3>
                                <p className="text-base">Si el valor del impuesto ha cambiado en tu país, puedes modificarlo este cobrará vigencia en la fecha que indiques.</p>
                                <div className="flex justify-start items-center">
                                    <div className="flex items-center justify-center self-end">
                                        <p className="mr-4">Porcentaje (%)</p>
                                        <IonItem className="sfn-input " mode="md" lines="none">
                                            <IonInput name="rate" type="text" placeholder="" 
                                            onKeyPress={numberHandleInputChange} 
                                            onChange={numberHandleInputChange} 
                                            onKeyUp={numberHandleInputChange} 
                                            onKeyDown={numberHandleInputChange}
                                            value={rate} />
                                        </IonItem>
                                    </div>
                                    <div className="flex items-center justify-center self-end md:ml-24 ml-4">
                                        <p className="mr-4 mt-4">Fecha vigencia</p>
                                        <InputDate className="sm:w-60" label="" value={effectiveDate} handleInputChange={handleInputChange} name="effectiveDate" icon={false} required={false}></InputDate>
                                    </div>
                                </div>
                                <GridTable {...config} className="sfn-data-table-slim sfn-data-table" columns={columns} rows={rates}
                                    texts={texts}
                                    components={{
                                        NoResults: () => (
                                            <Placeholder icon={fileTrayOutline} description='No tienes historial de impuestos' />
                                        )
                                    }}
                                    isLoading={loading}
                                    icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                                />
                            </div>
                        )}

                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                                Cancelar
                            </IonButton>
                            <SfnButton form="tax-form" label={taxId ? 'Guardar cambios' : 'Guardar impuesto'} loading={cLoading || uLoading} />
                        </div>
                    </form>
                </Wrapper>
            </IonContent>
        </IonModal>

    )

}

export default AccountModal;