import { IonModal, IonImg } from '@ionic/react';
import Wrapper from '../../wrapper/Wrapper';
import SfnButton from '../../button/Button';
const AcceptInvitationModal = ({ showModal, setShowModal, user = null, enterprise = null, changeEnterprise, setAuth }) => {


    const onClose = async () => {
        let { accepted, pendings } = user.enterprises

        if (accepted == 0) {
            const { data, errors: enterpriseError } = await changeEnterprise({ variables: { input: enterprise?.id } })
            if (!enterpriseError && data) {
                const { changeEnterprise } = data
                const { accepted, pendings } = changeEnterprise.enterprises
                setAuth({ token: changeEnterprise.token });
                window.location.reload()
            }
        }
        setShowModal(false);
    }


    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={() => setShowModal(false)}>
            <Wrapper>

                <div className="flex items-center justify-center flex-col h-screen">
                    <img className="w-56 my-8" src="../assets/icon/logo.svg" alt="logo" />
                    <h3 className="font-bold text-2xl mt-8 mb-4">¡Hola, {user?.name}!</h3>
                    <p className="text-primary text-lg">Has aceptado la invitación para colaborar en {enterprise?.name}</p>
                    <div className="flex flex-col md:flex-row justify-center items-center mt-16">
                        <SfnButton onClick={onClose} label="¡Entendido!" />
                    </div>
                </div>
            </Wrapper>
        </IonModal >

    )

}

export default AcceptInvitationModal;