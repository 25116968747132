// prettier-ignore

import {useMutation} from '@apollo/client'
import {
  cancelSubscriptionMutation,
  createSubscriptionMutation,
  updateBillingDataMutation,
  updateCreditCardMutation,
} from './mutation';
import { getSubscription } from './queries';

export const useSubscriptionService = () => {
  const [createSubscription, { loading: cLoading, error: cError }] =
    useMutation(createSubscriptionMutation, {
      errorPolicy: 'all',
      refetchQueries: [{ query: getSubscription }],
    });

  const [
    updateBillingData,
    { loading: uBillingLoading, error: uBillingError },
  ] = useMutation(updateBillingDataMutation, {
    errorPolicy: 'all',
    refetchQueries: [{ query: getSubscription }],
  });

  const [updateCreditCard, { loading: uCardLoading, error: uCardError }] =
    useMutation(updateCreditCardMutation, {
      errorPolicy: 'all',
      refetchQueries: [{ query: getSubscription }],
    });

  const [
    cancelSubscription,
    { loading: cSubscriptionLoading, error: cSubscriptionError },
  ] = useMutation(cancelSubscriptionMutation, {
    errorPolicy: 'all',
    refetchQueries: [{ query: getSubscription }],
  });

  return {
    createSubscription,
    cLoading,
    cError,
    updateBillingData,
    uBillingLoading,
    uBillingError,
    updateCreditCard,
    uCardLoading,
    uCardError,
    cancelSubscription,
    cSubscriptionLoading,
    cSubscriptionError,
  };
};
