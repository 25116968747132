import './Title.scss';



const Title = ({ title, description = "" }) => {
    return (
        <div className="flex flex-col">
            <h3 className="text-xl font-bold" >{title}</h3>
            <p className="text-base">{description}</p>
        </div>
    )
}

export default Title;