import { IonButton, IonContent, IonIcon, IonNote, IonPage } from '@ionic/react';
import { useState } from 'react';
import Header from '../../../../components/header/Header';
import InputDate from '../../../../components/input-date/InputDate';
import { getDate, getRange, parseDate } from '../../../../helpers/date';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Select from 'react-select';
import { ListNoOptionsMessage } from '../../../../components/select-menu-components/SelectMenuComponents';
import { useAuth } from '../../../../context/auth/AuthState';
import SearchTable from '../../../../components/search-table/SearchTable';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { filterOutline, receiptOutline } from 'ionicons/icons';
import { texts } from '../../../../theme/dataTabelTexts';
import Indicator from './components/indicator/Indicator';
import Toggle from '../../../../components/toggle/Toggle';
import './ProfitAndLossPage.scss';
import { formatMoney, generateYearsReports } from '../../../../helpers/utils';
import GridTable from './components/grid-table/GridTable';
import BreadCrumbs from '../../../../components/breadcrumbs/BreadCrumbs';
import { useForm } from '../../../../hooks/useForm';
import { useQuery } from '@apollo/client';
import { profitAndLoss } from '../../../../graphql/report/queries';

const initialState = {
  year: { value: new Date().getFullYear(), label: new Date().getFullYear() },
  from: getDate(new Date(new Date().getFullYear(), 0, 1), false),
  to: getDate(new Date(new Date().getFullYear(), 11, 31), false),
  min: `${new Date().getFullYear()}-01-01`,
  max: `${new Date().getFullYear()}-12-31`,
}

const ProfitAndLossPage = () => {
  const { authState } = useAuth();
  const { enterprise } = authState;
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const { year, from, to, min, max } = formValues;
  const [value, setValue] = useState(null);
  const [filter, setFilter] = useState({ from, to })
  const { data, loading, error, refetch } = useQuery(profitAndLoss, { variables: { ...filter }, fetchPolicy: 'cache-and-network' });


  const indicators = {
    income: {
      value: 8900
    },
    cost: {
      value: 1000
    },
    expenses: {
      value: 3100
    },
    netIncome: {
      value: -13000
    },

  }


  const columns = [{
    id: 1,
    width: '70%',
    field: 'name',
    label: 'Cuentas',
    klass: 'w-4/5 text-left',
  }, {
    id: 2,
    field: 'amount',
    label: 'Monto',
    klass: 'w-full text-right',
    getValue: ({ value }) => formatMoney(enterprise, value)
  }]

  let details = [{
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Inversión',
    value: 100000
  }]

  const breads = [{
    title: 'Reportes',
    url: '/reports',
  }, {
    title: 'Perdidas y ganacias',
    url: '/reports/profit-and-loss'
  }];

  const handleInputChangeSelect = (e, key) => {
    console.log(e, key)
    if (!key) {
      let { value, name } = e.target;
    } else {
      if (key == 'year') {
        if (e !== null) {
          handleInputChange(`${e?.value}-01-01`, 'min')
          handleInputChange(`${e?.value}-12-31`, 'max')
          handleInputChange(`${e?.value}-01-01`, 'from')
          handleInputChange(`${e?.value}-12-31`, 'to')
        } else {
          handleInputChange('', 'from')
          handleInputChange('', 'to')
        }
      }
      handleInputChange(e, key)
    }
  }


  return (
    <IonPage>
      <Header title="Perdidas y ganancias"></Header>

      <IonContent className="sfn-content report-profit" fullscreen>
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />
        <section className="flex flex-col sm:flex-row justify-around  mb-20 items-end">
          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64" >Año</p>
            <Select
              isClearable={true}
              placeholder="Seleccione el año"
              value={year} options={generateYearsReports()} onChange={e => (handleInputChangeSelect(e, 'year'))}
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
              components={
                {
                  // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo cliente", callback:setShowCustomerModal}),
                  NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay años" })
                }
              }
            />
          </div>
          <InputDate label="Desde" value={from} handleInputChange={handleInputChange} name="from" icon={false} />
          <InputDate label="Hasta" value={to} handleInputChange={handleInputChange} name="to" icon={false} />
          <IonButton className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" shape="round" onClick={() => setFilter({from: from !== '' ? from : undefined, to: to !== '' ? to: undefined})}>
            {/* <IonIcon slot="start" icon={addCircleOutline} /> */}
            Actualizar reprote
          </IonButton>
        </section>
        <section className="mt-20">
          <h3 className="font-bold text-base text-gray-blue sm:text-2xl mb-4">Período de {parseDate(filter.from).replace(/\//g, '-')} a {parseDate(filter.to).replace(/\//g, '-')} </h3>
        </section>
        <Indicator loading={loading} data={data?.profitAndLossReport?.indicators} enterprise={enterprise} />
        
        <section className="mt-28">
          <GridTable
          title="Ingresos"
          loading={loading}
          columns={columns}
          rows={data?.profitAndLossReport?.income}
          income={data?.profitAndLossReport?.indicators?.income}
          klassHeader="border-b-2 border-gray-white"
          getValue={({ value }) => formatMoney(enterprise, value)}
          placeholder={<Placeholder icon={receiptOutline} description='Aún no tienes información' />} />
        </section>
        <section className="mt-28">
          <GridTable
          title="Costos de bienes vendidos"
          loading={loading}
          columns={columns}
          rows={data?.profitAndLossReport?.costOfGoodsSold}
          costOfGoodsSold={data?.profitAndLossReport?.indicators?.costOfGoodsSold}
          grossProfit={(data?.profitAndLossReport?.indicators?.grossProfit)}
          klassHeader="border-b-2 border-gray-white"
          getValue={({ value }) => formatMoney(enterprise, value)}
          placeholder={<Placeholder icon={receiptOutline} description='Aún no tienes información' />} />
        </section>
        <section className="mt-28">
          <GridTable
          title="Gastos operativos"
          loading={loading}
          columns={columns}
          rows={data?.profitAndLossReport?.expense}
          expense={data?.profitAndLossReport?.indicators?.expense}
          netIncome={data?.profitAndLossReport?.indicators?.netIncome}
          klassHeader="border-b-2 border-gray-white"
          getValue={({ value }) => formatMoney(enterprise, value)}
          placeholder={<Placeholder icon={receiptOutline} description='Aún no tienes información' />} />
        </section>
      </IonContent>
    </IonPage>
  )
}

export default ProfitAndLossPage
