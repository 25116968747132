import GridTable from '@nadavshaar/react-grid-table';
import Placeholder from '../../../../../../components/placeholder/Placeholder';
import { texts } from '../../../../../../theme/dataTabelTexts';
import SearchTable from '../../../../../../components/search-table/SearchTable';
import { createOutline, trashOutline, albumsOutline, cardOutline } from 'ionicons/icons';
import { IonIcon, useIonAlert, IonButton, IonNote } from '@ionic/react';
import OptionItem from '../../../../../../components/option-item/OptionItem';
import { formatMoney, isEmpty, tableProps } from '../../../../../../helpers/utils';
import ButtonPopover from '../../../../../../components/button-popover/ButtonPopover';
import './PaymentHistory.scss';
import { statusName, statusNamePaymentHistory } from '../../../../../../helpers/catalogs';
import { parseDate } from '../../../../../../helpers/date';
import { useAuth } from '../../../../../../context/auth/AuthState';
import { Tooltip } from '../../../../../../components/tooltip/Tooltip';
import { useQuery } from '@apollo/client';
import { paymentHistory } from '../../../../../../graphql/subscription/queries';

const PaymentHistory = ({ authState }) => {
    const { data, loading } = useQuery(paymentHistory);
    const [present] = useIonAlert();

    const { enterprise } = authState;


    function downloadFile(e) {
        e.stopPropagation();

        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: '',
            subHeader: `Descarga de factura`,
            message: `Oops! ha ocurrido un error, no hemos podido generar tu factura, puede tomar un lapso de hasta 24 horas en generarse.`,
            buttons: [
              {
                text: 'Cerrar',
                role: 'cancel',
                cssClass: 'secondary',
              },
            ],
          })
    }
    const columns = [
        {
            id: 1,
            field: 'status',
            label: 'Estado',
            widht: '25%',
            cellRenderer: ({ value }) => <IonNote className={`w-full text-center ${statusNamePaymentHistory[value]} with-bg`}>{isEmpty(value)}</IonNote>
        },
        {
            id: 2,
            field: 'date',
            label: 'Fecha',
            widht: '25%',
            getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
        },
        {
            id: 3,
            field: 'total',
            label: 'Total',
            widht: '25%',
            getValue: ({ value }) => isEmpty(value)
        },
        {
            id: 4,
            field: 'billing',
            label: '',
            widht: '25%',
            cellRenderer: ({ value }) => value === 'PENDING' ? <div className="flex w-full justify-center items-center"><Tooltip label="Tu factura de compra puede tomar un lapso de hasta 24 horas en llegar a tu correo electrónico"> <IonNote className={`w-full text-center bg-danger-hover text-danger p-2 rounded-full`}>Factura pendiente </IonNote></Tooltip></div> : <div className="text-center w-full">
                <IonButton className="sfn-button" fill="outline" shape="round" onClick={downloadFile} >Descargar</IonButton></div>
        },
    ];

    let rows = [{
        status: 'REJECTED',
        date: new Date(),
        total: 15,
        billing: true,
    }, {
        status: 'ACCEPTED',
        date: new Date(),
        total: 15,
        billing: true,

    }]



    return (
        <div className="p-4 payment-history">
            <h2 className="text-xl text-gray-blue font-bold mb-4">Historial de pagos</h2>
            <p className="text-base mb-12">Pagos deducidos de tu tarjeta.</p>
            <div className={`flex ${false ? 'h-screen' : 'h-96'}`}>
                <GridTable
                    className="max-h-80 sfn-data-table"
                    columns={columns}
                    isLoading={loading}
                    rows={data?.paymentHistory}
                    texts={texts}
                    components={{
                        NoResults: () => (
                            <Placeholder icon={cardOutline} title="No hay cobros realizados" description='Aún no se ha efectuado ningún cobro en tu método de pago.' />
                        ),
                        Search: SearchTable
                    }}
                    {...tableProps(false)} />
            </div>

        </div>
    );
}

export default PaymentHistory;