import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonTextarea, useIonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useForm } from "../../../hooks/useForm";
import Wrapper from "../../wrapper/Wrapper";
import Select from 'react-select';
import { sfnSelectTheme } from "../../../theme/sfnSelectTheme";
import { getDate } from "../../../helpers/date";
import InputDate from "../../input-date/InputDate";
import {paymentMethods} from '../../../helpers/catalogs';
import { useMutation, useQuery } from "@apollo/client";
import { assetAccountsQuery } from "../../../graphql/account/queries";
import { useAuth } from "../../../context/auth/AuthState";
import { useEffect, useState } from "react";
import { roundValue } from "../../../helpers/utils";
import { createInvoicePaymentMutation, updateInvoicePaymentMutation } from "../../../graphql/invoice/mutations";
import { createBillPaymentMutation, updateBillPaymentMutation } from "../../../graphql/bill/mutations";
import {getAllInvoicesQuery, getOneInvoiceDetailQuery, getOneInvoicePaymentQuery} from '../../../graphql/invoice/queries';  
import {getAllBillsQuery, getOneBillDetailQuery, getOneBillPaymentQuery} from '../../../graphql/bill/queries';  
import SfnButton from "../../button/Button";
import { Tooltip } from "../../tooltip/Tooltip";

const initialState = {
    date: getDate(new Date(), false),
    amount: 0,
    exchangeRate: 0,
    description: "",
    method: "",
    accountId: null,
    invoiceId: null,
    billId: null
}

const PaymentRecordModal = ({showPaymentRecordModal, setShowPaymentRecordModal, invoiceId=null, setInvoiceId=(e)=>{}, billId=null, setBillId=(e)=>{}, payment=null, setPayment=(e)=>{}, hookClose = ()=>{}}) => {
    
    const {formValues, handleInputChange, reset, numberHandleInputChange} = useForm({...initialState});
    const {amount, method, accountId, description, exchangeRate, date} = formValues;
    const [currency, setCurrency] = useState(null);
    const [document, setDocument] = useState(null);
    const [present] = useIonToast();
    const [createInvoicePayment, { loading:iLoading, error:iError }] = useMutation(createInvoicePaymentMutation, {refetchQueries:[{ query: getAllInvoicesQuery}]});
    const [updateInvoicePayment, { loading:iuLoading, error:iuError }] = useMutation(updateInvoicePaymentMutation, {refetchQueries:[{ query: getOneInvoicePaymentQuery, variables: { input: invoiceId }}]});

    const [createBillPayment, { loading:bLoading, error:bError }] = useMutation(createBillPaymentMutation, {refetchQueries:[{ query: getAllBillsQuery}]});
    const [updateBillPayment, { loading:buLoading, error:buError }] = useMutation(updateBillPaymentMutation, {refetchQueries:[{ query: getOneBillPaymentQuery, variables: { input: billId }}]});


    const {data:accounts, loading:accountsLoading, error:accountsError} = useQuery(assetAccountsQuery, {
        fetchPolicy: "no-cache"});

    useEffect(() => {
        if(payment && accounts){
            let pay = {...payment}
            pay.method = paymentMethods.find(m => m.value === payment.method);
            pay.account = accounts?.productAccounts.find(a => a.id === payment.account.id);
            console.log(payment.account);
            pay['accountId'] = {label: `${payment.account.name} (${payment.account.currency.code})`,  value: payment.account}
            console.log(payment, accounts?.productAccounts);
            setCurrency(payment.account.currency);
            reset(pay);
        }
    }, [payment, accounts]);

    useQuery(getOneInvoiceDetailQuery, {
        variables: { input: invoiceId },
        skip: !invoiceId,
        fetchPolicy: "no-cache",
        async onCompleted(data) {
            setDocument(data.invoice);
            if(!payment) handleInputChange(data.invoice.remaining, 'amount'); 
        }
    });

    useQuery(getOneBillDetailQuery, {
        variables: { input: billId },
        skip: !billId,
        fetchPolicy: "no-cache",
        async onCompleted(data) {
            setDocument(data.bill);
            if(!payment) handleInputChange(data.bill.remaining, 'amount'); 
        }
    });
    


    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateForm()) {
            if(invoiceId) handleInvoicePayment();
            if(billId) handleBillPayment();
        }
    }

    const handleInvoicePayment = async() => {
        if(payment){
            delete formValues.account;
            delete formValues.convertedAmount;
            delete formValues?.dataInvoice
            delete formValues?.dataBill
            let input = { ...formValues, currencyId: currency.id }
            await updateInvoicePayment({ variables: { input }})
        } else {
            let input = { ...formValues, currencyId: currency.id }
            await createInvoicePayment({ variables: { input } });
        }
        if(iError || iuError){
            present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if(!iLoading && !iuLoading){
            present({ message: `El pago se registro exitosamente! 🎉`, color: "success", mode: "md", duration: 4000 });
            onClose();
        }
    }

    const handleBillPayment = async() => {
        if(payment){
            delete formValues.account;
            delete formValues.convertedAmount;
            delete formValues?.dataInvoice
            delete formValues?.dataBill
            let input = { ...formValues, currencyId: currency.id }
            await updateBillPayment({ variables: { input }})
        } else {
            let input = { ...formValues, currencyId: currency.id }
            await createBillPayment({ variables: { input } });
        }
        if(bError || buError){
            present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if(!bLoading && !buLoading){
            present({ message: `El pago se registro exitosamente! 🎉`, color: "success", mode: "md", duration: 4000 });
            onClose();
        }
    }

    const validateForm = () => {
        formValues.amount = parseFloat(formValues.amount);
        formValues.exchangeRate = parseFloat(formValues.exchangeRate);
        if(!formValues.method){
            present({ message: 'Debes agregar un método de pago 💳', color: "warning", mode: "md", duration: 4000});
           return
        }
        if(!formValues.accountId){
            present({ message: 'Debes agregar una cuenta de pago 💹', color: "warning", mode: "md", duration: 4000});
           return
        }
        if(formValues.amount <= 0){
            present({ message: 'Debes agregar una monto 💲', color: "warning", mode: "md", duration: 4000});
           return
        }
        formValues.method = formValues.method.value;
        formValues.accountId = formValues.accountId.value.id; 

        if(invoiceId){
            formValues.invoiceId = invoiceId;
            delete formValues.billId;
        } else {
            formValues.billId = billId;
            delete formValues.invoiceId
        }
        return true;
    }

    const onClose = async() => {
        await hookClose();
        setShowPaymentRecordModal(false);
        reset({...initialState});
        setInvoiceId(null);
        setBillId(null);
        setPayment(null);
    }

    const handleAccountInputChange = (e) => {
        handleInputChange(e, 'accountId');
        setCurrency(e.value.currency);
        handleInputChange(getRate([e.value.currency.code]), 'exchangeRate')
    }

    const getRate = (code) => {
        return document?.currency?.rate?.rates[code]
    }

    const formatSelectAccounts = (accounts) => {
        if(!accounts) return [];
        return accounts.map(a => {
            return {
                label: `${a.name} (${a.currency.code})`,
                value: a 
            }
        })
    }

    return(
    <IonModal id="sfn-payment-record-modal" isOpen={showPaymentRecordModal} cssClass='sfn-modal-small' onDidDismiss={() => setShowPaymentRecordModal(false)}>
            <IonContent>
            <Wrapper>
                <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold">Registrar pago</h3>
                <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                </div>
                <IonNote color="medium" className="text-sm">{billId ? 'Registra el pago que hayas realizado a tu proveedor, ya sea en efectivo, cheque o transferencia bancaria.' :  'Registra el pago que hayas recibido, ya sea en efectivo, cheque o transferencia bancaria.'}</IonNote>

                <form onSubmit={handleSubmit}>
                <InputDate
                    label="Fecha de pago"
                    handleInputChange={handleInputChange}
                    name="date" 
                    value={date}
                    icon={false}
                    required
                />
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">Monto<span className="text-base text-danger">*</span></IonLabel>
                    <IonInput 
                        name="amount" 
                        type="text" 
                        onKeyPress={numberHandleInputChange} 
                        onChange={numberHandleInputChange} 
                        onKeyUp={numberHandleInputChange} 
                        onKeyDown={numberHandleInputChange}
                        value={amount}
                        required={true}
                    >
                    <span className="ml-4">{document?.currency?.symbol}</span>
                     </IonInput>    
                    <IonNote >{`${document?.currency?.code} - ${document?.currency?.name}`}</IonNote>
                </IonItem>

                {(currency && currency?.symbol !== document?.currency?.symbol) 
                && (<IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">Tasa de cambio</IonLabel>
                    <IonInput 
                    name="exchangeRate" 
                    type="tel" 
                    onIonChange={handleInputChange} 
                    value={exchangeRate}
                    required>
                    </IonInput>    
                    <IonNote>{`${document?.currency?.code} a ${currency?.code}`}</IonNote>
                </IonItem>) 
                }
                {(currency && currency?.symbol !== document?.currency?.symbol) &&
                (<div className="flex flex-col mt-4">
                    <h3 className="text-base font-bold">Monto conversión</h3>
                    <p className="text-xl">{currency?.symbol} {roundValue( amount * exchangeRate)}</p>
                    <IonNote className="text-sm">{`${currency?.code} - ${currency?.name}`}</IonNote>
                </div>)
                }

                <div className="mt-4 ">
                <p className="text-sm mb-2 hover:text-secondary" >Método de pago <Tooltip className="ml-1" label="Método con el que se hizo el pago total o parcial de la factura."/></p>
                <Select
                    placeholder="Método de pago"
                    value={method}
                    name="method"
                    onChange={e => handleInputChange(e, 'method')}
                    options={paymentMethods}
                    className="z-20"
                    styles={sfnSelectTheme}
                    />
                </div>
                
                <div className="my-4">
                <p className="text-sm mb-2 hover:text-secondary" >Cuenta de pago <Tooltip className="ml-1" label="Cuenta bancaria o contable a la que ingresa el dinero obtenido por este pago."/></p>
                <Select
                    placeholder="Cuenta de pago"
                    value={accountId}
                    isLoading={accountsLoading}
                    name="accountId"
                    onChange={e => handleAccountInputChange(e)}
                    options={formatSelectAccounts(accounts?.productAccounts)}
                    className="z-10"
                    styles={sfnSelectTheme}
                />
                </div>
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">Notas (Opcional)</IonLabel>
                    <IonTextarea 
                        name="description" 
                        type="text" 
                        rows="4"
                        onIonChange={handleInputChange} 
                        value={description}
                        /> 
                </IonItem>
                <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={() => setShowPaymentRecordModal(false)}> 
                        Cancelar
                        </IonButton>
                        <SfnButton label="Registrar pago" btnClass={'ml-4'} loading={bLoading || iLoading}/>

                    </div>
                </form>
            </Wrapper>
            </IonContent>
    </IonModal>
    )
}

export default PaymentRecordModal;