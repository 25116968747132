export const PayrollsOnboarding = [
  {
    title: "Lleva el control de tu nómina",
    description:
      "Agrega a los colaboradores de tu empresa para poder registrar los pagos manuales que les realices en la frecuencia que definas.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Nómina1-min.gif",
    alt: "Control de nómina",
  },
  {
    title: "Beneficios y descuentos de colaboradores",
    description:
      "Registra fácilmente los beneficios y/o descuentos de tus colaboradores para cálcularlos en el pago correspondiente de nómina.",
    image: " https://cdn01.prd.symfonia.io/Gifs/Nómina2-min.gif",
    alt: "Beneficios y descuentos",
  },
  // {
  //   title: "Envío de boletas de pago",
  //   description:
  //     "Descarga o envía las boletas de pago a tus colaboradores vía correo electrónico para llevar el control de los pagos realizados.",
  //   image: "https://cdn01.prd.symfonia.io/Gifs/Nómina3-min.gif",
  //   alt: "Envío de boletas",
  // },
];
