import { IonContent, IonPage, IonButton, IonIcon, IonItem, IonInput, IonLabel } from '@ionic/react';
import React, { useState, useRef } from 'react';
import { refresh, downloadOutline, trash, calendar, filterOutline, receiptOutline } from 'ionicons/icons';
import Header from '../../../../components/header/Header';
import Tabs from '../../../../components/tabs/Tabs';
import Kpi from '../../../../components/kpi/Kpi';
import Select from 'react-select';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import './CashFlowPage.scss';
import InputDate from '../../../../components/input-date/InputDate';
import { useForm } from '../../../../hooks/useForm';
import { getDate, getRange, parseDate } from '../../../../helpers/date';
import { cashFlow } from '../../../../graphql/report/queries';
import { useQuery } from '@apollo/client';
import { useAuth } from '../../../../context/auth/AuthState';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { formatMoney } from '../../../../helpers/utils';
import GridTable from './components/grid-table/GridTable';
import BreadCrumbs from '../../../../components/breadcrumbs/BreadCrumbs';


const initialState = {
  from: getDate(new Date(new Date().getFullYear(), 0, 1), false),
  to: getDate(new Date(new Date().getFullYear(), 11, 31), false),
}
const CashFlowPage = ({ location, history }) => {
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const { from, to } = formValues;
  const [selected, setSelected] = useState('resume');
  const [compare, setCompare] = useState(false);
  const [filter, setFilter] = useState({ from, to })
  const [resumeTable, setResumeTable] = useState([]);
  const { data, loading, error, refetch } = useQuery(cashFlow, {
    variables: { ...filter }, fetchPolicy: 'cache-and-network', onCompleted: (data) => {
      data = JSON.parse(JSON.stringify(data))
      setResumeTable(data?.cashFlow.map(cashFlow => {
        if (cashFlow.id == '' && cashFlow.label != '' && (cashFlow.val != '' || parseFloat(cashFlow.val) < 1)) {
          cashFlow.classes = 'highlight';
        }
        if (cashFlow.val == '' || parseFloat(cashFlow.val) < 1 && !cashFlow.label.toLowerCase().includes('total')) {
          cashFlow.val = ''
        } else {
          cashFlow.val = `${enterprise?.currency?.symbol}${cashFlow.val} ${enterprise?.currency?.code}`;
        }
        // if(cashFlow.label.toLowerCase().includes('total')) {
        //   cashFlow.classes = 
        // }
        return cashFlow
      }))
      console.log(data)
    }
  });


  const columns = [{
    id: 1,
    width: '70%',
    field: 'label',
    label: 'Nombre',
    klass: 'w-4/5 text-left',
  }, {
    id: 2,
    field: 'value',
    label: 'Monto',
    klass: 'w-full text-right',
    getValue: ({ value }) => formatMoney(enterprise, value)
  }]

  let details = [{
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Actividades operativas',
    value: '123132.13',
    childrens: [{
      label: 'Ventas',
      value: '10.21'
    }, {
      label: 'Compras',
      value: '10.21'
    }, {
      label: 'Inventario',
      value: '10.21'
    }, {
      label: 'Pago de nómina',
      value: '10.21'
    }]
  }, {
    label: 'Inversión',
    value: 100000
  }]

  const breads = [{
    title: 'Reportes',
    url: '/reports',
  }, {
    title: 'Flujo de efectivo',
    url: '/reports/cash-flow'
  }];

  return (
    <IonPage>
      <Header title="Flujo de efectivo"></Header>

      <IonContent className='sfn-content' fullscreen>
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />

        <div className="flex sm:flex-row flex-col justify-between md:items-end items-center m-8">
          <div className="flex flex-col md:w-1/2 w-full">

            <div className="flex md:flex-row flex-col">

              {/* <div className="sfn-CashFlow-select mt-8 ">
                <p className="text-sm mb-2 hover:text-secondary" >A partir de </p>
                <Select
                  placeholder="Selecciona año"
                  value={selectedOption}
                  onChange={handleChangeSelect}
                  formatGroupLabel={formatGroupLabel}
                  options={options}
                  className=""
                  styles={sfnSelectTheme}
                />
              </div> */}

              <InputDate className="px-3" label="Desde" value={from} handleInputChange={handleInputChange} name="from" icon={false}  ></InputDate>
              <InputDate className="px-3" label="Hasta" value={to} handleInputChange={handleInputChange} name="to" icon={false} ></InputDate>


            </div>
            {/* {compare == false &&
              <span className="text-primary font-bold cursor-pointer mt-8" onClick={() => setCompare(true)}>
                + Comparar con período anterior
              </span>}
            {compare &&
              <div className="flex flex-row w-full items-end ">

                <div className="sfn-CashFlow-select mt-8">
                  <p className="text-xs mb-2 hover:text-secondary" >A partir de </p>
                  <Select
                    placeholder="Selecciona año"
                    value={selectedOption}
                    onChange={handleChangeSelect}
                    formatGroupLabel={formatGroupLabel}
                    options={options}
                    className=""
                    styles={sfnSelectTheme}
                  />
                </div>

                <InputDate className="px-3" label="Desde" value={from} handleInputChange={handleInputChange} name="from" icon={calendar}  ></InputDate>
                <InputDate className="px-3" label="Hasta" value={to} handleInputChange={handleInputChange} name="to" icon={calendar} ></InputDate>

                <div className="text-gray p-3 cursor-pointer" onClick={() => setCompare(false)}>
                  <IonIcon icon={trash}></IonIcon>
                </div>

              </div>} */}
          </div>
          <IonButton className="sfn-button" shape="round" onClick={() => { setFilter({ from, to }) }} >
            <IonIcon slot="start" icon={refresh} />
            Actualizar reporte
          </IonButton>

        </div>
        <div className="flex flex-row justify-between items-center mt-14 mb-8 mx-8">
          <p className="text-2xl text-gray-blue font-bold">
            Período de {parseDate(from).replace(/\//g, '-')} a {parseDate(to).replace(/\//g, '-')}
          </p>
          {/* <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => { }}>
            <IonIcon slot="start" icon={downloadOutline} />
              Descargar
          </IonButton> */}
        </div>
        {/* <div className="indicator flex flex-row justify-center items-center m-8">
          <Kpi icon="" title="Q. 120,000.00" description="Flujo bruto de efectivo" />
          <span>-</span>
          <Kpi icon="" title="Q. 10,000.00" description="Salida bruta de efectivo" />
          <span>=</span>
          <Kpi icon="" title="Q. 110,000.00" description="Cambio neto de efectivo" />
        </div> */}
        {/* <div className="flex flex-col justify-center items-center aling-center sfn-CashFlow-tabs">
          <Tabs tabs={tabs} selected={selected} setSelected={setSelected} url={true} path="/accounts" history="" />
        </div> */}
        {/* {selected == 'resume' && <Resume />} */}
        {/* {selected == 'detail' && <Detail />} */}
        {/* {!loading && <Detail columns={columnsDetail} data={resumeTable}/>} */}
        <GridTable
          columns={columns} 
          rows={details} 
          klassHeader="border-b-2 border-gray-white"
          getValue={({ value }) => formatMoney(enterprise, value)}
          placeholder={<Placeholder icon={receiptOutline} description='Aún no tienes información' />} />
      </IonContent>
    </IonPage>
  );
};

export default CashFlowPage;
