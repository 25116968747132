// prettier-ignore

import { useMutation } from '@apollo/client'
import {
	createReceiptMutation,
	deleteReceiptMutation,
	updateReceiptMutation,
} from './mutations'
import { getAllReceiptQuery, getOneReceiptQuery } from './queries'

export const useReceiptsService = () => {
	const [createReceipt, { loading: cLoading, error: cError }] = useMutation(
		createReceiptMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createReceipt } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllReceiptQuery })
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllReceiptQuery,
					data: {
						receipts: {
							rows: [createReceipt, ...data.receipts.rows],
						},
					},
				})
			},
		},
	)

	const [updateReceipt, { loading: uLoading, error: uError }] = useMutation(
		updateReceiptMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateReceipt } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllReceiptQuery })
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllReceiptQuery,
					data: {
						receipts: {
							rows: data.receipts ? data.receipts.rows.map((receipt) =>
								receipt.id === updateReceipt.id
									? updateReceipt
									: receipt,
							) : [],
						},
					},
				})
			},
		},
	)

	const [deleteReceipt, { loading: dLoading, error: dError }] = useMutation(
		deleteReceiptMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteReceipt } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllReceiptQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllReceiptQuery,
					data: {
						receipts: {
							rows: data.receipts ? data.receipts.rows.filter(
								(receipt) => receipt.id !== deleteReceipt.id,
							) : [],
						},
					},
				})
			},
		},
	)

	return {
		createReceipt,
		cLoading,
		cError,

		updateReceipt,
		uLoading,
		uError,

		deleteReceipt,
		dLoading,
		dError,
	}
}
