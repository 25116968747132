// prettier-ignore

import {useMutation} from '@apollo/client'
import {
  createInvoiceMutation,
  deleteInvoiceMutation,
  generateInvoiceMutation,
  updateInvoiceMutation,
} from './mutations';
import { getAllInvoicesQuery } from './queries';

export const useInvoicesService = () => {
  const [createInvoice, { loading: cLoading, error: cError }] = useMutation(
    createInvoiceMutation,
    {
      errorPolicy: 'all',
      update(cache, { data: { createInvoice } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllInvoicesQuery });
        // reescribir ese objeto
        cache.writeQuery({
          query: getAllInvoicesQuery,
          data: {
            invoices: {
              rows: [createInvoice, ...data.invoices.rows],
            },
          },
        });
      },
    }
  );

  const [updateInvoice, { loading: uLoading, error: uError }] = useMutation(
    updateInvoiceMutation,
    {
      errorPolicy: 'all',
      update(cache, { data: { updateInvoice } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllInvoicesQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllInvoicesQuery,
          data: {
            invoices: {
              rows: data?.invoices
                ? data.invoices.rows.map((invoice) =>
                    invoice.id === updateInvoice.id ? updateInvoice : invoice
                  )
                : [],
            },
          },
        });
      },
    }
  );

  const [deleteInvoice, { loading: dLoading, error: dError }] = useMutation(
    deleteInvoiceMutation,
    {
      errorPolicy: 'all',
      update(cache, { data: { deleteInvoice } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllInvoicesQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllInvoicesQuery,
          data: {
            invoices: {
              rows: data?.invoices
                ? data.invoices.rows.map((invoice) =>
                    invoice?.id === deleteInvoice?.id ? deleteInvoice : invoice
                  )
                : [],
            },
          },
        });
      },
    }
  );

  const [generateInvoice, { loading: gLoading, error: gError }] = useMutation(
    generateInvoiceMutation,
    {
      errorPolicy: 'all',
      update(cache, { data: { generateInvoice } }) {
        // obtener el objeto de cache
        const data = cache.readQuery({ query: getAllInvoicesQuery });

        // reescribir ese objeto
        cache.writeQuery({
          query: getAllInvoicesQuery,
          data: {
            invoices: {
              rows: data?.invoices
                ? data.invoices.rows.map((invoice) =>
                    invoice.id === generateInvoice?.message ? {...invoice, isFEL: true } : invoice
                  )
                : [],
            },
          },
        });
      },
    }
  );

  return {
    createInvoice,
    cLoading,
    cError,

    updateInvoice,
    uLoading,
    uError,

    deleteInvoice,
    dLoading,
    dError,

	generateInvoice,
	gLoading,
	gError
  };
};
