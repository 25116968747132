import './GridTable.scss';

const GridTable = ({ title, columns, rows, income, costOfGoodsSold, grossProfit, netIncome, expense, klassHeader, getValue, loading = false, placeholder }) => {

    return (<div className="m-8 grid-table-node">
          <h3 className="font-bold text-base text-gray-blue sm:text-2xl mb-4">{title}</h3>
        {rows?.length ? <div className={`header flex w-full font-bold  ${klassHeader}`}>
          {  columns?.map(({ id, field, label, klass }, index) => (<p key={id + index} className={`m-4 w-full text-xl ${klass}`}>{label}</p>))}
        </div> : null}
        {loading && <div className="flex w-full content  items-center justify-center">
            <div className="donut"></div>
        </div>}
        {/* {(!loading && !rows.length) && <div className="flex w-full content  items-center justify-center">
            {placeholder}
        </div>} */}
        <div className="h-auto mb-8 flex flex-col w-full content">

            {!loading && rows?.map((data, index) => (
                <div key={data.id + index}>

                    <div key={data + index} className="flex hover:bg-gray-white rounded-md hover:cursor-pointer">
                        {columns?.map(({ id, field, klass, getValue }) => (
                            <p key={id + index} className={`${klass} mx-4 py-4 text-base`}>
                                {getValue ? getValue({ value: data[field] }) : data[field]}
                            </p>))}
                    </div>

                </div>))}
            {(income) ? <div className="my-2 flex w-full bg-gray-white rounded-md">
                <p className="w-4/5 text-left mx-4 py-4 text-base font-bold">Total de ingresos</p>
                <p className="w-full text-right mx-4 py-4 text-base font-bold ">{getValue({ value: income })}</p>
            </div> : null}

            {(costOfGoodsSold) ? <div className="my-2 flex w-full bg-gray-white rounded-md">
                <p className="w-4/5 text-left mx-4 py-4 text-base font-bold">Total de costos de bienes vendidos</p>
                <p className="w-full text-right mx-4 py-4 text-base font-bold ">{getValue({ value: costOfGoodsSold })}</p>
            </div> : null}


            {( expense) ? <div className="my-2 flex w-full bg-gray-white rounded-md">
                <p className="w-4/5 text-left mx-4 py-4 text-base font-bold">Total de gastos operativos</p>
                <p className="w-full text-right mx-4 py-4 text-base font-bold ">{getValue({ value: expense })}</p>
            </div> : null}

            {(grossProfit) ? <div className="my-2 flex w-full bg-gray-light rounded-md">
                <p className="w-4/5 text-left mx-4 py-4 text-base font-bold text-gray-white">Ganancia bruta</p>
                <p className="w-full text-right mx-4 py-4 text-base  text-gray-white font-bold ">{getValue({ value: grossProfit })}</p>
            </div> : null}

            {(netIncome) ? <div className="my-2 flex w-full bg-gray-light rounded-md">
                <p className="w-4/5 text-left mx-4 py-4 text-base font-bold text-gray-white">Ganancia neta</p>
                <p className="w-full text-right mx-4 py-4 text-base  text-gray-white font-bold ">{getValue({ value: netIncome })}</p>
            </div> : null}

        </div>
    </div>);
}

export default GridTable;