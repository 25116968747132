import { IonButton, IonContent, IonIcon, IonPage, useIonAlert, useIonToast, IonImg, useIonViewDidEnter } from '@ionic/react';
import { addCircleOutline, create, play, trash, filterOutline, peopleOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Header from '../../components/header/Header';
import Title from '../../components/title/Title';
import InviteModal from '../../components/modals/invite-modal/InviteModal';
import OptionItem from '../../components/option-item/OptionItem';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import './UserManagementPage.scss';
import language from './language.json';
import { useQuery, useApolloClient } from '@apollo/client';
import { getAllUsersQuery } from '../../graphql/user/queries';
import { useUsersService } from '../../graphql/user/service';
import { useAuth } from '../../context/auth/AuthState';
import Placeholder from '../../components/placeholder/Placeholder';
import { texts } from '../../theme/dataTabelTexts';
import { getUrlFile } from '../../helpers/uploadFiles';
import { getAvatar } from '../../helpers/utils';
import { rolesName, translatePlans } from '../../helpers/catalogs';
import SearchTable from '../../components/search-table/SearchTable';
import { AuthStore } from '../../store/auth';
import { addUserStore, removeUserStore, UsersStore } from '../../store/users';

const text = language.es;
const code = 'D32J#DH'
const UserManagementPage = () => {
  const client = useApolloClient();
  const [inviteModal, setShowInviteModal] = useState(false)
  const [id, setId] = useState(null)
  const [load, setLoad] = useState(false);
  const users = UsersStore.useState(s => s.users)
  const user = AuthStore.useState(s => s.user)
  const { createUser, cLoading, cError, updateUser, uLoading, uError, deleteUser, dLoading, dError } = useUsersService();

  const { data, loading, error, refetch } = useQuery(getAllUsersQuery, {
    fetchPolicy: 'cache-and-network',
    async onCompleted(data) {
      data = JSON.parse(JSON.stringify(data));
      setLoad(loading);
      if (data && !error) {
        for (let index = 0; index < data.users.rows.length; index++) {
          if (data.users.rows[index].picture && data.users.rows[index].picture != null) {
            data.users.rows[index].picture = await getUrlFile(client, data.users.rows[index].picture, 'profile')
          }
        }
        addUserStore(data.users.rows);
      }
      setLoad(false);
    }
  })


  const [present] = useIonAlert();
  const [toast] = useIonToast();
  const { authState, whoiam } = useAuth();

  // useEffect(() => {
  //   whoiam();
  // }, []);


  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: text.sections.delete.title,
      subHeader: text.sections.delete.description,
      message: text.sections.delete.message,
      buttons: [
        {
          text: text.sections.delete.actions.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: blah => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: text.sections.delete.actions.confirm,
          cssClass: 'delete',
          handler: async () => {
            let {data: remove, errors} = await deleteUser({ variables: { input: id } });
            if(!errors) { 
              removeUserStore(id);
            }
          }
        }
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
  }

  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} icon={create} caption={text.sections.users.table.edit} event={() => handleUpdate(data.id)} />
      <OptionItem onHide={onHide} icon={trash} caption={text.sections.users.table.remove} event={() => handleDelete(data.id)} />
    </>
  );

  const Options = ({ data }) => (
    <>
      {!['OWNER'].includes(data?.role?.name) && ['ADMIN', 'OWNER'].includes(user?.role?.permissions['users']) &&
        <div>
          <ButtonPopover options={ItemsPopover} data={data} />
        </div>
      }
    </>
  );

  const avatar = ({ data }) => {
    return (
      <div className="flex flex-row self-auto items-center p-4">
        <div className="sfn-user-avatar flex items-center justify-center rounded-full">
          {(data.picture == null) && getAvatar(data.name)}
          {(data.picture != null) && <IonImg src={data.picture} />}
        </div>
        <div className="flex-initial">{data.name}</div>
      </div>
    )
  }

  const copy = (text) => {
    navigator.clipboard.writeText(text)
    toast({ message: `Código copiado al porta papeles`, color: "success", mode: "md", duration: 4000 })
  }

  const confirmation = ({ data }) => {
    return (
      <>
        {data.status && <div className="mx-5">{text.sections.users.table.confirmed}</div>}
        {!data.status && <div className="sfn-user-table-pending mx-5">{text.sections.users.table.pending}</div>}
      </>
    )
  }

  const handleUpdate = (id) => {
    setId(id);
    setShowInviteModal(true);
  }

  const columns = [
    {
      id: 1,
      field: 'name',
      label: text.sections.users.table.name,
      width: '35%',
      cellRenderer: avatar
    },
    {
      id: 2,
      field: 'email',
      label: text.sections.users.table.email,
      width: '20%'
    },
    {
      id: 3,
      field: 'role.name',
      label: text.sections.users.table.role,
      width: '15%',
      cellRenderer: ({ data }) => (<p className="mx-5">{rolesName[data.role.name] ? rolesName[data.role.name] : 'N/A'}</p>)
    },
    {
      id: 4,
      label: 'Estado',
      width: '25%',
      cellRenderer: confirmation
    },
    {
      id: 'option',
      label: '',
      width: "max-content",
      pinned: true,
      sortable: true,
      resizable: false,
      cellRenderer: Options
    },

  ];

  useEffect(async () => {
    setLoad(true);
    let data = [...users]
    if (data.length) {
      for (let index = 0; index < data.length; index++) {
        data[index] = {...data[index]}
        if (data[index].picture && data[index].picture != null) {
          if (data[index].id == user.id) {
            data[index].picture = user?.urlPicture;
          } else {
            data[index].picture = await getUrlFile(client, data[index].picture, 'profile')
          }
        }
      }
      addUserStore(data);
    }
    setLoad(false);
  }, [authState])


  return (
    <IonPage>
      <Header title={text.header.title} subtitle={`${users?.length || 0} ${text.header.description}${users?.length > 1 ? 's' : ''}`}></Header>

      <IonContent className="sfn-content">
        {/* <div className="flex flex-row justify-end self-auto mt-12 mb-8">
          <div className="flex flex-row justify-between items-center self-auto">
            <p className="text-xl font-bold p-2 cursor-pointer" onClick={() => { copy(code) }}>{code}</p>
            <div className="flex flex-col ">
              <h4 className="text-l font-bold">{text.sections.share.title}</h4> <p className="text-s">{text.sections.share.description}</p>
            </div>
          </div>
        </div> */}
        <div className="flex flex-row justify-between self-auto mt-12 mb-8">
          <Title title={text?.sections?.users?.title} description={`${text?.sections?.users?.plan} ${translatePlans[user?.plan?.id]?.toLowerCase()}: ${(user?.plan?.users - users?.length | 0)} ${text?.sections?.users.licenses}`}></Title>
          <IonButton className="sfn-button" shape="round" onClick={() => setShowInviteModal(true)} disabled={user?.plan?.users - users?.length <= 0 && ['READ'].includes(user?.role?.permissions['users'])}>
            <IonIcon slot="start" icon={addCircleOutline} />
            {text?.sections?.users?.button}
          </IonButton>
        </div>
        {!load && <GridTable
          className="sfn-data-table"
          columns={columns}
          rows={users}
          texts={texts}
          components={{
            NoResults: () => (
              <Placeholder icon={peopleOutline} description={text.sections.users.table.noResults} />
            ),
            Search: SearchTable
          }}
          isLoading={load}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }} />}
        {inviteModal && <InviteModal showModal={inviteModal} setShowModal={setShowInviteModal} id={id} setId={setId} users={users}></InviteModal>}
      </IonContent>
    </IonPage>
  );
};

export default UserManagementPage;
