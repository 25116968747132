import { IonButton, IonIcon, IonImg, useIonToast, IonSpinner } from "@ionic/react"
import { camera } from "ionicons/icons";
import './ProfileAvatarImage.scss';
import { readURL, uploadFiles, getUrlFile } from '../../helpers/uploadFiles';
import { useQuery, useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useUsersService } from "../../graphql/user/service";
import { useAuth } from '../../context/auth/AuthState';


const ProfileAvatarImage = ({ auth }) => {
    const [present] = useIonToast();
    const client = useApolloClient();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { updateUser, uLoading, uError, } = useUsersService();
    const { authState, setAuth } = useAuth();
    const [picture, setPicture] = useState(auth?.urlPicture|| 'assets/img/avatar.jpg');

    const handleFileInput = async (e) => {
        let { files: fileList } = e.target;
        if (fileList[0]) {
            setFiles([fileList[0]]);
            if (fileList[0].size >= 5000000) {
                present({ message: `El archivo debe ser menor a 5mb 😯`, color: "warning", mode: "md", duration: 4000 });
                return;
            }
            setLoading(true);
            let _files = await uploadFiles(client, [fileList[0]], setFiles, 'profile');
            let input = {
                id: authState.id,
                picture: _files[0].ufid
            }
            if (_files) {
                await updateUser({ variables: { input }, fetchPolicy: 'no-cache' });
                let auth = JSON.parse(JSON.stringify(authState));
                auth.urlPicture = await readURL(fileList[0])
                localStorage.setItem('profile_picture', auth.urlPicture);
                setAuth({ user: auth, token: localStorage.getItem('token') });
                setPicture(auth.urlPicture);
                setLoading(false);
            }
        }
    }

    return (
        <div id="profile-avatar-image">
            <div className="avatar-image">
                <div className="loader">
                    {loading && <IonSpinner name="crescent" />}
                    {!loading && <IonImg src={auth?.urlPicture || 'assets/img/avatar.jpg'} 
                    onIonError={() => setPicture('assets/img/avatar.jpg')}  />}
                </div>
                <IonButton color="medium">
                    <IonIcon slot="icon-only" icon={camera}></IonIcon>
                    <input type="file" onChange={handleFileInput} accept="image/x-png, image/jpeg" />
                </IonButton>
            </div>

            <p className="text-xl font-bold text-center mt-2">{auth.name}</p>
            <p className="text-center mb-6">{auth.email}</p>
        </div>
    )
}

export default ProfileAvatarImage;