import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonFooter, IonContent, IonTextarea, useIonToast, IonCheckbox } from '@ionic/react';
import { closeCircleOutline, closeOutline, trashOutline, checkmarkOutline } from 'ionicons/icons';
import { useRef, useState, useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import { defaultValue, formatSelectData } from '../../../helpers/utils';
import { validate, validateKey } from '../../../helpers/validations';
import './BenefitDiscountModal.scss';
import Select, { Component } from 'react-select';
import { sfnSelectMultiTheme, sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { areaCodes, bankAccountTypes, countries, currencies, phoneTypes, allBenefits, typeFrequency, allDiscounts } from '../../../helpers/catalogs';
import { useLegalBenefitService } from '../../../graphql/legalBenefit/service';
import { validationMap } from './validation';
import SfnButton from '../../button/Button';
import { useQuery } from '@apollo/client';
import { getOneCustomerQuery } from '../../../graphql/customer/queries';
import InputDate from '../../input-date/InputDate';
import { getAllEmployeesQuery } from '../../../graphql/employee/queries';
import { getOneLegalBenefitQuery } from '../../../graphql/legalBenefit/queries';
import { components } from "react-select";
import { OptionCheckbox, MultiValue, MenuListButton } from '../../select-menu-components/SelectMenuComponents';
import makeAnimated from 'react-select/animated';
import EmployeeModal from '../employee-modal/EmployeeModal';
import { Tooltip } from '../../tooltip/Tooltip';

const initialState = {
    legalBenefitTypeId: '',
    dueDate: '',
    frequency: '',
    amount: '',
    description: '',
    employees: [],
    isPercent: false,
}

const BenefitDiscountModal = ({ type, showModal, setShowModal, id = null, setId, hookClose = ()=> {} }) => {
    const animatedComponents = makeAnimated();
    const {formValues, handleInputChange, reset, resetProps, handleInputBlur, numberHandleInputChange} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { legalBenefitTypeId, dueDate, frequency, amount, description, employees, isPercent } = formValues;
    const { createLegalBenefit, cLoading, cError, updateLegalBenefit, uLoading, uError } = useLegalBenefitService();
    const [present] = useIonToast();
    const [valid, setValid] = useState(false);
    const { data: dataEmployees, loading: lodingEmployee, error: errorEmployeee } = useQuery(getAllEmployeesQuery);
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [inputAmount, setInputAmount] = useState({ min: 0, max: null, step: 'any', type: 'number' });
    const { loading, data } = useQuery(getOneLegalBenefitQuery, {
        variables: { input: id },
        skip: !id,
        fetchPolicy: "no-cache",
        async onCompleted(data) {
            let { legalBenefit } = JSON.parse(JSON.stringify(data));
            legalBenefit.legalBenefitTypeId = legalBenefit.legalBenefitType;
            delete legalBenefit.legalBenefitType;
            legalBenefit.frequency = typeFrequency.find(t => t.value === legalBenefit.frequency);
            legalBenefit.employees = legalBenefit.employees.length == dataEmployees.employees.rows.length ? [{
                label: "Seleccionar todo",
                value: "*"
            }, ...legalBenefit.employees] : legalBenefit.employees;
            if (legalBenefit.isPercent) {
                setInputAmount({ min: 0, max: 100, step: '0.00', type: 'tel' });
            } else {
                setInputAmount({ min: null, max: null, step: 'any', type: 'number' });

            }
            resetProps({ ...legalBenefit });
        }
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        formValues.amount = parseFloat(formValues.amount);
        const valid = validate(formValues, validationMap);
        setValid(valid);

        if (!valid.valid) {
            present({ message: 'Debes llenar todos los campos obligatorios', color: "warning", mode: "md", duration: 4000 });
            return;
        }
        let input = JSON.parse(JSON.stringify(formValues));
        input.employees = input.employees.filter(f => f.value != '*').map(e => e.value);
        input.frequency = input.frequency.value;
        input.legalBenefitTypeId = input.legalBenefitTypeId.value;

        console.log(input);
        if (id) {
            await updateLegalBenefit({ variables: { input } })
        } else {
            await createLegalBenefit({ variables: { input } });
        }

        if (!uLoading && !cLoading) {
            if (cError || uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            }
            present({ message: `El ${type == 'BENEFIT' ? 'beneficio' : 'descuento'} se ${id ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
            e.target.reset();
        }
    }


    const onClose = () => {
        hookClose();
        reset({ ...initialState });
        setShowModal(false);
        setId(null);
    }

    const changeMultiple = (selected, event, selectedAll, options) => {
        if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === selectedAll.value) {
                return handleInputChange([selectedAll, ...options], 'employees');
            }
            let result = [];
            
            if (selected.length === options.length) {
                if (selected.includes(selectedAll)) {
                    result = selected.filter(
                        option => option.value !== selectedAll.value
                    );
                } else if (event.action === 'deselect-option') {
                    if (event.option.value === selectedAll.value) {
                        return handleInputChange([], 'employees');
                    }
                    result = options.filter(op => op.value !== selectedAll.value);
                    result = options.filter(op => op.value !== event.option.value);
                }
                handleInputChange(result, 'employees');
            }
        }
        handleInputChange(selected, 'employees');
    }

    const handleChangeAmount = (e) => {
        let { name, value } = e.target;
        value = parseFloat(value)
        console.log(value);
        if (isPercent) {
            if (value < 0 || value > 100) {
                handleInputChange(0, name);
            } else {
                if (!isNaN(value)) {
                    handleInputChange(value, name);
                } else {
                    handleInputChange(0, name);
                }
            }
        } else {
            if (value < 0) {
                handleInputChange(0, name);
            }
        }
    }
    const handleChangeIsperecent = (e) => {
        let { checked } = e.detail;
        // handleInputChange(amount || 0, 'amount');
        if (checked) {
            setInputAmount({ min: 0, max: 100, step: '0.00', type: 'tel' });
        } else {
            setInputAmount({ min: null, max: null, step: 'any', type: 'number' });
        }
        handleInputChange(checked, 'isPercent');
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-2xl font-bold">{id ? 'Editar' : 'Nuevo'} {type == 'BENEFIT' ? 'beneficio' : 'descuento'}</h3>
                    <p className="text-center text-sm">Completa el siguiente formulario para {id ? 'editar un' : 'agregar un nuevo'} {type === 'BENEFIT' ? 'beneficio' : 'descuento'}</p>
                    <form id='benefit-discount-form' onSubmit={handleSubmit} className="h-full p-2 mb-8">
                        <div>
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'legalBenefitTypeId') ? 'text-danger' : ''}`}>{type === 'BENEFIT' ? 'Beneficio' : 'Descuento'}<span className="text-base text-danger">*</span></p>
                            <Select placeholder={`Selecciona tipo de ${type === 'BENEFIT' ? 'beneficio' : 'descuento'}`} value={legalBenefitTypeId} onChange={e => handleInputChange(e, 'legalBenefitTypeId')} options={type === 'BENEFIT' ? formatSelectData({ value: 'id', label: 'name' }, allBenefits) : formatSelectData({ value: 'id', label: 'name' }, allDiscounts)} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'employees') ? 'text-danger' : ''}`}>Colaboradores<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona colaboradores"
                                hideSelectedOptions={false}
                                // controlShouldRenderValue={true}
                                // allowSelectAll={true}
                                components={{
                                    MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo colaborador", callback: setShowEmployeeModal }),
                                    Option: OptionCheckbox, animatedComponents
                                }}
                                isMulti={true}
                                value={employees}
                                onChange={(selected, event) => (changeMultiple(selected, event, {
                                    label: "Seleccionar todo",
                                    value: "*"
                                }, formatSelectData({ value: 'id', label: 'name' }, dataEmployees?.employees?.rows)))}
                                options={[{
                                    label: "Seleccionar todo",
                                    value: "*"
                                }, ...formatSelectData({ value: 'id', label: 'name' }, dataEmployees?.employees?.rows)]}
                                className="sfn-select" styles={sfnSelectMultiTheme} />
                        </div>
                        <div className="my-4">
                            <div className="flex items-center">
                                <IonCheckbox className="sfn-checkbox mr-2 z-0" checked={isPercent} onIonChange={handleChangeIsperecent} />
                                <IonLabel>Utilizar porcentaje para calcular sobre salario base</IonLabel>
                            </div>
                        </div>
                        <div className="my-3 flex justify-start items-center">
                            <InputDate className="sm:w-1/2" label={type == 'BENEFIT' ? 'Fecha de pago' : 'Fecha descuento'} value={dueDate} handleInputChange={handleInputChange} name="dueDate" icon={false} required={true}></InputDate>
                            <div className="sfn-input ml-4 w-full flex flex-col" mode="md" lines="none">
                                <div position="stacked" className="flex items-center text-sm hover:text-secondary mt-3">
                                    <p className="mr-1 mb-1">
                                        {isPercent ? 'Porcentaje' : 'Monto'}<span className="text-base text-danger">*</span>
                                    </p>
                                    {isPercent ? <Tooltip label="El cálculo porcentual se hará sobre el salario de cada colaborador seleccionado." /> : null}
                                </div>
                                <IonInput name="amount" type="text" 
                                    onKeyPress={numberHandleInputChange} 
                                    onIonChange={numberHandleInputChange} 
                                    onKeyUp={numberHandleInputChange} 
                                    onKeyDown={numberHandleInputChange}
                                    min={inputAmount.min} max={inputAmount.max} step={inputAmount.step} value={amount} placeholder="0.00" required> </IonInput>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'frequency') ? 'text-danger' : ''}`}>Frecuencia<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona frecuencia" value={frequency} onChange={e => handleInputChange(e, 'frequency')} options={typeFrequency} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">Descripción</IonLabel>
                            <IonTextarea name="description" placeholder={`${type === 'BENEFIT' ? 'Indica nombre de este bono o una nota' : 'Indica nombre de este descuento o nota'}`} onIonChange={handleInputChange} value={description}> </IonTextarea>
                        </IonItem>
                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                                Cancelar
                            </IonButton>
                            <SfnButton form="benefit-discount-form" label={id ? `Guardar cambios` : `${type == 'BENEFIT' ? 'Guardar beneficio' : 'Guardar descuento'}`} loading={cLoading || uLoading} />
                        </div>
                    </form>
                </Wrapper>
            </IonContent>
            {showEmployeeModal && <EmployeeModal showModal={showEmployeeModal} setShowModal={setShowEmployeeModal} />}

        </IonModal>
    )
}

export default BenefitDiscountModal;