import gql from 'graphql-tag'

export const createBankAccountMutation = gql`
	mutation createBankAccount($input: CreateBankAccount!) {
		createBankAccount(input: $input) {
			id
			type
			number
			alias
			bank
		}
	}
`

export const updateBankAccountMutation = gql`
	mutation updateBankAccount($input: UpdateBankAccount!) {
		updateBankAccount(input: $input) {
			id
			type
			number
			alias
			bank
		}
	}
`

export const deleteBankAccountMutation = gql`
	mutation deleteBankAccount($input: ID!) {
		deleteBankAccount(id: $input) {
			id
			type
			number
			alias
			bank
		}
	}
`
