import Kpi from "../../../../../../components/kpi/Kpi";
import { formatMoney } from "../../../../../../helpers/utils";

const Indicator = ({loading, enterprise, data}) => {
    return (<div className="flex mt-12 flex-col md:flex-row items-start justify-start md:items-center md:justify-center">
        <div className="flex flex-row">
            <Kpi textKlass="text-center" loading={loading} title={formatMoney(enterprise, data?.income)} description='Ingresos' />
            <h3 className="text-xl md:text-3xl font-bold text-danger mx-8">-</h3>
        </div>
        <div className="flex flex-row ">
            <Kpi textKlass="text-center" loading={loading} title={formatMoney(enterprise, data?.costOfGoodsSold)} description='Costos de bienes vendidos' />
            <h3 className="text-xl md:text-3xl font-bold text-danger  mx-8">-</h3>
        </div>
        <div className="flex flex-row">
            <Kpi textKlass="text-center" loading={loading} title={formatMoney(enterprise, data?.expense)} description='Gastos operativos' />
            <h3 className="text-xl md:text-3xl font-bold mx-8">=</h3>
        </div>
        <Kpi textKlass="text-center" loading={loading} title={formatMoney(enterprise, data?.netIncome)}  description='Ganancia neta' />
    </div>);
}

export default Indicator;