// prettier-ignore

import {useMutation} from '@apollo/client'
import {
  buyPackMutation,
} from './mutation';
import { getSubscription } from '../subscription/queries';

export const usePackService = () => {
  const [buyPack, { loading: bLoading, error: bError }] =
    useMutation(buyPackMutation, {
      errorPolicy: 'all',
      refetchQueries: [{ query: getSubscription }],
    });


  return {
    buyPack,
    bLoading,
    bError,
  };
};
