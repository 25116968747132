import gql from 'graphql-tag'

export const createProductMutation = gql`
	mutation createProduct($input: CreateProduct!) {
		createProduct(input: $input) {
			id
			name
			price
			sku
			description
			
			taxes {
				id
				name
				abbreviation
				
			}
			incomeAccount {
				id
				name
				customId
				description
				
			}
			expenseAccount {
				id
				name
				customId
				description
				
			}
		}
	}
`

export const updateProductMutation = gql`
	mutation updateProduct($input: UpdateProduct!) {
		updateProduct(input: $input) {
			id
			name
			price
			sku
			description
			
			taxes {
				id
				name
				abbreviation				
			}
			incomeAccount {
				id
				name
				customId
				description
				
			}
			expenseAccount {
				id
				name
				customId
				description
				
			}

		}
	}
`

export const deleteProductMutation = gql`
	mutation deleteProduct($input: ID!) {
		deleteProduct(id: $input) {
			id
		}
	}
`
