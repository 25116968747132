import { IonButton, IonContent, IonIcon, IonModal } from "@ionic/react";
import { closeOutline, fileTrayOutline, filterOutline, settingsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import './WarningPayrollModal.scss';



const WarningPayrollModal = ({ showModal, setShowModal, showSettingPayrollModal, setShowSettingPayrollModal }) => {
    const [payrolls, setPayrolls] = useState([]);

    const goToSettingPayroll = () => {
        setShowModal(false);
        setShowSettingPayrollModal(true);
    }
    return (
        <IonModal cssClass="sfn-warning-payroll-modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonContent>
                <div className="flex flex-col p-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-xl font-bold">Configura tu nómina</h3>
                        <IonIcon onClick={() => setShowModal(false)} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>
                    <p className="mt-8">Para calcular la nómina debes indicar su frecuencia y día de pago en el diálogo de configuración. Haz clic en el icono <IonIcon onClick={goToSettingPayroll} className="cursor-pointer" icon={settingsOutline} size="small" slot="icon-only" /> ubicado en la parte al lado derecho de la pestaña historial de nóminas para abrir el diálogo y configurar tu nómina. </p>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default WarningPayrollModal;