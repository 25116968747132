import { useQuery } from "@apollo/client";
import { IonSkeletonText, useIonAlert } from "@ionic/react";
import { useState } from "react";
import { getOneEnterpriseQuery } from "../../../../../../graphql/enterprise/queries";
import { getSubscription } from "../../../../../../graphql/subscription/queries";
import { translatePlans } from "../../../../../../helpers/catalogs";
import { parseDate } from "../../../../../../helpers/date";
import { isEmpty } from "../../../../../../helpers/utils";
import { updateSubscription } from "../../../../../../store/myPlan";
import BillingModal from "../billing-modal/BillingModal";
import CancelSubscriptionModal from "../cancel-subscription-modal/CancelSubscriptionModal";
import PaymentCardModal from "../payment-card-modal/PaymentCardModal";
import Placeholder from "../placeholder/Placeholder";
import "./Detail.scss";
const Detail = ({ authState }) => {
	const { data: enterprise, loading: loadingEnterprise } = useQuery(getOneEnterpriseQuery, { variables: { input: authState?.enterprise?.id } });
  
  const { data, loading } = useQuery(getSubscription, {
    onCompleted: (data) => {
      updateSubscription(JSON.parse(JSON.stringify(data.subscription)));
    },
  });

  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showPaymentCardModal, setShowPaymentCardModal] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);

  return (
    <div className="detail-plan-billing p-4">
      <h2 className="text-xl text-gray-blue font-bold mb-4">Resumen</h2>
      <ul className="w-full flex items-center md:flex-row flex-col">
        <li className={`flex flex-col items-left md:w-[80%] w-full`}>
          {(loading && loadingEnterprise) ? (
            <>
              <IonSkeletonText animated className="w-[200px] h-6 mb-2" />
              <IonSkeletonText animated className="w-[380px] h-6 mb-2" />
              <IonSkeletonText animated className="w-[270px] h-6 mb-2" />
            </>
          ) : (
            <>
              <p className="my-2">
                Plan <strong className="text-primary">{isEmpty(translatePlans[data?.subscription?.plan?.id])}</strong>
              </p>
              <p className="my-2">
                Fecha próxima de pago <strong> {enterprise?.enterprise?.nextPayment !== null ? isEmpty(parseDate(enterprise?.enterprise?.nextPayment).replace(/\//g, '-')): isEmpty(enterprise?.enterprise?.nextPayment)}</strong>
              </p>
              <p className="my-2">
                Método de pago <strong>{isEmpty(data?.subscription?.card?.number)}</strong>
              </p>
              <p className="my-2">
                DTE's restantes <strong>{data?.subscription?.dtes}</strong>
              </p>
            </>
          )}
        </li>
        <li
          className={`flex flex-col md:items-end items-start md:w-[20%] w-full md:mt-0 mt-4 text-primary text-end detail-list-action`}
        >
          {(loading && loadingEnterprise) ? (
            <>
              <IonSkeletonText animated className="w-[200px] h-6 mb-2" />
              <IonSkeletonText animated className="w-[160px] h-6 mb-2" />
              <IonSkeletonText animated className="w-[120px] h-6 mb-2" />
            </>
          ) : (
            <>
              {enterprise?.enterprise?.isSubscribed ? (
                <>
                  <p
                    onClick={(e) => setShowBillingModal(true)}
                    className="hover:cursor-pointer my-2 hover:underline"
                  >
                    Editar información de facturación
                  </p>
                  <p
                    onClick={(e) => setShowPaymentCardModal(true)}
                    className="hover:cursor-pointer my-2 hover:underline"
                  >
                    Administrar método de pago
                  </p>
                  <p
                    onClick={(e) => setShowCancelSubscription(true)}
                    className="hover:cursor-pointer my-2 hover:underline"
                  >
                    Cancelar plan
                  </p>
                </>
              ) : (
                <Placeholder />
              )}
            </>
          )}
        </li>
      </ul>

      {showBillingModal && (
        <BillingModal
          subscription={data?.subscription}
          showModal={showBillingModal}
          setShowModal={setShowBillingModal}
        />
      )}
      {showPaymentCardModal && (
        <PaymentCardModal
          subscription={data?.subscription}
          showModal={showPaymentCardModal}
          setShowModal={setShowPaymentCardModal}
        />
      )}
      {showCancelSubscription && (
        <CancelSubscriptionModal
          showModal={showCancelSubscription}
          setShowModal={setShowCancelSubscription}
        />
      )}
    </div>
  );
};

export default Detail;
