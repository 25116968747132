import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import './InputDate.scss';

const InputDate = ({disabled, value, handleInputChange, name, label="Fecha", icon=true, type="date", required=false, className = 'sm:w-36', handleInput, min, max}) => {
    return (
        <IonItem className={`sfn-input sfn-input-date sm:w-auto w-full ${className}`} mode="md" lines="none">
            <IonLabel position="stacked">{label}{(label && required )&& <span className="text-base text-danger">*</span>}</IonLabel>
                {icon && <IonIcon icon={calendarClearOutline}  slot="start" />}
                <IonInput
                disabled={disabled}
                min={min}
                max={max}
                name={name} 
                type={type}
                onIonChange={handleInputChange}
                onIonInput={handleInput} 
                value={value}
                required={required}/>   
            
        </IonItem >
    )
}

export default InputDate;