import { IonIcon } from "@ionic/react";


const IconText = ({icon, text, title, color="tertiary", className, customIcon=()=>{}}) => {
    return (
        <div className="flex items-center">
           {icon ?  <IonIcon color={color} slot="icon-only" icon={icon} className={className}/> : customIcon()}
            {title && (<h3 className="text-lg font-bold text-gray-blue">{title}</h3>)}
        </div>
    )
}

export default IconText;