import { IonModal,IonNote, IonContent, IonIcon, IonItem, IonLabel } from "@ionic/react";
import Wrapper from "../../wrapper/Wrapper";
import { Items } from './indexItems'
import { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { chevronDownOutline, chevronUpOutline, closeOutline } from 'ionicons/icons';

const IndexModal = ({showModal, setShowModal}) => {
    
    const onClose = () => {
        setShowModal(false);
    }

    const menuRef = useRef(null);
    const location = useLocation();
    const history = useHistory();
    const [appPages, setAppPages] = useState(Items);
    const handleSelect = (page) => {
        if (page.children) {
        const pages = [...appPages.map(appPage => appPage.title === page.title ? { ...appPage, open: !page.open } : { ...appPage, open: false })]
        setAppPages(pages);
        return
        }
        const pages = [...appPages.map(appPage => ({ ...appPage, open: false }))]
        setAppPages(pages);
        history.push(page.url);
        menuRef.current.close();
    }

    const goToHelp = (url) => {
    window.open(url, "_blank")
    }

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal-medium-height' onDidDismiss={onClose} >
            <IonContent>
                <Wrapper max='max-w-full'>
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">¿Cómo utilizar Symfonia?</h3>
                        <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>
                    <IonNote color="medium" className="text-base">Si tienes alguna duda sobre las funcionalidades de la aplicación, te ayudamos con video tutoriales y/o artículos en nuestro blog.</IonNote>
                    <div className="mb-4"></div>
                    {appPages.map((appPage, index) => {
                        return (
                            <div key={index} className='w-full'>
                                <IonItem onClick={() => handleSelect(appPage)} key={index} className={'ml-0' + location.pathname === appPage.url ? 'selected' : ''} lines="none" detail={false}>
                                    <IonLabel color='medium' className="font-bold text-sm">{appPage.title}</IonLabel>
                                    {appPage.children && <IonIcon slot="end" icon={appPage.open ? chevronUpOutline : chevronDownOutline} />}
                                </IonItem>
                                <div>
                                    {appPage.open && appPage.children?.map(children =>
                                        (children.url && <div key={children.url} className="py-0.5">
                                        <IonItem key={children.title} className={'text-sm bg-primary cursor-pointer hover:bg-primary' + location.pathname === children.url ? 'selected' : ''} onClick={() => goToHelp(children.url)} lines="none" detail={false}>
                                            <IonLabel color='medium' className='text-sm '>{children.title}</IonLabel>
                                        </IonItem>
                                        </div>)
                                    )}
                                </div>
                            </div>

                        );
                    })}
                </Wrapper>
           </IonContent>
        </IonModal>
    )
}

export default IndexModal;

