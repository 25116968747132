import {
  IonModal,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonTextarea,
  useIonToast,
  IonRadioGroup,
  IonRadio,
  IonContent,
  IonCheckbox,
} from "@ionic/react";
import {
  closeCircleOutline,
  closeOutline,
  ellipse,
  ellipseOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useForm } from "../../../hooks/useForm";
import Wrapper from "../../wrapper/Wrapper";
import Select from "react-select";
import { sfnSelectTheme } from "../../../theme/sfnSelectTheme";
import {
  currencies,
  countries,
  bankAccountTypes,
  phoneTypes,
  accountTypes,
  accountTypesName,
  months,
  areaCodes,
  departments,
  departmentsBilling,
  statesGuatemala,
} from "../../../helpers/catalogs";
import SfnButton from "../../button/Button";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../context/auth/AuthState";
import {
  defaultValue,
  formatSelectData,
  generateYears,
  generateYearsReports,
} from "../../../helpers/utils";
import { Tooltip } from "../../tooltip/Tooltip";
import InputCardNumber from "../../input-card-number/InputCardNumber";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";
import { useSubscriptionService } from "../../../graphql/subscription/services";
import { MyPlanStore, updateSubscription } from "../../../store/myPlan";
import {
  getAllBillingPlansQuery,
  getAllPlansQuery,
} from "../../../graphql/plan/queries";
import Accordion from "../../accordion/Accordion";
import { usePackService } from "../../../graphql/packs/services";
import Plan from "../../plan/Plan";
import Pack from "./components/pack/Pack";
import { AuthStore } from "../../../store/auth";
import { getSubscription } from "../../../graphql/subscription/queries";
import { getOneEnterpriseQuery } from "../../../graphql/enterprise/queries";

const initialState = {
  planId: "",
  billingData: {
    firstName: "",
    lastName: "",
    phone: { type: "", areaCode: "", number: "" },
    email: "",
    nit: "",
    address: "",
    countryISO2: "GT",
    city: "",
    state: "",
    postalCode: "",
  },
  card: {
    number: "",
    name: "",
    month: "",
    year: "",
    CVV: "",
    enterprise: "",
  },
};

const transformData = (pack, subscription) => {
  initialState.planId = subscription?.plan?.id;
  if (!pack || pack == null) return JSON.parse(JSON.stringify(initialState));
  let data = JSON.parse(JSON.stringify(subscription));
  data.billingData.phone =
    data.billingData?.phone === null
      ? initialState.billingData.phone
      : data.billingData?.phone;
  data.billingData.city =
    data.billingData?.city === null
      ? initialState.billingData.city
      : data.billingData?.city;

  if (data.billingData?.phone !== null) {
    data.billingData.phone.type = defaultValue(
      { value: "id", label: "name" },
      phoneTypes,
      data?.billingData?.phone.type
    );
    data.billingData.phone.areaCode = defaultValue(
      { value: "value", label: "label" },
      areaCodes,
      data?.billingData?.phone.areaCode
    );
  }
  if (data.billingData.city !== null) {
    data.billingData.city = departments.find(
      (department) => department?.label === data?.billingData?.city
    ) || '';
    data.billingData.state = data.billingData?.city?.value?.find(
      (state) => state.label === data.billingData.state
    ) || '';
  }
  data.billingData.countryISO2 = "GT";
  return {
    billingData: data?.billingData,
    card: initialState?.card,
    packId: pack?.id,
  };
};

const UpgradeMyPlanModal = ({ showModal, setShowModal }) => {
  const { subscription, pack } = MyPlanStore.useState((s) => s);
  const user = AuthStore.useState((s) => s.user);
  const { data: myPlans, loading: plansLoading } = useQuery(
    getAllBillingPlansQuery,
    { skip: pack || pack !== null }
  );
  const {
    formValues,
    handleInputChange,
    reset,
    removeCharacter,
    validateMaxLengthInput,
  } = useForm(transformData(pack, subscription));
  const { planId, billingData, card } = formValues;
  const [present] = useIonToast();
  const [isConsumer, setIsConsumer] = useState(
    formValues?.billingData?.nit === "C/F" ? true : false
  );
  const { createSubscription, cLoading } = useSubscriptionService();
  const { buyPack, bLoading } = usePackService();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (invalidForm()) return;
      let input = { ...JSON.parse(JSON.stringify(formValues)) };
      input.billingData.phone = {
        type: input?.billingData.phone.type.value,
        areaCode: input?.billingData.phone.areaCode.value,
        number: input?.billingData.phone.number,
      };
      input.billingData.firstName = input?.billingData?.firstName?.trim();
      input.billingData.lastName = input?.billingData?.lastName?.trim();
      input.billingData.city =
        departmentsBilling[input?.billingData.city.label]?.ISOCode?.trim();
      input.billingData.state = input?.billingData?.state?.value?.trim();
      input.card.month = input?.card?.month?.value;
      input.card.year = input?.card?.year?.value;
      input.card.number = input?.card?.number?.replaceAll(" ", "");
      input.card.enterprise = getCreditCardNameByNumber(
        input.card.number
      )?.toUpperCase();

      let resp;
      if (pack === null || !pack) {
        resp = await createSubscription({
          variables: { input },
          refetchQueries: [
            { query: getSubscription },
            { query: getOneEnterpriseQuery },
          ],
        });
      } else {
        resp = await buyPack({
          variables: { input },
          refetchQueries: [
            { query: getSubscription },
            { query: getOneEnterpriseQuery },
          ],
        });
      }

      if (resp?.errors) {
        present({
          message: "Oops! ha ocurrido un error, intentalo nuevamente 🤔",
          color: "danger",
          mode: "md",
          duration: 4000,
        });
        return;
      } else {
        present({
          message: "Compra realizada!",
          color: "success",
          mode: "md",
          duration: 4000,
        });
        updateSubscription(input);
      }
      e.target.reset();
      onClose();
    } catch (error) {
      console.log(error);
      present({
        message: "Oops! ha ocurrido un error, intentalo nuevamente 🤔",
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }
  };

  const onClose = async () => {
    setIsConsumer(false);
    reset({ ...initialState });
    setShowModal(false);
  };

  function setNameCard() {
    handleInputChange(
      `${billingData.firstName} ${billingData.lastName}`.trim(),
      "card.name"
    );
  }

  useEffect(() => {
    if (billingData?.city === null || billingData?.state === null) return;
    handleInputChange(
      departmentsBilling[billingData?.city?.label]?.postalCode,
      "billingData.postalCode"
    );
  }, [billingData?.city, billingData?.state]);

  function invalidForm() {
    const cards = ["visa", "mastercard"];
    const cardNumber = card.number.replaceAll(" ", "");
    const typeCard = getCreditCardNameByNumber(cardNumber)?.toLowerCase();
    if(billingData?.firstName === '' || billingData?.firstName === null) {
      present({
        message: "Debe ingresar tu nombre!",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if(billingData?.lastName === '' || billingData?.lastName === null) {
      present({
        message: "Debe ingresar tu apellido!",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if(billingData?.email === '' || billingData?.email === null) {
      present({
        message: "Debe ingresar tu correo electrónico!",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if(billingData?.phone?.number === '' || billingData?.phone?.number === null) {
      present({
        message: "Debe ingresar tu número de teléfono!",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (billingData.phone?.type === "" || billingData.phone?.type == null) {
      present({
        message: `Debe seleccionar un tipo de teléfono!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (
      billingData.phone?.areaCode === "" ||
      billingData.phone?.areaCode == null
    ) {
      present({
        message: `Debe seleccionar un área de código!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if(billingData?.address === '' || billingData?.address === null) {
      present({
        message: "Debe ingresar tu dirección!",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (billingData?.city?.value === "" || billingData?.city == null) {
      present({
        message: `Debe seleccionar un departamento!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (billingData?.state?.value === "" || billingData?.state == null) {
      present({
        message: `Debe seleccionar un municipio!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (card?.name === "" || card?.name == null) {
      present({
        message: `Debe ingresar nombre de la tarjeta!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (card?.CVV === "" || card?.CVV == null) {
      present({
        message: `Debe ingresar cvv de la tarjeta!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (card?.month?.value === "" || card?.month == null) {
      present({
        message: `Debe seleccionar un mes en la cual expira tu tarjeta!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (card?.year?.value === "" || card?.year == null) {
      present({
        message: `Debe seleccionar un año en la cual expira tu tarjeta!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    if (!cards.includes(typeCard)) {
      present({
        message: `Symfonia te permite realizar pagos a través de tus tarjetas VISA y MASTERCARD!`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    // if (!isValid(cardNumber)) {
    //     present({ message: `Número de tarjeta incorrecta!`, color: "warning", mode: "md", duration: 4000 })
    //     return true
    // }
    // if (!isExpirationDateValid(card?.month?.value, card?.year?.value)) {
    //     console.log(card?.month?.value, card?.year?.value)
    //     present({ message: `La fecha de expiración de la tarjeta es incorrecta!`, color: "warning", mode: "md", duration: 4000 })
    //     return true
    // }
    // if (!isSecurityCodeValid(cardNumber, card?.ccv)) {
    //     present({ message: `El código de seguridad de la tarjeta es incorrecta!`, color: "warning", mode: "md", duration: 4000 })
    //     return true
    // }
    return false;
  }

  return (
    <IonModal
      isOpen={showModal}
      cssClass="sfn-modal"
      onDidDismiss={() => setShowModal(false)}
    >
      <IonContent>
        <Wrapper>
          <IonIcon
            onClick={onClose}
            icon={closeOutline}
            className="flex ml-auto cursor-pointer modal-close-button"
          ></IonIcon>
          <h3 className="text-center text-xl font-bold">
            {pack !== null && pack ? "Comprar paquete" : "Comprar plan"}
          </h3>
          <p className="text-center text-base">
            {pack !== null && pack
              ? "Completa el siguiente formulario para realizar la compra tu paquete."
              : "Selecciona el plan al que deseas y brindános tu información de pago."}
          </p>

          <form
            id="account-form"
            onSubmit={handleSubmit}
            className="h-full p-2"
          >
            {(pack === null || !pack) && (
              <Plan
                loading={plansLoading}
                value={planId}
                onChange={handleInputChange}
                plans={myPlans?.plans}
              />
            )}
            {pack !== null && pack && <Pack {...pack} />}
            <div className="flex flex-col justify-between my-5">
              <h2 className="text-xl font-bold">Información de facturación</h2>
              <IonItem className="sfn-input" mode="md" lines="none">
                <IonLabel position="stacked">
                  No. de identificación tributaria
                  <span className="text-base text-danger">*</span>
                </IonLabel>
                <IonInput
                  name="billingData.nit"
                  type="text"
                  maxlength="13"
                  placeholder="Indica No. de identificación tributaria"
                  onKeyDown={removeCharacter}
                  onKeyUp={removeCharacter}
                  onInput={removeCharacter}
                  onIonChange={removeCharacter}
                  value={billingData.nit}
                  autocomplete="off"
                  disabled={isConsumer}
                  required={!isConsumer}
                ></IonInput>
              </IonItem>
              <div className="flex items-center my-4">
                <IonCheckbox
                  className="sfn-checkbox mr-2"
                  checked={isConsumer}
                  onIonChange={(e) => {
                    handleInputChange(
                      e.detail.checked ? "C/F" : "",
                      "billingData.nit"
                    );
                    setIsConsumer(e.detail.checked);
                  }}
                />
                <IonLabel
                  onClick={(e) => {
                    handleInputChange(
                      isConsumer ? "C/F" : "",
                      "billingData.nit"
                    );
                    setIsConsumer(!isConsumer);
                  }}
                  className="hover:cursor-pointer mr-1"
                >
                  Consumidor final
                </IonLabel>
              </div>
              <Accordion
                hideButton={
                  pack == null || !pack || !user?.enterprise?.isSubscribed
                }
                notCollapse={
                  pack == null || !pack || !user?.enterprise?.isSubscribed
                }
                header={
                  <IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">
                      Nombre<span className="text-base text-danger">*</span>
                    </IonLabel>
                    <IonInput
                      name="billingData.firstName"
                      type="text"
                      placeholder="Indica tu nombre"
                      onIonChange={(e) => {
                        handleInputChange(e);
                        setNameCard();
                      }}
                      value={billingData?.firstName}
                      autocomplete="off"
                    ></IonInput>
                  </IonItem>
                }
              >
                <IonItem className="sfn-input" mode="md" lines="none">
                  <IonLabel position="stacked">
                    Apellido<span className="text-base text-danger">*</span>
                  </IonLabel>
                  <IonInput
                    name="billingData.lastName"
                    type="text"
                    placeholder="Indica tu apellido"
                    onIonChange={(e) => {
                      handleInputChange(e);
                      setNameCard();
                    }}
                    value={billingData.lastName}
                    autocomplete="off"
                  ></IonInput>
                </IonItem>
                <IonItem className="sfn-input mb-4" mode="md" lines="none">
                  <IonLabel position="stacked">
                    Correo electrónico
                    <span className="text-base text-danger">*</span>
                  </IonLabel>
                  <IonInput
                    name="billingData.email"
                    type="email"
                    placeholder="Indica el correo electrónico"
                    onIonChange={handleInputChange}
                    value={billingData.email}
                    autocomplete="off"
                  />
                </IonItem>
                <div className="flex flex-col my-4">
                  <p className={`text-left text-sm mb-1`}>
                    Teléfono<span className="text-base text-danger">*</span>
                  </p>
                  <div className="flex justify-between items-center">
                    <Select
                      value={billingData.phone.type}
                      onChange={(e) =>
                        handleInputChange(e, `billingData.phone.type`)
                      }
                      placeholder=""
                      options={formatSelectData(
                        { value: "id", label: "name" },
                        phoneTypes
                      )}
                      className="sfn-select w-60 z-10"
                      menuPosition="fixed"
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      styles={sfnSelectTheme}
                    />
                    <Select
                      value={billingData.phone.areaCode}
                      onChange={(e) =>
                        handleInputChange(e, `billingData.phone.areaCode`)
                      }
                      placeholder=""
                      options={areaCodes}
                      className="sfn-select w-40 z-10"
                      menuPosition="fixed"
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      styles={sfnSelectTheme}
                    />
                    <IonItem className="sfn-input" mode="md" lines="none">
                      <IonInput
                        name="billingData.phone.number"
                        type="number"
                        maxlength="8"
                        placeholder="5555-5555"
                        value={billingData.phone.number}
                        onKeyDown={removeCharacter}
                        onKeyUp={removeCharacter}
                        onInput={removeCharacter}
                        onIonChange={removeCharacter}
                        autocomplete="off"
                      ></IonInput>
                    </IonItem>
                  </div>
                </div>
                <IonItem className="sfn-input" mode="md" lines="none">
                  <IonLabel position="stacked">
                    Dirección<span className="text-base text-danger">*</span>
                  </IonLabel>
                  <IonInput
                    name="billingData.address"
                    type="text"
                    placeholder="Indica tu dirección de facturación"
                    onIonChange={handleInputChange}
                    value={billingData.address}
                    autocomplete="off"
                  ></IonInput>
                </IonItem>
                <div className="flex w-full md:flex-row flex-col">
                  <div className="my-4 w-full">
                    <p className={`text-sm mb-2 hover:text-secondary w-80`}>
                      Departamento
                      <span className="text-base text-danger">*</span>
                    </p>
                    <Select
                      placeholder="Seleccione departamento"
                      menuPosition="fixed"
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      value={billingData?.city}
                      isClearable={true}
                      onChange={(e) => handleInputChange(e, "billingData.city")}
                      options={departments}
                      className="sfn-select md:mr-4 mr-0 z-0"
                      styles={sfnSelectTheme}
                    />
                  </div>
                  <div className="my-4 w-full">
                    <p className={`text-sm mb-2 hover:text-secondary w-80`}>
                      Municipio<span className="text-base text-danger">*</span>
                    </p>
                    <Select
                      placeholder="Seleccione municipio"
                      menuPosition="fixed"
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      value={billingData.state}
                      isClearable={true}
                      onChange={(e) =>
                        handleInputChange(e, "billingData.state")
                      }
                      options={billingData.city?.value}
                      className="sfn-select z-0"
                      styles={sfnSelectTheme}
                    />
                  </div>
                </div>
              </Accordion>
            </div>

            <div className="flex flex-col my-5">
              <h2 className="text-xl font-bold">Método de pago</h2>
              <IonItem
                className={`sfn-input mr-4 w-full`}
                mode="md"
                lines="none"
              >
                <IonLabel position="stacked">
                  Nombre en tarjeta
                  <span className="text-base text-danger">*</span>
                </IonLabel>
                <IonInput
                  name="card.name"
                  type="text"
                  placeholder="Indica el nombre en tu tarjeta"
                  onIonChange={handleInputChange}
                  value={card.name}
                  autocomplete="off"
                ></IonInput>
              </IonItem>
              <div className="flex w-full justify-between md:flex-row flex-col">
                <InputCardNumber
                  klass="md:w-[50%] w-full"
                  label="Número de tarjeta"
                  placeholder="Indica número de tarjeta"
                  name="card.number"
                  type="text"
                  value={card.number}
                  onChange={handleInputChange}
                  autocomplete="off"
                />
                <IonItem
                  className={`sfn-input md:w-[50%] md:ml-4 ml-0  w-full`}
                  mode="md"
                  lines="none"
                >
                  <div className="flex flex-row items-center align-center">
                    <IonLabel className="mb-4 mr-1" position="stacked">
                      CVC<span className="text-base text-danger">*</span>
                    </IonLabel>
                    <div className="mt-2 text-base">
                      <Tooltip label="Número de 4 dígitos que se encuentra en el reverso de tu tarjeta." />
                    </div>
                  </div>
                  <IonInput
                    name="card.CVV"
                    placeholder="0000"
                    type="number"
                    maxlength="4"
                    onInput={validateMaxLengthInput}
                    onIonChange={validateMaxLengthInput}
                    value={card.CVV}
                    autocomplete="off"
                  >
                  </IonInput>
                </IonItem>
              </div>
              <div className="flex md:flex-row flex-col w-full mt-3 items-center">
                <div className="w-full">
                  <p className="text-sm h-[36px] flex items-center hover:text-secondary w-80">
                    Expira<span className="text-base text-danger">*</span>
                  </p>
                  <div className="flex md:flex-row flex-col">
                    <Select
                      placeholder="Selecciona mes"
                      value={card.month}
                      onChange={(e) => handleInputChange(e, "card.month")}
                      options={months}
                      className="sfn-select md:w-[50%] w-full md:mr-4 mr-0 md:mb-0 mb-4"
                      styles={sfnSelectTheme}
                    />
                    <Select
                      placeholder="Selecciona el año"
                      value={card.year}
                      onChange={(e) => handleInputChange(e, "card.year")}
                      options={generateYears(new Date(), 10)}
                      className="sfn-select md:w-[50%] w-full  md:mb-0 mb-4"
                      styles={sfnSelectTheme}
                    />
                  </div>
                </div>
              </div>

              <p className="text-gray-2 mt-8">
                Autoriza a Symfonia a debitar los pagos de esta tarjeta enlazada
                a la cuenta de la institución bancaria que la extiende.
              </p>
            </div>
            <div className="flex flex-col md:flex-row justify-end items-center mt-4">
              <IonButton
                className="sfn-button"
                shape="round"
                fill="none"
                onClick={onClose}
              >
                Cancelar
              </IonButton>
              <SfnButton
                form="account-form"
                label="Realizar compra"
                loading={cLoading || bLoading}
              />
            </div>
          </form>
        </Wrapper>
      </IonContent>
    </IonModal>
  );
};

export default UpgradeMyPlanModal;
