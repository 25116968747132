import gql from 'graphql-tag';

export const getNetIncomeQuery = gql`
  query netIncome {
    netIncomeIndicators {
      expenses {
        label
        value
      }
      income {
        label
        value
      }
      netIncome {
        label
        value
      }
    }
  }
`;

export const getNetIncomeChartQuery = gql`
  query netIncomeChart($longAgo: EnumLongAgo) {
    netIncomeChart(longAgo: $longAgo) {
      resume {
        label
        value
      }
      expenses {
        label
        value
      }
      income {
        label
        value
      }
      netIncome {
        label
        value
      }
    }
  }
`;
export const getProfitAndLossQuery = gql`
  query profitAndLoss($longAgo: EnumLongAgo) {
    profitAndLoss(longAgo: $longAgo) {
      resume {
        label
        value
      }
      profit {
        label
        value
      }
      losess {
        label
        value
      }
    }
  }
`;
export const getExpenseChartQuery = gql`
  query expenseChart {
    expenseChart {
      id
      label
      value
      percent
    }
  }
`;

export const getNetIncomeLastFourYearsQuery = gql`
  query netIncomeLastFourYears {
    netIncomeLastFourYears {
      year
      income
      expenses
      netIncome
    }
  }
`;
