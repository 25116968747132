import gql from "graphql-tag";

export const remainingForCreditNoteQuery = gql`
  query remainingForCreditNote($invoiceId: ID!) {
    remainingForCreditNote(invoiceId: $invoiceId) {
      product {
        id
        name
      }
      amount
      itemId
    }
  }
`;

export const getOneCreditNote = gql`
  query note($input: ID!) {
    note(id: $input) {
      amount
      description
      detail {
        product {
          id
          name
        }
        amount
      }
    }
  }
`;

export const downloadNote = gql`
query downloadNote($input: ID!) {
  downloadNote(id: $input)
}`;
