import gql from 'graphql-tag'

export const getAllEnterprisesQuery = gql `
	query enterprises($options: Options, $page: Int) {
		enterprises(options: $options, page: $page) {
			id
			name
			bill {
				phone {
					type
					areaCode
					number
				}
				nit
				state
				address
			}
			accounting {
				ISRPayment
				ISRRetention
				fiscalMonth
				dayMonth
			}
			data {
				picture
				footer
			}			
			
			country {
				id
				name
			}
			role {
				id
				name
			}
			currency {
				id
				name
			}
			bussinesType {
				id
				name
			}
			size {
				id
				name
			}
			removable
			editable
			status
		}
	}
`

export const getOneEnterpriseQuery = gql `
	query enterprise($input: ID!) {
		enterprise(id: $input) {
			id
			name
			bill {
				phone {
					type
					areaCode
					number
				}
				nit
				state
				address
				zip
				city
				issuer
				issuerConfig {
					frases {
						tipo
						escenario
					}
				}
				name
				comercialName
				email
			}
			accounting {
				ISRPayment
				ISRRetention
				fiscalMonth
				dayMonth
			}
			data {
				picture
				footer
			}			
			
			country {
				id
				name
			}
			currency {
				id
				name
			}
			bussinesType {
				id
				name
			}
			size {
				id
				name
			}
			plan {
				id
				name
			}
			removable
			editable
			daysToExpire
			expiratedAt
			nextPayment
			isSubscribed
			isEnabled
		}
	}
`
export const exchangeRateQuery = gql`
	query exchangeRate {
		exchangeRate {
		id
		name
		plural
		code
		symbol
		rate
		}
	}`


	export const getOneEditEnterpriseQuery = gql `
	query enterprise($input: ID!) {
		enterprise(id: $input) {
			id
			name
			bill {
				phone {
					type
					areaCode
					number
				}
				nit
				state
				address
				zip
				city
				issuer
				name
				comercialName
				email
			}
			accounting {
				ISRPayment
				ISRRetention
				fiscalMonth
				dayMonth
			}
			data {
				picture
				footer
			}			
			
			country {
				id
				name
			}
			currency {
				id
				name
			}
			bussinesType {
				id
				name
			}
			size {
				id
				name
			}
			plan {
				id
				name
			}
			removable
			editable
		}
	}
`