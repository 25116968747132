export const ExpensesOnboarding = [
  {
    title: "¿Cómo registrar tus gastos?",
    description:
      "Para registrar una factura de gastos debes agregar primero a tus proveedores y sus productos o servicios  para luego asociarlos a las factura de gastos.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Gastos1-min.gif",
    alt: "Registrar gastos",
  },
  {
    title: "Agrega recibos para tus gastos no deducibles",
    description:
      "Si hay gastos en los que no pagas impuestos puedes agregarlos en recibos y Symfonia los clasificará como gastos no deducibles en tus reportes financieros.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Gastos2-min.gif",
    alt: "Agrega recibos para tus gastos",
  },
  {
    title: "Registra los pagos  que le hagas a tus proveedores",
    description:
      "Registra el pago parcial o total realizado a tu proveedor por la compra de un producto o servicio para llevar el control detallado de tus gastos.",
    image: "https://cdn01.prd.symfonia.io/Gifs/Gastos2-min.gif",
    alt: "Registra los pagos",
  },
];
