import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { PublicRoutes } from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { useAuth } from '../context/auth/AuthState';
import usePageTracking  from '../components/google-analytics/usePageTracking'

/* Theme variables */
import '../theme/global.scss';
import '../theme/variables.scss';
import {  useEffect } from 'react';
import Splash from '../components/splash/Splash';

import ReactPixel from 'react-facebook-pixel';
import { AuthStore } from '../store/auth';


const App = () => {
  const { authState, whoiam } = useAuth();
  const authStore = AuthStore.useState(s => s)
  usePageTracking()
  useEffect(() => {
    whoiam();
  }, []);

  if (authStore.isAuthenticated && authStore.checking) {
    return <Splash></Splash>;
  }

  return (
    // <IonApp>
      // <IonReactRouter>
      <>
        {!authStore.isAuthenticated ? <PublicRoutes authStore={authStore} /> : <PrivateRoutes authStore={authStore} />}
      </>
      // </IonReactRouter>
    // </IonApp>
  );
};

export default App;
