import './Wrapper.scss';

const Wrapper = ({children, max = ''}) => {
    return (
    <div id="wrapper" className="content">
        <div className={`wrapper ${max}`}>
           {children}
        </div>
    </div>
    )
}

export default Wrapper;