import './SearchTable.scss';
import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, useIonToast } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';

const SearchTable = ({ tableManager }) => {
    const { searchApi } = tableManager;
    const { searchText, setSearchText } = searchApi;

    return (
        <div className="search-table flex w-full items-center justify-center my-4">
            <IonIcon className=" font-bold mr-4" icon={searchOutline} size="small" slot="icon-only"/>
            <IonItem className="input-search-table w-full" mode="md" lines="none">
                <IonInput
                    name="name"
                    type="text"
                    placeholder="Buscar"
                    onIonChange={e => (setSearchText(e.target.value))}
                    value={searchText}
                    required> </IonInput>
            </IonItem>
        </div>
    );
}

export default SearchTable;