import { useQuery } from '@apollo/client';
import { IonButton, IonSkeletonText } from '@ionic/react';
import { getPacks } from '../../../../../../graphql/packs/queries';
import { formatMoney } from '../../../../../../helpers/utils';
import { setShowUpgradeMyPlanModal, updatePackage } from '../../../../../../store/myPlan';
import './PackageDTE.scss';

const packagedtes = [{
    title: "5 DTE's",
    price: "10",
},
{
    title: "25 DTE's",
    price: "45",
}, {
    title: "50 DTE's",
    price: "80",
}, {
    title: "100 DTE's",
    price: "15",
}
]
const PackageDTE = ({ authState }) => {
    const { data, loading, error } = useQuery(getPacks);

    function handlePack(pack) {
        updatePackage(pack)
        setShowUpgradeMyPlanModal(true);
    }
    return (
        <div className="flex w-full flex-col px-4 pt-10 pb-4 package-dte">
            <div className="flex md:flex-row flex-col w-full items-center justify-between mb-4">
                <h2 className="text-xl text-gray-blue font-bold md:mb-0 mb-2">Paquetes de DTE's</h2>
                {authState?.enterprise?.isSubscribed && <IonButton color="secondary" className="sfn-button sm:w-60 w-full normal-case font-bold" fill="solid" shape="round" onClick={() => { updatePackage(null); setShowUpgradeMyPlanModal(true) }}>
                    Cambiar plan
                </IonButton>}
            </div>
            <p className="text-base mb-12">Puedes comprar paquetes de DTE's (Documento Tributario Electrónico) a tu plan actual para que puedas emitir <strong>facturas electrónicas, anulación de facturas y notas de crédito.</strong></p>

            <div className="flex w-full mt-8 md:justify-center justify-start flex-wrap items-center">
                {loading && [1, 2, 3, 4].map((item, index) =>
                    <div lines="none" className="flex flex-col items-center justify-center m-4" key={index}>
                        <div className={`flex flex-col sfn-plan-card   md:w-[246px] md:h-[224px] w-full h-full items-center justify-center mb-4`}>
                            <div className="flex flex-col text-center justify-end items-center w-full h-[100px]">
                                <IonSkeletonText animated className="w-[200px] h-[20px]" />
                                <IonSkeletonText animated className="w-[100px] h-[20px]" />
                            </div>
                            <IonSkeletonText animated className="mt-8 w-[80%] rounded-full h-[40px]" />
                        </div>
                    </div>
                )}
                {!loading && data?.packs?.map(({ id, name, price, currency, symbol, isSuggested }, index) => (
                    <div key={id} className={`package-dte-card md:w-[246px] md:h-[224px] mx-10 w-full m-4 h-full flex p-4 flex-col items-center justify-center ${isSuggested && 'border-2 border-primary'}`}>
                        {isSuggested && <p className="bg-primary-hover text-primary text-center px-4 py-2 mb-4 rounded-full">Recomendado</p>}
                        <h4 className="text-3xl text-center font-bold">{name?.replace('Paquete de', '')}</h4>
                        <p className="text-xl text-center mt-2 mb-8 text-primary font-bold">{formatMoney({ currency: { code: currency, symbol } }, price)}</p>
                        <IonButton color="primary" className="sfn-button sm:w-60 w-full normal-case font-bold" fill={isSuggested ? 'solid' : 'outline'} shape="round" onClick={() => { handlePack({ id, name, price, currency, symbol }) }}>
                            Comprar {name?.replace('Paquete de', '')}
                        </IonButton>
                    </div>
                ))}
            </div>
        </div>);
}

export default PackageDTE;