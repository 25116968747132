import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  useIonToast,
} from "@ionic/react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useInvoice } from "../../../../hooks/useInvoice";
import { addOutline, trashOutline } from "ionicons/icons";
import { sfnSelectTheme } from "../../../../theme/sfnSelectTheme";
import Select from "react-select";
import { transactionCategoriesQuery } from "../../../../graphql/transaction/queries";
import { currencyConverterQuery } from "../../../../graphql/currency/queries";
import { useCallback, useEffect, useState } from "react";

const SplitAmountDetail = ({
  handleInputChange,
  elements,
  index,
  addLine,
  removeLine,
  type,
  amount,
  splitButton = false,
  currency,
  getTotal = () => {},
  taxes,
}) => {
  const { data, loading, error } = useQuery(transactionCategoriesQuery, {
    variables: { type: type.value },
  });
  const { formatSelectTaxes } = useInvoice();
  const [present] = useIonToast();
  const client = useApolloClient();

  const getCurrencyConverter = useCallback(async () => {
    const validCurrency = Object.keys(currency || {});

    if (!elements.accountId) return;

    if (!currency) return;

    if (!validCurrency.length) return;

    if (
      (elements?.accountId?.value?.currency?.id ||
        elements?.accountId?.value2?.currency?.id) === currency.id
    )
      return;

    const { data, loading, error } = await client.query({
      query: currencyConverterQuery,
      variables: {
        from:
          elements?.accountId?.value?.currency?.id ||
          elements?.accountId?.value2?.currency?.id,
        to: currency.id,
      },
      // fetchPolicy: 'no-cache'
    });
    if (!loading && !error) {
      handleInputChange(data.currencyConverter, `items[${index}].exchangeRate`);
    }
  }, [client, currency, handleInputChange, index, elements]);

  useEffect(() => {
    getCurrencyConverter();
  }, [currency, elements.accountId, getCurrencyConverter]);

  const changeTaxes = (e, idx) => {
    if (!elements.children) return;

    let tmp = elements.children.reduce(
      (a, c) => (c?.accountId ? a + c.accountId.value.rate / 100 : 0),
      1
    );

    let price = elements.amount / tmp;

    elements.children = elements.children.map((i) => {
      if (!i.accountId) return 0;
      i.amount = Number(price * (i.accountId.value.rate / 100)).toFixed(2);
      i.amount = Number(i.amount);

      return i;
    });

    let total = Number(elements.children.reduce((a, c) => a + c.amount, 0));
    handleInputChange(elements.children, `items[${index}].children`);
    handleInputChange(total, `items[${index}].tmpTaxAmount`);
  };

  const handleInputAmountChange = (e) => {
    let { value } = e.target;
    if (value < 0) {
      handleInputChange(0, `items[${index}].amount`);
    }
    handleInputChange(e, `items[${index}].amount`);
    changeTaxes();
    getTotal();
  };

  const handleInputChildAmountChange = (e, idx) => {
    let { value } = e.target;
    if (value < 0) {
      handleInputChange(0, `items[${index}].children[${idx}].amount`);
    }
    handleInputChange(e, `items[${index}].children[${idx}].amount`);
    let total = elements.children.reduce((a, c) => a + Number(c.amount), 0);
    if (total > parseFloat(elements.amount)) {
      e.target.value = 0;
      present({
        message: `El valor del impuesto no puede ser mayor al monto`,
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      handleInputChange(e, `items[${index}].children[${idx}].amount`);
      return;
    }
    handleInputChange(total, `items[${index}].tmpTaxAmount`);
  };

  const handleInputTaxChange = (e, idx) => {
    console.log(e);
    handleInputChange(e, `items[${index}].children[${idx}].accountId`);
    changeTaxes();
  };

  const handleInputParentAmountChange = (e) => {
    let value = e.target.value;
    if (value < 0) {
      handleInputChange(0, "amount");
      handleInputChange(0, `items[0].amount`);
      return;
    }
    handleInputChange(value, "amount");
    handleInputChange(value, `items[0].amount`);
    changeTaxes();
  };

  return (
    <div className={`flex flex-col`}>
      <div className="flex md:flex-row flex-col md:justify-between md:items-end">
        <IonItem
          className="sfn-input mr-8 w-full md:w-60"
          mode="md"
          lines="none"
        >
          <IonLabel position="stacked">
            Monto<span className="text-base text-danger">*</span>
          </IonLabel>
          {splitButton && (
            <IonInput
              name="amount"
              placeholder="0.00"
              type="number"
              step=".01"
              onIonChange={handleInputParentAmountChange}
              value={amount}
              required
            >
              <IonNote className="font-bold ml-2">
                {currency?.code || ""}
              </IonNote>
            </IonInput>
          )}
          {!splitButton && (
            <IonInput
              name={`items[${index}].amount`}
              placeholder="0.00"
              type="number"
              step=".01"
              onIonChange={handleInputAmountChange}
              value={elements.amount}
              required
            >
              <IonNote className="font-bold ml-2">
                {currency?.code || ""}
              </IonNote>
            </IonInput>
          )}
        </IonItem>
        <div className="w-full mt-4">
          <p className="text-sm mb-2 hover:text-secondary">
            Categoría<span className="text-base text-danger">*</span>
          </p>
          <Select
            isLoading={loading}
            options={data?.transactionCategories}
            styles={{ ...sfnSelectTheme }}
            value={elements.accountId}
            onChange={(e) => handleInputChange(e, `items[${index}].accountId`)}
            placeholder="Seleccione una categoría"
            className="sfn-select mb-1"
            required
          />
        </div>
        {elements.exchangeRate && (
          <IonItem
            className="sfn-input ml-8 w-full md:w-80"
            mode="md"
            lines="none"
          >
            <IonLabel position="stacked">
              Tasa cambio<span className="text-base text-danger">*</span>
            </IonLabel>
            <IonInput
              name={`items[${index}].exchangeRate`}
              placeholder="0.00"
              type="text"
              onIonChange={(e) =>
                handleInputChange(e, `items[${index}].exchangeRate`)
              }
              value={elements.exchangeRate}
              required
            ></IonInput>
          </IonItem>
        )}
        {!splitButton && (
          <IonIcon
            onClick={() => removeLine(index)}
            icon={trashOutline}
            className="md:w-8 w-full h-8 md:ml-8 md:mb-2 md:mt-0 mt-4 cursor-pointer"
          />
        )}
        {splitButton && (
          <IonButton
            disabled={amount < 1}
            onClick={() => addLine()}
            fill="clear"
            shape="round"
            size="small"
            className="sfn-button md:ml-8 md:-mb-1"
            color="primary"
          >
            Dividir monto
          </IonButton>
        )}
      </div>

      <div className="flex justify-between w-full">
        {elements.children?.length > 0 && (
          <IonNote>
            Impuestos: {currency?.code || ""} {elements.tmpTaxAmount}
          </IonNote>
        )}
        <IonButton
          onClick={() => addLine(index)}
          fill="clear"
          shape="round"
          size="small"
          className="sfn-button ml-auto"
          color="primary"
        >
          <IonIcon slot="start" icon={addOutline} />
          Incluir {elements.children?.length > 0 && "otro"} impuesto
        </IonButton>
      </div>

      {elements.children?.length > 0 &&
        elements.children?.map((ch, idx) => {
          return (
            <div
              className={`flex flex-col ${
                elements.children?.length == idx + 1 && "mb-16"
              } ${idx === 0 && "-mt-10"}`}
              key={idx}
            >
              <div className="flex ml-4 md:flex-row flex-col md:justify-between md:items-end">
                <IonItem
                  className={`sfn-input mr-8 w-full md:w-60`}
                  mode="md"
                  lines="none"
                >
                  <IonLabel position="stacked">
                    Monto<span className="text-base text-danger">*</span>
                  </IonLabel>

                  <IonInput
                    name={`items[${index}].children[${idx}].amount`}
                    placeholder="0.00"
                    type="number"
                    step=".01"
                    onIonChange={(e) => handleInputChildAmountChange(e, idx)}
                    value={ch.amount}
                    required
                  >
                    <IonNote className="font-bold ml-2">
                      {currency?.code || ""}
                    </IonNote>
                  </IonInput>
                </IonItem>
                <div className="w-full mt-4">
                  <p className="text-sm mb-2 hover:text-secondary">
                    Impuesto<span className="text-base text-danger">*</span>
                  </p>
                  <Select
                    isLoading={loading}
                    options={formatSelectTaxes(taxes || [])}
                    styles={{ ...sfnSelectTheme }}
                    value={ch.accountId}
                    onChange={(e) => handleInputTaxChange(e, idx)}
                    placeholder="Seleccione un impuesto"
                    className="sfn-select mb-1"
                    required
                  />
                </div>
                <IonIcon
                  onClick={(e) => removeLine(index, idx)}
                  icon={trashOutline}
                  className="md:w-8 w-full h-8 md:ml-8 md:mb-2 md:mt-0 mt-4 cursor-pointer"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SplitAmountDetail;
