import gql from 'graphql-tag'

export const signInMutation = gql`
	mutation signIn($username: String!, $password: String!) {
		signIn(username: $username, password: $password) {
			token
		}
	}
`

export const signUpMutation = gql`
	mutation signUp($input: SignUp) {
		signUp(input: $input) {
			message
		}
	}
`