import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  useIonToast,
} from "@ionic/react";
import "./ResetPasswordPage.scss";
import { useHistory } from "react-router-dom";
import language from "./language.json";
import { useForm } from "../../hooks/useForm";
import { useState } from "react";
import Wrapper from "../../components/wrapper/Wrapper";

const initialState = {
  email: "",
};
const text = language.es;
const ResetPasswordPage = ({ match }) => {
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const { email } = formValues;
  let step = parseInt(1);
  const [toast] = useIonToast();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
    goToStep(step + 1);
  };

  const goToStep = (id) => {
    history.push(`/step/${id}`);
  };

  const getData = () => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      process.env.REACT_APP_FORGOT_PASSWORD_URL
    );
    xhr.send(JSON.stringify({ username: email, app: "symfonia" }));
    toast({
      message: "Recibirás un correo para continuar.",
      color: "success",
      mode: "md",
      duration: 4000,
    });
  };

  const Header = (num) => {
    let title = text.steps.step1.title;
    let description = text.steps.step1.description;
    return (
      <div className="justify-center">
        <div className="flex flex-col justify-center  items-center">
          <img
            className="w-56 py-8"
            src="assets/img/Logotipo-Symfonia.svg"
            alt="logo"
          />
          <h1 className="text-xl my-4">{title}</h1>
          <p className="text-l text-center">{description}</p>
        </div>
      </div>
    );
  };

  // const Step1 = () => {
  //   return (
  //     <></>
  //   )
  // }

  // const Step2 = () => {
  //   return (
  //     <div className="flex flex-col w-1/3 p-2 justify-center items-center">
  //       <IonButton color="secondary" className="sfn-button" shape="round" onClick={() => { goToStep(3) }}>
  //         {text.steps.step2.actions.confirm}
  //       </IonButton>
  //     </div>
  //   )
  // }

  // const Step3 = () => {
  //   return (
  //     <form onSubmit={handleSubmit} className="w-1/3 p-2">
  //       <IonItem className="sfn-input" mode="md" lines="none">
  //         <IonLabel position="stacked">{text.steps.step3.labels.password}</IonLabel>
  //         <IonInput
  //           name="password"
  //           type="password"
  //           onIonChange={handleInputChange}
  //           value={email}
  //           required> </IonInput>
  //       </IonItem>

  //       <IonItem className="sfn-input" mode="md" lines="none">
  //         <IonLabel position="stacked">{text.steps.step3.labels.confirm}</IonLabel>
  //         <IonInput
  //           name="confirm"
  //           type="password"
  //           onIonChange={handleInputChange}
  //           value={email}
  //           required> </IonInput>
  //       </IonItem>

  //       <div className="flex flex-col md:flex-row justify-around items-center mt-4">
  //         <IonButton className="sfn-button" shape="round" fill="clear">
  //           {text.steps.step3.actions.cancel}
  //         </IonButton>
  //         <IonButton color="secondary" type="submit" className="sfn-button" shape="round">
  //           {text.steps.step3.actions.confirm}
  //         </IonButton>
  //       </div>
  //     </form>
  //   )
  // }

  // const Step4 = () => {
  //   return (
  //     <div className="flex flex-col w-1/3 p-2 justify-center items-center">
  //       <IonButton color="secondary" className="sfn-button" shape="round" onClick={() => { history.push(`/login`); }}>
  //         {text.steps.step4.actions.confirm}
  //       </IonButton>
  //     </div>
  //   )
  // }

  return (
    <IonPage>
      <IonContent className="sfn-content">
        <Wrapper>
          <div className="justify-center">
            <div className="flex flex-col justify-center  items-center">
              <img
                className="w-56 py-8"
                src="assets/img/Logotipo-Symfonia.svg"
                alt="logo"
              />
              <h1 className="text-xl my-4">{text.steps.step1.title}</h1>
              <p className="text-l text-center">
                {text.steps.step1.description}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="sm:px-32">
            <IonItem className="sfn-input" mode="md" lines="none">
              <IonLabel position="stacked">
                {text.steps.step1.labels.email}
              </IonLabel>
              <IonInput
                name="email"
                type="email"
                onIonChange={handleInputChange}
                value={email}
                required
              />
            </IonItem>

            <div className="flex flex-col md:flex-row justify-around items-center mt-4">
              <IonButton
                className="sfn-button"
                shape="round"
                fill="clear"
                routerLink="/login"
              >
                {text.steps.step1.actions.cancel}
              </IonButton>
              <IonButton
                color="secondary"
                type="submit"
                className="sfn-button"
                shape="round"
              >
                {text.steps.step1.actions.confirm}
              </IonButton>
            </div>
          </form>
          {/* {step === 2 && <Step2 />}
          {step === 3 && <Step3 />}
          {step === 4 && <Step4 />} */}
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

export default ResetPasswordPage;
