import { IonIcon } from "@ionic/react";

const SfnButton = ({form, loading=false, label, btnClass, disabled=false, onClick=()=>{}}) => (
    
    <button disabled={loading || disabled} form={form} type="submit" onClick={onClick} className={`sfn-form-button primary focus:outline-none button-disabled ${btnClass}`}>
        {loading && <IonIcon src="assets/icon/loading.svg" ></IonIcon>} 
        {!loading && <span>{label}</span>} 
    </button>
)

export default SfnButton;