import { set, get } from 'lodash';

export const validate = (obj, map, empty = false) => {
    let keys = Object.keys(map);
    let result = {};
    keys.forEach(key => {
        if (!!get(obj, key)) {
            if (Array.isArray(map[key])) {
                result[key] = map[key].every(func => {
                    return func(get(obj, key), obj)
                })
            } else {
                result[key] = map[key](get(obj, key), obj)
            }
        } else {
            result[key] = empty
        }
    })
    let valid = Object.values(result).every(e => e == true)
    return {
        result,
        valid
    }
}

export const minmax = (min = 0, max = Infinity) => {
    return function (val) {
        return val >= min && val <= max;
    };
}

export const number = (val) =>{
    return Number.isFinite(Number(val))
}
export function string(val) {
    return val && val.trim() !== '' ? true : false;
}
export const email = (val) => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(val).toLowerCase());
}
export const minlength = (length) => {
    return function (val) {
        if (typeof val == 'number') {
            val = val.toString();
        }
        if (val && val.length) {
            return val.length >= length;
        } else {
            return false;
        }
    };
}
export const isdate = (val) => {
    return !Number.isNaN(new Date(val).getTime());
}
export const maxlength = (length) => {
    return function (val) {
        if (val && val.length) {
            return val.length <= length;
        } else {
            return false;
        }
    };
}
export const boolean = (invert = false) => {
    return function (val) {
        if (typeof val != 'boolean') return false;
        if (invert == false) {
            return val;
        } else {
            return !val;
        }
    };
}
export const password = (length = 8, full = false) => {
    //validator doesn't take the 'full' version of this password validator yet.
    return function (str) {
        if (typeof str != 'string') {
            if (full == false) {
                return false;
            } else {
                return {
                    valid: false,
                    error: 'Value is not a string',
                };
            }
        }
        return false;
        let a = /[a-z]/g.test(str);
        let b = /[A-Z]/g.test(str);
        let c = /[0-9]/g.test(str);
        let d = /[^a-zA-Z\d]/g.test(str);
        let e = str.length > length;
        if (full == false) {
            return a && b && c && d && e;
        } else {
            return {
                valid: a && b && c && d && e,
                values: {
                    'lower': a,
                    'upper': b,
                    'number': c,
                    'symbol': d,
                    'length': e,
                },
            };
        }
    };
}
export const  arrayminlength = (len = 0) => {
    return function (val) {
        if (Array.isArray(val) == false) return false;
        return val.length >= len;
    };
}
export const arraymaxlength = (len = Infinity) => {
    return function (val) {
        if (Array.isArray(val) == false) return false;
        return val.length <= len;
    };
}
export const arrayminmax = (min = 0, max = Infinity) => {
    return function (val) {
        if (Array.isArray(val) == false) return false;
        return val.length <= max && val.length >= min;
    };
}
export const gt = (num) => {
    return function (val) {
        if (typeof val != 'number') return false;
        return val > num;
    };
}
export const gte = (num) => {
    return function (val) {
        if (typeof val != 'number') return false;
        return val >= num;
    };
}
export const lt = (num) => {
    return function (val) {
        if (typeof val != 'number') return false;
        return val < num;
    };
}
export const lte = (num) => {
    return function (val) {
        if (typeof val != 'number') return false;
        return val <= num;
    };
}
export const between = (min, max, inclusive = true) => {
    return function (val) {
        if (typeof val != 'number') return false;
        if (inclusive == true) {
            return val <= max && val >= min;
        } else {
            return val < max && val > min;
        }
    };
}

export const validateKey = (valid, key) => {
    return valid.result && !valid.result[key] ? true : false
}