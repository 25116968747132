import gql from 'graphql-tag'

export const createBillMutation = gql`
	mutation createBill($input: CreateBill!) {
		createBill(input: $input) {
			id
			date
			dueDate
			number
			remaining 
			convertedRemaining
			total 
			convertedTotal
			currency {
				code
				symbol
			}
			vendor {
				name				
			}
		}
	}
`

export const updateBillMutation = gql`
	mutation updateBill($input: UpdateBill!) {
		updateBill(input: $input) {
			id
			date
			dueDate
			number
			remaining 
			convertedRemaining
			total 
			convertedTotal
			status
			currency {
				code
				symbol
			}
			vendor {
				name				
			}
		}
	}
`

export const deleteBillMutation = gql`
	mutation deleteBill($input: ID!) {
		deleteBill(id: $input) {
			id
			date
			dueDate
			number
			remaining 
			convertedRemaining
			total 
			convertedTotal
			status
			currency {
				code
				symbol
			}
			vendor {
				name				
			}
		}
	}
`

export const deleteBillPaymentMutation = gql`
	mutation deleteBillPayment($input: ID!) {
		deleteBillPayment(id: $input) {
			id
		}
	}
`

export const createBillPaymentMutation = gql`
	mutation createBillPayment($input: CreateBillPayment!) {
		createBillPayment(input: $input) {
			id
		}
	}
`

export const updateBillPaymentMutation = gql`
	mutation updateBillPayment($input: UpdateBillPayment!) {
		updateBillPayment(input: $input) {
			id
		}
	}
	`

