import { IonButton, IonCheckbox, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, useIonToast, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import { closeOutline, enter } from "ionicons/icons";
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import Select from 'react-select';
import { useForm } from '../../../../../../hooks/useForm';
import { typeBilling, departments, areaCodes, phoneTypes, typePharaseAndStage, checkListPhraseAndStage } from "../../../../../../helpers/catalogs";
import { sfnSelectTheme } from "../../../../../../theme/sfnSelectTheme";
import SfnButton from "../../../../../../components/button/Button";
import Accordion from "../../../../../../components/accordion/Accordion";
import { useEffect, useRef, useState } from "react";
import { defaultValue, formatSelectData, setPhraseAndStage, toBase64 } from "../../../../../../helpers/utils";
import { useEnterprisesService } from "../../../../../../graphql/enterprise/service";
import { useQuery } from "@apollo/client";
import { getOneEnterpriseQuery } from "../../../../../../graphql/enterprise/queries";
import { useAuth } from "../../../../../../context/auth/AuthState";
import Upload from "../upload/Upload";


const initialState = {
    comercialName: '',
    name: '',
    email: '',
    isConsumer: false,
    nit: '',
    address: '',
    state: '',
    city: '',
    user: '',
    issuer: {
        label: 'Megaprint',
        value: 'MEGAPRINT',
    },
    issuerConfig: {
        frases: []
    },
    infile: {
        usuarioApi: '',
        llaveApi: '',
        usuarioFirma: '',
        llaveFirma: '',
    },
    megaprint: {
        user: '',
        apikey: '',
    },
    zip: '',
    phone: {
        type: '',
        areaCode: '',
        number: ''
    },
    signed: ''
}

const BillingModal = ({ enterprise, findCustomer = null, showModal, setShowModal = () => { }, refetchEnterprise, notUpdate = false, setShowModalVerifyRTU = () => { }, setShowAlertConfirSignatureModal = () => { } }) => {
    const { authState, setAuth } = useAuth();
    const { updateEnterprise, createEnterprise, cLoading, cError, uLoading, uError, } = useEnterprisesService(notUpdate);
    const [present] = useIonToast();
    const { formValues, handleInputChange, reset, resetProps, removeCharacter } = useForm({ ...findCustomer });
    const { comercialName, name, email, nit, address, infile, megaprint, issuer, issuerConfig, state, city, zip, isConsumer, phone, signed } = formValues;


    const onClose = () => {
        resetProps({ ...initialState })
        setShowModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (invalidFieldForms()) return
        let input = {
            id: enterprise.id, bill: {
                phone: {
                    type: phone.type.value,
                    areaCode: phone.areaCode.value,
                    number: phone.number
                },
                nit,
                email: email == '' ? null : email,
                name,
                comercialName,
                address: address ?? 'Ciudad',
                issuer: enterprise?.country?.id === 'bc995fa7-a19c-45ed-b48d-412e954e83f2' ? issuer?.value !== undefined ? issuer?.value ?? 'NONE' : issuer || 'NONE' : 'NONE',
                state: enterprise.country.id == 'bc995fa7-a19c-45ed-b48d-412e954e83f2' ? state?.label || '' : '',
                city: enterprise.country.id == 'bc995fa7-a19c-45ed-b48d-412e954e83f2' ? city?.value || '' : '',
                zip: String(zip) ?? '0',
                affiliationIVA: findCustomer.affiliationIVA
            },
        }
        const signature = await toBase64(issuerConfig.signature);
        input.bill.issuerConfig = enterprise.country.id == 'bc995fa7-a19c-45ed-b48d-412e954e83f2' ? {
            frases: setPhraseAndStage(issuerConfig.frases, true),
            password: issuerConfig.password,
            signature: signature.replace(/^data(.+?);base64,/, ""),
        } : {}

        let { errors } = await updateEnterprise({ variables: { input }, refetchQueries: [{ query: getOneEnterpriseQuery, variables: { input: enterprise.id } }] })
        if (!uLoading) {
            if (errors) {
                if (errors[0]?.extensions?.code === 'INVALID_CERTIFICATE_CREDENTIALS') {
                    present({ message: 'Oops! la firma o contraseña electrónica son incorrectos, intentalo nuevamente!', color: 'danger', mode: "md", duration: 4000 })
                    return
                }
                if (errors[0]?.extensions?.code === 'EXISTING_RECORD') {
                    present({ message: 'Oops! este registro ya existe, por favor contacte con nuestro equipo de soporte!', color: 'danger', mode: "md", duration: 4000 })
                    return
                }
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
                return
            }
            let auth = JSON.parse(JSON.stringify(authState))
            auth.enterprise.bill.issuer = input.bill.issuer
            setAuth({ user: auth, token: localStorage.getItem('token') })
            refetchEnterprise()
            present({ message: `Tu información de facturacíón se actualizó exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
            setTimeout(() => {
                setShowAlertConfirSignatureModal(true)
            }, 3000)
        }
    }



    const clearValues = (obj) => {
        Object.keys(obj).map(k => {
            if (obj[k] === '') delete obj[k]
        })
        return obj
    }

    const invalidFieldForms = (e) => {
        if (enterprise?.country?.id !== 'bc995fa7-a19c-45ed-b48d-412e954e83f2') return false
        if (state?.value === '' || state == null || state == '') {
            present({ message: `Debe seleccionar un departamento!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (phone?.type === '' || phone?.type == null || phone?.type == '') {
            present({ message: `Debe seleccionar un tipo de teléfono!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (phone?.areaCode === '' || phone?.areaCode == null || phone?.areaCode == '') {
            present({ message: `Debe seleccionar un área de código!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (city?.value === '' || city == null || city == '') {
            present({ message: `Debe seleccionar un municipio!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if (issuerConfig.signature === '' || issuerConfig.signature == null || issuerConfig.signature == '' || !issuerConfig.signature) {
            present({ message: `Debe subir tu firma electrónica!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        // if(issuer?.value === 'NONE' || issuer == null || issuer == 'NONE') {
        //     present({ message: `Debe seleccionar un proveedor de facturación!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        // if (!issuerConfig?.frases?.some(e => e)) {
        //     present({ message: `Debe seleccionar al menos un escenario y frase!`, color: "warning", mode: "md", duration: 4000 })
        //     return true
        // }
        return false
    }




    return (<IonModal id="billingModal" isOpen={showModal} cssClass='sfn-modal' onDidDismiss={() => setShowModal(false)}>
        <IonContent>
            <Wrapper>
                <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                <h3 className="text-center text-xl font-bold">Editar facturación</h3>
                <p className="text-center text-base">Completa el siguiente formulario para editar la información de facturación</p>

                <form id='billing-form' onSubmit={handleSubmit} className="h-full p-2">
                    <Accordion header={
                        <IonItem className="sfn-input mb-4" mode="md" lines="none">
                            <IonLabel position="stacked">Nombre comercial<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="comercialName" type="text" placeholder="Indica el nombre comercial de la empresa" onIonChange={handleInputChange} value={comercialName} disabled={true} required />
                        </IonItem>
                    }>

                        <IonItem className="sfn-input mb-4" mode="md" lines="none">
                            <IonLabel position="stacked">Nombre empresa<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="name" type="text" placeholder="Indica el nombre de la empresa" onIonChange={handleInputChange} value={name} disabled={true} required />
                        </IonItem>
                        {!isConsumer && (
                            <IonItem className="sfn-input mb-4" mode="md" lines="none">
                                <IonLabel position="stacked">Identifcador tributario<span className="text-base text-danger">*</span></IonLabel>
                                <IonInput name="nit" type="text" placeholder="Indica el identifcador tributario de la empresa" onIonChange={handleInputChange} value={nit} disabled={true} required />
                            </IonItem>
                        )}
                        {enterprise?.country?.id === 'bc995fa7-a19c-45ed-b48d-412e954e83f2' &&
                            <>
                                <h2 className="pt-4 pb-2">Escenarios y frases<span className="text-base text-danger">*</span></h2>
                                <div className="flex flex-col justify-start items-start">
                                    {
                                        checkListPhraseAndStage.map((label, index) => (
                                            <div className="flex items-center mb-2" key={label + index}>
                                                <IonCheckbox className="sfn-checkbox mr-2 z-0" checked={issuerConfig?.frases[index]} onIonChange={e => handleInputChange(e.detail.checked, `issuerConfig.frases[${index}]`)} disabled={true} />
                                                <IonLabel>{label}</IonLabel>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </Accordion>

                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Correo electrónico<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="email" type="email" placeholder="Indica el correo electrónico de la empresa" onIonChange={handleInputChange} value={email} required />
                    </IonItem>
                    <div className="flex flex-col my-4">
                        <p className={`text-left text-sm mb-1`}>Teléfono<span className="text-base text-danger">*</span></p>
                        <div className="flex justify-between items-center">
                            <Select value={phone.type} onChange={(e) => handleInputChange(e, `phone.type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-60 z-10" styles={sfnSelectTheme} />
                            <Select value={phone.areaCode} onChange={(e) => handleInputChange(e, `phone.areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-40 z-10" styles={sfnSelectTheme} />
                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonInput name="phone.number" type="number" maxlength="8" placeholder="5555-5555" value={phone.number} onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} required> </IonInput>
                            </IonItem>
                        </div>
                    </div>
                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Dirección<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="address" type="text" placeholder="Indica la dirección de la empresa" onIonChange={handleInputChange} value={address} required />
                    </IonItem>
                    <IonItem className="sfn-input mb-4" mode="md" lines="none">
                        <IonLabel position="stacked">Código postal<span className="text-base text-danger">*</span></IonLabel>
                        <IonInput name="zip" type="number" placeholder="Indica código postal" onIonChange={handleInputChange} value={zip} required />
                    </IonItem>

                    {enterprise?.country?.id === 'bc995fa7-a19c-45ed-b48d-412e954e83f2' &&
                        <>
                            <div className="my-4">
                                <p className={`text-sm mb-2 hover:text-secondary w-80`}>Departamento<span className="text-base text-danger">*</span></p>
                                <Select
                                    placeholder="Seleccione departamento"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    value={state}
                                    isClearable={true}
                                    onChange={(e) => handleInputChange(e, 'state')}
                                    options={departments}
                                    className="sfn-select"
                                    styles={sfnSelectTheme}
                                />
                            </div>
                            <div className="my-4">
                                <p className={`text-sm mb-2 hover:text-secondary w-80`}>Municipio<span className="text-base text-danger">*</span></p>
                                <Select
                                    placeholder="Seleccione municipio"
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                    value={city}
                                    isClearable={true}
                                    onChange={(e) => handleInputChange(e, 'city')}
                                    options={state?.value}
                                    className="sfn-select"
                                    styles={sfnSelectTheme}
                                />
                            </div>
                        </>
                    }



                    <div className="flex mt-8 w-full flex-col">
                        <Upload title="Subir firma electrónica" description="Sube tu firma electrónica registrada en la SAT" file={issuerConfig?.signature} name="issuerConfig?.signature" onChange={e => handleInputChange(e, 'issuerConfig.signature')} required />
                        <IonItem className="sfn-input mb-4" mode="md" lines="none">
                            <IonLabel position="stacked">Contraseña de firma electrónica<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="issuerConfig.password" type="text" placeholder="Ingresa tu contraseña registrada en la SAT" onIonChange={handleInputChange} value={issuerConfig?.password} required />
                        </IonItem>
                    </div>

                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="none" onClick={() => setShowModal(false)}>
                            Cancelar
                        </IonButton>
                        <SfnButton label='Guardar cambios' loading={uLoading} />
                    </div>
                </form>
            </Wrapper>
        </IonContent>
    </IonModal>);
}

export default BillingModal;