import { useEffect, useState } from 'react';
import { IonContent, IonIcon, IonPage, useIonAlert, useIonToast } from "@ionic/react";
import queryString from 'query-string'
import { flagOutline, createOutline, trashOutline, cashOutline, personOutline, pricetagOutline, albumsOutline, filterOutline, fileTrayOutline, analyticsOutline, eyeOutline, } from "ionicons/icons";
import Kpi from "../../components/kpi/Kpi";
import { useApolloClient } from '@apollo/client';
import { getOneSaleProductDetailQuery, getOnePurchaseProductDetailQuery } from "../../graphql/product/queries";
import Header from '../../components/header/Header';
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { useProductsService } from "../../graphql/product/service";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import { useAuth } from '../../context/auth/AuthState';
import IconText from '../../components/icon-text/IconText';
import { texts } from '../../theme/dataTabelTexts';
import Placeholder from '../../components/placeholder/Placeholder';
import GridTable from '@nadavshaar/react-grid-table';
import TransactionsLineChart from './TransactionsLineChart';
import ProductModal from '../../components/modals/product-modal/ProductModal';
import { useHistory } from 'react-router';
import ProductDetailModal from '../../components/modals/product-detail-modal/ProductDetailModal';
import SearchTable from '../../components/search-table/SearchTable';
import { formatMoney, isEmpty } from '../../helpers/utils';
import { AuthStore } from '../../store/auth';

const BuyingPage = ({ location, match }) => {
  const user = AuthStore.useState(s => s.user);
  const { params } = match;
  const { from } = queryString.parse(location.search);
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [presentToast] = useIonToast();
  const history = useHistory();

  const [product, setProduct] = useState({});
  const [indicators, setIndicators] = useState({});
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const { deleteProduct, dLoading, dError } = useProductsService();
  const [present] = useIonAlert();

  const client = useApolloClient();

  const ItemsPopover = ({ onHide }) => (
    <>
      <OptionItem onHide={onHide} event={handleViewDetails} icon={eyeOutline} caption="Ver más información" />
      {(!['READ'].includes(user?.role?.permissions['sales']) && !['READ'].includes(user?.role?.permissions['expenses'])) &&
        <>
          <OptionItem onHide={onHide} event={handleUpdate} icon={createOutline} caption="Editar" />
          <OptionItem onHide={onHide} event={handleDelete} icon={trashOutline} caption="Eliminar" className="danger" />
        </>
      }
    </>
  );

  const Options = () => (<ButtonPopover options={ItemsPopover} />)

  const columns = [{
    id: 1,
    label: 'Nombre',
    field: 'name',
    getValue: ({ value }) => isEmpty(value)
  }, {
    id: 2,
    label: 'Precio promedio',
    field: 'averagePrice',
    getValue: ({ value }) => formatMoney(enterprise, value, true)

  }, {
    id: 3,
    label: 'Cantidad promedio',
    field: 'averageQuantity',
    getValue: ({ value }) => isEmpty(value)

  }, {
    id: 4,
    label: 'Total promedio',
    field: 'averageTotal',
    getValue: ({ value }) => formatMoney(enterprise, value, true)
  }, {
    id: 5,
    label: 'Total',
    field: 'amount',
    width: 'max-content',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Total</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          <p>{formatMoney(enterprise, data?.total)}</p>
        </div>)
    }
  }];
  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar producto',
      subHeader: 'Este producto se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este producto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar producto',
          cssClass: 'delete',
          handler: async () => {
            console.log(product?.id);
            let resp = await deleteProduct({ variables: { input: product?.id } });
            if (resp.errors) {
              showErrorAlert(resp.errors);
            } else {
              presentToast({ message: 'El producto se ha eliminado correctamente 👍', color: "success", mode: "ios", duration: 4000 });
            }
          }
        }
      ],
    })
  }

  const showErrorAlert = (errors) => {
    const { extensions } = errors[0];
    if (extensions?.code === "LOCKED") {
      present({
        cssClass: 'sfn-alert-dialog',
        mode: 'md',
        header: 'Oops! ha ocurrido un problema',
        subHeader: `No podemos eliminar este producto porque está asociado con lo siguiente:
         ${from === 'selling'
            ?
            extensions?.invoices?.map(invoice => {
              return `\n• Factura ${invoice?.number}`
            })
            :
            extensions?.bills?.map(bill => {
              return `\n• Factura ${bill?.number}`
            })}
         `,
        message: `Mostrando ${from === 'selling' ? extensions?.invoices?.length : extensions?.bills?.length}/${extensions?.length} Para eliminar este producto, elimínelo de los elementos enumerados anteriormente`,
        buttons: [
          {
            text: 'Cerrar',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      })
    } else {
      presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
    }
  }

  const handleViewDetails = () => {
    setShowDetailModal(true);
  }
  const handleUpdate = (id) => {
    setShowModal(true);
  }

  const getProductDetail = async () => {

    let { data, errors, loading: load } = from === 'selling'
      ? await client.query({ query: getOneSaleProductDetailQuery, variables: { input: params.id }, skip: !params.id, fetchPolicy: 'no-cache' })
      : await client.query({ query: getOnePurchaseProductDetailQuery, variables: { input: params.id }, skip: !params.id, fetchPolicy: 'no-cache' })

    setLoading(load);
    if (!errors) {
      setProduct(data.product);
      if (from === 'selling') setIndicators(data.productSalesIndicators);
      if (from === 'buyings') setIndicators(data.productExpenseIndicators);
    }
  }

  useEffect(() => {
    if (params.id && !showModal) {
      getProductDetail();
    }
  }, [match, showModal])

  const breads = [{
    title: `${from === 'selling' ? 'Productos o servicios - ventas' : 'Productos o servicios - gastos'}`,
    url: `/${from}`
  }, {
    title: product?.name,
  }]

  const QuantityIcon = () => (
    <IonIcon className="text-4xl mr-6" color="secondary" slot="start" src="../assets/icon/boxes.svg" />
  )

  const tableProps = {
    isPaginated: product?.transactions?.length > 19 ? true : false,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
  }

  return (
    <IonPage>
      <Header title="Detalle producto" subtitle="" />
      <IonContent className="sfn-content">
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />
        <div className="shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white" >

          <div style={{ position: 'absolute', right: '32px' }} className="flex md:hidden">
            <Options />
          </div>
          <div className="flex w-full justify-between items-center flex-col md:flex-row text-center md:text-left">

            <div className="flex flex-col sm:ml-4 justify-center">
              <h3 className="font-bold text-3xl text-gray-blue">{product?.name}</h3>
              <p className="text-base md:text-xl text-gray-blue">Precio {formatMoney(enterprise, product.price, true)}</p>
            </div>
            <Kpi loading={loading} className="md:mt-0 mt-12" icon={flagOutline} title={formatMoney(enterprise, indicators?.total, true)} description={`Total ${from === 'selling' ? 'cobrado a clientes' : 'pagado a proveedores'}`} />

            <div className="hidden md:flex">
              <Options />
            </div>
          </div>

        </div>

        <div className="flex flex-col justify-center items-start ">

          <section className="flex flex-col md:flex-row justify-between items-start md:items-center my-12 w-full">
            <Kpi loading={loading} icon={pricetagOutline} className="small" title={formatMoney(enterprise, indicators?.averagePrice, true)} description="Precio promedio" />
            <Kpi loading={loading} customIcon={QuantityIcon} className="small my-6 md:my-0" title={`${indicators?.averageQuantity}`} description="Cantidad promedio" />
            <Kpi loading={loading} icon={cashOutline} className="small" title={formatMoney(enterprise, indicators?.averageTotal, true)} description="Total promedio" />
          </section>

          <IconText icon={personOutline} title={`${from === 'selling' ? 'Clientes' : 'Proveedores'}`} className="text-2xl mr-3" color="tertiary" />
          <GridTable
            {...tableProps}
            className={product?.transactions?.length ? 'sfn-data-table' : 'max-h-80'}
            components={{
              NoResults: () => (
                <Placeholder icon={fileTrayOutline} description='No hay ninguna transacción con este producto' />
              ),
              Search: SearchTable
            }}
            isLoading={loading}
            icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
            columns={columns}
            rows={product?.transactions}
            texts={texts}
          />
        </div>
        <div className="w-full mt-16">
          <IconText icon={analyticsOutline} title={`${from === 'selling' ? 'Ventas' : 'Compras'} últimos 12 meses`} className="text-2xl mr-3" color="tertiary" />
          <TransactionsLineChart chartData={product?.chart} enterprise={enterprise} />
        </div>
        {showModal && <ProductModal showModal={showModal} setShowModal={setShowModal} productId={params.id}></ProductModal>}
        {showDetailModal && <ProductDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} productId={params.id}></ProductDetailModal>}

      </IonContent>
    </IonPage>
  )
}

export default BuyingPage;