import { useQuery } from '@apollo/client';
import { IonButton, IonContent, IonIcon, IonModal, useIonToast } from '@ionic/react'
import { closeOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { getOneBillQuery } from '../../../graphql/bill/queries';
import { useBillsService } from '../../../graphql/bill/service';
import { getAllTaxRates } from '../../../graphql/tax/queries';
import { currencies } from '../../../helpers/catalogs';
import { getDate, startOfMonth } from '../../../helpers/date';
import { useForm } from '../../../hooks/useForm';
import SfnButton from '../../button/Button';
import ExpenseGenerator from '../../expense-generator/ExpenseGenerator';
import Wrapper from '../../wrapper/Wrapper';

const initialState = {
    date: getDate(new Date(), false),
    dueDate: getDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+30), false),
    number: '',
    description: '',
    poso: "",
    vendorId: '',
    currencyId: '',
    exchangeRate: 0,
    products: [],
    includeTaxes: false,
    extras: {
        serie: '',
        authorization: '',
    }
}


const ExpenseModal = ({ showExpenseModal, setShowExpenseModal, billId = null, setBillId, hookClose = () => { } }) => {

    const {formValues, handleInputChange, reset, resetProps} = useForm({ ...initialState });
    const { createBill, cLoading, cError, updateBill, uLoading, uError } = useBillsService();
    const [present] = useIonToast();
    const [rows, setRows] = useState([]);
    const isMounted = useRef(true);
    const [taxesLoading, setTaxesLoading] = useState(true);
    const [taxes, setTaxes] = useState([]);
    const { loading, data } = useQuery(getOneBillQuery, {
        variables: { input: billId },
        skip: !billId,
        fetchPolicy: "no-cache"
    });

    const { data: allRates, loading: ratesLoading, error: ratesError, refetch: refetchTaxes } = useQuery(getAllTaxRates,
        {
            variables: { date: formValues.date },
            fetchPolicy: 'no-cache',
            async onCompleted(data) {
                updateRates();
                setTaxesLoading(false);
                setTaxes(data?.taxRates);
            }
        });

    useEffect(() => {
        if (billId && !loading && data) {
            let tmpProducts = [];
            data.bill.products.forEach(p => {
                let tmpProduct = {
                    ...p,
                    name: p.product.name,
                    pDescription: p.description,
                    productId: p.product.id,
                    accountId: p.accountId,
                    taxes: p.taxes
                }
                delete tmpProduct.product;
                tmpProducts.push(tmpProduct)
            })
            data.bill.products = tmpProducts;
            data.bill.currencyId = currencies.find(c => c.value.id === data.bill.currencyId.id)
            setRows(data.bill.products);
            reset(data.bill);
            updateRates(tmpProducts);
            return () => {
                isMounted.current = false;
            }
        }
    }, [data]);

    const updateRates = async (products) => {
        products = formValues.products.length ? formValues.products : products
        if (products) {
            let rowsClone = [...products];
            if (!ratesLoading) {
                let tmp = rowsClone.map(r => {
                    r.taxes = getUpdateRates(r.taxes, allRates?.taxRates);
                    return r
                })
                setRows(tmp);
            }
        }
    }

    const getUpdateRates = (taxes, taxRates) => {
        if (!taxes) return [];
        let taxIds = taxes.map(t => {
            return t.tax?.id || t.value.tax.id
        })
        return taxRates.map(i => {
            return {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
        }).filter(i => taxIds.includes(i.value.tax.id))
    }


    const handleCreateExpense = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (billId) {
                await updateBill({ variables: { billId, input: formValues }, })
            } else {
                await createBill({ variables: { input: formValues } });
                console.log(formValues);
            }

            if (!uLoading && !cLoading) {
                if (cError || uError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 😑', color: "danger", mode: "md", duration: 4000 });
                    return
                }
                present({ message: `La factura se ${billId ? 'actualizó' : 'creó'} exitosamente! 🎉`, color: "success", mode: "md", duration: 4000 })
                onClose();
                console.log(formValues);
            }
        }
    }

    const validateForm = () => {
        if (!formValues.currencyId) {
            present({ message: 'Debes agregar una moneda 💲', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if (!formValues.vendorId) {
            present({ message: 'Debes agregar un proveedor 🤷‍♂️', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if (!formValues.products.length) {
            present({ message: 'Debes agregar productos 🛒', color: "danger", mode: "md", duration: 4000 });
            return
        }
        if(!formValues.products.every(p => p.name !== '' && (p.quantity !== '' && p.quantity > 0 ) && (p.price !== '' || p.price !== null) && (Object.keys(p.accountId) && p.accountId?.value !== ''))) {
            present({ message: 'Llena los campos que faltan en tus productos 🛒', color: "danger", mode: "md", duration: 4000 });
            return
        }
        // if (!formValues.number) {
        //     present({ message: 'Debes agregar un número de documento 📃', color: "danger", mode: "md", duration: 4000 });
        //     return
        // }
        formValues.vendorId = formValues.vendorId.value.id;
        formValues.currencyId = formValues.currencyId.value.id;
        console.log(formValues);
        let tmpProducts = [];
        formValues.products.forEach(p => {
            let prod = {
                accountId: p.accountId.value,
                productId: p.productId,
                price: parseFloat(p.price),
                quantity: parseFloat(p.quantity),
                description: p.pDescription,
                taxes: p.taxes ? p.taxes.map(t => t.value.id) : []
            }
            tmpProducts.push(prod);
        })
        formValues.products = tmpProducts;
        return true;
    }

    const onClose = async () => {
        await hookClose();
        resetProps({ ...initialState });
        setShowExpenseModal(false);
        setBillId(null);
    }


    return (
        <IonModal isOpen={showExpenseModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper max="xl">
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-3xl font-bold">Nuevo gasto</h3>
                    <p className="text-center text-base">Completa el siguiente formulario para registrar un gasto fijo o variable de tu empresa</p>
                    <ExpenseGenerator
                        rows={rows}
                        setRows={setRows}
                        formValues={formValues}
                        handleInputChange={handleInputChange}
                        taxes={taxes}
                        setTaxes={setTaxes}
                        ratesLoading={taxesLoading}
                        setRatesLoading={setTaxesLoading}
                        refetchTaxes={refetchTaxes}
                        className="max-w-screen-md"></ExpenseGenerator>
                    <div className="flex flex-col md:flex-row justify-end items-center my-8">
                        <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                            Cancelar
                        </IonButton>
                        <SfnButton label={billId ? 'Guardar cambios' : 'Guardar gasto'} btnClass={'ml-4'} loading={uLoading || cLoading} onClick={handleCreateExpense} />

                    </div>
                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default ExpenseModal;