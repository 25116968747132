import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonIcon, IonButton, useIonAlert, IonImg, useIonToast, IonCheckbox, useIonViewDidEnter } from "@ionic/react";
import { flagOutline, fileTrayOutline, filterOutline, personOutline, settingsOutline, peopleOutline } from "ionicons/icons";
import Kpi from "../../components/kpi/Kpi";
import GridTable from '@nadavshaar/react-grid-table';
import { useQuery } from '@apollo/client';
import Placeholder from '../../components/placeholder/Placeholder';
import Header from '../../components/header/Header';
import { dateTimeFormat, getDate, getRange, parseDate } from '../../helpers/date'
import { useState } from 'react';
import { texts } from "../../theme/dataTabelTexts";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import SfnButton from '../../components/button/Button';
import { getCalculatePayrollQuery } from "../../graphql/payroll/queries";
import { useAuth } from '../../context/auth/AuthState';
import { usePayrollService } from "../../graphql/payroll/service";
import Avatar from "../../components/avatar/Avatar";
import SearchTable from "../../components/search-table/SearchTable";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import DetailPayrollModal from '../../components/modals/detail-payroll-modal/DetailPayrollModal';
import { formatMoney, isEmpty } from "../../helpers/utils";

const PayrollPage = ({ match, history }) => {
  let { params } = match;
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  let [fromDate, setFromDate] = useState(getDate(new Date(getRange(-1)), false))
  let [toDate, setToDate] = useState(getDate(new Date().setDate(new Date(fromDate).getDate() + 30), false))
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [showDetailTablePayrollModal, setShowDetailTablePayrollModal] = useState(false);
  const [employee, setEmployee] = useState(null);

  let where = {
    _or: [{
      dueDate: {
        _between: [fromDate, toDate]
      }
    }, {
      date: {
        _between: [fromDate, toDate]
      }
    }]
  }

  const [options, setOptions] = useState({ where });
  const [employees, setEmployees] = useState([])
  const { data, loading, error, refetch } = useQuery(getCalculatePayrollQuery, {
    fetchPolicy: "no-cache",
    async onCompleted(data) {
      let employees = [...data?.calculatePayroll?.employees];
      employees.forEach(employee => {
        employee['isPaid'] = true;
        employee['isPaidSalary'] = true;
        employee['totalPaid'] = parseFloat(employee.salary + employee.amountBenefits) - parseFloat(employee.amountDiscounts);
        employee['counterDiscounts'] = employee.discounts.length;
        employee['counterBenefits'] = employee.benefits.length;

        employee.discounts.forEach(discount => {
          discount['isPaid'] = true;
        });
        employee.benefits.forEach(benefit => {
          benefit['isPaid'] = true;
        });
      });
      setEmployees(employees);
    }
  });
  const { createPayroll, cLoading, cError } = usePayrollService();


  const handleOutstanding = ({ value, tableManager, rowIndex }) => {
    let rowsClone = [...tableManager.rowsApi.rows];
    rowsClone[rowIndex].isPaid = value;
    rowsClone[rowIndex].isPaidSalary = value;
    rowsClone[rowIndex]['totalPaid'] = value ? parseFloat(rowsClone[rowIndex].salary + rowsClone[rowIndex].amountBenefits) - parseFloat(rowsClone[rowIndex].amountDiscounts) : 0;
    rowsClone[rowIndex]['counterDiscounts'] = value ? rowsClone[rowIndex].discounts.length : 0;
    rowsClone[rowIndex]['counterBenefits'] = value ? rowsClone[rowIndex].benefits.length : 0;
    rowsClone[rowIndex].discounts.forEach(discount => {
      discount.isPaid = value;
    });
    rowsClone[rowIndex].benefits.forEach(benefit => {
      benefit.isPaid = value;
    });
    setEmployees(rowsClone);
  }

  const settingPaid = (data) => {
    setEmployee(data);
    setShowDetailTablePayrollModal(true);
  }

  const ItemsPopover = ({ onHide, data }) => {
    return (
      <>
        <OptionItem onHide={onHide} event={() => (settingPaid(data))} icon={settingsOutline} caption="Configurar pago" />
      </>)
  }
  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />);


  const columns = [{
    id: 1,
    label: 'Fecha ingreso',
    field: 'startDate',
    getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
  }, {
    id: 2,
    label: 'Colaborador',
    field: 'name',
    cellRenderer: ({ data: user }) => {
      return (
        <div className="flex flex-row self-auto items-center p-4">
          <Avatar user={user}></Avatar>
          <div className="flex-initial">{isEmpty(user.name)}</div>
        </div>
      )
    }
  }, {
    id: 3,
    label: 'Salario base',
    field: 'salary',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Salario</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          <p>{formatMoney(enterprise, data?.salary)}</p>
        </div>)
    }
  },
  {
    id: 4,
    label: 'Bonos',
    field: 'benefit',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Bonos</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          {data?.benefits?.length ? <p>({data?.counterBenefits}) {formatMoney(enterprise, data?.amountBenefits)}</p> : <p> - - -</p>}
        </div>)
    }
  },
  {
    id: 5,
    label: 'Descuentos',
    field: 'discount',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Descuentos</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          {data?.discounts?.length ? <p>({data?.counterDiscounts}) {formatMoney(enterprise, data?.amountDiscounts)}</p> : <p> - - -</p>}
        </div>)
    }
  }, {
    id: 6,
    label: 'Aprobar',
    field: 'isPaid',
    width: '80px',
    cellRenderer: ({ tableManager, rowIndex, data }) => {
      return (
        <div className="sfn-checkbox-table">
          <label className="container">
            <input type="checkbox" checked={data?.isPaid} onChange={e => (handleOutstanding({ value: e.target.checked, tableManager, rowIndex: rowIndex - 1 }))} />
            <span className="checkmark"></span>
          </label>
        </div>
      )
    }
  }, {
    id: 7,
    label: 'Total',
    field: 'totalPaid',
    headerCellRenderer: () => (<div className="ml-auto mr-2">Total</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          {<p>{formatMoney(enterprise, data?.totalPaid)}</p>}
        </div>)
    }
  }, {
    id: 'option',
    label: '',
    width: "max-content",
    pinned: true,
    cellRenderer: Options
  },]


  const tableProps = {
    isPaginated: document?.payments?.length > 19 ? true : false,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
  }



  const handleChangeDate = async e => {
    let { name, value } = e.target;
    let from = fromDate, to = toDate
    if (name == 'fromDate') {
      from = value
      setFromDate(from)
    }
    if (name == 'toDate') {
      to = value
      setToDate(to)
    }

    where = {
      _or: [{
        dueDate: {
          _between: [from, to]
        }
      }, {
        date: {
          _between: [from, to]
        }
      }]
    }
    setOptions({ ...options, where })

  }

  const breads = [{
    title: 'Nómina',
    url: '/payrolls?tab=employee'
  }, {
    title: 'Cálculo de nómina'
  }];

  useIonViewDidEnter(() => {
    refetch();
  });


  const handlePayroll = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Confirmar autorización pago de nómina',
      subHeader: `Esta acción generará un registro del mes actual en el historial de nómina y no podrá editarse ni eliminarse. Esta autorización no genera ningún pago automático a los colaboradores, únicamente se registra para fines del cálculo de costos y gastos de tu empresa.`,
      message: `¿Esta seguro de pagar la nómina que pertenece al siguiente periodo ${data?.calculatePayroll?.startDate ? parseDate(data?.calculatePayroll?.startDate).replace(/\//g, '-') : ''} - ${data?.calculatePayroll?.endDate ? parseDate(data?.calculatePayroll?.endDate).replace(/\//g, '-') : ''}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Autorizar pago de nómina',
          cssClass: 'delete',
          handler: async () => {
            let { total, endDate, startDate, month, year } = data.calculatePayroll;
            let discounts = employees.filter(f => f.discounts.length).flatMap(e => e.discounts.map(d => ({ employeeId: e.id, type: 'DISCOUNT', total: d.amount, legalBenefitId: d.id, isPaid: d.isPaid })));
            let benefits = employees.filter(f => f.benefits.length).flatMap(e => e.benefits.map(d => ({ employeeId: e.id, type: 'BENEFIT', total: d.amount, legalBenefitId: d.id, isPaid: d.isPaid })));
            let records = employees.map(e => ({ employeeId: e.id, type: 'SALARY', total: e.salary, legalBenefitId: null, isPaid: e.isPaidSalary }));
            records = [...records, ...discounts, ...benefits]
            let input = {
              total,
              endDate,
              startDate,
              month,
              year,
              records
            }

            let { error } = await createPayroll({ variables: { input } });

            if (!error) {
              presentToast({ message: `Se ha autorizado el pago de nómina! 👍`, color: "success", mode: "md", duration: 4000 });
              history.replace('/payrolls?tab=employee');
            } else {
              console.log(error);
              presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
            }
          }
        }
      ],
      onDidDismiss: (e) => { },
    })
  }

  const EmployeeHeader = () => {
    return (
      <div className="shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white" >
        <div className="flex w-full justify-between items-center">
          <div className="flex">
            <div className="bg-gray-blue rounded-full p-4 sm:w-16 w-12 h-12 sm:h-16 hidden sm:flex justify-center items-center">
              <IonIcon color="light" size="large" icon={personOutline}></IonIcon>
            </div>
            <div className="flex flex-col sm:ml-4 justify-center">
              <h3 className="font-bold text-base sm:text-3xl">{data?.calculatePayroll?.employees?.length}</h3>
              <h3 className="text-base">Colaboradores</h3>
            </div>
          </div>
          <Kpi icon={flagOutline} loading={loading} className="hidden sm:flex" title={data?.calculatePayroll?.month} description="No. de nómina" />
          <Kpi icon={flagOutline} loading={loading} className="hidden sm:flex" title={`${enterprise?.currency?.symbol}. ${(Math.round(data?.calculatePayroll?.total * 100) / 100).toFixed(2)}`} description="Total nómina" />
        </div>
        <Kpi icon={flagOutline} loading={loading} className="mt-8 flex sm:hidden" title={data?.calculatePayroll?.month} description="No. de nómina" />
        <Kpi icon={flagOutline} loading={loading} className="mt-8 flex sm:hidden" title={`${enterprise?.currency?.symbol}. ${(Math.round(data?.calculatePayroll?.total * 100) / 100).toFixed(2)}`} description="Total nómina" />
      </div>
    )
  }

  return (
    <IonPage>
      <Header title="Cálculo de nómina" subtitle="" />
      <IonContent className="sfn-content">
        <BreadCrumbs breads={breads} className="md:-ml-6 ml-0 mb-12" />
        <EmployeeHeader />
        <section className="flex flex-col justify-around mt-8">
          <div className="flex flex-col justify-between items-center sm:flex-row">
            <h3 className="font-bold text-base sm:text-2xl">Período nómina {data?.calculatePayroll?.startDate ? parseDate(data?.calculatePayroll?.startDate).replace(/\//g, '-') : ''} - {data?.calculatePayroll?.endDate ? parseDate(data?.calculatePayroll?.endDate).replace(/\//g, '-') : ''}</h3>
            <SfnButton btnClass="sm:m-0 mt-2" label="Autorizar pago de nómina" onClick={handlePayroll} />
          </div>
        </section>
        <GridTable {...tableProps} className="sfn-data-table"
          isLoading={loading}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          columns={columns}
          rows={employees}
          texts={texts}
          components={{
            NoResults: () => (
              <Placeholder icon={peopleOutline} description='No tienes colaboradores, ni registros de beneficios o descuentos para este cálculo de nómina' />
            ),
            Search: SearchTable
          }} />

        {showDetailTablePayrollModal && <DetailPayrollModal employee={employee} employees={employees} setEmployees={setEmployees} showDetailPayrollModal={showDetailTablePayrollModal} setShowDetailPayrollModal={setShowDetailTablePayrollModal} enterprise={enterprise} />}
      </IonContent>
    </IonPage>
  )
}

export default PayrollPage;