import { useMutation } from "@apollo/client";
import { IonIcon, IonButton, IonNote, useIonAlert } from "@ionic/react";
import { addCircleOutline, cashOutline, createOutline, filterOutline, timeOutline, trashOutline } from "ionicons/icons";
import { useState } from "react";
import ButtonPopover from "../../../../components/button-popover/ButtonPopover";
import OptionItem from "../../../../components/option-item/OptionItem";
import Placeholder from "../../../../components/placeholder/Placeholder";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { deleteBillPaymentMutation } from "../../../../graphql/bill/mutations";
import { getAllBillsQuery, getOneBillPaymentQuery } from "../../../../graphql/bill/queries";
import { deleteInvoicePaymentMutation } from "../../../../graphql/invoice/mutations";
import { getAllInvoicesQuery, getOneInvoicePaymentQuery } from "../../../../graphql/invoice/queries";
import { paymentMethodsName } from "../../../../helpers/catalogs";
import { parseDate } from "../../../../helpers/date";
import { formatMoney, isEmpty } from "../../../../helpers/utils";
import { texts } from "../../../../theme/dataTabelTexts";
import GridTable from '@nadavshaar/react-grid-table';
import { AuthStore } from "../../../../store/auth";


const RecordTab = ({ payment, setPayment = () => { }, dataInvoice, dataBill, invoiceId, authState, data, setData = () => { }, iLoading, bLoading, setShowPaymentRecordModal = () => { }, billId }) => {
    const user = AuthStore.useState(s => s.user)
    const [present] = useIonAlert();
    const [deleteInvoicePayment, { loading: dipLoading, error: dipError }] = useMutation(deleteInvoicePaymentMutation,
        { refetchQueries: [{ query: getOneInvoicePaymentQuery, variables: { input: invoiceId } }, { query: getAllInvoicesQuery }] });
    const [deleteBillPayment, { loading: dbpLoading, error: dbpError }] = useMutation(deleteBillPaymentMutation,
        { refetchQueries: [{ query: getOneBillPaymentQuery, variables: { input: billId } }, { query: getAllBillsQuery }] });


    const tableProps = {
        isPaginated: data?.payments?.length > 19 ? true : false,
        showSearch: false,
        highlightSearch: false,
        showRowsInformation: false,
    }

    const handleUpdate = (payment) => {
        setPayment(payment);
        setShowPaymentRecordModal(true);
    }

    const handleDelete = ({id, dataBill, dataInvoice}) => {

        present({
            cssClass: 'sfn-alert-dialog',
            mode: 'md',
            header: 'Eliminar Pago',
            subHeader: 'Este pago se eliminará permanentemente',
            message: '¿Esta seguro de eliminar a este pago?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Eliminar pago',
                    cssClass: 'delete',
                    handler: () => {
                        if (dataInvoice?.invoice) deleteInvoicePayment({ variables: { input: id } });
                        if (dataBill?.bill) deleteBillPayment({ variables: { input: id } });
                    }
                }
            ],
            onDidDismiss: (e) => {
                if (dataInvoice?.invoice) setData(dataInvoice.invoice);
                if (dataBill?.bill) setData(dataBill.bill);
            },
        })
        // if(dError){
        //   console.log(dError);
        // }
    }


    const ItemsPopover = ({ onHide, data}) => (

        <>
            <OptionItem onHide={onHide} event={() => handleUpdate(data)} icon={createOutline} caption="Editar" />
            <OptionItem onHide={onHide} event={() => handleDelete(data)} icon={trashOutline} caption="Eliminar" className="danger" />
        </>
    )

    const Options = ({ data}) => (!(['READ'].includes(user?.role?.permissions['expenses']) && ['READ'].includes(user?.role?.permissions['sales'])) && <ButtonPopover options={ItemsPopover} data={{...data, dataBill, dataInvoice}} />);


    const columns = [{
        id: 1,
        label: 'Fecha',
        field: 'date',
        width: '120px',
        getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
    }, {
        id: 2,
        label: 'Cuenta de pago',
        field: 'account',
        getValue: ({ value }) => `${value.name} ${value.currency.code}`

    }, {
        id: 3,
        label: 'Método de pago',
        field: 'method',
        getValue: ({ value }) => paymentMethodsName[value]
    }, {
        id: 4,
        label: 'Monto',
        field: 'amount',
        width: 'max-content',
        headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
        cellRenderer: ({ data: _data }) => {
            return (
                <div className="text-right w-full m-4">
                    <p>{formatMoney(data, _data.amount)}</p>
                    {(_data.account.currency.code !== data?.currency?.code) && <IonNote className="text-sm">{formatMoney(_data.account, _data.convertedAmount)}</IonNote>}
                </div>)
        }
    }, {
        id: 5,
        label: '',
        cellRenderer: Options
    },];

    return (
        <>
            <div className="w-full flex justify-end items-center">
                {/* <div className="text-lg mr-3 flex items-center font-bold text-gray-blue">
                    <IonIcon className="text-2xl" icon={timeOutline}></IonIcon>
                    <p className="mr-3.5 ml-1">Historial de pagos</p>
                    <Tooltip className="text-base mt-1" label="Todos los pagos que haz realizado a tu proveedor sobre esta factura compra." />
                </div> */}
                <IonButton disabled={data?.remaining === 0 || data?.status === 'CANCELLED' || (['READ'].includes(user?.role?.permissions['expenses']) && ['READ'].includes(user?.role?.permissions['sales']))} className="sfn-button" shape="round" onClick={() => setShowPaymentRecordModal(true)}>
                    <IonIcon slot="start" icon={addCircleOutline} />
                    Agregar pago
                </IonButton>
            </div>
            {dataBill || dataInvoice ?
            <GridTable
                {...tableProps}
                className={data?.payments?.length ? 'sfn-data-table' : 'justify-start items-start max-h-80'}
                components={{
                    NoResults: () => (
                        <Placeholder icon={cashOutline} description='No tienes pagos registrados' />
                    )
                }}
                isLoading={iLoading || bLoading}
                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                columns={columns}
                rows={data?.payments?.length ? data?.payments : []}
                texts={texts} />
            : null }
        </>
    );
}

export default RecordTab;