import { Store } from "pullstate";

export const PageStore = new Store({
  url: "",
  page: "",
  title: "",
  tab: "general",
});

export const updatePage = ({ page, url, title, tab }) => {
  PageStore.update((s) => {
    s.page = page;
    s.url = url;
    s.title = title;
    s.tab = tab;
    console.log({ page, url, title, tab });
  });
};
