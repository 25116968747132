import { useEffect, useState } from "react";
import {
  IonContent,
  IonIcon,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import queryString from "query-string";
import Tabs from "../../components/tabs/Tabs";
import Header from "../../components/header/Header";

import AccountingSettingsPage from "./tabs/AccountingSettings";
import BanksSettingsPage from "./tabs/BanksSettings";
import GeneralSettingsPage from "./tabs/GeneralSettings";
import CustomizationSettingsPage from "./tabs/CustomizationSettings";
import MyPlan from "./tabs/my-plan/MyPlan";
import Billing from "./tabs/billing/Billing";
import {
  getAllEnterprisesQuery,
  getOneEnterpriseQuery,
} from "../../graphql/enterprise/queries";
import { useAuth } from "../../context/auth/AuthState";
import { useQuery } from "@apollo/client";
import { AuthStore } from "../../store/auth";
import { PageStore, updatePage } from "../../store/page";

const SettingsPage = ({ location, history }) => {
  const tab = PageStore.useState(s => s.tab);
  const currentTab = queryString.parse(location.search);
  const { authState } = useAuth();
  const user = AuthStore.useState((s) => s.user);
  const {
    data: datEnterprises,
    loading: enterpriseLoading,
    error: enterpriseError,
  } = useQuery(getAllEnterprisesQuery);

  const {
    data,
    loading,
    error,
    refetch: refetchEnterprise,
  } = useQuery(getOneEnterpriseQuery, {
    variables: { input: authState?.enterprise?.id || user?.enterprise?.id },
  });
  useEffect(() => {
    if (currentTab.tab) {
      updatePage({ tab: currentTab.tab });
    }
  }, []);


  let tabs = [
    {
      title: "General",
      value: "general",
    },
    {
      title: "Facturación",
      value: "billing",
    },
    {
      title: "Impuestos",
      value: "taxes",
    },
    {
      title: "Bancos",
      value: "banks",
    },
    {
      title: "Personalización",
      value: "costumization",
    },
    {
      title: "Mi plan",
      value: "plan",
    },
  ];

  const section = {
    general: (
      <GeneralSettingsPage
        enterprise={user?.enterprise}
        authState={user}
        refetchEnterprise={refetchEnterprise}
      />
    ),
    taxes: <AccountingSettingsPage authState={user} />,
    banks: <BanksSettingsPage authState={user} />,
    costumization: <CustomizationSettingsPage authState={user} />,
    billing: (
      <Billing
        authState={user}
        enterprise={user?.enterprise}
        refetchEnterprise={refetchEnterprise}
      />
    ),
    plan: <MyPlan authState={user} />,
  };
  return (
    <IonPage>
      <Header title="Configuración">
        <Tabs
          scrollable={true}
          tabs={tabs}
          selected={tab}
          setSelected={e => updatePage({ tab: e })}
          url={true}
          path="/settings"
          history={history}
          className="w-full mt-10 px-7"
        />
      </Header>

      <IonContent fullscreen>
        <div className="px-10 py-4 w-full h-full">
          {loading ? (
            <div className="flex w-full h-full items-center justify-center">
              <IonIcon
                className="text-8xl"
                src="assets/icon/loading.svg"
              ></IonIcon>
            </div>
          ) : (
            <div className="mt-4">{section[tab || currentTab?.tab || 'general']}</div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
