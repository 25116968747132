import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonFooter, IonContent, IonTextarea, useIonToast, IonRadio, IonRadioGroup } from '@ionic/react';
import { closeCircleOutline, closeOutline, trashOutline, checkmarkOutline, fileTrayOutline, filterOutline } from 'ionicons/icons';
import { useRef, useState, useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import { defaultValue, formatSelectData } from '../../../helpers/utils';
import { validate } from '../../../helpers/validations';
import './EmployeeModal.scss';
import Select from 'react-select';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { areaCodes, bankAccountTypes, countries, currencies, phoneTypes, typeEmployees, maritalStatuses, bloodTypes } from '../../../helpers/catalogs';
import { useEmployeesService } from '../../../graphql/employee/service';
import { validationMap } from './validation';
import SfnButton from '../../button/Button';
import { useQuery } from '@apollo/client';
import InputDate from '../../input-date/InputDate';
import { getAreas, getJobs, getOneEmployeeQuery } from '../../../graphql/employee/queries';
import GridTable from '@nadavshaar/react-grid-table';
import { dateTimeFormat, formatDate, getDate, parseDate } from '../../../helpers/date';
import { texts } from "../../../theme/dataTabelTexts";
import Placeholder from '../../../components/placeholder/Placeholder';
import InputAutocomplete from '../../input-autocomplete/InputAutocomplete';
import InputDatePayroll from '../../input-date-payroll/InputDatePayroll';

const initialState = {
    name: '',
    type: typeEmployees[0],
    startDate: '',
    endDate: null,
    job: '',
    area: '',
    bill: {
        dni: '',
        nit: '',
        countryId: '',
        state: '',
        address: ''
    },
    extra: {
        civilStatus: '',
        nationality: '',
        bloodType: '',
    },
    contact: {
        emails: [''],
        phones: [{ number: '', areaCode: '', type: '' }],
        countryId: '',
        state: '',
        address: '',
    },
    status: '',
    salary: '',
    effectiveDate: null,
}

const EmployeeModal = ({ showModal, setShowModal, employeeId = null, setEmployeeId = () => { }, enterprise, hookClose= () => {}}) => {

    const {formValues, handleInputChange, reset, resetProps, numberHandleInputChange} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { job, area, name, type, startDate, endDate, bill, extra, description, contact, status, salary, effectiveDate } = formValues;
    const listSection = ['', 'Contacto'];
    const [step, setStep] = useState(1);
    const { createEmployee, cLoading, cError, updateEmployee, uLoading, uError } = useEmployeesService();
    const [present] = useIonToast();
    const { data: allJobs, loading: jobLoading, error: jobError } = useQuery(getJobs);
    const { data: allAreas, loading: areaLoading, error: areaError } = useQuery(getAreas);

    const { loading, data } = useQuery(getOneEmployeeQuery, {
        variables: { input: employeeId },
        skip: !employeeId,
        fetchPolicy: "no-cache",
        async onCompleted(data) {
            data = JSON.parse(JSON.stringify(data));
            data.employee.type = typeEmployees.find(t => t.value == data.employee.type)
            data.employee.contact.emails = data.employee.contact.emails === null ? [''] : data.employee.contact.emails;
            data.employee.contact.phones = data.employee.contact.phones === null ? [{ number: '', areaCode: '', type: '' }] : data.employee.contact.phones.map(p => ({
                ...p,
                areaCode: defaultValue({ value: 'value', label: 'label' }, areaCodes, p.areaCode),
                type: defaultValue({ value: 'id', label: 'name' }, phoneTypes, p.type)
            }
            ));
            data.employee.extra.bloodType = data.employee.extra.bloodType !== '' ? bloodTypes.find(b => b.value === data.employee.extra.bloodType) : data.employee.extra.bloodType;
            data.employee.extra.civilStatus = data.employee.extra.civilStatus !== '' ? maritalStatuses.find(b => b.value === data.employee.extra.civilStatus) : data.employee.extra.civilStatus;
            data.employee.contact.countryId = data.employee.contact.countryId !== null ? defaultValue({ value: 'id', label: 'name' }, countries, data.employee.contact.countryId) : data.employee.contact.countryId;
            data.employee.bill.countryId = data.employee.bill.countryId !== null ? defaultValue({ value: 'id', label: 'name' }, countries, data.employee.bill.countryId) : data.employee.bill.countryId;
            delete data.employee.records;
            data.employee.salary = '';
            resetProps(data.employee);
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = JSON.parse(JSON.stringify(formValues));
        input.bill.countryId = input?.bill?.countryId?.value || null;
        input.contact.countryId = input?.contact?.countryId?.value || null;
        input.extra.bloodType = input?.extra?.bloodType?.value || '';
        input.extra.civilStatus = input?.extra?.civilStatus?.value || '';
        input.type = input?.type?.value || null;
        input.salary = input.salary == null || input.salary == '' ? null : parseFloat(input.salary || 0);
        input.contact.phones = input.contact.phones.every(p => p.number === '' || p.type === '') ? null : input.contact.phones;
        input.contact.emails = input.contact.emails.every(e => e === '') ? null : input.contact.emails;
        if (input.contact.phones !== null) {
            input.contact.phones = input.contact.phones.map(phone => {
                phone.type = phone?.type?.value || '';
                phone.areaCode = phone?.areaCode?.value || '';
                return phone;
            });
        }

        if (input.status == '') {
            present({ message: 'Debes seleccionar el estado de colaborador', color: "warning", mode: "md", duration: 4000 });
            return;
        }
        if (input.type == '') {
            present({ message: 'Debes seleccionar el tipo de colaborador', color: "warning", mode: "md", duration: 4000 });
            return;
        }

        delete input.salaries;

        if (employeeId) {
            let effectiveDate = input.effectiveDate != '' ? input.effectiveDate.split('/') : []
            if (effectiveDate.length) {
                const [day, month, year] = effectiveDate;
                input.effectiveDate = `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
            }
            if (input.effectiveDate != '' && (input.salary == '' || input.salary == null)) {
                delete input.effectiveDate;
                delete input.salary;
            }
            await updateEmployee({ variables: { input } });
        } else {
            delete input.effectiveDate;
            await createEmployee({ variables: { input } });
        }

        if (!uLoading && !cLoading) {
            if (cError || uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
                return
            }
            present({ message: `El colaborador se ${employeeId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
            e.target.reset();
        }
        present({ message: `El colaborador se ${employeeId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
        onClose();
        e.target.reset();
    }

    const stepPage = () => {
        const content = document.querySelector('#wrapper');
        let currentStep = step === listSection.length ? 1 : 2;
        setStep(currentStep);
        content.scrollTop = 0;
    }

    const onClose = () => {
        hookClose();
        reset(JSON.parse(JSON.stringify(initialState)));
        setShowModal(false);
        setEmployeeId(null);
    }

    const addElement = (type) => {
        switch (type) {
            case 'email':
                contact.emails.push('');
                handleInputChange(contact.emails, 'contact.emails')
                break;
            case 'phone':
                contact.phones.push({ number: '', areaCode: '', type: '' })
                handleInputChange(contact.phones, 'contact.phones')
                break;
            default:
                break;
        }
    }

    const removeElement = (type, index) => {
        switch (type) {
            case 'email':
                handleInputChange(contact.emails.filter((e, i) => i !== index), 'contact.emails');
                break;
            case 'phone':
                handleInputChange(contact.phones.filter((e, i) => i !== index), 'contact.phones');
                break;
            default:
                break;
        }
    }
    const columns = [{
        id: 1,
        label: 'Salario',
        field: 'salary',
        width: '50%',
        getValue: ({ value }) => `${enterprise?.currency?.symbol} ${value} ${enterprise?.currency?.code}`

    }, {
        id: 2,
        width: '50%',
        label: 'Fecha de vigencia',
        field: 'effectiveDate',
        cellRenderer: ({ data }) => (<p className="mx-5">{data.effectiveDate ? parseDate(data?.effectiveDate).replace(/\//g, '-') : 'N/A'}</p>)

    }]

    const config = {
        isPaginated: false,
        showSearch: false,
        highlightSearch: false,
        showRowsInformation: false,
    }

    let optionsJobs = ['Programador', 'Analista', 'Product Manager', 'Administrador', 'Admin Base de datos', 'Marketing'];

    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent id="contentEmployee">
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-2xl font-bold">{employeeId ? 'Editar' : 'Nuevo'} colaborador</h3>
                    <p className="text-center text-sm">Completa el siguiente formulario para {employeeId ? 'editar un' : 'agreagar un nuevo'} colaborador de tu empresa</p>
                    <div className="flex justify-between my-5">
                        <h2 className="text-center text-xl font-bold">{listSection[step - 1]}</h2>
                        <p className="text-center text-xl">Sección {step}/{listSection.length}</p>
                    </div>
                    <div className={step != listSection.length ? 'h-full' : ' w-medium'}>
                        <div className="w-full">
                            {step == 1 && (
                                <form id='customer-form' onSubmit={handleSubmit} className="h-full p-2 mb-8">
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Nombre colaborador<span className="text-base text-danger">*</span></IonLabel>
                                        <IonInput name="name" type="text" onIonChange={handleInputChange} value={name} placeholder="Indica nombre de colaborador" required> </IonInput>
                                    </IonItem>
                                    <div className="mt-4">
                                        <p className="text-left text-sm mb-2 hover:text-secondary">Tipo de colaborador<span className="text-base text-danger">*</span></p>
                                        <Select placeholder="Selecciona tipo de colaborador" value={type} onChange={e => handleInputChange(e, 'type')} options={typeEmployees} className="sfn-select z-10" styles={sfnSelectTheme} />
                                    </div>
                                    {
                                        !employeeId &&
                                        <>
                                            {type?.value == 'PROFESSIONAL_SERVICES' && <InputDate className="sm:ml-4 ml-0  sm:hidden flex" label="Vencimiento de contrato" value={endDate} handleInputChange={handleInputChange} name="endDate" icon={false}></InputDate>}

                                            <div className="my-3 flex justify-start items-start">
                                                {/* <InputDatePayroll label="Fecha de ingreso" value={startDate} onChange={handleInputChange} name="startDate" placeholder="dd/mm/yyyy" required={true} /> */}
                                                <InputDate className="sm:w-2/3 w-full" label="Fecha de ingreso" value={startDate} handleInputChange={handleInputChange} name="startDate" icon={false} required={true}></InputDate>
                                                {/* {type?.value == 'PROFESSIONAL_SERVICES' &&  <InputDatePayroll className="sm:w-2/3 ml-4" label="Vencimiento de contrato" value={endDate} onChange={handleInputChange} name="endDate" placeholder="dd/mm/yyyy" required={true} />} */}
                                                {type?.value == 'PROFESSIONAL_SERVICES' && <InputDate className="sm:w-2/3 sm:ml-4 ml-0 sm:flex hidden" label="Vencimiento de contrato" value={endDate} handleInputChange={handleInputChange} name="endDate" icon={false}></InputDate>}
                                                <IonItem className={`sfn-input ml-4 ${type?.value === 'PROFESSIONAL_SERVICES' ? 'sm:w-2/3' : ' w-full'}`} mode="md" lines="none">
                                                    <IonLabel position="stacked">Salario<span className="text-base text-danger">*</span></IonLabel>
                                                    <IonInput name="salary" type="text" 
                                                        onKeyPress={numberHandleInputChange} 
                                                        onIonChange={numberHandleInputChange} 
                                                        onKeyUp={numberHandleInputChange} 
                                                        onKeyDown={numberHandleInputChange}
                                                        value={salary} placeholder={`${enterprise?.currency?.symbol} 0.00 ${enterprise?.currency?.code}`} required> </IonInput>
                                                </IonItem>
                                            </div>
                                        </>
                                    }

                                    <div className="mt-4">
                                        <p className="text-left text-sm mb-2 hover:text-secondary">Estado<span className="text-base text-danger">*</span></p>
                                        <IonRadioGroup value={status} onIonChange={e => handleInputChange(e.detail.value, 'status')}>
                                            <div className="flex items-center mb-4">
                                                <IonRadio value="ACTIVE" className="mr-2" color="secondary" />
                                                <IonLabel>Activo</IonLabel>
                                            </div>
                                            <div className="flex items-center">
                                                <IonRadio value="INACTIVE" className="mr-2" color="secondary" />
                                                <IonLabel>Inactivo</IonLabel>
                                            </div>

                                        </IonRadioGroup>
                                    </div>

                                    <div className="mt-10">
                                        <h3 className="text-left text-xl font-bold mb-2">{type?.value === 'SERVICE' ? 'Información de facturación' : 'Información general'}</h3>
                                        <InputAutocomplete label="Puesto" name="job" type="text" onChange={handleInputChange} value={job} placeholder="Indica el puesto o rol de este colaborador" options={allJobs?.jobs} tooltip="Puesto o rol a la que pertenece este colaborador" />
                                        <InputAutocomplete label="Área" name="area" type="text" onChange={handleInputChange} value={area} placeholder="Indica el área de tu organización a la que pertenece este colaborador" options={allAreas?.areas}  tooltip="Área de tu organización a la que pertenece este colaborador"/>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">No. identificación personal </IonLabel>
                                            <IonInput name="bill.dni" type="text" onIonChange={handleInputChange} value={bill.dni} placeholder="0000000000"> </IonInput>
                                        </IonItem>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">No. de identificación tributaria</IonLabel>
                                            <IonInput name="bill.nit" type="text" onIonChange={handleInputChange} value={bill.nit} placeholder="0000000000"> </IonInput>
                                        </IonItem>
                                        {/* {type?.value == 'PROFESSIONAL_SERVICES' && (
                                            <>
                                                <IonItem className="sfn-input" mode="md" lines="none">
                                                    <IonLabel position="stacked">Dirección</IonLabel>
                                                    <IonInput name="bill.address" type="text" placeholder="Indica tu dirección física" onIonChange={handleInputChange} value={bill.address}> </IonInput>
                                                </IonItem>
                                                <div className="mt-4">
                                                    <p className="text-left text-sm mb-2 hover:text-secondary">País</p>
                                                    <Select placeholder="Selecciona el país" value={bill.countryId} onChange={e => handleInputChange(e, 'bill.countryId')} options={formatSelectData({ value: 'id', label: 'name' }, countries)} className="sfn-select" styles={sfnSelectTheme} />
                                                </div>
                                                <IonItem className="sfn-input" mode="md" lines="none">
                                                    <IonLabel position="stacked">Ciudad</IonLabel>
                                                    <IonInput name="bill.state" type="text" onIonChange={handleInputChange} value={bill.state} placeholder="Indica ciudad"> </IonInput>
                                                </IonItem>
                                            </>
                                        )} */}
                                    </div>

                                    {employeeId &&
                                        <div className="flex flex-col mt-10">
                                            <h3 className="text-left text-xl font-bold">Historial salarios</h3>
                                            <GridTable {...config} className={`sfn-data-table-slim sfn-data-table`} columns={columns} rows={data?.employee?.salaries}
                                                texts={texts}
                                                components={{
                                                    NoResults: () => (
                                                        <Placeholder icon={fileTrayOutline} description='No tienes historial de salarios' />
                                                    )
                                                }}
                                                isLoading={loading}
                                                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                                            />
                                            <div className="flex justify-start items-center">
                                                <div className="flex items-center justify-center self-end">
                                                    <p className="mr-4">Salario</p>
                                                    <IonItem className="sfn-input " mode="md" lines="none">
                                                        <IonInput name="salary" type="text" placeholder={`${enterprise?.currency?.symbol} 0.00 ${enterprise?.currency?.code}`}
                                                            onKeyPress={numberHandleInputChange} 
                                                            onIonChange={numberHandleInputChange} 
                                                            onKeyUp={numberHandleInputChange} 
                                                            onKeyDown={numberHandleInputChange}
                                                            value={salary}
                                                        />
                                                    </IonItem>
                                                </div>
                                                <div className="flex items-center justify-center self-end md:ml-24 ml-4">
                                                    <p className="mr-4 mt-4">Fecha vigencia</p>
                                                    <InputDatePayroll className="sm:w-60" label="" value={effectiveDate} onChange={handleInputChange} name="effectiveDate" placeholder="dd/mm/yyyy" required={false} />
                                                    {/* <InputDate className="sm:w-60" label="" value={effectiveDate} handleInputChange={handleInputChange} name="effectiveDate" icon={false} required={false}></InputDate> */}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </form>
                            )}
                        </div>
                        <div className="w-full">
                            {step == 2 && (
                                <form id='customer-form2' onSubmit={handleSubmit} className="h-full p-2 mb-8">
                                    <div className="flex flex-col my-4">
                                        <p className="text-left text-sm mb-1 hover:text-secondary" >Correo electrónico</p>
                                        {contact?.emails.map((email, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <IonItem className="sfn-input w-full" mode="md" lines="none">
                                                    <IonInput name={`contact.emails[${index}]`} type="email" placeholder="correo@electronico.com" onIonChange={handleInputChange} value={email}> </IonInput>
                                                </IonItem>
                                                {index > 0 &&
                                                    <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('email', index))}>
                                                        <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                    </IonButton>}
                                            </div>
                                        ))}

                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('email'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-4">
                                        <p className="text-left text-sm mb-1 hover:text-secondary" >Teléfono</p>
                                        {contact?.phones.map((phone, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <Select value={phone.type} onChange={(e) => handleInputChange(e, `contact.phones[${index}].type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-60" styles={sfnSelectTheme} />
                                                <Select value={phone.areaCode} onChange={(e) => handleInputChange(e, `contact.phones[${index}].areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-40" styles={sfnSelectTheme} />
                                                <IonItem className="sfn-input" mode="md" lines="none">
                                                    <IonInput name={`contact.phones[${index}].number`} type="text" placeholder="5555-5555" value={phone.number} onIonChange={handleInputChange}> </IonInput>
                                                </IonItem>
                                                {index > 0 &&
                                                    <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('phone', index))}>
                                                        <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                    </IonButton>}
                                            </div>
                                        ))}
                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('phone'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Dirección</IonLabel>
                                        <IonInput name="contact.address" type="text" placeholder="Indica tu dirección física" onIonChange={handleInputChange} value={contact.address}> </IonInput>
                                    </IonItem>
                                    <div className="mt-4">
                                        <p className="text-left text-sm mb-2 hover:text-secondary">País</p>
                                        <Select placeholder="Selecciona el país" value={contact.countryId} onChange={e => handleInputChange(e, 'contact.countryId')} options={formatSelectData({ value: 'id', label: 'name' }, countries)} className="sfn-select" styles={sfnSelectTheme} />
                                    </div>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Ciudad</IonLabel>
                                        <IonInput name="contact.state" type="text" placeholder="Indica ciudad" onIonChange={handleInputChange} value={contact.state}> </IonInput>
                                    </IonItem>
                                    <div className="mt-10">
                                        <h3 className="text-left text-xl font-bold">Otra información</h3>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary">Estado civil</p>
                                            <Select placeholder="Selecciona tu estado civil" value={extra.civilStatus} onChange={e => handleInputChange(e, 'extra.civilStatus')} options={maritalStatuses} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">Nacionalidad</IonLabel>
                                            <IonInput name="extra.nationality" type="text" placeholder="Indica tu nacionalidad" onIonChange={handleInputChange} value={extra.nationality}> </IonInput>
                                        </IonItem>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary">Tipo de sangre</p>
                                            <Select placeholder="Selecciona tu tipo de sangre" value={extra.bloodType} onChange={e => handleInputChange(e, 'extra.bloodType')} options={bloodTypes} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className='flex flex-col md:flex-row justify-end items-center mt-4'>
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={() => onClose()}>
                                Cancelar
                            </IonButton>
                            <IonButton className="sfn-button sm:mr-8 " shape="round" fill="outline" onClick={() => stepPage()}>
                                {step === listSection.length ? 'Regresar' : 'Siguiente'}
                            </IonButton>
                            <SfnButton form={step === listSection.length ? 'customer-form2' : 'customer-form'} label={employeeId ? 'Guardar cambios' : 'Guardar colaborador'} loading={cLoading || uLoading} />
                        </div>
                        <br /><br />
                    </div>

                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default EmployeeModal;