export const modules = [
  {
    image: "assets/img/gestion-financiera-icon-reports.svg",
      title: "Gestión financiera",
      description: "Reportes generales del efectivo entrando y saliendo de tu empresa.",
      items: [
        {
          name: "Pérdidas y ganancias",
          link: "/reports/profit-and-loss",
        },
        // {
        //   name: "Balance general",
        //   link: "/reports/balance-sheet",
        // },
        // {
        //   name: "Flujo de efectivo",
        //   link: "/reports/cash-flow",
        // },
      ],
    },
    {
      image: "assets/img/clientes-icon-reports.svg",
      title: "Clientes",
      description: "Visualiza los informes de tus clientes, quiénes aportan la mayor parte de tus ingresos y controla saldos pendientes.",
      items: [
        {
          name: "General de clientes",
          link: "/reports/income-by-customer",
        },
        {
          name: "Cuentas por cobrar",
          link: "/reports/aged-receivables",
        },
        // {
        //   name: "Reporte CLV (Costo de vida de cliente)",
        //   link: "/coming-soon",
        // },
        // {
        //   name: "Reporte CAC (Costo de adquisición de cliente)",
        //   link: "/coming-soon",
        // },
      ],
    },
    /*
    {
      image: "assets/img/ingresos-ventas-icon-reports.svg",
      title: "Ingresos / Ventas",
      description: "Visualiza el detalle de los ingresos de tu empresa.",
      items: [
        {
          name: "Reporte general ingresos",
          link: "/coming-soon",
        },
        {
          name: "Ingresos por producto / servicio (por categoría)",
          link: "/coming-soon",
        },
        // {
        //   name: "Inventario de productos / artículos",
        //   link: "/coming-soon",
        // },
      ],
    },
    */
    {
      image: "assets/img/proveedores-icon-reports.svg",
      title: "Proveedores",
      description: "Analiza tus gastos comerciales y visualiza cuánto le debes a tus proveedores.",
      items: [
        {
          name: "General proveedores",
          link: "/reports/purchase-by-vendor",
        },
        {
          name: "Cuentas por pagar",
          link: "/reports/aged-payables",
        },
      ],
    },
    /*
    {
      image: "assets/img/costos-icon-reports.svg",
      title: "Costos y gastos",
      description: "Visualiza el detalle de los costos y gastos de tu empresa.",
      items: [
        {
          name: "Reporte general de egresos",
          link: "/coming-soon",
        },
        {
          name: "Reporte de egresos por costo / gastos",
          link: "/coming-soon",
        },
      ],
    },
    */
   /*
    {
      image: "assets/img/otros-reportes-icon-reports.svg",
      title: "Otros reportes",
      description: "Analiza a detalle las transacciones financieras de tu empresa.",
      items: [
        {
          name: "Libro diario",
          link: "/coming-soon",
        },
        {
          name: "Libro mayor",
          link: "/coming-soon",
        },
        {
          name: "Balance de saldos",
          link: "/coming-soon",
        },
      ],
    },
    */
  ];
