import gql from 'graphql-tag';

export const getAllCustomersQuery = gql`
  query customers($options: Options, $page: Int) {
    customers(options: $options, page: $page) {
      rows {
        id
        name
        emails
        overdueAmount
        outstandingAmount
        phones {
          type
          areaCode
          number
        }
        bill {
          currencyId
          nit
        }
        bank {
          type
          number
          name
        }
        description

        invoices {
          id
          date
          dueDate
          number
          description
        }
      }
    }
  }
`;

export const getOneCustomerQuery = gql`
  query customer($input: ID!, $options: Options) {
    customer(id: $input) {
      id
      name
      emails
      overdueAmount
      outstandingAmount
      totalPaid
      phones {
        type
        areaCode
        number
      }
      bill {
        currencyId
        nit
      }
      bank {
        type
        number
        name
      }
      description
      address {
        countryId
        state
        address
        zip
      }
      invoices(options: $options) {
        id
        date
        dueDate
        number
        remaining
        convertedRemaining
        total
        convertedTotal
        status
        currency {
          symbol
          code
        }
      }
      createdAt
    }
  }
`;

export const incomeByCustumer = gql`
  query incomeByCustumer($from: LocalDate!, $to: LocalDate) {
    incomeByCustumer(from: $from, to: $to) {
      name
      total
      paid
    }
  }
`;

export const findCustomerByIdentifier = gql`
  query findCustomerByIdentifier($input: String!) {
    findCustomerByIdentifier(identifier: $input) {
      name
      address
    }
  }
`;

export const findCustomerByRTU = gql`
  query findCustomerByRTU($input: String!) {
    findCustomerByRTU(rtu: $input) {
      phone {
        type
        areaCode
        number
      }
      nit
      email
      name
      comercialName
      state
      city
      address
      zip
      issuer
      affiliationIVA
      issuerConfig {
        frases {
          tipo
          escenario
        }
      }
    }
  }
`;
