import { IonButton, IonContent, IonIcon, IonModal } from "@ionic/react";
import { closeOutline, fileTrayOutline, filterOutline } from "ionicons/icons";
import Wrapper from "../../wrapper/Wrapper";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import SfnButton from "../../button/Button";
import './DetailPayrollModal.scss';
import GridTable from '@nadavshaar/react-grid-table';
import Placeholder from "../../placeholder/Placeholder";
import { texts } from "../../../theme/dataTabelTexts";
import { typePayroll } from "../../../helpers/catalogs";



const SettingPayrollModal = ({ employee, showDetailPayrollModal, setShowDetailPayrollModal, employees, setEmployees, enterprise }) => {

    const [payrolls, setPayrolls] = useState([]);

    useEffect(() => {
        let data = [];
        data = [...data, { description: '', type: 'SALARY', amount: employee.salary, isPaid: employee.isPaidSalary }]
        if (employee.benefits.length) {
            data = [...data, ...employee.benefits.map(benefit => ({
                ...benefit,
                type: 'BENEFIT',
            }))]
        }
        if (employee.discounts.length) {
            data = [...data, ...employee.discounts.map(discount => ({
                ...discount,
                type: 'DISCOUNT',
            }))]
        }
        setPayrolls(data);
    }, [employee != null])

    const handleChangePaidRow = ({ value, tableManager, rowIndex }) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone[rowIndex].isPaid = value;
        setPayrolls(rowsClone);
    }

    const handleApplyChange = () => {
        const salary = payrolls.filter(p => p.type === 'SALARY')[0];
        const discounts = payrolls.filter(p => p.type === 'DISCOUNT');
        const benefits = payrolls.filter(p => p.type === 'BENEFIT');

        const totalDiscounts = discounts.reduce((acc, value) => {
            acc += value.isPaid ? value.amount : 0;
            return acc;
        }, 0);

        const totalBenefits = benefits.reduce((acc, value) => {
            acc += value.isPaid ? value.amount : 0;
            return acc;
        }, 0);

        const totalSalary = salary.isPaid ? salary.amount : 0;

        employee.discounts.forEach((value, index) => {
            let find = discounts.find(f => f.id == value.id);
            if (find) {
                value.isPaid = find.isPaid;
            }
        });
        employee.benefits.forEach((value, index) => {
            let find = benefits.find(f => f.id == value.id);
            if (find) {
                value.isPaid = find.isPaid;
            }
        });

        employee = {
            ...employee,
            counterDiscounts: discounts.filter(d => d.isPaid).length,
            counterBenefits: benefits.filter(b => b.isPaid).length,
            totalPaid: (totalSalary + totalBenefits) - totalDiscounts,
        }

        setEmployees(employees.map(e => {
            if (e.id == employee.id) {
                e = employee;
            }
            return e
        }));
        setShowDetailPayrollModal(false);
    }


    const columns = [ {
        id: 1,
        label: 'Tipo',
        field: 'type',
        width: '150px',
        getValue: ({ value }) => typePayroll[value]
    },{
        id: 2,
        label: 'Descripción',
        field: 'description',
        width: '230px'
    }, {
        id: 3,
        label: 'Monto',
        field: 'amount',
        width: '150px',
        headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
        cellRenderer: ({ data }) => {
            return (
                <div className="text-right w-full m-4">
                    <p>{enterprise?.currency?.symbol}{(Math.round(data?.amount * 100) / 100).toFixed(2)} {enterprise?.currency?.code}</p>
                </div>)
        }
    }, {
        id: 4,
        label: 'Aprobar',
        field: 'isPaid',
        width: '100px',
        cellRenderer: ({ tableManager, rowIndex, data }) => {
            return (
                <div className="sfn-checkbox-table">
                    <label className="container">
                        <input type="checkbox" checked={data?.isPaid} onChange={e => (handleChangePaidRow({ value: e.target.checked, tableManager, rowIndex: rowIndex - 1 }))} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            )
        }
    }
    ];
    const onClose = () => {
        setShowDetailPayrollModal(false);
    }
    const tableProps = {
        isPaginated: false,
        showSearch: false,
        highlightSearch: false,
        showRowsInformation: false,
        showColumnVisibilityManager: false
    }


    return (
        <IonModal cssClass="sfn-detail-payroll-modal" isOpen={showDetailPayrollModal} onDidDismiss={() => setShowDetailPayrollModal(false)}>
            <IonContent>
                <div className="flex flex-col p-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-xl font-bold">Configurar pago</h3>
                        <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>
                    <p>Selecciona los pagos de bonos y descuentos de {employee?.name} que van aplicar para este cálculo de nómina</p>

                    <div className="flex mt-4">
                        <GridTable {...tableProps} className="sfn-data-table-medium"
                            icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                            columns={columns}
                            rows={payrolls}
                            texts={texts}
                            components={{
                                NoResults: () => (
                                    <Placeholder icon={fileTrayOutline} description='No tienes bonos o descuentos para este colaborador' />
                                )
                            }} />
                    </div>
                    <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                        <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                            Cancelar
                        </IonButton>
                        <SfnButton label="Aplicar cambios" btnClass={'ml-4'} loading={false} onClick={handleApplyChange} />

                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default SettingPayrollModal;