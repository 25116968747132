import { IonIcon } from "@ionic/react";

import './OptionItem.scss';

const OptionItem = ({icon = null, caption, onHide = () => {}, event = () => {}, className="",  disabled = false}) => {
    return (
        <div className={`option-item ${className} ${disabled ? 'disabled': ''}`} onClick={(e) => {
            if(!disabled) {
                onHide();
                event(e);
            }
        }}>
           {icon && <IonIcon icon={icon} className="ml-1 mr-2"/>} 
            <p>{caption}</p>
        </div>
    )
}

export default OptionItem;