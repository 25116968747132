import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, useIonToast, IonRadio, IonRadioGroup, IonTextarea, IonCheckbox } from "@ionic/react";
import { closeCircleOutline, closeOutline, ellipse } from "ionicons/icons";
import { useForm } from "../../../../../../hooks/useForm";
import { useEffect, useState } from "react";
import './CancelSubscriptionModal.scss';
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import SfnButton from "../../../../../../components/button/Button";
import parse from 'html-react-parser';
import Select from 'react-select';
import { sfnSelectTheme } from "../../../../../../theme/sfnSelectTheme";
import { useSubscriptionService } from "../../../../../../graphql/subscription/services";
import { updateSubscription } from "../../../../../../store/myPlan";
import { reasonCancelSubscription } from "../../../../../../helpers/catalogs";

const initialState = {
    reason: '',
    comment: ''
}


const CancelSubscriptionModal = ({ colorIcon = 'DANGER', showModal, setShowModal, title = "", description = "", icon, hideCancelButton = false }) => {

    const [disabledConfig, setDisabledConfig] = useState(false)
    const { formValues, handleInputChange, reset, resetProps } = useForm(initialState);
    const { cancelSubscription, cSubscriptionLoading, cSubscriptionError } = useSubscriptionService();

    const { reason, comment } = formValues;
    const [present] = useIonToast();


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (invalidForm()) return
            let input = { ...formValues };
            input.comment = comment.trim();
            input.reason = reason?.trim();

            let { data, errors } = await cancelSubscription({ variables: { input } });
            if (errors) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
                return
            } else {
                present({ message: 'Se ha cancelado tu plan, exitosamente!', color: "success", mode: "md", duration: 4000 });
                updateSubscription(JSON.parse(JSON.stringify(data?.cancelSubscription)));
            }
            e.target.reset();
            onClose();
        } catch (error) {
            console.log(error)
        }

    }

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
    }


    function invalidForm() {
        if (reason === '' || reason == null || reason == '') {
            present({ message: `Debe seleccionar un motivo para cancelar tu plan!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        return false
    }


    return (
        <IonModal id="sfn-cancel-subscription-modal" isOpen={showModal} cssClass="rounded-2xl sfn-modal-medium" onDidDismiss={() => setShowModal(false)}>
            <div className="flex flex-col h-full w-full p-6">
                <h3 className="mb-2 text-left text-xl font-bold">Cancelar plan</h3>
                <form id="cancel-subscription-form" className="h-[100%] w-full flex flex-col md:justify-between justify-start" onSubmit={handleSubmit}>
                    <div className="flex flex-col justify-center items-center text-base select-none">
                        <p className="mt-2">Tu opinión es importante para nosotros, ¿Por qué estás cancelando tu plan en Symfonia?</p>
                        <p className="w-full mt-2 font-bold text-gray-2">Antes de cancelar, déjanos saber por qué estás cancelando tu plan con nostros.</p>
                    </div>
                    <div className="mt-4">
                        <p className={`text-sm mb-2 hover:text-secondary w-80`}>Motivo<span className="text-base text-danger">*</span></p>
                        {reasonCancelSubscription.map(({ label, value }, index) => (
                            <div lines="none" className="flex w-auto items-center text-base mb-2" key={index + value} onClick={() => { handleInputChange(value, 'reason') }}>
                                <div className={`w-[20px] h-[20px] rounded-full animate__animated ${reason === value ? 'bg-primary animate__zoomIn' : 'bg-gray-3'} mr-2 text-2xl hover:cursor-pointer`} />
                                <p className="hover:cursor-pointer">{label}</p>
                            </div>
                        ))}
                    </div>
                    <IonItem className="sfn-input" mode="md" lines="none">
                        <IonLabel position="stacked">Comentario<span className="text-base text-danger">*</span></IonLabel>
                        <IonTextarea name="comment" placeholder="Comentarios a tomar para cancelar tu plan" onIonChange={handleInputChange} value={comment}> </IonTextarea>
                    </IonItem>
                    <div className={`flex flex-col md:flex-row items-center justify-end mt-8`}>
                        <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                            Regresar
                        </IonButton>
                        <SfnButton form="cancel-subscription-form" label="Cancelar mi plan" btnClass={'ml-4 danger'} loading={cSubscriptionLoading} />
                    </div>
                </form>
            </div>
        </IonModal>
    )
}

export default CancelSubscriptionModal;