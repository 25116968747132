import { Store } from 'pullstate';

const getItem = () => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth')) ?? {
      isAuthenticated: false,
      user: null,
      checking: false
    };
    return auth;
  } catch (error) {
    return {
      isAuthenticated: false,
      user: null,
      checking: false
    };
  }
};
export const AuthStore = new Store(getItem());

export const updateAuth = (data) => {
  let auth = JSON.parse(localStorage.getItem('auth')) ?? {}
  AuthStore.update((s) => {
    Object.entries(data).map(([key, value]) => {
      s[key] = value
      auth[key] = value
    })
    localStorage.setItem(
      'auth',
      JSON.stringify({...auth})
    );
  });
};