import gql from 'graphql-tag'

export const createCustomerMutation = gql`
	mutation createCustomer($input: CreateCustomer!) {
		createCustomer(input: $input) {
			id
			name
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}
			description
			
			invoices {
				id
				date
				dueDate
				number
				description
			}
		}
	}
`

export const updateCustomerMutation = gql`
	mutation updateCustomer($input: UpdateCustomer!) {
		updateCustomer(input: $input) {
			id
			name
			phones {
				type
				areaCode
				number
			}
			bill {
				currencyId
				nit
			}
			bank {
				type
				number
				name
			}
			description
			
			invoices {
				id
				date
				dueDate
				number
				description
			}
		}
	}
`

export const deleteCustomerMutation = gql`
	mutation deleteCustomer($input: ID!) {
		deleteCustomer(id: $input) {
			id
		}
	}
`
