import { IonIcon, IonSkeletonText } from '@ionic/react';
import  './Kpi.scss';
const Kpi = ({ icon, textKlass, customIcon=()=>{}, title, description, loading=false, className="", color="text-gray-blue", iconColor="secondary", colorTitle, classIcon, tooltipDescription}) => {

    return (
        <div className={`kpi-card flex justify-start items-center ${className}`}>
             {icon ? <IonIcon className="text-4xl mr-6" color={iconColor} slot="start" icon={icon} /> : customIcon()}
             <div className="text-section">
                {loading ? <IonSkeletonText animated className="w-20" /> : <h3 className={`text-xl md:text-3xl font-bold ${colorTitle || color} ${textKlass}`}>{title}</h3>}
                {loading ? <IonSkeletonText animated className="w-30"/> : <p className={`text-base md:text-xl ${color} ${textKlass}`}>{description}{tooltipDescription && tooltipDescription}</p>}
             </div>
        </div>
    )
}

export default Kpi;