import { formatMoney } from "../../../../../helpers/utils";

const Pack = ({ symbol, currency, price, name }) => {
    return (
        <div className="flex w-full flex-col">
            <h2 className="text-xl font-bold mb-4">Paquete de DTES seleccionado</h2>
            <div className="flex justify-center items-center flex-wrap w-full">
                <div className="flex flex-col sfn-plan-card p-4  w-[246px] h-[89px] items-center justify-center mb-4 hover:cursor-pointer border-primary border-2">
                    <h2 className="font-bold text-3xl mb-2 text-center">{formatMoney({ currency: { symbol, code: currency } }, price) || 'Q. 0.00'}</h2>
                    <p className="text-primary font-bold text-xl text-center">{name}</p>
                </div>
            </div>
        </div>
    );
}

export default Pack;