import { IonItem, IonLabel, IonSkeletonText, IonToggle } from "@ionic/react";
import { useState } from "react";
import { formatMoney } from "../../helpers/utils";
import './Plan.scss';

const Plan = ({klass="", klassTitle= "", name="planId", loading, plans, value, onChange = () => { }, hidePrice = false, required= false}) => {
    const [isYearly, setIsYearly] = useState(false)
    return (<div className={`flex w-full flex-col ${klass}`}>
            <h2 className={`text-xl font-bold mb-4 ${klassTitle}`}>Selecciona plan{required && <span className="text-base text-danger">*</span>}</h2>
        {/* <div className="flex w-full items-center justify-between">
            <IonItem lines="none" className="text-gray" >
                <IonToggle mode="ios" checked={isYearly} onIonChange={e => setIsYearly(e?.detail?.checked)} />
                <IonLabel slot="end">Anual</IonLabel>
            </IonItem>
        </div> */}
        <div className="flex justify-center items-center flex-wrap w-full">
            {loading && [1, 2].map((item, index) =>
                <div lines="none" className="flex flex-col items-center justify-center m-4" key={index}>
                    <div className={`flex flex-col sfn-plan-card p-4  w-[246px] h-[89px] items-center justify-center mb-4 hover:cursor-pointer`}>
                        <IonSkeletonText animated className="w-[200px] h-[20px]" />
                        <IonSkeletonText animated className="w-[100px] h-[20px]" />
                    </div>
                </div>
            )}
            {!loading && plans?.map(({ id, name: title, price, symbol, currency }, index) => (
                <div lines="none" className="flex flex-col items-center justify-center m-4" key={id} onClick={() => { onChange(id, name) }}>
                    <div className={`flex flex-col sfn-plan-card  w-[246px] h-[89px] p-4 items-center justify-center mb-4 hover:cursor-pointer ${value === id && 'border-primary border-2'}`}>
                        {!hidePrice && <h2 className="font-bold text-3xl mb-2 text-center">{formatMoney({ currency: { symbol, code: currency } }, price) || 'Q. 0.00'}</h2>}
                        <p className="text-primary font-bold text-xl text-center">{title}</p>
                    </div>
                    <div className={`w-[20px] h-[20px] rounded-full animate__animated ${value === id ? 'bg-primary animate__zoomIn' : 'bg-gray-3'} mr-2 text-2xl hover:cursor-pointer`} />
                </div>))}
        </div>
        <p className="text-gray-4">Conoce los beneficios de cada uno de nuestros planes. <a href={`${process.env.REACT_APP_PRICES_URL}`} target="_blank" className="text-primary hover:cursor-pointer underline">Ver detalle</a></p>
    </div>
    );
}

export default Plan;