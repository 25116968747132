import gql from 'graphql-tag';

export const createEnterpriseMutation = gql`
  mutation createEnterprise($input: CreateEnterprise!) {
    createEnterprise(input: $input) {
      id
      name
      bill {
        phone {
          type
          areaCode
          number
        }
        nit
        state
        address
        zip
      }
      accounting {
        ISRPayment
        ISRRetention
        fiscalMonth
        dayMonth
      }
      data {
        picture
        footer
      }
      country {
        id
        name
        data
        alpha3
        areaCode
        picture
      }
      currency {
        id
        name
        code
        symbol
      }

      bussinesType {
        id
        name
      }
      enterpriseType {
        id
        name
      }
    }
  }
`;

export const updateEnterpriseMutation = gql`
  mutation updateEnterprise($input: UpdateEnterprise!) {
    updateEnterprise(input: $input) {
      id
      name
      bill {
        phone {
          type
          areaCode
          number
        }
        nit
        state
        address
        zip
      }
      accounting {
        ISRPayment
        ISRRetention
        fiscalMonth
        dayMonth
      }
      data {
        picture
        footer
      }

      country {
        id
        name
        data
        alpha3
        areaCode
        picture
      }
      currency {
        id
        name
        code
        symbol
      }
      bussinesType {
        id
        name
      }
      enterpriseType {
        id
        name
      }
    }
  }
`;

export const deleteEnterpriseMutation = gql`
  mutation deleteEnterprise($input: ID!) {
    deleteEnterprise(id: $input) {
      id
      name
      bill {
        phone {
          type
          areaCode
          number
        }
        nit
        state
        address
      }
      accounting {
        ISRPayment
        ISRRetention
        fiscalMonth
        dayMonth
      }
      data {
        picture
        footer
      }

      country {
        id
        name
        data
        alpha3
        areaCode
        picture
      }
      currency {
        id
        name
        code
        symbol
      }
      bussinesType {
        id
        name
      }
      enterpriseType {
        id
        name
      }
    }
  }
`;

export const changeEnterpriseMutation = gql`
  mutation changeEnterprise($input: ID!) {
    changeEnterprise(id: $input) {
      token
      enterprises {
        accepted
        pendings
      }
    }
  }
`;

export const changeEnterpriseDeleteMutation = gql`
  mutation changeEnterprise {
    changeEnterprise {
      token
      enterprises {
        accepted
        pendings
      }
    }
  }
`;

export const acceptInvitationMutation = gql`
  mutation acceptInvitation($input: ID!) {
    acceptInvitation(enterpriseId: $input) {
      id
      name
    }
  }
`;
