import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useForm } from '../../../hooks/useForm';
import { useAuth } from '../../../context/auth/AuthState';
import { getAllTaxesQuery } from '../../../graphql/tax/queries'
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { addCircleOutline, trash, create, informationCircleOutline } from 'ionicons/icons';
import { getOneEnterpriseQuery } from '../../../graphql/enterprise/queries';
import { useEnterprisesService } from '../../../graphql/enterprise/service';
import { useTaxesService } from '../../../graphql/tax/service';
import { IonIcon, IonLabel, IonButton, IonRadioGroup, IonRadio, useIonAlert, IonCheckbox, useIonToast } from '@ionic/react';
import Select from 'react-select';
import queryString from 'query-string'
import GridTable from '@nadavshaar/react-grid-table';
import OptionItem from '../../../components/option-item/OptionItem';
import TaxModal from '../../../components/modals/add-tax-modal/TaxModal';
import ButtonPopover from '../../../components/button-popover/ButtonPopover';
import DeleteTax from '../../../components/modals/delete-tax-modal/DeleteTax';
import { texts } from '../../../theme/dataTabelTexts';
import Placeholder from '../../../components/placeholder/Placeholder';
import SearchTable from '../../../components/search-table/SearchTable';
import { AuthStore } from '../../../store/auth';

const AccountingSettingsPage = () => {
	const user = AuthStore.useState(s => s.user);
	const ItemsPopover = ({ onHide, data }) => (
		<>
			<OptionItem onHide={onHide} icon={create} event={() => (handleEdit(data))} caption="Editar" />
			<OptionItem onHide={onHide} icon={trash} event={() => (handleDelete(data.id))} caption="Eliminar" className="danger" />
		</>
	)
	const Options = ({ data }) => (!['READ'].includes(user?.role?.permissions['configuration']) && <ButtonPopover options={ItemsPopover} data={data} />)
	const [companyTaxRegime, setTaxRegime] = useState(true);
	const [companyRetainerRegime, setRetainerRegime] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const {formValues, handleInputChange, reset} = useForm({
		fiscalMonth: new Date().getMonth(),
		dayMonth: new Date().getDate()
	});
	const { fiscalMonth, dayMonth } = formValues;
	const [taxId, setTaxId] = useState(null)
	const { authState } = useAuth();
	const [present] = useIonToast();
	const [presentM] = useIonAlert();

	const months = [{
		label: 'Enero',
		value: 0
	}, {
		label: 'Febrero',
		value: 1
	}, {
		label: 'Marzo',
		value: 2
	}, {
		label: 'Abril',
		value: 3
	}, {
		label: 'Mayo',
		value: 4
	}, {
		label: 'Junio',
		value: 5
	}, {
		label: 'Julio',
		value: 6
	}, {
		label: 'Agosto',
		value: 7
	}, {
		label: 'Septiembre',
		value: 8
	}, {
		label: 'Octubre',
		value: 9
	}, {
		label: 'Noviembre',
		value: 10
	}, {
		label: 'Diciembre',
		value: 11
	}]

	let days = []
	for (let i = 0; i < 30; i++) {
		days.push({
			label: i + 1,
			value: i
		})
	}
	const columns = [
		{
			id: 1,
			field: 'name',
			label: 'Impuesto'
		},
		{
			id: 2,
			field: 'abbreviation',
			label: 'Abreviación',
		},
		{
			id: 3,
			field: 'rates',
			label: 'Porcentaje',
			getValue: ({ value }) => value?.length ? value[0].rate : ''
		},
		{
			id: 'option',
			label: '',
			width: "max-content",
			pinned: true,
			sortable: true,
			resizable: false,
			cellRenderer: Options
		}
	];
	const [rows, setRows] = useState([])
	const setRegimeData = (enterprise) => {
		setTaxRegime(enterprise.accounting.ISRPayment)
		setRetainerRegime(enterprise.accounting.ISRRetention)
		handleInputChange(enterprise.accounting.dayMonth, 'dayMont')
		handleInputChange(enterprise.accounting.fiscalMonth, 'fiscalMonth')
		console.log(enterprise)
	}


	useQuery(getOneEnterpriseQuery, { variables: { input: authState.enterprise.id }, onCompleted: (data) => setRegimeData(data?.enterprise) });
	const { data, loading, error } = useQuery(getAllTaxesQuery, {fetchPolicy: 'cache-and-network'});

	const setData = (rows) => {
		console.log(rows)
		setRows(rows)
	}

	const showModalTax = () => {
		setTaxId(null)
		setShowModal(true)
	}

	const handleEdit = (data) => {
		setTaxId(data.id)
		setShowModal(true)
	}

	const { deleteTax, dError, dLoading } = useTaxesService();

	const handleDelete = (id) => {
		presentM({
			cssClass: "sfn-alert-dialog",
			mode: "md",
			header: "Eliminar impuesto",
			subHeader: "Este impuesto se eliminará permanentemente",
			message: "¿Esta seguro de eliminar este impuesto?",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
					cssClass: "secondary",
					handler: (blah) => {
						console.log("Confirm Cancel: blah");
					},
				},
				{
					text: "Eliminar impuesto",
					cssClass: "delete",
					handler: async () => {
						let resp = await deleteTax({ variables: { input: id } });
						if(resp.errors){
							showErrorAlert(resp.errors)
						} else {
							present({
								message: `El impuesto se ha eliminado exitosamente! 👍`,
								color: "success",
								mode: "ios",
								duration: 4000,
							});
						}
					},
				},
			],
			onDidDismiss: (e) => console.log("did dismiss"),
		});
	};

	const showErrorAlert = (errors) => {
		const {extensions} = errors[0];
		if(extensions?.code === "LOCKED"){
		   presentM({
			 cssClass: 'sfn-alert-dialog',
			 mode: 'md',
			 header: 'Oops! ha ocurrido un problema',
			 subHeader: `No podemos eliminar este impuesto porque está asociado a ${extensions?.length || 0} elementos.`,
			 message: '',
			 buttons: [
			   {
				 text: 'Cerrar',
				 role: 'cancel',
				 cssClass: 'secondary',
			   },
			 ],
		   })
		 } else {
		   present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
		 }
	  }



	const { updateEnterprise, uLoading, uError, } = useEnterprisesService();
	const saveAccountingData = async () => {
		let input = {
			id: authState.enterprise.id,
			accounting: {
				ISRPayment: companyTaxRegime,
				ISRRetention: companyRetainerRegime,
				fiscalMonth: fiscalMonth.value,
				dayMonth: dayMonth.value
			}
		}
		let { data } = await updateEnterprise({ variables: { input } })
		if (!uLoading) {
			if (uError) {
				present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
				return
			}
			present({ message: `Tu empresa se actualizó exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
		}
	}

	const tableProps = (show) => ({
		showSearch: show,
		showRowsInformation: show,
		isPaginated: show,
		showColumnVisibilityManager: show
	})

	return (
		<div className="p-4 flex flex-col flex-wrap w-full">
			{/* <h3 className="body-tag mb-8">
				Finalización de año fiscal
			</h3>
			<div className="flex flex-row flex-wrap justify-start items-center mb-8" style={{ width: '300px' }}>
				<Select placeholder="Mes" name="fiscalMonth" value={fiscalMonth} onChange={(e) => handleInputChange(e, 'fiscalMonth')} options={months} className="sfn-select z-auto w-1/2 mr-12" styles={sfnSelectTheme} />
				<Select placeholder="Día" name="dayMonth" value={dayMonth} onChange={(e) => handleInputChange(e, 'dayMonth')} options={days} className="sfn-select z-auto w-1/3" styles={sfnSelectTheme} />
			</div>
			<IonButton color="primary" shape="round" fill="solid" className="sfn-button w-48" expand="block" onClick={saveAccountingData}>
				<span className="my-8 text-white">Guardar</span>
			</IonButton>
			<hr className="w-1/2 text-gray-light my-12" /> */}
			<div className="flex flex-row flex-wrap justify-between items-center mb-8" >
				<h2 className="text-xl text-gray-blue font-bold mb-4">
					Impuestos de ventas
				</h2>
				<IonButton disabled={['READ'].includes(user?.role?.permissions['configuration'])} className="sfn-button" shape="round" expand="block" onClick={showModalTax}>
					<IonIcon slot="start" icon={addCircleOutline} />
					Agregar impuesto
				</IonButton>
			</div>
			<div className={`flex w-full ${data?.taxes?.rows.length ? 'h-screen' : 'h-96'}`}>
				<GridTable
					className="sfn-data-table row-cursor-pointer"
					columns={columns}
					isLoading={loading}
					rows={data?.taxes?.rows}
					texts={texts}
					{...tableProps(data?.taxes?.rows.length ? true : false)}
					components={{
						NoResults: () => (
							<Placeholder icon={informationCircleOutline} description='Aún no tienes impuestos agregados haz clic en el botón "Agregar impuesto"' />
						),
						Search: SearchTable
					}} />
			</div>

			<TaxModal showModal={showModal} setShowModal={setShowModal} taxId={taxId} setTaxId={setTaxId} />
			<DeleteTax showModal={showModalDelete} setShowModal={setShowModalDelete} />
		</div>
	)
}


export default AccountingSettingsPage;
