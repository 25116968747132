// prettier-ignore

import { useMutation } from '@apollo/client'
import {
	createProductMutation,
	deleteProductMutation,
	updateProductMutation,
} from './mutations'
import { getAllProductsQuery } from './queries'


function updateCache(cache, query, data) {
	cache.writeQuery({
		...query,
		data: {
			products: {
				rows: data
			},
		},
	})
}
const incomeQuery = {
	query: getAllProductsQuery,
	variables: {
		options: { where: { incomeAccountId: { _not: null } } }
	}
};

const expenseQuery = {
	query: getAllProductsQuery,
	variables: {
		options: { where: { expenseAccountId: { _not: null } } }
	}
};

export const useProductsService = () => {
	const [createProduct, { loading: cLoading, error: cError }] = useMutation(
		createProductMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createProduct } }) {
				// obtener el objeto de cache
				let incomeData = cache.readQuery(incomeQuery)
				let expenseData = cache.readQuery(expenseQuery)

				// reescribir ese objeto
				if (createProduct.incomeAccount && incomeData) {
					updateCache(cache, incomeQuery, [createProduct, ...incomeData.products.rows])
				}

				if (createProduct.expenseAccount && expenseData) {
					updateCache(cache, expenseQuery, [createProduct, ...expenseData.products.rows])
				}
			},
		},
	)

	const [updateProduct, { loading: uLoading, error: uError }] = useMutation(
		updateProductMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateProduct } }) {
				// obtener el objeto de cache

				const incomeData = cache.readQuery(incomeQuery)
				if (incomeData) {
					const iIndex = incomeData.products.rows.findIndex(p => p.id === updateProduct.id);
					let incomeRows = [];

					if (updateProduct.incomeAccount) {
						incomeRows = iIndex < 0 ? [...incomeData.products.rows, updateProduct] :
							incomeData.products.rows.map((product) =>
								product.id === updateProduct.id ? updateProduct : product)
					} else {
						incomeRows = incomeData.products.rows.filter((p) => p.id !== updateProduct.id);
					}

					// reescribir ese objeto
					updateCache(cache, incomeQuery, incomeRows);
				}

				const expenseData = cache.readQuery(expenseQuery)
				if (expenseData) {
					const eIndex = expenseData.products.rows.findIndex(p => p.id === updateProduct.id);
					let expenseRows = [];

					if (updateProduct.expenseAccount) {
						expenseRows = eIndex < 0 ? [...expenseData.products.rows, updateProduct] :
							expenseData.products.rows.map((product) =>
								product.id === updateProduct.id ? updateProduct : product)

					} else {
						expenseRows = expenseData.products.rows.filter((p) => p.id !== updateProduct.id);
					}

					// reescribir ese objeto
					updateCache(cache, expenseQuery, expenseRows);
				}
			},
		},
	)

	const [deleteProduct, { loading: dLoading, error: dError }] = useMutation(
		deleteProductMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteProduct } }) {
				// obtener el objeto de cache
				let incomeData = cache.readQuery(incomeQuery)
				let expenseData = cache.readQuery(expenseQuery)
				if(deleteProduct){
				// reescribir ese objeto
				if (incomeData) {
					updateCache(cache, incomeQuery, incomeData.products.rows.filter(
						(product) => product.id !== deleteProduct.id,
					))
				}

				if (expenseData) {
					updateCache(cache, expenseQuery, expenseData.products.rows.filter(
						(product) => product.id !== deleteProduct.id,
					))
				}
				}
			},
			
		},
	)

	return {
		createProduct,
		cLoading,
		cError,

		updateProduct,
		uLoading,
		uError,

		deleteProduct,
		dLoading,
		dError,
	}
}
