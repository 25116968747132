import gql from 'graphql-tag'

export const createTransactionMutation = gql`
	mutation createTransaction($input: CreateTransaction!) {
		createTransaction(input: $input) {
			id
			date
			description
			amount
			type
			order
			notes
			attachments
			vendor {
				id
				name
			}
			customer {
				id
				name
			}
			account {
				id
				name
			}
			items {
				description
				amount
				type
				order
				vendor {
					id
					name
				}
				customer {
					id
					name
				}
				account {
					id
					name
				}
				taxes {
					id
					name
				}
			}
		}
	}
`

export const updateTransactionMutation = gql`
	mutation updateTransaction($input: UpdateTransaction!) {
		updateTransaction(input: $input) {
			id
			date
			description
			amount
			type
			order
			notes
			attachments
			vendor {
				id
				name
			}
			customer {
				id
				name
			}
			account {
				id
				name
			}
			items {
				description
				amount
				type
				order
				vendor {
					id
					name
				}
				customer {
					id
					name
				}
				account {
					id
					name
				}
				taxes {
					id
					name
				}
			}
		}
	}
`

export const deleteTransactionMutation = gql`
	mutation deleteTransaction($input: ID!) {
		deleteTransaction(id: $input) {
			id
		}
	}
`
