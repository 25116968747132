import { IonModal, IonButton } from '@ionic/react';
import Wrapper from '../../wrapper/Wrapper';
import language from './language.json';
// import './DeleteUserModal.scss';

const text = language.es;
const DeleteUserModal = ({ showModal, setShowModal }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(true);
    }

    return (
        <IonModal isOpen={showModal} className="">
            <Wrapper>
                <h3 className="text-justify text-2xl font-bold">{text.title}</h3>
                <p className="text-justify text-l text-gray-light my-8">{text.description}</p>
                <h4 className="text-justify text-xl font-bold">{text.message}</h4>
                <form onSubmit={handleSubmit} className="p-2">

                    <div className="flex flex-col md:flex-row justify-around items-center mt-4">
                        <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => setShowModal(false)}>
                            <span className="text-gray-light">{text.actions.cancel}</span>
                        </IonButton>
                        <IonButton type="submit" className="sfn-button " color="danger" shape="round">
                            {text.actions.confirm}
                        </IonButton>
                    </div>

                </form>
            </Wrapper>
        </IonModal>

    )

}

export default DeleteUserModal;