import gql from "graphql-tag";

export const getAllUsersQuery = gql`
  query users($options: Options, $page: Int) {
    users(options: $options, page: $page) {
      rows {
        id
        email
        status
        name
        phone {
          type
          areaCode
          number
        }
        picture
        role {
          id
          name
          permissions
        }
      }
    }
  }
`;

export const getOneUserQuery = gql`
  query user($input: ID!) {
    user(id: $input) {
      id
      email
      name
      picture
      status
      bills {
        id
        number
        exchangeRate
        description
        date
        dueDate
      }
      role {
        id
        name
        permissions
      }
    }
  }
`;

export const meQuery = gql`
  query me {
    me {
      id
      email
      name
      picture
      registeredAt
      plan {
        id
        name
        users
        enterprises
        freeDays
      }
      enterprises {
        accepted
        pendings
      }
      enterprise {
        id
        name
        createdAt
        daysToExpire
        expiratedAt
        nextPayment
        isSubscribed
        isEnabled
        currency {
          id
          symbol
          name
          code
        }
        bill {
          phone {
            type
            areaCode
            number
          }
          nit
          state
          address
          issuer
          comercialName
          name
          email
          city
          zip
          issuerConfig {
            frases {
              tipo
              escenario
            }
          }
        }
        accounting {
          ISRPayment
          ISRRetention
          fiscalMonth
          dayMonth
        }
        data {
          picture
          footer
        }
        payroll {
          frequency
          payday
          firstFortnight
          secondFortnight
          configured
        }
        country {
          id
          name
        }
      }
      role {
        id
        name
        permissions
      }
    }
  }
`;
