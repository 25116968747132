import gql from 'graphql-tag';

export const createCreditNoteMutation = gql`
  mutation createNote($input: CreateNote!){
    createNote(input: $input) {
      id
      date
      products
      amount
      status
    }
  }
`;


export const deleteCreditNoteMutation = gql`
mutation deleteNote($input: ID!){
  deleteNote(id: $input) {
    id
    date
  }
}
`