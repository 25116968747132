export const AccountsOnboarding = [
  {
    title: "Catálogo de cuentas contables",
    description:
      "Las cuentas contables están dividas en: Activos, Pasivos, Ingresos, Egresos y Capital. Symfonia te brinda las cuentas contables necesarias para que puedas clasificar tus ingresos y egresos.<br/><br/>Si quieres aprender más sobre cómo se utilizan las cuentas contables <a href='https://symfonia.io/ayuda/contabilidad/cuentas-contables'>haz clic aquí.</a>",
    image: "https://cdn01.prd.symfonia.io/Gifs/Cuentascontables1-min.gif",
    alt: "Cuentas",
  },
  {
    title: "Agrega tus propias cuentas contables",
    description:
      "Puedes agregar las cuentas contables que necesites utilizar para clasificar la operación de tu empresa.<br/><br/>Si quieres aprender más sobre cómo se utilizan las cuentas contables <a href='https://symfonia.io/ayuda/contabilidad/agregar-cuenta'>haz clic aquí.</a>",
    image: "https://cdn01.prd.symfonia.io/Gifs/Cuentascontables1-min.gif",
    alt: "Cuentas",
  },
];
