import { useQuery } from "@apollo/client";
import { IonButton, IonIcon } from "@ionic/react";
import { pencilOutline, settingsOutline } from "ionicons/icons";
import { useAuth } from "../../../../../../context/auth/AuthState";
import { getOneEnterpriseQuery } from "../../../../../../graphql/enterprise/queries";
import { checkListPhraseAndStage, typePharaseAndStage } from "../../../../../../helpers/catalogs";
import { isEmpty } from "../../../../../../helpers/utils";
import { AuthStore } from "../../../../../../store/auth";
import Placeholder from "../placeholder/Placeholder";


const Detail = ({ authState, enterprise, showModal, setShowModal }) => {
    const user = AuthStore.useState(s => s.user);

    return (enterprise && (
        <>
            <div className="p-4">
                <div className="flex justify-between items-center mb-4 md:flex-row flex-col ">
                    <div className="flex flex-col justify-center md:mb-0 mb-4">
                        <h2 className="text-xl text-gray-blue font-bold">
                            Información de facturación
                        </h2>
                        <p className="text-base text-gray-light">
                            Esta información de tu empresa aparecerá en la factura que le envies a tus clientes.
                        </p>
                    </div>
                    <IonButton disabled={['READ'].includes(user?.role?.permissions['configuration']) || enterprise?.bill?.issuer !== 'NONE'} color="primary" shape="round" fill="outline" className="sfn-button" onClick={() => { setShowModal(true) }}>
                        <IonIcon slot="start" icon={settingsOutline} />
                        Configuración
                    </IonButton>
                </div>

                {enterprise?.bill?.issuer == 'NONE' ? <Placeholder /> : <>
                    <h3 className="body-tag">
                        Nombre comercial
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.comercialName) || " - - -"}
                    </p>
                    <h3 className="body-tag">
                        Nombre empresa
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.name) || " - - -"}
                    </p>
                    <h3 className="body-tag">
                        Correo electrónico
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.email) || " - - -"}
                    </p>
                    <h3 className="body-tag">
                        Identifcador tributario
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.nit) || " - - -"}
                    </p>
                    <h3 className="body-tag">
                        Dirección
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.address) || " - - -"}
                    </p>
                    {
                        enterprise?.country?.id == 'bc995fa7-a19c-45ed-b48d-412e954e83f2' &&
                        <>  <h3 className="body-tag">
                            Ciudad
                        </h3>
                            <p className="text-base text-gray-light mb-8">
                                {isEmpty(enterprise?.bill?.city) || " - - -"}
                            </p></>
                    }
                    <h3 className="body-tag">
                        Código postal
                    </h3>
                    <p className="text-base text-gray-light mb-8">
                        {isEmpty(enterprise?.bill?.zip) || " - - -"}
                    </p>

                    {
                        enterprise.country.id === 'bc995fa7-a19c-45ed-b48d-412e954e83f2' &&
                        <div className="mt-8">
                            <h3 className="body-tag">
                                Escenarios y tipos
                            </h3>
                            {
                                enterprise?.bill?.issuerConfig?.frases?.length ? typePharaseAndStage.map((e, i) => (
                                    enterprise?.bill?.issuerConfig?.frases?.find(f => f?.escenario == e?.escenario && f?.tipo == e?.tipo) ? (<p key={e + i} className="text-base text-gray-light mb-2">
                                        {checkListPhraseAndStage[i]}
                                    </p>) : ''

                                )) : ' - - -'
                            }
                           <br />

                        </div>
                    }
                </>
                }

            </div></>
    ));
}

export default Detail;