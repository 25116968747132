import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  useIonToast,
  IonCheckbox,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import "./RegistryPage.scss";
import { useHistory } from "react-router-dom";
import language from "./language.json";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { useForm } from "../../hooks/useForm";
import { formatSelectData } from "../../helpers/utils";
import { areaCodes, countries, currencies } from "../../helpers/catalogs";
import { sfnSelectTheme } from "../../theme/sfnSelectTheme";
import { signUpMutation } from "../../graphql/auth/mutations";
import Wrapper from "../../components/wrapper/Wrapper";
import SfnButton from "../../components/button/Button";
import { useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
// import useGaTracker from "../../components/google-analytics/usePageTracking";
import to from "await-to-js";

const initialState = {
  name: "",
  email: "",
  enterprise: "",
  countryId: "",
  currencyId: "",
  bussinesTypeId: "",
  sizeId: "",
  phone: {number: '', areaCode: ''},
};
const text = language.es;
const RegistryPage = () => {
  // useGaTracker();
  const { formValues, handleInputChange, reset } = useForm({ ...initialState });
  const {
    name,
    email,
    phone,
    enterprise,
    countryId,
    currencyId,
    bussinesTypeId,
    sizeId,
  } = formValues;
  const history = useHistory();
  const [register, { loading }] = useMutation(signUpMutation);
  const [checkbox, setCheckbox] = useState(false);
  const location = useLocation();
  const [present] = useIonToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formValues)
    if (formValues.phone.areaCode === null || formValues.phone.areaCode === "") {
      present({
        message: "Debes seleccionar un código de área",
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }

    // if (formValues.currencyId === null || formValues.currencyId === "") {
    //   present({
    //     message: "Debes agregar una moneda 💲",
    //     color: "danger",
    //     mode: "md",
    //     duration: 4000,
    //   });
    //   return;
    // }

    if (!checkbox) {
      present({
        message: text.messages.validation,
        color: "danger",
        mode: "md",
        duration: 6000,
      });
      return;
    }

    let values = {
      name: formValues.name,
      email: formValues.email,
      phone: {...formValues.phone, areaCode: formValues.phone?.areaCode?.value}
      // countryId: formValues.countryId?.value,
      // currencyId: formValues.currencyId?.value?.id,
    };

    let [err, res] = await to(register({ variables: { input: values } }));

    if (err) {
      const msg =
        err.message === "An account with the given email already exists."
          ? "Ya existe una cuenta con el mismo correo electrónico."
          : text.messages.error;

      present({
        message: msg,
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }

    try {
      ReactGA.event({
        category: location.search || "Default",
        action: "Created an Account",
        label: "Register",
        value: formValues.email
      });
      ReactPixel.trackCustom("Register", {
        name: formValues.name,
        email: formValues.email,
        countryId: formValues.countryId.value,
        currencyId: formValues.currencyId.value.id,
        category: location.search || "Default",
      });
    } catch (error) {
      console.log(error);
    }
    setShowSignUpSuccessModal(true);

    reset({ ...initialState });
  };

  const goToStep = (id) => {
    history.push(`/login`);
  };

  const Header = ({ title, description }) => {
    return (
      <div className="justify-center">
        <div className="flex flex-col justify-center  items-center">
          <img
            className="w-80 pt-8 pb-4"
            src="assets/img/Logotipo-Symfonia.svg"
            alt="logo"
          />
          <h1 className="text-2xl text-center py-6 sm:px-32">{title}</h1>
          <p className="">{description}</p>
        </div>
      </div>
    );
  };

  const [showSignUpSuccessModal, setShowSignUpSuccessModal] = useState(false);

  const SignUpSuccessModal = () => {
    return showSignUpSuccessModal ? (
      <div className="signup-success_modal-wrapper">
        <div className="signup-success_modal">
          <img
            className="logo"
            src="assets/img/Logotipo-Symfonia.svg"
            alt="logo"
          />
          <h2 className="title">¡Te has registrado exitosamente!</h2>
          <p className="sub-title">
            Revisa tu correo electrónico para obtener tu contraseña de acceso.
            El correo puede tardar unos minutos o revisa en spam.
          </p>
          <SfnButton
            onClick={() => {
              setShowSignUpSuccessModal(false);
              goToStep(1);
            }}
            label="Ir a inicio de sesión"
            btnClass="mt-8"
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <SignUpSuccessModal />
      <IonPage>
        <IonContent className="sfn-content">
          <Wrapper>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/login" />
            </IonButtons>
            <Header title={text.title} description={text.description} />
            <form onSubmit={handleSubmit} className="sm:px-32">
              <IonItem className="sfn-input" lines="none">
                <IonLabel position="stacked">{text.input.name.label}</IonLabel>
                <IonInput
                  name="name"
                  type="text"
                  onIonChange={handleInputChange}
                  placeholder={text.input.name.placeholder}
                  value={name}
                  required
                >
                  {" "}
                </IonInput>
              </IonItem>

              <IonItem className="sfn-input" lines="none">
                <IonLabel position="stacked">{text.input.email.label}</IonLabel>
                <IonInput
                  name="email"
                  type="email"
                  onIonChange={handleInputChange}
                  placeholder={text.input.email.placeholder}
                  value={email}
                  required
                ></IonInput>
              </IonItem>

              <div className="flex flex-col justify-start mt-4">
                <p className="text-sm mb-2 hover:text-secondary">
                  Teléfono
                </p>
                <div className="flex flex-row items-center justify-start w-full ">
                  <div className="w-2/4 sm:w-2/5 mr-8">
                    <Select
                      placeholder=""
                      name="phone.areaCode"
                      value={phone.areaCode}
                      onChange={(e) => handleInputChange(e, "phone.areaCode")}
                      options={areaCodes}
                      className="z-90"
                      styles={sfnSelectTheme}
                      required
                    />
                  </div>

                  <IonItem className="sfn-input w-2/4 sm:w-full" lines="none">
                    {/* <IonLabel position="stacked">Teléfono</IonLabel> */}
                    <IonInput
                      name="phone.number"
                      type="number"
                      onIonChange={handleInputChange}
                      placeholder="5555-5555"
                      value={phone.number}
                      required
                    ></IonInput>
                  </IonItem>
                </div>

              </div>



              {/* <div className="mt-8 w-full">
                <p className="text-sm mb-2 hover:text-secondary">
                  {text.input.currency.label}
                </p>
                <Select
                  placeholder={text.input.currency.placeholder}
                  name="currencyId"
                  value={currencyId}
                  onChange={(e) => handleInputChange(e, "currencyId")}
                  options={currencies}
                  className=""
                  styles={sfnSelectTheme}
                  required
                />
              </div> */}

              <div className="mt-8 w-full flex flex-col justify-center  items-center">
                <a href="" className="text-primary">
                  {text.input.terms.placeholder}
                </a>
                <IonItem lines="none" className="z-0">
                  <IonCheckbox
                    slot="start"
                    name="terms"
                    value="accept"
                    onIonChange={(e) => setCheckbox(e.detail.checked)}
                  />
                  <IonLabel>{text.input.terms.label}</IonLabel>
                </IonItem>
              </div>
              <SfnButton
                label={text.actions.confirm}
                btnClass="w-full mt-8"
                loading={loading}
                disabled={!checkbox}
              />
            </form>
          </Wrapper>
        </IonContent>
      </IonPage>
    </>
  );
};

export default RegistryPage;
