import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, useIonToast, IonRadio, IonRadioGroup } from "@ionic/react";
import { closeCircleOutline, closeOutline } from "ionicons/icons";
import { useForm } from "../../../../../../hooks/useForm";
import { useEffect, useState } from "react";
import './AlertModal.scss';
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import SfnButton from "../../../../../../components/button/Button";
import parse from 'html-react-parser';

const initialState = {
    identifier: ''
}


const AlertModal = ({ colorIcon = 'DANGER', showModal, setShowModal, title = "", description = "", icon, hideCancelButton = false }) => {

    const [disabledConfig, setDisabledConfig] = useState(false)
    const { formValues, handleInputChange, reset, resetProps } = useForm(initialState);

    const { identifier } = formValues;
    const [present] = useIonToast();


    const handleSubmit = async (e) => {
        e.preventDefault();
    }

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
    }



    return (
        <IonModal id="sfn-alert-modal" isOpen={showModal} cssClass="rounded-2xl sfn-modal-medium" onDidDismiss={() => setShowModal(false)}>
            <div className="h-full w-full p-6">
                <form className="h-full w-full flex flex-col md:justify-between justify-start" onSubmit={handleSubmit}>
                    <div className="flex flex-col justify-center items-center">
                        <IonIcon className={`text-8xl ${colorIcon === 'DANGER' ? 'text-danger' : colorIcon == 'PRIMARY' ? 'text-primary' : 'text-secondary'}`} icon={icon} />
                        <h3 className="mt-4 font-bold text-lg text-center">{title}</h3>
                        <p className="mt-2 text-gray-2 text-center">{parse(description)}</p>
                    </div>
                    <div className={`flex flex-col md:flex-row ${!hideCancelButton ? 'justify-end' : 'justify-center'} items-center mt-8`}>
                        {!hideCancelButton && <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                            Cancelar
                        </IonButton>}
                        <SfnButton label="Entendido" btnClass={'ml-4'} loading={false} onClick={onClose} />
                    </div>
                </form>
            </div>
        </IonModal>
    )
}

export default AlertModal;