import { useApolloClient, useMutation } from '@apollo/client';
import { useContext, createContext, useReducer } from 'react';
import { signInMutation } from '../../graphql/auth/mutations';
import { exchangeRateQuery } from '../../graphql/enterprise/queries';
import { meQuery } from '../../graphql/user/queries';
import { types } from '../types/types';
import AuthReducer from './AuthReducer';
import { readURL, uploadFiles, getUrlFile } from '../../helpers/uploadFiles';
import { updateAuth } from '../../store/auth';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useAuthProvider() {
  // State of auth
  const initialState = {
    checking: true,
    isAuthenticated: false,
  };

  const [login, { loading, error }] = useMutation(signInMutation);
  const [authState, dispatch] = useReducer(AuthReducer, initialState);
  const client = useApolloClient();
  const signin = async (email, password) => {
    updateAuth({ checking: true });

    try {
      const { data } = await login({
        variables: { username: email, password: password },
      });

      if (!loading && data) {
        const { token } = data.signIn;
        localStorage.setItem('token', token);
        const {
          data: user,
          loading,
          error,
        } = await client.query({ query: meQuery });
        await getExchangeRateQuery()
        updateAuth({ user: user.me, isAuthenticated: true, checking: false });
        dispatch({
          type: types.AUTH_LOGIN,
          payload: user.me,
        });
      }
    } catch (error) {}
  };

  const logout = () => {
    client.clearStore();
    updateAuth({ user: null, isAuthenticated: false });
    localStorage.removeItem('token');
    // window.location.reload();
    dispatch({
      type: types.AUTH_LOGOUT,
    });
  };

  const whoiam = async () => {
    if (localStorage.getItem('token')) {
      try {
        updateAuth({ checking: true });

        let {
          data: user,
          loading,
          error,
        } = await client.query({ query: meQuery, errorPolicy: 'all' });
        if (!loading && user.me) {
          user = JSON.parse(JSON.stringify(user));
          if (
            user.me.enterprise !== null &&
            user.me.enterprise?.data?.picture !== null &&
            user.me.enterprise?.data?.picture !== ''
          ) {
            const url = await getUrlFile(
              client,
              user.me.enterprise.data.picture
            );
            const data = await fetch(url);
            const blob = await data.blob();
            user.me.enterprise.data.urlPicture = await readURL(blob);
          }
          if (user.me.picture !== null && user.me.picture != '') {
            user.me.urlPicture =
              localStorage.getItem('profile_picture') ||
              (await getUrlFile(client, user.me.picture, 'profile'));
          }
          await getExchangeRateQuery()
          updateAuth({ user: user.me, isAuthenticated: true, checking: false });
          dispatch({
            type: types.AUTH_LOGIN,
            payload: user.me,
          });
          return;
        } else {
          // logout();
        }
      } catch (error) {
        // logout();
      }
    }
    dispatch({
      type: types.AUTH_LOGOUT,
    });
  };

  const setAuth = async ({ user, token }) => {
    if (token) {
      localStorage.setItem('token', token);
    }
    updateAuth({ user, token });
    dispatch({
      type: types.AUTH_LOGIN,
      payload: user,
    });
  };

  const getExchangeRateQuery = async () => {
    const {data: exchangeRate, loading, error} = await client.query({
      query: exchangeRateQuery,
      errorPolicy: 'all'
    });
    return exchangeRate
  }

  const getExchangeRate = (code, execute = false) => {
    const {exchangeRate} = client.readQuery({
      query: exchangeRateQuery,
      errorPolicy: 'all'
    });
    return exchangeRate?.rate?.rates[code];
  };

  return {
    signin,
    logout,
    loading,
    error,
    authState,
    setAuth,
    whoiam,
    getExchangeRate,
  };
}
