import gql from 'graphql-tag';

export const createInvoiceMutation = gql`
  mutation createInvoice($input: CreateInvoice!) {
    createInvoice(input: $input) {
      id
      date
      dueDate
      number
      description
      number
      total
      customer {
        id
        name
      }
    }
  }
`;

export const updateInvoiceMutation = gql`
  mutation updateInvoice($input: UpdateInvoice!) {
    updateInvoice(input: $input) {
      id
      date
      dueDate
      number
      number
      remaining
      total
      convertedTotal
      status
      currency {
        symbol
        code
      }
      customer {
        name
      }
    }
  }
`;

export const deleteInvoiceMutation = gql`
  mutation deleteInvoice($input: ID!) {
    deleteInvoice(id: $input) {
      id
      date
      dueDate
      number
      number
      remaining
      total
      convertedTotal
      status
      currency {
        symbol
        code
      }
      customer {
        name
      }
    }
  }
`;

export const deleteInvoicePaymentMutation = gql`
  mutation deleteInvoicePayment($input: ID!) {
    deleteInvoicePayment(id: $input) {
      id
    }
  }
`;

export const createInvoicePaymentMutation = gql`
  mutation createInvoicePayment($input: CreateInvoicePayment!) {
    createInvoicePayment(input: $input) {
      id
    }
  }
`;
export const updateInvoicePaymentMutation = gql`
  mutation updateInvoicePayment($input: UpdateInvoicePayment!) {
    updateInvoicePayment(input: $input) {
      id
    }
  }
`;

export const generateInvoiceMutation = gql`
  mutation generateInvoice($input: ID!){
    generateInvoice(id: $input) {
      message
    }
  }
`;
