import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonTextarea, IonContent, useIonToast } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { useEnterprisesService } from '../../../graphql/enterprise/service';
import { currencies, countries, phoneTypes, bussinesTypes, areaCodes, sizes, translatePlans } from '../../../helpers/catalogs';
import { formatSelectData, defaultValue } from '../../../helpers/utils';
import Select from 'react-select';
import SfnButton from '../../button/Button';
import { useQuery } from '@apollo/client';
import { getOneEditEnterpriseQuery, getOneEnterpriseQuery } from '../../../graphql/enterprise/queries';
import { validationMap } from './validation';
import { validate, validateKey } from '../../../helpers/validations';
import { AuthStore } from '../../../store/auth';
import { useAuth } from '../../../context/auth/AuthState';
import { getAllPlansQuery } from '../../../graphql/plan/queries';
import Plan from '../../plan/Plan';
const initialState = {
    name: '',
    countryId: '',
    currencyId: '',
    bussinesTypeId: '',
    sizeId: '',
    planId: '',
    bill: {
        phone: { type: '', areaCode: '', number: '' },
    }
}

const BusinessModal = ({ showBusinessModal, setShowBusinessModal, businessId = null, setBusinessId = () => { }, setEnterprise, refetchEnterprise, notUpdate = false}) => {
    const authStore = AuthStore.useState(s => s);
    const {setAuth} = useAuth();
    const { changeEnterprise, updateEnterprise, createEnterprise, cLoading, cError, uLoading, uError, } = useEnterprisesService(notUpdate);
    const [present] = useIonToast();
    const { formValues, handleInputChange, reset, resetProps } = useForm(initialState);
    const { name, countryId, currencyId, bussinesTypeId, sizeId, planId, bill } = formValues;
    const [selectedOption, setSelectedOption] = useState(null);
    const [valid, setValid] = useState({});

    let {data: dataPlans, loading: loadingPlans, error: errorPlans} = useQuery(getAllPlansQuery)

    let { data, loading, error } = useQuery(getOneEditEnterpriseQuery, {
        variables: { input: businessId },
        skip: !businessId,
        fetchPolicy: 'no-cache'
    }); 


    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = { ...formValues };
       
        if (invalidForm()) return
        
        input.bussinesTypeId = formValues.bussinesTypeId.value;
        input.sizeId = formValues.sizeId.value;
        input.countryId = formValues.countryId.value;
        input.currencyId = formValues.currencyId.value.id;
        input.bill.phone.type = formValues.bill.phone.type.value;
        input.bill.phone.areaCode = formValues.bill.phone.areaCode.value;

        if (!businessId) {
            let {data, errors} = await createEnterprise({ variables: { input } })
            if(!errors && data && authStore.user.enterprise === null) {
                handleChangeEnterprise(data?.createEnterprise)
            }
        } else {
            delete input.editable
            delete input.removable
            delete input.currencyId;
            let { data, errors} = await updateEnterprise({ variables: { input }, refetchQueries: [{ query: getOneEditEnterpriseQuery, variables: { input: businessId } }] })
            if(data && !errors && authStore.user.enterprise.id === data?.updateEnterprise?.id) {
                let auth = { ...authStore.user };
                auth.enterprise = data?.updateEnterprise;
                setAuth({ user: auth});
            }
        }
        if (!uLoading && !cLoading) {
            if (cError || uError) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
                return
            }
           
            present({ message: `Tu empresa se ${businessId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
        }
    }


    const onClose = () => {
        setBusinessId && setBusinessId(null);
        resetProps({ ...initialState })
        setShowBusinessModal(false)
    }

    const handleChangeEnterprise = async (enterprise) => {
        let { data, errors } = await changeEnterprise({ variables: { input: enterprise?.id } });
        if (!errors) {
          let { changeEnterprise } = data;
          let auth = { ...authStore.user };
          auth.enterprise = enterprise
          setAuth({ user: auth, token: changeEnterprise.token });
          window.location.reload();
        }
    };

    const transformPlans =  (data = []) => {
        if(!data?.length) return data
        return data?.map(plan => {
            plan = {...plan}
            plan.name = translatePlans[plan.id]
            return plan
        })
    }

    function invalidForm() {
        if(countryId === null || countryId?.value === '' || countryId === '' ) {
            present({ message: `Debe seleccionar un país!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(currencyId === null || currencyId?.value === '' || currencyId === '' ) {
            present({ message: `Debe seleccionar una moneda!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(bussinesTypeId === null || bussinesTypeId?.value === '' || bussinesTypeId === '' ) {
            present({ message: `Debe seleccionar un giro de negocio!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(sizeId === null || sizeId?.value === '' || sizeId === '' ) {
            present({ message: `Debe seleccionar una cantidad de colaboradores!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(planId === null || planId?.value === '' || planId === '' ) {
            present({ message: `Debe seleccionar una plan!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(bill?.phone?.type === null || bill?.phone.type?.value === '' || bill?.phone.type === '' ) {
            present({ message: `Debe seleccionar una tipo de telefono!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        if(bill?.phone?.areaCode === null || bill?.phone.areaCode?.value === '' || bill?.phone.areaCode === '' ) {
            present({ message: `Debe seleccionar un área de código!`, color: "warning", mode: "md", duration: 4000 })
            return true
        }
        return false
    }

    useEffect(() => {
        if (businessId && !loading) {
            let { enterprise } = data;
            enterprise = { ...enterprise };
            enterprise.sizeId = defaultValue({ value: 'id', label: 'name' }, sizes, enterprise.size.id);
            enterprise.bussinesTypeId = defaultValue({ value: 'id', label: 'name' }, bussinesTypes, enterprise.bussinesType.id);
            enterprise.countryId = defaultValue({ value: 'id', label: 'name' }, countries, enterprise.country.id);
            enterprise.currencyId = currencies.find(f => f.value.id === enterprise.currency.id);
            enterprise.planId = enterprise.plan.id;
            if (enterprise.bill || enterprise.bill !== null && enterprise.bill.phone) {
                if (!enterprise.bill.phone) enterprise.bill.phone = { type: '', areaCode: '', number: '' }
                enterprise.bill.phone.type = defaultValue({ value: 'id', label: 'name' }, phoneTypes, enterprise.bill.phone.type || []);
                enterprise.bill.phone.type = enterprise.bill.phone.type == null ? '' : enterprise.bill.phone.type
                enterprise.bill.phone.areaCode = defaultValue({ value: 'value', label: 'label' }, areaCodes, enterprise.bill.phone.areaCode);
                enterprise.bill.phone.areaCode = enterprise.bill.phone.areaCode == null ? '' : enterprise.bill.phone.areaCode
            } else {
                enterprise.bill = initialState.bill;
            }
            delete enterprise.plan
            delete enterprise.enterpriseType
            delete enterprise.bussinesType
            delete enterprise.country
            delete enterprise.currency
            delete enterprise.size
            reset({ ...enterprise });
        }
    }, [data]);

    return (
        <IonModal isOpen={showBusinessModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent>
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h2 className="text-center text-xl font-bold">{businessId ? 'Editar' : 'Agregar'} información de empresa</h2>
                    <p className="text-center text-base">{businessId ? 'Edita' : 'Agrega'} la información que necesites y guarda los cambios</p>

                    <form onSubmit={handleSubmit} id="enterprise-form" className="h-full p-2">

                        <IonItem className={`sfn-input mb-4 ${validateKey(valid, 'name') ? 'sfn-input-validate' : ''}`} mode="md" lines="none">
                            <IonLabel position="stacked">Nombre de empresa<span className="text-base text-danger">*</span></IonLabel>
                            <IonInput name="name" type="text" placeholder="Indica el nombre de la empresa" onIonChange={handleInputChange} value={name} required />
                        </IonItem>

                        <div className="mt-3">
                            <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'bussinesTypeId') ? 'text-danger' : ''}`} >Giro de negocio<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona el giro de negocio de tu empresa" value={bussinesTypeId} onChange={(e) => handleInputChange(e, 'bussinesTypeId')} options={formatSelectData({ value: 'id', label: 'name' }, bussinesTypes)} className="sfn-select" styles={sfnSelectTheme} />
                        </div>

                        <div className="mt-3">
                            <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'countryId') ? 'text-danger' : ''}`} >País<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona el país" value={countryId} onChange={(e) => handleInputChange(e, 'countryId')} options={formatSelectData({ value: 'id', label: 'name' }, countries)} className="sfn-select" styles={sfnSelectTheme} />
                            <p className="mt-2 text-base text-gray-light">Elija el pais donde presenta sus impuestos o donde está incorporada su empresa.</p>
                        </div>
                        <div className="mt-3">
                            <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'currencyId') ? 'text-danger' : ''}`} >Moneda<span className="text-base text-danger">*</span></p>
                            <Select isDisabled={businessId} placeholder="Selecciona moneda" value={currencyId} onChange={(e) => handleInputChange(e, 'currencyId')} options={currencies} className="sfn-select" styles={sfnSelectTheme} />
                            <p className="mt-2 text-base text-gray-light">Moneda se usa en los reportes y no se puede cambiar. Puede enviar facturas, realizar un seguimiento de los gastos e ingresar transacciones en cualquier moneda y se le aplicará un tipo de cambio.</p>
                        </div>
                        <div className="mt-3">
                            <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'sizeId') ? 'text-danger' : ''}`} >Cantidad de colaboradores<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona cantidad de colaboradores" value={sizeId} onChange={(e) => handleInputChange(e, 'sizeId')} options={formatSelectData({ value: 'id', label: 'name' }, sizes)} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        {/* <div className="mt-3">
                            <p className={`text-sm mb-2 hover:text-secondary w-80 ${validateKey(valid, 'planId') ? 'text-danger' : ''}`} >Plan<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona un plan" value={planId} onChange={(e) => handleInputChange(e, 'planId')} options={formatSelectData({ value: 'id', label: 'name' }, dataPlans?.plans)} className="sfn-select" styles={sfnSelectTheme} />
                        </div> */}
                        {!businessId && <Plan klass="mt-8" klassTitle="text-sm font-normal" required value={planId} onChange={handleInputChange} name="planId" loading={loading} plans={dataPlans?.plans} hidePrice={true} />}
                        <div className="flex flex-col my-4">
                            <p className={`text-left text-sm mb-1 hover:text-secondary ${validateKey(valid, 'bill.phone') ? 'text-danger' : ''}`}>Teléfono <span className="text-base text-danger">*</span></p>
                            <div className="flex justify-between items-center">
                                <Select value={bill.phone.type} onChange={(e) => handleInputChange(e, `bill.phone.type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-60" styles={sfnSelectTheme} />
                                <Select value={bill.phone.areaCode} onChange={(e) => handleInputChange(e, `bill.phone.areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-40" styles={sfnSelectTheme} />
                                <IonItem className="sfn-input" mode="md" lines="none">
                                    <IonInput name="bill.phone.number" type="text" placeholder="5555-5555" value={bill.phone.number} onIonChange={handleInputChange} required> </IonInput>
                                </IonItem>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => setShowBusinessModal(false)}>
                                Cancelar
                            </IonButton>
                            <SfnButton form="enterprise-form" label={businessId ? 'Guardar cambios' : 'Guardar empresa'} loading={cLoading || uLoading} />
                        </div>
                    </form>
                </Wrapper>
            </IonContent>
        </IonModal>

    )

}

export default BusinessModal;