import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, useIonToast, IonRadio, IonRadioGroup } from "@ionic/react";
import { closeCircleOutline, closeOutline, enter } from "ionicons/icons";
import { useForm } from "../../../../../../hooks/useForm";
import { useEffect, useState } from "react";
import './VerifyRTUModal.scss';
import Wrapper from "../../../../../../components/wrapper/Wrapper";
import SfnButton from "../../../../../../components/button/Button";
import AlertModal from "../alert-modal/AlertModal";
import { useApolloClient } from "@apollo/client";
import { findCustomerByRTU } from "../../../../../../graphql/customer/queries";
import { areaCodes, departments, phoneTypes, typeBilling } from "../../../../../../helpers/catalogs";
import { defaultValue, setPhraseAndStage } from "../../../../../../helpers/utils";

const initialState = {
    identifier: ''
}

const enterprise = {
    comercialName: '',
    name: '',
    email: '',
    isConsumer: false,
    nit: '',
    address: '',
    state: '',
    city: '',
    user: '',
    issuer: {
        label: 'Megaprint',
        value: 'MEGAPRINT',
    },
    issuerConfig: {
        signature: '',
        password: '',
        frases: []
    },
    zip: '',
    phone: {
        type: '',
        areaCode: '',
        number: ''
    },
}


const VerifyRTUModal = ({ showModal, setShowModal, setShowBillingModal, setFindCustomer }) => {

    const [disabledConfig, setDisabledConfig] = useState(false)
    const { formValues, handleInputChange, reset, resetProps, removeCharacter } = useForm(initialState);
    const [showAlertModal, setShowAlertModal] = useState(false)
    const { identifier } = formValues;
    const [present] = useIonToast();
    const [loading, setLoading] = useState(false);
    const client = useApolloClient();





    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (identifier == '' || !identifier) return
            setLoading(true)
            const { data, errors } = await client.query({ query: findCustomerByRTU, variables: { input: identifier } });

            if (!errors && data !== null) {
                setLoading(false)
                let customer = {...enterprise, ...JSON.parse(JSON.stringify(data?.findCustomerByRTU))}
                customer.issuer = (customer?.issuer !== undefined || customer.issuer !== null) ? typeBilling?.find(v => v.value === customer?.issuer) ?? {
                    label: 'Megaprint',
                    value: 'MEGAPRINT',
                } : {
                    label: 'Megaprint',
                    value: 'MEGAPRINT',
                }
                customer.state = customer.state ? departments.find(v => v.label.toLowerCase().includes(customer.state.toLowerCase())) : null
                customer.city = customer.city ? customer.state.value.find(f => f => f.label.toLowerCase().includes(customer.city.toLowerCase())) : null
                customer.issuerConfig.frases = setPhraseAndStage(customer.issuerConfig.frases)
                customer.phone.type = defaultValue({ value: 'id', label: 'name' }, phoneTypes, customer.phone.type || []);
                customer.phone.areaCode = defaultValue({ value: 'value', label: 'label' }, areaCodes, customer.phone.areaCode);

                setFindCustomer(customer);
                setShowModal(false);
                setShowBillingModal(true)
            }
            if (errors) {
                setShowAlertModal(true);
            }
        } catch (error) {
            if (error) {
                setShowAlertModal(true);
            }
        }
        setLoading(false)
    }

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
    }

    


    return (
        <>
            <IonModal id="sfn-verify-rtu-modal" isOpen={showModal} cssClass="rounded-2xl" onDidDismiss={() => setShowModal(false)}>
                <IonContent>
                    <div className="h-auto w-full p-6">
                        <div className="flex justify-between items-start mb-8 ">
                            <div className="flex flex-col md:w-full w-3/4">
                                <h3 className="text-xl font-bold">Ingresa tu identificador tributario</h3>
                                <p>Verificaremos si tu registro en la SAT se hizo correctamente.</p>
                            </div>
                            <IonIcon onClick={onClose} icon={closeOutline} className="flex  cursor-pointer modal-close-button"></IonIcon>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonLabel position="stacked" className="text-gray-light">No. de identificación tributaria (NIT)<span className="text-base text-danger">*</span></IonLabel>
                                <IonInput maxlength="13" name="identifier" type="text" placeholder="Ingresa No. de identificación tributaria" value={identifier} onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} autocomplete="off" required> </IonInput>
                            </IonItem>
                            <div className="flex flex-col md:flex-row justify-end items-center mt-8">
                                <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                                    Cancelar
                                </IonButton>
                                <SfnButton label="Verificar" btnClass={'ml-4'} loading={loading} />
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonModal>
            <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} colorIcon="DANGER" icon={closeCircleOutline} title="No existe identificador tributario" description={`Tu identificador tributario no está correctamente vinculado con certificador de facturación electrónica, asegúrate de seguir los pasos de configuración en SAT. <a class="text-primary" target="_blank" href=${process.env.REACT_APP_STEP_BILLING_URL}>Ver más</a>`} />
        </>
    )
}

export default VerifyRTUModal;