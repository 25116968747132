import {string, email} from '../../../helpers/validations';

export const validationMap = {
    name: string,
    accountTypeId: (val) => {
        return typeof(val) === 'string' ? string(val) : string(val?.value?.id || val?.value)
    },
    currencyId:  (val) => {
       return typeof(val) == 'string' ? string(val) : string(val?.value?.id)
    } ,
}