import gql from 'graphql-tag'

export const getAllProductsQuery = gql`
	query products($options: Options, $page: Int) {
		products(options: $options, page: $page) {
			rows {
				id
				name
				price
				sku
				description
				type
				taxes {
					id
					name
					abbreviation
					rates {
						id
						rate
					}
					
				}
				incomeAccount {
					id
					name
					customId
					description
					
				}
				expenseAccount {
					id
					name
					customId
					description
				}
			}
		}
	}
`

export const getOneProductQuery = gql`
	query product($input: ID!) {
		product(id: $input) {
			id
			name
			price
			sku
			description
			type
			numberOfBills 
			numberOfInvoices
			taxes {
				value: id
				label: abbreviation				
			}
			incomeAccountId: incomeAccount  {
				value: id
				label: name			
			}
			expenseAccountId: expenseAccount {
				value: id
				label: name				
			}
		}
	}
`

export const getOneSaleProductDetailQuery = gql`
	query product($input: ID!) {
		product(id: $input) {
			id
			name
			price
			transactions: customers {
				name
				averagePrice
				averageQuantity
				averageTotal
				total
			  }
			chart: salesChart {
			  label
			  value
			}
		  }
		  productSalesIndicators(id: $input) {
			averagePrice
			total
			averageQuantity
			averageTotal
		  }
	}
`

export const getOnePurchaseProductDetailQuery = gql`
	query product($input: ID!) {
		product(id: $input) {
			id
			name
			price
			transactions: vendors {
			  name
			  averagePrice
			  averageQuantity
			  averageTotal
			  total
			}
			chart: expenseChart {
				label
				value
			  }
		  }
		  productExpenseIndicators(id: $input) {
			averagePrice
			total
			averageQuantity
			averageTotal
		  }
	}
`