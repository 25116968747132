import gql from "graphql-tag";

export const getAllReceiptQuery = gql`
  query receipts($options: Options, $page: Int) {
    receipts(options: $options, page: $page) {
      rows {
        id
        merchant
        description
        subtotal
        total
        date
        picture
        convertedTotal
        account {
          id
          name
        }
        category {
          id
          name
        }
        currency {
          id
          name
          symbol
        }
        taxes {
          id
          tax {
            id
            name
          }
          rate
        }
      }
    }
  }
`;

export const getOneReceiptQuery = gql`
  query receipt($input: ID!) {
    receipt(id: $input) {
      id
      merchant
      description
      subtotal
      total
      date
      picture
      method
      account {
        value: id
        label: name
      }
      category {
        value: id
        label: name
      }
      currency {
        id
        name
        symbol
      }
      taxes {
        id
        tax {
          id
          name
          abbreviation
        }
        rate
      }
    }
  }
`;

export const getAllMerchantsQuery = gql`
  query merchants($options: Options) {
    merchants(options: $options) {
      label
      value
    }
  }
`;


export const getMerchantsQuery = gql`
query merchants {
  getMerchants:merchants {
    label
    value
  }
}
`;
