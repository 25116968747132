import Plan from "./components/plan/Plan";
import PaymentHistory from "./components/payment-history/PaymentHistory";
import Detail from "./components/detail/Detail";
import PackageDTE from "./components/package-dte/PackageDTE";
const MyPlan = ({authState}) => {
    return (<>
        <Detail authState={authState} />
        <PackageDTE authState={authState}/>
        <PaymentHistory authState={authState} />
    </>);
}

export default MyPlan;