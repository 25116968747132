import gql from 'graphql-tag'

export const createTaxMutation = gql`
	mutation createTax($input: CreateTax!) {
		createTax(input: $input) {
			id
			name
			abbreviation
		}
	}
`

export const updateTaxMutation = gql`
	mutation updateTax($input: UpdateTax!) {
		updateTax(input: $input) {
			id
			name
			abbreviation
		}
	}
`

export const deleteTaxMutation = gql`
	mutation deleteTax($input: ID!) {
		deleteTax(id: $input) {
			id
			name
			abbreviation
		}
	}
`
