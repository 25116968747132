import gql from 'graphql-tag';

export const getPacks = gql`
  query {
    packs {
      id
      name
      currency
      price
      symbol
    }
  }
`;