// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createAccountMutation,
	deleteAccountMutation,
	updateAccountMutation,
} from './mutations'
import { getAllAccountsQuery } from './queries'
import { bankAccountTypes } from '../../helpers/catalogs';

export const useAccountsService = () => {
	const [createAccount, { loading: cLoading, error: cError }] = useMutation(
		createAccountMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createAccount } }) {
				// obtener el objeto de cache
				let data = cache.readQuery({ query: getAllAccountsQuery})
				data = JSON.parse(JSON.stringify(data))
				if(!data.accounts.data[createAccount.accountType.id]) {
					data.accounts.data[createAccount.accountType.id] = [];
				}
				data.accounts.data[createAccount.accountType.id] = [createAccount, ...data.accounts.data[createAccount.accountType.id]]
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllAccountsQuery,
					data,
				})
			},
		},
	)

	const [updateAccount, { loading: uLoading, error: uError }] = useMutation(
		updateAccountMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateAccount } }) {
				// obtener el objeto de cache
				let data = cache.readQuery({ query: getAllAccountsQuery, variables: {input: updateAccount.accountType.group}})
				data = JSON.parse(JSON.stringify(data))
				if(!data || data == null) {
					return;
				}
				data.accounts.data = {...data.accounts.data}
				data.accounts.data[updateAccount.accountType.id] = data.accounts.data[updateAccount.accountType.id].map((account) => account.id === updateAccount.id ? updateAccount : account)
				
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllAccountsQuery,
					data
				})
			},
		},
	)

	const [deleteAccount, { loading: dLoading, error: dError }] = useMutation(
		deleteAccountMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteAccount } }) {
				// obtener el objeto de cache
				if(deleteAccount){
				let data = cache.readQuery({ query: getAllAccountsQuery, variables: {input: deleteAccount.accountType.group}})
				data = JSON.parse(JSON.stringify(data));
					data.accounts.data = {...data.accounts.data}
					data.accounts.data[deleteAccount.accountType.id] = data.accounts.data[deleteAccount.accountType.id].filter(account => account.id !== deleteAccount.id)
					if(!data.accounts.data[deleteAccount.accountType.id].length) {
						delete data.accounts.data[deleteAccount.accountType.id]
					}
					// reescribir ese objeto
					cache.writeQuery({
						query: getAllAccountsQuery,
						data,
					})
				}
			},
		},
	)

	return {
		createAccount,
		cLoading,
		cError,

		updateAccount,
		uLoading,
		uError,

		deleteAccount,
		dLoading,
		dError,
	}
}
