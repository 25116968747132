import axios from "axios";
import { uploadFileMutation, downloadFileMutation } from "../graphql/file/mutations";
import { donwloadFileQuery } from "../graphql/file/queries";

const upload = async (client, files, setFiles, index, path = 'files') => {
  const { data, loading, error } = await client.mutate({
    mutation: uploadFileMutation,
    variables: { input: { file: files[index].name, key: path } },
  });
  files[index].upload = true;
  files[index].progress = 0;
  setFiles([...files]);
  if (!loading && data) {
    let { url, name } = data.uploadFile;
    await axios.request({
      url,
      method: "put",
      data: files[index],
      headers: {
        'Content-Type': files[index].type
      },
      cancelToken: new axios.CancelToken((c) => {
        files[index].cancel = c;
        setFiles(files);
      }),
      onUploadProgress: (p) => {
        files[index].progress = Math.round((p.loaded * 100) / p.total) / 100;
        setFiles([...files]);
      },
    });
    files[index].progress = 1;
    files[index].ufid = name;
    files[index].upload = false;
    setFiles([...files]);
    return files[index];
  }
};

export const uploadFiles = async (client, files, setFiles, path) => {
  let promises = [];
  files.map((file, index) => {
    promises.push(upload(client, files, setFiles, index, path));
  });
  let _files = await Promise.all(promises);
  return _files;
};

export const downloadFile = async (client, file) => {
  const { data, loading, error } = await client.query({
    query: donwloadFileQuery,
    variables: { input: { file, key: "files" } },
  });

  if(!loading) {
    let {url, name} = data.downloadFile;
    let link = document.createElement("a");
    link.href = new URL(url);
    link.setAttribute('download', name);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  }
};


export const readURL = (file)  => {
  return new Promise((resolve, reject) => {
    if(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = error => reject(error);
    }
  });
}

export const getUrlFile = async(client, file, path = 'files') => {
  const { data, loading, error } = await client.query({
    query: donwloadFileQuery,
    variables: { input: { file, key: path } },
  });

  if(!loading) {
    let {url, name} = data.downloadFile;
    return new URL(url);
  }
}