import gql from 'graphql-tag'

export const createAccountMutation = gql`
	mutation createAccount($input: CreateAccount!) {
		createAccount(input: $input) {
			id
			name
			customId
			description
			currency {
				id
				name
				code
			}
			accountType {
				id
				name
				group
			}
		}
	}
`

export const updateAccountMutation = gql`
	mutation updateAccount($input: UpdateAccount!) {
		updateAccount(input: $input) {
			id
			name
			customId
			description
			currency {
				id
				name
				code
			}
			accountType {
				id
				name
				group
			}
		}
	}
`

export const deleteAccountMutation = gql`
	mutation deleteAccount($input: ID!) {
		deleteAccount(id: $input) {
			id
			accountType {
				id
				name
				group
			}
		}
	}
`
