// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createVendorMutation,
	deleteVendorMutation,
	updateVendorMutation,
} from './mutations'
import { getAllVendorsQuery } from './queries'

export const useVendorsService = () => {
	const [createVendor, { loading: cLoading, error: cError }] = useMutation(
		createVendorMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createVendor } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllVendorsQuery })

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllVendorsQuery,
					data: {
						vendors: { rows: [createVendor, ...data.vendors.rows] },
					},
				})
			},
		},
	)

	const [updateVendor, { loading: uLoading, error: uError }] = useMutation(
		updateVendorMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateVendor } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllVendorsQuery })
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllVendorsQuery,
					data: {
						vendors: {
							rows: data.vendors.rows.map((vendor) =>
								vendor.id === updateVendor.id
									? updateVendor
									: vendor,
							),
						},
					},
				})
			},
		},
	)

	const [deleteVendor, { loading: dLoading, error: dError }] = useMutation(
		deleteVendorMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteVendor } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllVendorsQuery })
				if(deleteVendor){
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllVendorsQuery,
					data: {
						vendors: {
							rows: data.vendors.rows.filter(
								(vendor) => vendor.id !== deleteVendor.id,
							),
						},
					},
				})
			}
			},
		},
	)

	return {
		createVendor,
		cLoading,
		cError,

		updateVendor,
		uLoading,
		uError,

		deleteVendor,
		dLoading,
		dError,
	}
}
