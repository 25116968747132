import {useMutation} from '@apollo/client'
import {
	createTaxMutation,
	deleteTaxMutation,
	updateTaxMutation,
} from './mutations'
import { getAllTaxesQuery } from './queries'

export const useTaxesService = () => {
	const [createTax, { loading: cLoading, error: cError }] = useMutation(
		createTaxMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createTax } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTaxesQuery })

				if(!data || data === null) {
					return
				}
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllTaxesQuery,
					data: {
						taxes: { rows: [createTax, ...data.taxes.rows] },
					},
				})
			},
		},
	)

	const [updateTax, { loading: uLoading, error: uError }] = useMutation(
		updateTaxMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateTax } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTaxesQuery })

				console.log(data);
				// reescribir ese objeto
				cache.writeQuery({
					query: getAllTaxesQuery,
					data: {
						taxes: {
							rows: data?.taxes ? data.taxes.rows.map((tax) =>
								tax.id === updateTax.id ? updateTax : tax,
							) : [],
						},
					},
				})
			},
		},
	)

	const [deleteTax, { loading: dLoading, error: dError }] = useMutation(
		deleteTaxMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteTax } }) {
				// obtener el objeto de cache
				const data = cache.readQuery({ query: getAllTaxesQuery })
					if(deleteTax){
					// reescribir ese objeto
					cache.writeQuery({
						query: getAllTaxesQuery,
						data: {
							taxes: {
								rows: data.taxes.rows.filter(
									(tax) => tax.id !== deleteTax.id,
								),
							},
						},
					})
				}
			},
		},
	)

	return {
		createTax,
		cLoading,
		cError,

		updateTax,
		uLoading,
		uError,

		deleteTax,
		dLoading,
		dError,
	}
}
