import gql from 'graphql-tag';

export const getAllEmployeesQuery = gql`
  query employees($options: Options, $page: Int) {
    employees(options: $options, page: $page) {
      rows {
        id
        name
        type
        status
        startDate
        endDate
        salary
        bill {
          dni
          nit
          countryId
          state
          address
        }
        contact {
          emails
          phones {
            type
            areaCode
            number
          }
          countryId
          state
          address
        }
        extra {
          civilStatus
          nationality
          bloodType
        }
      }
    }
  }
`;

export const getOneEmployeeQuery = gql`
  query employee($input: ID!, $startDate: String, $endDate: String) {
    employee(id: $input) {
      id
      name
      type
      status
      startDate
      endDate
      salary
      job
      area
      salaries {
        id
        salary
        effectiveDate
      }
      bill {
        dni
        nit
        countryId
        state
        address
      }
      contact {
        emails
        phones {
          type
          areaCode
          number
        }
        countryId
        state
        address
      }
      extra {
        civilStatus
        nationality
        bloodType
      }
      records(
        options: { where: { endDate: { _between: [$startDate, $endDate] } } }
      ) {
        id
        type
        payroll {
          startDate
          endDate
          month
          year
        }
        legalBenefit {
          id
          description
        }
        total
      }
    }
  }
`;

export const getJobs = gql`
  query jobs {
    jobs {
      label
      value
    }
  }
`;

export const getAreas = gql`
  query areas {
    areas {
      label
      value
    }
  }
`;
