import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonTextarea,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  cashOutline,
  closeOutline,
  enter,
  filterOutline,
  informationCircleOutline,
  pricetagsOutline,
  receiptOutline,
} from "ionicons/icons";
import { useForm } from "../../../hooks/useForm";
import Wrapper from "../../wrapper/Wrapper";
import { getDate } from "../../../helpers/date";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import SfnButton from "../../button/Button";
import { texts } from "../../../theme/dataTabelTexts";
import GridTable from "@nadavshaar/react-grid-table";
import Placeholder from "../../placeholder/Placeholder";
import { formatMoney, isEmpty } from "../../../helpers/utils";
import {
  getOneCreditNote,
  remainingForCreditNoteQuery,
} from "../../../graphql/creditNote/queries";
import "./CreditNoteModal.scss";
import { useCreditNoteService } from "../../../graphql/creditNote/service";
import { useHistory } from "react-router";
import { updatePage } from "../../../store/page";
const initialState = {
  date: getDate(new Date(), false),
  amount: 0,
  exchangeRate: 0,
  description: "",
  method: "",
  accountId: null,
  invoiceId: null,
  billId: null,
};

const CreditNoteModal = ({
  enterprise,
  showModal,
  setShowModal,
  creditNote = null,
  invoice = null,
  setCreditNoteId = (e) => {},
  creditNotes = [],
  setCreditNotes = () => {},
}) => {
  const { formValues, handleInputChange, reset, numberHandleInputChange } =
    useForm({ ...initialState });
  const { amount, method, accountId, description, exchangeRate, date } =
    formValues;
  const [present] = useIonToast();
  const [dataRemainingForCreditNote, setDataRemainingForCreditNote] = useState(
    []
  );
  const { createNote, cLoading, cError } = useCreditNoteService(invoice);
  const [columns, setColumns] = useState([]);
  const [load, setLoad] = useState(true);
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  useEffect(() => {
    let data = [];
    if (creditNote === null) {
      data = [
        {
          id: 1,
          label: "Producto/Servicio",
          field: "product",
          width: "250px",
          getValue: ({ value }) => value?.name,
        },
        {
          id: 2,
          label: "Monto",
          field: "amount",
          width: "max-content",
          headerCellRenderer: () => <div className="ml-auto mr-2">Monto</div>,
          cellRenderer: ({ data }) => {
            return (
              <div className="text-right w-full m-4">
                <p>{formatMoney(enterprise, data.amount, true)}</p>
              </div>
            );
          },
        },
        {
          id: 3,
          label: "Descuento",
          field: "discount",
          width: "140px",
          headerCellRenderer: () => (
            <div className="ml-auto mr-2">Descuento</div>
          ),
          cellRenderer: ({ tableManager, rowIndex, data }) => {
            return (
              <IonItem
                className="sfn-input text-right w-full"
                mode="md"
                lines="none"
              >
                <IonInput
                  value={data?.discount}
                  name="discount"
                  type="tel"
                  placeholder="Descuento"
                  onKeyPress={(e) =>
                    handleInputDiscount(e, tableManager, rowIndex - 1)
                  }
                  onChange={(e) =>
                    handleInputDiscount(e, tableManager, rowIndex - 1)
                  }
                  onKeyUp={(e) =>
                    handleInputDiscount(e, tableManager, rowIndex - 1)
                  }
                  onKeyDown={(e) =>
                    handleInputDiscount(e, tableManager, rowIndex - 1)
                  }
                  className="m-2 no-bg text-right"
                  required
                />
              </IonItem>
            );
          },
        },
      ];
    } else {
      data = [
        {
          id: 1,
          label: "Producto/Servicio",
          field: "product",
          width: "410px",
          getValue: ({ value }) => value?.name,
        },
        {
          id: 2,
          label: "Monto",
          field: "amount",
          width: "max-content",
          headerCellRenderer: () => <div className="ml-auto mr-2">Monto</div>,
          cellRenderer: ({ data }) => {
            return (
              <div className="text-right w-full m-4">
                <p>{formatMoney(enterprise, data.amount, true)}</p>
              </div>
            );
          },
        },
      ];
    }
    setColumns(data);
  }, [creditNote, invoice]);

  const {
    data,
    loading: rLoading,
    error: rError,
  } = useQuery(remainingForCreditNoteQuery, {
    variables: { invoiceId: invoice?.id || creditNote?.invoiceId },
    onCompleted(data) {
      data = { ...data };
      setDataRemainingForCreditNote(
        data?.remainingForCreditNote?.map((f) => {
          f = { ...f };
          f.discount = 0;
          return f;
        })
      );
      console.log(data);

      setLoad(false);
    },
    fetchPolicy: "no-cache",
    skip: creditNote !== null,
  });

  const {
    data: dataCreditNote,
    loading,
    error,
  } = useQuery(getOneCreditNote, {
    variables: { input: creditNote?.id },
    onCompleted(data) {
      data = { ...data };
      setDataRemainingForCreditNote(data?.note?.detail);
      reset(data?.note);
      console.log(data);
      setLoad(false);
    },
    fetchPolicy: "no-cache",
    skip: creditNote == null,
  });

  function handleInputDiscount({ target }, tableManager, index) {
    let rowsClone = [...tableManager.rowsApi.rows];
    let field = target.name;
    let value = target.value;

    let oldValue = rowsClone[index][field];
    if (!/^-?\d*[.]?\d{0,2}$/.test(value)) {
      value = oldValue;
    }
    if (Number(value) > Number(rowsClone[index].amount) || Number(value) < 0) {
      value = "";
    }
    rowsClone[index][field] = value;
    setDataRemainingForCreditNote(rowsClone);
  }

  const tableProps = {
    isPaginated: false,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
    showColumnVisibilityManager: false,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!dataRemainingForCreditNote.some((s) => s.discount > 0)) {
      present({
        message: "Debes agregar al menos un descuento 💲",
        color: "warning",
        mode: "md",
        duration: 4000,
      });
      return true;
    }
    let input = {
      description,
      invoiceId: invoice?.id,
      detail: dataRemainingForCreditNote?.map((d) => ({
        productId: d.product.id,
        amount: d.discount,
        itemId: d.itemId,
      })),
    };
    let { data: note, errors } = await createNote({ variables: { input } });
    if (!errors && note) {
      setCreditNotes([...creditNotes, note?.createNote]);
      present({
        message: `La nota de crédito se creo exitosamente! 👍`,
        color: "success",
        mode: "md",
        duration: 4000,
      });
      onClose();
      return;
    }
    if (errors) {
      const { extensions } = errors[0];
      if (extensions.code === "DTES_NOT_AVAILABLE") {
        presentAlert({
          cssClass: "sfn-alert-dialog",
          mode: "md",
          header: `No tienes DTE's disponibles`,
          subHeader: `Para emitir una nota de crédito en SAT necesitas tener DTE's (Documento tributario eléctronico) disponibles.`,
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Comprar paquete",
              cssClass: "primary",
              handler: async () => {
                onClose();
                updatePage({ tab: "plan" });
                history.push("/settings?tab=plan");
              },
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        });
      }
      return;
    }
    present({
      message: "Oops! ha ocurrido un error, intentalo nuevamente 🤔",
      color: "danger",
      mode: "ios",
      duration: 4000,
    });
  };

  const onClose = async () => {
    setShowModal(false);
    reset({ ...initialState });
  };

  const overall = () => {
    if (creditNote == null) {
      return dataRemainingForCreditNote?.reduce((acc, data) => {
        return acc + Number(data.discount);
      }, 0);
    } else {
      return dataRemainingForCreditNote?.reduce((acc, data) => {
        return acc + Number(data.amount);
      }, 0);
    }
    return 0;
  };

  return (
    <IonModal
      id="sfn-credit-note-modal"
      isOpen={showModal}
      cssClass="rounded-2xl sfn-modal-medium-height"
      onDidDismiss={() => setShowModal(false)}
    >
      <IonContent>
        <Wrapper>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Nota de crédito</h3>
            <IonIcon
              onClick={onClose}
              icon={closeOutline}
              className="flex ml-auto cursor-pointer modal-close-button"
            ></IonIcon>
          </div>

          <div className="header text-sm mb-8">
            <p>
              <strong>No. Serie</strong> {isEmpty(invoice?.extras?.serie)}
            </p>
            <p>
              <strong>No. Factura</strong> {isEmpty(invoice?.number)}
            </p>
            <p>
              <strong>Cliente</strong> {isEmpty(invoice?.customer?.name)}
            </p>
            <p>
              <strong>Identificador tribuatrio</strong>{" "}
              {isEmpty(invoice?.customer?.bill?.nit)}
            </p>

            {!invoice?.isFEL && (
               <p className="font-bold text-warning">
               Esta nota de crédito no sera emitida por la SAT.
             </p>
            )}
          </div>
          {load && !error && !rError ? (
            <div className="flex w-full h-40 content  items-center justify-center">
              <IonIcon
                className="text-8xl"
                src="assets/icon/loading.svg"
              ></IonIcon>
            </div>
          ) : columns.length &&
            dataRemainingForCreditNote?.length &&
            dataRemainingForCreditNote?.some((s) => Number(s.amount) > 0) ? (
            <GridTable
              {...tableProps}
              className={
                dataRemainingForCreditNote?.length
                  ? "sfn-data-table"
                  : "justify-start items-start max-h-80"
              }
              components={{
                NoResults: () => (
                  <Placeholder
                    icon={pricetagsOutline}
                    description="No tienes productos"
                  />
                ),
              }}
              isLoading={rLoading || loading}
              icons={{
                columnVisibility: (
                  <IonIcon icon={filterOutline} slot="icon-only" />
                ),
              }}
              columns={columns}
              rows={
                dataRemainingForCreditNote?.length
                  ? dataRemainingForCreditNote
                  : []
              }
              texts={texts}
            />
          ) : (
            <div className="px-4 py-8 text-center flex flex-col w-full h-40 rounded justify-center items-center bg-danger text-white">
              <IonIcon icon={receiptOutline} className="text-4xl mb-4" />
              <p>
                Ya no puedes crear más notas de crédito en está factura porque
                ya has aplicado el total de la misma.
              </p>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="flex col justify-between items-center mt-4 mb-4 py-8 px-4 bg-blue text-white texts-sm rounded">
              <p className="font-bold">Total nota de crédito</p>
              <p>{formatMoney(enterprise, overall(), true)}</p>
            </div>
            {creditNote == null ? (
              <IonItem className="sfn-input" mode="md" lines="none">
                <IonLabel position="stacked">
                  Motivo<span className="text-base text-danger">*</span>
                </IonLabel>
                <IonTextarea
                  readonly={creditNote !== null}
                  required={true}
                  name="description"
                  type="text"
                  rows="4"
                  onIonChange={handleInputChange}
                  value={description}
                />
              </IonItem>
            ) : (
              <div className="flex flex-col w-ful md:h-36 h-20">
                <p className="text-sm">
                  Motivo<span className="text-base text-danger">*</span>
                </p>
                <p className="text-base mt-2">{description}</p>
              </div>
            )}
            <div className="flex flex-col md:flex-row justify-end items-center mt-4">
              <IonButton
                color="medium"
                className="sfn-button"
                shape="round"
                fill="clear"
                onClick={() => setShowModal(false)}
              >
                {creditNote === null ? "Cancelar" : "Cerrar"}
              </IonButton>

              {creditNote === null && (
                <SfnButton
                  disabled={
                    dataRemainingForCreditNote?.length &&
                    !dataRemainingForCreditNote?.some(
                      (s) => Number(s.amount) > 0
                    )
                  }
                  label="Guardar nota de crédito"
                  btnClass={"ml-4"}
                  loading={cLoading}
                />
              )}
            </div>
          </form>
        </Wrapper>
      </IonContent>
    </IonModal>
  );
};

export default CreditNoteModal;
