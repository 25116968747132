import { IonContent, IonPage } from '@ionic/react'

const ComingSoonPage = () => {
    return (
        <IonPage>
            <IonContent className="sfn-content">
                <div className=" flex flex-col w-full h-full justify-center items-center ">
                    <h3 className="text-6xl font-bold">Proximamente...</h3>
                    <p>Seguimos trabajando para darte la mejor experiencia en Symfonia.</p>

                </div>
            </IonContent>
        </IonPage>
    )
}

export default ComingSoonPage
