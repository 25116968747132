import { Store } from 'pullstate';

const getItem = () => {
  try {
    const isOpen = JSON.parse(localStorage.getItem('menu'))?.open ?? true;
    return { isOpen, showIconOpen: false };
  } catch (error) {
    return { isOpen: true, showIconOpen: false };
  }
}
export const MenuStore = new Store(getItem());

export const handleMenu = (value) => {
  MenuStore.update((s) => {
    s.isOpen = value !== undefined ? value : !s.isOpen;
    localStorage.setItem('menu', JSON.stringify({ open: s.isOpen }));
  });
};
