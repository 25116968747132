import { IonContent, IonPage, IonButton, IonIcon, useIonAlert, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import {
  addCircleOutline, createOutline, eyeOutline, filterOutline, pricetagsOutline, trashOutline,
} from 'ionicons/icons';
import ButtonPopover from '../../components/button-popover/ButtonPopover';
import Header from '../../components/header/Header';
import OptionItem from '../../components/option-item/OptionItem';
import ProductModal from '../../components/modals/product-modal/ProductModal';
import Placeholder from '../../components/placeholder/Placeholder';
import { getAllProductsQuery } from '../../graphql/product/queries';
import { useProductsService } from '../../graphql/product/service';
import { texts } from '../../theme/dataTabelTexts';
import { useAuth } from '../../context/auth/AuthState';
import SearchTable from '../../components/search-table/SearchTable';
import { _productTypes } from '../../helpers/catalogs';
import { formatMoney, isEmpty } from '../../helpers/utils';
import { AuthStore } from '../../store/auth';

const BuyingsPage = () => {
  const user = AuthStore.useState(s => s.user);
  let productTypes = _productTypes.reduce((acc, data) => ({ ...acc, [data.value]: data.label }), {})

  const { data, loading, error } = useQuery(getAllProductsQuery, {
    variables: {
      options: {
        where: {
          expenseAccountId: {
            _not: null
          }
        }
      }
    }
  });


  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const history = useHistory();
  const { deleteProduct, dLoading, dError } = useProductsService();
  const [present] = useIonAlert();
  const { authState } = useAuth();
  const [presentToast] = useIonToast();

  const { enterprise } = authState;

  const ItemsPopover = ({ onHide, data }) => (
    <>
      <OptionItem onHide={onHide} event={() => goToProductPage(data.id)} icon={eyeOutline} caption="Ver detalle" />
      {!['READ'].includes(user?.role?.permissions['expenses']) && <><OptionItem onHide={onHide} event={() => handleUpdate(data.id)} icon={createOutline} caption="Editar" />
        <OptionItem onHide={onHide} event={() => handleDelete(data.id)} icon={trashOutline} caption="Eliminar" className="danger" /> </>}
    </>
  );

  const Options = ({ data }) => (<ButtonPopover options={ItemsPopover} data={data} />)


  const handleDelete = (id) => {
    present({
      cssClass: 'sfn-alert-dialog',
      mode: 'md',
      header: 'Eliminar producto',
      subHeader: 'Este producto se eliminará permanentemente',
      message: '¿Esta seguro de eliminar a este producto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar producto',
          cssClass: 'delete',
          handler: async () => {
            let resp = await deleteProduct({ variables: { input: id } });
            if (resp.errors) {
              showErrorAlert(resp.errors);
            } else {
              presentToast({ message: 'El producto se ha eliminado correctamente 👍', color: "success", mode: "ios", duration: 4000 });
            }
          }
        }
      ],
    })
  }

  const showErrorAlert = (errors) => {
    const { extensions } = errors[0];
    if (extensions?.code === "LOCKED") {
      present({
        cssClass: 'sfn-alert-dialog',
        mode: 'md',
        header: 'Oops! ha ocurrido un problema',
        subHeader: `No podemos eliminar este producto porque está asociado con lo siguiente:
         ${extensions?.bills?.map(bill => {
          return `\n• Factura ${bill?.number}`
        })}
         `,
        message: `Mostrando ${extensions?.bills?.length}/${extensions?.length} Para eliminar este producto, elimínelo de los elementos enumerados anteriormente`,
        buttons: [
          {
            text: 'Cerrar',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      })
    } else {
      presentToast({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000 });
    }
  }

  const handleUpdate = (id) => {
    setProductId(id);
    setShowModal(true);
  }

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  })


  const columns = [
    {
      id: 1,
      field: 'sku',
      label: 'Código',
      width: '100px',
      getValue: ({ value }) => isEmpty(value)
    },
    {
      id: 2,
      field: 'name',
      label: 'Nombre',
      getValue: ({ value }) => isEmpty(value)
    },
    {
      id: 3,
      field: 'description',
      label: 'Descripción',
      getValue: ({ value }) => isEmpty(value)
    },
    {
      id: 4,
      field: 'price',
      label: 'Precio',
      width: '140px',
      headerCellRenderer: () => (<div className="ml-auto mr-2">Precio</div>),
      cellRenderer: ({ data }) => {
        return (
          <div className="text-right w-full m-4">
            <p>{formatMoney(enterprise, data.price)}</p>
          </div>)
      }
    },
    {
      id: 5,
      field: 'type',
      label: 'Tipo de producto',
      getValue: ({ value }) => isEmpty(productTypes[value])
    },
    {
      id: 'option',
      label: '',
      width: "max-content",
      pinned: true,
      sortable: true,
      resizable: false,
      cellRenderer: Options
    },

  ];
  const goToProductPage = (id) => {
    history.push(`/buyings/${id}?from=buyings`);
  }

  return (
    <IonPage>
      <Header title="Productos o servicios - costos y gastos" subtitle={`Cantidad de productos: ${data?.products?.rows.length || 0}`}></Header>
      <IonContent className="sfn-content">
        <section className="flex flex-col sm:flex-row justify-end mb-4" >
          <IonButton disabled={['READ'].includes(user?.role?.permissions['expenses'])} className="sfn-button" shape="round" onClick={() => setShowModal(true)}>
            <IonIcon slot="start" icon={addCircleOutline} />
            Agregar producto/servicio
          </IonButton>
        </section>

        <GridTable
          className="max-h-screen sfn-data-table"
          columns={columns}
          rows={data?.products?.rows}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
          isLoading={loading}
          components={{
            NoResults: () => (
              <Placeholder icon={pricetagsOutline} description='Aún no tienes productos agregados haz clic en el botón "Agregar productos/servicios"' />
            ),
            Search: SearchTable
          }}
          {...tableProps(data?.products?.rows.length ? true : false)}
          onRowClick={({ data, event }) => {
            event.stopPropagation();
            goToProductPage(data.id);
          }}
        />
        {showModal && <ProductModal showModal={showModal} setShowModal={setShowModal} setProductId={setProductId} productId={productId}></ProductModal>}
      </IonContent>
    </IonPage>
  );
};

export default BuyingsPage;
