// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createBankAccountMutation,
	deleteBankAccountMutation,
	updateBankAccountMutation,
} from './mutations'
import { getAllBankAccountsQuery } from './queries'

export const useBankAccountsService = () => {
	const [
		createBankAccount,
		{ loading: cLoading, error: cError },
	] = useMutation(createBankAccountMutation, {
		errorPolicy: 'all',
		update(cache, { data: { createBankAccount } }) {
			// obtener el objeto de cache
			const data = cache.readQuery({ query: getAllBankAccountsQuery })

			// reescribir ese objeto
			cache.writeQuery({
				query: getAllBankAccountsQuery,
				data: {
					bankAccounts: {
						rows: [createBankAccount, ...data.bankAccounts.rows],
					},
				},
			})
		},
	})

	const [
		updateBankAccount,
		{ loading: uLoading, error: uError },
	] = useMutation(updateBankAccountMutation, {
		errorPolicy: 'all',
		update(cache, { data: { updateBankAccount } }) {
			// obtener el objeto de cache
			const data = cache.readQuery({ query: getAllBankAccountsQuery })

			// reescribir ese objeto
			cache.writeQuery({
				query: getAllBankAccountsQuery,
				data: {
					bankAccounts: {
						rows: data.bankAccounts.rows.map((bankAccount) =>
							bankAccount.id === updateBankAccount.id
								? updateBankAccount
								: bankAccount,
						),
					},
				},
			})
		},
	})

	const [
		deleteBankAccount,
		{ loading: dLoading, error: dError },
	] = useMutation(deleteBankAccountMutation, {
		errorPolicy: 'all',
		update(cache, { data: { deleteBankAccount } }) {
			// obtener el objeto de cache
			const data = cache.readQuery({ query: getAllBankAccountsQuery })

			// reescribir ese objeto
			cache.writeQuery({
				query: getAllBankAccountsQuery,
				data: {
					bankAccounts: {
						rows: data.bankAccounts.rows.filter(
							(bankAccount) =>
								bankAccount.id !== deleteBankAccount.id,
						),
					},
				},
			})
		},
	})

	return {
		createBankAccount,
		cLoading,
		cError,

		updateBankAccount,
		uLoading,
		uError,

		deleteBankAccount,
		dLoading,
		dError,
	}
}
