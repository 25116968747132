import gql from 'graphql-tag'

export const getAllTaxesQuery = gql`
	query taxes($options: Options, $page: Int) {
		taxes(options: $options, page: $page) {
			rows {
				id
				name
				abbreviation
				rates {
					id
					rate
				}
			}
		}
	}
`

export const getOneTaxQuery = gql`
	query tax($input: ID!) {
		tax(id: $input) {
			id
			name
			abbreviation
			description
			isRecoverable
			rates {
				id
				rate
				effectiveDate
			}
		}
	}
`
export const getAllTaxRates = gql`
	query getAllTaxRates($date: LocalDate) {
		taxRates(date: $date) {
		id
		rate
		tax {
			id
			name
			abbreviation
			accountId
		}
		}
	}
`