import { IonButton, IonContent, IonIcon, IonNote, IonPage } from '@ionic/react';
import { useState } from 'react';
import Header from '../../../../components/header/Header';
import InputDate from '../../../../components/input-date/InputDate';
import { getDate, getRange } from '../../../../helpers/date';
import { sfnSelectTheme } from '../../../../theme/sfnSelectTheme';
import Select from 'react-select';
import { ListNoOptionsMessage } from '../../../../components/select-menu-components/SelectMenuComponents';
import GridTable from '@nadavshaar/react-grid-table';
import { useAuth } from '../../../../context/auth/AuthState';
import SearchTable from '../../../../components/search-table/SearchTable';
import Placeholder from '../../../../components/placeholder/Placeholder';
import { filterOutline, receiptOutline } from 'ionicons/icons';
import { texts } from '../../../../theme/dataTabelTexts';
import Indicator from './components/Indicator';
import Toggle from '../../../../components/toggle/Toggle';

const BalanceSheetPage = () => {
  const { authState } = useAuth();
  const { enterprise } = authState;
  const [date, setDate] = useState(getDate(new Date(getRange(-1)), false));

  const indicators = {
    cash: {
      value: 12900
    },
    received: {
      value: 1200
    },
    paid: {
      value: 11200
    },
    total: {
      value: -13000
    },

  }
  const filterDate = (e) => {

  }

  const tableProps = (show) => ({
    showSearch: show,
    showRowsInformation: show,
    isPaginated: show,
    showColumnVisibilityManager: show
  });

  const options = [{
    label: 'Resumen',
    value: 'SUMMARY'
  }, {
    label: 'Detalle',
    value: 'DETAIL'
  }];

  const columns = [{
    id: 1,
    width: '60%',
    field: 'account',
    label: 'Cuentas',
  }, {
    id: 2,
    field: 'total',
    label: '13 de agosto de 2021',
    headerCellRenderer: ({ tableManager, column }) => (<div className="ml-auto mr-2">{column.label}</div>),
    cellRenderer: ({ data }) => {
      return (
        <div className="text-right w-full m-4">
          <p>{enterprise?.currency?.symbol}{(Math.round(data.total * 100) / 100).toFixed(2)} {enterprise?.currency?.code}</p>
          {(data.total !== data.convertedTotal) && <IonNote className="text-sm">{enterprise?.currency?.symbol}{(Math.round(data.convertedTotal * 100) / 100).toFixed(2) + " "}{enterprise?.currency?.code}</IonNote>}
        </div>)
    }
  }];


  return (
    <IonPage>
      <Header title="Hoja de balacne"></Header>

      <IonContent fullscreen className="sfn-content report-balance-sheet">
        <section className="flex flex-col sm:flex-row justify-around  mb-4 items-end">
          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64" >Apartir de</p>
            <Select
              isClearable={true}
              placeholder="Seleccione año"
              onChange={(e) => filterDate(e, 'customer')}
              options={[]}
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
              components={
                {
                  // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo cliente", callback:setShowCustomerModal}),
                  NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay rango de fechas" })
                }
              }
            />
          </div>
          <InputDate label="Hasta" value={date} handleInputChange={e => filterDate(e)} name="date" icon={false} />
          <div className="sm:w-64 w-full mt-4 sm:mt-0">
            <p className="text-sm mb-2 hover:text-secondary w-64">Tipo de informe</p>
            <Select
              isClearable={true}
              placeholder="Seleccione el tipo de informe"
              onChange={(e) => filterDate(e, 'customer')}
              options={[]}
              className="sfn-select z-10 mb-1"
              styles={sfnSelectTheme}
              components={
                {
                  // MenuList: (props) => MenuListButton({...props, label:"Agregar nuevo cliente", callback:setShowCustomerModal}),
                  NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay tipo de reporte" })
                }
              }
            />
          </div>
          <IonButton className="sfn-button sm:w-60 w-full mt-12 sm:mt-0" shape="round" onClick={() => null}>
            {/* <IonIcon slot="start" icon={addCircleOutline} /> */}
            Actualizar reporte
          </IonButton>
        </section>

        <Indicator loading={false} data={indicators} enterprise={enterprise} />


        <GridTable
          className="mt-10 sfn-data-table h-screen"
          // isLoading={loading}
          columns={columns}
          rows={[]}
          components={{
            NoResults: () => (
              <Placeholder icon={receiptOutline} description='Aún no tienes información' />
            ),
            Search: SearchTable
          }}
          {...tableProps(false)}
          texts={texts}
          icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
        // onRowClick={({data}) => { 
        //  goToInvoicePage(data.id);}}
        />
      </IonContent>
    </IonPage>
  )
}

export default BalanceSheetPage
