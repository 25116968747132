import { IonModal, IonItem, IonLabel, IonInput, IonIcon, IonButton, IonFooter, IonContent, IonTextarea, useIonToast, IonCheckbox } from '@ionic/react';
import { closeCircleOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { useRef, useState, useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import Wrapper from '../../wrapper/Wrapper';
import { defaultValue, formatSelectData } from '../../../helpers/utils';
import { validate, validateKey } from '../../../helpers/validations';
import './CustomerModal.scss';
import Select from 'react-select';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { areaCodes, bankAccountTypes, countries, currencies, phoneTypes } from '../../../helpers/catalogs';
import { useCustomersService } from '../../../graphql/customer/service';
import { validationMap } from './validation';
import SfnButton from '../../button/Button';
import { useApolloClient, useQuery } from '@apollo/client';
import { findCustomerByIdentifier, getOneCustomerQuery } from '../../../graphql/customer/queries';
import { AuthStore } from '../../../store/auth';

const initialState = {
    name: '',
    emails: [''],
    phones: [{ number: '', areaCode: '', type: '' }],
    bill: {
        currencyId: '', //id
        nit: ''
    },
    bank: {
        type: '', //id
        number: '',
        name: ''
    },
    description: '',
    address: {
        countryId: '',//id
        state: '',
        address: '',
        zip: ''
    },
    invoices: null
}

const CustomerModal = ({ showModal, setShowModal, customerId = null, setCustomerId= ()=>{}, afterCreate = ()=>{}}) => {

    const user = AuthStore.useState(s => s.user);
    const client = useApolloClient();
    const {formValues, handleInputChange, reset, handleInputBlur, removeCharacter} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { name, emails, description, phones, bill, bank, address } = formValues;
    const listSection = ['Datos de contacto', 'Información bancaria'];
    const [step, setStep] = useState(1);
    const [valid, setValid] = useState({});
    const [isConsumer, setIsConsumer] = useState(false)
    const { createCustomer, cLoading, cError, updateCustomer, uLoading, uError } = useCustomersService();
    const [present] = useIonToast();
    const [isLoadingSearchCustomer, setIsLoadingSearchCustomer] = useState(false);

    const { loading, data } = useQuery(getOneCustomerQuery, {
        variables: { input: customerId },
        skip: !customerId,
        fetchPolicy: "no-cache"
    });


    useEffect(() => {
        if(customerId && !loading){
            const {customer} = data;
            customer.bank.type = customer.bank.type == null ? '' : customer.bank.type
            if(customer.bill == null){
                customer.bill = {currencyId: '', nit: ''}
            }
            customer.bill.currencyId = currencies.find(c => c.value.id === customer?.bill?.currencyId) || null;
            customer.address.countryId = customer.address.countryId == null ? '' : customer.address.countryId
            customer.address.countryId = defaultValue({value: 'id', label: 'name' }, countries, customer.address.countryId);
            customer.bank.type = defaultValue({value: 'id', label: 'name'}, bankAccountTypes, customer.bank.type)
            customer.emails = Array.isArray(customer.emails) && customer.emails?.length > 0 ? customer.emails : ['']
            customer.phones = Array.isArray(customer.phones) && customer.phones?.length > 0 ? customer.phones.map(phone => {
                phone.type = defaultValue({ value: 'id', label: 'name' }, phoneTypes, phone.type);
                phone.areaCode = defaultValue({ value: 'value', label: 'label' }, areaCodes, phone.areaCode);
                return phone;
            }) : [{ number: '', areaCode: '', type: '' }]
            reset(data.customer);
        }
    }, [data])

    const handleSubmit = async (e) => {
        e.preventDefault();
            formValues.bill.currencyId = formValues.bill?.currencyId?.value?.id || null;
            formValues.bank.type = formValues.bank?.type?.value || null;
            formValues.address.countryId = formValues?.address?.countryId?.value || null;
            let input = {...validateField(formValues)};

            console.log(input);
            if (customerId) {
                delete input.createdAt;
                delete input.invoices;
                delete input.overdueAmount;
                delete input.outstandingAmount;
                delete input.totalPaid;
                await updateCustomer({ variables: { customerId, input: input } })
            } else {
                const {data, errors} = await createCustomer({ variables: { input: input } });
                if(data && !errors) {
                    afterCreate(data?.createCustomer)
                }
            }

            if (!uLoading && !cLoading) {
                if (cError || uError) {
                    present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration:4000 });
                    return
                } 
                present({ message: `El cliente se ${customerId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration:4000 })
                onClose();
                e.target.reset();
            }
            present({ message: `El cliente se ${customerId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
            e.target.reset();
        }
        
    

    const stepPage = () => {
        const content = document.querySelector('#wrapper');
        let currentStep = step === listSection.length ? 1 : 2;
        setStep(currentStep);
        content.scrollTop = 0;
    }

    const onClose = () => {
        reset({ ...initialState });
        setShowModal(false);
        setCustomerId(null);
    }

    const addElement = (type) => {
        switch (type) {
            case 'emails':
                emails.push('');
                handleInputChange(emails, 'emails')
                break;
            case 'phones':
                phones.push([{ number: '', areaCode: '', type: '' }])
                handleInputChange(phones, 'phones')
                console.log(phones);
                break;
            default:
                break;
        }
    }

    const removeElement = (type, index) => {
        switch (type) {
            case 'emails':
                handleInputChange(emails.filter((e, i) => i !== index), type);
                break;
            case 'phones':
                handleInputChange(phones.filter((e, i) => i !== index), type);
                console.log(phones, index);
                break;
            default:
                break;
        }
    }

    const validateField = (form) => {
        let data = { ...form }
        let {emails, phones} = data;

        emails = emails.filter(email => email.trim() !== '')
        phones = phones.filter(({number, areaCode, type}) => number && areaCode && type)
        .map(phone => {
            phone.type = phone?.type?.value;
            phone.areaCode = phone?.areaCode?.value;
            return phone;
        })
        let {bill, ...more} = data;
        if(bill.currencyId == null && bill.nit === '') {
            data = {...more};
        }
        return { ...data, emails, phones };
    }



    const searchCustomerByIdentifier = async(e) => {
       try {
            if(bill.nit == '' || bill.nit == null) return
            e.preventDefault();
            setIsLoadingSearchCustomer(true);
            handleInputChange('', 'name')
            handleInputChange('', 'address.address')
            const {data, errors} = await client.query({query: findCustomerByIdentifier, fetchPolicy: 'no-cache', variables: {input: bill.nit}});
            
            if(data.findCustomerByIdentifier) {
                const {address, name} = data?.findCustomerByIdentifier;
                if(name === null && address === null) {
                    present({ message: 'NIT invalido, intentalo nuevamente 🤔', color: "warning", mode: "ios", duration:4000 });
                }else {
                    present({ message: 'NIT valido!', color: "success", mode: "ios", duration:4000 });
                    handleInputChange(name, 'name')
                    handleInputChange(address, 'address.address')
                }
            }
            if(errors) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration:4000 });
            }
            setTimeout(() => {
                setIsLoadingSearchCustomer(false);
            }, 500);
       } catch (error) {
            if(error) {
                present({ message: 'NIT invalido, intentalo nuevamente 🤔', color: "warning", mode: "ios", duration:4000 });
            }
            setIsLoadingSearchCustomer(false);
       }   
    }


    return (
        <IonModal isOpen={showModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent id="contentCustomer">
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-2xl font-bold">{customerId ? 'Editar' : 'Nuevo'} Cliente</h3>
                    <p className="text-center text-sm">Completa el siguiente formulario para {customerId ? 'editar un' : 'crear un nuevo'} cliente</p>
                    <div className="flex justify-between my-5">
                        <h2 className="text-center text-xl font-bold">{listSection[step - 1]}</h2>
                        <p className="text-center text-xl">Sección {step}/{listSection.length}</p>
                    </div>
                    <div className={step != listSection.length ? 'h-full' : ' w-medium'}>
                        <div className="w-full">
                            {step == 1 && (
                                <form id='customer-form' onSubmit={handleSubmit} className="h-full p-2 mb-8">
                                    <div className="flex justify-between items-center">
                                        <IonItem className="sfn-input w-full" mode="md" lines="none">
                                            <IonLabel position="stacked">No. de identificación tributaria</IonLabel>
                                            <IonInput name="bill.nit" type="text" maxlength="13" placeholder="Indica No. de identificación tributaria del cliente o CF" onKeyDown={removeCharacter} onKeyUp={removeCharacter} onInput={removeCharacter} onIonChange={removeCharacter} value={bill.nit} autocomplete="off" disabled={isConsumer}> </IonInput>
                                        </IonItem>
                                        {user?.enterprise?.bill?.issuer !== 'NONE' && <SfnButton btnClass="mt-10 ml-2" onClick={searchCustomerByIdentifier} label="Buscar" loading={isLoadingSearchCustomer}/>}
                                    </div>
                                    <div className="flex items-center my-4">
                                        <IonCheckbox className="sfn-checkbox mr-2" checked={isConsumer} onIonChange={e => {
                                            handleInputChange(e.detail.checked ? 'CF' : '', 'bill.nit')
                                            setIsConsumer(e.detail.checked)
                                        }} />
                                        <IonLabel className="mr-1 hover:cursor-pointer" onClick={() => {
                                             handleInputChange(isConsumer ? 'CF' : '', 'bill.nit')
                                             setIsConsumer(!isConsumer)
                                        }}>Consumidor final</IonLabel>
                                    </div>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Nombre del cliente<span className="text-base text-danger">*</span></IonLabel>
                                        <IonInput name="name" type="text" onIonChange={handleInputChange} value={name} required autocomplete="off"> </IonInput>
                                    </IonItem>
                                    <div className="flex flex-col my-4">
                                        <p className="text-left text-sm mb-1 hover:text-secondary">Correo electrónico</p>
                                        {emails.map((email, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <IonItem className="sfn-input w-full" mode="md" lines="none">
                                                    <IonInput name={`emails[${index}]`} type="email" required={emails.length > 1 && emails.length - 1 !== index} placeholder="correo@electronico.com" onIonChange={handleInputChange} value={email} autocomplete="off"> </IonInput>
                                                </IonItem>
                                                {index > 0 &&
                                                    <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('emails', index))}>
                                                        <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                    </IonButton>}
                                            </div>
                                        ))}

                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('emails'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-4">
                                        <p className="text-left text-sm mb-1 hover:text-secondary">Teléfono</p>
                                        {phones.map((phone, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                                <Select value={phone.type} onChange={(e) => handleInputChange(e, `phones[${index}].type`)} placeholder="" options={formatSelectData({ value: 'id', label: 'name' }, phoneTypes)} className="sfn-select w-60" styles={sfnSelectTheme} />
                                                <Select value={phone.areaCode} onChange={(e) => handleInputChange(e, `phones[${index}].areaCode`)} placeholder="" options={areaCodes} className="sfn-select w-40" styles={sfnSelectTheme} />
                                                <IonItem className="sfn-input" mode="md" lines="none">
                                                    <IonInput name={`phones[${index}].number`} type="text" placeholder="5555-5555" value={phone.number} onIonChange={handleInputChange} autocomplete="off"> </IonInput>
                                                </IonItem>
                                                {index > 0 &&
                                                    <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (removeElement('phones', index))}>
                                                        <IonIcon slot="icon-only" color="medium" size="small" icon={trashOutline} />
                                                    </IonButton>}
                                            </div>
                                        ))}
                                        <div className="flex items-center justify-end">
                                            <IonButton className="sfn-button" shape="round" fill="clear" onClick={() => (addElement('phones'))}> + Agregar otro</IonButton>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h3 className="text-left text-xl font-bold">Datos de facturación</h3>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary">Moneda</p>
                                            <Select name="bill.currencyId" placeholder="Seleccione moneda" value={bill.currencyId} onChange={e => handleInputChange(e, 'bill.currencyId')} options={currencies} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">Dirección</IonLabel>
                                            <IonInput name="address.address" type="address" placeholder="Indica dirección" onIonChange={handleInputChange} value={address.address} autocomplete="off"> </IonInput>
                                        </IonItem>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">Ciudad</IonLabel>
                                            <IonInput name="address.state" type="text" placeholder="Indica ciudad" onIonChange={handleInputChange} value={address.state} autocomplete="off"> </IonInput>
                                        </IonItem>
                                        <div className="mt-4">
                                            <p className="text-left text-sm mb-2 hover:text-secondary">Pais</p>
                                            <Select placeholder="Selecciona un país" name="address.countryId" value={address.countryId} onChange={e => handleInputChange(e, 'address.countryId')} options={formatSelectData({ value: 'id', label: 'name' }, countries)} className="sfn-select" styles={sfnSelectTheme} />
                                        </div>
                                        <IonItem className="sfn-input" mode="md" lines="none">
                                            <IonLabel position="stacked">ZIP</IonLabel>
                                            <IonInput name="address.zip" type="text" placeholder="Indica ZIP de área" onIonChange={handleInputChange} value={address.zip} autocomplete="off"> </IonInput>
                                        </IonItem>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className="w-full">
                            {step == 2 && (
                                <form id='customer-form2' onSubmit={handleSubmit} className="h-full p-2 mb-8">
                                    <div className="mt-4">
                                        <p className="text-left text-sm mb-2 hover:text-secondary">Tipo de cuenta</p>
                                        <Select placeholder="Selecciona tipo de cuenta" name="bank.type" value={bank.type} onChange={e => handleInputChange(e, 'bank.type')} options={formatSelectData({ value: 'id', label: 'name' }, bankAccountTypes)} className="sfn-select" styles={sfnSelectTheme} />
                                    </div>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Número de cuenta bancaria</IonLabel>
                                        <IonInput name="bank.number" type="text" placeholder="000000000" onIonChange={handleInputChange} value={bank.number}> </IonInput>
                                    </IonItem>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Banco</IonLabel>
                                        <IonInput name="bank.name" type="text" placeholder="Nombre de banco de cuenta" onIonChange={handleInputChange} value={bank.name}> </IonInput>
                                    </IonItem>
                                    <IonItem className="sfn-input" mode="md" lines="none">
                                        <IonLabel position="stacked">Notas</IonLabel>
                                        <IonTextarea name="description" placeholder="Comentarios a tomar en cuenta sobre cliente..." onIonChange={handleInputChange} value={description}> </IonTextarea>
                                    </IonItem>
                                </form>
                            )}
                        </div>
                        <div className='flex flex-col md:flex-row justify-end items-center mt-4'>
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={() => onClose()}>
                                Cancelar
                            </IonButton>
                            <IonButton className="sfn-button sm:mr-8 " shape="round" fill="outline" onClick={() => stepPage()}>
                                {step === listSection.length ? 'Regresar' : 'Siguiente'}
                            </IonButton>
                            <SfnButton form={step === listSection.length ? 'customer-form2' : 'customer-form'} label={customerId ? 'Guardar cambios' : 'Guardar cliente'} loading={cLoading || uLoading} />
                        </div>
                        <br /><br />
                    </div>

                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default CustomerModal;