import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonTextarea,
  useIonToast,
} from "@ionic/react";
import {
  addOutline,
  closeOutline,
  removeOutline,
  trashOutline,
} from "ionicons/icons";
import Wrapper from "../../../../components/wrapper/Wrapper";
import { transactionsType } from "../../../../helpers/catalogs";
import { useForm } from "../../../../hooks/useForm";
import { sfnSelectTheme } from "../../../../theme/sfnSelectTheme";
import Select from "react-select";
import SfnButton from "../../../../components/button/Button";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  getOneTransactionQuery,
  transactionCategoriesQuery,
} from "../../../../graphql/transaction/queries";
import { useState } from "react";
import InputDate from "../../../../components/input-date/InputDate";
import { useTransactionsService } from "../../../../graphql/transaction/service";
import { uploadFiles } from "../../../../helpers/uploadFiles";
import UploadFiles from "../../../../components/uploadFiles/UploadFiles";
import LogFiles from "../../../../components/logFiles/LogFiles";
import { getAllTaxRates } from "../../../../graphql/tax/queries";
import { useInvoice } from "../../../../hooks/useInvoice";
import { getDate } from "../../../../helpers/date";
import SplitAmountDetail from "./SplitAmountDetail";
import { Tooltip } from "../../../../components/tooltip/Tooltip";

const initialState = {
  order: 0,
  description: "",
  date: getDate(new Date(), false),
  type: transactionsType[0],
  amount: 0,
  accountId: null,
  notes: "",
  attachments: null,
  vendorId: null,
  customerId: null,
  items: [
    {
      // description: "",
      tmpTaxAmount: 0,
      amount: 0,
      accountId: null,
      type: transactionsType[1].value,
      // order: 1,
      // vendorId: null,
      // customerId: null,
      exchangeRate: null,
      children: [],
    },
  ],
};

const TransactionModal = ({
  showModal,
  setShowModal,
  setTransactionId = () => {},
  transactionId = null,
}) => {
  const {formValues, handleInputChange, reset, resetProps} = useForm({
    ...initialState,
  });

  const {
    type,
    description,
    date,
    amount,
    items,
    accountId,
    attachments,
    notes,
  } = formValues;

  const {
    data: accountsData,
    loading: accountsLoading,
    error: accountsError,
  } = useQuery(transactionCategoriesQuery, { variables: { type: "ACCOUNT" } });

  const [present] = useIonToast();

  const {
    createTransaction,
    cLoading,
    cError,
    updateTransaction,
    uLoading,
    uError,
  } = useTransactionsService();

  const [splitTotal, setSplitTotal] = useState(amount);
  const [files, setFiles] = useState([]);
  const client = useApolloClient();

  useQuery(getOneTransactionQuery, {
    variables: { input: transactionId },
    skip: !transactionId,
    fetchPolicy: "no-cache",
    async onCompleted(data) {
      let transaction = { ...data.transaction };

      transaction.type = transactionsType.find(
        (type) => type.value === transaction.type
      );

      transaction.accountId = {
        label: transaction.accountId.name,
        value: transaction.accountId,
      };

      transaction.items.forEach((item) => {
        item.accountId.value2 = transaction.accountId.value;
        item.children = [];
        item.tmpTaxAmount = 0;
      });

      reset({...formatDataToUptade(transaction)});
      setSplitTotal(transaction.amount);
    },
  });

  const formatDataToUptade = (t) => {
    for (let i = 0; i < t.items.length; i++) {
      const item = t.items[i];

      if (!item.taxRate) continue;

      if (!Array.isArray(t.items[i - 1].children)) t.items[i - 1].children = [];

      t.items[i - 1].amount += item.amount;

      if (!t.items[i - 1].tmpTaxAmount) t.items[i - 1].tmpTaxAmount = 0;

      t.items[i - 1].tmpTaxAmount += item.amount;

      t.items[i - 1].children.push(item);

      item.accountId = {
        label: `${item.taxRate.tax.abbreviation} (${item.taxRate.rate}%)`,
        value: item.taxRate,
      };

      delete item.taxRate;

      t.items.splice(i, 1);
      i--;
    }

    return t;
  };

  const {
    data: allRates,
    loading: ratesLoading,
    error: ratesError,
  } = useQuery(getAllTaxRates, {
    variables: { date },
    fetchPolicy: "no-cache",
  });

  const getTotal = () => {
    let tmpTotal = 0;

    items.forEach((el) => {
      tmpTotal += parseInt(el.amount || 0);
    }, 0);

    setSplitTotal(tmpTotal);
  };

  const handleTypeInputChange = (e) => {
    handleInputChange(e, "type");
    handleInputChange(
      items.map((el) => {
        return {
          ...el,
          accountId: null,
          type: e.value == "DEBIT" ? "CREDIT" : "DEBIT",
        };
      }),
      "items"
    );
  };

  const onClose = () => {
    setTransactionId(null);
    reset({ ...initialState });
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormValues()) {
      let _files;
      if (files.length > 0) {
        _files = await uploadFiles(client, files, changeFiles);
      }

      handleInputChange(
        _files ? _files.map((f) => f.ufid) : attachments || null,
        "attachments"
      );

      if (transactionId) {
        await updateTransaction({ variables: { input: formValues } });

        present({
          message: `La transacción se actualizó exitosamente! 🎉`,
          color: "success",
          mode: "md",
          duration: 4000,
        });
      } else {
        await createTransaction({
          variables: { input: JSON.parse(JSON.stringify(formValues)) },
        });

        present({
          message: `La transacción se realizó exitosamente! 🎉`,
          color: "success",
          mode: "md",
          duration: 4000,
        });
      }
      onClose();
    }
  };

  const addLine = (index = false) => {
    if (items.length === 1) {
      setSplitTotal(amount);
    }

    if (index !== false) {
      items[index].children.push({
        amount: 0,
        accountId: null,
        type: type.value === "DEBIT" ? "CREDIT" : "DEBIT",
      });
      handleInputChange([...items], "items");
    } else {
      handleInputChange(
        [
          ...items,
          {
            amount: 0,
            accountId: null,
            type: type.value === "DEBIT" ? "CREDIT" : "DEBIT",
            children: [],
            tmpTaxAmount: 0,
          },
        ],
        "items"
      );
    }
  };

  const removeLine = (parentIndex, childIndex = -1) => {
    if (items.length === 1) items[0].amount = amount;

    if (childIndex > -1) {
      items[parentIndex].children = items[parentIndex].children.filter(
        (el, index) => index != childIndex
      );

      handleInputChange([...items], "items");
    } else {
      handleInputChange(
        items.filter((el, index) => index != parentIndex),
        "items"
      );
    }
  };

  const validateFormValues = () => {
    //validar select vacios
    if (formValues.accountId === null) {
      present({
        message: `Debes seleccionar una cuenta contable`,
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }

    if (items.some((e) => e.accountId === null)) {
      present({
        message: `Debes seleccionar una categoría`,
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }

    if (
      items.every(
        (item) =>
          item?.children?.length &&
          !item?.children?.every((i) =>
            i.accountId !== null &&
            Object.keys(i.accountId).length > 0 &&
            i?.accountId?.value !== "" &&
            i?.amount !== ""
          )
      )
    ) {
      present({
        message: `Debes llenar los campos de impuestos`,
        color: "danger",
        mode: "md",
        duration: 4000,
      });
      return;
    }

    // validar si tienen solo un elemento e igualarlo al monto inicial
    if (items.length === 1) formValues.items[0].amount = amount;

    // asignar el id a los valores seleccionados en los elementos select accountId, type
    if (typeof formValues.accountId === "object")
      formValues.accountId = accountId.value.id;
    if (typeof formValues.type === "object") formValues.type = type.value;

    // formatear los children en el array items
    for (let i = 0; i < formValues.items.length; i++) {
      const element = formValues.items[i];

      if (!element?.children) continue;

      formValues.items.splice(i + 1, 0, ...element.children);

      let taxes = element.children.reduce((a, c) => a + Number(c.amount), 0);
      formValues.items[i].amount = Number(formValues.items[i].amount) - taxes;
      delete formValues.items[i].children;
      delete formValues.items[i].tmpTaxAmount;
    }

    // asignar el id a los valores seleccionados en los elementos select de los items accountId
    formValues.items = items.map((item) => {
      if (typeof item.accountId === "object") {
        if (item.accountId?.value?.tax) {
          item["taxRateId"] = item.accountId.value.id;
          item.accountId = item.accountId.value.tax.accountId;
        } else {
          delete item.taxRate;
          item.accountId = item.accountId.value.id || item.accountId.value;
        }
      }
      return item;
    });
    return true;
  };

  const changeFiles = (files) => {
    setFiles(files);
    handleInputChange("", "attachments");
  };

  const deleteFiles = (e) => {
    handleInputChange(e, "attachments");
  };

  return (
    <IonModal isOpen={showModal} cssClass="sfn-modal" onDidDismiss={onClose}>
      <IonContent>
        <Wrapper>
          <IonIcon
            onClick={onClose}
            icon={closeOutline}
            className="flex ml-auto cursor-pointer modal-close-button"
          />
          <h3 className="text-center text-xl font-bold">{transactionId ? 'Editar' : 'Agregar'} transacción</h3>
          <p className="text-center">
            {transactionId ? "Edita esta transacción de dinero y guarda los cambios." : "Registra una transacción de dinero que no sean facturas, recibos y/onomina."}
          </p>

          <form onSubmit={handleSubmit} className="h-full p-2">
            <InputDate
              label="Fecha"
              handleInputChange={handleInputChange}
              name="date"
              value={date}
              icon={false}
              required
            />
            <IonItem className="sfn-input" mode="md" lines="none">
              <IonLabel position="stacked">Descripción</IonLabel>
              <IonInput
                name="description"
                placeholder="Indica nombre o descripción para esta transacción "
                type="text"
                onIonChange={handleInputChange}
                value={description}
              />
            </IonItem>

            <div className="w-full mt-4">
              <p className="text-sm mb-2 hover:text-secondary w-64">
                Cuenta<span className="text-base text-danger">*</span>
              </p>
              <Select
                options={accountsData?.transactionCategories}
                isLoading={accountsLoading}
                value={accountId}
                onChange={(e) => handleInputChange(e, "accountId")}
                placeholder="Seleccione una cuenta contable"
                className="sfn-select z-40 mb-1"
                styles={sfnSelectTheme}
                required
              />
            </div>
            <div className="w-full mt-4">
              <p className="text-sm mb-2 hover:text-secondary w-64">
                Tipo<span className="text-base text-danger">*</span>
              </p>
              <Select
                options={transactionsType}
                value={type}
                onChange={(e) => handleTypeInputChange(e, "type")}
                placeholder="Seleccione un tipo"
                className="sfn-select z-30 mb-1"
                styles={sfnSelectTheme}
                required
              />
            </div>

            {items.length === 1 && (
              <SplitAmountDetail
                amount={amount}
                handleInputChange={handleInputChange}
                index={0}
                elements={items[0]}
                addLine={addLine}
                removeLine={removeLine}
                splitButton
                type={type}
                currency={accountId?.value?.currency || null}
                taxes={allRates?.taxRates || []}
              />
            )}

            {items.length > 1 && (
              <>
                <div className="flex items-center justify-between mt-4">
                  <h3 className="font-bold text-xl">
                    {accountId?.value?.currency?.code || ""} {amount} - Monto
                    inicial
                  </h3>
                  <IonButton
                    onClick={() => addLine()}
                    fill="clear"
                    shape="round"
                    size="small"
                    className="sfn-button ml-8"
                    color="primary"
                  >
                    Agregar otra división
                  </IonButton>
                </div>
                <div className="rounded border-t-2 border-gray-white shadow-md px-6 pb-6 pt-4">
                  {items.map((el, index) => {
                    return (
                      <SplitAmountDetail
                        amount={amount}
                        key={index}
                        handleInputChange={handleInputChange}
                        index={index}
                        elements={el}
                        addLine={addLine}
                        removeLine={removeLine}
                        type={type}
                        currency={accountId?.value?.currency || {}}
                        getTotal={getTotal}
                        taxes={allRates?.taxRates || []}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {splitTotal != amount && items.length > 1 && (
              <p className="text-danger p-4 mt-2 mb-8 text-center">
                El total de los montos divididos es{" "}
                {splitTotal > amount
                  ? `${accountId?.value?.currency?.code || ""} ${
                      splitTotal - amount
                    } mayor`
                  : `${accountId?.value?.currency?.code || ""} ${
                      amount - splitTotal
                    } mayor`}{" "}
                que la transacción original. El total debe ser igual a{" "}
                {accountId?.value?.currency?.code || ""} {amount}.
              </p>
            )}

            <div className="flex flex-col my-4">
              <p className="text-left text-sm mb-2 hover:text-secondary">
                Tamaño máximo para los archivos adjuntos 5mb
              </p>
              {(attachments == null || !attachments?.length) && (
                <UploadFiles
                  files={files}
                  setFiles={changeFiles}
                  accept="application/pdf, application/vnd.ms-excel, .doc,.docx,application/msword, image/x-png, image/jpeg"
                />
              )}
              {Array.isArray(attachments) && (
                <LogFiles
                  client={client}
                  files={attachments}
                  handleDelete={(e) => {
                    deleteFiles(e);
                  }}
                />
              )}
            </div>
            <IonItem className="sfn-input" mode="md" lines="none">
              <IonLabel position="stacked">Nota (Opcional)</IonLabel>
              <IonTextarea
                name="notes"
                placeholder=""
                onIonChange={handleInputChange}
                rows="3"
                value={notes}
              />
            </IonItem>
            <div className="flex flex-col md:flex-row justify-end items-center mt-4">
              <IonButton
                color="medium"
                className="sfn-button"
                shape="round"
                fill="clear"
                onClick={onClose}
              >
                Cancelar
              </IonButton>
              <SfnButton
                disabled={
                  cLoading ||
                  uLoading ||
                  (splitTotal != amount && items.length > 1)
                }
                label={transactionId ? "Guardar cambios": "Guardar transacción"}
                btnClass={"ml-4"}
                loading={cLoading || uLoading}
              />
            </div>
          </form>
        </Wrapper>
      </IonContent>
    </IonModal>
  );
};

export default TransactionModal;
