import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, useIonToast, IonRadio, IonRadioGroup } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useForm } from "../../../hooks/useForm";
import Wrapper from "../../wrapper/Wrapper";
import Select from 'react-select';
import { sfnSelectTheme } from "../../../theme/sfnSelectTheme";
import { getDate } from "../../../helpers/date";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "../../../context/auth/AuthState";
import { useEffect, useState } from "react";
import { frequencyPayroll } from "../../../helpers/catalogs";
import SfnButton from "../../button/Button";
import './SettingPayrollModal.scss';
import { usePayrollService } from "../../../graphql/payroll/service";
import { getPayrollConfigQuery } from "../../../graphql/payroll/queries";
import { Tooltip } from "../../tooltip/Tooltip";
import moment from 'moment';
import 'moment/locale/es';

const initialState = {
    frequency: '',
    payday: 0,
    firstFortnight: 50,
    secondFortnight: 50,
}


const SettingPayrollModal = ({ showSettingPayrollModal, setShowSettingPayrollModal }) => {

    const { authState, setAuth,} = useAuth();
    const { enterprise } = authState;
    const [disabledConfig, setDisabledConfig] = useState(false)
    const {formValues, handleInputChange, reset, resetProps} = useForm(initialState);

    const { data, loading, error } = useQuery(getPayrollConfigQuery, {
        variables: { id: enterprise?.id },
        skip: !enterprise?.id,
        fetchPolicy: 'no-cache',
        async onCompleted(data) {
            handleInputChange(data.enterprise.payroll.payday, 'payday');
            setDisabledConfig(!data.enterprise.payroll.editable ? true : false);
            resetProps({ ...data.enterprise.payroll })
        }
    });
    const { frequency, payday, firstFortnight, secondFortnight } = formValues;
    const [present] = useIonToast();
    const { setPayrollConfig, sLoading, sError } = usePayrollService();


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formValues.frequency == '' || !formValues.frequency) {
            present({ message: 'Debes seleccionar una opción de frecuencia de pago', color: "warning", mode: "md", duration: 4000 });
            return;
        }

        if(formValues.frequency != 'TWICE_A_MONTH') {
            formValues.firstFortnight = 0;
            formValues.secondFortnight = 0;
        }

        delete formValues.configured
        delete formValues.editable
        let {error, data} = await setPayrollConfig({ variables: { input: { id: enterprise.id, payroll: { ...formValues, payday: parseInt(formValues.payday) } } } });

        if (!sLoading) {
            if (error) {
                present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "ios", duration: 4000});
                return
            }
            let auth = {...authState};
            auth = {...auth, enterprise: {...auth.enterprise, payroll: data?.updateEnterprise?.payroll || auth.enterprise.payroll}}
            console.log(auth)
            setAuth({user: auth,token: localStorage.getItem('token')});
            present({ message: `Se ha actualizado la configuración de nomina! 👍`, color: "success", mode: "md", duration: 4000 })
            onClose();
            e.target.reset();
        }
    }

    const onClose = () => {
        reset({ ...initialState });
        setShowSettingPayrollModal(false);
    }

    const fortnightCalculate = e => {
        let { value, name } = e.target;
        if(value < 0) {
            handleInputChange(1, name);
            return;
        }
        if(value >= 100) {
            value = 99
        }
        
        if (name == 'firstFortnight') {
            
            handleInputChange(value, name);
            handleInputChange(100 - value, 'secondFortnight');
        }else {
            handleInputChange(value, name);
            handleInputChange(100 - value, 'firstFortnight');
        }
    }

    const hanldeInputNumber = (e) => {
        let {value, name} = e.target;
        if(value < 0) {
            handleInputChange(0, name);
            return;
        }
        handleInputChange(value, name);
    }

    
    useEffect(()=>{
        moment.locale('es');
    }, []);


    return (
        <IonModal id="sfn-setting-payroll-modal" isOpen={showSettingPayrollModal} cssClass={`rounded-2xl ${frequency == '' || frequency != 'TWICE_A_MONTH' ? 'sfn-modal-medium ' : 'sfn-modal-medium-height'}`} onDidDismiss={() => setShowSettingPayrollModal(false)}>
            <IonContent>
                <Wrapper>
                    <div className="flex justify-between items-start mb-4">
                        <div className="flex flex-col">
                            <h3 className="text-xl font-bold">Configurar pago nómina</h3>
                            <p>Configura la siguiente información para calcular tu nómina</p>
                        </div>
                        <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col mt-4">
                            <p className="text-left text-base mb-2 hover:text-secondary">Indica frecuencia de pago<span className="text-base text-danger">*</span></p>
                            <IonRadioGroup onIonChange={e => handleInputChange(e.detail.value, 'frequency')} value={frequency}>
                                {frequencyPayroll.map((frequency, index) => (
                                    <div key={index} className="flex mb-4 items-start justify-start">
                                        <div className="flex">
                                            <IonRadio disabled={disabledConfig} value={frequency.value} color="secondary" />
                                        </div>
                                        <div className="flex flex-col ml-4">
                                            <p className="text-base font-bold">{frequency.label}</p>
                                            <p className="text-base text-gray-light">{frequency.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </IonRadioGroup>
                            {frequency == 'TWICE_A_MONTH' &&
                                <div className="flex flex-col">
                                    <p className="ml-10 text-left text-base hover:text-secondary">Indica el porcentaje a pagar para cada quincena</p>
                                    <div className="flex flex-col md:flex-row justify-around md:items-start items-center mb-12">
                                        <IonItem className="sfn-input w-48" mode="md" lines="none">
                                            <IonLabel position="stacked" className="text-gray-light">Primera quincena (%)<span className="text-base text-danger">*</span></IonLabel>
                                            <IonInput disabled={disabledConfig} name="firstFortnight" type="number" min="0" placeholder="00.00" value={firstFortnight} onIonChange={fortnightCalculate} required> </IonInput>
                                        </IonItem>
                                        <IonItem className="sfn-input w-52" mode="md" lines="none">
                                            <IonLabel position="stacked" className="text-gray-light">Segunda quincena (%)<span className="text-base text-danger">*</span></IonLabel>
                                            <IonInput disabled={disabledConfig} name="secondFortnight" type="number" min="0" placeholder="00.00" value={secondFortnight} onIonChange={fortnightCalculate} required> </IonInput>
                                        </IonItem>
                                    </div>
                                </div>
                            }
                            <div className="flex flex-col">
                                <div className="flex items-center">
                                    <p className="mr-1 text-base hover:text-secondary">Día de pago predeterminado</p>
                                    {(frequency === "TWICE_A_MONTH") ? <Tooltip label={`Cantidad de días después de que finalice el período para realizar el pago. Si el período del mes actual inicia hoy ${moment().format('dddd ll')} y finaliza el ${moment().add(15, 'days').format('ll')}, el pago se realizará ${payday || 5} días después. Es decir, el ${moment().add(15+(+(payday || 5)), 'days').format('ll')}.`}/> : <Tooltip label={`Cantidad de días después de que finalice el período para realizar el pago. Si el período del mes actual inicia hoy ${moment().format('dddd ll')} y finaliza el ${moment().add(30, 'days').format('ll')}, el pago se realizará ${payday || 5} días después. Es decir, el ${moment().add(30+(+(payday || 5)), 'days').format('ll')}.`}/>}
                                </div>
                                <IonItem className="sfn-input" mode="md" lines="none">
                                    <IonLabel position="stacked" className="text-gray-light">Ingrese los días después que finalice el período de nómina<span className="text-base text-danger">*</span></IonLabel>
                                    <IonInput name="payday" type="number" placeholder="Indica los días" value={payday} onIonChange={hanldeInputNumber} required> </IonInput>
                                </IonItem>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-end items-center mt-8">
                            <IonButton color="medium" className="sfn-button" shape="round" fill="clear" onClick={onClose}>
                                Cancelar
                            </IonButton>
                            <SfnButton label="Guardar configuración nómina" btnClass={'ml-4'} loading={sLoading} />
                        </div>
                    </form>
                </Wrapper>
            </IonContent>
        </IonModal>
    )
}

export default SettingPayrollModal;