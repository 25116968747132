import { IonButton, IonSkeletonText, useIonViewDidEnter } from '@ionic/react';
import { flagOutline, statsChartOutline } from 'ionicons/icons';
import { Bar } from 'react-chartjs-2';
import Placeholder from '../../../components/placeholder/Placeholder';
import Kpi from '../../../components/kpi/Kpi';
import Toggle from '../../../components/toggle/Toggle';
import { profitLossChartData, yearStatus } from '../chart';
import { useEffect, useState } from 'react';
import { getProfitAndLossQuery } from '../../../graphql/dashboard/queries';
import { useQuery } from '@apollo/client';
import { formatMoney } from '../../../helpers/utils';
import { useHistory } from 'react-router';

const ProfitLoss = ({ options, enterprise }) => {
  const [value, setValue] = useState('LAST_YEAR');
  const [data, setData] = useState(null);
  const history = useHistory()
  const { data: profitData, loading = false, error, refetch } = useQuery(getProfitAndLossQuery, {
    variables: { longAgo: value },
    fetchPolicy: 'no-cache',
    async onCompleted(data) {
      let { profitAndLoss } = data;
      if (Object.keys(profitAndLoss).length) {
        profitLossChartData.labels = profitAndLoss.losess.map(e => e.label)
        profitLossChartData.datasets[0].data = profitAndLoss.profit.map(e => e.value)
        profitLossChartData.datasets[1].data = profitAndLoss.losess.map(e => e.value)
        setData(profitLossChartData);
      }
    }
  });


  const changeValue = (e) => {
    setValue(e)
  }
  const lineOptions = options || {
    responsive: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return `${enterprise?.currency?.symbol}${value}`;
          }
        },
      },
    },
  };


  useIonViewDidEnter(() => {
    refetch();
  });



  return (
    <>
      <div className="flex flex-col md:flex-row my-12 items-start justify-between w-full">
        <div className="flex flex-col items-start mr-12 w-full md:w-2/4">
          <div className="flex flex-col justify-center mb-4 h-32">
            <h3 className="text-2xl font-bold mb-4 text-gray-blue">Ingresos y gastos</h3>
            <p className="text-base mb-8 w-full md:w-4/5">Ingresos, costos y gastos únicamente (incluye cuentas por cobrar y cuentas por pagar).</p>
          </div>
          <Kpi icon={flagOutline} className="mb-12" title={formatMoney(enterprise, profitData?.profitAndLoss?.resume[0]?.value)} description="Ingresos" loading={loading} />
          <Kpi icon={flagOutline} iconColor="danger" title={formatMoney(enterprise, profitData?.profitAndLoss?.resume[1]?.value)} description="Gastos" loading={loading} />
        </div>
        <div className="flex flex-col items-start justify-start w-full md:w-2/4">
          <div className="flex flex-col md:flex-row items-center justify-between mt-8 md:mb-4 w-full h-full">
            <Toggle id="toggle-profit" value={value} onChange={changeValue} buttons={yearStatus} className="flex items-center md:items-start justify-center md:justify-start w-full md:w-3/5 mb-4 md:mb-0" />
            <div className="hidden md:flex items-center h-12 md:h-full md:my-0">
              <IonButton className="sfn-button sm:w-60 w-full text-primary normal-case font-bold" fill="none" shape="round" onClick={() => {history.replace('/reports/profit-and-loss') }}>
              Ver reporte
            </IonButton>
            </div>
          </div>
          {loading && <IonSkeletonText animated style={{ width: '100%', height: '400px' }} />}
          {(!loading && data !== null && data) && <Bar className="mt-8 md:my-0" data={data} options={lineOptions} />}
          {(!loading && data === null || !data) && <Placeholder icon={statsChartOutline} description='Aún no tienes ingresos y gastos' />}
          <div className="md:hidden flex items-center justify-center w-full h-12 md:h-full mt-8">
            <IonButton className="sfn-button sm:w-60 w-full text-primary normal-case font-bold" fill="none" shape="round" onClick={() => { }}>
              Ver reporte
            </IonButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfitLoss;