import { IonContent, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonButton, useIonToast, IonTextarea} from "@ionic/react"
import { closeOutline, tvSharp } from "ionicons/icons"
import { useForm } from "../../../hooks/useForm"
import Wrapper from "../../wrapper/Wrapper"
import Select from 'react-select';
import InputDate from '../../input-date/InputDate';
import { defaultValue, formatSelectData, formatSelect } from '../../../helpers/utils';
import SfnButton from '../../button/Button';
import { sfnSelectTheme } from '../../../theme/sfnSelectTheme';
import { currencies, paymentMethods } from '../../../helpers/catalogs';
import { useQuery, useApolloClient } from '@apollo/client';
import { getAllTaxesQuery } from '../../../graphql/tax/queries';
import { productExpenseAccountsQuery, productAssetAccountsQuery } from '../../../graphql/account/queries';
import { useReceiptsService } from '../../../graphql/receipt/service';
import { getMerchantsQuery, getOneReceiptQuery } from "../../../graphql/receipt/queries";
import { useEffect, useState } from 'react';
import { getDate } from '../../../helpers/date';
import { getAllTaxRates } from '../../../graphql/tax/queries';
import { validate, validateKey } from '../../../helpers/validations';
import { uploadFiles } from '../../../helpers/uploadFiles';
import { validationMap } from './validation';
import Uploadfiles from '../../uploadFiles/UploadFiles';
import LogFiles from '../../logFiles/LogFiles';
import './ReceiptModal.scss';
import { useAuth } from '../../../context/auth/AuthState';
import { ListNoOptionsMessage, MenuListButton } from "../../select-menu-components/SelectMenuComponents";
import TaxModal from '../add-tax-modal/TaxModal';
import InputAutocomplete from "../../input-autocomplete/InputAutocomplete";

const initialState = {
    merchant: '',
    description: '',
    subtotal: '',
    total: '',
    date: getDate(new Date(), false),
    picture: '',
    accountId: '',
    categoryId: '',
    currencyId: '',
    taxes: [],
    method: '',
}

const ReceiptModal = ({ showReceiptModal, setShowReceiptModal, receiptId = null, setReceiptId }) => {
    const { authState } = useAuth();
    const { enterprise } = authState;
    const {formValues, handleInputChange, reset, resetProps, numberHandleInputChange} = useForm(JSON.parse(JSON.stringify(initialState)));
    const { data: allCategories, loading: categoryLoading, error: categoryError } = useQuery(productExpenseAccountsQuery);
    const { data: allAssets, loading: assetLoading, error: assetError } = useQuery(productAssetAccountsQuery);
    const { data: allMerchants, loading: merchantsLoading, error: merchantsError } = useQuery(getMerchantsQuery);

    let { data, loading, error } = useQuery(getOneReceiptQuery,
        {
            variables: { input: receiptId },
            skip: !receiptId, fetchPolicy: 'no-cache',
            async onCompleted(data) {
                data = JSON.parse(JSON.stringify(data));
                data.receipt = { ...data.receipt };
                data.receipt.accountId = data.receipt.account;
                data.receipt.categoryId = data.receipt.category;
                data.receipt.method = paymentMethods.find(p => p.value === data.receipt.method);
                data.receipt.currencyId = currencies.find(c => c.value.id === data.receipt.currency.id);
                data.receipt.taxes = formatSelectTaxes(data.receipt.taxes);
                data.receipt.picture = data.receipt.picture == '' ? data.receipt.picture : [data.receipt.picture];
                delete data.receipt.currency;
                delete data.receipt.account;
                delete data.receipt.category;
                resetProps(data.receipt);
            }
        })
    const { merchant, description, subtotal, total, date, picture, accountId, categoryId, currencyId, taxes, method } = formValues;
    const { data: allRates, loading: ratesLoading, error: ratesError, refetch: rateRefetch } = useQuery(getAllTaxRates,
        {
            variables: { date: data?.date || formValues.date },
            fetchPolicy: 'no-cache',
            async onCompleted(data) {
                handleInputChange(getUpdateRates(taxes, data?.taxRates), 'taxes');
            }
        });


    const { createReceipt, cLoading, cError, updateReceipt, uLoading, uError } = useReceiptsService();
    const [present] = useIonToast();
    const [valid, setValid] = useState({});
    const [files, setFiles] = useState([]);
    const client = useApolloClient();
    const [showTax, setShowTax] = useState(false);

    const onClose = () => {
        reset(JSON.parse(JSON.stringify(initialState)));
        setShowReceiptModal(false);
        setReceiptId(null);
    }
    const handleSubmit = async e => {
        e.preventDefault();
        formValues.subtotal = parseFloat(formValues.subtotal || 0);
        formValues.total = parseFloat(formValues.total || 0);
        const valid = validate(formValues, validationMap);
        setValid(valid);
        if (!valid.valid) {
            present({ message: 'Debes llenar todos los campos obligatorios', color: "warning", mode: "md", duration: 4000 });
            return;
        }
        let _files;
        if (files.length > 0) {
            _files = await uploadFiles(client, files, changeFiles);
        }
        let input = { ...formValues };
        input.accountId = formValues?.accountId?.value || null;
        input.categoryId = formValues?.categoryId?.value || null;
        input.method = formValues?.method?.value || null;
        input.currencyId = formValues?.currencyId?.value?.id || null;
        input.taxes = input.taxes.map(tax => tax.value.id);
        input.picture = _files ? _files[0].ufid : picture[0] || '';
        if (receiptId) {
            await updateReceipt({ variables: { input } });
        } else {
            await createReceipt({ variables: { input } });
        }

        if (cError || uError) {
            present({ message: 'Oops! ha ocurrido un error, intentalo nuevamente 🤔', color: "danger", mode: "md", duration: 4000 });
            return
        }

        if (!uLoading && !cLoading) {
            present({ message: `El recibo se ${receiptId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 });
            onClose();
            e.target.reset();
        }
        present({ message: `El recibo se ${receiptId ? 'actualizó' : 'creó'} exitosamente! 👍`, color: "success", mode: "md", duration: 4000 });
        onClose();
        e.target.reset();
    }

    const formatSelectTaxes = (allRates) => {
        return allRates.map(i => {
            return {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
        })
    }

    const calculateTaxes = () => {
        return (taxes && taxes.length) ? taxes.reduce((sum, data) => {
            return sum + data.value.rate
        }, 0) : 0;
    }

    const calculate = e => {
        const totalTaxes = calculateTaxes();
        const subtotal = parseFloat(total || 0) - totalTaxes;
        if (!isNaN(subtotal)) {
            handleInputChange(subtotal, 'subtotal');
        }
    }

    const getUpdateRates = (taxes, taxRates) => {
        if (!taxes) return [];
        let taxIds = taxes.map(t => {
            return t.tax?.id || t.value.tax.id
        })
        return taxRates && taxRates.map(i => {
            return {
                label: `${i.tax.abbreviation} (${i.rate}%)`,
                value: i
            }
        }).filter(i => taxIds.includes(i.value.tax.id))
    }

    const changeFiles = (files) => {
        setFiles(files);
        handleInputChange('', 'picture');
    }


    const deleteFiles = (e) => {
        handleInputChange(e, 'picture');
    }

    useEffect(() => {
        calculate();
    }, [total, taxes]);

    return (
        <IonModal isOpen={showReceiptModal} cssClass='sfn-modal' onDidDismiss={onClose}>
            <IonContent id="receiptModal">
                <Wrapper>
                    <IonIcon onClick={onClose} icon={closeOutline} className="flex ml-auto cursor-pointer modal-close-button"></IonIcon>
                    <h3 className="text-center text-2xl font-bold">{receiptId ? 'Editar' : 'Agregar'} recibo</h3>
                    <p className="text-center text-sm">Recibo es un registro de un gasto realizado a un establecimiento o persona que no es parte de tus proveedores.</p>

                    <form id="receipt-form" onSubmit={handleSubmit} className="h-full p-2">
                        <InputAutocomplete label="Establecimiento" required={true} name="merchant" type="text" onChange={handleInputChange} value={merchant} placeholder="Indica el nombre de establecimiento" options={allMerchants?.getMerchants} />
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'method') ? 'text-danger' : ''}`}>Metodo de pago<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona metodo de pago" value={method} onChange={e => handleInputChange(e, 'method')} options={paymentMethods} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        <div className="flex">
                            <InputDate className="sm:w-1/2" label="Fecha de pago" value={date} handleInput={handleInputChange} name="date" icon={false} required={true}></InputDate>
                            <IonItem className="sfn-input ml-4 w-1/2" mode="md" lines="none">
                                <IonLabel position="stacked">Total<span className="text-base text-danger">*</span></IonLabel>
                                <IonInput name="total" placeholder="0.00" type="text" 
                                onKeyPress={numberHandleInputChange} 
                                onIonChange={numberHandleInputChange} 
                                onKeyUp={numberHandleInputChange} 
                                onKeyDown={numberHandleInputChange}
                                 value={total} required />
                            </IonItem>
                        </div>
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'categoryId') ? 'text-danger' : ''}`}>Categoría<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona categoría" value={categoryId} onChange={e => handleInputChange(e, 'categoryId')} options={allCategories?.productAccounts} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'accountId') ? 'text-danger' : ''}`}>Cuenta de pago<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona cuenta de pago" value={accountId} onChange={e => handleInputChange(e, 'accountId')} options={allAssets?.productAccounts} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        <div className="flex flex-col my-4">
                            <p className="text-left text-sm mb-2 hover:text-secondary">Tamaño máximo para los archivos adjuntos 5mb</p>
                            {(picture == null || !picture?.length) && <Uploadfiles files={files} setFiles={changeFiles} accept='image/x-png, image/jpeg' />}
                            {Array.isArray(picture) && <LogFiles client={client} files={picture} handleDelete={deleteFiles} />}
                        </div>
                        <div className="mt-4">
                            <p className={`text-left text-sm mb-2 hover:text-secondary ${validateKey(valid, 'currencyId') ? 'text-danger' : ''}`}>Moneda<span className="text-base text-danger">*</span></p>
                            <Select placeholder="Selecciona moneda" value={currencyId} onChange={e => handleInputChange(e, 'currencyId')} options={currencies} className="sfn-select" styles={sfnSelectTheme} />
                        </div>
                        {/* <div className="mt-4">
                            <p className="text-left text-sm mb-2 hover:text-secondary">Impuesto</p>
                            <Select placeholder="Selecciona impuesto"
                                isMulti={true}
                                value={taxes}
                                onChange={e => handleInputChange(e, 'taxes')}
                                options={formatSelectTaxes(allRates?.taxRates || [])}
                                className="sfn-select" styles={sfnSelectTheme}
                                components={{
                                    NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay impuesto" }),
                                    MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo impuesto", callback: setShowTax }),
                                }}
                            />
                        </div> */}
                        <IonItem className="sfn-input" mode="md" lines="none">
                            <IonLabel position="stacked">Notas</IonLabel>
                            <IonTextarea name="description" placeholder="Comentarios a tomar en cuenta del recibo" onIonChange={handleInputChange} value={description}> </IonTextarea>
                        </IonItem>
                        <div className="summary p-4 mb-8 w-full" >
                            <div className="flex justify-end my-2 mr-4">
                                <p>SUBTOTAL</p>
                                <p className="totals">{currencyId?.value?.symbol || enterprise?.currency?.symbol}. {subtotal}</p>
                            </div>
                            {/* <div className="flex justify-end my-2 mr-4">
                                <p>IMPUESTOS</p>
                                <p className="totals">{currencyId?.value?.symbol || enterprise?.currency?.symbol}. {calculateTaxes()}</p>
                            </div> */}
                            <div className="flex justify-end items-center mt-8 rounded py-4 pr-4" style={{ background: '#3D4D5A', color: '#ffffff' }}>
                                <p className="font-bold mr-8">TOTAL</p>
                                <p className="font-bold totals">{currencyId?.value?.symbol || enterprise?.currency?.symbol}. {total || 0}</p>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-end items-center mt-4">
                            <IonButton className="sfn-button" shape="round" fill="none" onClick={onClose}>
                                Cancelar
                            </IonButton>
                            <SfnButton form="receipt-form" label={receiptId ? 'Guardar cambios' : "Guardar recibo"} loading={cLoading || uLoading} />
                        </div>
                    </form>
                </Wrapper>
            </IonContent>
            {showTax && <TaxModal showModal={showTax} setShowModal={(e) => { setShowTax(e); rateRefetch(); }} />}
        </IonModal>
    )
}

export default ReceiptModal
