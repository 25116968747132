import { IonButton, IonIcon, IonProgressBar, useIonToast } from '@ionic/react'
import { closeCircleOutline, closeOutline, documentOutline, downloadOutline, trashOutline, tvSharp } from "ionicons/icons"
import { useEffect, useState } from 'react';
import './LogFiles.scss';
import { downloadFile } from '../../helpers/uploadFiles'

const LogFiles = ({ client, files, handleDelete = () =>{} }) => {
    const [present] = useIonToast();

    const deleteFiles = (file, i) => {
        handleDelete([...files.filter((f, index) => index !== i)])
    }


    return (
        <div id="logContent">
            <div className="loger">
                {files.length ?
                    (<ul className="list mt-4 shadow">
                        {files.map((file, index) => (
                            <li className="item" key={index}>
                                <IonIcon icon={documentOutline} className="flex ml-auto"></IonIcon>
                                <p>{file}</p>
                                <div className="actions">
                                    <IonIcon icon={downloadOutline} className="flex ml-auto cursor-pointer" onClick={() => downloadFile(client, file)}></IonIcon>
                                    <IonIcon icon={trashOutline} className="flex ml-auto cursor-pointer" onClick={() => {deleteFiles(file, index)}}></IonIcon>
                                </div>
                            </li>
                        ))}
                    </ul>) : ''
                }
            </div>
        </div>
    );

}

export default LogFiles;