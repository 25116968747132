import { Line } from 'react-chartjs-2';


const TransactionsLineChart = ({ chartData=[], enterprise }) => {

    const data = {
        labels: chartData.flatMap(x => x.label),
        datasets: [
            {
                label: '',
                data: chartData.flatMap(x => x.value),
                fill: false,
                backgroundColor: 'rgb(0,221,169)',
                borderColor: 'rgba(0,221,169, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, values) {
                        return `${enterprise?.currency?.symbol}${value}`;
                    }
                },
            }
        },
        plugins:{
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${enterprise?.currency?.symbol}${context.parsed.y}`;
                    }
                }
            }
        }
    };


    return (
        <Line data={data} options={options}/>
    )
};

export default TransactionsLineChart;