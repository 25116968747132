import { IonRouterOutlet } from '@ionic/react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import LoginPage from '../pages/login/LoginPage';
import RegistryPage from '../pages/registry/RegistryPage';
import ResetPasswordPage from '../pages/reset-password/ResetPasswordPage';

export const PublicRoutes = ({authStore}) => {

    return (
        <IonRouterOutlet>
            <Route exact path='/signup' render={props => <RegistryPage />} />
            <Route exact path='/reset' render={props => <ResetPasswordPage {...props} />} />
            <Route exact path='/login' render={props => !authStore.isAuthenticated ? <LoginPage /> : <Redirect to="/" />} />
            <Route exact path="/" render={(props) => authStore.isAuthenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />} />
            <Route render={() => <Redirect to="/login" />} />
        </IonRouterOutlet>
    )
}

