import AccountsPage from "../pages/accounts/AccountsPage";
import BusinessesPage from "../pages/businesses/BusinessesPage";
import BuyingsPage from "../pages/buyings/BuyingsPage";
import ComingSoonPage from "../pages/coming-soon/ComingSoonPage";
import CustomerPage from "../pages/customer/CustomerPage";
import CustomersPage from "../pages/customers/CustomersPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import DetailRecordPayrollPage from "../pages/DetailRecordPayroll/DetailRecordPayrollPage";
import EmployeePage from "../pages/employee/EmployeePage";
import ExpensesPage from "../pages/expenses/ExpensesPage";
import InvoicePage from "../pages/invoice/InvoicePage";
import InvoicesPage from "../pages/invoices/InvoicesPage";
import PayrollPage from "../pages/payroll/PayrollPage";
import PayrollsPage from "../pages/payrolls/PayrollsPage";
import ProductPage from "../pages/product/ProductPage";
import ReceiptsPage from "../pages/receipts/ReceiptsPage";
import AgedPayablesPage from "../pages/reports/components/aged-payables-page/AgedPayablesPage";
import AgedReceivablesPage from "../pages/reports/components/aged-receivables-page/AgedReceivablesPage";
import BalanceSheetPage from "../pages/reports/components/balance-sheet-page/BalanceSheetPage";
import CashFlowPage from "../pages/reports/components/cash-flow-page/CashFlowPage";
import IncomeByCustomerPage from "../pages/reports/components/income-by-customer-page/IncomeByCustomerPage";
import ProfitAndLossPage from "../pages/reports/components/profit-and-loss-page/ProfitAndLossPage";
import PurchaseByVendorPage from "../pages/reports/components/purchase-by-vendor-page/PurchaseByVendorPage";
import ReportsPage from "../pages/reports/ReportsPage";
import SellingPage from "../pages/selling/SellingPage";
import SettingsPage from "../pages/settings/SettingsPage";
import TransactionsPage from "../pages/transactions/TransactionsPage";
import UserManagementPage from "../pages/user-management/UserManagementPage";
import VendorsPage from "../pages/vendors/VendorsPage";

export const routes = [{
    path: '/accounts',
    screen: props => <AccountsPage {...props} />
},{
    path: '/payrolls',
    screen: props => <PayrollsPage {...props} />
},{
    path: '/payroll',
    screen: props => <PayrollPage {...props} />
},{
    path: '/payroll/:payrollId',
    screen: props => <DetailRecordPayrollPage {...props} />
},{
    path: '/businesses',
    screen: props => <BusinessesPage {...props} />
},{
    path: '/customers',
    screen: props => <CustomersPage {...props} />
},{
    path: '/customers/:customerId',
    screen: props => <CustomerPage {...props} />
},{
    path: '/buyings',
    screen: props => <BuyingsPage {...props} />
},{
    path: '/buyings/:id',
    screen: props => <ProductPage {...props} />
},{
    path: '/dashboard',
    screen: props => <DashboardPage {...props} />
},{
    path: '/expenses/:billId',
    screen: props => <InvoicePage {...props} />
},{
    path: '/expenses',
    screen: props => <ExpensesPage {...props} />
},{
    path: '/receipts',
    screen: props => <ReceiptsPage {...props} />
},{
    path: '/invoices',
    screen: props => <InvoicesPage {...props} />
},{
    path: '/invoices/:invoiceId',
    screen: props => <InvoicePage {...props} />
},{
    path: '/reports',
    screen: props => <ReportsPage {...props} />
},{
    path: '/reports/aged-payables',
    screen: props => <AgedPayablesPage {...props} />
},{
    path: '/reports/aged-receivables',
    screen: props => <AgedReceivablesPage {...props} />
},{
    path: '/reports/balance-sheet',
    screen: props => <BalanceSheetPage {...props} />
},{
    path: '/reports/profit-and-loss',
    screen: props => <ProfitAndLossPage {...props} />
},{
    path: '/reports/purchase-by-vendor',
    screen: props => <PurchaseByVendorPage {...props} />
},{
    path: '/reports/income-by-customer',
    screen: props => <IncomeByCustomerPage {...props} />
},{
    path: '/selling',
    screen: props => <SellingPage {...props} />
},{
    path: '/selling/:id',
    screen: props => <ProductPage {...props} />
},{
    path: '/vendors',
    screen: props => <VendorsPage {...props} />
},{
    path: '/vendors/:vendorId',
    screen: props => <CustomerPage {...props} />
},{
    path: '/employee/:id',
    screen: props => <EmployeePage {...props} />
},{
    path: '/settings',
    screen: props => <SettingsPage {...props} />
},{
    path: '/user-management',
    screen: props => <UserManagementPage {...props} />
},
// {
//     path: '/reports/cash-flow',
//     screen: props => <CashFlowPage {...props} />
// },
{
    path: '/transactions',
    screen: props => <TransactionsPage {...props} />
},{
    path: '/coming-soon',
    screen: props => <ComingSoonPage {...props} />
},]