// prettier-ignore

import {useMutation} from '@apollo/client'
import { getOneInvoicePaymentQuery } from '../invoice/queries'
import {createCreditNoteMutation} from './mutations'

export const useCreditNoteService = (invoice) => {
	const [createNote, { loading: cLoading, error: cError }] = useMutation(
		createCreditNoteMutation,
		{
			errorPolicy: 'all',
			refetchQueries: [{ query: getOneInvoicePaymentQuery, variables: { input: invoice?.id }}]
		},
	)

	
	return {
		createNote,
		cLoading,
		cError,

		
	}
}
