import {
  IonContent,
  IonIcon,
  IonNote,
  IonPage,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  calendarClearOutline,
  cashOutline,
  createOutline,
  documentAttachOutline,
  downloadOutline,
  receiptOutline,
  trashOutline,
} from "ionicons/icons";
import Header from "../../components/header/Header";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  downloadInvoice,
  getOneInvoicePaymentQuery,
} from "../../graphql/invoice/queries";
import { getOneBillPaymentQuery } from "../../graphql/bill/queries";
import { useHistory } from "react-router";
import OptionItem from "../../components/option-item/OptionItem";
import ButtonPopover from "../../components/button-popover/ButtonPopover";
import { paymentMethodsName, statusName } from "../../helpers/catalogs";
import { useEffect, useState } from "react";
import PaymentRecordModal from "../../components/modals/payment-record-modal/PaymentRecordModal";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Kpi from "../../components/kpi/Kpi";
import { formatMoney, isEmpty, roundValue } from "../../helpers/utils";
import InvoiceModal from "../../components/modals/invoice-modal/InvoiceModal";
import { useBillsService } from "../../graphql/bill/service";
import { useInvoicesService } from "../../graphql/invoice/service";
import ExpenseModal from "../../components/modals/expense-modal/ExpenseModal";
import { parseDate } from "../../helpers/date";
import { useAuth } from "../../context/auth/AuthState";
import Tabs from "../../components/tabs/Tabs";
import RecordTab from "./tabs/record-tab/RecordTab";
import CreditNotesTab from "./tabs/credit-notes-tab/CreditNotesTab";
import { Tooltip } from "../../components/tooltip/Tooltip";
import TooltipCreditNote from "./components/tooltip-credit-note/TooltipCreditNote";
import { AuthStore } from "../../store/auth";
import { updatePage } from "../../store/page";

const InvoicePage = ({ match }) => {
  const { params } = match;
  const { authState } = useAuth();
  const user = AuthStore.useState((s) => s.user);
  const history = useHistory();
  const [showPaymentRecordModal, setShowPaymentRecordModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [payment, setPayment] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [billId, setBillId] = useState(null);
  const [document, setDocument] = useState(null);
  const [present] = useIonAlert();
  const { deleteInvoice, generateInvoice, diLoading, diError } =
    useInvoicesService();
  const { deleteBill, dbLoading, dbError } = useBillsService();
  const [presentToast] = useIonToast();
  const [selected, setSelected] = useState("record");
  const client = useApolloClient();

  const { data: dataInvoice, loading: iLoading } = useQuery(
    getOneInvoicePaymentQuery,
    {
      variables: { input: invoiceId },
      skip: !invoiceId,
      fetchPolicy: "network-only",
    }
  );
  const { data: dataBill, loading: bLoading } = useQuery(
    getOneBillPaymentQuery,
    {
      variables: { input: billId },
      skip: !billId,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setSelected("record");
  },[])

  useEffect(() => {
    setSelected("record");
    if (params?.invoiceId) setInvoiceId(params.invoiceId);
    if (params?.billId) setBillId(params.billId);
    if (dataInvoice) setDocument(dataInvoice.invoice);
    if (dataBill) setDocument(dataBill.bill);
  }, [match, dataInvoice, dataBill]);

  const handleGenerateInvoice = async (input) => {
    let { errors, data } = await generateInvoice({
      variables: { input: input?.id },
      refetchQueries: [
        { query: getOneInvoicePaymentQuery, variables: { input: invoiceId } },
      ],
    });
    if (!errors && data) {
      presentToast({
        message: "Se ha emitido y registrado factura FEL en SAT exitosamente",
        color: "success",
        mode: "ios",
        duration: 4000,
      });
      return;
    }
    if (errors) {
      let message = "";
      const { extensions } = errors[0];
      if (extensions?.code === "DTES_NOT_AVAILABLE") {
        present({
          cssClass: "sfn-alert-dialog",
          mode: "md",
          header: `No tienes DTE's disponibles`,
          subHeader: `Para emitir una factura en SAT necesitas tener DTE's  (Documento tributario eléctronico) disponibles.`,
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Comprar paquete",
              cssClass: "primary",
              handler: async () => {
                updatePage({ tab: "plan" });
                history.push("/settings?tab=plan");
              },
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        });
        return;
      }
      if (extensions?.code === "INVALID_ISSUER_CREDENTIALS") {
        message = `La factura no. ${isEmpty(
          input?.number
        )} no ha podido ser emitida hacia la SAT. Por favor verifica la definición de tu proveedor de facturación en la sección Configuración, pestaña Facturación.`;
      }
      if (
        extensions?.code === "FAILED_ELECTRONIC_INVOICE" &&
        extensions?.errors?.length
      ) {
        message = `La factura no. ${isEmpty(
          input?.number
        )} no ha podido ser emitida hacia la SAT. Por favor verifica lo siguiente: \n ${extensions?.errors?.map(
          (e) => `\n• ${e}`
        )}`;
      } else {
        message = `La factura no. ${isEmpty(
          input?.number
        )} no ha podido ser emitida hacia la SAT. Por favor contactate con nuestro equipo de soporte y envianos tus comentarios.`;
      }
      present({
        cssClass: "sfn-alert-dialog",
        mode: "md",
        header: "No se ha podido emitir factura",
        subHeader: message,
        // message: ``,
        buttons: [
          {
            text: "Cerrar",
            role: "cancel",
            cssClass: "secondary",
          },
        ],
      });
    }
  };

  const handleDownloadInvoice = async (input) => {
    const { data } = await client.query({
      query: downloadInvoice,
      variables: { input: input.id },
    });

    if (data?.downloadInvoice) {
      fetch(data?.downloadInvoice)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = input.number ? input.number : input.id;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => null);
    }
    console.log(data);
  };

  const ItemsInvoicePopover = ({ data, onHide }) => (
    <>
      {/* <OptionItem onHide={onHide} event={() => goToInvoicePage(data.id)} icon={eyeOutline} caption="Ver detalle" /> */}
      {params.invoiceId && !data?.isFEL && (
        <OptionItem
          onHide={onHide}
          event={handleUpdateInvoice}
          icon={createOutline}
          caption="Editar"
        />
      )}
      {params.billId && (
        <OptionItem
          onHide={onHide}
          event={handleUpdateInvoice}
          icon={createOutline}
          caption="Editar"
        />
      )}
      {params.invoiceId && !data?.isFEL && (
        <OptionItem
          onHide={onHide}
          event={() => handleGenerateInvoice(data)}
          icon={documentAttachOutline}
          caption="Emitir factura FEL en SAT"
        />
      )}
      {params.invoiceId && data?.isFEL && (
        <OptionItem
          onHide={onHide}
          event={() => handleDownloadInvoice(data)}
          icon={downloadOutline}
          caption="Descargar factura"
        />
      )}
      <OptionItem
        onHide={onHide}
        event={handleDeleteInvoice}
        icon={trashOutline}
        caption="Anular"
        className="danger"
      />
    </>
  );
  const OptionsInvoice = () => (
    <ButtonPopover data={document} options={ItemsInvoicePopover} />
  );

  const handleDeleteInvoice = () => {
    present({
      cssClass: "sfn-alert-dialog",
      mode: "md",
      header: "Anular factura",
      subHeader: "Esta factura se anulará permanentemente",
      message: "¿Esta seguro de anular a esta factura?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Anular factura",
          cssClass: "delete",
          handler: () => {
            if (invoiceId) deleteInvoice({ variables: { input: invoiceId } });
            if (billId) deleteBill({ variables: { input: billId } });
            history.goBack();
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
    if (dbError || diError) {
      console.log("ERROR");
    }
  };
  const handleUpdateInvoice = () => {
    console.log(document?.id);
    if (invoiceId) setShowInvoiceModal(true);
    if (billId) setShowBillModal(true);
  };

  const goToCustomerPage = () => {
    if (invoiceId) history.push(`/customers/${document?.customer?.id}`);
    if (billId) history.push(`/vendors/${document?.customer?.id}`);
  };

  const tableProps = {
    isPaginated: document?.payments?.length > 19 ? true : false,
    showSearch: false,
    highlightSearch: false,
    showRowsInformation: false,
  };

  const breads = [
    {
      title: `${invoiceId ? "Facturas de venta" : "Facturas de compra"}`,
      url: invoiceId ? "/invoices" : "/expenses",
    },
    {
      title: `No. Factura ${document?.number}`,
    },
  ];

  const InvoiceHeader = () => (
    <div className="shadow-lg p-6 sm:p-12 rounded-2xl border-t-2 border-gray-white">
      <div
        style={{ position: "absolute", right: "32px" }}
        className="flex md:hidden"
      >
        {document?.status !== "CANCELLED" && <OptionsInvoice />}
      </div>
      <div className="flex flex-col md:flex-row w-full justify-between items-center text-center md:text-left">
        <div className="flex">
          <div className="bg-gray-blue rounded-full p-4 sm:w-16 w-12 h-12 sm:h-16 hidden sm:flex justify-center items-center">
            <IonIcon color="light" size="large" icon={receiptOutline}></IonIcon>
          </div>
          <div className="flex flex-col sm:ml-4 justify-center">
            <h3 className="font-bold text-3xl text-gray-blue">
              Factura {document?.number}
            </h3>
            <h3
              className="text-base hover:text-primary cursor-pointer mt-1 text-gray-blue"
              onClick={goToCustomerPage}
            >
              {document?.customer?.name}{" "}
              {document?.customer?.bill?.nit
                ? "| " + document?.customer?.bill?.nit
                : ""}
            </h3>
            {/* <h3 className="text-base  mt-1 text-gray-blue">{document?.customer?.bill?.nit}</h3>  */}
          </div>
        </div>
        <div className="flex flex-col md:my-0 my-8">
          <IonNote className="font-bold text-3xl text-gray-blue">{`${
            document?.currency?.symbol || "¤"
          }${document?.total || 0} ${document?.currency?.code || ""}`}</IonNote>
          <div className="flex flex-row">
            <p className="text-sm sm:text-base mt-1 text-gray-blue  flex  items-center">
              Total de factura{" "}
            </p>
            <TooltipCreditNote
              enterprise={user?.enterprise}
              invoice={document}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <IonNote className={`font-bold text-3xl ${document?.status}`}>
            {statusName[document?.status]}
          </IonNote>
          <p className="text-sm sm:text-base mt-1 text-gray-blue">Estado</p>
        </div>
        <div className="hidden md:flex">
          {document?.status !== "CANCELLED" &&
            !(
              ["READ"].includes(user?.role?.permissions["expenses"]) &&
              ["READ"].includes(user?.role?.permissions["sales"])
            ) && <OptionsInvoice />}
        </div>
      </div>
    </div>
  );

  const payIcon = () => (
    <IonIcon
      className="text-4xl mr-6"
      slot="start"
      src="../assets/icon/income.svg"
    />
  );

  const tabs = invoiceId ? [
    { title: "Historial de pagos", value: "record" },
    { title: "Notas de crédito", value: "credit" },
  ] : [{ title: "Historial de pagos", value: "record" }];

  const componentsTabs = {
    record: (
      <RecordTab
        data={document}
        authState={authState}
        payment={payment}
        setPayment={setPayment}
        dataInvoice={dataInvoice}
        dataBill={dataBill}
        invoiceId={invoiceId}
        billId={billId}
        setData={setDocument}
        iLoading={iLoading}
        bLoading={bLoading}
        setShowPaymentRecordModal={setShowPaymentRecordModal}
      />
    ),
    credit: (
      <CreditNotesTab
        authState={authState}
        enterprise={user?.enterprise}
        invoice={document}
        setInvoice={setDocument}
        loading={iLoading}
      />
    ),
  };

  return (
    <IonPage>
      <Header
        title={`Detalle de factura de ${invoiceId ? "venta" : "gasto"}`}
      />
      <IonContent className="sfn-content">
        <BreadCrumbs className="md:-ml-6 ml-0 mb-12" breads={breads} />

        <InvoiceHeader />
        <section className="flex flex-col sm:flex-row sm:py-8 mt-8 justify-between">
          <Kpi
            loading={iLoading || bLoading}
            icon={calendarClearOutline}
            className="small"
            title={parseDate(document?.date).replace(/\//g, "-") || ""}
            description="Fecha de creación"
          />
          <Kpi
            loading={iLoading || bLoading}
            icon={calendarClearOutline}
            className="small mt-6 md:m-0"
            title={parseDate(document?.dueDate).replace(/\//g, "-") || ""}
            description="Fecha límite de pago"
          />
          <Kpi
            loading={iLoading || bLoading}
            customIcon={payIcon}
            className="small my-6 md:m-0"
            title={formatMoney(
              document,
              roundValue(document?.total - document?.remaining),
              true
            )}
            description="Total pagado"
          />
          <Kpi
            loading={iLoading || bLoading}
            icon={cashOutline}
            className="small mb-8 sm:mb-0"
            title={formatMoney(document, document?.remaining, true)}
            description="Saldo pendiente de pago"
          />
        </section>
        <div className="w-full flex flex-col justify-center items-start mt-16">
          <div className="w-full border-b-2 border-gray-white mb-4">
            <Tabs
              className="w-full text-left justify-start flex"
              tabs={tabs}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          {componentsTabs[selected]}
        </div>
      </IonContent>

      {showPaymentRecordModal && (
        <PaymentRecordModal
          setBillId={setBillId}
          billId={billId}
          setInvoiceId={setInvoiceId}
          invoiceId={invoiceId}
          payment={payment}
          setPayment={setPayment}
          showPaymentRecordModal={showPaymentRecordModal}
          setShowPaymentRecordModal={setShowPaymentRecordModal}
        />
      )}
      {showInvoiceModal && (
        <InvoiceModal
          invoiceId={invoiceId}
          setInvoiceId={setInvoiceId}
          showModal={showInvoiceModal}
          setShowModal={setShowInvoiceModal}
        />
      )}
      {showBillModal && (
        <ExpenseModal
          billId={billId}
          setBillId={setBillId}
          showExpenseModal={showBillModal}
          setShowExpenseModal={setShowBillModal}
        />
      )}
    </IonPage>
  );
};

export default InvoicePage;
