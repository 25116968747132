// prettier-ignore

import {useMutation} from '@apollo/client'
import {
	createEmployeeMutation,
	deleteEmployeeMutation,
	updateEmployeeMutation,
} from './mutations'
import { getAllEmployeesQuery } from './queries'

export const useEmployeesService = () => {
	const [createEmployee, { loading: cLoading, error: cError }] = useMutation(
		createEmployeeMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { createEmployee } }) {
				// obtener el objeto de cache
				let data = cache.readQuery({ query: getAllEmployeesQuery })
				data = data == null || !data || data === undefined ? [] : data

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllEmployeesQuery,
					data: {
						employees: { rows: [createEmployee, ...data.employees.rows] },
					},
				})
			},
		},
	)

	const [updateEmployee, { loading: uLoading, error: uError }] = useMutation(
		updateEmployeeMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { updateEmployee } }) {
				// obtener el objeto de cache
				let data = cache.readQuery({ query: getAllEmployeesQuery })
				data = data == null || !data || data === undefined ? [] : data

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllEmployeesQuery,
					data: {
						employees: {
							rows: data?.employees?.rows?.map((employee) =>
								employee.id === updateEmployee.id
									? updateEmployee
									: employee,
							),
						},
					},
				})
			},
		},
	)

	const [deleteEmployee, { loading: dLoading, error: dError }] = useMutation(
		deleteEmployeeMutation,
		{
			errorPolicy: 'all',
			update(cache, { data: { deleteEmployee } }) {
				// obtener el objeto de cache
				let data = cache.readQuery({ query: getAllEmployeesQuery })
				data = data == null || !data || data === undefined ? [] : data

				// reescribir ese objeto
				cache.writeQuery({
					query: getAllEmployeesQuery,
					data: {
						employees: {
							rows: data?.employees?.rows?.filter(
								(employee) => employee.id !== deleteEmployee.id,
							),
						},
					},
				})
			},
		},
	)

	return {
		createEmployee,
		cLoading,
		cError,

		updateEmployee,
		uLoading,
		uError,

		deleteEmployee,
		dLoading,
		dError,
	}
}
