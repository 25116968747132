import { cashOutline, fileTrayOutline, filterOutline, timeOutline } from "ionicons/icons"
import IconText from "../../../../components/icon-text/IconText"
import Placeholder from "../../../../components/placeholder/Placeholder"
import { dateTimeFormat, formatDate, parseDate } from "../../../../helpers/date";
import GridTable from '@nadavshaar/react-grid-table';
import { IonIcon, IonNote } from "@ionic/react";
import { texts } from "../../../../theme/dataTabelTexts";
import { useAuth } from "../../../../context/auth/AuthState";
import { statusName } from "../../../../helpers/catalogs";
import Kpi from "../../../../components/kpi/Kpi";
import { useHistory } from "react-router";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { formatMoney, isEmpty } from "../../../../helpers/utils";

const GeneralTab = ({data, loading, isCustomer=true}) => {
    const {authState} = useAuth();
    const {enterprise} = authState;
    const history = useHistory();

    const config = {
      isPaginated: false,
      showSearch: false,
      highlightSearch: false,
      showRowsInformation: false,
    }

    const columns = [
        {
            id: 1,
            label: 'Estado',
            field: 'status',
            width: '100px',
            cellRenderer: ({ value }) => (
                <IonNote className={`w-full text-center ${value} with-bg`}>{statusName[value]}</IonNote>
            )
        },
        {
            id: 2,
            label: 'No. factura',
            field: 'number',
            width: '120px',
            getValue: ({value}) => isEmpty(value)
        },
        {
            id: 3,
            field: 'date',
            label: 'Fecha creación',
            width: '135px',
            getValue: ({ value }) => isEmpty(parseDate(value).replace(/\//g, '-'))
        },
        {
            id: 4,
            label: 'Fecha límite',
            field: 'dueDate',
            width: '140px',
            cellRenderer: ({ data }) => (<p className="mx-5">{data.dueDate ? parseDate(data?.dueDate).replace(/\//g, '-') : ' - - -'}</p>)
        },
        {
            id: 5,
            field: 'total',
            label: 'Monto',
            width: 'max-content',
            headerCellRenderer: () => (<div className="ml-auto mr-2">Monto</div>),
            cellRenderer: ({ data }) => {
                return (
                    <div className="text-right w-full m-4">
                        <p>{formatMoney(data, data.total)}</p>
                        {(data.total !== data.convertedTotal) && <IonNote className="text-sm">{formatMoney(enterprise, data.convertedTotal)}</IonNote>}
                    </div>)
            }
        },
        {
            id: 6,
            field: 'remaining',
            label: 'Saldo',
            width: 'max-content',
            headerCellRenderer: () => (<div className="ml-auto mr-2">Saldo</div>),
            cellRenderer: ({ data }) => {
                return (
                    <div className="text-right w-full m-4">
                        <p>{formatMoney(data, data.remaining)}</p>
                        {(data.remaining !== data.convertedRemaining) && <IonNote className="text-sm">{formatMoney(enterprise, data?.convertedRemaining)}</IonNote>}
                    </div>)
            }
        },
    ]

    const goToInvoicePage = (id) => {
        if(isCustomer) history.push(`/invoices/${id}`);
        else history.push(`/expenses/${id}`);
        
      }

    const payIcon = () => (
        <IonIcon className="text-4xl mr-6" slot="start" src="../assets/icon/income.svg" />
    )

    return (
        <div className="flex flex-col justify-center items-start ">

            <section className="flex flex-col md:flex-row justify-between items-start md:items-center my-12 w-full">
                <Kpi loading={loading} iconColor="danger" icon={timeOutline} className="small my-6 md:my-0" title={formatMoney(enterprise, data?.overdueAmount, true)} description="Facturación atrasada" />
                <Kpi loading={loading} iconColor="warning" icon={cashOutline} className="small" title={formatMoney(enterprise, data?.outstandingAmount, true)} description="Facturación pendiente y parcial" />
                <Kpi loading={loading} iconColor="secondary" customIcon={payIcon} className="small" title={formatMoney(enterprise, data?.totalPaid, true)} description="Facturación pagada últimos 12 meses" />
            </section>

            <div className="flex justify-center items-center">
                <p className="font-bold text-gray-blue text-lg mr-3">Facturas pendientes</p>
                <Tooltip className="text-base text-gray-blue  text-center items-center justify-center flex" label={isCustomer ? "Facturas pendientes de este cliente." : "Facturas pendientes de pago de este proveedor"}/>
            </div>
            <GridTable 
                {...config} 
                className={data?.invoices?.length ? 'sfn-data-table h-screen': 'max-h-80'}
                components={{
                NoResults: () => (
                    <Placeholder icon={fileTrayOutline} description='No tienes facturas pendientes' />
                )
                }}
                isLoading={loading}
                icons={{ columnVisibility: <IonIcon icon={filterOutline} slot="icon-only" /> }}
                columns={columns} 
                rows={data?.invoices} 
                texts={texts}
                onRowClick={({data}) => { 
                    goToInvoicePage(data.id);}}
                 />
    </div>
    )
}

export default GeneralTab
