import { Store } from 'pullstate';
const subscription = {
  planId: '',
  billingData: {
    firstName: '',
    lastName: '',
    phone: { type: '', areaCode: '', number: '' },
    email: '',
    nit: '',
    address: '',
    countryISO2: 'GT',
    city: '',
    state: '',
    postalCode: '',
  },
  card: {
    number: '',
    name: '',
    month: '',
    year: '',
    CVV: '',
    enterprise: '',
  },
};
export const MyPlanStore = new Store({
  showModal: false,
  subscription,
  pack: null,
});

export const setShowUpgradeMyPlanModal = (value, callback) => {
  if (typeof callback === 'function' && callback) callback();
  MyPlanStore.update((s) => {
    s.showModal = value;
  });
};

export const updateSubscription = (subscription) => {
  MyPlanStore.update((s) => {
    s.subscription = { ...subscription };
  });
};

export const updatePackage = (pack) => {
  MyPlanStore.update((s) => {
    s.pack = pack;
  });
};
