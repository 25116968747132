import { getAvatar, getBackground } from '../../helpers/utils';
import './ElipsisUsers.scss';

const ElipsisUsers = ({ users, itemsFit }) => {

    let paginatedItems = users.slice(0, itemsFit);

    return (<div className="elipsis-users">
        {paginatedItems.map((user, index) => {
            let text = getAvatar(getAvatar(user?.name));
            let background = getBackground(user?.id)
            return (
            <span key={index + user} className="circle-avatar flex items-center justify-center rounded-full" style={{ background }}>
                {text}
            </span>
        )})}
        {(
            users?.length - itemsFit) > 0 && (<span className="ellipsis" data-ellipsis> +{users.length - itemsFit}</span>)}
    </div>)
}


export default ElipsisUsers;