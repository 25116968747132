import { useEffect, useRef, useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import Select from 'react-select';
import { IonButton, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonNote, IonTextarea, IonToggle } from "@ionic/react";
import './ExpenseGenerator.scss';
import { addCircleOutline, pricetagsOutline, trash } from 'ionicons/icons';
import { sfnSelectTheme } from '../../theme/sfnSelectTheme';
import { useApolloClient, useQuery } from '@apollo/client';
import { getAllVendorsQuery } from '../../graphql/vendor/queries';
import { fixedFloat, formatSelect, getRoundExchangeRate, roundValue } from '../../helpers/utils';
import { getAllProductsQuery } from '../../graphql/product/queries';
import Placeholder from '../placeholder/Placeholder';
import { currencies } from '../../helpers/catalogs';
import { useAuth } from '../../context/auth/AuthState';
import { productExpenseAccountsQuery } from '../../graphql/account/queries';
import InputDate from '../input-date/InputDate';
import { useInvoice } from '../../hooks/useInvoice';
import { ListNoOptionsMessage, MenuListButton } from '../select-menu-components/SelectMenuComponents';
import VendorModal from '../modals/vendor-modal/VendorModal';
import ProductModal from '../modals/product-modal/ProductModal';
// import Tooltip from '../tooltip/Tooltip';
import TaxModal from '../modals/add-tax-modal/TaxModal';
import { getAllTaxRates } from '../../graphql/tax/queries';
import { Tooltip } from '../tooltip/Tooltip';

const ExpenseGenerator = ({ formValues, handleInputChange, rows, setRows, taxes, setTaxes, ratesLoading, refetchTaxes, setRatesLoading}) => {
    const isMounted = useRef(true);
    const { number, description, date, dueDate, vendorId, currencyId, poso, products, extras, includeTaxes } = formValues;
    const { getExchangeRate, authState } = useAuth();
    const client = useApolloClient();

    const { enterprise } = authState;

    const { data: allVendors, loading: vendorsLoading, error: vendorsError } = useQuery(getAllVendorsQuery);
    const { data: expenses, loading: expensesLoading, error: expensesError } = useQuery(productExpenseAccountsQuery);

    const { calculate, totalTaxes, subTotal, grandTotal, getRates, formatSelectTaxes, setIncludeTaxes } = useInvoice();
    const [showVendorModal, setShowVendorModal] = useState(false)
    const [showProductModal, setShowProductModal] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);
    const [showTaxModal, setShowTaxModal] = useState(false);
    const [positionProduct, setPositionProduct] = useState(null)
    const [positionTax, setPositionTax] = useState(null)

    const getAllProducts = async () => {
        setProductsLoading(true);
        const { data, loading, error } = await client.query({
            query: getAllProductsQuery, variables: {
                options: {
                    where: {
                        expenseAccountId: {
                            _not: null
                        }
                    }
                }
            },
            fetchPolicy: 'no-cache'
        });
        if (!loading && !error) {
            setAllProducts(data);
            setProductsLoading(loading);
        }

    }

    useEffect(() => {
        if (products.length) calculate(rows, includeTaxes);
        handleInputChange(rows, 'products');
        return () => {
            isMounted.current = false;
        }
    }, [rows]);

    useEffect(() => {
        if (!showProductModal) {
            getAllProducts();
        }
    }, [showProductModal])

    const handleIncludeTaxes = (e) => {
        handleInputChange(e, 'includeTaxes');
        setIncludeTaxes(e);
        calculate(rows, e);
    }

    const handleVendorChange = (e) => {
        let currencyId = e.value?.bill?.currencyId || enterprise?.currency?.id;
        let tmpCurrency = currencies.find(c => c.value.id === currencyId);
        handleInputChange(tmpCurrency, 'currencyId');
        handleInputChange(e, 'vendorId');
        handleInputChange(getRoundExchangeRate(getExchangeRate(tmpCurrency?.value?.code)), 'exchangeRate');
    }

    const handleCurrencyChance = (e) => {
        handleInputChange(e, 'currencyId');
        handleInputChange(getRoundExchangeRate(getExchangeRate(e?.value?.code)), 'exchangeRate');
    }


    const handleAddRow = () => {
        const newRow = {
            productId: null,
            name: null,
            pDescription: "",
            price: "0",
            quantity: 1,
            taxes: [],
            accountId: null,
            billId: null
        }
        setRows([...rows, newRow]);
    }

    const handleDeleteRow = (i, tableManager, setRows) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone = rowsClone.filter((f, index) => index !== i);
        setRows(rowsClone);
    }

    const handleProductChange = (e, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone[index] = {
            ...rowsClone[index],
            productId: e.value.id,
            name: e.value.name,
            pDescription: e.value.description,
            price: e.value.price,
            taxes: getRates(e.value.taxes, taxes || [], "add"),
            accountId: { label: e.value.expenseAccount.name, value: e.value.expenseAccount.id }
        };
        setRows(rowsClone);
    };

    const handleInputExpenseChange = ({ target }, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];

        let field = target.name;
        let value = target.value;
        if (field === 'price') {
            let oldValue = rowsClone[index][field];
            if (!/^-?\d*[.]?\d{0,2}$/.test(value)) {
              value = oldValue;
            }
        }

        if (field === 'quantity') {
            let oldValue = rowsClone[index][field];
            if (!/^\d*$/.test(value)) {
              value = oldValue;
            }
        }

        rowsClone[index][field] = value;
        setRows(rowsClone);
    }

    const handleAccountChange = (e, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone[index].accountId = e;
        setRows(rowsClone);
    };

    const handleTaxChange = (e, tableManager, index) => {
        let rowsClone = [...tableManager.rowsApi.rows];
        rowsClone[index].taxes = e;
        setRows(rowsClone);
    }

    const fetchTaxes = async () => {
        setRatesLoading(true)
        const {data, errors, loading} = await refetchTaxes();
        if (!errors) {
            setTaxes([...data.taxRates]);
            setRatesLoading(false);
        }
    }



    const tableProps = {
        showSearch: false,
        showRowsInformation: false,
        isPaginated: false,
        showColumnVisibilityManager: false
    }

    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Producto/Servicio',
            cellRenderer: ({ tableManager, rowIndex, data }) => {
                let row = { value: data, label: data.name };
                return (
                    <Select
                        menuPortalTarget={document.body}
                        menuPosition="fixed" 
                        placeholder="Seleccion un procuto/servicio"
                        value={row}
                        isLoading={productsLoading}
                        options={formatSelect(allProducts?.products?.rows || [])}
                        onChange={(e) => handleProductChange(e, tableManager, rowIndex - 1)}
                        className="w-full m-2"
                        styles={sfnSelectTheme}
                        components={
                            {
                                MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo", callback: ()=> {
                                    setPositionProduct(rowIndex - 1)
                                    setShowProductModal(true)
                                } }),
                                NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay productos" })
                            }
                        }
                    />
                )
            }
        },
        {
            id: 2,
            field: 'accounts',
            label: 'Categoría',
            cellRenderer: ({ tableManager, rowIndex, data }) => {

                return (
                    <Select
                        menuPortalTarget={document.body}
                        menuPosition="fixed" 
                        placeholder="Seleccione una categoría"
                        value={data?.accountId}
                        name="accountId"
                        onChange={(e) => handleAccountChange(e, tableManager, rowIndex - 1)}
                        options={expenses?.productAccounts}
                        className="w-full m-2"
                        styles={sfnSelectTheme}
                    />
                )
            }
        },
        {
            id: 3,
            field: 'description',
            label: 'Descripción',
            width: '120px',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonTextarea
                        value={data?.pDescription}
                        name="pDescription"
                        type="text"
                        placeholder="Descripción"
                        onIonChange={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                        rows="1"
                        autoGrow={true}
                    />

                </IonItem>
            )

        },
        {
            id: 4,
            field: 'quantity',
            label: 'Cantidad',
            width: '90px',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonInput
                        value={data?.quantity}
                        name="quantity"
                        type="text"
                        placeholder="Cantidad"
                        onKeyPress={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onChange={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onKeyUp={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onKeyDown={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                    >
                    </IonInput>
                </IonItem>
            )

        },
        {
            id: 5,
            field: 'price',
            label: 'Precio',
            width: '70px',
            cellRenderer: ({ tableManager, rowIndex, data }) => (
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonInput
                        value={data.price}
                        name="price"
                        type="text"
                        placeholder="Precio"
                        onKeyPress={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onChange={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onKeyUp={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)} 
                        onKeyDown={(e) => handleInputExpenseChange(e, tableManager, rowIndex - 1)}
                        className="m-2 no-bg"
                    >
                    </IonInput>
                </IonItem>
            )
        },
        {
            id: 6,
            field: 'tax',
            label: 'Impuesto',
            cellRenderer: ({ tableManager, rowIndex, data }) => {
                return (<Select
                    menuPortalTarget={document.body}
                    menuPosition="fixed" 
                    placeholder="Impuesto"
                    value={data?.taxes}
                    isMulti
                    isLoading={ratesLoading}
                    onChange={(e) => handleTaxChange(e, tableManager, rowIndex - 1)}
                    options={formatSelectTaxes([...taxes])}
                    className="w-full m-2"
                    styles={sfnSelectTheme}
                    components={
                        {
                            MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo", callback: () => {
                                setPositionTax(rowIndex- 1)
                                setShowTaxModal(true)
                            } }),
                            NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay impuestos" })
                        }
                    }
                />
                )
            }
        },
        {
            id: 7,
            label: 'Total',
            width: '80px',
            cellRenderer: ({ data }) => {
                return <IonNote className="m-2 text-center" >{fixedFloat(data.quantity * data.price)}</IonNote>
            }
        },
        {
            id: 8,
            width: '60px',
            cellRenderer: ({ tableManager, rowIndex }) => (
                <IonButton size="small" fill="clear" color="medium" onClick={() => (handleDeleteRow(rowIndex - 1, tableManager, setRows))}>
                    <IonIcon slot="icon-only" icon={trash} />
                </IonButton>
            )
        },
    ]

    useEffect(() => {
        try {
            getExchangeRate()
        } catch (error) {
            console.log(error)
        }
    
    }, [])
    
    const afterCreateVendor = (vendor) => {
        handleVendorChange({label: vendor?.name, value: vendor})
    }

    const afterCreateProduct = (index, product) => {
        let rowsClone = [...rows];
        const expenseAccount = expenses?.productAccounts.find(f => f.value === product?.expenseAccountId)
        rowsClone[index] = {
            ...rowsClone[index],
            productId: product.id,
            name: product.name,
            pDescription: product.description,
            price: product.price,
            taxes: getRates(product.taxes, taxes || [], "add"),
            accountId: expenseAccount
        };
        setRows(rowsClone);
    }

    const afterCreateTax = (index, tax) => {
        let rowsClone = [...rows]
        rowsClone[index].taxes = [...rowsClone[index].taxes, {label: tax?.name, value: tax}];
        setRows(rowsClone);
    }


    return (
        <div id="sfn-expense-generator" className="flex flex-col shadow-md rounded w-full">
            <div className="expense-container p-8">
                <div className="expense-header">

                    <div className="flex flex-col justify-start">
                        <h3 className="text-2xl font-bold mr-4" >FACTURA</h3>
                        <div className="invoice-identifier flex items-center">
                            
                            <IonItem className="sfn-input" mode="md" lines="none">
                                <IonLabel position="stacked">No. de serie</IonLabel>
                                <IonInput
                                    name="extras.serie"
                                    type="text"
                                    placeholder="No. de serie"
                                    onIonChange={handleInputChange}
                                    value={extras.serie}
                                >
                                </IonInput> 
                            </IonItem>
                        </div>
                    </div>

                </div>

                <section className="flex flex-col  mt-8">
                    <div className="flex flex-col sm:flex-row justify-between items-end">
                        <IonItem className="sfn-input md:w-48 w-full" mode="md" lines="none">
                            <IonLabel position="stacked">No. de factura</IonLabel>
                            <IonInput name="number" type="text" onIonChange={handleInputChange} value={number} placeholder="Ingresa no. de factura" required> </IonInput>
                        </IonItem>
                        <div className="w-full sm:w-80">
                            <p className="text-sm mb-2 hover:text-secondary w-80" >Proveedor<span className="text-base text-danger">*</span></p>
                            <Select
                                placeholder="Seleccione un proveedor"
                                value={vendorId}
                                onChange={handleVendorChange}
                                options={formatSelect(allVendors?.vendors?.rows || [])}
                                className="sfn-select z-30 mb-1"
                                styles={sfnSelectTheme}
                                components={
                                    {
                                        MenuList: (props) => MenuListButton({ ...props, label: "Agregar nuevo proveedor", callback: setShowVendorModal }),
                                        NoOptionsMessage: (props) => ListNoOptionsMessage({ ...props, label: "No hay proveedores" })
                                    }
                                }
                            />
                        </div>

                        <InputDate label="Fecha de factura" icon={false} value={date} handleInputChange={handleInputChange} name="date"  required={true}></InputDate>
                        <InputDate label="Fecha de pago" icon={false} value={dueDate} handleInputChange={handleInputChange} name="dueDate" required={true}></InputDate>

                    </div>
                    <div className="flex flex-col sm:flex-row items-start">
                        <div className="sfn-input md:w-48 w-full mr-0 sm:mr-4 flex flex-col" mode="md" lines="none">
                            <div position="stacked" className="text-sm flex align-center mt-4">
                                <p className="mr-1 mb-1">No. de autorización</p>
                                <Tooltip label="Número de autorización que provea la entidad tributaria de tu país."/>
                            </div>
                            <IonInput
                                name="extras.authorization"
                                type="text"
                                placeholder="No. de autorización"
                                onIonChange={handleInputChange}
                                value={extras.authorization}
                            >
                            </IonInput>
                        </div>
                        <div className="sfn-input md:ml-28 ml-0 md:w-48 w-full flex flex-col" mode="md" lines="none">
                            <div position="stacked" className="text-xs flex align-center mt-4">
                                <p className="mr-1 mb-1 text-xs">P.O/S.O (Opcional)</p>
                                <Tooltip label="Número de orden de compra u orden de venta."/>
                            </div>
                            <IonInput
                                name="poso"
                                type="text"
                                placeholder="No. Orden"
                                onIonChange={handleInputChange}
                                value={poso}
                            >
                            </IonInput>
                        </div>
                    </div>

                </section>

                {(!productsLoading && !expensesLoading && !ratesLoading) &&
                    <GridTable
                        className={`mt-8 sfn-data-table ${!rows?.length ? 'sfn-data-table-xs': 'max-h-screen h-auto'}`}
                        columns={columns}
                        rows={rows}
                        {...tableProps}
                        components={{
                            NoResults: () => (
                                <Placeholder icon={pricetagsOutline} description='Aún no tienes productos agregados haz clic en el botón "Agregar producto/servicio"' />
                            )
                        }}
                    />}



                <IonButton className="sfn-button" fill="clear" expand="block" onClick={handleAddRow}>
                    <IonIcon icon={addCircleOutline} slot="start" />
                    AGREGAR UN PRODUCTO/SERVICIO
                </IonButton>

                <div className="summary p-4 mb-8" >
                    <div className="flex justify-end my-2 mr-4 items-center">
                        <p className="mr-14">¿Los productos ya incluyen impuestos?</p>
                        <IonToggle mode="ios" checked={includeTaxes} onIonChange={e => handleIncludeTaxes(e.detail.checked)} />
                    </div>
                    <div className="flex justify-end my-2 mr-4">
                        <p>Total de impuestos</p>
                        <p className="totals text-right">{currencyId?.value?.symbol || '¤'}. {totalTaxes}</p>
                    </div>
                    
                    <div className="flex flex-col md:flex-row justify-center md:justify-end items-center mt-8 rounded py-4 pr-4" style={{ background: '#3D4D5A', color: '#ffffff' }}>
                        <p className="font-bold md:mr-8 mr-0 md:mb-0 mb-4">TOTAL</p>
                        <Select name="currencyId" placeholder="Seleccione moneda" value={currencyId} onChange={handleCurrencyChance} options={currencies} className="sfn-select z-20 mb-1 w-60 md:mb-0 mb-4" styles={sfnSelectTheme} />
                        <p className="font-bold totals md:text-right text-center">{currencyId?.value?.symbol || '¤'}. {grandTotal}</p>
                    </div>
                    {(currencyId?.value?.symbol && currencyId?.value?.code !== enterprise?.currency?.code)
                        && <p className="text-right my-4">Conversion de moneda: {`${enterprise?.currency?.symbol}${roundValue(grandTotal / getExchangeRate(currencyId?.value?.code))} (${enterprise?.currency?.code})`} a {getRoundExchangeRate(getExchangeRate(currencyId?.value?.code))}</p>}
                </div>

            </div>
            <div className="expense-footer p-8">
                <IonItem className="sfn-input" mode="md" lines="none">
                    <IonLabel position="stacked">Nota (Opcional)</IonLabel>
                    <IonTextarea
                        name="description"
                        type="text"
                        placeholder="Agrega una nota extra en esta factura"
                        onIonChange={handleInputChange}
                        value={description}
                        className="md"
                        rows="1"
                        autoGrow="true"
                    />
                </IonItem>
            </div>
            {showTaxModal && <TaxModal position={positionTax} afterCreate={afterCreateTax} showModal={showTaxModal} setShowModal={setShowTaxModal} eventsClose={fetchTaxes} />}
            {showVendorModal && <VendorModal afterCreate={afterCreateVendor} showModal={showVendorModal} setShowModal={setShowVendorModal} />}
            {showProductModal && <ProductModal position={positionProduct} afterCreate={afterCreateProduct} showModal={showProductModal} setShowModal={setShowProductModal}></ProductModal>}

        </div>
    )
}

export default ExpenseGenerator;