import { useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import { useQuery } from '@apollo/client';
import { getOneEnterpriseQuery } from '../../../graphql/enterprise/queries'
import BusinessModal from '../../../components/modals/business-modal/BusinessModal';
import { useAuth } from '../../../context/auth/AuthState';
import { enterpriseTypesNames } from '../../../helpers/catalogs';
import { dateTimeFormat, parseDate } from '../../../helpers/date';
import { AuthStore } from '../../../store/auth';

const initialState = {
	id: "",
	name: "",
	bill: {
		phone: {
			type: "WORK",
			areaCode: "",
			number: "",
		},
		nit: "",
		state: "",
		address: "",
	},
	country: {
		id: "",
		name: "",
	},
	currency: {
		id: "",
		name: "",
	},
	bussinesType: {
		id: "",
		name: "",
	},
	enterpriseType: {
		id: "",
		name: "",
	}
};

const initialPlan = {
	name: "Plan Freeemium",
	users: 2,
	companies: 1,
	modules: [
		'Reportería básica'
	],
	demo: true,
	remainingDays: 30,
	registeredAt: new Date().toLocaleDateString()
}

const GeneralSettingsPage = ({ authState, enterprise, refetchEnterprise }) => {
	const [showBusinessModal, setShowBusinessModal] = useState(false);
	const user = AuthStore.useState(s => s.user);
	return (
		<div className="p-4">
			<div className="flex flex-wrap justify-between items-start w-full mb-12">
				<h2 className="text-xl text-gray-blue font-bold">
					Información general de la empresa
				</h2>
				<IonButton disabled={['READ'].includes(user?.role?.permissions['configuration'])} color="primary" shape="round" fill="outline" className="sfn-button" onClick={() => { setShowBusinessModal(true) }}>
					<IonIcon slot="start" icon={pencilOutline} />
					Editar
				</IonButton>
			</div>
			<div className="grid lg:grid-cols-2 grid-cols-1 gap-12">
				<div className="col-span-1 flex flex-col">
					<h3 className="body-tag">
						Nombre de empresa
					</h3>
					<span className="text-base text-gray-light mb-8">
						{enterprise?.name}
					</span>
					<h3 className="body-tag">
						Giro de negocio
					</h3>
					<span className="text-base text-gray-light mb-8">
						{enterprise?.bussinesType?.name}
					</span>
					<h3 className="body-tag">
						Pais
					</h3>
					<span className="text-base text-gray-light mb-8">
						{enterprise?.country ? enterprise?.country?.name : "N/A"}
					</span>
					<h3 className="body-tag">
						Moneda
					</h3>
					<span className="text-base text-gray-light mb-8">
						{enterprise?.currency?.name}
					</span>
					<h3 className="body-tag">
						Teléfono
					</h3>
					<p className="text-base text-gray-light mb-8">
						{enterprise?.bill?.phone ?
							`+ ${enterprise?.bill?.phone?.areaCode || ''} ${enterprise?.bill?.phone?.number}`
							:
							" - - -"
						}
					</p>
				</div>
				{/* <div className="col-span-1">
					<div className="rounded-lg w-full bg-gray-white p-8 flex flex-col shadow-md" style={{ height: 'fit-content' }}>
						<div className="flex justify-between items-center w-full mb-12">
							<div>
								<h2 className="text-xl text-gray-blue font-bold">
									{authState.plan.name || initialPlan.name}
								</h2>
								<span className="text-base text-gray-light mb-8">
									Tu plan actual
								</span>
							</div>
							<IonButton disabled={['READ'].includes(user?.role?.permissions['configuration'])} color="secondary" shape="round" fill="solid" href="https://symfonia.io/precios" target="_blank" className="sfn-button text-white">
								<span className="my-8 text-white">Mejorar mi plan</span>
							</IonButton>
						</div>
						<div className="flex flex-col text-center">
							<span className="text-base text-gray-light mb-12">
								{authState.plan.users} usuario{authState.plan.users > 1 && 's'}, {authState.plan.enterprises} empresa{authState.plan.enterprises > 1 && 's'}, {authState.plan.modules ? authState.plan.modules.join() : ''}.
							</span>
							<span className="text-base text-gray-light mb-2">
								Días restantes: <span className="font-bold text-gray">{authState.plan.freeDays || initialPlan.remainingDays}</span>
							</span>
							<span className="text-base text-gray-light">
								Fecha de inicio: {authState.registeredAt ? parseDate(authState.registeredAt).replace(/\//g, '-') : parseDate(initialPlan.registeredAt).replace(/\//g, '-')}
							</span>
						</div>
					</div>
				</div> */}
			</div>
			{showBusinessModal && <BusinessModal notUpdate={true} showBusinessModal={showBusinessModal} setShowBusinessModal={setShowBusinessModal} businessId={enterprise.id} refetchEnterprise={refetchEnterprise} />}
		</div>
	)
}


export default GeneralSettingsPage;
